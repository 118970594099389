/*
 * Component Description
 */
import * as React from "react";

import { ToggleSection } from "../../../common/";
import { Tyto } from "../../../../typings/tyto";

import CreateNewUser from "./CreateNewUser";
import GenerateLink from "./GenerateLink";

import "./AddToTeam.scss";

interface Props {
  closedAtStart?: boolean;
  reloadTeamMembers: (teamID: number) => void;
  team?: Tyto.Team;
}

export default (props: Props) => {
  return (
    <ToggleSection
      closedAtStart={props.closedAtStart}
      title="Add someone to this team"
      className="meta-super-sidepane-toggle-section"
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        return (
          <div className="meta-super-sidepane-addtoteam-main-cont">
            <div className="meta-super-sidepane-addtoteam-inner-cont">
              <CreateNewUser
                reloadTeamMembers={props.reloadTeamMembers}
                team={props.team}
              />
            </div>

            <div className="meta-super-sidepane-addtoteam-inner-cont width-border-left">
              <GenerateLink />
            </div>
          </div>
        );
      }}
    />
  );
};
