/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { letterSpread } from "../utils";

import "./ColumnDistributionGraph.scss";
import { Tyto } from "../../../../typings/tyto";

interface Props {
  focusLetter: "d" | "i" | "s" | "c";
  profile: {
    d: number;
    i: number;
    s: number;
    c: number;
    personName?: string;
    percentile?: {
      d: number;
      i: number;
      s: number;
      c: number;
    };
    asset?: Tyto.Asset;
  };
}

export default (props: Props) => {
  const [barWidths, updateBarWidths] = React.useState(
    Array.from({ length: 16 }, () => ({ width: 0, transitionDuration: 400 }))
  );
  const distribution = letterSpread[props.focusLetter];

  React.useEffect(() => {
    if (Array.isArray(distribution)) {
      const newBarWidths = distribution.map(item => ({
        width: item.value * 100 * 4,
        transitionDuration: 400 + Math.round(Math.random() * 600)
      }));

      updateBarWidths(newBarWidths);
    }
  }, []);

  if (!Array.isArray(distribution)) {
    return <span style={{ visibility: "hidden" }} />;
  }

  const letterValue = props.profile[props.focusLetter];
  const cutoffIdx = Math.abs(8 - Math.floor(letterValue || 0));

  return (
    <>
      {distribution.map((percentSection, curIdx) => (
        <div
          className={cx(
            "cc-disc-plot-graph-column-disctribution-row",
            curIdx >= cutoffIdx &&
              `cc-disc-plot-graph-column-disctribution-row-${props.focusLetter}`
          )}
          key={`${props.focusLetter}-${percentSection.key}`}
          style={{
            top: `${curIdx * (1 / 16) * 100}%`,
            width: `${barWidths[curIdx].width || 0}%`,
            transitionDuration: `${barWidths[curIdx].transitionDuration || 0}ms`
          }}
        />
      ))}
    </>
  );
};
