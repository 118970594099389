import { TabType } from "../";
import { AppStoreProps } from "../../../../data/stores/AppStore";
import TytoCalls from "../../../../data/tyto/";
import { Tyto } from "../../../../typings/tyto";

export type PersonTabsType =
  | "comm-tips"
  | "about-r3type"
  | "tryyb-profile"
  | "no-disc"
  | "admin-info"
  | "you-and-other";

export type OwnProfileTabsType =
  | "growth-areas"
  | "about-r3type"
  | "comm-tips"
  | "download"
  | "downloads"
  | "tryyb-profile"
  | "no-disc";

const ownProfile = [
  {
    label: "Communication Tips",
    value: "comm-tips",
  },
  {
    label: "About {{styleName}}",
    value: "about-r3type",
  },
  {
    label: "Growth Areas",
    value: "growth-areas",
  },
  {
    label: "Downloads",
    value: "downloads",
  },
  {
    label: "Account",
    value: "tryyb-profile",
  },
];

const personTabs = [
  {
    label: "Communication Tips",
    value: "comm-tips",
  },
  {
    label: "About {{styleName}}",
    value: "about-r3type",
  },
  {
    label: "Perception",
    value: "you-and-other",
  },
  {
    label: "About {{personName}}",
    value: "tryyb-profile",
  },
  {
    label: "Admin",
    value: "admin-info",
  },
];

const personTabsNoDISC = [
  {
    label: "No DISC",
    value: "no-disc",
  },
  {
    label: "About {{personName}}",
    value: "tryyb-profile",
  },
];

export function getTabsFromTabType(tabType: TabType, hasMiniProfile: boolean) {
  switch (tabType) {
    case "own-profile":
      return ownProfile;
    case "profile-full":
    default:
      return !hasMiniProfile ? personTabsNoDISC : personTabs;
  }
}

export async function loadMiniProfilesForTeam(
  teamID: string,
  appStore: AppStoreProps
) {
  if (!appStore.dispatch) {
    return;
  }

  const teamIDParsed = parseInt(teamID);

  if (!teamIDParsed) {
    return;
  }

  try {
    const teamMiniProfileResp = await TytoCalls.DISCProfilesMini.get({
      teamID: teamIDParsed,
    });

    appStore.dispatch({
      payload: {
        discProfiles: teamMiniProfileResp.discProfiles,
        teamID,
      },
      type: "TEAM_DISC_MINI_PROFILES_LOADED",
    });
  } catch (err) {
    appStore.dispatch({
      payload: {
        discProfiles: [],
        teamID,
      },
      type: "TEAM_DISC_MINI_PROFILES_LOADED",
    });

    return;
  }
}

// export async function loadTryybProfile({
//   AppStore,
//   callback,
//   onError,
//   userID,
// }: {
//   AppStore: AppStoreProps;
//   callback: () => void;
//   onError: (msg: string) => void;
//   userID: number;
// }) {
//   if (!userID) {
//     return;
//   }

//   try {
//     const personResp = await TytoCalls.Person.get({ personID: userID });

//     if (AppStore.dispatch) {
//       AppStore.dispatch({
//         payload: {
//           profile: personResp.person,
//           userID,
//         },
//         type: "TRYYB_PROFILE_LOADED",
//       });

//       callback();
//     }
//   } catch (err) {
//     const error = typeof err === "string" ? err : JSON.stringify(err);

//     onError(error);
//   }
// }

export async function updatePerson({
  AppStore,
  changes,
  userID,
  callback,
}: {
  AppStore: AppStoreProps;
  changes: Partial<Endpoints.Tyto.Person.PutParameters>;
  userID: number;
  callback: () => void;
}) {
  if (!userID) {
    return;
  }

  try {
    const updateResp = await TytoCalls.Person.put({
      personID: userID,
      ...changes,
    });

    const personResp = await TytoCalls.Person.get({ personID: userID });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          profile: personResp.person,
          userID,
        },
        type: "TRYYB_PROFILE_LOADED",
      });
    }

    callback();
  } catch (err) {
    console.warn("Failed to Update Profile Information");
  }
}

export interface AccountItem {
  label: string;
  domainLabelKey?: string;
  key: keyof Tyto.Person;
  width?: "full" | "half";
}

export const accountItems: AccountItem[] = [
  {
    label: "First Name",
    key: "givenName",
  },
  {
    label: "Family Name",
    key: "familyName",
  },
  {
    label: "Email Address",
    key: "email",
  },
  {
    label: "Phone",
    key: "phone1",
  },
  {
    label: "Job title",
    key: "jobTitle",
  },
  {
    label: "Department",
    key: "department",
  },
  {
    label: "Birthday",
    key: "birthDate",
  },
  {
    label: "Website",
    key: "website",
  },
  {
    label: "Address Line 1",
    key: "address1",
    width: "full",
  },
  {
    label: "Address Line 2",
    key: "address2",
    width: "full",
  },
  {
    label: "City",
    key: "city",
  },
  {
    label: "State",
    key: "state",
  },
  {
    label: "Postal Code",
    key: "postalCode",
  },
  {
    label: "Outside ID",
    key: "outsideID",
  },
  // {
  //   label: "Role ID",
  //   key: "roleID"
  // },
  {
    label: "Personal 1",
    domainLabelKey: "domainLabelPersonal1",
    key: "personal1",
    width: "full",
  },
  {
    label: "Personal 2",
    domainLabelKey: "domainLabelPersonal2",
    key: "personal2",
    width: "full",
  },
  {
    label: "Personal 3",
    domainLabelKey: "domainLabelPersonal3",
    key: "personal3",
    width: "full",
  },
  {
    label: "Personal 4",
    domainLabelKey: "domainLabelPersonal4",
    key: "personal4",
    width: "full",
  },
];
