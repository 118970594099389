import * as _ from "lodash";
import { AppStoreProps } from "../../stores/AppStore";

import { sendMessageTypes, messageTypes } from "../constants/";

export function getMessageAsString(data: WebSocketClient.SendParams) {
  const dataAsString = JSON.stringify(data);

  return dataAsString;
}

export function getMessageFromString(
  data: string
): WebSocketClient.SendParams | null {
  try {
    const parsedMessage: WebSocketClient.SendParams = JSON.parse(data);

    if (!parsedMessage) {
      console.log(
        "(::WebSocket::) Parsed Message found to be invalid: ",
        parsedMessage
      );
      return null;
    }

    return sanitizeReceivedMessage(parsedMessage);
  } catch (err) {
    console.log("(::WebSocket::) Error Occurred while parsing Message: ", err);
    return null;
  }
}

export function sanitizeReceivedMessage(
  data: WebSocketClient.SendParams
): WebSocketClient.SendParams | null {
  switch (data.type) {
    case messageTypes.MINI_CHANGED:
      const userID = _.get(data, "payload.userID");

      if (userID) {
        return {
          type: data.type,
          payload: {
            userID,
          },
        };
      } else {
        return null;
      }
    case messageTypes.MULTIPLE_MINIS_CHANGED:
      const userIDs = _.get(data, "payload.userIDs");

      if (userIDs) {
        return {
          type: data.type,
          payload: {
            userIDs,
          },
        };
      } else {
        return null;
      }
    case messageTypes.REQUESTED_USERS_CONNECTIONS_STATUS:
      const resp_usersConnectionsInfo = _.get(
        data,
        "payload.usersConnectionsInfo",
        []
      );

      if (resp_usersConnectionsInfo && resp_usersConnectionsInfo.length) {
        return {
          type: data.type,
          payload: {
            usersConnectionsInfo: resp_usersConnectionsInfo,
          },
        };
      }

      return null;
    case messageTypes.USER_ACTIVITY_CHANGED:
      const activity_userID = _.get(data, "payload.userID", 0);
      const activity_connectionsCount = _.get(
        data,
        "payload.connectionsCount",
        0
      );
      const activity_lastActivity = _.get(data, "payload.lastActivity", "");

      if (
        activity_userID &&
        // activity_lastActivity &&
        activity_connectionsCount !== undefined
      ) {
        return {
          type: data.type,
          payload: {
            userID: activity_userID,
            connectionsCount: activity_connectionsCount,
            lastActivity: activity_lastActivity,
          },
        };
      }

      return null;
    case messageTypes.USER_ACTION_CONFIRMATION:
      // actionType,
      // wasSuccess,
      const confirmation_actionType = _.get(data, "payload.actionType");
      const confirmation_wasSuccess = _.get(data, "payload.wasSuccess");

      if (confirmation_actionType && confirmation_wasSuccess !== undefined) {
        return {
          type: data.type,
          payload: {
            actionType: confirmation_actionType,
            wasSuccess: confirmation_wasSuccess,
          },
        };
      }

      return null;
    case messageTypes.USER_EXCEPTION_PING:
      const errorMsg = _.get(data, "payload.errorMsg", undefined);

      if (errorMsg) {
        return {
          type: data.type,
          payload: {
            errorMsg,
          },
        };
      }

      return null;
    default:
      console.log(
        "Received Message Type invalid, so was not Sanitizied, but returned null. Type was: ",
        data.type
      );
      return null;
  }
}

export function getValidMessage(
  data: WebSocketClient.SendParams
): string | null {
  switch (data.type) {
    case sendMessageTypes.MINIS_UPDATED:
    case sendMessageTypes.WATCH_MULTIPLE:
      const userIDs = _.get(data, "payload.userIDs");

      if (Array.isArray(userIDs) && userIDs.length) {
        return getMessageAsString({
          type: data.type,
          payload: {
            userIDs,
          },
        });
      } else {
        return null;
      }
    case sendMessageTypes.GET_USERS_CONNECTION_STATUS:
      const statusUserIDs = _.get(data, "payload.userIDs", []);

      if (statusUserIDs && statusUserIDs.length) {
        return getMessageAsString({
          type: data.type,
          payload: {
            userIDs: statusUserIDs,
          },
        });
      }

      return null;
    case sendMessageTypes.RESET_USER_WATCH_LISTS:
      // TODO: Decide if this should actually be a thing
      return null;
    case sendMessageTypes.UPDATE_CONNECTION_USER_METADATA:
      const connectionUserID = _.get(data, "payload.userID", undefined);
      const connectionIsAdmin = _.get(data, "payload.isAdmin", undefined);

      if (connectionUserID || connectionIsAdmin) {
        return getMessageAsString({
          type: data.type,
          payload: {
            userID: connectionUserID,
            isAdmin: connectionIsAdmin,
          },
        });
      }

      return null;
    case sendMessageTypes.WATCH_INTERACTIVE:
      // TODO: Decide if this should actually be a thing
      // * At the moment the thought is that the client should discern
      // * if the Mini and/or interactive should be reloaded, and otherwise
      // * which of the 2 it does or does not care about.
      return null;
    case sendMessageTypes.WATCH_MINI:
      const userID = _.get(data, "payload.userID");

      if (userID) {
        return getMessageAsString({
          type: data.type,
          payload: {
            userID,
          },
        });
      } else {
        return null;
      }
    default:
      return null;
  }
}

export function tellAppStoreWebSocketConnectionChanged({
  AppStore,
  isConnected,
}: {
  AppStore?: AppStoreProps;
  isConnected: boolean;
}) {
  if (AppStore && AppStore.dispatch) {
    AppStore.dispatch({
      payload: {
        isConnected: !!isConnected,
      },
      type: "WEBSOCKET_CONNECTION_STATUS_CHANGE",
    });
  }
}
