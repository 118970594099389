import { ENDPOINT_LOGOUT_URL } from "../constants";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_LOGOUT_URL;

export const Logout = {
  post(
    params: Endpoints.Tyto.Session.Logout.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, { ...params }, callOpts);
  }
};
