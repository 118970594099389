import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import type { Tyto } from "../../../typings/tyto";
import { Button } from "../../../components/common/";

import "./LandingPage.scss";

interface Props {
  discProfileMini?: Tyto.DISCProfileMini;
  loggedInUserID: number;
  onContinue: () => void;
}

const AssessmentTakerLandingPage = ({
  discProfileMini,
  loggedInUserID,
  onContinue,
}: Props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      !discProfileMini?.styleKey3 &&
      !(discProfileMini as any)?.atsInitialsAdaptive
    )
      return;

    navigate(`/profile/${loggedInUserID}`);
  }, [
    !!discProfileMini?.styleKey3,
    !!(discProfileMini as any)?.atsInitialsAdaptive,
  ]);

  if (
    !!discProfileMini?.styleKey3 ||
    !!(discProfileMini as any)?.atsInitialsAdaptive
  ) {
    return <div>Redirecting...</div>;
  }

  return (
    <article className="assessment-taker-landing">
      <h2 className="assessment-taker-landing-title title-font">
        DISC Assessment
      </h2>

      <p className="assessment-taker-landing-text">
        Through the DISC assessment you can learn more about your innate
        abilities, as well as any corresponding weaknesses.
      </p>

      <p className="assessment-taker-landing-text">
        You will also learn about how you see yourself and how others see you.
        This knowledge that can not only help you understand your most valuable
        skills, but will also reveal the connection between your passions and
        your expertise.
      </p>

      <section className="assessment-taker-landing-cards-cont">
        <AssessmentLandingCard
          title="Here are some instructions and what to expect"
          msg="For each of the 24 questions in this assessment you will be presented with four words. Select one word that you feel is most like you and one word that you feel is least like you."
        />

        <AssessmentLandingCard
          title="You can edit your answers at any time "
          msg="As you take the assessment, you will be able to view and edit all of your previous answer choices, even if you quit and resume the assessment later."
        />

        <AssessmentLandingCard
          title="We may have a few additional questions for you"
          msg="If your assessment results need more clarity, we will ask you six additional questions to help us make a more accurate evaluation."
        />
      </section>

      <footer className="assessment-taker-landing-footer">
        <Button
          className="assessment-taker-landing-footer-btn"
          onClick={onContinue}
          value="Start Assessment"
          style={{ borderColor: "currentColor" }}
        />
      </footer>
    </article>
  );
};

interface AssessmentLandingCardProps {
  title: string;
  msg: string;
}

const AssessmentLandingCard = (props: AssessmentLandingCardProps) => {
  return (
    <div className="assessment-taker-landing-card">
      <h3 className="assessment-taker-landing-card-title title-font">
        {props.title}
      </h3>
      <p className="assessment-taker-landing-card-msg">{props.msg}</p>
    </div>
  );
};

export default AssessmentTakerLandingPage;
