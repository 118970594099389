/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import QueryString from "query-string";

import { StoreContext } from "../../data/stores/AppStore";
import TytoCalls from "../../data/tyto/";
import { StoreContextProvider } from "../teams/data/stores/DirectoryStore";
import { Nav } from "../../components/meta/";
import { Loading } from "../../components/common/";
import Teams from "../teams";

import "./style.scss";
import { Tyto } from "../../typings/tyto";

interface Props {
  path?: string;
  teamID?: string;
  uri?: string;
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);

  const navigate = useNavigate();
  const location = useLocation();

  const teamIDFromURL = useTeamIDFromURL();

  const [sessionKey, updateSessionKey] = React.useState(
    store &&
      store.state &&
      store.state.sessionData &&
      store.state.sessionData.sessionKey
      ? store.state.sessionData.sessionKey
      : ""
  );
  const [domainID] = React.useState(
    store &&
      store.state &&
      store.state.sessionData &&
      store.state.sessionData.domainID
      ? store.state.sessionData.domainID
      : 0
  );

  // // * [E-1]
  // React.useEffect(() => {
  //   async function getTeamMembers() {
  //     TytoCalls.DISCProfiles.get({ teamIDs: [domainID] })
  //       .then((resp: any) => {
  //         console.log("DISC [full] Profiles loaded: ", resp);

  //         if (directoryStore && directoryStore.dispatch) {
  //           directoryStore.dispatch({
  //             payload: {
  //               users: resp.discProfiles
  //             },
  //             type: "DIRECTORY_USERS_LOADED"
  //           });
  //         }
  //       })
  //       .catch(err => {
  //         console.log(
  //           "Error occurred while loading Full DISC Profiles in Home Interface",
  //           err
  //         );
  //       });
  //   }

  //   if (sessionKey) {
  //     getTeamMembers();
  //   }
  // }, []);

  // * [E-1] - Keep local sessionData up to date
  React.useEffect(() => {
    const newSessionData = _.get(store, "state.sessionData", undefined);

    if (
      sessionKey &&
      (!newSessionData || (newSessionData && !newSessionData.sessionKey))
    ) {
      updateSessionKey(undefined);
    }
  }, [_.get(store, "state.sessionData", undefined)]);

  const personInfo: Tyto.Person | undefined = _.get(
    store,
    "state.personInfo",
    undefined
  );

  React.useEffect(() => {
    if (!sessionKey) {
      navigate(getURI(location?.pathname));
    } else if (store.state && store.state.needsAssessment) {
      navigate("/take-assessment");
    } else if (location?.pathname === "/") {
      const domainType = _.get(
        store,
        "state.userDomain.domainType",
        ""
      ) as keyof typeof Tyto.DomainType;

      if (domainType && domainType !== "ocTEAMTOOLSCOACH") {
        navigate(
          personInfo && personInfo.teamRoot
            ? `/team/${personInfo.teamRoot}`
            : "/directory"
        );
      } else {
        navigate(
          `/profile/${_.get(
            store,
            "state.loggedInUserID",
            _.get(store, "state.personInfo.personID")
          )}`
        );
      }
    }
  }, [sessionKey, !!store.state?.needsAssessment, location?.pathname === "/"]);

  React.useEffect(() => {
    const rootTeamID = store.state?.sessionData?.teamRootID;

    if (!rootTeamID || !!teamIDFromURL) return;

    navigate(`/team/${rootTeamID}`);
  }, [store.state?.sessionData || null, teamIDFromURL]);

  if (!sessionKey || (store.state && store.state.needsAssessment)) {
    return <div>Redirecting...</div>;
  } else if (props.uri === "/") {
    if (!personInfo) {
      return (
        <div className="loading-crane-cont">
          <Loading size={150} type="squares" />
        </div>
      );
    } else {
      // * Reroute to profile, unless we know the domainType and it isn't NO SOCIAL (coach)
      <div>Redirecting...</div>;
    }
  }

  return (
    <StoreContextProvider path={props.path} uri={location?.pathname}>
      <section className="home-interface">
        <Nav path={props.path} uri={location?.pathname} />

        <Teams teamID={`${teamIDFromURL}`} uri={location?.pathname} />
        {/* <p className="session-info">{sessionKey}</p>
            <h1 style={{ margin: "0px", paddingTop: "20px" }}>Home</h1> */}
      </section>
    </StoreContextProvider>
  );
};

function useTeamIDFromURL() {
  const { teamID } = useParams();

  let safeTeamID: number | undefined = undefined;

  if (typeof teamID === "string") {
    const parsedID = parseInt(teamID);

    if (!Number.isNaN(safeTeamID)) {
      safeTeamID = parsedID;
    }
  }

  return safeTeamID || 0;
}

const getURI = (uri?: string): string => {
  if (uri) {
    if (
      /\/team\//g.test(uri) ||
      /\/partner\-dashboard\//g.test(uri) ||
      /\/admin\//.test(uri)
    ) {
      return "/login";
    }

    return `/login?redirect=${uri}`;
  }

  return "/login";
};
