/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  letter: "d" | "i" | "s" | "c";
  value: number;
}

export default (props: Props) => {
  return (
    <div
      className={cx(
        "cc-disc-item-badge",
        getLetterStyling(props.letter),
        props.value < 0 && "disc-item-negative",
        props.className
      )}
      style={props.style}
    >
      <p className="cc-disc-item-letter">{props.letter.toUpperCase()}</p>
      <p className="cc-disc-item-value">{props.value.toFixed(1)}</p>
    </div>
  );
};

const getLetterStyling = (letter: "d" | "i" | "s" | "c") => {
  switch (letter) {
    case "d":
      return "cc-disc-item-d";
    case "i":
      return "cc-disc-item-i";
    case "s":
      return "cc-disc-item-s";
    case "c":
      return "cc-disc-item-c";
    default:
      return "";
  }
};
