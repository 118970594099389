/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import {
  StoreState as AppStoreState,
  AppStoreDispatch,
} from "../../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "../../data/stores/AdminStore";
import {
  Button,
  ContextMenu,
  Icon,
  TextHighlighter,
} from "../../../../components/common/";
import { iconType } from "../../../../components/common/icon/typings";

import "./TeamTreeItem.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AppStoreDispatch: AppStoreDispatch;
  AppStoreState: AppStoreState;
  chooseTeam: (teamID: number) => void;
  childTeams: Tyto.Team[];
  curDomainID: number;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  forceSelfOnly?: boolean;
  hasChildTeams: boolean;
  previewPersonID?: number;
  searchTerm?: string;
  selectedTeamID: number;
  showChildTeams: boolean;
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
  updateShowChildTeams: () => void;
  depth: number;
}

interface Props {}

export default (props: Props) => {
  const [showAddTeam, updateShowAddTeam] = React.useState<boolean>(false);

  //   const childTeams = getNonDomainChildTeams({
  //     team: props.team,
  //     teamsStructured: props.teamsStructured,
  //   });
  //   const childTeams = props.team.iPath
  //     ? props.teamsStructured[`${props.team.iPath}${props.team.teamID},`]
  //     : [];

  const isSelected = props.team.teamID === props.selectedTeamID;

  //   const teamMembers = props.discMiniByTeam[props.team.teamID]
  //     ? Object.values(props.discMiniByTeam[props.team.teamID])
  //     : undefined;

  return (
    <div
      className={cx(
        "admin-teams-team-structure-iteminfo-cont",
        props.team.teamType === "ocDOMAIN" &&
          "admin-teams-team-structure-iteminfo-cont-for-domain",
        props.selectedTeamID === props.team.teamID &&
          "admin-teams-team-structure-iteminfo-cont-selected"
      )}
    >
      <button
        className="admin-teams-team-structure-iteminfo-title-cont"
        onClick={() => props.chooseTeam(props.team.teamID)}
      >
        <Icon
          className="admin-teams-team-structure-iteminfo-icon"
          size={22}
          icon={getIconType({
            team: props.team,
            hasNestedTeams: props.childTeams && !!props.childTeams.length,
            asSolid: !!(props.team.teamID === props.curDomainID || isSelected),
          })}
        />

        <div className="admin-teams-team-structure-iteminfo-title-inner-cont">
          <p className="admin-teams-team-structure-iteminfo-name">
            <TextHighlighter
              highlightClassName=""
              searchTerm={props.searchTerm || ""}
              text={props.team.name}
              type="hightlight"
            />

            {props.team.isTeamToolsConfig && (
              <Icon
                className="admin-teams-team-structure-iteminfo-rules-icon"
                icon="arrow-alt-from-top"
                size={13}
              />
            )}
          </p>

          {props.childTeams && !!props.childTeams.length && (
            <p className="admin-teams-team-structure-iteminfo-childteams-count">
              {props.childTeams.length} direct sub-team
              {props.childTeams.length > 1 && "s"}
            </p>
          )}
        </div>
      </button>

      {props.team.teamType === "ocDOMAIN" && (
        <ContextMenu
          containerClassName="admin-teams-team-structure-iteminfo-contextmanu-cont"
          icon={{
            className: "admin-teams-team-structure-iteminfo-contextmanu-icon",
            size: 25,
            icon: "elipsis-h",
          }}
          menuItems={[
            {
              value: "Switch to Domain",
              uniqueKey: "switch-to-domain-button",
              onClick: () =>
                props.AppStoreDispatch({
                  payload: {
                    domainID: props.team.teamID,
                  },
                  type: "SET_TARGET_DOMAINID",
                }),
            },
          ]}
        />
      )}

      {props.hasChildTeams && (
        <Icon
          className=""
          buttonProps={{
            className: "admin-teams-team-structure-iteminfo-toggle-button",
            name: `${props.showChildTeams ? "Hide" : "Show"} Teams within ${
              props.team.name
            }`,
          }}
          isButton={true}
          onClick={props.updateShowChildTeams}
          icon={props.showChildTeams ? "minus" : "plus"}
          size={20}
        />
      )}
    </div>
  );
};

const getIconType = ({
  asSolid,
  hasNestedTeams,
  team,
}: {
  team: Tyto.Team;
  hasNestedTeams: boolean;
  asSolid?: boolean;
}): iconType => {
  switch (team.teamType) {
    case "ocTEAM":
      if (hasNestedTeams) {
        return asSolid ? "users-class-solid" : "users-class";
      }
      return asSolid ? "users-solid" : "users";
    case "ocDOMAIN":
      return asSolid ? "globe" : "globe";
    default:
      return "question";
  }
};
