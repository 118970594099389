/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { FileInput } from "../../../common/";

interface Props {
  curFile: File | null;
  importing: boolean;
  inputRef: any;
  onFileChange: (File?: File) => void;
}

export default (props: Props) => {
  return (
    <div
      className="modals-importusers-modal-input-cont"
      style={
        props.importing
          ? {
              opacity: 0,
              pointerEvents: "none"
            }
          : undefined
      }
    >
      <FileInput
        acceptedFileTypes=".csv"
        className={cx(
          "modals-importusers-modal-input",
          !props.curFile && "modals-importusers-modal-input-showing",
          props.curFile && "modals-importusers-modal-input-hidden"
        )}
        innerRef={props.inputRef}
        label="Import Users Modal Import"
        onChange={files => {
          if (Array.isArray(files)) {
            props.onFileChange(files[0]);
          }
        }}
      />

      {props.curFile && (
        <h4 className="modals-importusers-modal-input-file-name">
          {props.curFile.name}
        </h4>
      )}
    </div>
  );
};
