import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
// import LogRocket from "logrocket";
import mixpanel from "mixpanel-browser";

import { IS_DEV_ENV } from "./data/constants/";
import { queryClient } from "./data/query-client";
import { StoreContextProvider } from "./data/stores/AppStore";
import { StoreContextProvider as GeneralStoreContextProvider } from "./data/stores/GeneralStore";
import { StoreContextProvider as DirectoryStoreContextProvider } from "./interfaces/directory/data/stores/DirectoryStore";
import { StoreContextProvider as AdminStoreContextProvider } from "./interfaces/admin/data/stores/AdminStore";

// import Router from "./router/";
import RouterWrapper from "./router/wrapper";

import "./styles/Main.scss";

// LogRocket.init("udp3vb/tti");
!IS_DEV_ENV && mixpanel.init("fc74e144837331e015436972122a0d66");

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GeneralStoreContextProvider>
          <StoreContextProvider>
            <DirectoryStoreContextProvider>
              <AdminStoreContextProvider>
                <div className="App" id="main">
                  <RouterWrapper />
                </div>
              </AdminStoreContextProvider>
            </DirectoryStoreContextProvider>
          </StoreContextProvider>
        </GeneralStoreContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
