/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import "./style.scss";

interface Props {
  highlightedTextStyle?: React.CSSProperties;
  highlightClassName: string;
  ignoreCase?: boolean;
  searchTerm: string;
  text: string;
  type: "hightlight" | "bold" | "italicize";
}

export default (props: Props) => {
  if (!props.searchTerm) {
    return <span>{props.text}</span>;
  }

  const ignoreCase =
    typeof props.ignoreCase === "undefined" ? true : props.ignoreCase;

  const results = renderResults({ ...props, ignoreCase });
  const hasResults = Array.isArray(results) && results.length > 0;

  if (hasResults) {
    return <>{results}</>;
  }

  return <span />;
};

const renderResults = ({
  highlightClassName,
  ignoreCase,
  searchTerm,
  text,
  type
}: Props) => {
  const searchTermRE = new RegExp(
    `(${_.escapeRegExp(searchTerm)})`,
    ignoreCase ? "i" : ""
  );

  const matchedContent = text
    .split(searchTermRE)
    .filter(Boolean)
    .map((token, idx) => {
      if (searchTermRE.test(token)) {
        // * Should perhaps be split into own function > switch statement
        if (type === "bold") {
          return (
            <b key={idx} className={highlightClassName}>
              {token}
            </b>
          );
        } else if (type === "italicize") {
          return (
            <i key={idx} className={highlightClassName}>
              {token}
            </i>
          );
        }

        return (
          <mark key={idx} className={highlightClassName}>
            {token}
          </mark>
        );
      }
      return <span key={idx}>{token}</span>;
    });

  return matchedContent;
};
