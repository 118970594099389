/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

type CheckBoxType = "square" | "circle";

export interface Props {
  className?: string;
  innerClassName?: string;
  checked: boolean;
  disabled?: boolean;
  onCheck: (nextValue: boolean) => void;
  size: number;
  style?: React.CSSProperties;
  type?: CheckBoxType;
}

export default ({
  className,
  checked,
  disabled,
  innerClassName,
  onCheck,
  size = 20,
  style = {},
  type = "square",
}: Props) => {
  return (
    <button
      className={cx(
        "cc-checkbox",
        disabled && "cc-checkbox-is-disabled",
        getTypeStyle(type),
        className
      )}
      disabled={disabled}
      onClick={() => onCheck(!checked)}
      style={{
        height: size,
        minHeight: size,
        maxHeight: size,
        width: size,
        minWidth: size,
        maxWidth: size,
        ...style,
      }}
    >
      <p
        className={cx(
          "cc-checkbox-inner",
          checked && "is-checked",
          innerClassName
        )}
      />
    </button>
  );
};

const getTypeStyle = (type: CheckBoxType) => {
  switch (type) {
    case "circle":
      return "cc-checkbox-circle";
    case "square":
    default:
      return "cc-checkbox-square";
  }
};
