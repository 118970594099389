/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className: string;
  size?: number;
  style?: React.CSSProperties;
}

export default ({ className, size = 25, style }: Props) => {
  const [animationTimerMS] = React.useState(() =>
    Math.floor(Math.random() * 3000 + 600)
  );

  return (
    <div
      className={cx(
        "cc-userthumb-cont",
        className,
        "placeholder-userthumb-cont"
      )}
      style={{
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
        maxHeight: size,
        maxWidth: size,
        animationDuration: `${animationTimerMS}ms`,
        ...(style || {})
      }}
    >
      {/* <p className="cc-userthumb-no-img">{initials}.</p> */}
    </div>
  );
};
