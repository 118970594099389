import PapaParse from "papaparse";

import TytoCalls from "../../../../data/tyto/";
import { Tyto } from "../../../../typings/tyto";

export const personKeys = [
  {
    label: "First Name",
    value: "givenName",
  },
  {
    label: "Last Name",
    value: "familyName",
  },
  {
    label: "Full Name",
    value: "__fullName__", // ? What to value ?
  },
  {
    label: "Outside ID",
    value: "outsideID",
  },
  // {
  //     label: "KVault Person ID",
  //     value: "userID"
  // },
  // {
  //   label: "Logon Name",
  //   value: "logonName"
  // },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone",
    value: "phone1",
  },
  {
    label: "Job Title",
    value: "jobTitle",
  },
  {
    label: "Address Line 1",
    value: "address1",
  },
  {
    label: "Address Line 2",
    value: "address2",
  },
  {
    label: "Country",
    value: "country",
  },
  {
    label: "State",
    value: "state",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Postal Code",
    value: "postalCode",
  },
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Terminate Date",
    value: "outsideTerminateDate",
  },
  {
    label: "Join Date",
    value: "outsideJoinDate",
  },
  {
    label: "Renewal Date",
    value: "outsideRenewalDate",
  },
];

export async function importUsers({
  onError,
  onSuccess,
  columnLabels,
  parsedCSV,
  primaryElementID,
}: {
  columnLabels: string[];
  onError: (err?: string) => void;
  onSuccess: (newUserIDs?: number[]) => void;
  parsedCSV: string[][];
  primaryElementID: number;
}) {
  if (!primaryElementID) {
    debugger;
    return;
  }

  const hasAtLeastOneColumn = columnLabels.some((label) => !!label);
  const hasValidIdentifier = columnLabels.some(
    (label) =>
      label === "logonName" ||
      label === "email" ||
      label === "outsideID" ||
      label === "userID"
  );

  if (!hasAtLeastOneColumn || !hasValidIdentifier) {
    // TODO
    return;
  }

  const formattedPeopleData = parsedCSV.reduce(
    (accum: Endpoints.Tyto.Person.PostParameters[], row) => {
      // * [1] - Iterate of labels and add truthy labels with their value to Person Object
      const person: { [x: string]: any } = columnLabels.reduce(
        (colLabelAccum: { [x: string]: any }, key, curIdx) => {
          if (!!key) {
            colLabelAccum[key] = row[curIdx];
          }

          return colLabelAccum;
        },
        {}
      );

      // * [2] - If logonName is undefined attempt to set it to email
      if (!person["logonName"] && person["email"]) {
        person["logonName"] = person["email"];
      }

      // * [3] - If Full Name is supplied override first and last names with value
      if (person["__fullName__"]) {
        const [firstName, lastName] = person["__fullName__"].split(" ");

        person["givenName"] = firstName || "";
        person["familyName"] = lastName || "";
      }

      // * [4] - If logonName now exists, add to accum, if not person will not be imported
      if (person["logonName"]) {
        accum.push(person as Endpoints.Tyto.Person.PostParameters);
      }

      return accum;
    },
    []
  );

  if (!formattedPeopleData.length) {
    return;
  }

  try {
    const personPOSTCalls = formattedPeopleData.map((newPerson) =>
      TytoCalls.Person.post({ ...newPerson, primaryElementID })
    );
    const resps = await Promise.all(personPOSTCalls);

    onSuccess();
  } catch (err) {
    console.log(err);

    const errorMsg = typeof err === "string" ? err : JSON.stringify(err);
    onError(errorMsg);
  }
}

export async function importUser({
  autoAddToTeam,
  domainID,
  onError,
  onSuccess,
  columnLabels,
  personData,
  primaryElementID,
  updatePerson,
  updateMissingMembership,
  setDifferences,
}: {
  autoAddToTeam: boolean;
  columnLabels: string[];
  domainID: number;
  onError: (err?: string) => void;
  onSuccess: (info: { personID?: number; msg: string }) => void;
  personData: string[];
  primaryElementID: number;
  updatePerson: (person?: Tyto.Person) => void;
  updateMissingMembership: (missingMembership: boolean) => void;
  setDifferences: (differences: string[]) => void;
}) {
  const formattedPersonData = formatUserForSave({
    columnLabels,
    personData,
    primaryElementID,
  });

  if (!formattedPersonData) {
    // TODO
    return;
  }

  const existingMatchingPerson = await findExistingPerson(
    domainID,
    formattedPersonData
  );

  if (existingMatchingPerson) {
    const differences = findKeysOfDifferences({
      person: existingMatchingPerson,
      personParams: formattedPersonData,
    });

    const hasMembershipToCurTeam = hasMembershipToTeam({
      person: existingMatchingPerson,
      teamID: primaryElementID,
    });

    if (!hasMembershipToCurTeam && autoAddToTeam) {
    }

    if (differences.length || (!hasMembershipToCurTeam && !autoAddToTeam)) {
      if (!hasMembershipToCurTeam) {
        updateMissingMembership(true);
      }
      setDifferences(differences);
      updatePerson(existingMatchingPerson);
    } else {
      if (!hasMembershipToCurTeam) {
        addUserToTeam({
          teamID: primaryElementID,
          userID: existingMatchingPerson.personID,
          onSuccess: () =>
            onSuccess({
              personID: existingMatchingPerson.personID,
              msg: "Matching User found and added to team.",
            }),
          onError,
        });
      } else {
        onSuccess({
          personID: existingMatchingPerson.personID,
          msg: "Matching User found, nothing to change.",
        });
      }
    }

    // updateExistingUsersData({
    //   onError,
    //   onSuccess,
    //   person: existingMatchingPerson,
    //   personParams: formattedPersonData
    // });
  } else {
    createNewUser({
      onError,
      onSuccess,
      primaryElementID,
      personParams: formattedPersonData,
    });
  }
}

export function formatUserForSave({
  columnLabels,
  personData,
  primaryElementID,
}: {
  columnLabels: string[];
  personData: string[];
  primaryElementID: number;
}) {
  if (!primaryElementID) {
    return undefined;
  }

  const hasAtLeastOneColumn = columnLabels.some((label) => !!label);
  const hasValidIdentifier = columnLabels.some(
    (label) =>
      label === "logonName" ||
      label === "email" ||
      label === "outsideID" ||
      label === "userID"
  );

  if (!hasAtLeastOneColumn || !hasValidIdentifier) {
    // TODO
    return undefined;
  }

  // * [1] - Iterate of labels and add truthy labels with their value to Person Object
  const person: { [x: string]: any } = columnLabels.reduce(
    (colLabelAccum: { [x: string]: any }, key, curIdx) => {
      if (!!key) {
        colLabelAccum[key] = personData[curIdx];
      }

      return colLabelAccum;
    },
    {}
  );

  // * [2] - If logonName is undefined attempt to set it to email
  if (!person["logonName"] && person["email"]) {
    person["logonName"] = person["email"];
  }

  // * [3] - If Full Name is supplied override first and last names with value
  if (person["__fullName__"]) {
    const [firstName, lastName] = person["__fullName__"].split(" ");

    person["givenName"] = firstName || "";
    person["familyName"] = lastName || "";
    person["__fullName__"] = undefined;
  }

  // * [4] - If logonName now exists, add to accum, if not person will not be imported
  if (!person["logonName"]) {
    return undefined;
  }

  return person as Endpoints.Tyto.Person.PostParameters;
}

export async function findExistingPerson(
  domainID: number,
  personParams: Endpoints.Tyto.Person.PostParameters
) {
  try {
    const { logonName, outsideID } = personParams;
    const personResp: any = await TytoCalls.Person.get({
      domainID,
      logonName,
      outsideID,
    });

    return personResp.person as Tyto.Person;
  } catch (err) {
    return undefined;
  }
}

export async function updateExistingUsersData({
  differingValues,
  onError,
  onSuccess,
  person,
  personParams,
}: {
  differingValues: string[];
  onError: (err?: string) => void;
  onSuccess: (info: { personID?: number; msg: string }) => void;
  person: Tyto.Person;
  personParams: Endpoints.Tyto.Person.PostParameters;
}) {
  if (differingValues.length) {
    onSuccess({ personID: person.personID, msg: "Nothing to Change." });
  }

  const params = differingValues.reduce((accum: { [x: string]: any }, key) => {
    accum[key] =
      personParams[key as keyof Endpoints.Tyto.Person.PostParameters];

    return accum;
  }, {});

  try {
    await TytoCalls.Person.put({
      personID: person.personID,
      ...(params as Omit<Endpoints.Tyto.Person.PutParameters, "personID">),
    });

    onSuccess({
      personID: (person && person.personID) || undefined,
      msg: "Account Updated",
    });
  } catch (err) {
    console.log(err);

    const errorMsg = typeof err === "string" ? err : JSON.stringify(err);
    onError(errorMsg);
  }
}

function hasMembershipToTeam({
  person,
  teamID,
}: {
  person: Tyto.Person;
  teamID: number;
}) {
  if (!person || !Array.isArray(person.teamMemberships)) {
    return false;
  }

  return person.teamMemberships.some((team) => team.TeamID === teamID);
}

export function findKeysOfDifferences({
  person,
  personParams,
}: {
  person: Tyto.Person;
  personParams: Endpoints.Tyto.Person.PostParameters;
}) {
  const differingValues = Object.keys(personParams).filter(
    (key) =>
      personParams[key as keyof Endpoints.Tyto.Person.PostParameters] &&
      personParams[key as keyof Endpoints.Tyto.Person.PostParameters] !==
        person[key as keyof Tyto.Person]
  );

  return differingValues;
}

export async function createNewUser({
  onError,
  onSuccess,
  primaryElementID,
  personParams,
}: {
  onError: (err?: string) => void;
  onSuccess: (info: { personID?: number; msg: string }) => void;
  primaryElementID: number;
  personParams: Endpoints.Tyto.Person.PostParameters;
}) {
  try {
    const { primaryElementID: suppliedPrimaryElementID, ...params } =
      personParams;

    const personResp: any = await TytoCalls.Person.post({
      primaryElementID: suppliedPrimaryElementID || primaryElementID,
      ...params,
    });

    onSuccess({
      personID: personResp ? personResp.personID : undefined,
      msg: "Account Created",
    });
  } catch (err) {
    console.log(err);

    const errorMsg = typeof err === "string" ? err : JSON.stringify(err);
    onError(errorMsg);
  }
}

export async function addUserToTeam({
  teamID,
  userID,
  onError,
  onSuccess,
}: {
  userID: number;
  teamID: number;
  onError: (err: string) => void;
  onSuccess: (msg: string) => void;
}) {
  try {
    const addResp = await TytoCalls.TeamMembership.Person.post({
      memberID: userID,
      teamID,
    });

    onSuccess("Account Updated");
  } catch (err) {
    // TODO:
    onError("Error while adding user to team.");
  }
}

const template = [
  {
    Email: "",
    ["Full Name"]: "",
    ["First Name"]: "",
    ["Family Name"]: "",
    ["Job Title"]: "",
    Phone: "",
    ["Address Line 1"]: "",
    ["Address Line 2"]: "",
    Country: "",
    State: "",
    City: "",
    ["Postal Code"]: "",
    outsideID: "",
    ["Join Date"]: "",
    ["Terminate Date"]: "",
    ["Renewal Date"]: "",
  },
];

export function startDownloadTemplate() {
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const csv = PapaParse.unparse(template);

    const exportedFilename = "teamtools-import-users-template.csv";

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", exportedFilename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
  }

  document.body.removeChild(link);
}
