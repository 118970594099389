import { ENDPOINT_R3_DISC_VENDOR_247_ASSESSMENT } from "../../constants/";
import { callWrapper, CallOpts } from "../utils";

import { AssessmentGetParams, AssessmentGetResponse } from "./types";

const endpoint = ENDPOINT_R3_DISC_VENDOR_247_ASSESSMENT;

export const Vendor247Assessment = {
  get(params: AssessmentGetParams, callOpts?: CallOpts) {
    return callWrapper(
      "get",
      endpoint,
      params,
      callOpts
    ) as Promise<AssessmentGetResponse>;
  },
};
