/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Tyto } from "../../../../../typings/tyto";
import { StoreContext as AppStoreContext } from "../../../../../data/stores/AppStore";
import {
  DISCValueRender,
  Icon,
  PlaceholderText,
} from "../../../../../components/common/";
import TytoCalls from "../../../../../data/tyto/";
import { URL_BASE } from "../../../../../data/constants/";
import {
  getSessionData,
  getLoggedInUserID,
} from "../../../../../data/storage/session";

import "./Downloads.scss";

interface Props {
  discMiniProfile?: Tyto.DISCProfileMini;
  isOwnProfile?: boolean;
}

const OUTLOOK_ADD_IN_URL =
  "https://appsource.microsoft.com/en-us/product/office/WA200001805";
const CHROME_EXTENSION_URL =
  "https://chrome.google.com/webstore/detail/teamtools-browser-extensi/jibpnbbjcanbhijfikdbofnniiangnbd";

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);

  const [errorMsg, updateErrorMsg] = React.useState("");
  const [activeReportID, updateActiveReportID] = React.useState(0);
  const [sessionKey, updateUpdateSessionKey] = React.useState(() => {
    const loggedInUserID = getLoggedInUserID();

    if (!loggedInUserID) {
      return "";
    }

    const sessionData = getSessionData(loggedInUserID);

    return _.get(sessionData, "sessionKey", "");
  });
  // const [fullDISCProfile, updateFullDISCProfile] = React.useState(null);
  const [hasLoaded, updateHasLoaded] = React.useState(false);
  const [downloadURL, updateDownloadURL] = React.useState("");

  React.useEffect(() => {
    const pdfLessonID = _.get(props, "discMiniProfile.pdfLessonID", 0);

    if (pdfLessonID && pdfLessonID !== activeReportID) {
      updateHasLoaded(false);
      updateActiveReportID(pdfLessonID);

      const pdfLessonFromState = _.get(
        AppStore,
        `state.pdfLessons[${pdfLessonID}]`,
        undefined
      );

      if (pdfLessonFromState) {
        updateHasLoaded(true);
        updateDownloadURL(grabEncodingPathURL(pdfLessonFromState) || "");

        return;
      }

      loadLesson({
        lessonID: pdfLessonID,
        onSuccess: (downloadURL: string) => {
          if (!downloadURL) {
            updateErrorMsg(
              "An asset download path was not found for the PDF Report."
            );
          }

          updateDownloadURL(downloadURL);
          updateHasLoaded(true);
        },
        onError: (msg: string) => {
          updateErrorMsg(msg);
          updateHasLoaded(true);
        },
      });
    } else if (!pdfLessonID) {
      updateErrorMsg("Access to PDF not found.");
      updateHasLoaded(true);
    }
  }, [props.discMiniProfile]);

  const styleKeyName = _.get(props.discMiniProfile, "styleKey3", undefined);

  return (
    <div className="directory-tabs-tab-section-account-section-cont profile-tab-downloads-cont">
      <ul className="profile-tab-downloads-list">
        <li className="profile-tab-downloads-list-item">
          <Icon icon="behavioral-style" size={44} />

          <div className="profile-tab-downloads-list-item-inner-cont">
            {props.discMiniProfile && (
              <DISCValueRender
                className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                isCompareValue={false}
                targetKey="pdfLessonID"
                discMiniProfile={props.discMiniProfile}
              >
                <>
                  {hasLoaded && (
                    <a
                      className={cx(
                        "profile-tab-downloads-list-item-download-button",
                        (!downloadURL || !sessionKey) && "cannot-download"
                      )}
                      href={
                        downloadURL && sessionKey
                          ? `https://${URL_BASE}${downloadURL}&sessionKey=${sessionKey}`
                          : ""
                      }
                      download="My-DISC-Results"
                      target="_blank"
                    >
                      Result PDF
                      <Icon
                        className="profile-tab-downloads-list-item-download-button-icon"
                        size={12}
                        icon="download"
                      />
                    </a>
                  )}

                  {!hasLoaded && !errorMsg && (
                    <PlaceholderText value="Result PDF" />
                  )}

                  {errorMsg && (
                    <p className="profile-tab-downloads-list-item-download-error-msg">
                      {errorMsg}
                    </p>
                  )}

                  <p className="profile-tab-downloads-list-item-desc">
                    The Result PDF is your exhaustive report. It contains the
                    results of your assessment including:
                    <ul className="profile-tab-downloads-list-item-desc-list">
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Scores
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Graphs
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - An overview of your style
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Motivations
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Challenge Areas
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Motivators
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Communication Tips
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - Some tips with how you can best communicate with other
                        styles
                      </li>
                      <li className="profile-tab-downloads-list-item-desc-list-item">
                        - An Action Plan for yourself
                      </li>
                    </ul>
                  </p>
                </>
              </DISCValueRender>
            )}
          </div>
        </li>
        <li className="profile-tab-downloads-list-item">
          <Icon icon="crane-template" size={52} />

          <div className="profile-tab-downloads-list-item-inner-cont">
            {props.discMiniProfile && (
              <DISCValueRender
                className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                isCompareValue={false}
                targetKey="pdfLessonID"
                discMiniProfile={props.discMiniProfile}
              >
                <>
                  {styleKeyName ? (
                    <a
                      className={cx(
                        "profile-tab-downloads-list-item-download-button",
                        !styleKeyName && "cannot-download"
                      )}
                      target="_blank"
                      href={`https://teamtools.com/storage/app/media/cranes/Team_Tools_Crane_${styleKeyName.toUpperCase()}.pdf`}
                    >
                      Crane Template
                      <Icon
                        className="profile-tab-downloads-list-item-download-button-icon"
                        size={12}
                        icon="download"
                      />
                    </a>
                  ) : (
                    <PlaceholderText value="Crane Template" />
                  )}

                  <p className="profile-tab-downloads-list-item-desc">
                    The Crane Template is a printable Sheet for folding an
                    Origami Crane specific to your DISC Style. You can fins
                    instructions as to how to fold your crane in the TeamTools
                    website at{" "}
                    <a
                      className="profile-tab-downloads-list-item-desc-link"
                      href="https://www.teamtools.com/bird"
                      target="_blank"
                    >
                      https://www.teamtools.com/bird
                    </a>
                  </p>
                </>
              </DISCValueRender>
            )}
          </div>
        </li>

        <li className="profile-tab-downloads-list-item downloads-list-item-split">
          <div className="profile-tab-downloads-list-item-inner-split-cont">
            <a href={OUTLOOK_ADD_IN_URL} target="_blank">
              <img
                className="profile-tab-downloads-list-item-img"
                src="/outlook-logo.png"
              />
            </a>

            <div className="profile-tab-downloads-list-item-inner-cont">
              <a
                className={cx(
                  "profile-tab-downloads-list-item-download-button"
                )}
                target="_blank"
                href={OUTLOOK_ADD_IN_URL}
              >
                TeamTools for Outlook
                <Icon
                  className="profile-tab-downloads-list-item-download-button-icon"
                  size={12}
                  icon="external-link"
                />
              </a>

              <p className="profile-tab-downloads-list-item-desc">
                For desktop versions of Outlook, find us in the Add-In store or
                contact your administrator. You can find more instructions on
                installing the Outlook Add-In in the TeamTools Website{" "}
                <a
                  className="profile-tab-downloads-list-item-desc-link"
                  href="https://www.teamtools.com/blog/teamtools-for-outlook-available"
                  target="_blank"
                >
                  Here
                </a>
              </p>
            </div>
          </div>

          <div className="profile-tab-downloads-list-item-inner-split-cont">
            <a href={CHROME_EXTENSION_URL} target="_blank">
              <img
                className="profile-tab-downloads-list-item-img"
                src="/chrome-logo.png"
              />
            </a>

            <div className="profile-tab-downloads-list-item-inner-cont">
              <a
                className={cx(
                  "profile-tab-downloads-list-item-download-button"
                )}
                target="_blank"
                href={CHROME_EXTENSION_URL}
              >
                TeamTools for Gmail
                <Icon
                  className="profile-tab-downloads-list-item-download-button-icon"
                  size={12}
                  icon="external-link"
                />
              </a>

              <p className="profile-tab-downloads-list-item-desc">
                The TeamTools Browser Extension provides all the power of
                TeamTools inside Gmail. The Chrome extension is available on all
                Chromium based Browsers including Chrome, Edge, Brave, and many
                more!
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

const loadLesson = async ({
  lessonID,
  onSuccess,
  onError,
}: {
  lessonID: number;
  onSuccess: (profile: any) => void;
  onError: (msg: string) => void;
}) => {
  if (!lessonID) {
    onError("Proper information not supplied (PDF Identifier not found).");
    return;
  }

  try {
    const lessonResp = await TytoCalls.Lesson.get({ lessonID });

    // const downloadURL = _.get(
    //   lessonResp,
    //   "lesson.assets[0].encodings[0].pathURL",
    //   ""
    // );

    const downloadURL = grabEncodingPathURL(lessonResp.lesson);

    onSuccess(downloadURL);
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            _.get(err, "error.technical", "Could not load PDF Report.")
          )
    );
  }
};

const grabEncodingPathURL = (lesson: any) => {
  if (!lesson || !lesson.assets) {
    return "";
  }

  const originalEncodings = lesson.assets
    .map((asset: any) =>
      _.get(asset, "encodings", []).find(
        (enc: any) => enc.encodingType === "ocORIGINAL"
      )
    )
    .filter((enc: any) => !!enc);

  const pathURLs: string[] = (originalEncodings || []).map(
    (enc: any) => enc.pathURL
  );

  return pathURLs[0] || "";
};

const loadFullProfile = async ({
  userID,
  onSuccess,
  onError,
}: {
  userID: number;
  onSuccess: (profile: any) => void;
  onError: (msg: string) => void;
}) => {
  if (!userID) {
    onError("Proper information not supplied.");
    return;
  }

  try {
    const resp = await TytoCalls.DISCProfiles.get({ personIDs: `${userID}` });

    onSuccess(resp.discProfiles && (resp.discProfiles[0] as any));
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Could not load Information for Downloads.")
    );
  }
};
