/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import NewAccountForm from "./subcomponents/NewAccountForm";
import { Tyto } from "../../../typings/tyto";

import "./style.scss";

interface Props {
  className?: string;
  overlayClassName?: string;
  isOpen?: boolean;
  loggedInUserRoleID?: number;
  modalStyle?: React.CSSProperties;
  onAccountCreation: (domainID: number) => void;
  onDismiss?: () => void;
  overlayStyle?: React.CSSProperties;
  primaryElementID?: number;
  parentDomain?: Tyto.Team;
  uploadURL?: string;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className={cx("newaccount-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      <NewAccountForm
        onAccountCreation={props.onAccountCreation}
        onDismiss={props.onDismiss}
        loggedInUserRoleID={props.loggedInUserRoleID}
        parentDomain={props.parentDomain}
        primaryElementID={props.primaryElementID}
        uploadURL={props.uploadURL}
      />
    </AgnosticModal>
  );
};
