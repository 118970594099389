/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Button, Icon, Input, Select } from "../../../components/common";
import { Props as ButtonProps } from "../../../components/common/button/";

import { SearchFilter } from "..";

import "./SearchBar.scss";

type View = "list" | "tree";

interface Props {
  className?: string;
  filter: string;
  filterOptions: {
    label: string;
    value: string;
  }[];
  hideClear?: boolean;
  noSpace?: boolean;
  placeholder?: string;
  searchTerm: string;
  searchButton?: ButtonProps;
  updateFilter: (value: SearchFilter) => void;
  updateSearchTerm: (value: string) => void;
}

export default (props: Props) => {
  // const [searchTerm, updateSearchTerm] = React.useState("");
  // const [selectFilter, updateSelectFilter] = React.useState(props.filterOptions[0].value);
  const [view, updateView] = React.useState("list");

  return (
    <section className={cx("teams-search-bar", props.className)}>
      <Input
        containerClassName={cx(
          "teams-search-bar-search-input-cont",
          props.noSpace && "teams-search-bar-search-input-cont-no-space"
        )}
        hideClear={props.hideClear === undefined ? true : props.hideClear}
        iconLeft="search"
        iconProps={{
          className: "teams-search-bar-search-input-icon",
        }}
        onChange={(value) => props.updateSearchTerm(value)}
        onEnter={
          props.searchButton
            ? () => {
                if (
                  props.searchButton &&
                  !props.searchButton.disabled &&
                  props.searchButton.onClick
                ) {
                  props.searchButton.onClick();
                }
              }
            : undefined
        }
        placeholder={props.placeholder || "Find your colleague"}
        value={props.searchTerm}
      />

      {props.searchButton && (
        <div className="teams-search-bar-search-button-cont">
          <Button
            className="teams-search-bar-search-button"
            {...props.searchButton}
          />
        </div>
      )}

      <Select
        containerClassName={cx(
          "director-search-bar-select-cont",
          props.noSpace && "director-search-bar-select-cont-no-space"
        )}
        defaultValue={props.filterOptions[0].value}
        name="People View Filter"
        onChange={(value) => props.updateFilter(`${value}` as SearchFilter)}
        options={props.filterOptions}
        placeholder="Select Filter"
        value={props.filter}
      />

      <div className="teams-search-bar-view-buttons-cont">
        <button
          className={cx(
            "teams-search-bar-view-button-cont",
            view === "list" && "is-selected"
          )}
          onClick={() => {
            updateView("list");
          }}
        >
          <Icon
            className="teams-search-bar-view-button"
            icon="list-ul"
            size={16}
          />
        </button>
        <button
          className={cx(
            "teams-search-bar-view-button-cont",
            view === "tree" && "is-selected"
          )}
          onClick={() => {
            updateView("tree");
          }}
        >
          <Icon
            className="teams-search-bar-view-button"
            icon="angle-down"
            size={16}
          />
        </button>
      </div>
    </section>
  );
};
