/*
  * Component Description
*/
import * as React from "react";
import * as _ from "lodash";
import { StoreAction } from "../../../../typings";
// import {
//   mapMiniProfilesByEmails,
//   mapPersonEmailsByPersonIDs
// } from "../utils/helpers";
// import {
//   // getSessionKey,
//   getSessionData,
//   setSessionData
// } from "../utils/";
import { Tyto } from "../../../../typings/tyto";

export interface DirectoryProps {
    state?: StoreState;
    dispatch?: React.Dispatch<StoreAction<RecipientActionType>>;
}

export interface StoreState {
    discMiniProfiles: Tyto.DISCProfileMini[];
    discProfiles: Tyto.DISCInfo[];
}

type RecipientActionType =
    | "DIRECTORY_USERS_LOADED";

let StoreContext: React.Context<DirectoryProps> = React.createContext({});

let initialState: StoreState = {
    discMiniProfiles: [],
    discProfiles: []
};

let reducer = (state: StoreState, action: StoreAction<RecipientActionType>) => {
    console.log(action);

    switch (action.type) {
        case "DIRECTORY_USERS_LOADED":
            if (action.payload && action.payload.users) {
                const discProfiles = [
                    ...state.discMiniProfiles,
                    ...(action.payload.users || [])
                ];

                return {
                    ...state,
                    discProfiles: _.uniqBy(discProfiles, "personID")
                }
            }

            return state;
        default:
            return state;
    }
};

function StoreContextProvider(props: any) {
    // [A]
    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { state, dispatch };

    // [B]
    return (
        <StoreContext.Provider value={value}>
            {props.children}
        </StoreContext.Provider>
    );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };