import * as React from "react";
import { Button } from "../../../components/common/";

import type { Tyto } from "../../../typings/tyto";

import "./LandingPage.scss";

interface Props {
  discProfileMini?: Tyto.DISCProfileMini;
  loggedInUserID: number;
  onContinue: () => void;
}

const RestartPage = ({ onContinue }: Props) => {
  return (
    <article className="assessment-taker-landing">
      <h2 className="assessment-taker-landing-title title-font">
        DISC Assessment
      </h2>

      <p className="assessment-taker-landing-text">
        It Appears your results were possibly incoherent and otherwise
        inconclusive.
      </p>

      <p className="assessment-taker-landing-text">
        It can be difficult to decide how to answer the questions as one thinks
        about the different contexts in life: family, work, social settings,
        etc.. For purposes of this Assessment, please think about yourself in
        your Work environment; what are your tendencies for how you interact
        with your peers? How do you think and behave at work? What interactions
        with others fuel you, and what kind of interactions make you feel
        drained?
      </p>

      <footer className="assessment-taker-landing-footer">
        <Button
          className="assessment-taker-landing-footer-btn"
          onClick={onContinue}
          value="Restart Assessment"
        />
      </footer>
    </article>
  );
};

export default RestartPage;
