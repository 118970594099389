/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../typings/tyto";
import AgnosticModal from "../agnostic";
import DomainMessage from "./DomainMessage";

import "./style.scss";

interface Props {
  domainID: number;
  isOpen: boolean;
  onDismiss: () => void;
  messageType: string;
  title: string;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className="modals-domainmessageedit-modal-main-cont"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-domainmessageedit-modal-overlay"
    >
      <DomainMessage
        domainID={props.domainID}
        messageType={props.messageType}
        onDismiss={props.onDismiss}
        title={props.title}
      />
    </AgnosticModal>
  );
};
