/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import TytoCalls from "../../../../data/tyto/";
import { Button, Icon, Select, ToggleSection } from "../../../common/";
import { Tyto } from "../../../../typings/tyto";
// import WebSocketClient from "../../../../data/websocket";

import { getTeamConfigValues } from "../utils/";

import "./AccessLevels.scss";
import { parsePointsAttribute } from "fabric/fabric-impl";

// mayTakeDisc: true
// mayImportDisc: true
// mayTakeBasicTrain: true
// mayTakeAdvTrain: true
// hasBasicViewDisc: true
// hasAdvViewDisc: true
// hasPermitChange: true
// hasGrantPermitChange: true

const milestones = [
  {
    label: "Immediately",
    value: "onInitialize",
  },
  {
    label: "After Assessment",
    value: "onDiscComplete",
  },
  {
    label: "After Training",
    value: "onBasicTrainingComplete",
    hide: true,
  },
  {
    label: "After Advanced Training",
    value: "onAdvTrainingComplete",
    hide: true,
  },
  {
    label: "When Manually Granted",
    value: "manual-permission",
  },
];

const configItems = [
  {
    label: "Can Take Assessment",
    value: "mayTakeDisc",
    exceptions: ["onDiscComplete"],
  },
  {
    label: "Can Import DISC",
    value: "mayImportDisc",
    hide: true,
  },
  {
    label: "Can View DISC Results",
    value: "hasBasicViewDisc",
  },
  {
    label: "Can View Advanced DISC Results",
    value: "hasAdvViewDisc",
    hide: true,
  },
  {
    label: "Can Take Training",
    value: "mayTakeBasicTrain",
    exceptions: ["onBasicTrainingComplete", "onAdvTrainingComplete"],
    hide: true,
  },
  {
    label: "Can Take Advanced Training",
    value: "mayTakeAdvTrain",
    exceptions: ["onAdvTrainingComplete"],
    hide: true,
  },
  // {
  //   label: "",
  //   value: "hasPermitChange"
  // },
  // {
  //   label: "",
  //   value: "hasGrantPermitChange"
  // },
];

interface Props {
  closeAtStart?: boolean;
  onConfigurationUpdate: (teamID: number) => void;
  personalDISCMini?: Tyto.DISCProfileMini;
  titleOverride?: string;
  team?: Tyto.Team;
  teamWithConfiguration?: Tyto.TeamGet;
  teamID: number;
}

export default (props: Props) => {
  const [saving, updateSaving] = React.useState(false);
  const [originalConfigValues, updateOriginalConfigValues] = React.useState<{
    [x: string]: string;
  } | null>(null);
  const [configValues, updateConfigValues] = React.useState<{
    [x: string]: string;
  } | null>(null);
  const [error, updateError] = React.useState("");
  const [resetLogicError, updateResetLogicError] = React.useState("");
  const [resetting, updateResetting] = React.useState(false);

  React.useEffect(() => {
    // if (props.teamWithConfiguration) {
    const newValues = getValues(props.teamWithConfiguration);

    updateConfigValues(newValues);
    updateOriginalConfigValues(newValues);
    updateSaving(false);
    updateResetting(false);
    // }
  }, [props.teamWithConfiguration, props.teamID]);

  const changesExist = detectChanges(originalConfigValues, configValues);
  const loggedInUserHasChangePermissions = !!_.get(
    props.personalDISCMini,
    "teamToolsPermit.hasPermitChange",
    false
  );
  const configHasLoaded = !!props.teamWithConfiguration;

  return (
    <ToggleSection
      title={props.titleOverride || "Report options and access levels:"}
      className="meta-super-sidepane-toggle-section"
      closedAtStart={props.closeAtStart}
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        return (
          <section className="meta-super-sidepane-accesslevels-main-cont">
            {error && <p style={{ color: "red" }}>{error}</p>}

            {
              configValues &&
                configItems.map((configItem) =>
                  configItem.hide ? null : (
                    <div
                      className="meta-super-sidepane-accesslevels-inner-cont"
                      key={configItem.value}
                    >
                      <p className="meta-super-sidepane-accesslevels-inner-select-label">
                        <Icon
                          className="meta-super-sidepane-accesslevels-inner-label-icon"
                          icon="info-circle"
                          size={12}
                        />
                        {configItem.label}
                      </p>

                      <Select
                        canClear={false}
                        containerClassName="meta-super-sidepane-accesslevels-inner-select-cont"
                        disabled={
                          !configHasLoaded || !loggedInUserHasChangePermissions
                        }
                        className="meta-super-sidepane-accesslevels-inner-select"
                        omitFilter={true}
                        options={milestones.filter(
                          (milestone) =>
                            !milestone.hide &&
                            (!Array.isArray(configItem.exceptions)
                              ? true
                              : configItem.exceptions.every(
                                  (exception) => exception !== milestone.value
                                ))
                        )}
                        onChange={(newVal) => {
                          if (configValues) {
                            updateConfigValues({
                              ...configValues,
                              [configItem.value]: newVal as any,
                            });
                          }
                        }}
                        placeholder="Choose.."
                        value={
                          (configValues && configValues[configItem.value]) || ""
                        }
                      />
                    </div>
                  )
                )

              // <ul className="meta-super-sidepane-accesslevels-grid">
              //   <li
              //     className="meta-super-sidepane-accesslevels-grid-row meta-super-sidepane-accesslevels-grid-row-labels-row"
              //     style={{ minWidth: `${configItems.length * 50 + 250}px` }}
              //   >
              //     <div className="meta-super-sidepane-accesslevels-grid-column meta-super-sidepane-accesslevels-grid-column-first-column" />

              //     {milestones.map(milestone =>
              //       milestone.hide ? null : (
              //         <div className="meta-super-sidepane-accesslevels-grid-column">
              //           <span className="meta-super-sidepane-accesslevels-grid-column-label">
              //             {milestone.label}
              //           </span>
              //         </div>
              //       )
              //     )}
              //   </li>

              //   {configItems.map(item =>
              //     item.hide ? null : (
              //       <li
              //         className={cx(
              //           "meta-super-sidepane-accesslevels-grid-row",
              //           configValues &&
              //             originalConfigValues &&
              //             configValues[item.value] !==
              //               originalConfigValues[item.value] &&
              //             "row-has-change"
              //         )}
              //         style={{ minWidth: `${configItems.length * 50 + 250}px` }}
              //         key={`item-${item.value}`}
              //       >
              //         <div className="meta-super-sidepane-accesslevels-grid-column meta-super-sidepane-accesslevels-grid-column-first-column">
              //           {item.label}
              //         </div>

              //         {milestones.map(milestone =>
              //           milestone.hide ? null : (
              //             <div
              //               className="meta-super-sidepane-accesslevels-grid-column"
              //               key={`item-${item.value}-milestone-${milestone.value}`}
              //             >
              //               {Array.isArray(item.exceptions) &&
              //               item.exceptions.some(
              //                 milestoneKey => milestoneKey === milestone.value
              //               ) ? (
              //                 <Icon
              //                   className="meta-super-sidepane-accesslevels-grid-column-notallowed-icon"
              //                   icon="ban"
              //                   size={14}
              //                 />
              //               ) : (
              //                 <input
              //                   checked={
              //                     configValues &&
              //                     configValues[item.value] === milestone.value
              //                       ? true
              //                       : false
              //                   }
              //                   name={item.value}
              //                   type="radio"
              //                   onClick={e => {
              //                     if (configValues) {
              //                       updateConfigValues({
              //                         ...configValues,
              //                         [item.value]: milestone.value
              //                       });
              //                     }
              //                   }}
              //                   onChange={e => {
              //                     // e.preventDefault();
              //                   }}
              //                 />
              //               )}
              //               {/* {configValues &&
              //         configValues[item.value] === milestone.value
              //           ? 1
              //           : 0} */}
              //             </div>
              //           )
              //         )}
              //       </li>
              //     )
              //   )}
              // </ul>
            }

            <div className="meta-super-sidepane-accesslevels-buttons-cont">
              <Button
                className="meta-super-sidepane-accesslevels-button"
                disabled={
                  !loggedInUserHasChangePermissions || saving || !changesExist
                }
                onClick={() => {
                  if (configValues && originalConfigValues) {
                    updateConfigValues(originalConfigValues);
                  }
                }}
                shape="square"
                type="hollow"
                value="Reset"
              />

              <Button
                className="meta-super-sidepane-accesslevels-button"
                disabled={
                  !configHasLoaded ||
                  !loggedInUserHasChangePermissions ||
                  saving ||
                  !changesExist
                }
                onClick={() => {
                  if (!configValues) {
                    return;
                  }

                  updateSaving(true);

                  saveConfigChanges({
                    teamID: props.team ? props.team.teamID : 0,
                    updates: configValues,
                    onError: (msg: string) => updateError(msg),
                    onSuccess: () => {
                      // updateSaving(false);
                      props.onConfigurationUpdate(
                        props.team ? props.team.teamID : 0
                      );
                    },
                  });
                }}
                shape="square"
                type="color"
                value={saving ? "Saving..." : "Save Changes"}
              />
            </div>

            {resetLogicError && (
              <p style={{ color: "red", display: "block", width: "100%" }}>
                {resetLogicError}
              </p>
            )}

            {props.teamWithConfiguration &&
              props.teamWithConfiguration.teamToolsConfig && (
                <div className="meta-super-sidepane-accesslevels-detectChanges(originalConfigValues, configValues)-cont">
                  <Button
                    className="meta-super-sidepane-accesslevels-reset-button"
                    disabled={resetting}
                    onClick={() => {
                      updateResetting(true);

                      resetLogic({
                        teamID: props.teamWithConfiguration
                          ? props.teamWithConfiguration.teamID
                          : 0,
                        onError: (msg) => {
                          updateResetLogicError(msg);
                          updateResetting(false);
                        },
                        onSuccess: () => {
                          props.onConfigurationUpdate(
                            props.teamWithConfiguration
                              ? props.teamWithConfiguration.teamID
                              : 0
                          );
                        },
                      });
                    }}
                    value={resetting ? "Resetting Logic..." : "Reset Logic"}
                    shape="square"
                    type="hollow"
                  />

                  {/* <span className="meta-super-sidepane-accesslevels-button-desc">
                This will delete current access settings and default to the
                settings in <b>{"[Domain Name]"}</b>
              </span> */}
                </div>
              )}
          </section>
        );
      }}
    />
  );
};

const detectChanges = (
  originalConfigValues: {
    [x: string]: string;
  } | null,
  configValues: {
    [x: string]: string;
  } | null
) => {
  if (!originalConfigValues || !configValues) {
    return false;
  }

  return configItems.some(
    (configItem) =>
      originalConfigValues[configItem.value] !== configValues[configItem.value]
  );
};

const getValues = (team?: Tyto.TeamGet) => {
  const {
    onInitialize,
    onDiscComplete,
    onBasicTrainingComplete,
    onAdvTrainingComplete,
  } = getTeamConfigValues(team || ({} as any));

  const values = configItems.reduce(
    (accum: { [x: string]: string }, configItem) => {
      accum[configItem.value] = getValue(
        {
          onInitialize,
          onDiscComplete,
          onBasicTrainingComplete,
          onAdvTrainingComplete,
        },
        configItem.value
      );

      return accum;
    },
    {}
  );

  return values;
};

const getValue = (
  {
    onInitialize,
    onDiscComplete,
    onBasicTrainingComplete,
    onAdvTrainingComplete,
  }: {
    onInitialize: Tyto.TeamToolsPermissions;
    onDiscComplete: Tyto.TeamToolsPermissions;
    onBasicTrainingComplete: Tyto.TeamToolsPermissions;
    onAdvTrainingComplete: Tyto.TeamToolsPermissions;
  },
  key: string
) => {
  if (onInitialize[key as keyof Tyto.TeamToolsPermissions]) {
    return "onInitialize";
  } else if (onDiscComplete[key as keyof Tyto.TeamToolsPermissions]) {
    return "onDiscComplete";
  } else if (onBasicTrainingComplete[key as keyof Tyto.TeamToolsPermissions]) {
    return "onBasicTrainingComplete";
  } else if (onAdvTrainingComplete[key as keyof Tyto.TeamToolsPermissions]) {
    return "onAdvTrainingComplete";
  }

  return "manual-permission";
};

const saveConfigChanges = async ({
  teamID,
  updates,
  onError,
  onSuccess,
}: {
  teamID: number;
  updates: { [x: string]: string };
  onError: (msg: string) => void;
  onSuccess: () => void;
}) => {
  if (!teamID || !updates) {
    return;
  }

  try {
    const formattedUpdates = getTeamConfigValues({
      teamToolsConfig: formatUpdates(updates),
    } as any);
    debugger;
    const resp = await TytoCalls.TeamTools.Config.put({
      teamRoot: teamID,
      ...(formattedUpdates as any as {
        onInitialize: Tyto.TeamToolsPermissions;
        onDiscComplete: Tyto.TeamToolsPermissions;
        onBasicTrainingComplete: Tyto.TeamToolsPermissions;
        onAdvTrainingComplete: Tyto.TeamToolsPermissions;
      }),
    });

    // WebSocketClient.announceUpdates

    onSuccess();
  } catch (err) {
    debugger;
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            "An Error Occurred. Could not update Team Configuration"
          )
    );
  }
};

const formatUpdates = (updates: { [x: string]: string }) => {
  const asArr = Object.entries(updates);
  // * Where 'val' is a value from configItems and 'key' is a value from milestones
  const values = asArr.reduce(
    (
      accum: {
        onInitialize: {
          [x: string]: boolean;
        };
        onDiscComplete: {
          [x: string]: boolean;
        };
        onBasicTrainingComplete: {
          [x: string]: boolean;
        };
        onAdvTrainingComplete: {
          [x: string]: boolean;
        };
      },
      [key, val]
    ) => {
      if (val !== "manual-permission") {
        // accum[
        //   val as
        //     | "onInitialize"
        //     | "onDiscComplete"
        //     | "onBasicTrainingComplete"
        //     | "onAdvTrainingComplete"
        // ][key as any] = true;
        const indexOfVal = milestones.findIndex(
          (milestone) => milestone.value === val
        );

        if (indexOfVal >= 0) {
          milestones.forEach((milestone, curIdx) => {
            if (
              curIdx >= indexOfVal &&
              milestone.value !== "manual-permission"
            ) {
              accum[
                milestone.value as
                  | "onInitialize"
                  | "onDiscComplete"
                  | "onBasicTrainingComplete"
                  | "onAdvTrainingComplete"
              ][key as any] = true;
            }
          });
        }
      }

      return accum;
    },
    {
      onInitialize: {},
      onDiscComplete: {},
      onBasicTrainingComplete: {},
      onAdvTrainingComplete: {},
    }
  );

  return values;
};

const resetLogic = async ({
  teamID,
  onError,
  onSuccess,
}: {
  teamID: number;
  onError: (msg: string) => void;
  onSuccess: () => void;
}) => {
  if (!teamID) {
    return;
  }

  try {
    // const dataAsJSONString = JSON.stringify({ teamRoot: teamID });
    // const dataAsJSON = JSON.parse(dataAsJSONString);

    const resp = await TytoCalls.TeamTools.Config.delete(
      { teamRoot: teamID },
      { paramsAsData: true }
    );

    onSuccess();
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Error occurred; Could Not Reset Logic.")
    );
  }
};

// export default (props: Props) => {
//   return (
//     <ToggleSection
//       title="Report options and access levels:"
//       className="meta-super-sidepane-toggle-section"
//       renderFunction={isExpanded => {
//         if (!isExpanded) {
//           return null;
//         }

//         return (
//           <section className="meta-super-sidepane-accesslevels-main-cont">
//             <div className="meta-super-sidepane-accesslevels-inner-cont">
//               <p className="meta-super-sidepane-accesslevels-inner-select-label">
//                 <Icon
//                   className="meta-super-sidepane-accesslevels-inner-label-icon"
//                   icon="info-circle"
//                   size={12}
//                 />
//                 Partial Report
//               </p>

//               <Select
//                 canClear={false}
//                 containerClassName="meta-super-sidepane-accesslevels-inner-select-cont"
//                 className="meta-super-sidepane-accesslevels-inner-select"
//                 omitFilter={true}
//                 options={[]}
//                 onChange={() => {}}
//                 placeholder="Choose.."
//                 value=""
//               />
//             </div>
//             <div className="meta-super-sidepane-accesslevels-inner-cont">
//               <p className="meta-super-sidepane-accesslevels-inner-select-label">
//                 <Icon
//                   className="meta-super-sidepane-accesslevels-inner-label-icon"
//                   icon="info-circle"
//                   size={12}
//                 />
//                 Full Report
//               </p>

//               <Select
//                 containerClassName="meta-super-sidepane-accesslevels-inner-select-cont"
//                 className="meta-super-sidepane-accesslevels-inner-select"
//                 omitFilter={true}
//                 options={[]}
//                 onChange={() => {}}
//                 placeholder="Choose.."
//                 value=""
//               />
//             </div>
//             <div className="meta-super-sidepane-accesslevels-inner-cont">
//               <p className="meta-super-sidepane-accesslevels-inner-select-label">
//                 <Icon
//                   className="meta-super-sidepane-accesslevels-inner-label-icon"
//                   icon="info-circle"
//                   size={12}
//                 />
//                 Spoiler Mode
//               </p>

//               <Select
//                 containerClassName="meta-super-sidepane-accesslevels-inner-select-cont"
//                 className="meta-super-sidepane-accesslevels-inner-select"
//                 omitFilter={true}
//                 options={[]}
//                 onChange={() => {}}
//                 placeholder="Choose.."
//                 value=""
//               />
//             </div>
//             <div className="meta-super-sidepane-accesslevels-inner-cont">
//               <p className="meta-super-sidepane-accesslevels-inner-select-label">
//                 <Icon
//                   className="meta-super-sidepane-accesslevels-inner-label-icon"
//                   icon="info-circle"
//                   size={12}
//                 />
//                 Default Chart
//               </p>

//               <Select
//                 containerClassName="meta-super-sidepane-accesslevels-inner-select-cont"
//                 className="meta-super-sidepane-accesslevels-inner-select"
//                 omitFilter={true}
//                 options={[]}
//                 onChange={() => {}}
//                 placeholder="Choose.."
//                 value=""
//               />
//             </div>

//             <div className="meta-super-sidepane-accesslevels-detectChanges(originalConfigValues, configValues)-cont">
//               <Button
//                 className="meta-super-sidepane-accesslevels-button"
//                 onClick={() => {}}
//                 value="Reset Logic"
//                 shape="square"
//                 type="hollow"
//               />

//               <span className="meta-super-sidepane-accesslevels-button-desc">
//                 This will delete current access settings and default to the
//                 settings in <b>{"[Domain Name]"}</b>
//               </span>
//             </div>
//           </section>
//         );
//       }}
//     />
//   );
// };
