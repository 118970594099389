import { ENDPOINT_PEOPLEKEYS_PAGES_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PEOPLEKEYS_PAGES_URL;

export const PeopleKeysPages = {
    get(params: Endpoints.Tyto.PeopleKeys.Pages.GetParameters, callOpts?: CallOpts) {
        return callWrapper("get", endpoint, { ...params }, callOpts);
    }
};
