/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Button } from "../../components/common/";
import PersonHeader from "./subcomponents/PersonHeader";
import TabsInterface from "./tabs-interface/";
import { Tyto } from "../../typings/tyto";

import LoadingInterface from "./subcomponents/placeholders/";
import ProfileNotFound from "./subcomponents/ProfileNotFound";
import { AppStoreProps } from "../../data/stores/AppStore";

interface Props {
  AppStore: AppStoreProps;
  backButton?: {
    value: string;
    onClick: () => void;
  };
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile?: Tyto.DISCProfileMini;
  discMiniProfileIsNull: boolean;
  hasLoadedProfile: boolean;
  loggedInUserID: number;
  curTabName?: string;
  tryybProfile?: Tyto.Person;
  userDISCMini?: Tyto.DISCProfileMini;
  userID: string;
  uploadURL?: string;
}

export default (props: Props) => {
  const isOwnProfile =
    props.loggedInUserID &&
    parseInt(`${props.userID}`) === props.loggedInUserID;
  const userHasManage = !!_.get(props, "AppStore.state.userHasManage", false);
  const stillAwaitingMini =
    !props.discMiniProfile && !props.discMiniProfileIsNull;
  const userDomainType = _.get(
    props.AppStore,
    "state.userDomain.domainType",
    ""
  ) as keyof typeof Tyto.DomainType;
  const showBackButton =
    userDomainType && userDomainType !== "ocTEAMTOOLSCOACH";

  if (
    !props.hasLoadedProfile ||
    (!props.discMiniProfile && props.tryybProfile === undefined) ||
    stillAwaitingMini
  ) {
    return (
      <LoadingInterface
        backButton={props.backButton}
        showBackButton={showBackButton}
        tabType={isOwnProfile ? "own-profile" : "profile-full"}
      />
    );
  }

  if (
    props.hasLoadedProfile &&
    !props.discMiniProfile &&
    props.tryybProfile === null
  ) {
    return (
      <ProfileNotFound
        backButton={props.backButton}
        showBackButton={showBackButton}
        userID={props.userID}
      />
    );
  }

  return (
    <div className="directory-person-profile-wrapper side-pane-open">
      <section className="directory-person-profile-main-cont">
        {showBackButton && props.backButton && (
          <div className="directory-person-profile-back-button-cont">
            <Button
              iconLeft="angle-left"
              iconProps={{
                className: "directory-person-profile-back-button-icon",
                size: 30,
              }}
              className="directory-person-profile-back-button"
              onClick={props.backButton.onClick}
              value={props.backButton.value}
            />
          </div>
        )}

        <PersonHeader
          AppStore={props.AppStore}
          isOwnProfile={!!isOwnProfile}
          discCompareProfile={props.discCompareProfile}
          discMiniProfile={props.discMiniProfile}
          tryybProfile={props.tryybProfile}
          userID={props.userID}
          uploadURL={props.uploadURL}
        />

        <TabsInterface
          discMiniProfile={props.discMiniProfile}
          discCompareProfile={props.discCompareProfile}
          curTabName={props.curTabName}
          tabType={isOwnProfile ? "own-profile" : "profile-full"}
          tryybProfile={props.tryybProfile}
          userDISCMini={props.userDISCMini}
          userHasManage={userHasManage}
          userID={props.userID}
        />
      </section>
    </div>
  );
};
