import { ENDPOINT_DISC_PROFILE_INTERACTIVE_MOCKUP } from "../constants";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_PROFILE_INTERACTIVE_MOCKUP;

export const DISCProfileInteractiveMockup = {
  get(
    params: Endpoints.Tyto.DISCProfile.Interactive.Mockup.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts);
  }
};
