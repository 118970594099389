/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  DISCPlotGraph,
  DISCValueRender,
  Message,
  TextButton,
} from "../../../../../components/common/";
import { getNameFromLetter } from "../../../../../data/utils/helpers/";
import { StoreContext } from "../../../../../data/stores/GeneralStore";

import { Tyto } from "../../../../../typings/tyto";

interface intensitiesObj {
  [x: string]: Tyto.DISCCompareProfile.Intensity;
}

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
}

export default (props: Props) => {
  let GeneralStore = React.useContext(StoreContext);

  const graphContRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(
    null
  );
  const [graphContWidth, updateGraphContWidth] = React.useState(0);
  const [intensities, updateIntensities] = React.useState<
    intensitiesObj | undefined
  >(() => {
    if (!props.discCompareProfile) {
      return undefined;
    }

    return _.keyBy(props.discCompareProfile.intensity, (intensityLetter) =>
      intensityLetter.styleKey.toLowerCase()
    ) as intensitiesObj;
  });
  const [selectedLetter, updateSelectedLetter] = React.useState<
    "d" | "i" | "s" | "c" | undefined
  >(undefined);

  // * [E-1] - Update graphWidth as ref changes
  React.useEffect(() => {
    if (graphContRef.current) {
      const graphContInfo = graphContRef.current.getBoundingClientRect();

      updateGraphContWidth(graphContInfo.width);
    } else {
      updateGraphContWidth(0);
    }
  }, [graphContRef.current]);

  // * [E-2] - Update intensities object as discCompareProfile changes
  React.useEffect(() => {
    if (props.discCompareProfile) {
      updateIntensities(
        _.keyBy(props.discCompareProfile.intensity, (intensityLetter) =>
          intensityLetter.styleKey.toLowerCase()
        )
      );
    } else {
      updateIntensities(undefined);
    }
  }, [props.discCompareProfile]);

  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  return (
    <div className="directory-tabs-tab-section-comm-tips-graph-section">
      <DISCValueRender
          className="directory-tabs-tab-section-comm-tips-graph-wrapper"
          isCompareValue={true}
          compareProfile={props.discCompareProfile}
          targetKey="result.d3percentile"
          targetKeys={[
            "result.d3percentile",
            "intensity"
          ]}
          discMiniProfile={props.discMiniProfile}
        >
        <div
          className="directory-tabs-tab-section-comm-tips-graph-section-left"
          ref={graphContRef}
        >
          <DISCValueRender
            className="directory-tabs-tab-section-comm-tips-graph-wrapper"
            isCompareValue={true}
            compareProfile={props.discCompareProfile}
            targetKey="result.d3percentile"
            discMiniProfile={props.discMiniProfile}
          >
            {graphContWidth && (
              <DISCPlotGraph
                focusLetter={selectedLetter}
                groupStyle={[
                  {
                    d: props.discMiniProfile.d3,
                    i: props.discMiniProfile.i3,
                    s: props.discMiniProfile.s3,
                    c: props.discMiniProfile.c3,
                    personName: props.discMiniProfile.personName,
                    percentile: props.discCompareProfile
                      ? {
                          d: props.discCompareProfile.result.d3percentile,
                          i: props.discCompareProfile.result.i3percentile,
                          s: props.discCompareProfile.result.s3percentile,
                          c: props.discCompareProfile.result.c3percentile,
                        }
                      : undefined,
                  },
                ]}
                onLetterSelect={(letter) => updateSelectedLetter(letter)}
                showLetterValues={true}
                size={graphContWidth}
              />
            )}
          </DISCValueRender>
        </div>
        <div className="directory-tabs-tab-section-comm-tips-graph-section-right">
          <DISCValueRender
            className="directory-tabs-tab-section-comm-tips-graph-wrapper"
            isCompareValue={true}
            compareProfile={props.discCompareProfile}
            targetKey="intensity"
            discMiniProfile={props.discMiniProfile}
          >
            {selectedLetter ? (
              <div
                className={cx(
                  "directory-tabs-tab-section-comm-tips-graph-section-letter-info-cont",
                  `graph-section-letter-${selectedLetter}-info`
                )}
                style={{
                  width: graphContWidth - (isMobile ? 0 : 20),
                  minHeight: ((graphContWidth - 20) * 275) / 296,
                }}
              >
                <div className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-inner-cont">
                  <div className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-label-cont">
                    <h3 className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-name">
                      {getNameFromLetter(selectedLetter)}
                    </h3>
                    <p className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-value">
                      {getValueFromLetter(selectedLetter, props.discMiniProfile)}
                    </p>
                  </div>

                  <p className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-blurb">
                    {intensities &&
                    selectedLetter &&
                    intensities[selectedLetter] ? (
                      intensities[selectedLetter].description
                    ) : (
                      <Message className="" value="No information found" />
                    )}
                  </p>

                  <div className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-close-button-cont">
                    <TextButton
                      className="directory-tabs-tab-section-comm-tips-graph-section-letter-info-close-button"
                      onClick={() => updateSelectedLetter(undefined)}
                      value="Got It!"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Message
                className="directory-tabs-tab-section-comm-tips-graph-section-select-message"
                value={`Select each dimension to learn about ${
                  props.discMiniProfile.personName.split(" ")[0] || "Their"
                } personality`}
                style={{
                  marginTop: isMobile ? undefined : `${graphContWidth / 2}px`,
                }}
              />
            )}
          </DISCValueRender>
        </div>
        </DISCValueRender>
    </div>
  );
};

const getValueFromLetter = (
  letter: "d" | "i" | "s" | "c",
  discMiniProfile: Tyto.DISCProfileMini
) => {
  const key = `${letter}3` as "d3" | "i3" | "s3" | "c3";
  const value = discMiniProfile[key];

  return `${value >= 0 ? "+" : ""}${value.toFixed(1)}`;
};
