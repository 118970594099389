/*
 * Component Description
 */
import * as React from "react";
// import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button";
import * as _ from "lodash";
import pkg from "../../../../../package.json";

import { Icon, TextButton } from "../../../common/";
import { ChangeLog, Welcome } from "../../../modals/";
import { TEAM_TOOLS_WEB_CHANGELOG_TEAMID } from "../../../../data/constants/";
import { StoreContext } from "../../../../data/stores/AppStore";
import { StoreContext as AdminStoreContext } from "../../../../interfaces/admin/data/stores/AdminStore";

import "./NavFooter.scss";

interface Props {
  logout: () => void;
  rootDomain?: number;
}

export default (props: Props) => {
  const [buildNum] = React.useState((pkg && pkg.version) || "");
  let AppStore = React.useContext(StoreContext);
  let AdminStore = React.useContext(AdminStoreContext);

  const [canViewChangeLog, updateCanViewChangeLog] = React.useState(false);
  const [changeLogIsShowing, updateChangeLogIsShowing] = React.useState(false);
  const [modalIsOpen, updateModalIsOpen] = React.useState(false);

  React.useEffect(() => {
    updateCanViewChangeLog(
      !!_.get(
        AdminStore,
        `state.teamsByTeamID[${TEAM_TOOLS_WEB_CHANGELOG_TEAMID}]`
      )
    );
  }, [
    _.get(
      AdminStore,
      `state.teamsByTeamID[${TEAM_TOOLS_WEB_CHANGELOG_TEAMID}]`
    ),
  ]);

  const domainID = _.get(AppStore, "state.curDomainID", 0);

  return (
    <footer className="meta-nav-footer">
      <div className="meta-nav-footer-top-cont">
        <button
          className="meta-nav-footer-button"
          // onClick={() => clearSessionData()}
          onClick={props.logout}
        >
          <Icon className="meta-nav-footer-icon" size={20} icon="sign-out" />
          <p className="meta-nav-footer-button-text">Logout</p>
        </button>

        <button
          className="meta-nav-footer-button"
          onClick={() => {
            // @ts-ignore
            if (Usersnap) {
              // @ts-ignore
              Usersnap.open({
                fields: {
                  email: {
                    initialValue: _.get(AppStore, "state.personInfo.email", ""),
                    label: "E-Mail",
                    required: true,
                  },
                },
              });
            }
          }}
        >
          <Icon
            className="meta-nav-footer-icon"
            size={20}
            icon="comment-alt-exclamation"
          />
          <p className="meta-nav-footer-button-text">Feedback</p>
        </button>

        <button
          className="meta-nav-footer-button"
          onClick={() => updateModalIsOpen(true)}
        >
          <Icon
            className="meta-nav-footer-icon"
            size={20}
            icon="question-circle"
          />
          <p className="meta-nav-footer-button-text">Welcome</p>
        </button>
      </div>

      <div className="meta-nav-footer-bottom-cont">
        <img className="meta-nav-footer-bottom-logo" src="/tt-text-light.png" />

        <div className="meta-nav-footer-bottom-right-cont">
          <p className="meta-nav-footer-bottom-right-text">Copyright 2020</p>
          <p className="meta-nav-footer-bottom-right-text">
            {canViewChangeLog ? (
              <TextButton
                className="meta-nav-footer-bottom-right-text-button"
                onClick={() => updateChangeLogIsShowing(true)}
                value={`Build ${buildNum}`}
              />
            ) : (
                <span>Build {buildNum}</span>
              )}
          </p>
        </div>
      </div>

      {/* <p>
        <TextButton
          onClick={() => {
            updateShowLoginModal(true);
          }}
          value="Login to Another Account"
        />
      </p> */}

      <ChangeLog
        isOpen={changeLogIsShowing}
        onDismiss={() => updateChangeLogIsShowing(false)}
      />

      <Welcome
        domainID={domainID}
        isOpen={modalIsOpen}
        onDismiss={() => updateModalIsOpen(false)}
        rootDomain={props.rootDomain}
        toggleShow={shouldShow => updateModalIsOpen(!!shouldShow)}
      />
    </footer>
  );
};
