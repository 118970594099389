/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import {
  StoreState as AppStoreState,
  AppStoreDispatch,
} from "../../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "../../data/stores/AdminStore";

import TeamTreeTeam from "./TeamTreeTeam";

import "./TeamTreeInnerList.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AppStoreDispatch: AppStoreDispatch;
  AppStoreState: AppStoreState;
  chooseTeam: (teamID: number) => void;
  childTeams: Tyto.Team[];
  curDomainID: number;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  forceSelfOnly?: boolean;
  previewPersonID?: number;
  searchTerm?: string;
  selectedTeamID: number;
  parentTeam: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
  teamSearchTerm?: string;
  teamSearchMatchPathIDs?: Set<number>;
  teamSearchMatchIDs?: Set<number>;
  depth: number;
}

export default (props: Props) => {
  return (
    <ul
      className={cx(
        "admin-teams-team-structure-childteams-list",
        !!props.depth && "admin-teams-team-structure-childteams-list-nested"
      )}
    >
      {Array.isArray(props.childTeams) &&
        !!props.childTeams.length &&
        props.childTeams.map((childTeam) =>
          childTeam.teamType === "ocDOMAIN" ? null : (
            <TeamTreeTeam
              key={childTeam.teamID}
              {...props}
              team={childTeam}
              stopCascade={false}
              // stopCascade={childTeam.teamType === "ocDOMAIN"}
              depth={props.depth + 1}
            />
          )
        )}
    </ul>
  );
};
