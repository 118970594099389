import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { get } from "lodash";
// import { SessionHandling } from "data/storage";

import { StoreContext as AppStoreContext } from "../../../data/stores/AppStore";
import type { AssessmentTakerSubInterface } from "./types";
import { use247DISCQuestionnaireQuery } from "../../../data/hooks/Vendor247/DISCQuestionnaire";
import { useSubmitAnswersMutation } from "../../../data/hooks/Vendor247/SubmitAnswers";
import { useDISCMini } from "../../../data/hooks/useDISCMini";
import { createAnswersFile, downloadFile } from "./utils";

import { Icon, Loading, Message } from "../../../components/common";

import type { QuestionsResp } from "./types";
// import { questionsResp } from "./test-data";
import ProgressBar from "./ProgressBar";
import Question from "./Question";

import "./Taker.scss";

interface Props extends Taker247DataWrapperProps {
  questions?: QuestionsResp["discQuestionnaireBasic"];
  tieBreakerQuestions?: QuestionsResp["discQuestionnaireTiebreakers"];
  isLoading: boolean;
  validateURL?: string;
}

const Taker247 = (props: Props) => {
  const AppStore = React.useContext(AppStoreContext);

  const [errorMsg, updateErrorMsg] = useState("");
  const discMiniQuery = useDISCMini({
    userID: props.loggedInUserID,
    isEnabled: !!props.loggedInUserID,
  });

  const submitAnswersMutation = useSubmitAnswersMutation();

  const managedQuestionsData = useManagedQuestions({
    questions: props.questions,
    tieBreakerQuestions: props.tieBreakerQuestions,
  });

  const questions = managedQuestionsData.showTieBreakers
    ? props.tieBreakerQuestions
    : props.questions;

  const isLastQuestion =
    managedQuestionsData.curQuestionIndex + 1 >= (questions?.length ?? 24);

  const submitAnswers = () => {
    updateErrorMsg("");
    submitAnswersMutation
      .mutateAsync({
        answers: managedQuestionsData.getAnswers(),
        suppliedURL: props.validateURL ?? "",
      })
      .then((resp) => {
        if (resp.validation.isOverUnder) {
          props.updateCurSubInterface("restart");
        } else if (resp.validation.needTieBreak) {
          managedQuestionsData.toggleShowTieBreakers(true);
        } else if (resp.validation.completed) {
          discMiniQuery.refetch();
          props.updateCurSubInterface("completed");
        } else {
          updateErrorMsg(
            "Something went wrong. Your results require no further action but are not complete."
          );
        }
      })
      .catch((err) => {
        updateErrorMsg(
          pullErrorMsg(err) ?? "Something went wrong. Unable to submit answers."
        );
      });
  };

  if (submitAnswersMutation.isPending) {
    return (
      <article className="assessment-247-taker-wrapper">
        <div className="assessment-247-taker-loading-wrapper">
          {/* <Icon
            className={cx(
              "assessment-taker-initializing-icon loading-icon-247"
            )}
            size={props.isMobile ? 125 : 145}
            icon="bell"
            // icon="r3"
          /> */}

          <Loading
            className="assessment-taker-initializing-icon loading-icon-247"
            // size={props.isMobile ? 125 : 145}
          />

          <Message
            // fade={false}
            className="assessment-247-taker-submitting-text"
            value="Submitting your answers..."
          />

          <Message
            // fade={false}
            className="assessment-247-taker-submitting-text-sub"
            value="This may take some time. Please leave this tab open and do not navigate away."
          />
          <Message
            // fade={false}
            className="assessment-247-taker-submitting-text-sub"
            value="You will be redirected when the process is complete."
          />
        </div>
      </article>
    );
  }

  return (
    <article className="assessment-247-taker-wrapper">
      <ProgressBar
        countTotal={questions?.length ?? 24}
        curQuestionIndex={managedQuestionsData.curQuestionIndex}
      />

      <div className="assessment-247-taker-directions-cont">
        {managedQuestionsData.showTieBreakers && (
          <p className="assessment-247-taker-directions-text tiebreak-text">
            It seems like a bit more clarity is needed for a few of your
            responses. We have some additional questions to help straighten
            things out.
          </p>
        )}

        <p className="assessment-247-taker-directions-text">
          In each set of four words, select the word that is{" "}
          <span className="most-text-color">most like you</span> and the word
          that is <span className="least-text-color">least like you</span>.
        </p>
      </div>

      {errorMsg && (
        <div className="assessment-247-taker-error-cont">
          <p className="assessment-247-taker-directions-text">
            An error occurred while submitting your answers.{" "}
            <button
              className="assessment-247-taker-error-download-btn"
              onClick={() => {
                const userID = AppStore.state?.loggedInUserID || 0;

                const file = createAnswersFile(
                  `disc-assessment-answers-${userID}`,
                  managedQuestionsData.getAnswers()
                );

                downloadFile(file);
              }}
              type="button"
            >
              Download My Answers
            </button>
          </p>

          <p className="assessment-247-taker-directions-text">{errorMsg}</p>
        </div>
      )}

      <Question
        questionIdx={managedQuestionsData.curQuestionIndex}
        questionsCount={questions?.length ?? 24}
        curQuestion={managedQuestionsData.curQuestion}
        nextQuestion={
          isLastQuestion ? submitAnswers : managedQuestionsData.nextQuestion
        }
        previousQuestion={managedQuestionsData.previousQuestion}
        setQuestionAnswer={managedQuestionsData.setQuestionAnswer}
        answerLeast={managedQuestionsData.answerLeast}
        answerMost={managedQuestionsData.answerMost}
      />
    </article>
  );
};

type Answers = Record<number, number>;

export function useManagedQuestions({
  questions,
  tieBreakerQuestions,
}: Pick<Props, "questions" | "tieBreakerQuestions">) {
  const [curQuestionIndex, updateCurQuestionIndex] = useState(0);
  const [showTieBreakers, updateShowTieBreakers] = useState(false);
  const [answersMost, updateAnswersMost] = useState<Answers>({});
  const [answersLeast, updateAnswersLeast] = useState<Answers>({});
  const [curQuestionID, updateCurQuestionID] = useState<number>(() => {
    if (!questions?.length) {
      return 0;
    }

    return questions[0].QId ?? 0;
  });

  useEffect(() => {
    if (curQuestionID) return;

    const targetQuestions = showTieBreakers ? tieBreakerQuestions : questions;

    updateCurQuestionID(targetQuestions?.[0].QId ?? 0);
  }, [questions, tieBreakerQuestions]);

  const curQuestion = useMemo(() => {
    const targetQuestions = showTieBreakers ? tieBreakerQuestions : questions;

    if (!targetQuestions?.length || !curQuestionID) return;

    const question = targetQuestions[curQuestionIndex];

    if (question?.QId === curQuestionID) {
      return question;
    }

    return targetQuestions.find((question) => question.QId === curQuestionID);
  }, [
    curQuestionID,
    curQuestionIndex,
    questions,
    tieBreakerQuestions,
    showTieBreakers,
  ]);

  return {
    curQuestionID,
    curQuestionIndex,
    curQuestion,
    answerMost: answersMost[curQuestionID],
    answerLeast: answersLeast[curQuestionID],
    showTieBreakers,
    setQuestionAnswer: (
      qID: number,
      answerID: number,
      mostOrLeast: "least" | "most"
    ) => {
      if (mostOrLeast === "least") {
        updateAnswersLeast((prevAnswers) => ({
          ...prevAnswers,
          [qID]: answerID,
        }));
      } else {
        updateAnswersMost((prevAnswers) => ({
          ...prevAnswers,
          [qID]: answerID,
        }));
      }
    },
    nextQuestion: () => {
      const targetQuestions = showTieBreakers ? tieBreakerQuestions : questions;

      if (!targetQuestions?.length) return;

      // * Can't move on if have not answered current question
      if (!answersMost[curQuestionID] || !answersLeast[curQuestionID]) return;
      // * Can't move on if Most and Least have the Same Answer
      if (answersMost[curQuestionID] === answersLeast[curQuestionID]) return;

      const curQuestionIndex = targetQuestions?.findIndex(
        (question) => question.QId === curQuestionID
      );

      if (curQuestionIndex < 0) {
        updateCurQuestionID(targetQuestions[0].QId ?? 0);
        updateCurQuestionIndex(0);
        return;
      }

      const newIdx = Math.max(curQuestionIndex, 0) + 1;

      if (newIdx > targetQuestions.length) {
        // * No more questions
        // TODO
        return;
      }

      const nextQuestionID = targetQuestions[newIdx]?.QId;

      // TODO
      if (!nextQuestionID) return;

      updateCurQuestionID(nextQuestionID);
      updateCurQuestionIndex(newIdx);
    },
    previousQuestion: () => {
      if (!curQuestionIndex) return;

      const targetQuestions = showTieBreakers ? tieBreakerQuestions : questions;

      const newIdx = Math.max(curQuestionIndex, 1) - 1;

      const prevQID = targetQuestions?.[newIdx]?.QId;

      if (!prevQID) return;

      updateCurQuestionID(prevQID);
      updateCurQuestionIndex(newIdx);
    },
    toggleShowTieBreakers: (showTieBreakers?: boolean) => {
      const newShowTieBReakers =
        typeof showTieBreakers === "boolean"
          ? showTieBreakers
          : !showTieBreakers;

      updateShowTieBreakers(newShowTieBReakers);

      updateCurQuestionIndex(0);

      if (newShowTieBReakers) {
        updateCurQuestionID(tieBreakerQuestions?.[0]?.QId ?? 0);
      } else {
        updateCurQuestionID(questions?.[0]?.QId ?? 0);
      }
    },
    getAnswers: () => {
      if (!questions?.length) {
        return [];
      }

      const answers: {
        QId: number;
        AIdMost: number;
        AIdLeast: number;
      }[] = [];

      questions.forEach((question) => {
        answers.push({
          QId: question.QId,
          AIdMost: answersMost[question.QId] ?? 0,
          AIdLeast: answersLeast[question.QId] ?? 0,
        });
      });

      if (
        showTieBreakers &&
        tieBreakerQuestions &&
        tieBreakerQuestions.length
      ) {
        tieBreakerQuestions.forEach((question) => {
          answers.push({
            QId: question.QId,
            AIdMost: answersMost[question.QId] ?? 0,
            AIdLeast: answersLeast[question.QId] ?? 0,
          });
        });
      }

      return answers;
    },
    getTiebreakerAnswers: () => {
      if (!tieBreakerQuestions?.length) {
        return [];
      }

      return tieBreakerQuestions.map((question) => {
        return {
          qID: question.QId,
          most: answersMost[question.QId] ?? 0,
          least: answersLeast[question.QId] ?? 0,
        };
      });
    },
  };
}

interface Taker247DataWrapperProps {
  curSubInterface: AssessmentTakerSubInterface;
  isMobile?: boolean;
  loggedInUserID: number;
  updateCurSubInterface: (newSubInterface: AssessmentTakerSubInterface) => void;
}

const Taker247DataWrapper = (props: Taker247DataWrapperProps) => {
  // const questionsQuery = useQuery(
  //   "disc-test-questions",
  //   async () => {
  //     return questionsResp;
  //   },
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const questionsQuery = use247DISCQuestionnaireQuery({});

  if (questionsQuery.isLoading) {
    return <div>Loading...</div>;
  } else if (questionsQuery.isError || questionsQuery.forTakeQuery.isError) {
    return (
      <div>
        <h1>Error</h1>

        <p>
          {pullErrorMsg(
            questionsQuery.error || questionsQuery.forTakeQuery.error
          )}
        </p>
      </div>
    );
  } else if (
    !questionsQuery.discVendor?.tryybServiceEndpoint ||
    !questionsQuery.isExpectedURL
  ) {
    return (
      <div>
        <h1>Error</h1>
        <p>
          {!questionsQuery.isExpectedURL
            ? "Unexpected DISC Vendor Flag. Cannot Begin Assessment."
            : "Could not find DISC Vendor for your assessment."}
        </p>
        <p>Please reach out to an administrator</p>
      </div>
    );
  }

  return (
    <Taker247
      {...props}
      isLoading={questionsQuery.isLoading}
      questions={questionsQuery.data?.questionnaire?.questions?.basic}
      tieBreakerQuestions={
        questionsQuery.data?.questionnaire?.questions?.tiebreakers
      }
      validateURL={questionsQuery.data?.questionnaire?.validateUrl}
    />
  );
};

function pullErrorMsg(error: unknown) {
  if (typeof error === "string") {
    return error;
  } else if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error === "object") {
    if (get(error, "technical", "")) {
      return `${get(error, "technical", "")}`;
    } else if (get(error, "msg", "")) {
      return `${get(error, "msg", "")}`;
    }

    return JSON.stringify(error);
  }
}

export default Taker247DataWrapper;
