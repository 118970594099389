/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import { StoreContext, AppStoreProps } from "../../data/stores/AppStore";
import { StoreContext as GeneralStoreContext } from "../../data/stores/GeneralStore";
import { StoreContext as AdminStoreContext } from "../admin/data/stores/AdminStore";
import TytoCalls from "../../data/tyto/";
import { Tyto } from "../../typings/tyto";
import { SidePane } from "../../components/meta/";

import MembersList from "./subcomponents/MembersList";
import PlaceholderLoadingMembersList from "./subcomponents/PlaceholderLoadingMembersList";

import "./style.scss";
import { loadCompareProfile } from "../directory/utils";

interface Props {
  path?: string;
  uri?: string;
  personIDs: number[];
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);
  let AdminStore = React.useContext(AdminStoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  const navigate = useNavigate();

  const [error, updateError] = React.useState("");
  const [groupMembersLoaded, updateGroupMembersLoaded] = React.useState(false);
  const [groupMembers, updateGroupMembers] = React.useState<
    Tyto.DISCProfileMini[] | null
  >(null);
  const [focusedPersonID, updateFocusedPersonID] = React.useState(0);

  React.useEffect(() => {
    loadGroupMembers({
      AppStore,
      personIDs: props.personIDs,
      onSuccess: (discMinis: Tyto.DISCProfileMini[]) => {
        const keyedResponse = _.keyBy(discMinis, "personID");
        const desiredUsers = props.personIDs
          .map((personID) => {
            if (keyedResponse[personID]) {
              return keyedResponse[personID];
            }

            return _.get(AppStore, `state.discMini[${personID}]`, undefined);
          })
          .filter(
            (discMiniProfile): discMiniProfile is Tyto.DISCProfileMini =>
              !!discMiniProfile
          );

        updateGroupMembers(desiredUsers);
        updateGroupMembersLoaded(true);

        if (AppStore && AppStore.dispatch) {
          AppStore.dispatch({
            payload: {
              discMini: discMinis,
            },
            type: "DISC_MINI_PROFILES_LOADED",
          });
        }
      },
      onError: (ms: string) => {
        updateGroupMembers(null);
        updateGroupMembersLoaded(true);
      },
    });
  }, [props.personIDs]);

  React.useEffect(() => {
    if (focusedPersonID) {
      const compareProfile = _.get(
        AppStore,
        `state.discCompareProfiles[${focusedPersonID}]`,
        undefined
      );

      if (!compareProfile) {
        loadCompareProfile(AppStore, focusedPersonID);
      }
    }
  }, [focusedPersonID]);

  // * =================================================
  // * Render Variables ================================
  const isMobile = _.get(GeneralStore, "state.isMobile", false);
  const userDISCMini = _.get(AppStore, "state.userDISCMini", undefined);
  const userHasManage = _.get(AppStore, "state.userHasManage", undefined);
  const teams = _.get(AdminStore, "state.teamsByTeamID", undefined);
  const teamsWithConfiguration = _.get(
    AdminStore,
    "state.teamsWithConfiguration",
    undefined
  );
  const memberships = _.get(
    AdminStore,
    "state.membershipsByPersonID",
    undefined
  );
  const previewUser: Tyto.DISCProfileMini | undefined = focusedPersonID
    ? _.get(AppStore, `state.discMini[${focusedPersonID}]`, undefined)
    : undefined;

  return (
    <section className="groups-content-main-cont side-pane-open">
      <div className="groups-content-inner-cont">
        <h1 className="directory-content-header title-bold">Custom Group</h1>

        {error && <p style={{ color: "red" }}>Error</p>}

        {groupMembersLoaded ? (
          !groupMembers ? (
            <h3>No Group Members Found.</h3>
          ) : (
            <MembersList
              groupMembers={groupMembers}
              onPersonSelect={(personID) => updateFocusedPersonID(personID)}
            />
          )
        ) : (
          <PlaceholderLoadingMembersList
            length={props.personIDs ? props.personIDs.length : 1}
          />
        )}
      </div>

      {groupMembers && !!groupMembers.length && (
        <SidePane
          canClose={true}
          canMinimize={isMobile}
          className={cx(
            "groups-sidepane",
            previewUser && "groups-sidepane-underneath"
          )}
          // discCompareProfile={discCompareProfile}
          discCompareProfile={_.get(
            AppStore,
            `state.discCompareProfiles.${groupMembers[0].personID}`
          )}
          discMini={groupMembers}
          focusedPersonID={undefined}
          loggedInUserHasManage={userHasManage}
          loggedInUserID={_.get(AppStore, "state.loggedInUserID", 0)}
          onClick={!!previewUser ? () => updateFocusedPersonID(0) : undefined}
          onClose={() => {}}
          onPersonSelect={(personID) => updateFocusedPersonID(personID)}
          memberships={memberships}
          reloadTeam={(teamID: number) => {}}
          teamsWithConfiguration={teamsWithConfiguration}
          teams={teams}
        />
      )}

      {previewUser && (
        <SidePane
          asModal={isMobile}
          canClose={true}
          className={cx(
            "groups-sidepane groups-preview-pane",
            !!groupMembers && !!groupMembers.length && "groups-sidepane-overtop"
          )}
          canMinimize={false}
          closeOnBlur={true}
          discCompareProfile={_.get(
            AppStore,
            `state.discCompareProfiles.${focusedPersonID}`
          )}
          discMini={[previewUser]}
          focusedPersonID={focusedPersonID}
          loggedInUserHasManage={userHasManage}
          loggedInUserID={_.get(AppStore, "state.loggedInUserID", 0)}
          onClose={() => updateFocusedPersonID(0)}
          onPersonSelect={(personID) => {
            navigate(`/profile/${personID}`);
          }}
          memberships={memberships}
          reloadTeam={(teamID: number) => {}}
          teamsWithConfiguration={teamsWithConfiguration}
          teams={teams}
        />
      )}
    </section>
  );
};

const loadGroupMembers = async ({
  AppStore,
  personIDs,
  onSuccess,
  onError,
}: {
  AppStore: AppStoreProps;
  personIDs: number[];
  onSuccess: (discMinis: Tyto.DISCProfileMini[]) => void;
  onError: (msg: string) => void;
}) => {
  if (!Array.isArray(personIDs) || !personIDs.length) {
    onSuccess([]);
  }

  try {
    const filteredPersonIDs = personIDs.filter(
      (personID) => !_.get(AppStore, `state.discMini[${personID}]`, undefined)
    );

    if (!filteredPersonIDs.length) {
      onSuccess([]);
      return;
    }

    const resp = await TytoCalls.DISCProfilesMini.post({
      personIDs: filteredPersonIDs.join(","),
    });

    onSuccess(resp.discProfiles);
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Failed to load Group Members")
    );
  }
};
