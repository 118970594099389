/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button";

import { PICK_SESSION_TARGET_ID_KEY } from "../../../../data/constants/";
import { AppStoreProps } from "../../../../data/stores/AppStore";
import { SessionData } from "../../../../typings/";
import { Icon } from "../../../common/";
import { ImpersonateSearch, LoginModal } from "../../../modals/";

import "./NavLoginModal.scss";

interface Props {
  AppStore: AppStoreProps;
  reloadSessions: () => void;
  sessionsArray: SessionData[];
  userHasManage: boolean;
}

export default (props: Props) => {
  const [showLoginModal, updateShowLoginModal] = React.useState(false);
  const [showImpersonationSearchModal, updateShowImpersonationSearchModal] =
    React.useState(false);

  const curLoggedInUserID = _.get(props.AppStore, "state.loggedInUserID", 0);
  const curLoggedInUserDomainID = _.get(
    props,
    "AppStore.state.sessionData.domainID",
    0
  );
  const isManager = _.get(props.AppStore, "state.userHasManage", false);

  const navigate = useNavigate();

  return (
    <div className="meta-nav-account-menu-wrapper">
      <Menu>
        <MenuButton
          className="meta-nav-account-options-menu-button"
          key="toggle-button"
        >
          <Icon
            className="meta-nav-account-options-menu-button-icon"
            icon="elipsis-h"
            size={25}
          />
        </MenuButton>
        <MenuList className="meta-nav-account-options-menu-list">
          {Array.isArray(props.sessionsArray) &&
            props.sessionsArray.length > 1 &&
            props.sessionsArray.map((session) => (
              <MenuItem
                key={session.sessionKey}
                className={cx(
                  "meta-nav-account-options-menu-list-item",
                  session.userID === curLoggedInUserID &&
                    "disabled-menu-list-item"
                )}
                onSelect={() => {
                  window.open(
                    `/account-selection?${PICK_SESSION_TARGET_ID_KEY}=${session.userID}&redirect=`
                  );
                  // if (
                  //   props.AppStore.dispatch &&
                  //   session.userID !== curLoggedInUserID
                  // ) {
                  //   props.AppStore.dispatch({
                  //     callback: () => {
                  //       navigate("/");
                  //     },
                  //     payload: session,
                  //     type: "USER_LOGGED_IN"
                  //   });
                  // }
                }}
              >
                {session.userName}
                <span className="meta-nav-account-options-menu-list-item-details">
                  {session.domainID}
                  {!!session.adminID && (
                    <Icon
                      className="meta-nav-account-options-menu-list-item-is-impersonation"
                      size={13}
                      icon="user-secret"
                    />
                  )}
                </span>
                <Icon
                  className="meta-nav-account-options-menu-list-item-external-link"
                  size={13}
                  icon="external-link"
                />
              </MenuItem>
            ))}
          {isManager && (
            <MenuItem
              key="partner-dashboard-link"
              className="meta-nav-account-options-menu-list-item"
              style={{
                color: "#7b5369",
                fontWeight: "bold",
              }}
              onSelect={() => navigate("/partner-dashboard")}
            >
              Partner Dashboard
            </MenuItem>
          )}
          {isManager && curLoggedInUserDomainID === 551 && (
            <MenuItem
              key="impersonate-user-link"
              className="meta-nav-account-options-menu-list-item"
              style={{
                // color: "#7b5369",
                fontWeight: "bold",
              }}
              onSelect={() => updateShowImpersonationSearchModal(true)}
            >
              Impersonate a User
            </MenuItem>
          )}
          <MenuItem
            key="login-to-additional-account"
            className="meta-nav-account-options-menu-list-item menu-list-item-italic-small"
            onSelect={() => updateShowLoginModal(true)}
          >
            Login to Additional Account
          </MenuItem>
        </MenuList>
      </Menu>

      <LoginModal
        isOpen={showLoginModal}
        onDismiss={(newSession?: SessionData) => {
          updateShowLoginModal(false);

          if (newSession) {
            props.reloadSessions();
          }
        }}
        sessionsArray={props.sessionsArray}
        isAccountSelection={false}
      />

      <ImpersonateSearch
        isOpen={showImpersonationSearchModal}
        onDismiss={() => {
          updateShowImpersonationSearchModal(false);
        }}
      />
    </div>
  );
};
