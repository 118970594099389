/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import {
  StoreState as AppStoreState,
  AppStoreDispatch
} from "../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState
} from "../data/stores/AdminStore";
import { Button, Icon } from "../../../components/common/";

import { addPeopleToTeams, loadTeamMembers } from "../data/utils";
import { SubIntefaceKey } from "../";
import CurComponentManager from "../subcomponents/CurComponentManager";
import PlaceholderTeamTree from "../subcomponents/placeholders/Placeholder-TeamTree";
import TeamTree from "./TeamTree";
import People from "./People";

import "./style.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AppStoreDispatch: AppStoreDispatch;
  AdminStoreState: AdminStoreState;
  AppStoreState: AppStoreState;
  subInterface: SubIntefaceKey;
  updateSubInterfaceKey: (newKey: SubIntefaceKey) => void;
}

export default (props: Props) => {
  const [adding, updateAdding] = React.useState(false);
  const [reloading, updateReloading] = React.useState(false);

  React.useEffect(() => {
    const { parsedMemberships } = props.AdminStoreState;

    if (
      !props.AdminStoreState.parsedMemberships ||
      (!!parsedMemberships &&
        !parsedMemberships[props.AppStoreState.curDomainID || 0])
    ) {
      loadTeamMembers({
        domainID: props.AppStoreState.curDomainID || 0,
        flattenedList: props.AdminStoreState.flattenedList,
        onLoad: peopleAndMemberships =>
          props.AdminStoreDispatch({
            payload: {
              people: peopleAndMemberships.people,
              memberships: peopleAndMemberships.memberships
            },
            type: "ADMIN_STORE_PEOPLE_AND_MEMBERSHIPS_LOADED"
          })
      });
    }
  }, [props.AppStoreState.curDomainID]);

  const domain =
    !!props.AppStoreState.curDomainID &&
    Array.isArray(props.AdminStoreState.flattenedList)
      ? props.AdminStoreState.flattenedList.find(
          team => team.teamID === props.AppStoreState.curDomainID
        )
      : undefined;

  if (!domain) {
    if (props.AdminStoreState.loaded) {
      return null;
    }

    return <PlaceholderTeamTree />;
  }

  const selectedTeamsCount = Object.values(
    props.AdminStoreState.selectedTeamsIDs
  ).filter(isSelected => isSelected).length;
  const selectedPeopleCount = Object.values(
    props.AdminStoreState.selectedPeopleIDs
  ).filter(isSelected => isSelected).length;

  return (
    <div className="admin-content-people-manage-wrapper-cont">
      <div className="admin-content-people-manage-main-cont">
        <CurComponentManager
          subInterface={props.subInterface}
          updateSubInterfaceKey={props.updateSubInterfaceKey}
        />

        <TeamTree
          AdminStoreDispatch={props.AdminStoreDispatch}
          AdminStoreState={props.AdminStoreState}
          chooseTeam={(teamID: number) => {}}
          curDomainID={props.AppStoreState.curDomainID || 0}
          disabled={adding || reloading}
          discMiniByTeam={props.AppStoreState.discMiniByTeam}
          previewPersonID={props.AdminStoreState.previewPersonID}
          selectedPeopleIDs={props.AdminStoreState.selectedPeopleIDs}
          selectedTeamsIDs={props.AdminStoreState.selectedTeamsIDs}
          selectedTeamID={props.AdminStoreState.selectedTeamID || 0}
          team={domain}
          teamsStructured={props.AdminStoreState.structured}
        />

        <People
          AdminStoreDispatch={props.AdminStoreDispatch}
          AdminStoreState={props.AdminStoreState}
          AppStoreState={props.AppStoreState}
          curDomainID={props.AppStoreState.curDomainID || 0}
          disabled={adding || reloading}
          people={props.AdminStoreState.people}
          selectedPeopleIDs={props.AdminStoreState.selectedPeopleIDs}
          teamMemberships={props.AdminStoreState.parsedMemberships}
        />
      </div>

      {!!selectedTeamsCount && !!selectedPeopleCount && (
        <div className="admin-content-people-manage-action-cont">
          <Button
            className="admin-content-people-manage-action-button"
            disabled={adding || !selectedTeamsCount || !selectedPeopleCount}
            onClick={() => {
              updateAdding(true);
              debugger;
              addPeopleToTeams({
                onComplete: () => {
                  loadTeamMembers({
                    domainID: props.AppStoreState.curDomainID || 0,
                    flattenedList: props.AdminStoreState.flattenedList,
                    onLoad: peopleAndMemberships => {
                      updateReloading(true);
                      updateAdding(false);
                      props.AdminStoreDispatch({
                        callback: () => {
                          setTimeout(() => {
                            updateReloading(false);

                            props.AdminStoreDispatch({
                              payload: {
                                forceClear: true
                              },
                              type: "ADMIN_STORE_TOGGLE_PERSON_ID"
                            });
                            props.AdminStoreDispatch({
                              payload: {
                                forceClear: true
                              },
                              type: "ADMIN_STORE_TOGGLE_TEAM_ID"
                            });
                          }, 0);
                        },
                        payload: {
                          people: peopleAndMemberships.people,
                          memberships: peopleAndMemberships.memberships
                        },
                        type: "ADMIN_STORE_PEOPLE_AND_MEMBERSHIPS_LOADED"
                      });
                    }
                  });
                },
                onError: () => {
                  updateAdding(false);
                },
                selectedPeopleIDs: props.AdminStoreState.selectedPeopleIDs,
                selectedTeamsIDs: props.AdminStoreState.selectedTeamsIDs
              });
            }}
            shape="square"
            type="color"
            value={
              adding
                ? "Adding Users..."
                : getButtonText(selectedTeamsCount, selectedPeopleCount)
            }
          />
        </div>
      )}

      {(adding || reloading) && (
        <div className="admin-content-people-manage-loading-overlay">
          <div className="admin-content-people-manage-loading-modal">
            <h3 className="admin-content-people-manage-loading-title">
              Adding Users To Teams
            </h3>

            <ul className="admin-content-people-manage-loading-list">
              <li
                className={cx(
                  "admin-content-people-manage-loading-list-item",
                  adding && "people-manage-loading-list-item-active-step"
                )}
              >
                <Icon
                  className={cx(
                    "admin-content-people-manage-loading-list-item-icon",
                    adding && "people-manage-loading-list-item-icon-hide"
                  )}
                  icon="check"
                  size={16}
                />

                <p className="admin-content-people-manage-loading-list-item-text">
                  Adding Users
                </p>
              </li>

              <li
                className={cx(
                  "admin-content-people-manage-loading-list-item",
                  (adding || reloading) &&
                    "people-manage-loading-list-item-active-step"
                )}
              >
                <Icon
                  className={cx(
                    "admin-content-people-manage-loading-list-item-icon",
                    (adding || reloading) &&
                      "people-manage-loading-list-item-icon-hide"
                  )}
                  icon="check"
                  size={16}
                />

                <p className="admin-content-people-manage-loading-list-item-text">
                  Updating List
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const getButtonText = (
  selectedTeamsCount: number,
  selectedPeopleCount: number
) => {
  return !selectedTeamsCount || !selectedPeopleCount
    ? "Add Selected Users"
    : `Add ${selectedPeopleCount} ${
        selectedPeopleCount > 1 ? "people" : "person"
      } to ${selectedTeamsCount} Team${selectedTeamsCount > 1 ? "s" : ""}`;
};
