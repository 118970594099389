import * as _ from "lodash";

import TytoCalls from "../../../data/tyto/";
import { Tyto } from "../../../typings/tyto";
import { AppStoreProps } from "../../../data/stores/AppStore";
import {
  getItem as getLocalStorageItem,
  setItem as setLocalStorageItem,
} from "../../../data/storage/utils";
import {
  DIRECTORY_RECENT_RESULTS,
  CUR_DOMAINID_KEY,
} from "../../../data/constants/";
import { AdminStoreProps } from "../../admin/data/stores/AdminStore";

export const searchOpts = [
  {
    label: "People",
    value: "people",
  },
  {
    label: "Teams",
    value: "teams",
  },
  {
    label: "Teams & People",
    value: "teams-and-people",
  },
];

export function getSearchKeyParams(
  AppStore: AppStoreProps,
  searchTerm: string
) {
  return {
    domainID: _.get(AppStore, "state.curDomainID", 0),
    searchTerm,
    userID: _.get(AppStore, "state.loggedInUserID", 0),
  };
}

export function getSelectedUsersKey({
  domainID,
  userID,
}: {
  domainID: number;
  userID: number;
}) {
  return `${domainID}-${userID}`;
}

export function getSearchStoreKey({
  domainID,
  userID,
  searchTerm,
}: {
  domainID: number;
  userID: number;
  searchTerm: string;
}) {
  return `${domainID}-${userID}-${searchTerm}`;
}

export async function searchForPeople(
  searchTerm: string
): Promise<Tyto.AdvancedPerson[]> {
  if (!searchTerm) {
    return [];
  }

  try {
    const personSearchResp = await TytoCalls.PersonAdvanced.get({
      // * "%" wrapping search term so it knows not exact match
      generalName: `%${searchTerm}%`,
      operation: "ocVIEW",
      // top: 100
    });

    return personSearchResp.ret.people;
  } catch (err) {
    console.warn("Error occurred while attempting to use PersonAdvancedSearch");
    return [];
  }
}

export async function loadCompareProfile(
  AppStore: AppStoreProps,
  focusedPersonID?: number
) {
  if (
    AppStore.state &&
    focusedPersonID &&
    typeof AppStore.state.discCompareProfiles[focusedPersonID] === "undefined"
  ) {
    try {
      const discCompareProfile: any =
        await TytoCalls.DISCProfile.Interactive.get({
          personID: focusedPersonID,
        });
      if (AppStore.dispatch) {
        AppStore.dispatch({
          payload: {
            discCompareProfile: discCompareProfile
              ? discCompareProfile.discProfile
              : undefined,
          },
          type: "DISC_COMPARE_PROFILE_LOADED",
        });
      }

      return true;
    } catch (err) {
      return false;
    }
  }
}

export async function loadDiscMinis(
  AppStore: AppStoreProps,
  missingPersonIDs: number[]
) {
  if (!Array.isArray(missingPersonIDs) || !missingPersonIDs.length) {
    return;
  }

  try {
    const discMiniResps = await TytoCalls.DISCProfilesMini.post({
      personIDs: missingPersonIDs.join(","),
    });

    if (AppStore && AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: discMiniResps.discProfiles,
          userIDsUsed: missingPersonIDs,
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }
  } catch (err) {
    console.warn("Error occurred while loading DISCMinis for Directory Search");
    return;
  }
}

export async function loadRecents({
  AppStore,
  userIDs,
}: {
  // AdminStore: AdminStoreProps;
  AppStore: AppStoreProps;
  userIDs: number[];
}) {
  if (!Array.isArray(userIDs) || !userIDs.length) {
    return [];
  }

  try {
    const curDISCMinis = _.get(AppStore, "state.discMini");

    const userDISCMiniProfilesNeeded = userIDs.filter(
      (userID) => !curDISCMinis || !curDISCMinis[userID]
    );

    if (!userDISCMiniProfilesNeeded.length) {
      return;
    }

    const { discProfiles } = await TytoCalls.DISCProfilesMini.get({
      personIDs: userDISCMiniProfilesNeeded.join(","),
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: discProfiles,
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }

    return discProfiles;
  } catch (err) {
    console.warn("Error ocurred while loading directory recents");
    return [];
  }
}

export async function updateRecents(domainID: number, userIDs: number[]) {
  if (!domainID || !Array.isArray(userIDs)) {
    return;
  }
}

export function getDirectorySearchRecents(domainID: number): number[] {
  if (!domainID) {
    return [];
  }

  const recents: number[] | undefined = getLocalStorageItem(
    `${DIRECTORY_RECENT_RESULTS}-${domainID}`
  ) as any;

  return recents || [];
}

export function setDirectorySearchRecents(
  newValue: number[],
  domainID: number
) {
  debugger;
  if (!domainID) {
    return;
  }

  setLocalStorageItem(
    `${DIRECTORY_RECENT_RESULTS}-${domainID}`,
    newValue || []
  );
}
