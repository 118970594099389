/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";

import {
  calcGradientForIcon,
  calcGradient,
  getLetterColor,
  getNameFromLetter
} from "../../../data/utils/helpers/";
import { Tyto } from "../../../typings/tyto";
import ColumnDistributionGraph from "./subcomponents/ColumnDistributionGraph";
import ColumnValueLabel from "./subcomponents/ColumnValueLabel";
import ColumnSections from "./subcomponents/ColumnSections";
import ColumnRangeMarkers from "./subcomponents/ColumnRangeMarkers";
import PlotPoint, { calcPlotPosition } from "./subcomponents/PlotPoint";
import PlotLine from "./subcomponents/PlotLine";

import "./style.scss";

export type LineInfo = {
  angle: number;
  length: number;
  endX: number;
  startX: number;
  endY: number;
  startY: number;
};

type LinesInfo = {
  di: LineInfo;
  is: LineInfo;
  sc: LineInfo;
}[];

const columns: { colNum: number; label: "d" | "i" | "s" | "c" }[] = [
  {
    label: "d",
    colNum: 0
  },
  {
    label: "i",
    colNum: 1
  },
  {
    label: "s",
    colNum: 2
  },
  {
    label: "c",
    colNum: 3
  }
];

interface Props {
  //   groupStyle: {
  //     label: string;
  //     value: number;
  //   }[][];
  canSelectUser?: boolean;
  groupStyle: {
    d: number;
    i: number;
    s: number;
    c: number;
    personName?: string;
    percentile?: {
      d: number;
      i: number;
      s: number;
      c: number;
    };
    asset?: Tyto.Asset;
  }[];
  focusLetter?: "d" | "i" | "s" | "c";
  omitValueKeys?: boolean;
  onLetterSelect?: (letter: "d" | "i" | "s" | "c") => void;
  size: number;
  showLetterValues?: boolean;
}

export default ({
  canSelectUser,
  groupStyle,
  omitValueKeys,
  onLetterSelect,
  focusLetter,
  size,
  showLetterValues
}: Props) => {
  const [localProfilesCopy, updateLocalProfilesCopy] = React.useState(
    groupStyle || []
  );
  const [useR3Gradient, updateUseR3Gradient] = React.useState(
    groupStyle.length > 1
  );
  const [curProfileIdx, updateCurProfileIdx] = React.useState(0);
  const [gradients, updateGradients] = React.useState(
    groupStyle.map(profile => calcGradient(profile))
  );
  const [lineGradients, updateLineGradients] = React.useState(
    groupStyle.map(profile => calcGradientForIcon(profile))
  );
  const [plotConnectingLines, updatePlotConnectingLines] = React.useState(
    calcPlotConnectingLines(groupStyle, {
      height: ((!!omitValueKeys ? size : size - 20) * 275) / 296,
      width: !!omitValueKeys ? size : size - 20
    })
  );
  const [hoverIdx, updateHoverIdx] = React.useState<number | undefined>(
    undefined
  );

  React.useEffect(() => {
    updateLineGradients(
      groupStyle.map(profile => calcGradientForIcon(profile))
    );
    updateGradients(groupStyle.map(profile => calcGradient(profile)));
    updateUseR3Gradient(groupStyle.length > 1);
    const connectingLines = calcPlotConnectingLines(groupStyle, {
      height: ((!!omitValueKeys ? size : size - 20) * 275) / 296,
      width: !!omitValueKeys ? size : size - 20
    });
    updatePlotConnectingLines(connectingLines);

    if (canSelectUser && groupStyle.length !== localProfilesCopy.length) {
      const curSelectedIdx = _.findIndex(
        localProfilesCopy,
        (item: any) =>
          `${item.personName}-${item.d}` ===
          `${_.get(
            localProfilesCopy,
            [curProfileIdx, "personName"],
            ""
          )}-${_.get(localProfilesCopy, [curProfileIdx, "d"], "")}`
      );
      const newSelectedIdx = _.findIndex(
        groupStyle,
        (item: any) =>
          `${item.personName}-${item.d}` ===
          `${_.get(
            localProfilesCopy,
            [curProfileIdx, "personName"],
            ""
          )}-${_.get(localProfilesCopy, [curProfileIdx, "d"], "")}`
      );

      if (newSelectedIdx < 0) {
        updateCurProfileIdx(0);
      } else if (curSelectedIdx !== newSelectedIdx) {
        updateCurProfileIdx(newSelectedIdx);
      }
    }

    updateLocalProfilesCopy(groupStyle || []);
  }, [groupStyle]);

  return (
    <div
      className="cc-disc-plot-graph-cont"
      style={{ paddingLeft: !!omitValueKeys ? "0px" : "20px" }}
    >
      <div
        className="cc-disc-plot-graph"
        // * Where "20" (in style-tag below) is padding-left on the graph cont to account for markers be absolute positioned
        style={{
          width: !!omitValueKeys ? size : size - 20,
          height: ((!!omitValueKeys ? size : size - 20) * 275) / 296
        }}
      >
        {columns.map(({ colNum, label }) => (
          <div
            className={cx(
              "cc-disc-plot-graph-column",
              `cc-disc-plot-graph-column-${label}`,
              onLetterSelect && "cc-disc-plot-graph-column-can-select",
              focusLetter &&
                focusLetter === label &&
                "cc-disc-plot-graph-column-focused"
            )}
            role={onLetterSelect ? "button" : ""}
            onClick={onLetterSelect ? () => onLetterSelect(label) : undefined}
            key={`${colNum}-${label}`}
          >
            {!colNum && !omitValueKeys && <ColumnRangeMarkers />}

            <ColumnValueLabel
              discLetter={label}
              discValue={groupStyle[0] ? groupStyle[0][label] : 0}
              //   discValue={getGroupLetterValue(label, groupStyle[0])}
              showLetterValues={!!showLetterValues && groupStyle.length >= 1}
              style={{
                fontSize: `${(!!omitValueKeys ? size : size - 20) / 4 / 5.42}px`
              }}
            />

            <ColumnSections key={colNum} columnNumber={colNum} />

            {focusLetter && focusLetter === label && (
              <ColumnDistributionGraph
                focusLetter={focusLetter}
                profile={groupStyle[0]}
              />
            )}

            <p
              className="cc-disc-plot-graph-column-label"
              style={{
                color: getLetterColor(label),
                fontSize: `${(!!omitValueKeys ? size : size - 20) / 4 / 6.42}px`
              }}
            >
              {getNameFromLetter(label)}
            </p>

            {groupStyle.map((profile, curIdx) => (
              <PlotPoint
                colWidth={(!!omitValueKeys ? size : size - 20) / 4}
                discLetter={label}
                discValue={profile[label]}
                discValues={{
                  d: profile.d,
                  i: profile.i,
                  s: profile.s,
                  c: profile.c
                }}
                // forceBackground={
                //   focusLetter && focusLetter === label
                //     ? `radial-gradient(50% 50% at 50% 50%, #E9E9E9 0%, #FFFFFF 100%)`
                //     : undefined
                // }
                gradient={gradients[curIdx]}
                isFocusedLetter={!!(focusLetter && focusLetter === label)}
                isSelected={curIdx === curProfileIdx}
                percentile={profile.percentile}
                selectProfile={
                  canSelectUser ? () => updateCurProfileIdx(curIdx) : undefined
                }
                key={curIdx}
                useR3Gradient={useR3Gradient}
                isHovered={curIdx === hoverIdx}
                onHover={(isLeave?: boolean) =>
                  updateHoverIdx(isLeave ? undefined : curIdx)
                }
                personName={profile.personName}
                photoAsset={profile.asset}
              />
              // </>
            ))}
          </div>
        ))}

        {useR3Gradient &&
          plotConnectingLines &&
          plotConnectingLines.map((profileLines, curIdx) =>
            canSelectUser && curIdx !== curProfileIdx && curIdx !== hoverIdx ? (
              <span></span>
            ) : (
              <React.Fragment key={`${curIdx}-${profileLines["di"].angle}`}>
                <PlotLine
                  canSelectUser={canSelectUser}
                  delay={curIdx * 750}
                  gradient={lineGradients[curIdx]}
                  info={profileLines["di"]}
                  isHovered={curIdx === hoverIdx}
                  isSelected={curProfileIdx === curIdx}
                  lineKey="di"
                  skipDelay={!!canSelectUser}
                  title="DI Connecting Line"
                />

                <PlotLine
                  canSelectUser={canSelectUser}
                  delay={curIdx * 750 + 250}
                  gradient={lineGradients[curIdx]}
                  info={profileLines["is"]}
                  isHovered={curIdx === hoverIdx}
                  isSelected={curProfileIdx === curIdx}
                  lineKey="is"
                  skipDelay={!!canSelectUser}
                  title="IS Connecting Line"
                />

                <PlotLine
                  canSelectUser={canSelectUser}
                  delay={curIdx * 750 + 500}
                  gradient={lineGradients[curIdx]}
                  info={profileLines["sc"]}
                  isHovered={curIdx === hoverIdx}
                  isSelected={curProfileIdx === curIdx}
                  lineKey="sc"
                  skipDelay={!!canSelectUser}
                  title="SC Connecting Line"
                />
              </React.Fragment>
            )
          )}
      </div>
    </div>
  );
};

const calcPlotConnectingLines = (
  groupStyle: {
    d: number;
    i: number;
    s: number;
    c: number;
    personName?: string;
  }[],
  dimensions: { height: number; width: number }
): LinesInfo => {
  const colWidth = dimensions.width / 4;
  const pointPositions = groupStyle.map(profile => {
    // * [1] - Calc Hypothesized Position
    const dPlotPercent = calcPlotPosition(profile.d, colWidth);
    const iPlotPercent = calcPlotPosition(profile.i, colWidth);
    const sPlotPercent = calcPlotPosition(profile.s, colWidth);
    const cPlotPercent = calcPlotPosition(profile.c, colWidth);

    const dYPosition =
      (dPlotPercent < 0
        ? 0.5 + Math.abs(dPlotPercent / 2)
        : 0.5 - dPlotPercent / 2) * dimensions.height;
    const iYPosition =
      (iPlotPercent < 0
        ? 0.5 + Math.abs(iPlotPercent / 2)
        : 0.5 - iPlotPercent / 2) * dimensions.height;
    const sYPosition =
      (sPlotPercent < 0
        ? 0.5 + Math.abs(sPlotPercent / 2)
        : 0.5 - sPlotPercent / 2) * dimensions.height;
    const cYPosition =
      (cPlotPercent < 0
        ? 0.5 + Math.abs(cPlotPercent / 2)
        : 0.5 - cPlotPercent / 2) * dimensions.height;

    const dXPosition = colWidth / 2;
    const iXPosition = colWidth + colWidth / 2;
    const sXPosition = colWidth * 2 + colWidth / 2;
    const cXPosition = colWidth * 3 + colWidth / 2;

    // * [2] - Calc width of line
    const diLength = calcConnectingLineWidth(
      { x: dXPosition, y: dYPosition },
      { x: iXPosition, y: iYPosition }
    );
    const isLength = calcConnectingLineWidth(
      { x: iXPosition, y: iYPosition },
      { x: sXPosition, y: sYPosition }
    );
    const scLength = calcConnectingLineWidth(
      { x: sXPosition, y: sYPosition },
      { x: cXPosition, y: cYPosition }
    );

    // * [3] - Calc Angle of Line
    const diAngle = calcConnectingLineAngle(
      { x: dXPosition, y: dYPosition },
      { x: iXPosition, y: iYPosition }
    );
    const isAngle = calcConnectingLineAngle(
      { x: iXPosition, y: iYPosition },
      { x: sXPosition, y: sYPosition }
    );
    const scAngle = calcConnectingLineAngle(
      { x: sXPosition, y: sYPosition },
      { x: cXPosition, y: cYPosition }
    );

    // * [4] - Return Values in Object
    return {
      di: {
        angle: diAngle,
        length: diLength,
        endX: iXPosition,
        startX: dXPosition,
        endY: iYPosition,
        startY: dYPosition
      },
      is: {
        angle: isAngle,
        length: isLength,
        endX: sXPosition,
        startX: iXPosition,
        endY: sYPosition,
        startY: iYPosition
      },
      sc: {
        angle: scAngle,
        length: scLength,
        endX: cXPosition,
        startX: sXPosition,
        endY: cYPosition,
        startY: sYPosition
      }
    };
  });
  return pointPositions;
};

const calcConnectingLineWidth = (
  point1: { x: number; y: number },
  point2: { x: number; y: number }
) => {
  const width = point2.x - point1.x;
  const height = Math.abs(point2.y - point1.y);

  const length = Math.sqrt(width * width + height * height);

  return length;
};

const calcConnectingLineAngle = (
  point1: { x: number; y: number },
  point2: { x: number; y: number }
) => {
  const width = point2.x - point1.x;
  const height = Math.abs(point2.y - point1.y);

  const hypotenuse = Math.sqrt(width * width + height * height);

  const angle = Math.asin(height / hypotenuse);

  return (angle || 0) * (180 / Math.PI);
};

const getGroupLetterValue = (
  label: string,
  groupStyle: {
    label: string;
    value: number;
  }[]
) => {
  const match = groupStyle.find(item => item.label === label);

  return match ? match.value : 0;
};

// interface PlotPointsProps {
//   colWidth: number;
//   curIdx: number;
//   label: string;
//   // discValue: number;
//   isSelected: boolean;
//   profile: {
//     d: number;
//     i: number;
//     s: number;
//     c: number;
//     personName?: string;
//   };
//   key: number;
//   useR3Gradient: boolean;
// }

// const PlotPoints = (props: PlotPointsProps) => {
//   return (
//     <>
//       <PlotPoint
//         colWidth={props.colWidth}
//         discLetter={props.label}
//         discValue={props.profile.d}
//         isSelected={props.isSelected}
//         key={props.curIdx}
//         useR3Gradient={props.useR3Gradient}
//       />
//       <PlotPoint
//         colWidth={props.colWidth}
//         discLetter={props.label}
//         discValue={props.profile.i}
//         isSelected={props.isSelected}
//         key={props.curIdx}
//         useR3Gradient={props.useR3Gradient}
//       />
//       <PlotPoint
//         colWidth={props.colWidth}
//         discLetter={props.label}
//         discValue={props.profile.s}
//         isSelected={props.isSelected}
//         key={props.curIdx}
//         useR3Gradient={props.useR3Gradient}
//       />
//       <PlotPoint
//         colWidth={props.colWidth}
//         discLetter={props.label}
//         discValue={props.profile.c}
//         isSelected={props.isSelected}
//         key={props.curIdx}
//         useR3Gradient={props.useR3Gradient}
//       />
//     </>
//   );
// };
