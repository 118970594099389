import { Tyto } from "../../typings/tyto";

// * URLs
export const URL_BASE =
  process.env.REACT_APP_API_HOSTNAME || "teamtoolsdemo.mocaworks.com";
export const URL_ORIGIN = `https://${URL_BASE}`;
// export const URL_BASE = "tyto.teamtools.com";
export const API_URL = `https://${URL_BASE}/tyto/api`;
export const WS_SERVER_URL = `wss://app.teamtools.com:8080/`;
// export const WS_SERVER_URL = `ws://localhost:8080`;
export const SENTRY_DSN =
  "https://c7c11558b08f457bafee116791916366@o486049.ingest.sentry.io/5542243";

// * Endpoints
export const ENDPOINT_CONFIGURATION_CLIENT = `${API_URL}/Configuration/Client`;
export const ENDPOINT_DISC_PROFILES = `${API_URL}/DiscProfiles`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE = `${API_URL}/DiscProfile/Interactive`;
export const ENDPOINT_DISC_PROFILE_INTERACTIVE_MOCKUP = `${API_URL}/DiscProfile/Interactive/Mockup`;
export const ENDPOINT_DISC_PROFILE_TEAM = `${API_URL}/DiscProfile/Team`;
export const ENDPOINT_DISC_PROFILE_MINI = `${API_URL}/DiscProfile/Mini`;
export const ENDPOINT_DISC_PROFILES_MINI = `${API_URL}/DiscProfiles/Mini`;
export const ENDPOINT_DISC_PROFILES_EMAIL_VIEW_READY = `${API_URL}/DiscProfiles/EmailViewReady`;
export const ENDPOINT_DOMAIN = `${API_URL}/Domain`;
export const ENDPOINT_DOMAIN_IMAGE = `${API_URL}/Domain/Image`;
export const ENDPOINT_DOMAIN_INVITATION_EMAIL = `${API_URL}/DomainInvitationEmail`;
export const ENDPOINT_DOMAIN_INVITATION_EMAIL_TEMPLATE = `${API_URL}/DomainInvitationEmail/Template`;
export const ENDPOINT_DOMAIN_BILLING = `${API_URL}/DomainBilling`;
export const ENDPOINT_DOMAIN_BILLINGS = `${API_URL}/DomainBillings`;
export const ENDPOINT_DOMAIN_MESSAGES = `${API_URL}/Domain/Messages`;
export const ENDPOINT_DOMAIN_MESSAGE = `${API_URL}/Domain/Message`;
export const ENDPOINT_EMAIL_QUEUE_STATUS = `${API_URL}/EmailQueueStatus`;
export const ENDPOINT_EMAIL_QUEUE_STATUS_USER = `${API_URL}/EmailQueueStatus/User`;
export const ENDPOINT_LESSON = `${API_URL}/Lesson`;
export const ENDPOINT_LOGIN_URL = `${API_URL}/Login/Authenticate`;
export const ENDPOINT_LOGIN4_URL = `${API_URL}/Login/Authenticate4`;
export const ENDPOINT_LOGIN_RECOVER_URL = `${API_URL}/Login/Recover`;
export const ENDPOINT_LOGIN_RECOVER_TEAMTOOLS_URL = `${API_URL}/Login/Recover/TeamTools`;
export const ENDPOINT_LOGIN_RESET_PASSWORD_URL = `${API_URL}/Login/resetpassword`;
export const ENDPOINT_LOGIN_RESET_PASSWORD_VALIDATE_URL = `${API_URL}/Login/resetpassword/validate`;
export const ENDPOINT_LOGOUT_URL = `${API_URL}/Session/Logout`;
export const ENDPOINT_R3_DISC_VENDOR_247_ASSESSMENT = `${API_URL}/r3Disc/Vendor247/Assessment`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_EXTENSION = `${API_URL}/r3Disc/Vendor247/Extension`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire/Validate`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_CONVERT_TO_RESPONSE = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire/convertToResponse`;
export const ENDPOINT_R3_DISC_VENDOR_247_DISC_QUESTIONNAIRE_VALIDATE_ANSWERED = `${API_URL}/r3Disc/Vendor247/DiscQuestionnaire/ValidateAnswered`;
export const ENDPOINT_R3_DISC_VENDOR_247_DIRECT_DISC_QUESTIONNAIRE = `${API_URL}/r3Disc/Vendor247/Direct/DiscQuestionnaire`;
export const ENDPOINT_R3_DISC_VENDOR_247_FIND_USERS = `${API_URL}/r3Disc/Vendor247/findUsers`;
export const ENDPOINT_R3_DISC_VENDOR_247_REGISTER = `${API_URL}/r3Disc/Vendor247/Register`;
export const ENDPOINT_DISC_VENDOR_FOR_TAKE = `${API_URL}/R3Disc/Vendor/Definition/ForTake`;
export const ENDPOINT_DISC_VENDOR_FOR_SEARCH = `${API_URL}/R3Disc/Vendor/Definition/ForSearch`;
export const ENDPOINT_DISC_247_FIND_SELF_ASSESSMENTS = `${API_URL}/r3Disc/Vendor247/findSelfAssessments`;
export const ENDPOINT_DISC_247_IMPORT_SELF_ASSESSMENT = `${API_URL}/r3Disc/Vendor247/importSelfAssessment`;
export const ENDPOINT_SESSION_CHECK = `${API_URL}/AccountSession`;
export const ENDPOINT_TEAM_INFO = `${API_URL}/TeamsByFunction`;
export const ENDPOINT_PEOPLEKEYS_INITIALIZE_URL = `${API_URL}/PeopleKeys/Initialize`;
export const ENDPOINT_PEOPLEKEYS_PAGES_URL = `${API_URL}/PeopleKeys/Pages`;
export const ENDPOINT_PEOPLEKEYS_NEXTPAGE_URL = `${API_URL}/PeopleKeys/NextPage`;
export const ENDPOINT_PEOPLEKEYS_REPORT_URL = `${API_URL}/PeopleKeysCallback/report`;
export const ENDPOINT_PEOPLEKEYS_RESPONSE_URL = `${API_URL}/PeopleKeys/Response`;
export const ENDPOINT_PERSON_GET = `${API_URL}/Person`;
export const ENDPOINT_PERSON_MY_PASSWORD = `${API_URL}/Person/MyPassword`;
export const ENDPOINT_PERSON_PASSWORD = `${API_URL}/Person/Password`;
export const ENDPOINT_PERSON_PROFILE_PHOTO = `${API_URL}/Person/profilephoto`;
export const ENDPOINT_SAVEFORLESSON = `${API_URL}/Saveforlesson`;
export const ENDPOINT_TEAM = `${API_URL}/Team`;
export const ENDPOINT_TEAMBOARD = `${API_URL}/Teamboard`;
export const ENDPOINT_TEAM_TOOLS_CONFIG = `${API_URL}/TeamTools/Config`;
export const ENDPOINT_TEAM_TOOLS_EMAIL_LOGIN = `${API_URL}/TeamTools/EmailLogin`;
export const ENDPOINT_TEAM_TOOLS_INVITE = `${API_URL}/TeamToolsInvite/`;
export const ENDPOINT_TEAM_TOOLS_INVITE_EMAIL = `${API_URL}/TeamToolsInvite/Email`;
export const ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION = `${API_URL}/TeamToolsInvite/tempSession`;
export const ENDPOINT_TEAM_MEMBERSHIP_PERSON = `${API_URL}/TeamMembership/Person`;
export const ENDPOINT_PERSON_GET_ADVANCED = `${API_URL}/People/AdvancedSearch`;
export const ENDPOINT_MENU_GET = `${API_URL}/Menu`;

// * URL Related Variables
export const PICK_SESSION_TARGET_ID_KEY = "targetID";

// * Office Storage (roamingData), sessionStorage, & localStorage KEYS
export const SESSION_DATA_KEY = "team-tools-sessionData";
export const TEMP_SESSION_DATA_KEY = "team-tools-temp-sessionData";
export const RECENTLY_RETRIEVED_PEOPLE = "team-tools-recently-retrieved";
export const TEAM_TOOLS_STORAGE_KEY = "::team-tools-web-data::";
export const PERSONAL_DISC_DATA_KEY = "team-tools-personal-disc-data";
export const DIRECTORY_DOMAIN_MINI_PROFILES_KEY =
  "team-tools-domain-mini-profiles";
export const LOGGED_IN_USER_KEY = "logged-in-user-id";
export const CUR_DOMAINID_KEY = "target-domainid";
export const LAST_SESSION_ACTIVITY = "last-session-activity";
export const DIRECTORY_RECENT_RESULTS = "directory-recent-results";
export const WELCOME_MESSAGE_DATE_AT_DISMISS =
  "welcome-message-date-at-dismiss";

// * DISC Colors

// * [1] - First Colors
// export const DISC_COLOR_D = "#bd487d";
// export const DISC_COLOR_I = "#3bb2eb";
// export const DISC_COLOR_S = "#6ab36b";
// export const DISC_COLOR_C = "#ffc52c";

// * [2] - Hakha's Colors
export const DISC_COLOR_D = "#af4986";
export const DISC_COLOR_I = "#2597d4";
export const DISC_COLOR_S = "#67b26a";
export const DISC_COLOR_C = "#fec42c";
export const DISC_COLOR_D_DARK = "#8f3688";
export const DISC_COLOR_I_DARK = "#1073c7";
export const DISC_COLOR_S_DARK = "#0d7746";
export const DISC_COLOR_C_DARK = "#f89a30";

// * Domain Messages (must be <= 16 characters long)
export const DOMAIN_MESSAGE_WELCOME_MESSAGE_KEY = "tti_web_welcome";
export const DOMAIN_MESSAGE_OUTLOOK_WELCOME_MESSAGE_KEY = "tti_ol_welcome"; // * ol === Outlook
export const DOMAIN_MESSAGE_EMAIL_INVITE_KEY = "tti_invite";
export const DOMAIN_MESSAGE_EMAIL_ACCESS_CHANGED_KEY = "tti_acs_change";
export const DOMAIN_MESSAGE_EMAIL_ASSESSMENT_REMINDER_KEY = "tti_reminder";
export const DOMAIN_MESSAGE_EMAIL_PASSWORD_RESET_KEY = "tti_pw_reset";
export const DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY = "tti_no_access";

// * Misc
export const BILLING_MODAL_DEFAULT = "usp_ByUserTypical_MonthlyBilling";
export const DEFAULT_ACCOUNT_CRITICAL_ERROR_MSG =
  "Your own account information could not be loaded. This may be a technical issue with how your permissions are set up, or some other kind of internal error. Please reach out to your TeamTools partner for technical assistance, or Report and Issue using the button in the bottom right of the screen. \n \n We apoligize for the inconvienence!";
export const TEAM_TOOLS_WEB_CHANGELOG_TEAMID = 1792672;
export const GRADIENT_DIFF_PERCENT_WIDTH = 18;
export const SAVE_ROAMING_DATA_TIMEOUT_MS = 5000;
export const INTERFACE_CONTEXT_PARAM_KEY = "interfacecontext";
export const DEFAULT_INTERFACE_CONTEXT_VALUE = "MSGCOMPOSE";
export const DEFAULT_LOCAL_STORAGE_EXPIRATION_TIME_MS = 86400 * 1000; // * 1 Day in MS
export const MOBILE_WIDTH_BREAKPOINT = 768;
export const IS_DEV_ENV =
  /localhost\:/g.test(document.location.host) ||
  !/([A-Za-z])/g.test(document.location.host);
export const DEFAULT_TEAMTOOLS_USER_PERMISSIONS = {
  mayTakeDisc: false,
  mayImportDisc: false,
  mayTakeBasicTrain: false,
  mayTakeAdvTrain: false,
  hasBasicViewDisc: false,
  hasAdvViewDisc: false,
  hasPermitChange: false,
  hasGrantPermitChange: false,
};
export const CALL_SOURCES = {
  WEBSOCKET: "::WEBSOCKET::",
};
export const DISC_OPT_OUT_OPTIONS = {
  ocLEVEL00: "Everything",
  ocLEVEL10: "Moderate",
  ocLEVEL20: "Minimal",
  ocVACANT: "Foundational",
  // ocLEVEL00: "Full",
  // ocLEVEL10: "Default",
  // ocLEVEL20: "Light",
  // ocVACANT: "Unset",
};

export const REACT_QUERY_KEYS = {
  ADVANCED_SEARCH_PERSON: "person-adv-search",
  ASSET_ENCODING: "asset-encoding",
  FUNCTIONS_ASP: "functions-asp",
  EXAM_INVITES: "exam-invites",
  EXAM_QUESTION: "exam-question",
  EXAM_TRAINING_INVITES: "exam-training-invites",
  TASK_STRUCTURE: "task-structure",
  TRAINING: "training",
  TRENDING: "trending",
  CATALOG: "catalog",
  BLOCK: "block",
  BLOCK_PREREQUISITES: "block-prerequisites",
  LAUNCH_ENROLLMENT: "launch-enrollment",
  LESSON: "lesson",
  PERSON: "person",
  PERSON_COMM_TIPS: "person-comm-tips",
  DISC_VENDOR_FOR_TAKE: "disc-vendor-for-take",
  DISC_VENDOR_FOR_SEARCH: "disc-vendor-for-search",
  DISC_247_FIND_ASSESSMENTS: "disc-247-find-assessments",
  PEOPLE_KEYS_FIND_ASSESSMENTS_V2: "people-keys-fa-v2",
  PEOPLE_KEYS_FIND_ASSESSMENTS_V4: "people-keys-fa-v4",
  PEOPLE_KEYS_INITIALIZE: "people-keys-intialize",
  PEOPLE_KEYS_NEXT_PAGE: "people-keys-nextpage",
  PEOPLE_KEYS_PAGES: "people-keys-pages",
  PREREQUISITE_ENROLLMENTS: "block-prerequisite-enrollments",
  TIMEZONES: "timezones",
  DISC_MINI_USER: "disc-mini-user",
  DISC_MINI_INTERACTIVE: "disc-mini-interactive",
  DISC_MINI_INTERACTIVE_MOCKUP: "disc-mini-interactive-mockup",
  DISC_MINI_TEAM: "disc-mini-team",
  DISC_PROFILE_TEAM: "disc-profile-team",
  TEAMS_BY_FUNCTION: "teams-by-function",
  VENDOR_247_ASSESSMENT: "vendor-247-assessment",
  VENDOR_247_DISC_QUESTIONNAIRE: "vendor-247-disc-questionnaire",
  VENDOR_247_DISC_EXTENSION: "vendor-247-disc-extension",
  VENDOR_247_CONVERT_TO_RESPONSE: "vendor-247-convert-to-response",
  VENDOR_247_FIND_USERS: "vendor-247-find-users",
  VENDOR_247_REGISTER: "vendor-247-register",
  VENDOR_247_VALIDATE_ANSWERED: "vendor-247-va;idate-answered",
};

// * Action Types
