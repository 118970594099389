export function getUserSnapIframe() {
  const iframeQuery = document.getElementsByName("us-entrypoint-button");

  return iframeQuery[0];
}

export function setUserSnapStyles(style?: React.CSSProperties) {
  const userSnapIframe = getUserSnapIframe();

  // ! Currently Only Handle Margin-Right
  if (userSnapIframe && style && style.marginRight) {
    const newMarginRight =
      typeof style.marginRight === "number"
        ? `${style.marginRight}px`
        : style.marginRight;

    userSnapIframe.style.marginRight = newMarginRight;
  } else {
    console.warn("Could not find User Snap Button");
  }
}
