import { ENDPOINT_CONFIGURATION_CLIENT } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_CONFIGURATION_CLIENT;

export const ConfigurationClient = {
  get(
    params: Endpoints.Tyto.Configuration.Client.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<
      Tyto.Configuration.Client
    >;
  },
};
