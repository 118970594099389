/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { MultipleChoice } from "../../../components/common/";

import { SubIntefaceKey } from "../";

import "./CurComponentManager.scss";

interface Props {
  loading?: boolean;
  subInterface: SubIntefaceKey;
  updateSubInterfaceKey: (newKey: SubIntefaceKey) => void;
}

export default (props: Props) => {
  return (
    <div
      className={cx(
        "admin-content-subinterface-manager-cont",
        props.loading && "admin-content-subinterface-manager-cont-isloading"
      )}
    >
      <MultipleChoice
        className="admin-content-subinterface-manager-choices-cont"
        options={[
          {
            label: "Manage Teams",
            value: "default"
          },
          {
            label: "Manage People",
            value: "manage-people"
          }
        ]}
        onChange={newValue =>
          props.updateSubInterfaceKey(newValue as SubIntefaceKey)
        }
        value={props.subInterface}
      />
    </div>
  );
};
