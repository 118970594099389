/*
 * Component Description
 */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import * as _ from "lodash";

import { AuthResult, SessionData } from "../../../typings";
import { StoreContext, AppStoreProps } from "../../../data/stores/AppStore";
import { setSessionData } from "../../../data/storage/session";
import TytoCalls from "../../../data/tyto/";
import { Button, Input } from "../../common/";
import { setSessionDataForMultipleSessions } from "../../../data/storage/session";
import { PICK_SESSION_TARGET_ID_KEY } from "../../../data/constants/";

import AgnosticModal from "../agnostic";
import { SessionList } from "../components/";
import MultipleSessionsMatched from "../../../interfaces/logon/subcomponents/MultipleSessionsMatched";

import "./style.scss";

interface Props {
  className?: string;
  overlayClassName?: string;
  isAccountSelection?: boolean;
  isOpen?: boolean;
  modalStyle?: React.CSSProperties;
  onDismiss?: (newSession?: SessionData) => void;
  overlayStyle?: React.CSSProperties;
  sessionsArray?: SessionData[];
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const navigate = useNavigate();

  const [loggingIn, updateLoggingIn] = React.useState(false);
  const [logonName, updateLogonName] = React.useState("");
  const [password, updatePassword] = React.useState("");
  const [authResults, updateAuthResults] = React.useState<
    AuthResult[] | undefined
  >(undefined);
  const [error, updateError] = React.useState("");

  const canLogin = !loggingIn && !!logonName && !!password;

  return (
    <AgnosticModal
      className={cx(
        "login-modal-dialog-container",
        error && "log-has-error",
        authResults && authResults.length && "wrapping-multiple-sessions-cont",
        props.className
      )}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      {authResults && authResults.length ? (
        <MultipleSessionsMatched
          AppStore={AppStore}
          authResults={authResults}
          onChoose={props.onDismiss}
        />
      ) : (
        <section className="logon-modal-cont">
          <h2 className="logon-modal-title title-bold">
            {props.isAccountSelection
              ? "Switch Sessions"
              : "Login to Another Account"}
          </h2>
          {props.isAccountSelection ? (
            <SessionList
              onSessionSelect={(sessionData) => {
                const userID = _.get(sessionData, "userID", 0);

                if (userID) {
                  window.open(
                    `/account-selection?${PICK_SESSION_TARGET_ID_KEY}=${userID}&redirect=`
                  );
                }

                if (props.onDismiss) {
                  props.onDismiss();
                }

                // if (AppStore.dispatch) {
                //   AppStore.dispatch({
                //     callback: () => {
                //       if (props.onDismiss) {
                //         props.onDismiss();
                //       }
                //     },
                //     payload: sessionData,
                //     type: "USER_LOGGED_IN",
                //   });
                // }
              }}
              storedSessions={props.sessionsArray || []}
            />
          ) : (
            <>
              {error && (
                <p className="logon-modal-logonname-error-msg">{error}</p>
              )}

              <Input
                autofocus={true}
                containerClassName="logon-modal-logonname-input-cont"
                className="logon-modal-logonname-input"
                hideClear={true}
                name="Logon Name"
                onChange={(newLogonName) => updateLogonName(newLogonName)}
                placeholder="Email"
                value={logonName}
              />

              <Input
                containerClassName="logon-modal-logonname-input-cont"
                className="logon-modal-logonname-input"
                hideClear={true}
                name="Logon Password"
                onChange={(newPassword) => updatePassword(newPassword)}
                onEnter={() =>
                  startLogin({
                    AppStore,
                    onDismiss: props.onDismiss,
                    logonName,
                    password,
                    updateAuthResults,
                    updateError,
                    updateLogonName,
                    updateLoggingIn,
                    updatePassword,
                  })
                }
                placeholder="Password"
                type="password"
                value={password}
              />

              <Button
                className="logon-modal-logonname-button"
                type="color"
                shape="square"
                disabled={!canLogin}
                onClick={() =>
                  startLogin({
                    AppStore,
                    onDismiss: props.onDismiss,
                    logonName,
                    password,
                    updateAuthResults,
                    updateError,
                    updateLogonName,
                    updateLoggingIn,
                    updatePassword,
                  })
                }
                value={loggingIn ? "Logging in..." : "Login"}
              />
            </>
          )}
        </section>
      )}
    </AgnosticModal>
  );
};

const startLogin = async ({
  AppStore,
  logonName,
  onDismiss,
  password,
  updateAuthResults,
  updateLoggingIn,
  updateLogonName,
  updatePassword,
  updateError,
}: {
  AppStore: AppStoreProps;
  logonName: string;
  onDismiss?: (newSession?: SessionData) => void;
  password: string;
  updateAuthResults: (authResults: AuthResult[]) => void;
  updateLoggingIn: (val: boolean) => void;
  updateLogonName: (val: string) => void;
  updatePassword: (val: string) => void;
  updateError: (val: string) => void;
}) => {
  if (AppStore && AppStore.dispatch) {
    updateLoggingIn(true);
    updateError("");
    TytoCalls.Login.Authenticate4.post({
      username: logonName,
      password,
    })
      .then((resp) => {
        if (AppStore && AppStore.dispatch && Array.isArray(resp.authResults)) {
          if (resp.authResults.length === 1) {
            setSessionData(resp.authResults[0].authSession);

            if (onDismiss) {
              updatePassword("");
              updateLogonName("");
              updateLoggingIn(false);
              onDismiss();
            }

            const userID = _.get(resp, `authResults[0].authSession.userID`, 0);

            if (userID) {
              window.open(
                `/account-selection?${PICK_SESSION_TARGET_ID_KEY}=${userID}&redirect=`
              );
            }

            // AppStore.dispatch({
            //   callback: () => {
            //     if (onDismiss) {
            //       updatePassword("");
            //       updateLogonName("");
            //       updateLoggingIn(false);
            //       onDismiss(resp.authResults[0].authSession);
            //     }
            //   },
            //   payload: resp.authResults[0].authSession,
            //   type: "USER_LOGGED_IN"
            // });
          } else if (resp.authResults.length > 1) {
            const sessions = resp.authResults
              .map((ar) => ar.authSession)
              .filter((authSession) => authSession && authSession.userID);

            setSessionDataForMultipleSessions(sessions);

            updateAuthResults(resp.authResults);
          }
        } else {
          console.warn(
            "store or store.dispatch were not defined when they should be."
          );
        }
      })
      .catch((err) => {
        console.warn("An Error occurred while trying to Authenticate User");
        if (typeof err === "string") {
          updateError(`${err}`);
          updateLoggingIn(false);
        } else {
          updateError(_.get(err, "technical", "Error Occurred."));
          updateLoggingIn(false);
        }
      });
  }
};
