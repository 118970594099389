import { ENDPOINT_EMAIL_QUEUE_STATUS_USER } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_EMAIL_QUEUE_STATUS_USER;

export const EmailQueueStatusUser = {
  post(
    params: Endpoints.Tyto.EmailQueueStatus.User.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<{
      emailQueueStatus?: string;
      session: SessionData;
    }>;
  }
};
