/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import TytoCalls from "../../../../data/tyto/";
import { Button, FileInput, TextButton } from "../../../common/";
import { Tyto } from "../../../../typings/tyto";

import ExistingProfileImage from "./ExistingProfileImage";

interface Props {
  className?: string;
  closePopup?: () => void;
  onImageUpload: () => void;
  memberID: number;
  tryybProfile?: Tyto.Person;
  uploadURL: string;
}

export default (props: Props) => {
  const inputRef = React.useRef<React.MutableRefObject<HTMLInputElement>>();
  const [
    curSelectedExistingImageID,
    updateCurSelectedExistingImageID,
  ] = React.useState<number | undefined>(undefined);
  const [uploading, updateUploading] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [file, updateFile] = React.useState<File | Blob | null>(null);
  const [fileObjectURL, updateFileObjectURL] = React.useState("");
  const [newProfileImageID, updateNewProfileImageID] = React.useState<
    number | null
  >(null);

  if (!props.uploadURL) {
    return (
      <div>
        <h2>Upload Destination not found.</h2>
      </div>
    );
  }

  const existingProfileImages = _.get(
    props,
    "tryybProfile.profileImageAssets",
    []
  ) as Tyto.Asset[];

  return (
    <div className={cx("profileimage-modal-inner-cont", props.className)}>
      <h2 className="title-bold profileimage-modal-title">Select Photo</h2>

      <div className="profileimage-modal-inner-images-cont">
        <div
          className="profileimage-modal-inner-images-image-cont"
          style={{ flexGrow: 1 }}
        >
          <FileInput
            className={cx(
              "profileimage-modal-file-input",
              fileObjectURL && "profileimage-modal-file-input-hidden"
            )}
            acceptedFileTypes="image/png,image/jpg,image/jpeg"
            // @ts-ignore
            innerRef={inputRef}
            onChange={(files) => {
              if (Array.isArray(files) && files.length) {
                updateFile(files[0]);
                updateCurSelectedExistingImageID(undefined);

                if (fileObjectURL) {
                  URL.revokeObjectURL(fileObjectURL);
                }

                const objURL = URL.createObjectURL(files[0]);

                updateFileObjectURL(objURL);
              }
            }}
          />
        </div>

        {!fileObjectURL &&
          existingProfileImages &&
          !!existingProfileImages.length &&
          existingProfileImages.map((imageAsset) => (
            <ExistingProfileImage
              key={imageAsset.assetID}
              existingProfileImage={imageAsset}
              curPhotoAssetID={_.get(props, "tryybProfile.profileImageID", 0)}
              curSelectedImageID={curSelectedExistingImageID}
              chooseExistingImage={(newCurExistingImageID) =>
                updateCurSelectedExistingImageID(newCurExistingImageID)
              }
            />
          ))}
      </div>

      {!!fileObjectURL && (
        <>
          <section className="profileimage-modal-preview-cont">
            <img
              className="profileimage-modal-preview-img"
              src={fileObjectURL}
            />
          </section>

          <TextButton
            className="profileimage-modal-preview-reset-button"
            onClick={() => {
              if (fileObjectURL) {
                URL.revokeObjectURL(fileObjectURL);
              }

              updateFileObjectURL("");

              if (inputRef && inputRef.current) {
                // @ts-ignore
                inputRef.current.value = null;
              }
            }}
            value="Choose Different Image"
          />
        </>
      )}

      {error && (
        <p style={{ color: "red", fontSize: "11px", margin: "12px 0px" }}>
          {error}
        </p>
      )}

      <div className="profileimage-modal-inner-row-cont profileimage-modal-inner-buttons-cont">
        <Button
          className="profileimage-modal-inner-buttons-button"
          disabled={
            uploading || (!fileObjectURL && !curSelectedExistingImageID)
          }
          onClick={() => {
            updateError("");
            updateUploading(true);

            if (!!curSelectedExistingImageID && !fileObjectURL) {
              updateActiveProfileImage({
                userID: props.memberID,
                photoAssetID: curSelectedExistingImageID,
                onError: (errMsg) => {
                  updateUploading(false);
                  updateError(errMsg);
                },
                onSuccess: () => {
                  updateUploading(false);

                  props.onImageUpload();

                  if (props.closePopup) {
                    props.closePopup();
                  }
                },
              });
            } else {
              uploadPhoto({
                userID: props.memberID,
                file,
                onError: (errMsg) => {
                  updateUploading(false);
                  updateError(errMsg);
                },
                onSuccess: (profileImageID) => {
                  updateUploading(false);
                  updateNewProfileImageID(profileImageID);

                  if (fileObjectURL) {
                    URL.revokeObjectURL(fileObjectURL);
                  }

                  props.onImageUpload();

                  if (props.closePopup) {
                    props.closePopup();
                  }
                },
                uploadURL: props.uploadURL,
              });
            }
          }}
          value={getButtonText({
            uploading,
            fileObjectURL,
            curSelectedExistingImageID,
          })}
        />
      </div>
    </div>
  );
};

const getButtonText = ({
  uploading,
  fileObjectURL,
  curSelectedExistingImageID,
}: {
  uploading: boolean;
  fileObjectURL: string;
  curSelectedExistingImageID?: number;
}) => {
  if (uploading) {
    return !!curSelectedExistingImageID ? "Updating..." : "Uploading...";
  }

  return !!curSelectedExistingImageID
    ? "Update to use Selected Photo"
    : "Upload Image as Domain Logo";
};

const updateActiveProfileImage = async ({
  userID,
  photoAssetID,
  onError,
  onSuccess,
}: {
  userID: number;
  photoAssetID: number;
  onError: (errMsg: string) => void;
  onSuccess: () => void;
}) => {
  if (!userID || !photoAssetID) {
    return;
  }

  try {
    await TytoCalls.Person.put({
      personID: userID,
      profileImageID: photoAssetID,
    });

    onSuccess();
  } catch (err) {
    const error = JSON.stringify(err);

    onError(`${error}`);
  }
};

const uploadPhoto = async ({
  userID,
  file,
  onError,
  onSuccess,
  uploadURL,
}: {
  userID?: number;
  file: File | Blob | null;
  onError: (errMsg: string) => void;
  onSuccess: (profileImageID: number | null) => void;
  uploadURL?: string;
}) => {
  if (!uploadURL || !file || !userID) {
    onError("No Upload destination found.");
    return;
  }

  try {
    const resp = await TytoCalls.Upload.post({
      endpointURL: uploadURL,
      files: [file],
    });

    const uploadKey = _.get(resp, "uploadFiles[0].fileUploadKey", "");

    const domainResp = await TytoCalls.Person.ProfilePhoto.post({
      userID,
      uploadKey,
    });

    onSuccess(_.get(domainResp, "profileImageID", null));
  } catch (err) {
    const error = JSON.stringify(err);

    onError(`${error}`);
  }
};
