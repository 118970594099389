/*
 * Component Description
 */
import * as React from "react";

import { TextButton } from "../../../components/common/";
import { AppStoreProps } from "../../../data/stores/AppStore";
import {
  getLoggedInUserID,
  removeSessionData,
} from "../../../data/storage/session";

import "./CannotTakeDISC.scss";

interface Props {
  AppStore: AppStoreProps;
}

export default (props: Props) => {
  return (
    <section className="assessmenttaker-interface-cannottakedisc-cont">
      <h3 className="assessmenttaker-interface-cannottakedisc-title">
        You Do not have permission to take the DISC assessment at this time.
      </h3>

      <p className="assessmenttaker-interface-cannottakedisc-text">
        If you feel this is a mistake, or otherwise have any questions, please
        reach out to your TeamTools Partner.
        {/* <b>
          <a href="mailto:domain-admin@fake.com">domain-admin@fake.com</a>
        </b> */}
      </p>

      <div className="assessmenttaker-interface-cannottakedisc-logout-button-cont">
        <TextButton
          className="assessmenttaker-interface-cannottakedisc-logout-button"
          onClick={() => {
            if (props.AppStore && props.AppStore.dispatch) {
              const loggedInUserID = getLoggedInUserID();

              removeSessionData(loggedInUserID, () => {
                if (props.AppStore.dispatch) {
                  props.AppStore.dispatch({
                    payload: {},
                    type: "CLEAR_USER_SESSION_DATA",
                  });
                }
              });
            }
          }}
          value="Logout"
        />
      </div>
    </section>
  );
};
