import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../typings/tyto";
import { iconType } from "../../../components/common/icon/typings";
import { Icon } from "../../../components/common";
import { AdminStoreDispatch } from "../data/stores/AdminStore";
import { updateUser } from "../data/utils/";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  hide: boolean;
  person: Tyto.AdvancedPersonTeamMembership;
  personInfo: Tyto.AdvancedPerson;
  previewPersonID?: number;
}

export default ({
  AdminStoreDispatch,
  hide,
  person,
  personInfo,
  previewPersonID
}: Props) => {
  const [updatingMember, updateUpdatingMember] = React.useState(false);
  const isBeingPreviewed = !!(
    previewPersonID && previewPersonID === person.memberID
  );

  const displayName =
    personInfo.givenName || personInfo.familyName
      ? `${personInfo.givenName} ${personInfo.familyName}`
      : personInfo.email;

  if (hide) {
    return null;
  }

  return (
    <li
      className={cx(
        "admin-teams-team-structure-item-people-person-item",
        updatingMember &&
          "admin-teams-team-structure-item-people-person-item-updating",
        isBeingPreviewed &&
          "admin-teams-team-structure-item-people-person-item-being-previewed"
      )}
    >
      <Icon
        buttonProps={{
          className:
            "admin-teams-team-structure-item-people-person-teamleader-icon-cont",
          disabled: updatingMember,
          name: !!person.isTeamLeader
            ? "Icon for Team Leader (click to remove leadership title)"
            : "Icon for Non-Team Leader (click to add leadership title)"
        }}
        isButton={true}
        className={cx(
          "admin-teams-team-structure-item-people-person-teamleader-icon",
          !!person.isTeamLeader &&
            "team-structure-item-people-person-teamleader-icon-isteamleader"
        )}
        icon={!!person.isTeamLeader ? "crown-solid" : "crown"}
        onClick={() => {
          updateUpdatingMember(true);

          updateUser({
            teamID: person.teamID,
            memberID: person.memberID,
            updates: {
              isTeamLeader: !person.isTeamLeader
            },
            onError: () => {
              updateUpdatingMember(false);
            },
            onSuccess: successfulUpdates => {
              const newMembershipObject = {
                ...person,
                ...successfulUpdates
              };

              AdminStoreDispatch({
                payload: {
                  membership: newMembershipObject
                },
                type: "ADMIN_STORE_UPDATE_PERSON_MEMBERSHIP"
              });
              updateUpdatingMember(false);
            }
          });
        }}
        size={12}
      />

      <button
        className="admin-teams-team-structure-item-people-person-cont admin-managepeople-team-structure-item-people-person-cont"
        disabled={updatingMember}
        onClick={
          () => {}
          //   AdminStoreDispatch({
          //     payload: {
          //       previewPersonID: person.memberID
          //     },
          //     type: "ADMIN_STORE_PREVIEW_PERSON_ID"
          //   })
        }
      >
        <Icon
          className="admin-teams-team-structure-item-people-person-icon"
          icon={determineIcon({
            isTeamLeader: !!person.isTeamLeader,
            isBeingPreviewed
          })}
          size={12}
        />

        <p className="admin-teams-team-structure-item-people-person-name">
          {/* {person.} */}
          {displayName || <i>Name Not Found</i>}
        </p>
      </button>
    </li>
  );
};

const determineIcon = ({
  isBeingPreviewed,
  isTeamLeader
}: {
  isTeamLeader: boolean;
  isBeingPreviewed: boolean;
}): iconType => {
  if (isBeingPreviewed) {
    return isTeamLeader ? "user-crown-solid" : "user-alt-solid";
  }

  return isTeamLeader ? "user-crown" : "user-alt";
};
