/*
 * Component Description
 */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import * as _ from "lodash";

import {
  Icon,
  ToggleSection,
  UserThumb,
} from "../../../../../components/common";

import { Tyto } from "../../../../../typings/tyto";

import "./TeamPeople.scss";

interface Props {
  discMini: Tyto.DISCProfileMini[];
  setFocusedPerson: (personID: number) => void;
}

export default (props: Props) => {
  const navigate = useNavigate();

  return (
    <ToggleSection
      className="meta-sidepane-team-section-cont meta-sidepane-team-people-cont"
      closedAtStart={true}
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        const recipientsFitCount =
          getRecipientsThatCanFitCount(sectionWrapperWidth);
        const firstRecipients = _.slice(
          props.discMini,
          0,
          recipientsFitCount ? recipientsFitCount - 1 : 0
        );
        const showMoreIcon =
          !isExpanded &&
          recipientsFitCount &&
          recipientsFitCount - 1 < props.discMini.length;

        return (
          <>
            {(showMoreIcon ? firstRecipients : props.discMini).map(
              (profile, idx) => (
                <UserProfile
                  profile={profile}
                  idx={idx}
                  isExpanded={isExpanded}
                  key={profile.personID}
                  setAsFocusedProfile={() =>
                    props.setFocusedPerson(profile.personID)
                  }
                />
              )
            )}

            {showMoreIcon && (
              <div className="meta-sidepane-team-people-person-userthumb-cont more-icon-cont">
                <button className="meta-sidepane-team-people-person-more-icon">
                  +{props.discMini.length - firstRecipients.length}
                </button>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

const getRecipientsThatCanFitCount = (sectionWrapperWidth: number) => {
  if (!sectionWrapperWidth) {
    return 6;
  }

  return Math.floor((sectionWrapperWidth - 10 - 24) / 33);
};

const getSectionWidth = (
  sectionContRef: React.MutableRefObject<null | HTMLElement>
) => {
  //   debugger;
  if (sectionContRef.current) {
    const info = sectionContRef.current.getBoundingClientRect();
    return info.width;
  }

  return 0;
};

interface UserProfileProps {
  profile?: Tyto.DISCProfileMini;
  idx: number;
  isExpanded: boolean;
  setAsFocusedProfile: () => void;
}

const UserProfile = ({
  profile,
  idx,
  isExpanded,
  setAsFocusedProfile,
}: UserProfileProps) => {
  return (
    <div
      className={cx(
        "meta-sidepane-team-people-person-userthumb-cont",
        !idx && "first-userthumb",
        isExpanded && "meta-sidepane-team-people-person-userthumb-cont-full-row"
      )}
    >
      {profile && (
        <UserThumb
          asset={profile.profileImageAsset}
          className="team-people-person-userthumb"
          discMiniProfile={profile}
          discValues={{
            d: profile.d3,
            i: profile.i3,
            s: profile.s3,
            c: profile.c3,
          }}
          hasGradient={true}
          onClickIcon="user-alt"
          onClick={setAsFocusedProfile}
          size={43}
          userName={profile.personName}
        />
      )}

      {isExpanded && (
        <div className="meta-sidepane-team-people-person-info-cont">
          <p className="meta-sidepane-team-people-person-info-name">
            {profile ? profile.personName : ""}
          </p>
          <p className={cx("meta-sidepane-team-people-person-info-jobtitle")}>
            {profile ? profile.jobTitle : ""}
          </p>

          {profile && (
            <Icon
              buttonProps={{
                className: "meta-sidepane-team-people-person-info-btn",
              }}
              onClick={setAsFocusedProfile}
              icon="angle-right"
              isButton={true}
              size={30}
            />
          )}
        </div>
      )}
    </div>
  );
};
