/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Icon } from "../../common/";
import { Tyto } from "../../../typings/tyto";
import { StoreState as AppStoreState } from "../../../data/stores/AppStore";

import DomainInfo from "./DomainInfo";
import TeamBreadCrumb from "./subcomponents/TeamBreadCrumb";
import AccessLevels from "./subcomponents/AccessLevels";
import AddToTeam from "./subcomponents/AddToTeam";
import TeamImportUsers from "./subcomponents/TeamImportUsers";
import TeamMembership from "./subcomponents/TeamMembership";

import "./style.scss";

export interface Props {
  AppStoreState: AppStoreState;
  canClose?: boolean;
  className?: string;
  onConfigurationUpdate?: (teamID: number) => void;
  onClick?: () => void;
  onClose?: () => void;
  onPersonSelect: (personID: number) => void;
  onTeamSelect: (teamID: number) => void;
  roleID?: number;
  reloadTeamMembers: (teamID: number) => void;
  team?: Tyto.Team;
  teamID?: number;
  teamWithConfiguration?: Tyto.TeamGet;
}

export default (props: Props) => {
  const {
    discMiniByTeam,
    loggedInUserID,
    onlineUsersTable,
    discMini,
    websocketServerConnected,
  } = props.AppStoreState;
  const teamMembers = getProfilesOfTeamMembers({
    AppStoreState: props.AppStoreState,
    teamID: props.teamID,
  });
  const isDomain = _.get(props, "team.teamType", undefined) === "ocDOMAIN";
  const personalDISCMini = _.get(
    discMini,
    loggedInUserID,
    _.get(props.AppStoreState, "userDISCMini")
  );

  React.useEffect(() => {
    const teamID = _.get(props, "team.teamID") || props.teamID;

    if (!teamID || !props.onConfigurationUpdate) return;

    props.onConfigurationUpdate(teamID);
  }, [props.teamID, props.team]);

  // * If currently viewed Team is a Domain, render different domain specific sections
  if (isDomain && props.team) {
    return (
      <DomainInfo
        {...props}
        team={props.team}
        isDomain={isDomain}
        onlineUsersTable={onlineUsersTable}
        personalDISCMini={personalDISCMini}
        teamMembers={teamMembers}
        websocketServerConnected={websocketServerConnected}
      />
    );
  }

  return (
    <section
      className={cx("meta-super-sidepane-main-cont", props.className)}
      onClick={props.onClick}
    >
      {props.canClose && (
        <Icon
          className="meta-sidepane-close-button"
          isButton={true}
          icon="close"
          size={25}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />
      )}

      <TeamBreadCrumb onTeamSelect={props.onTeamSelect} team={props.team} />

      <AccessLevels
        onConfigurationUpdate={(teamID) => {
          if (props.onConfigurationUpdate) {
            props.onConfigurationUpdate(teamID);
          }
        }}
        personalDISCMini={personalDISCMini}
        team={props.team}
        teamWithConfiguration={props.teamWithConfiguration}
        teamID={_.get(props.team, "teamID", 0)}
      />

      <TeamMembership
        onlineUsersTable={onlineUsersTable}
        onPersonSelect={props.onPersonSelect}
        personalDISCMini={personalDISCMini}
        reloadTeamMembers={() =>
          props.reloadTeamMembers(_.get(props.team, "teamID", 0))
        }
        teamMembers={teamMembers}
        teamID={props.teamID}
        websocketServerConnected={websocketServerConnected}
      />

      <AddToTeam
        reloadTeamMembers={props.reloadTeamMembers}
        team={props.team}
      />

      {props.team && (
        <TeamImportUsers
          reloadTeamMembers={props.reloadTeamMembers}
          team={props.team}
        />
      )}
    </section>
  );
};

export function getProfilesOfTeamMembers({
  AppStoreState,
  teamID,
}: {
  AppStoreState: AppStoreState;
  teamID?: number;
}) {
  if (!teamID) {
    return undefined;
  }

  const teamList = _.get(AppStoreState, `discMiniByTeam[${`${teamID}`}]`);

  if (!Array.isArray(teamList)) {
    return undefined;
  }

  if (!teamList.length) {
    return [];
  }

  const miniProfiles = teamList.reduce(
    (accum: Tyto.DISCProfileMini[], userID) => {
      const miniProfile = _.get(AppStoreState, `discMini[${userID}]`);
      // debugger;

      // * If Mini found, add to list
      if (miniProfile) {
        accum.push(miniProfile);
      } else {
        const miniProfileNullProfile = _.get(
          AppStoreState,
          `discMiniNullProfiles[${userID}]`
        );

        // * If mini NOT found, but nullProfile found, add to list
        if (miniProfileNullProfile) {
          accum.push(miniProfileNullProfile);
        }
      }

      return accum;
    },
    []
  );

  return miniProfiles;
}
