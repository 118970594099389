import mixpanel from "mixpanel-browser";

import { MixPanelTrackEvents } from "../../typings/logging";
import { IS_DEV_ENV } from "../constants/";

export function initMixPanelPerson(userIdentifier: string | number) {
  if (IS_DEV_ENV) {
    console.log(`MixPanel indentification averted because of dev environment.`);
    return;
  }

  mixpanel.identify(`${userIdentifier}`);
}

export function triggerMixPanelEvent(
  eventName: MixPanelTrackEvents.MixPanelEventType,
  eventData: Object
) {
  if (IS_DEV_ENV) {
    console.log(`Logging Event averted because of dev environment.`);
    return;
  }

  console.log(`LOGGING EVENT: `, { type: eventName, data: eventData });

  mixpanel.track(eventName, eventData);
}

export function setMixPanelUserProperties(
  userIdentifier: string | number,
  properties: Object
) {
  if (IS_DEV_ENV) {
    console.log(
      `MixPanel users info update averted because of dev environment.`
    );
    return;
  }

  const info = {
    userID: userIdentifier,
    ...(properties || {})
  };

  // const infoAsJson = JSON.stringify(info);

  mixpanel.people.set(info);
}
