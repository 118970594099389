/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../typings/tyto";
import AgnosticModal from "../agnostic";
import UserRecovery from "./UserRecovery";

import "./style.scss";

interface Props {
  userID: number;
  isOpen: boolean;
  onDismiss: () => void;
  discProfileMini?: Tyto.DISCProfileMini;
  personalDiscMini?: Tyto.DISCProfileMini;
  title?: string;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className="modals-userrecovery-modal-main-cont"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-userrecovery-modal-overlay"
    >
      <UserRecovery
        discProfileMini={props.discProfileMini}
        personalDiscMini={props.personalDiscMini}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        title={props.title}
        userID={props.userID}
      />
    </AgnosticModal>
  );
};
