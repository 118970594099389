/*
 * Component Description
 */
import * as React from "react";

import { DISCValueRender, Icon, UserThumb } from "../../../components/common";
import {
  calcGradientForIcon,
  getIconTypeFromDISCType,
  isValidIcon,
} from "../../../data/utils/helpers";
import { Tyto } from "../../../typings/tyto";

import "./PersonHeader.scss";

interface Props {
  discMiniProfile: Tyto.DISCProfileMini;
}

export default ({ discMiniProfile }: Props) => {
  return (
    <section className="directory-profile-profile-header">
      <div className="directory-profile-profile-header-left-cont">
        <UserThumb
          className="directory-profile-profile-header-userthumb"
          asset={discMiniProfile.profileImageAsset}
          discValues={{
            d: discMiniProfile.d3,
            i: discMiniProfile.i3,
            s: discMiniProfile.s3,
            c: discMiniProfile.c3,
          }}
          userName={discMiniProfile.personName}
          size={135}
        />

        <div className="directory-profile-profile-header-left-info-cont">
          <h1 className="directory-profile-profile-header-username">
            {discMiniProfile.personName}
          </h1>
          <p className="directory-profile-profile-header-jobtitle">
            {discMiniProfile.jobTitle}
          </p>
          {discMiniProfile.phone1 && (
            <p className="directory-profile-profile-header-phone">
              <a
                className="directory-profile-profile-header-contact-icon-wrapper"
                href={`tel:${discMiniProfile.emails[0]}`}
              >
                <Icon
                  className="directory-profile-profile-header-contact-icon"
                  icon="phone"
                  size={12}
                />
              </a>
              {discMiniProfile.phone1}
            </p>
          )}
          {discMiniProfile.emails[0] && (
            <p className="directory-profile-profile-header-email">
              <a
                className="directory-profile-profile-header-contact-icon-wrapper"
                href={`mailto:${discMiniProfile.emails[0]}`}
              >
                <Icon
                  className="directory-profile-profile-header-contact-icon"
                  icon="envelope"
                  // isButton={true}
                  // onClick={() => {}}
                  size={12}
                />
              </a>
              {discMiniProfile.emails[0]}
            </p>
          )}
        </div>
      </div>

      <div className="directory-profile-profile-header-right-cont">
        <DISCValueRender
          className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper directory-profile-profile-header-style-icon"
          iconSize={100}
          isCompareValue={false}
          omitHiddenMsg={true}
          targetKey="graphic"
          discMiniProfile={discMiniProfile}
        >
          <Icon
            className="directory-profile-profile-header-style-icon"
            icon={isValidIcon(discMiniProfile.graphic)}
            gradient={calcGradientForIcon({
              d: discMiniProfile.d3,
              i: discMiniProfile.i3,
              s: discMiniProfile.s3,
              c: discMiniProfile.c3,
            })}
            size={100}
          />
        </DISCValueRender>

        <div className="directory-profile-profile-header-right-info-cont">
          <p className="directory-profile-profile-header-style-label">
            Personality
          </p>
          <p className="directory-profile-profile-header-style-name">
            <DISCValueRender
              className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper directory-profile-profile-header-style-icon"
              isCompareValue={false}
              omitHiddenMsg={true}
              targetKey="styleName"
              discMiniProfile={discMiniProfile}
            >
              {discMiniProfile.styleName3}
            </DISCValueRender>
          </p>
        </div>
      </div>
    </section>
  );
};
