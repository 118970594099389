/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../typings/tyto";
import { StoreState as AppStoreState } from "../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
  ParsedTeamMemberships
} from "../data/stores/AdminStore";
import SearchBar from "../subcomponents/SearchBar";
import Person from "./Person";

import "./People.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AdminStoreState: AdminStoreState;
  AppStoreState: AppStoreState;
  curDomainID: number;
  disabled: boolean;
  people?: Tyto.AdvancedPerson[];
  selectedPeopleIDs: { [x: number]: boolean };
  teamMemberships?: ParsedTeamMemberships;
}

export default (props: Props) => {
  const [peopleSearchTerm, updatePeopleSearchTerm] = React.useState("");
  const { curDomainID = 0 } = props.AppStoreState;

  const regExp = peopleSearchTerm
    ? new RegExp(_.escapeRegExp(peopleSearchTerm), "i")
    : undefined;

  const filteredPeople = props.people
    ? props.people.filter(person => person.domainID === curDomainID)
    : undefined;

  return (
    <div className="admin-content-people-manage-people-cont">
      <SearchBar
        disabled={props.disabled}
        onChange={newTerm => updatePeopleSearchTerm(newTerm)}
        placeholder="Filter People"
        value={peopleSearchTerm}
      />

      <ul className="admin-content-people-manage-people-list">
        {filteredPeople ? (
          filteredPeople.map(person => (
            <Person
              key={person.userID}
              disabled={props.disabled}
              onCheck={() =>
                props.AdminStoreDispatch({
                  payload: {
                    personID: person.userID
                  },
                  type: "ADMIN_STORE_TOGGLE_PERSON_ID"
                })
              }
              isChecked={
                !!props.AdminStoreState.selectedPeopleIDs[person.userID]
              }
              isRevealed={
                props.AdminStoreState.curDraggedPersonID === person.userID
              }
              onDrag={personID => {
                props.AdminStoreDispatch({
                  payload: {
                    personID
                  },
                  type: "ADMIN_STORE_UPDATE_DRAGGING_PERSONID"
                });
              }}
              person={person}
              selectPerson={() => {}}
              searchTerm={peopleSearchTerm}
              searchTermRegExp={regExp}
            />
          ))
        ) : (
          <li
            className="admin-content-people-manage-people-list-item"
            style={{ fontWeight: "bold" }}
          >
            Loading...
          </li>
        )}
      </ul>
    </div>
  );
};
