/*
 * Component Description
 */
import * as React from "react";

import Result from "./Result";
import ColumnLabels from "./ColumnLabels";

import { AppStoreProps } from "../../../../data/stores/AppStore";
import { Tyto } from "../../../../typings/tyto";

import "./Results.scss";

interface Props {
  autoAddToTeam: boolean;
  appStore: AppStoreProps;
  columnCount: number;
  columnKeys: string[];
  curHoveredColumn: number;
  deleteRow: (rowIdx: number) => void;
  importing: boolean;
  results: any[][];
  showDeleteButton: boolean;
  showDisabledRows: boolean;
  team: Tyto.Team;
  useEmailAsLogonName: boolean;
  updateCurHoveredColumn: (columnIdx: number) => void;
  updateColumnLabel: (labelIdx: number, value: string) => void;
  updateItem: (listItemIdx: number, itemIdx: number, value: any) => void;
}

export default (props: Props) => {
  return (
    <div className="modals-importusers-modal-results-cont">
      <ul
        className="modals-importusers-modal-results-list"
        style={
          !props.importing
            ? {
                width: `${props.columnCount * 200 +
                  20 +
                  (props.showDeleteButton ? 35 : 0)}px`
              }
            : undefined
        }
      >
        {!props.importing && (
          <ColumnLabels
            columnCount={props.columnCount}
            columnLabels={props.columnKeys}
            curHoveredColumn={props.curHoveredColumn}
            importing={props.importing}
            showDeleteButton={props.showDeleteButton}
            updateCurHoveredColumn={props.updateCurHoveredColumn}
            updateLabel={props.updateColumnLabel}
          />
        )}

        {Array.isArray(props.results) &&
          props.results.map((result, curIdx) => (
            <Result
              autoAddToTeam={props.autoAddToTeam}
              appStore={props.appStore}
              columnLabels={props.columnKeys}
              curHoveredColumn={props.curHoveredColumn}
              deleteRow={props.deleteRow}
              importing={props.importing}
              showDeleteButton={props.showDeleteButton}
              showDisabledRows={props.showDisabledRows}
              useEmailAsLogonName={props.useEmailAsLogonName}
              updateCurHoveredColumn={props.updateCurHoveredColumn}
              listItemIdx={curIdx}
              key={curIdx}
              result={result}
              team={props.team}
              updateItem={props.updateItem}
            />
          ))}
      </ul>
    </div>
  );
};
