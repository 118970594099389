/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import TytoCalls from "../../../../data/tyto/";
import {
  Icon,
  PlaceholderText,
  UserThumb,
  UserThumbPlaceholder,
} from "../../../common/";
import { SessionData } from "../../../../typings";
import { Tyto } from "../../../../typings/tyto";

import "./style.scss";

interface Props {
  canDeleteCurrentSession?: boolean;
  storedSessions: SessionData[];
  onSessionSelect: (sessionData: SessionData) => void;
  onSessionDelete?: (sessionData: SessionData) => void;
}

export default ({
  canDeleteCurrentSession = false,
  storedSessions = [],
  onSessionDelete,
  onSessionSelect,
}: Props) => {
  return (
    <ul className="logon-sessions-list">
      {storedSessions.map((storedSession, curIdx) => (
        <SessionListItem
          canDeleteCurrentSession={canDeleteCurrentSession}
          curIdx={curIdx}
          key={storedSession.sessionKey}
          storedSession={storedSession}
          onSessionDelete={onSessionDelete}
          onSessionSelect={onSessionSelect}
        />
      ))}
    </ul>
  );
};

const checkSessionIsAlive = async ({
  callback,
  removeSession,
  sessionInQuestion,
  updateHasCheckedSessionIsAlive,
}: {
  callback: () => void;
  removeSession?: (session: SessionData) => void;
  sessionInQuestion: SessionData;
  updateHasCheckedSessionIsAlive: (hasChecked: boolean) => void;
}) => {
  if (!sessionInQuestion) {
    return;
  }

  try {
    const resp = await TytoCalls.AccountSession.get({
      sessionKey: sessionInQuestion.sessionKey,
    });

    updateHasCheckedSessionIsAlive(true);
    callback();
    return;
  } catch (err) {
    if (removeSession) {
      removeSession(sessionInQuestion);
    }
  }
};

const loadDomainInfo = async (
  domainID: number,
  sessionKey: string,
  updateDomainInfo: (domain: Tyto.Team) => void
) => {
  if (!domainID) {
    return;
  }

  try {
    const resp = await TytoCalls.Team.get({ teamID: domainID, sessionKey });

    updateDomainInfo(resp.team as any);
  } catch (err) {
    debugger;
  }
};

const loadPersonInfo = async (
  userID: number,
  sessionKey: string,
  updateUserInfo: (user: Tyto.Person) => void
) => {
  try {
    const resp = await TytoCalls.Person.get({ personID: userID, sessionKey });

    updateUserInfo(resp.person);
  } catch (err) {
    debugger;
  }
};

interface SessionProps {
  curIdx: number;
  canDeleteCurrentSession?: boolean;
  onSessionSelect: (sessionData: SessionData) => void;
  onSessionDelete?: (sessionData: SessionData) => void;
  storedSession: SessionData;
}

const SessionListItem = ({
  curIdx,
  canDeleteCurrentSession = false,
  onSessionDelete,
  onSessionSelect,
  storedSession,
}: SessionProps) => {
  const [
    hasCheckedSessionIsAlive,
    updateHasCheckedSessionIsAlive,
  ] = React.useState(false);
  const [userInfo, updateUserInfo] = React.useState<Tyto.Person | null>(null);
  const [
    userDomainInfo,
    updateUserDomainInfo,
  ] = React.useState<Tyto.Team | null>(null);

  React.useEffect(() => {
    checkSessionIsAlive({
      callback: () => {
        loadPersonInfo(
          storedSession.userID,
          storedSession.sessionKey,
          updateUserInfo
        );
        // loadDomainInfo(
        //   storedSession.domainID,
        //   storedSession.sessionKey,
        //   updateUserDomainInfo
        // );
      },
      sessionInQuestion: storedSession,
      removeSession: onSessionDelete,
      updateHasCheckedSessionIsAlive,
    });
  }, []);

  if (!hasCheckedSessionIsAlive) {
    return (
      <li
        className={cx(
          "logon-sessions-list-item",
          !!curIdx && "not-first-session-list-item"
        )}
      >
        <span>Check Session...</span>
      </li>
    );
  }

  return (
    <li
      className={cx(
        "logon-sessions-list-item",
        !!curIdx && "not-first-session-list-item"
      )}
      key={storedSession.userID}
    >
      <button
        className={cx(
          "logon-sessions-list-item-button",
          !onSessionDelete && "logon-sessions-list-item-button-full"
        )}
        onClick={() => onSessionSelect(storedSession)}
      >
        {userInfo ? (
          <UserThumb
            asset={
              userInfo.profileImageAssets && userInfo.profileImageID
                ? userInfo.profileImageAssets.find(
                    (asset) => asset.assetID === userInfo.profileImageID
                  )
                : undefined
            }
            className="logon-sessions-list-item-userthumb"
            discValues={{
              d: 0,
              i: 0,
              s: 0,
              c: 0,
            }}
            hasGradient={false}
            size={32}
            userName={storedSession.userName}
          />
        ) : (
          <UserThumbPlaceholder
            className="logon-sessions-list-item-userthumb"
            size={32}
          />
        )}

        {storedSession.userName}
        <span style={{ paddingLeft: "8px", fontSize: "10px", opacity: 0.4 }}>
          {userInfo ? (
            userInfo.domain.domainName
          ) : (
            <PlaceholderText value="Domain Name" />
          )}
        </span>
      </button>

      {onSessionDelete && (
        <Icon
          className="logon-sessions-list-item-delete-button"
          isButton={true}
          icon="trash-alt"
          onClick={() => onSessionDelete(storedSession)}
          size={15}
        />
      )}
    </li>
  );
};
