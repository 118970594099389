/*
  * Component Description
*/
import * as React from "react";

import { Tyto } from "../../../typings/tyto";
import {
    getDomainMessage,
    saveDomainMessage,
} from "../../meta/super-side-pane/utils";
import PlainText from "./subcomponents/PlainText";
import HTMLBody from "./subcomponents/HTMLBody";

const DEFAULT_DOMAIN_MESSAGE: Partial<Tyto.Domain.Message> = {
    headLine: "",
    bodyHtml: "",
    bodyPlain: "",
};

interface Props {
    domainID: number;
    messageType: string;
    onDismiss: () => void;
    isOpen: boolean;
    title: string;
}

export default (props: Props) => {
    const [saving, updateSaving] = React.useState(false);
    const [error, updateError] = React.useState("");
    const [messageTypeLoaded, updateMessageTypeLoaded] = React.useState(false);
    const [
        domainMessage,
        updateDomainMessage,
    ] = React.useState<Tyto.Domain.Message | null>(null);
    const [headline, updateHeadline] = React.useState("");
    const [htmlBody, updateHTMLBody] = React.useState("");
    const [plainText, updatePlainText] = React.useState("");

    React.useEffect(() => {
        if ((!props.domainID && props.domainID !== 0) || !props.messageType) {
            updateError("Proper information not found.");
        } else {
            updateMessageTypeLoaded(false);
            updateHeadline("");
            updatePlainText("");
            updateHTMLBody("");

            getDomainMessage({
                domainID: props.domainID,
                messageType: props.messageType,
                onSuccess: (newDomainMessage) => {
                    const newMessage =
                        newDomainMessage ||
                        getDefaultMessage({
                            domainID: props.domainID,
                            messageType: props.messageType,
                        });

                    updateDomainMessage(newMessage);
                    updateHeadline(newMessage.headLine || "");
                    updateHTMLBody(newMessage.bodyHtml || "");
                    updatePlainText(newMessage.bodyPlain || "");

                    updateMessageTypeLoaded(true);
                },
                onError: (msg: string) => {
                    updateError(msg);
                    updateMessageTypeLoaded(true);
                },
            });
        }
    }, [props.domainID, props.messageType, props.isOpen]);

    return (
        <div className="modals-domainmessage-inner-cont">
            <h2 className="modals-domainmessage-title title-bold">{headline}</h2>

            {htmlBody ? (
                <HTMLBody htmlBody={htmlBody} plainText={plainText} />
            ) : (
                    <PlainText plainText={plainText} />
                )}
        </div>
    );
}

const getDefaultMessage = ({
    domainID,
    messageType,
}: {
    domainID: number;
    messageType: string;
}) => {
    return {
        ...DEFAULT_DOMAIN_MESSAGE,
        domainID,
        messageType,
        modifiedByID: 0,
        modifiedDate: new Date().toISOString(),
    } as Tyto.Domain.Message;
};
