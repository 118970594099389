import * as React from "react";
import cx from "classnames";
import { Tyto } from "../../../typings/tyto";

import { Checkbox, Icon, TextHighlighter } from "../../../components/common";
import { iconType } from "../../../components/common/icon/typings";
import TeamTreeItemWrapper from "./TeamTreeItemWrapper";
import { StoreState as AppStoreState } from "../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState
} from "../data/stores/AdminStore";
import TeamMember from "./TeamMember";

import "./TeamTreeItem.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AdminStoreState: AdminStoreState;
  chooseTeam: (teamID: number) => void;
  curDomainID: number;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  disabled: boolean;
  fadeUnselectedTeams: boolean;
  previewPersonID?: number;
  selectedPeopleIDs: {
    [x: number]: boolean;
  };
  selectedTeamsIDs: {
    [x: number]: boolean;
  };
  selectedTeamID: number;
  searchTerm: string;
  searchTermRegExp?: RegExp;
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
  depth: number;
}

export default (props: Props) => {
  const [showTeamMembers, updateShowTeamMembers] = React.useState(false);
  const childTeams = props.team.iPath
    ? props.teamsStructured[`${props.team.iPath}${props.team.teamID},`]
    : [];

  if (!!props.depth && props.team.domainID !== props.curDomainID) {
    return null;
  }

  const isSelected = props.team.teamID === props.selectedTeamID;

  const teamMembers = props.AdminStoreState.parsedMemberships
    ? props.AdminStoreState.parsedMemberships[props.team.teamID]
    : undefined;
  const isNestedDomain = !!props.depth && props.team.teamType === "ocDOMAIN";

  if (isNestedDomain) {
    return null;
  }

  const teamNameMatches = props.searchTermRegExp
    ? props.searchTermRegExp.test(props.team.name)
    : true;

  const shouldFade = teamShouldFade(
    !!props.searchTerm,
    teamNameMatches,
    !!props.fadeUnselectedTeams,
    !!props.selectedTeamsIDs[props.team.teamID]
  );

  const curDraggedPersonIsOnTeam =
    !!props.AdminStoreState.curDraggedPersonID &&
    teamMembers &&
    teamMembers.some(
      tm => tm.memberID === props.AdminStoreState.curDraggedPersonID
    );

  return (
    <>
      <li
        className={cx(
          "admin-teams-team-structure-item",
          "admin-managepeople-team-structure-item",
          isNestedDomain && "admin-teams-team-structure-item-is-nested-domain"
        )}
        style={{ paddingLeft: `${20 * props.depth - (props.depth ? 6 : 0)}px` }}
        id={`admin-team-${props.team.teamID}`}
      >
        <div className="admin-teams-team-structure-item-checkbox-cont">
          <Checkbox
            className="admin-teams-team-structure-item-checkbox"
            checked={!!props.selectedTeamsIDs[props.team.teamID]}
            disabled={props.disabled}
            onCheck={() =>
              props.AdminStoreDispatch({
                payload: {
                  teamID: props.team.teamID
                },
                type: "ADMIN_STORE_TOGGLE_TEAM_ID"
              })
            }
            size={16}
          />
        </div>

        <div
          className={cx(
            "admin-teams-team-structure-item-inner-cont",
            !!props.selectedTeamsIDs[props.team.teamID] &&
              "admin-teams-team-structure-item-inner-cont-selected"
          )}
        >
          <button
            className="admin-teams-team-structure-item-title-cont"
            disabled={isNestedDomain}
            // onClick={
            //   isNestedDomain
            //     ? undefined
            //     : () => props.chooseTeam(props.team.teamID)
            // }
          >
            <Icon
              className={cx(
                "admin-teams-team-structure-item-icon",
                shouldFade && "admin-teams-team-structure-item-icon-no-match"
              )}
              size={16}
              icon={getIconType({
                team: props.team,
                hasNestedTeams: childTeams && !!childTeams.length,
                asSolid: !!(
                  props.team.teamID === props.curDomainID ||
                  !!props.selectedTeamsIDs[props.team.teamID]
                )
              })}
            />

            <p
              className={cx(
                "admin-teams-team-structure-item-name",
                shouldFade && "admin-teams-team-structure-item-name-no-match"
              )}
            >
              <TextHighlighter
                highlightClassName=""
                searchTerm={props.searchTerm}
                text={props.team.name}
                type="hightlight"
              />
            </p>

            {props.team.isTeamToolsConfig && (
              <Icon
                className="admin-teams-team-structure-item-rules-icon"
                icon="arrow-alt-from-top"
                size={12}
              />
            )}
          </button>

          <div className="admin-teams-team-structure-item-contextmenu-cont admin-managepeople-team-item-toggle-button">
            <Icon
              className=""
              icon={showTeamMembers ? "angle-down" : "angle-left"}
              isButton={true}
              onClick={() => updateShowTeamMembers(!showTeamMembers)}
              size={16}
            />
          </div>

          {(showTeamMembers || curDraggedPersonIsOnTeam) && (
            <ul
              className="admin-teams-team-structure-item-people-list"
              aria-label={`Team Members list for ${props.team.name}`}
            >
              {Array.isArray(teamMembers) && !!teamMembers.length ? (
                teamMembers.length &&
                teamMembers.map(person => (
                  <TeamMember
                    AdminStoreDispatch={props.AdminStoreDispatch}
                    key={`${props.team.teamID}-${person.memberID}`}
                    hide={
                      !!props.AdminStoreState.curDraggedPersonID &&
                      person.memberID !==
                        props.AdminStoreState.curDraggedPersonID
                    }
                    person={person}
                    personInfo={
                      (props.AdminStoreState.indexedPeople &&
                        props.AdminStoreState.indexedPeople[person.memberID]) ||
                      ({} as Tyto.AdvancedPerson)
                    }
                    previewPersonID={props.previewPersonID}
                  />
                ))
              ) : (
                <li className="admin-teams-team-structure-item-people-person-item">
                  <div className="admin-teams-team-structure-item-people-person-cont">
                    <p className="admin-teams-team-structure-item-people-person-loading-msg">
                      {!teamMembers && "Empty"}
                    </p>
                  </div>
                </li>
              )}
            </ul>
          )}
        </div>
      </li>

      {Array.isArray(childTeams) &&
        childTeams.map(childTeam => (
          <TeamTreeItemWrapper
            AdminStoreDispatch={props.AdminStoreDispatch}
            AdminStoreState={props.AdminStoreState}
            chooseTeam={props.chooseTeam}
            curDomainID={props.curDomainID}
            discMiniByTeam={props.discMiniByTeam}
            disabled={props.disabled}
            fadeUnselectedTeams={props.fadeUnselectedTeams}
            previewPersonID={props.previewPersonID}
            key={childTeam.teamID}
            selectedPeopleIDs={props.selectedPeopleIDs}
            selectedTeamsIDs={props.selectedTeamsIDs}
            selectedTeamID={props.selectedTeamID}
            searchTerm={props.searchTerm}
            searchTermRegExp={props.searchTermRegExp}
            team={childTeam}
            teamsStructured={props.teamsStructured}
            depth={props.depth + 1}
          />
        ))}
    </>
  );
};

const getIconType = ({
  asSolid,
  hasNestedTeams,
  team
}: {
  team: Tyto.Team;
  hasNestedTeams: boolean;
  asSolid?: boolean;
}): iconType => {
  switch (team.teamType) {
    case "ocTEAM":
      if (hasNestedTeams) {
        return asSolid ? "users-class-solid" : "users-class";
      }
      return asSolid ? "users-solid" : "users";
    case "ocDOMAIN":
      return asSolid ? "home-lg-alt-solid" : "home-lg-alt";
    default:
      return "question";
  }
};

const teamShouldFade = (
  searchTerm: boolean,
  matchesSearchTerm: boolean,
  shouldFadeUnselectedTeams: boolean,
  teamIsSelected: boolean
) => {
  if (searchTerm && matchesSearchTerm) {
    return false;
  }

  if (shouldFadeUnselectedTeams && teamIsSelected) {
    return false;
  }

  if (!searchTerm && !shouldFadeUnselectedTeams) {
    return false;
  }

  return true;
};
