import axios, { AxiosRequestConfig } from "axios";
import * as _ from "lodash";

import {
  DEFAULT_LOCAL_STORAGE_EXPIRATION_TIME_MS,
  LOGGED_IN_USER_KEY,
  LAST_SESSION_ACTIVITY,
} from "../constants/";
import {
  formatXMLRequestForStorage,
  getItem as getLocalStorageItem,
  setItem as setLocalStorageItem,
  getTimestamp,
  getSessionStorageItem,
} from "../storage/utils";
import { getSessionKey } from "../storage/session";
import { SessionData } from "../../typings";
// import { getSessionKey } from "../utils/";

type CallType = "delete" | "get" | "post" | "post-form" | "put";

export type CallOpts = {
  circumventChangePasswordCheck?: boolean;
  paramsAsData?: boolean;
  omitSessionKey?: boolean;
  useLocalStorage?: boolean;
  localStorageAgeOverride?: number; // * In MS
  storeResponse?: boolean;
  respTarget?: string;
  axiosConfig?: AxiosRequestConfig;
};

const DEFAULT_OPTS = {
  validateStatus: (status: number) => {
    return status < 500; // Resolve only if the status code is less than 500
  },
};

export function callWrapper(
  callType: CallType,
  endpoint: string,
  params?: object,
  opts?: CallOpts
) {
  // * Get sessionKey from roamingSettings
  //   const storedSessionData = Office.context.roamingSettings.get(
  //     SESSION_DATA_KEY
  //   );
  //   const sessionKey = _.get(storedSessionData, "sessionKey", undefined);
  const loggedInUserIDFromStorage = getSessionStorageItem(
    LOGGED_IN_USER_KEY,
    true
  );
  const loggedInUserID = loggedInUserIDFromStorage
    ? parseInt(loggedInUserIDFromStorage)
    : 0;

  const sessionKey = getSessionKey(loggedInUserID);

  if (!!opts && !opts.omitSessionKey && !sessionKey) {
    console.log("no session key found in callWrapper function when expected.");
  }

  const paramsAltered =
    sessionKey && !(opts && opts.omitSessionKey)
      ? { ...(params || {}), sessionKey }
      : params || {};

  // * For DELETE Endpoints that want data as JSON and not as params
  const optsAltered =
    opts && opts.paramsAsData
      ? {
          ...opts,
          axiosConfig: {
            ...(opts.axiosConfig || {}),
            data: paramsAltered,
          },
        }
      : opts;

  switch (callType) {
    case "delete":
      return deleteCall(endpoint, paramsAltered, optsAltered);
    case "get":
      return getCall(endpoint, paramsAltered, opts);
    case "post":
      return postCall(endpoint, paramsAltered);
    case "post-form":
      return postFormCall(endpoint, paramsAltered);
    case "put":
      return putCall(endpoint, paramsAltered, opts);
    default:
      return new Promise((res, rej) => {
        rej(
          `Incorrect callType supplied. Expected 'delete', 'get', 'post', or 'put', but got ${callType}`
        );
      });
  }
}

export function deleteCall(endpoint: string, params: object, opts?: CallOpts) {
  const { axiosConfig = {} } = opts || {};

  return new Promise((res, rej) => {
    axios
      .delete(endpoint, { params, ...axiosConfig })
      .then((resp) => {
        updateSessionActivity();
        res(resp.data);
      })
      .catch((err) => {
        rej("An error occurred");
      });
  });
}

// axios.interceptors.response.use(
//   (onResp: any) => {
//     return onResp;
//   },
//   onRej => {
//     if (onRej && onRej.error) {
//       console.log("this got hit", onRej.error);
//     }

//     return onRej;
//   }
// );

export function getCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    if (opts && opts.useLocalStorage) {
      const localStorageKey = formatXMLRequestForStorage(endpoint, params);
      const timestamp = getTimestamp(localStorageKey);
      const timeAllotted =
        (opts && opts.localStorageAgeOverride) ||
        DEFAULT_LOCAL_STORAGE_EXPIRATION_TIME_MS;

      if (timestamp && timestamp + timeAllotted > Date.now()) {
        const storedData = getLocalStorageItem(localStorageKey);

        if (typeof storedData !== "undefined") {
          console.log(`Used stored Data for ${endpoint}`);
          res(storedData);
        } else {
          console.log(
            `GET call to ${endpoint} opted out of localStorage because data was undefined.`
          );
        }
      } else {
        console.log(
          `GET call to ${endpoint} opted out of localStorage because data was not found or expired.`
        );
      }
    }

    axios
      .get(endpoint, { params })
      .then((resp) => {
        if (!_.get(opts, "circumventChangePasswordCheck", false)) {
          checkIfPasswordChangeIsTrueOnSession(
            endpoint,
            _.get(resp, "data.session", undefined)
          );
        }

        updateSessionActivity();
        const respTarget = _.get(opts, "respTarget", undefined);

        // * If a target was supplied return that, but if it isn't or data can't be found simply return resp
        const data = respTarget
          ? _.get(resp.data, respTarget, resp.data)
          : resp.data;

        if (opts && opts.storeResponse) {
          if (endpoint) {
            const key = formatXMLRequestForStorage(endpoint, params);

            setLocalStorageItem(key, data);
          } else {
            console.warn("Expected endpoint url to be defined but was not.");
          }
        }

        res(data);
      })
      .catch((err) => {
        rej("An error occurred");
      });
  });
}

export function postCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .post(endpoint, params)
      .then((resp) => {
        if (!_.get(opts, "circumventChangePasswordCheck", false)) {
          checkIfPasswordChangeIsTrueOnSession(
            endpoint,
            _.get(resp, "data.session", undefined)
          );
        }

        updateSessionActivity();
        res(resp.data);
      })
      .catch((err) => {
        console.warn("Post Call errored", err);
        const errAsString = JSON.stringify(err);
        const error = _.get(err, "response.data.error", errAsString);
        rej(error || "An error occurred (could not parse error)");
      });
  });
}

export function postFormCall(
  endpoint: string,
  params: object,
  opts?: CallOpts
) {
  return new Promise((res, rej) => {
    try {
      const { sessionKey, files, ...restOfParams } = params as any;

      const form = new FormData();
      const file1 = files[0] as File;
      form.append("file1", file1);

      axios({
        method: "post",
        url: `${endpoint}?sessionKey=${sessionKey}`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: form,
      })
        .then((resp) => {
          if (!_.get(opts, "circumventChangePasswordCheck", false)) {
            checkIfPasswordChangeIsTrueOnSession(
              endpoint,
              _.get(resp, "data.session", undefined)
            );
          }

          updateSessionActivity();
          res(resp.data);
        })
        .catch((err) => {
          console.warn("Post Call errored", err);
          const errAsString = JSON.stringify(err);
          const error = _.get(err, "response.data.error", errAsString);
          rej(error || "An error occurred (could not parse error)");
        });

      // axios
      //   .post(`${endpoint}?sessionKey=${sessionKey}`, {
      //     ...restOfParams,
      //     headers: { "Content-Type": "multipart/form-data" },
      //     data: form,
      //   })
    } catch (err) {
      rej(`${err}`);
    }
  });
}

export function putCall(endpoint: string, params: object, opts?: CallOpts) {
  return new Promise((res, rej) => {
    axios
      .put(endpoint, {
        ...params,
        ..._.get(opts, "axiosConfig", {}),
        ...DEFAULT_OPTS,
      })
      .then((resp) => {
        if (!_.get(opts, "circumventChangePasswordCheck", false)) {
          checkIfPasswordChangeIsTrueOnSession(
            endpoint,
            _.get(resp, "data.session", undefined)
          );
        }

        updateSessionActivity();
        res(resp.data);
      })
      .catch((err) => {
        rej("An error occurred");
      });
  });
}

function checkIfPasswordChangeIsTrueOnSession(
  endpoint: string,
  sessionData: SessionData
) {
  if (_.get(sessionData, "changePassword", false)) {
    console.log(
      `Session found to need password change after getting response from ${endpoint}`
    );
    let pathName = window.location.pathname;

    if (/set-password\/my-password/gi.test(pathName)) {
      return;
    } else if (/set-password/gi.test(pathName)) {
      // if (/redirect/i.test(window.location.search)) {
      //   // TODO Handle pulling and checking existing redirect?
      // }

      pathName = "/";
    }

    window.location.assign(`/set-password/my-password?redirect=${pathName}`);
  }
}

function updateSessionActivity() {
  const now = Date.now();
  setLocalStorageItem(LAST_SESSION_ACTIVITY, `${now}`);
}

export function addParamsToURL(url: string, params: Record<string, any>) {
  const urlParsed = new URL(url);

  Object.keys(params).forEach((key) =>
    urlParsed.searchParams.append(key, params[key])
  );

  return urlParsed.href;
}
