/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { DialogContent, DialogOverlay } from "@reach/dialog";

import { DOMAIN_MESSAGE_WELCOME_MESSAGE_KEY, WELCOME_MESSAGE_DATE_AT_DISMISS } from "../../../data/constants/";
import TytoCalls from "../../../data/tyto/";
import { Tyto } from "../../../typings/tyto";
import { Crane, Icon, PlaceholderText } from "../../common/";
import { getItem as getLocalStorageItem, setItem as setLocalStorageItem } from "../../../data/storage/utils";

import "../style.scss";
import "./style.scss";
import { loadDomainMessage } from "../../meta/super-side-pane/utils";

interface Props {
  isOpen?: boolean;
  domainID: number;
  modalStyle?: React.CSSProperties;
  onDismiss?: () => void;
  overlayStyle?: React.CSSProperties;
  rootDomain?: number;
  toggleShow: (shouldShow?: boolean) => void;
}

export default ({
  domainID,
  isOpen,
  modalStyle,
  onDismiss,
  overlayStyle,
  rootDomain,
  toggleShow
}: Props) => {
  const [welcomeMessageLoaded, updateWelcomeMessageLoaded] = React.useState(
    false
  );
  const [
    welcomeMessage,
    updateWelcomeMessage,
  ] = React.useState<Tyto.Domain.Message | null>(null);

  React.useEffect(() => {
    if (isOpen) {
      const mainDiv = document.getElementById("main");

      if (mainDiv) {
        // mainDiv.style.filter = "blur(3px)";
        mainDiv.className = `${mainDiv.className} main-div-is-blurred`;
      }
    } else {
      const mainDiv = document.getElementById("main");

      if (mainDiv) {
        mainDiv.className = `App`;
      }
    }
  }, [isOpen]);

  React.useEffect(() => {
    getDomainWelcomeMessage({
      domainID,
      onError: (msg: string) => {
        updateWelcomeMessageLoaded(true);
        updateWelcomeMessage(null);
      },
      onSuccess: (welcomeMessageResp) => {
        if (domainID === rootDomain && welcomeMessageResp) {
          const storedDate = getLocalStorageItem(`${WELCOME_MESSAGE_DATE_AT_DISMISS}::${domainID}`);
          const storedDateFormatted = (typeof storedDate === "string" ? new Date(storedDate) : new Date()).toISOString();
          const respDateFormatted = new Date(welcomeMessageResp.modifiedDate).toISOString();

          if (!storedDate || storedDateFormatted !== respDateFormatted) {
            toggleShow(true);
          }
        }

        updateWelcomeMessage(welcomeMessageResp);
        updateWelcomeMessageLoaded(true);
      },
    });
  }, [domainID]);

  const useFallback = welcomeMessageLoaded && !welcomeMessage;

  return (
    <DialogOverlay
      className="modals-dialog-overlay"
      onDismiss={() => modifiedOnDismiss({ domainID, rootDomain, domainWelcomeMessage: welcomeMessage, onDismiss })}
      isOpen={isOpen}
      style={overlayStyle}
    >
      <DialogContent className="modals-dialog-content" style={modalStyle}>
        <section className="">
          <Icon
            buttonProps={{
              className: "modals-welcome-close-button",
            }}
            icon="window-close"
            isButton={true}
            onClick={() => modifiedOnDismiss({ domainID, rootDomain, domainWelcomeMessage: welcomeMessage, onDismiss })}
            size={25}
          />

          <Crane className="modals-welcome-crane" />

          {welcomeMessageLoaded ? (
            useFallback ? (
              defaultWelcomeMessage()
            ) : (
                <div>
                  <h1 className="modals-welcome-header title-bold">
                    {_.get(welcomeMessage, "headLine", "")}
                  </h1>

                  <p className="modals-welcome-text">
                    {_.get(welcomeMessage, "bodyPlain", "")}
                  </p>
                </div>
              )
          ) : (
              <div>
                <h1 className="modals-welcome-header title-bold">
                  <PlaceholderText value="Loading Title Text" />
                </h1>

                <p className="modals-welcome-text">
                  <PlaceholderText value="Loading Welcome Message Body Text. this is filler text while the actual data is loading. This should be disregarded as unuseful lorem ipsum. Do not actually consider this text real in any sense." />
                </p>

                <p className="modals-welcome-text">
                  <PlaceholderText
                    value="Loading Title Text. This is very early build of the platform - so don't be surprised
              when things move around."
                  />
                </p>
              </div>
            )}
        </section>
      </DialogContent>
    </DialogOverlay>
  );
};

const modifiedOnDismiss = ({
  domainID,
  rootDomain,
  domainWelcomeMessage,
  onDismiss
}: {
  domainID: number;
  domainWelcomeMessage: Tyto.Domain.Message | null;
  onDismiss?: () => void;
  rootDomain?: number;
}) => {
  if (domainWelcomeMessage) {
    setLocalStorageItem(`${WELCOME_MESSAGE_DATE_AT_DISMISS}::${domainID}`, domainWelcomeMessage.modifiedDate);
  }

  if (onDismiss) {
    onDismiss();
  }
}

const getDomainWelcomeMessage = async ({
  domainID,
  onError,
  onSuccess,
}: {
  domainID: number;
  onSuccess: (welcomeMessage: Tyto.Domain.Message | null) => void;
  onError: (msg: string) => void;
}) => {
  if (!domainID) {
    onSuccess(null);
  }

  try {
    const curDomainWelcomeMessage = await loadDomainWelcomeMessage({ domainID, onError, onSuccess });

    if (curDomainWelcomeMessage) {
      onSuccess(curDomainWelcomeMessage);
      return;
    }

    const domain0WelcomeMessage = await loadDomainWelcomeMessage({ domainID: 0, onError, onSuccess });

    onSuccess(domain0WelcomeMessage || null);
  } catch (err) {
    onError(
      typeof err == "string"
        ? err
        : _.get(err, "error.msg", "Could not Load Domain Welcome Message")
    );
  }
}

const loadDomainWelcomeMessage = async ({
  domainID,
  onError,
  onSuccess,
}: {
  domainID: number;
  onSuccess: (welcomeMessage: Tyto.Domain.Message | null) => void;
  onError: (msg: string) => void;
}) => {
  if (!domainID && domainID !== 0) {
    onSuccess(null);
  }

  try {
    const resp = await TytoCalls.Domain.Messages.get({
      domainID,
      messageType: DOMAIN_MESSAGE_WELCOME_MESSAGE_KEY,
    });

    // debugger;
    return resp.domainMessages[0] || null;
  } catch (err) {
    onError(
      typeof err == "string"
        ? err
        : _.get(err, "error.msg", "Could not Load Domain Welcome Message")
    );
  }
};

const defaultWelcomeMessage = () => {
  return (
    <div>
      <h1 className="modals-welcome-header">Welcome to TeamTools!</h1>

      <p className="modals-welcome-text">
        Welcome to this alpha preview of TeamTools.
      </p>

      <p className="modals-welcome-text">
        This is very early build of the platform - so don't be surprised when
        things move around.
      </p>

      <p className="modals-welcome-text">
        Some things are not going to work as expected. But that doesn't mean you
        shouldn't let us know! Your input will help us make something cool! Hit
        the feedback button for any reason:
      </p>

      <ul className="modals-welcome-list">
        <li className="modals-welcome-list-item">Something is broken</li>
        <li className="modals-welcome-list-item">
          Something isn't behaving like you are expecting
        </li>
        <li className="modals-welcome-list-item">
          You don't like a design choice
        </li>
        <li className="modals-welcome-list-item">
          You want to suggest a feature
        </li>
      </ul>

      <p className="modals-welcome-text">Thanks again for participating!</p>
    </div>
  );
};
