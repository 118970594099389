/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Routes, Route } from "react-router-dom";

import pkg from "../../package.json";
import { Loading } from "../components/common/";
import { StoreContext } from "../data/stores/AppStore";
import WebsocketClient from "../data/websocket/";
import {
  loadStarterData,
  testSession,
  thereIsSessionActivityWithinAnHour,
} from "../data/utils/";
import {
  curSessionStillExists,
  getSessionsAsArray,
} from "../data/storage/session";
import Antechamber from "../interfaces/antechamber/";
import AdminDashboard from "../interfaces/admin/";
import AssessmentTaker from "../interfaces/assessment-taker/";
import Directory from "../interfaces/directory/";
import Four04 from "./404/";
import Groups from "../interfaces/groups";
import Home from "../interfaces/home/";
import Login from "../interfaces/logon/";
import PartnerDashboard from "../interfaces/partner-dashboard/";
import Profile from "../interfaces/profile/";
import { SessionData } from "../typings";
import WebSocketClient from "../data/websocket/";

interface Props {
  visibilityState: "prerender" | "hidden" | "visible";
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);
  const [needsAssessment, updateNeedsAssessment] = React.useState(
    _.get(store, "state.needsAssessment")
  );
  const [sessionData, updateSessionData] = React.useState(() => {
    const sessions = getSessionsAsArray();

    if (!sessionStorage.length || sessions.length > 1) {
      return undefined;
    } else {
      return sessions[0];
    }
  });

  // ! THIS IS REALLY BAD - Excessive updates can clog the thread
  WebSocketClient.setAppStore(store);

  // * [E-1] - Attempt to grab sessionData and load initialData on mount
  React.useEffect(() => {
    if (!/localhost:/i.test(_.get(window, "location.host", "")) || true) {
      // * ======================================
      // * START UP WEBSOCKET !!!!! =============
      WebsocketClient.initialize({
        AppStore: store,
      });
      // * ======================================
      // * ======================================
    }

    const checkSession = async () => {
      loadStarterData(store);
    };

    checkSession();
  }, []);

  // * [E-2] - Reload start data
  React.useEffect(() => {
    console.log("Top level session change triggered in router.");
    const newSessionData: SessionData | undefined = _.get(
      store,
      "state.sessionData"
    );

    // * Case 1 - Have sessionData, didn't previously have any
    if (newSessionData && newSessionData.sessionKey && !sessionData) {
      console.log("Router Sesison CASE 1 triggered");
      loadStarterData(store);
      updateSessionData(newSessionData);
      // * Case 2 - Don't have sessionData anymore, use to have sessionData (probably logged out)
    } else if (!newSessionData && sessionData && sessionData.sessionKey) {
      updateSessionData(newSessionData);
      // * Case 3 - Have SessionData, but is different than previous sessionData
    } else if (
      newSessionData &&
      sessionData &&
      newSessionData.userID &&
      sessionData.userID &&
      newSessionData.userID !== sessionData.userID
    ) {
      console.log("SessionData Case 3 Triggered!");
      loadStarterData(store, true);
      updateSessionData(newSessionData);
    }
  }, [_.get(store, "state.sessionData")]);

  // * [E-3] - When needsAssessment Value changes in AppStore State, update local state mirror or value
  React.useEffect(() => {
    updateNeedsAssessment(_.get(store, "state.needsAssessment"));
  }, [_.get(store, "state.needsAssessment")]);

  // * [E-4] - Clear Session when session isn't valid after routing back to page
  React.useEffect(() => {
    if (props.visibilityState !== "visible") {
      return;
    }

    const sessionStillValid = curSessionStillExists();
    console.log("Page Visibility change triggered: ", props.visibilityState);

    if (!sessionStillValid && sessionData) {
      if (store.dispatch) {
        store.dispatch({
          payload: {},
          type: "CLEAR_USER_SESSION_DATA",
        });
      }
    } else if (
      props.visibilityState === "visible" &&
      !/\/login/g.test(window.location.pathname) &&
      !thereIsSessionActivityWithinAnHour()
    ) {
      testSession({
        onFail: () => {
          if (store.dispatch) {
            store.dispatch({
              payload: {},
              type: "CLEAR_USER_SESSION_DATA",
            });
          }
        },
      });
    }
  }, [props.visibilityState]);

  // * =============================================
  // * End of Effects ==============================
  // * =============================================

  if (_.get(store, "state.CRITICAL_ERROR", undefined)) {
    return (
      <section className="logon-interface">
        <div className="logon-inner-wrapper">
          <div className="logon-cont">
            <h1 className="logon-title extra-space title-bold">Uh oh...</h1>
            <p className="logon-critical-error-msg">
              {_.get(store, "state.CRITICAL_ERROR", undefined)}
            </p>

            <p className="logon-teamtools-version-id">
              {_.get(pkg, "version", "")}
            </p>
          </div>
        </div>
      </section>
    );
  }

  if (
    !_.get(store, "state.hasCheckedForStoredSession", false) ||
    (_.get(store, "state.hasCheckedForStoredSession", false) &&
      _.get(store, "state.sessionData", undefined) &&
      typeof needsAssessment === "undefined")
  ) {
    return (
      <div className="loading-crane-cont">
        <Loading size={150} type="squares" />
      </div>
    );
  } else if (
    !!_.get(store, "state.sessionData", undefined) &&
    !_.get(
      store,
      `state.loggedInUserTeamToolsPermit[${_.get(
        store,
        "state.loggedInUserID",
        undefined
      )}]`,
      undefined
    )
  ) {
    return (
      <div className="loading-crane-cont">
        <Loading size={150} type="squares" />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/admin" element={<AdminDashboard />} />
      <Route
        path="/waiting-room"
        element={<Antechamber AppStore={store} sessionData={sessionData} />}
      />
      <Route
        path="/antechamber"
        element={<Antechamber AppStore={store} sessionData={sessionData} />}
      />
      <Route path="take-assessment" element={<AssessmentTaker />} />
      <Route path="/groups/preview" element={<Groups />} />
      <Route path="/" element={<Home />} />
      <Route path="/team/:teamID" element={<Home />} />
      <Route path="/directory" element={<Directory />} />
      <Route path="/login" element={<Login />} />
      <Route path="/join" element={<Login creation={false} />} />
      <Route
        path="/join/:tempSessionKey"
        element={<Login creation={false} />}
      />
      <Route path="/login/:loginName" element={<Login />} />
      <Route path="/account-creation" element={<Login creation={true} />} />
      <Route path="/account-selection" element={<Login creation={false} />} />
      <Route path="/set-password" element={<Login creation={false} />} />
      <Route
        path="/set-password/my-password"
        element={<Login creation={false} />}
      />
      <Route path="/session-check" element={<Login creation={false} />} />
      <Route path="/partner-dashboard" element={<PartnerDashboard />} />
      <Route path="/profile/:userID" element={<Profile />} />
      <Route path="/profile/:userID/:curTabName" element={<Profile />} />
      <Route element={<Four04 default />} />
    </Routes>
  );
};
