/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import TytoCalls from "../../../../data/tyto/";
import { Tyto } from "../../../../typings/tyto";

import { TextButton, Select } from "../../../../components/common/";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "../../data/stores/AdminStore";
import TeamTreeItemAddTeam from "../TeamTreeItemAddTeam";

import "./AddTeam.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  domain: Tyto.Team;
  subTree: Tyto.Team[];
}

export default (props: Props) => {
  const [showAddTeam, updateShowAddTeam] = React.useState(false);
  const [selectedTeam, updateSelectedTeam] = React.useState<
    Tyto.Team | undefined
  >(props.domain);
  //   const [newTeamName, updateNewTeamName] = React.useState("");
  //   const [creating, updateCreating] = React.useState(false);
  const [error, updateError] = React.useState("");

  const subTreePlusDomain = [props.domain, ...props.subTree];

  return (
    <div className="admin-teams-team-structure-domain-add-team-cont">
      <TextButton
        className="admin-teams-team-structure-domain-add-team-button"
        value={showAddTeam ? "Cancel" : "Add Team"}
        onClick={() => updateShowAddTeam(!showAddTeam)}
      />

      {!!showAddTeam && (
        <>
          <Select
            containerClassName="admin-teams-team-structure-domain-add-team-select-cont"
            className="admin-teams-team-structure-domain-add-team-select"
            options={subTreePlusDomain.map((item) => ({
              label: item.name,
              value: item.teamID,
            }))}
            omitFilter={true}
            canClear={false}
            onChange={(teamID) => {
              const newTeam = subTreePlusDomain.find(
                (team) => `${team.teamID}` === teamID
              );

              updateSelectedTeam(newTeam);
            }}
            value={_.get(selectedTeam, "teamID", 0)}
            placeholder="New Team Location"
          />

          {error && (
            <p className="admin-teams-team-structure-domain-add-team-error-msg">
              {error}
            </p>
          )}

          {selectedTeam && (
            <TeamTreeItemAddTeam
              onCreation={(team) => {
                props.AdminStoreDispatch({
                  payload: {
                    team,
                  },
                  type: "ADMIN_STORE_TEAM_CREATED",
                });

                updateShowAddTeam(false);
              }}
              onError={(errMsg) => updateError(errMsg || "")}
              team={selectedTeam}
            />
          )}
        </>
      )}
    </div>
  );
};

// const createTeam = async ({
//     primaryElementID,
//     teamName,
//     onError,
//     onSuccess
// }: {
//     primaryElementID: number;
//     teamName: string;
//     onError: (errMsg: string) => void;
//     onSuccess: (teamID: number) => void;
// }) => {
//     try {
//         const { teamID } = await TytoCalls.Team.post({
//             primaryElementID,
//             teamName
//         });

//         onSuccess(teamID);
//     } catch (err) {
//         const msg = typeof err === "string" ? err : _.get(err, "msg", "Could not create Team");

//         onError(msg);
//     }
// }
