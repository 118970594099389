/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreAction } from "../../../../typings";
import { Tyto } from "../../../../typings/tyto";
import { CUR_DOMAINID_KEY } from "../../../../data/constants/";
import { getSessionStorageItem } from "../../../../data/storage/utils";
import {
  setDirectorySearchRecents,
  getSearchStoreKey,
  getSelectedUsersKey
} from "../../utils/";
import { triggerMixPanelEvent } from "../../../../data/logging/";

export interface DirectoryProps {
  state?: StoreState;
  dispatch?: React.Dispatch<StoreAction<RecipientActionType>>;
}

export interface StoreState {
  activeSearchTerms: {
    [x: string]: string;
  };
  allPeople: {
    [x: string]: Tyto.AdvancedPerson[];
  };
  people: {
    [x: string]: Tyto.AdvancedPerson[];
  };
  recents: number[];
  selectedUsers: {
    [x: string]: {
      [x: number]: boolean;
    };
  };
}

type RecipientActionType =
  | "DIRECTORY_SEARCH_USER_SEARCH_LOADED"
  | "DIRECTORY_SEARCH_CLEAR_SELECTED_USER"
  | "DIRECTORY_SEARCH_UPDATE_ACTIVE_SEARCH_TERM"
  | "DIRECTORY_SEARCH_UPDATE_RECENTS_USERIDS"
  | "DIRECTORY_SEARCH_TOGGLE_SELECTED_USER";

let StoreContext: React.Context<DirectoryProps> = React.createContext({});

let initialState: StoreState = {
  activeSearchTerms: {},
  allPeople: {},
  people: {},
  recents: [],
  selectedUsers: {}
};

let reducer = (state: StoreState, action: StoreAction<RecipientActionType>) => {
  console.log(action);

  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case "DIRECTORY_SEARCH_USER_SEARCH_LOADED":
      if (Array.isArray(action.payload.users)) {
        const storeKey = getSearchStoreKey({
          domainID: action.payload.domainID,
          userID: action.payload.userID,
          searchTerm: action.payload.searchTerm
        });

        const newPeople = {
          ...state.people,
          [storeKey]: action.payload.users
        };

        const allPeopleKey = getSelectedUsersKey({
          domainID: action.payload.domainID,
          userID: action.payload.userID
        });

        const newAllPeople = {
          ...state.allPeople,
          [allPeopleKey]: _.uniqBy(
            [...(state.allPeople[allPeopleKey] || []), ...action.payload.users],
            "userID"
          )
        };

        return {
          ...state,
          allPeople: newAllPeople,
          people: newPeople
        };
      }

      return state;
    case "DIRECTORY_SEARCH_CLEAR_SELECTED_USER":
      if (action.payload.key) {
        const newSelectedUsers = {
          ...state.selectedUsers,
          [action.payload.key]: {}
        };

        return {
          ...state,
          selectedUsers: newSelectedUsers
        };
      }

      return state;
    case "DIRECTORY_SEARCH_UPDATE_ACTIVE_SEARCH_TERM":
      if (action.payload.key) {
        const newActiveSearchs = {
          ...state.activeSearchTerms,
          [action.payload.key]: action.payload.activeSearchTerm || ""
        };

        triggerMixPanelEvent("DIRECTORY_SEARCH_TRIGGERED", {
          searchTerm: action.payload.activeSearchTerm || ""
        });

        return {
          ...state,
          activeSearchTerms: newActiveSearchs
        };
      }

      return state;
    case "DIRECTORY_SEARCH_UPDATE_RECENTS_USERIDS":
      if (action.payload.userIDs) {
        return {
          ...state,
          recents: action.payload.userIDs
        };
      }

      return state;
    case "DIRECTORY_SEARCH_TOGGLE_SELECTED_USER":
      if (action.payload.key && action.payload.userID) {
        // * [1] - Update localStorage for recents
        const newRecents: number[] = _.slice(
          _.uniq([action.payload.userID, ...(state.recents || [])]),
          0,
          10
        );
        const curDomainID = getSessionStorageItem(CUR_DOMAINID_KEY, true);
        setDirectorySearchRecents(newRecents, (curDomainID || 0) as number);

        // * [2] - Toggle userID
        // * Safe way of updating state.selectedUsers[action.payload.key][action.payload.userID]
        const newSelectedUsers = {
          ...state.selectedUsers,
          [action.payload.key]: {
            ...(state.selectedUsers[action.payload.key] || {}),
            [action.payload.userID]: !(state.selectedUsers[
              action.payload.key
            ] || {})[action.payload.userID]
          }
        };

        return {
          ...state,
          selectedUsers: newSelectedUsers
        };
      }

      return state;
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
