import { ENDPOINT_PERSON_PASSWORD } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_PERSON_PASSWORD;

export const PersonPassword = {
  put(
    params: Endpoints.Tyto.Person.Password.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "put",
      endpoint,
      { ...params },
      { circumventChangePasswordCheck: true, ...(callOpts || {}) }
    ) as Promise<{
      recordsAffected: number;
      session: SessionData;
    }>;
  },
};
