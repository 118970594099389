/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import {
  CurrentlyOnlineBadge,
  DISCValueRender,
  Icon,
  UserThumb,
} from "../../../../../components/common/";
import { UserOnlineInfo } from "../../../../../data/stores/AppStore";
import { Tyto } from "../../../../../typings/tyto";
import {
  calcGradientForIcon,
  getIconTypeFromDISCType,
  isValidIcon,
} from "../../../../../data/utils/helpers/";

import "./PersonHeader.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMini: Tyto.DISCProfileMini;
  hasDISCProfile: boolean;
  userOnlineInfo?: UserOnlineInfo;
}

export default ({ discCompareProfile, discMini, userOnlineInfo }: Props) => {
  const [discIcon, updateDiscIcon] = React.useState(
    // getIconTypeFromDISCType(discMini ? discMini.styleName3 : "NA")
    isValidIcon(discMini ? discMini.graphic : undefined)
  );
  const [iconGradient, updateIconGradient] = React.useState(
    calcGradientForIcon({
      d: discMini.d3,
      i: discMini.i3,
      s: discMini.s3,
      c: discMini.c3,
    })
  );
  const [showConfirm, updateShowConfirm] = React.useState(false);

  React.useEffect(() => {
    updateDiscIcon(
      // getIconTypeFromDISCType(discMini ? discMini.styleName3 : "NA")
      isValidIcon(discMini ? discMini.graphic : undefined)
    );
    updateIconGradient(
      calcGradientForIcon({
        d: discMini.d3,
        i: discMini.i3,
        s: discMini.s3,
        c: discMini.c3,
      })
    );
  }, [discMini]);

  const navigate = useNavigate();

  const isOnline = !!_.get(userOnlineInfo, "connectionsCount", 0);

  return (
    <section className="meta-sidepane-person-header-cont">
      <div className="meta-sidepane-person-header-cont-images-cont">
        <UserThumb
          className="meta-sidepane-person-header-userthumb"
          hasGradient={true}
          key={discMini.personID}
          discMiniProfile={discMini}
          discValues={{
            d: discMini.d3,
            i: discMini.i3,
            s: discMini.s3,
            c: discMini.c3,
          }}
          asset={discMini.profileImageAsset}
          onClick={() => navigate(`/profile/${discMini.personID}`)}
          photoID={discMini.profileImageID}
          size={125}
          userName={discMini.personName}
          style={{
            zIndex: showConfirm ? 51 : 1,
            ...(showConfirm
              ? {
                  position: "absolute",
                  left: "25%",
                  top: "calc(50vh - 275px)",
                }
              : {}),
          }}
        />

        <div>
          <DISCValueRender
            className="directory-profile-profile-header-wrapper"
            iconSize={44}
            isCompareValue={true}
            compareProfile={discCompareProfile}
            omitHiddenMsg={true}
            targetKey="style.graphic"
            targetKeys={["style.graphic", "style.styleName"]}
            discMiniProfile={discMini}
          >
            <DISCValueRender
              className="directory-profile-profile-header-wrapper"
              iconSize={44}
              isCompareValue={true}
              compareProfile={discCompareProfile}
              omitHiddenMsg={true}
              targetKey="style.graphic"
              discMiniProfile={discMini}
            >
              <Icon
                buttonProps={{
                  className: "",
                }}
                className="meta-sidepane-person-header-disc-profile-icon"
                gradient={iconGradient}
                icon={discIcon}
                isButton={true}
                onClick={() =>
                  navigate(`/profile/${discMini.personID}/about-r3type`)
                }
                size={65}
              />
            </DISCValueRender>

            <p className="meta-sidepane-person-header-disc-profile-title">
              <DISCValueRender
                className="directory-profile-profile-header-wrapper"
                isCompareValue={true}
                compareProfile={discCompareProfile}
                omitHiddenMsg={true}
                targetKey="style.styleName"
                discMiniProfile={discMini}
              >
                {discMini.styleName3}
              </DISCValueRender>
            </p>
          </DISCValueRender>
        </div>
      </div>

      <h1 className="meta-sidepane-person-header-name title-bold">
        {discMini.personName}
      </h1>

      {discMini.jobTitle && (
        <p className="meta-sidepane-person-header-jobtitle">
          {discMini.jobTitle}
        </p>
      )}

      {discMini.emails[0] && (
        <p className="meta-sidepane-person-header-jobtitle-contact-info">
          <a
            className="meta-sidepane-person-header-jobtitle-contact-icon-cont"
            target="_blank"
            href={`mailto:${discMini.emails[0]}`}
          >
            <Icon icon="envelope" size={13} />
          </a>
          {discMini.emails[0]}
        </p>
      )}

      {discMini.phone1 && (
        <p className="meta-sidepane-person-header-jobtitle-contact-info">
          <Icon
            buttonProps={{
              className:
                "meta-sidepane-person-header-jobtitle-contact-icon-cont",
            }}
            icon="phone"
            isButton={true}
            onClick={() => {
              // TODO
            }}
            size={13}
          />
          {discMini.phone1}
        </p>
      )}

      {isOnline && (
        <div className="curreny-online-badge-cont">
          <CurrentlyOnlineBadge className="meta-sidepane-person-header-badge" />
        </div>
      )}
    </section>
  );
};
