import { ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_TEAM_TOOLS_INVITE_TEMPSESSION;

export const TeamToolsInviteTempSession = {
  post(
    params: Endpoints.Tyto.TeamToolsInvite.TempSession.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params, {
      circumventChangePasswordCheck: true,
      ...(callOpts || {}),
    }) as Promise<{
      newSession: SessionData;
      accountSession: SessionData;
      emailKey: string;
      session: SessionData;
    }>;
  },
};
