/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext } from "../../../../data/stores/AppStore";
import { Tyto } from "../../../../typings/tyto";
import { Message } from "../../../common/";
import PersonFull from "./PersonFull";
import PersonPartial from "./PersonPartial";

import "./style.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile?: Tyto.DISCProfileMini;
  hasDISCProfile: boolean;
  isMinimized?: boolean;
  isOwnProfile: boolean;
  loggedInUserHasManage?: boolean;
  onPermitUpdate?: (personID: number) => void;
  memberships?: { [x: number]: Tyto.AdvancedPersonTeamMembership[] };
  reloadTeam?: (teamID: number) => void;
  showFull?: boolean;
  setFocusedPerson: (personID: number) => void;
  teams?: { [x: number]: Tyto.Team };
  teamsWithConfiguration?: { [x: number]: Tyto.TeamGet };
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);

  const [personName, updatePersonName] = React.useState(
    props.discMiniProfile ? props.discMiniProfile.personName.split(" ")[0] : ""
  );
  const [personalDiscMini, updatePersonalDiscMini] = React.useState(
    _.get(store, "state.userDISCMini", undefined)
  );

  // * [E-1] - Update Personal Profile info as it changes (shouldn't)
  React.useEffect(() => {
    updatePersonalDiscMini(_.get(store, "state.userDISCMini", undefined));
  }, [_.get(store, "state.userDISCMini", undefined)]);

  // * [E-2] - Change Name as Targetted Profile changes
  React.useEffect(() => {
    updatePersonName(
      props.discMiniProfile
        ? props.discMiniProfile.personName.split(" ")[0]
        : ""
    );
  }, [props.discMiniProfile]);

  // * Something is wrong is this ever evaluates true...
  if (!props.discMiniProfile || !personalDiscMini) {
    return (
      <div className="meta-sidepane-person-view-cont">
        <Message
          value={`Could not find DISC information for ${
            !props.discMiniProfile ? "You" : ""
          } ${!personalDiscMini && !personalDiscMini ? "& " : ""}${
            !props.discMiniProfile ? "Targeted Profile" : ""
          }`}
        />
      </div>
    );
  }

  return (
    <div className="meta-sidepane-person-view-cont">
      {props.showFull ? (
        <PersonFull
          AppStore={store}
          discCompareProfile={props.discCompareProfile}
          discMiniProfile={props.discMiniProfile}
          hasDISCProfile={props.hasDISCProfile}
          isMinimized={props.isMinimized}
          isOwnProfile={props.isOwnProfile}
          loggedInUserHasManage={props.loggedInUserHasManage}
          memberships={props.memberships}
          onPermitUpdate={props.onPermitUpdate}
          personalDiscMini={personalDiscMini}
          personName={personName}
          reloadTeam={props.reloadTeam}
          setFocusedPerson={props.setFocusedPerson}
          teams={props.teams}
          teamsWithConfiguration={props.teamsWithConfiguration}
        />
      ) : (
        <PersonPartial
          AppStore={store}
          discCompareProfile={props.discCompareProfile}
          discMiniProfile={props.discMiniProfile}
          hasDISCProfile={props.hasDISCProfile}
          isMinimized={props.isMinimized}
          isOwnProfile={props.isOwnProfile}
          onPermitUpdate={props.onPermitUpdate}
          personalDiscMini={personalDiscMini}
          loggedInUserHasManage={props.loggedInUserHasManage}
          personName={personName}
        />
      )}
    </div>
  );
};
