/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon, { IconProps } from "../icon/";

import "./style.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  iconProps?: IconProps;
  iconSide?: "left" | "right";
  onClick: () => void;
  style?: React.CSSProperties;
  title?: string;
  value: string;
}

export default (props: Props) => {
  return (
    <button
      className={cx(
        "cc-textbutton",
        props.disabled && "cc-textbutton-isdisabled",
        props.className
      )}
      disabled={props.disabled}
      style={props.style}
      title={props.title}
      onClick={props.onClick}
    >
      {props.iconProps && props.iconSide !== "right" && (
        <Icon {...props.iconProps} />
      )}

      {props.value}

      {props.iconProps && props.iconSide === "right" && (
        <Icon {...props.iconProps} />
      )}
    </button>
  );
};
