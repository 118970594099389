/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../../../typings/tyto";
import { DISCValueRender, Message } from "../../../../../components/common/";
import { createPseudoRandomString } from "../../../../../data/utils/helpers/";

import GraphAndTip from "./GraphAndTip";
import CommTips from "./CommTips";

import "./PersonCommTips.scss";

interface Props {
  discMiniProfile: Tyto.DISCProfileMini;
  discFullProfile?: Tyto.DISCInfo;
  discCompareProfile?: Tyto.DISCCompareProfile;
  isOwnProfile?: boolean;
}

const COMM_TIP_SPLIT_KEY = "\n";

export default (props: Props) => {
  const [commTips, updateCommTips] = React.useState(
    getDosDonts(props.discCompareProfile)
  );

  React.useEffect(() => {
    console.log("Updating Dos and Don'ts in Person Comm Tips Tab");
    updateCommTips(getDosDonts(props.discCompareProfile));
  }, [props.discCompareProfile]);

  return (
    <section className="directory-tabs-tab-section-comm-tips-cont">
      <GraphAndTip
        discCompareProfile={props.discCompareProfile}
        discMiniProfile={props.discMiniProfile}
      />

      <div className="directory-tabs-tab-section-comm-tips-tips-section">
        <h3 className="directory-tabs-tab-section-comm-tips-tips-section-title cc-toggle-section-title">
          {props.isOwnProfile ? (
            <>When others are working with you they should...</>
          ) : (
            <>
              When working with{" "}
              {props.discMiniProfile.personName.split(" ")[0] || "Them"}
            </>
          )}
        </h3>

        <div className="directory-tabs-tab-section-comm-tips-tips-section-left-cont">
          <h3 className="directory-tabs-tab-section-comm-tips-tips-section-subtitle tip-section-do-these-title">
            Do These Things
          </h3>

          <DISCValueRender
            className="directory-tabs-tab-section-comm-tips-dos-wrapper"
            isCompareValue={true}
            compareProfile={props.discCompareProfile}
            targetKey="communicationTips"
            discMiniProfile={props.discMiniProfile}
          >
            <ul className="directory-tabs-tab-section-comm-tips-tips-section-tip-list">
              {commTips.doThese ? (
                commTips.doThese.map((tip: any) => (
                  <li
                    className="directory-tabs-tab-section-comm-tips-tips-section-tip"
                    key={tip.discCommTipID}
                  >
                    {tip.tipDescription}
                  </li>
                ))
              ) : (
                <Message
                  value="These tips are not available"
                  style={{
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              )}
            </ul>
          </DISCValueRender>
        </div>

        <div className="directory-tabs-tab-section-comm-tips-tips-section-right-cont">
          <h3 className="directory-tabs-tab-section-comm-tips-tips-section-subtitle tip-section-dontdo-these-title">
            Don't Do These Things
          </h3>

          <DISCValueRender
            className="directory-tabs-tab-section-comm-tips-donts-wrapper"
            isCompareValue={true}
            compareProfile={props.discCompareProfile}
            targetKey="communicationTips"
            discMiniProfile={props.discMiniProfile}
          >
            <ul className="directory-tabs-tab-section-comm-tips-tips-section-tip-list">
              {commTips.doThese ? (
                commTips.dontDoThese.map((tip: any) => (
                  <li
                    className="directory-tabs-tab-section-comm-tips-tips-section-tip"
                    key={tip.discCommTipID}
                  >
                    {tip.tipDescription}
                  </li>
                ))
              ) : (
                <Message
                  value="These tips are not available"
                  style={{
                    marginTop: "30px",
                    marginBottom: "30px",
                  }}
                />
              )}
            </ul>
          </DISCValueRender>
        </div>
      </div>
    </section>
  );
};

const getStaticStyleCommTipsFormatted = (
  discCompareProfile?: Tyto.DISCCompareProfile
) => {
  if (!discCompareProfile) {
    return {
      doThese: [],
      dontDoThese: [],
    };
  }

  // * Format strings into array that partially mimic customizedTips object types
  const dos = _.get(discCompareProfile, "style.communicationDo", "").split(
    COMM_TIP_SPLIT_KEY
  );
  const donts = _.get(discCompareProfile, "style.communicationDont", "").split(
    COMM_TIP_SPLIT_KEY
  );

  const formattedDos =
    dos && dos.length
      ? dos.map((doText: any) => ({
          blnDo: true,
          discCommTipID: createPseudoRandomString(),
          tipDescription: doText,
        }))
      : [];
  const formattedDonts =
    donts && donts.length
      ? donts.map((doText: any) => ({
          blnDo: false,
          discCommTipID: createPseudoRandomString(),
          tipDescription: doText,
        }))
      : [];

  return {
    doThese: formattedDos,
    dontDoThese: formattedDonts,
  };
};

const getDosDonts = (discCompareProfile?: Tyto.DISCCompareProfile) => {
  return getStaticStyleCommTipsFormatted(discCompareProfile);

  // if (!discCompareProfile || !discCompareProfile.communicationTips) {
  //   return {
  //     doThese: [],
  //     dontDoThese: [],
  //   };
  // }

  // const { doThese, dontDoThese } = discCompareProfile.communicationTips.reduce(
  //   (
  //     accum: {
  //       doThese: Tyto.DISCCompareProfile.CommTip[];
  //       dontDoThese: Tyto.DISCCompareProfile.CommTip[];
  //     },
  //     commTip
  //   ) => {
  //     if (commTip.blnDo) {
  //       accum.doThese.push(commTip);
  //     } else {
  //       accum.dontDoThese.push(commTip);
  //     }

  //     return accum;
  //   },
  //   { doThese: [], dontDoThese: [] }
  // );

  // return {
  //   doThese,
  //   dontDoThese,
  // };
};

const getGradientBG = (selectedLetter: "d" | "i" | "s" | "c") => {
  return "";
};
