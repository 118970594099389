/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";

import { SidePane, SuperSidePane } from "../../../components/meta";
import TytoCalls from "../../../data/tyto/";
import {
  StoreState as AppStoreState,
  AppStoreDispatch,
} from "../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "../data/stores/AdminStore";
import { loadPersonDiscMiniAndUpdateAppStore } from "../../../data/utils/";
import { loadTeam, loadTeamsWithTeamToolsConfig } from "../data/utils/";
import { loadMiniProfilesForTeam } from "../../teams/tabs-interface/utils";
import { loadCompareProfile } from "../../directory/utils";

import PlaceholderTeamTree from "../subcomponents/placeholders/Placeholder-TeamTree";
import RevisedTeamTreeDomain from "./subcomponents/TeamTreeDomain";
// import TeamTreeItem from "./TeamTreeItem";
// import TeamTreeWrapper from "./TeamTreeItemWrapper";
import SearchBar from "../subcomponents/SearchBar";
import CurComponentManager from "../subcomponents/CurComponentManager";
import { Tyto } from "../../../typings/tyto";
import { SubIntefaceKey } from "..";
import { getDomainChildTeams } from "./utils/";
import CreateNewAccount from "./subcomponents/CreateNewAccount";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AppStoreDispatch: AppStoreDispatch;
  AdminStoreState: AdminStoreState;
  AppStoreState: AppStoreState;
  subInterface: SubIntefaceKey;
  updateSubInterfaceKey: (newKey: SubIntefaceKey) => void;
}

export default ({
  AdminStoreDispatch,
  AppStoreDispatch,
  AdminStoreState,
  AppStoreState,
  subInterface,
  updateSubInterfaceKey,
}: Props) => {
  const [teamSearchTerm, updateTeamSearchTerm] = React.useState(
    AdminStoreState.teamSearchTerm || ""
  );
  const [settimeoutKey, updateSettimeoutKey] = React.useState(null);

  const navigate = useNavigate();

  const {
    curDomainID,
    discCompareProfiles,
    discMini,
    discMiniNullProfiles,
    discMiniByTeam,
    sessionData,
    userHasManage,
  } = AppStoreState;
  const { flattenedList, previewPersonID, selectedTeamID } = AdminStoreState;

  const domain =
    !!curDomainID && Array.isArray(AdminStoreState.flattenedList)
      ? AdminStoreState.flattenedList.find(
          (team) => team.teamID === curDomainID
        )
      : undefined;

  // * ====================================================
  // * START OF EFFECTS ===================================
  // * ====================================================

  // * [E-1] - Update inforemation on selectTeamID Change
  React.useEffect(() => {
    if (selectedTeamID && !AppStoreState.discMiniByTeam[selectedTeamID]) {
      loadMiniProfilesForTeam(`${selectedTeamID}`, {
        state: AppStoreState,
        dispatch: AppStoreDispatch,
      });
    }

    // * Scrolls new team into view in Tree if partially or fully out of view
    if (selectedTeamID) {
      const teamListItem = document.getElementById(
        `admin-team-${selectedTeamID}`
      );

      if (teamListItem) {
        const { top, height } = teamListItem.getBoundingClientRect();

        if (top < 0 || top + height > window.innerHeight) {
          teamListItem.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [selectedTeamID]);

  // * [E-2] - Load compare profile if necessary on preview person change
  React.useEffect(() => {
    if (
      previewPersonID &&
      !AppStoreState.discCompareProfiles[previewPersonID]
    ) {
      loadCompareProfile(
        {
          state: AppStoreState,
          dispatch: AppStoreDispatch,
        },
        previewPersonID
      );
    }
  }, [previewPersonID]);

  // * [E-3] - Clear SearchTerm if curDomainID changes
  React.useEffect(() => {
    updateTeamSearchTerm("");

    AdminStoreDispatch({
      payload: {
        searchTerm: "",
      },
      type: "ADMIN_STORE_TEAM_SEARCHTERM_UPDATE",
    });
  }, [curDomainID]);
  // * =================================================
  // * END OF EFFECTS ==================================
  // * =================================================

  if (!AdminStoreState.loaded || !domain) {
    if (!AdminStoreState.loaded) {
      return (
        <div className="admin-content-main-cont">
          <CurComponentManager
            loading={true}
            subInterface={subInterface}
            updateSubInterfaceKey={updateSubInterfaceKey}
          />

          <PlaceholderTeamTree />
        </div>
      );
    }

    return (
      <h3 className="admin-content-toplevel-msg">
        {!AppStoreState.userHasManage
          ? "You do not have permission to view this interface"
          : "Domain Not Found..."}
      </h3>
    );
  }

  // const previewPerson = _.get(
  //   discMini,
  //   `${previewPersonID}`,
  //   _.get(discMiniNullProfiles, `${previewPersonID}`)
  // );
  const previewPerson = !previewPersonID
    ? undefined
    : discMini?.[previewPersonID] || discMiniNullProfiles?.[previewPersonID];
  // const previewPerson: Tyto.DISCProfileMini | undefined = previewPersonID
  //   ? (_.get(discMiniByTeam, `${selectedTeamID}.${previewPersonID}`) as any)
  //   : undefined;

  React.useEffect(() => {
    if (!AppStoreState.sessionData || !AppStoreState.sessionData.sessionKey) {
      navigate("/");
    }
  }, [AppStoreState.sessionData, AppStoreState.sessionData.sessionKey]);

  if (!AppStoreState.sessionData || !AppStoreState.sessionData.sessionKey) {
    return <div>Redirecting...</div>;
  }

  const curDomainIsNotUsers =
    domain && sessionData && domain.teamID !== sessionData.domainID;
  const usersDomain =
    curDomainIsNotUsers &&
    !!curDomainID &&
    Array.isArray(AdminStoreState.flattenedList)
      ? AdminStoreState.flattenedList.find(
          (team) => team.teamID === sessionData.domainID
        )
      : undefined;
  const teams = _.get(AdminStoreState, "teamsByTeamID", undefined);
  const teamsWithConfiguration = _.get(
    AdminStoreState,
    "teamsWithConfiguration",
    undefined
  );
  const memberships = _.get(
    AdminStoreState,
    "membershipsByPersonID",
    undefined
  );
  const curDomainChildDomains = getDomainChildTeams({
    team: domain,
    teamsStructured: AdminStoreState.structured,
    domainsByParentDomainID: AdminStoreState.domainsByParentDomainID,
  });

  // if (curDomainID === 1778261) {
  //   debugger;
  // }

  return (
    <>
      <div className="admin-content-main-cont">
        <CurComponentManager
          subInterface={subInterface}
          updateSubInterfaceKey={updateSubInterfaceKey}
        />

        {/* <ul className="admin-teams-team-structure-list">
          {curDomainIsNotUsers && usersDomain && (
            <TeamTreeItem
              AppStoreDispatch={AppStoreDispatch}
              AdminStoreDispatch={AdminStoreDispatch}
              curDomainID={usersDomain.teamID}
              chooseTeam={(teamID: number) => {}}
              discMiniByTeam={discMiniByTeam}
              forceSelfOnly={true}
              previewPersonID={
                previewPerson ? previewPerson.personID : undefined
              }
              selectedTeamID={selectedTeamID || 0}
              team={usersDomain}
              teamsStructured={AdminStoreState.structured}
              depth={0}
            />
          )}

          <TeamTreeWrapper
            AppStoreDispatch={AppStoreDispatch}
            AdminStoreDispatch={AdminStoreDispatch}
            curDomainID={domain.teamID}
            chooseTeam={(teamID) =>
              AdminStoreDispatch({
                payload: {
                  selectedTeamID: teamID,
                },
                type: "ADMIN_STORE_SELECTED_TEAM_ID",
              })
            }
            discMiniByTeam={discMiniByTeam}
            previewPersonID={previewPerson ? previewPerson.personID : undefined}
            selectedTeamID={selectedTeamID || 0}
            team={domain}
            teamsStructured={AdminStoreState.structured}
            depth={0}
          />
        </ul> */}

        <div>
          <SearchBar
            disabled={false}
            loading={
              !!(
                teamSearchTerm &&
                AdminStoreState.teamSearchTerm !== teamSearchTerm
              )
            }
            value={teamSearchTerm}
            onChange={(newTerm) => {
              updateTeamSearchTerm(newTerm);

              updateStoreSearchTermAsync({
                searchTerm: newTerm,
                settimeoutKey,
                updateSettimeoutKey,
                AdminStoreDispatch,
              });
            }}
            //   AdminStoreDispatch({
            //     payload: {
            //       searchTerm: newTerm,
            //     },
            //     type: "ADMIN_STORE_TEAM_SEARCHTERM_UPDATE",
            //   })
            // }
            placeholder="Filter Teams"
          />

          {curDomainIsNotUsers && usersDomain && (
            <RevisedTeamTreeDomain
              AppStoreDispatch={AppStoreDispatch}
              AdminStoreDispatch={AdminStoreDispatch}
              AppStoreState={AppStoreState}
              curDomainID={domain.teamID}
              chooseTeam={(teamID) =>
                AdminStoreDispatch({
                  payload: {
                    selectedTeamID: teamID,
                  },
                  type: "ADMIN_STORE_SELECTED_TEAM_ID",
                })
              }
              closedAtStart={usersDomain.teamID !== curDomainID}
              discMiniByTeam={discMiniByTeam}
              previewPersonID={
                previewPerson ? previewPerson.personID : undefined
              }
              selectedTeamID={selectedTeamID || 0}
              team={usersDomain}
              teamsStructured={AdminStoreState.structured}
              teamSearchTerm={AdminStoreState.teamSearchTerm}
              teamSearchMatchPathIDs={AdminStoreState.teamSearchMatchPathIDs}
              teamSearchMatchIDs={AdminStoreState.teamSearchMatchIDs}
              depth={0}
            />
          )}

          <RevisedTeamTreeDomain
            AppStoreDispatch={AppStoreDispatch}
            AdminStoreDispatch={AdminStoreDispatch}
            AppStoreState={AppStoreState}
            curDomainID={domain.teamID}
            chooseTeam={(teamID) =>
              AdminStoreDispatch({
                payload: {
                  selectedTeamID: teamID,
                },
                type: "ADMIN_STORE_SELECTED_TEAM_ID",
              })
            }
            closedAtStart={domain.teamID !== curDomainID}
            discMiniByTeam={discMiniByTeam}
            previewPersonID={previewPerson ? previewPerson.personID : undefined}
            selectedTeamID={selectedTeamID || 0}
            team={domain}
            teamsStructured={AdminStoreState.structured}
            teamSearchTerm={AdminStoreState.teamSearchTerm}
            teamSearchMatchPathIDs={AdminStoreState.teamSearchMatchPathIDs}
            teamSearchMatchIDs={AdminStoreState.teamSearchMatchIDs}
            depth={0}
          />

          {curDomainChildDomains &&
            !!curDomainChildDomains.length &&
            curDomainChildDomains.map((childDomain) => (
              <RevisedTeamTreeDomain
                key={childDomain.teamID}
                AppStoreDispatch={AppStoreDispatch}
                AdminStoreDispatch={AdminStoreDispatch}
                AppStoreState={AppStoreState}
                curDomainID={domain.teamID}
                chooseTeam={(teamID) =>
                  AdminStoreDispatch({
                    payload: {
                      selectedTeamID: teamID,
                    },
                    type: "ADMIN_STORE_SELECTED_TEAM_ID",
                  })
                }
                closedAtStart={childDomain.teamID !== curDomainID}
                discMiniByTeam={discMiniByTeam}
                previewPersonID={
                  previewPerson ? previewPerson.personID : undefined
                }
                selectedTeamID={selectedTeamID || 0}
                team={childDomain}
                teamsStructured={AdminStoreState.structured}
                teamSearchTerm={AdminStoreState.teamSearchTerm}
                teamSearchMatchPathIDs={AdminStoreState.teamSearchMatchPathIDs}
                teamSearchMatchIDs={AdminStoreState.teamSearchMatchIDs}
                depth={0}
              />
            ))}
        </div>

        {_.get(AppStoreState, "userHasManage", false) && (
          <CreateNewAccount
            curDomainID={curDomainID}
            curDomain={teams ? teams[`${curDomainID}`] : undefined}
            loggedInUserRoleID={_.get(
              AppStoreState,
              `tryybProfiles[${AppStoreState.loggedInUserID}].roleID`
            )}
            onAccountCreation={(newDomainID: number) => {
              loadNewDomain({
                newDomainID,
                AdminStoreDispatch,
              });
            }}
          />
        )}
      </div>

      {!!selectedTeamID && (
        <SuperSidePane
          AppStoreState={AppStoreState}
          className={cx(
            "directory-sidepane admin-super-sidepane"
            // previewPerson && "directory-sidepane-underneath"
          )}
          canClose={true}
          onConfigurationUpdate={(teamID) => {
            if (teamID) {
              loadUpdatedTeamConfig({ teamID, AdminStoreDispatch });
            }
          }}
          onClose={() =>
            AdminStoreDispatch({
              payload: {
                selectedTeamID: undefined,
              },
              type: "ADMIN_STORE_SELECTED_TEAM_ID",
            })
          }
          onPersonSelect={(personID) =>
            AdminStoreDispatch({
              payload: {
                previewPersonID: personID,
              },
              type: "ADMIN_STORE_PREVIEW_PERSON_ID",
            })
          }
          onTeamSelect={(teamID) =>
            AdminStoreDispatch({
              payload: {
                selectedTeamID: teamID,
              },
              type: "ADMIN_STORE_SELECTED_TEAM_ID",
            })
          }
          reloadTeamMembers={(teamID) => {
            loadMiniProfilesForTeam(`${teamID}`, {
              state: AppStoreState,
              dispatch: AppStoreDispatch,
            });
          }}
          roleID={_.get(AppStoreState, "personInfo.roleID", undefined)}
          team={flattenedList.find((team) => team.teamID === selectedTeamID)}
          teamID={selectedTeamID}
          teamWithConfiguration={_.get(
            AdminStoreState,
            `teamsWithConfiguration[${selectedTeamID}]`,
            undefined
          )}
        />
      )}

      {previewPerson && (
        <SidePane
          canClose={true}
          className={cx(
            "directory-sidepane directory-preview-pane",
            !!selectedTeamID && "directory-sidepane-overtop"
          )}
          closeOnBlur={true}
          discCompareProfile={discCompareProfiles[previewPerson.personID]}
          discMini={[previewPerson]}
          // focusedPersonID={focusedPersonID}
          onClose={() =>
            AdminStoreDispatch({
              payload: {
                previewPersonID: undefined,
              },
              type: "ADMIN_STORE_PREVIEW_PERSON_ID",
            })
          }
          loggedInUserHasManage={userHasManage}
          onPersonSelect={(personID) => {
            navigate(`/profile/${personID}`);
          }}
          onPermitUpdate={(personID) => {
            if (personID) {
              loadPersonDiscMiniAndUpdateAppStore({
                personID,
                AppStore: {
                  dispatch: AppStoreDispatch,
                  state: AppStoreState,
                },
              });
            }
          }}
          onlineUsersTable={AppStoreState.onlineUsersTable}
          memberships={memberships}
          reloadTeam={(teamID: number) => {
            const targetTeam = teams ? teams[teamID] : undefined;

            if (targetTeam) {
              loadTeamsWithTeamToolsConfig({
                AdminStore: {
                  state: AdminStoreState,
                  dispatch: AdminStoreDispatch,
                },
                teams: [targetTeam],
              });
            }
          }}
          teams={teams}
          teamsWithConfiguration={teamsWithConfiguration}
        />
      )}
    </>
  );
};

async function loadNewDomain({
  newDomainID,
  AdminStoreDispatch,
}: {
  newDomainID: number;
  AdminStoreDispatch: AdminStoreDispatch;
}) {
  try {
    const domainsResp = await TytoCalls.TeamsByFunction.get({
      teamType: "ocDOMAIN",
    });

    if (domainsResp && domainsResp.teams && AdminStoreDispatch) {
      AdminStoreDispatch({
        payload: {
          domains: domainsResp.teams,
        },
        type: "ADMIN_STORE_DOMAINS_RELOADED",
      });
    }

    // const teamResp = await TytoCalls.Team.get({ teamID: newDomainID });

    // if (teamResp.team && AdminStoreDispatch) {
    //   AdminStoreDispatch({
    //     payload: {
    //       team: teamResp.team
    //     },
    //     type: "ADMIN_STORE_TEAM_CREATED"
    //   })
    // }
  } catch (err) {
    debugger;
  }
}

const loadUpdatedTeamConfig = async ({
  teamID,
  AdminStoreDispatch,
}: {
  teamID: number;
  AdminStoreDispatch: AdminStoreDispatch;
}) => {
  try {
    const team = await loadTeam(teamID);

    if (team) {
      AdminStoreDispatch({
        payload: {
          teams: [team],
        },
        type: "ADMIN_STORE_TEAMS_WITH_CONFIGURATION_LOADED",
      });
    }
  } catch (err) {
    debugger;
  }
};

const updateStoreSearchTermAsync = async ({
  searchTerm,
  settimeoutKey,
  updateSettimeoutKey,
  AdminStoreDispatch,
}: {
  searchTerm: string;
  settimeoutKey: any;
  updateSettimeoutKey: (newVal: any) => void;
  AdminStoreDispatch: AdminStoreDispatch;
}) => {
  if (settimeoutKey) {
    clearTimeout(settimeoutKey);
  }

  const newSettimeoutKey = setTimeout(() => {
    AdminStoreDispatch({
      payload: {
        searchTerm,
      },
      type: "ADMIN_STORE_TEAM_SEARCHTERM_UPDATE",
    });
  }, 220);

  updateSettimeoutKey(newSettimeoutKey);
};
