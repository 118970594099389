/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Tyto } from "../../../typings/tyto";

import { Icon, Input } from "../../../components/common/";

import "./AccountsSummary.scss";

interface Props {
  billingInfo?: { [x: number]: Tyto.BillingSummaryResp };
  subDomains?: Tyto.Team[];
  searchTerm: string;
  updateSearchTerm: (newSearchTerm: string) => void;
}

export default ({
  billingInfo,
  subDomains,
  searchTerm,
  updateSearchTerm
}: Props) => {
  const [totalCount, updateTotalCount] = React.useState(() => {
    if (billingInfo) {
      const bIAsArr = Object.values(billingInfo);

      const total = bIAsArr.reduce((accum, summary) => {
        if (summary.billingSummary) {
          return accum + _.get(summary, "billingSummary[0].details.length", 0);
        }

        return accum;
      }, 0);

      return total;
    }

    return 0;
  });

  React.useEffect(() => {
    if (billingInfo) {
      const bIAsArr = Object.values(billingInfo);

      const total = bIAsArr.reduce((accum, summary) => {
        if (summary.billingSummary) {
          return accum + _.get(summary, "billingSummary[0].details.length", 0);
        }

        return accum;
      }, 0);

      updateTotalCount(total);
    }
  }, [billingInfo]);

  return (
    <section className="partner-dashboard-accounts-summary-cont">
      <div className="partner-dashboard-accounts-summary-item-cont">
        <h3 className="partner-dashboard-accounts-summary-item-value">
          {totalCount}
        </h3>
        <p className="partner-dashboard-accounts-summary-item-label">
          <Icon
            className="partner-dashboard-accounts-summary-item-label-icon"
            icon="users"
            size={14}
          />
          Active Users
        </p>
      </div>

      <div className="partner-dashboard-accounts-summary-item-cont">
        <h3 className="partner-dashboard-accounts-summary-item-value">NN</h3>
        <p className="partner-dashboard-accounts-summary-item-label">
          Completed Assessments
        </p>
      </div>

      <div className="partner-dashboard-accounts-summary-item-cont">
        <h3 className="partner-dashboard-accounts-summary-item-value">NN</h3>
        <p className="partner-dashboard-accounts-summary-item-label">
          Pending Unlock
        </p>
      </div>

      {/* <div className="partner-dashboard-accounts-summary-item-cont">
        <h3 className="partner-dashboard-accounts-summary-item-value partner-dashboard-accounts-summary-item-totalbills">
          $000.00
        </h3>
        <p className="partner-dashboard-accounts-summary-item-label">
          {"["}Month{"]"} total billing
        </p>
      </div> */}

      <div className="partner-dashboard-accounts-summary-search-cont-wrapper">
        <Input
          containerClassName="partner-dashboard-accounts-summary-search-input-cont"
          className="partner-dashboard-accounts-summary-search-input"
          iconLeft="search"
          iconProps={{
            size: 18
          }}
          name="Search Input for filtering Accounts"
          onChange={newValue => updateSearchTerm(newValue)}
          placeholder="Filter Accounts"
          value={searchTerm}
        />
      </div>
    </section>
  );
};
