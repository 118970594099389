/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Button, Input, Select } from "../../../common/";
import TytoCalls from "../../../../data/tyto/";

import DomainImageUpload from "../../domain-logo/DomainImageUpload";
import { Tyto } from "../../../../typings/tyto";

const domainTypeOptions = [
  {
    label: "Account",
    value: "ocTEAMTOOLS",
  },
  {
    label: "Partner",
    value: "ocSUPPORT",
  },
  {
    label: "No Social",
    value: "ocTEAMTOOLSCOACH",
  },
  {
    label: "Tryyb",
    value: "ocTRYYB",
  },
];

interface Props {
  onAccountCreation: (domainID: number) => void;
  onDismiss?: () => void;
  parentDomain?: Tyto.Team;
  primaryElementID?: number;
  loggedInUserRoleID?: number;
  uploadURL?: string;
}

type DomainTeamToolsTypes = "ocTEAMTOOLS" | "ocSUPPORT" | "ocTRYYB";

export default (props: Props) => {
  const [creating, updateCreating] = React.useState(false);
  const [domainName, updateDomainName] = React.useState("");
  const [newDomainID, updateNewDomainID] = React.useState(0);
  const [domainType, updateDomainType] = React.useState<DomainTeamToolsTypes>(
    "ocTEAMTOOLS"
  );
  const [errorMsg, updateErrorMsg] = React.useState("");

  const requiredFieldsPresent = !!domainName;
  const parentDomainName = _.get(props, "parentDomain.name", "");

  return (
    <div className="newaccount-modal-inner-cont">
      {newDomainID ? (
        // <DomainImageUpload
        //   className="newaccount-modal-logo-upload-cont"
        //   domainID={newDomainID}
        //   onComplete={() => {
        //     if (props.onDismiss) {
        //       props.onDismiss();
        //     }
        //   }}
        //   uploadURL={props.uploadURL}
        // />
        <div className="newaccount-modal-inner-created-cont">
          <h4 className="newaccount-modal-inner-created-subtitle">
            Domain Created!
          </h4>

          <Button
            className="newaccount-modal-inner-created-close-button"
            onClick={props.onDismiss ? props.onDismiss : () => {}}
            shape="square"
            type="hollow"
            value="Close"
          />
        </div>
      ) : (
        <>
          <h2 className="newaccount-modal-title title-bold">
            Account Creation
          </h2>

          {parentDomainName && (
            <p className="newaccount-modal-parentdomain-name">
              Account will be created inside{" "}
              <span className="newaccount-modal-parentdomain-name-text">
                {parentDomainName}
              </span>
            </p>
          )}

          <div className="newaccount-modal-inner-row-cont">
            <label className="newaccount-modal-inner-row-label">
              Account Name
            </label>

            <Input
              className="newaccount-modal-inner-row-input"
              onChange={(newVal) => updateDomainName(newVal)}
              placeholder="Account Name"
              value={domainName}
            />
          </div>

          {props.loggedInUserRoleID === 1 && (
            <div className="newaccount-modal-inner-row-cont">
              <label className="newaccount-modal-inner-row-label">
                Domain Type
              </label>

              <Select
                className="newaccount-modal-inner-row-select"
                omitFilter={true}
                options={domainTypeOptions}
                onChange={(newVal) =>
                  updateDomainType(newVal as DomainTeamToolsTypes)
                }
                placeholder="Domain Type"
                value={domainType}
              />
            </div>
          )}

          {errorMsg && (
            <div className="newaccount-modal-inner-error-msg-cont">
              <p className="newaccount-modal-inner-error-msg">{errorMsg}</p>
            </div>
          )}

          <div className="newaccount-modal-inner-row-cont newaccount-modal-inner-buttons-cont">
            <Button
              className={cx(
                "newaccount-modal-inner-buttons-button",
                !!creating && !requiredFieldsPresent && "is-disabled"
              )}
              disabled={!!creating && !requiredFieldsPresent}
              onClick={() => {
                updateErrorMsg("");
                updateCreating(true);

                createAccount({
                  domainName,
                  domainType,
                  onError: (errMsg) => {
                    updateCreating(false);
                    updateErrorMsg(errMsg);
                  },
                  onSuccess: (newDomainID: number) => {
                    updateCreating(false);
                    updateNewDomainID(newDomainID);
                    props.onAccountCreation(newDomainID);

                    // if (props.onAccountCreation) {
                    //   props.onAccountCreation();
                    // }
                  },
                  primaryElementID: props.primaryElementID,
                });
              }}
              value={getButtonText({
                isCreating: creating,
                hasError: !!errorMsg,
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};

const getButtonText = ({
  isCreating,
  hasError,
}: {
  isCreating: boolean;
  hasError: boolean;
}) => {
  if (hasError) {
    return "Try Again";
  } else if (isCreating) {
    return "Creating Account...";
  }

  return "Create Account";
};

const createAccount = async ({
  domainName,
  domainType,
  onError,
  onSuccess,
  primaryElementID,
}: {
  domainName: string;
  domainType: DomainTeamToolsTypes;
  onError: (msg: string) => void;
  onSuccess: (newDomainID: number) => void;
  primaryElementID?: number;
}) => {
  if (!domainName || !primaryElementID) {
    onError("All required information not supplied.");
    return;
  }

  try {
    const accountCreationResp = await TytoCalls.Domain.post({
      domainType: domainType || "ocTEAMTOOLS",
      primaryElementID,
      teamName: domainName,
    });

    onSuccess(accountCreationResp.domainID);
  } catch (err) {
    const errMsg =
      typeof err === "string"
        ? err
        : _.get(
            err,
            "msg",
            _.get(
              err,
              "technical",
              "Error occurred, could not create an Account."
            )
          );

    onError(errMsg);
  }
};
