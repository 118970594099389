/*
 * Component Description
 */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import { AppStoreProps } from "../../../../data/stores/AppStore";
import { DISCValueRender, Icon, TextButton, UserThumb } from "../../../common/";

import AdminSection from "./subcomponents/AdminSection";
import Tips from "../team/subcomponents/TeamTips";
import LockedSection from "./subcomponents/LockedSection";
import PersonCompareGraph from "./subcomponents/PersonCompareGraph";
import PersonDISCGraph from "./subcomponents/PersonDISCGraph";
import PersonHeader from "./subcomponents/PersonHeader";
import PersonGrowthTips from "./subcomponents/PersonGrowthTips";
import PersonMotivators from "./subcomponents/PersonMotivators";
import UserTeamMemberships from "./subcomponents/UserTeamMemberships";

interface Props {
  AppStore: AppStoreProps;
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
  hasDISCProfile: boolean;
  isMinimized?: boolean;
  isOwnProfile: boolean;
  loggedInUserHasManage?: boolean;
  memberships?: { [x: number]: Tyto.AdvancedPersonTeamMembership[] };
  onPermitUpdate?: (personID: number) => void;
  personalDiscMini: Tyto.DISCProfileMini;
  personName: string;
  reloadTeam?: (teamID: number) => void;
  setFocusedPerson: (personID: number) => void;
  teams?: { [x: number]: Tyto.Team };
  teamsWithConfiguration?: { [x: number]: Tyto.TeamGet };
}

const COMM_TIP_SPLIT_KEY = "\n";

export default (props: Props) => {
  const [isMinimized, updateIsMinimized] = React.useState(!!props.isMinimized);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (isMinimized && !props.isMinimized) {
      updateLocalIsMinimized(!!props.isMinimized, updateIsMinimized);
    } else {
      updateIsMinimized(!!props.isMinimized);
    }
  }, [props.isMinimized]);

  if (props.isMinimized) {
    return (
      <div className="meta-sidepane-person-viewfull-minimized-cont">
        <UserThumb
          asset={props.discMiniProfile.profileImageAsset}
          className="meta-sidepane-person-viewfull-minimized-userthumb"
          discMiniProfile={props.discMiniProfile}
          discValues={{
            d: props.hasDISCProfile ? props.discMiniProfile.d3 : 0,
            i: props.hasDISCProfile ? props.discMiniProfile.i3 : 0,
            s: props.hasDISCProfile ? props.discMiniProfile.s3 : 0,
            c: props.hasDISCProfile ? props.discMiniProfile.c3 : 0,
          }}
          userName={
            props.discMiniProfile ? props.discMiniProfile.personName : ""
          }
          size={42}
        />
      </div>
    );
  }

  if (isMinimized) {
    return null;
  }

  const userMemberships = _.get(
    props.memberships,
    props.discMiniProfile.personID,
    undefined
  );
  const userOnlineInfo = _.get(
    props.AppStore,
    `state.onlineUsersTable[${props.discMiniProfile.personID}]`,
    undefined
  );

  return (
    <>
      <div className="meta-sidepane-person-viewfull-button-cont">
        {props.discMiniProfile.personID ? (
          <TextButton
            onClick={() => {
              // props.setFocusedPerson(props.discMiniProfile.personID)
              navigate(`/profile/${props.discMiniProfile.personID}`);
            }}
            value="View Full Profile"
          />
        ) : (
          <p className="meta-sidepane-person-viewfull-nolink-msg">
            Cannot View Full Profile
          </p>
        )}
      </div>

      <PersonHeader
        discCompareProfile={props.discCompareProfile}
        discMini={props.discMiniProfile}
        hasDISCProfile={props.hasDISCProfile}
        userOnlineInfo={userOnlineInfo}
      />

      {props.loggedInUserHasManage && (
        <AdminSection
          AppStore={props.AppStore}
          discMini={props.discMiniProfile}
          loggedInUserHasManage={props.loggedInUserHasManage}
          onPermitUpdate={props.onPermitUpdate}
          personalDiscMini={props.personalDiscMini}
          primaryTeam={_.get(
            props,
            `teams[${_.get(props.discMiniProfile, "teamRoot", 0)}]`
          )}
          reloadTeam={props.reloadTeam}
          teams={props.teams}
          teamsWithConfiguration={props.teamsWithConfiguration}
        />
      )}

      {props.hasDISCProfile ? (
        <>
          {props.isOwnProfile && (
            <PersonGrowthTips
              compareProfile={props.discCompareProfile}
              discMini={props.discMiniProfile}
            />
          )}

          {!props.isOwnProfile && (
            <DISCValueRender
              className="meta-sidepane-person-renderer-wrapper"
              isCompareValue={true}
              compareProfile={props.discCompareProfile}
              targetKey="communicationTips"
              discMiniProfile={props.discMiniProfile}
            >
              <Tips
                closedAtStart={false}
                onLinkClick={
                  props.discMiniProfile
                    ? () =>
                        navigate(
                          `/profile/${props.discMiniProfile.personID}/comm-tips`
                        )
                    : undefined
                }
                title={`${props.personName || "Their"} Communication Tips`}
                tips={getCommTips(props.discCompareProfile)}
              />
            </DISCValueRender>
          )}

          <PersonDISCGraph
            discCompareProfile={props.discCompareProfile}
            discMini={props.discMiniProfile}
            startWithNew={true}
            titleOverride={props.isOwnProfile ? "Your Personality" : undefined}
          />

          {!props.isOwnProfile && (
            <PersonCompareGraph
              compareProfile={props.discCompareProfile}
              discMini={props.discMiniProfile}
              personName={props.personName}
              personalDiscMini={props.personalDiscMini}
            />
          )}

          <PersonMotivators
            discCompareProfile={props.discCompareProfile}
            discMiniProfile={props.discMiniProfile}
            personName={props.personName}
          />

          {userMemberships && (
            <UserTeamMemberships
              isOwnProfile={props.isOwnProfile}
              memberships={userMemberships}
              personName={props.personName}
              teams={props.teams}
            />
          )}
        </>
      ) : (
        <LockedSection />
      )}
    </>
  );
};

const getStaticStyleCommTipsFormatted = (
  discCompareProfile?: Tyto.DISCCompareProfile
) => {
  if (!discCompareProfile) {
    return [];
  }

  // * Format strings into array that partially mimic customizedTips object types
  const dos = _.get(discCompareProfile, "style.communicationDo", "").split(
    COMM_TIP_SPLIT_KEY
  );
  const donts = _.get(discCompareProfile, "style.communicationDont", "").split(
    COMM_TIP_SPLIT_KEY
  );

  const formattedDos =
    dos && dos.length
      ? dos.map((doText: any) => ({
          blnDo: true,
          tipDescription: doText,
        }))
      : [];
  const formattedDonts =
    donts && donts.length
      ? donts.map((doText: any) => ({
          blnDo: false,
          tipDescription: doText,
        }))
      : [];

  return [...formattedDos, ...formattedDonts];
};

const getCommTipsArr = (discCompareProfile: Tyto.DISCCompareProfile) => {
  if (!discCompareProfile.communicationTips) {
    return [];
  }

  const [dos, donts] = discCompareProfile.communicationTips.reduce(
    (accum: Tyto.DISCCompareProfile.CommTip[][], item) => {
      accum[item.blnDo ? 0 : 1].push(item);

      return accum;
    },
    [
      [], // * Dos
      [], // * Don'ts
    ]
  );

  return [_.sortBy(dos, ["seq"], ["asc"]), _.sortBy(donts, ["seq"], ["asc"])];
};

const getCommTips = (discCompareProfile?: Tyto.DISCCompareProfile) => {
  if (!discCompareProfile) {
    return [];
  }

  // * For handling temporarly using static style commTips instead of customized Tips
  // * Customized tips found at commented out line below (discCompareProfile.communicationTips)
  const [doThese, dontDoThese] = getCommTipsArr(discCompareProfile);
  // const tipsArray = getStaticStyleCommTipsFormatted(discCompareProfile);
  // const tipsArray = discCompareProfile.communicationTips;

  return [...doThese, ...dontDoThese].map((tip) => {
    return () => {
      return (
        <React.Fragment key={tip.discCommTipID}>
          <Icon
            className={cx(
              "meta-sidepane-team-team-tips-tip-icon",
              tip.blnDo
                ? "meta-sidepane-team-team-tips-tip-icon-do"
                : "meta-sidepane-team-team-tips-tip-icon-donot"
            )}
            size={16}
            icon={tip.blnDo ? "check" : "close"}
          />
          <span className="meta-sidepane-team-team-tips-tip-text">
            {tip.tipDescription}
          </span>
        </React.Fragment>
      );
    };
  });
};

const updateLocalIsMinimized = (
  isMinimized: boolean,
  updateIsMinimized: (val: boolean) => void
) => {
  setTimeout(() => {
    updateIsMinimized(isMinimized);
  }, 650);
};
