import * as _ from "lodash";
import QueryString from "query-string";

import TytoCalls from "../tyto/";

import {
  LOGGED_IN_USER_KEY,
  SESSION_DATA_KEY,
  LAST_SESSION_ACTIVITY,
} from "../constants";
import { SessionData } from "../../typings/";
import { getSessionsAsArray, removeSessionData } from "../storage/session";
import {
  getItem as getLocalStorageItem,
  getSessionStorageItem,
} from "../storage/utils";
import { AppStoreProps } from "../stores/AppStore";

import { Tyto } from "../../typings/tyto";

let saveKey: null | NodeJS.Timeout;

export function awaitOfficeLoad(callback: () => void) {
  // Office.onReady(() => callback());
  callback();
}

export function clearSessionData(callback?: () => void) {
  // sessionStorage.setItem(SESSION_DATA_KEY, "");

  // const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  localStorage.setItem(SESSION_DATA_KEY, "");

  const sessionData = localStorage.getItem(SESSION_DATA_KEY);
  console.log("SESSION DATA CLEARED - VALUE NOW: ", sessionData);

  if (callback) {
    callback();
  }
}

export function getSessionData(): SessionData | undefined {
  // const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  const sessionData = localStorage.getItem(SESSION_DATA_KEY);
  // console.log("SESSION DATA: ", sessionData);

  if (!sessionData || typeof sessionData !== "string") {
    return undefined;
  }

  const parsedSessionData = JSON.parse(sessionData);

  return parsedSessionData as SessionData;
}

export function getSessionKey(): string {
  const sessionData = getSessionData();

  if (!sessionData || typeof sessionData !== "object") {
    return "";
  }

  return sessionData.sessionKey || "";
}

// export async function login(email: string, password: string) {
//   try {
//     const info = await TytoCalls.LoginAuthenticate.post({
//       username: email,
//       password
//     });

//     return info;
//   } catch (err) {
//     debugger;
//     return {
//       error: {
//         msg: err
//       },
//       session: undefined
//     };
//   }
// }

export function login(
  email: string,
  password: string
): Promise<{ error?: any; session?: SessionData }> {
  return new Promise((res, rej) => {
    TytoCalls.LoginAuthenticate.post({
      username: email,
      password,
    })
      .then((info) => {
        res(info);
      })
      .catch((err) => {
        res({
          error: err,
          session: undefined,
        });
      });
  });
}

export function addHandler(callback: (recipients: any[]) => void) {
  // Office.context.mailbox.item.addHandlerAsync(
  //   "olkRecipientsChanged",
  //   ({
  //     changedRecipientFields
  //   }: {
  //     changedRecipientFields: { bcc: boolean; cc: boolean; to: boolean };
  //     type: string;
  //   }) => {
  //     if (changedRecipientFields.cc || changedRecipientFields.to) {
  //       getRecipientsWithCallback()
  //         .then(people => callback(people))
  //         .catch(err => {
  //           debugger;
  //         });
  //     }
  //   }
  // );
}

export function addInboxHandler(callback: (recipients: any[]) => void) {
  // Office.context.mailbox.addHandlerAsync(
  //   Office.EventType.ItemChanged,
  //   change => {
  //     const initialData: any = _.get(change, "initialData", {});
  //     if (initialData && typeof initialData === "object" && initialData.from) {
  //       const cc = Array.isArray(initialData.cc) ? initialData.cc : [];
  //       const to = Array.isArray(initialData.to) ? initialData.to : [];
  //       const ccFormatted = cc.map((recipient: any) => ({
  //         emailAddress: recipient.address,
  //         displayName: recipient.name
  //       }));
  //       const toFormatted = to.map((recipient: any) => ({
  //         emailAddress: recipient.address,
  //         displayName: recipient.name
  //       }));
  //       callback([
  //         {
  //           emailAddress: initialData.from.address,
  //           displayName: initialData.from.name
  //         },
  //         ...(toFormatted as Office.EmailAddressDetails[]),
  //         ...(ccFormatted as Office.EmailAddressDetails[])
  //       ]);
  //     }
  //   }
  // );
  // Office.context.mailbox.item.addHandlerAsync(
  //   "olkRecipientsChanged",
  //   ({
  //     changedRecipientFields
  //   }: {
  //     changedRecipientFields: { bcc: boolean; cc: boolean; to: boolean };
  //     type: string;
  //   }) => {
  //     if (changedRecipientFields.cc || changedRecipientFields.to) {
  //       getInboxRecipientInfo()
  //         .then(people => callback(people))
  //         .catch(err => {
  //           debugger;
  //         });
  //     }
  //   }
  // );
}

// export async function getRecipientsWithCallback() {
//   try {
//     const recipients = await Promise.all([
//       getCCRecipients(),
//       getToRecipients()
//     ]);

//     // return [{ emailAddress: "gotthisfar@gmail.com" }];
//     return _.flatten(recipients);
//   } catch (err) {
//     return [];
//   }
// }

// export async function getInboxRecipientInfo() {
//   try {
//     const recipients = await Promise.all([
//       getInboxCCRecipients(),
//       getInboxToRecipients(),
//       getFromAddresses()
//     ]);

//     debugger;
//     // return [{ emailAddress: "gotthisfar@gmail.com" }];
//     return _.flatten(recipients);
//   } catch (err) {
//     return [];
//   }
// }

// export function getCCRecipients(): Promise<Office.EmailAddressDetails[]> {
//   return new Promise((res, rej) => {
//     if (!_.get(Office, "context.mailbox.item.cc")) {
//       console.warn("Expected 'mailbox.item.cc' to be defined but was not.");
//       res([]);
//     }
//     Office.context.mailbox.item.cc.getAsync(
//       (resp: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
//         console.log("CC getAsync resp: ", resp);
//         // @ts-ignore
//         if (resp && resp.status === "succeeded") {
//           res(resp.value);
//         } else {
//           //   rej(undefined);
//           res([]);
//         }
//       }
//     );
//   });
// }

// export function getInboxCCRecipients(): Promise<Office.EmailAddressDetails[]> {
//   return new Promise((res, rej) => {
//     if (!_.get(Office, "context.mailbox.item.cc")) {
//       console.warn("Expected 'mailbox.item.cc' to be defined but was not.");
//       res([]);
//     }
//     const cc = Office.context.mailbox.item.cc;

//     if (Array.isArray(cc)) {
//       res(cc);
//     } else {
//       res([]);
//     }
//   });
// }

// export function getFromAddresses(): Promise<Office.EmailAddressDetails[]> {
//   return new Promise((res, rej) => {
//     if (!_.get(Office, "context.mailbox.item.from")) {
//       console.warn("Expected 'mailbox.item.from' to be defined but was not.");
//       res([]);
//     }

//     const from = Office.context.mailbox.item.from || [];
//     debugger;
//     if (Array.isArray([from])) {
//       res([from]);
//     } else {
//       res([]);
//     }
//   });
// }

// export function getToRecipients(): Promise<Office.EmailAddressDetails[]> {
//   return new Promise((res, rej) => {
//     if (!_.get(Office, "context.mailbox.item.to")) {
//       console.warn("Expected 'mailbox.item.to' to be defined but was not.");
//       res([]);
//     }
//     Office.context.mailbox.item.to.getAsync(
//       (resp: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
//         console.log("To getAsync resp: ", resp);
//         // TODO Fix typing - resp should be { status: string; value: Office.EmailAddressDetails[] }
//         // @ts-ignore
//         if (resp && resp.status === "succeeded") {
//           res(resp.value);
//         } else {
//           //   rej(undefined);
//           res([]);
//         }
//       }
//     );
//   });
// }

// export function getInboxToRecipients(): Promise<Office.EmailAddressDetails[]> {
//   return new Promise((res, rej) => {
//     if (!_.get(Office, "context.mailbox.item.cc")) {
//       console.warn("Expected 'mailbox.item.cc' to be defined but was not.");
//       res([]);
//     }
//     const to = Office.context.mailbox.item.to;

//     if (Array.isArray(to)) {
//       res(to);
//     } else {
//       res([]);
//     }
//   });
// }

// export async function loadPeople(emails: string[]): Promise<Tyto.Person[]> {
// try {
// const sessionData = Office.context.roamingSettings.get(SESSION_DATA_KEY);
// const domainID = _.get(sessionData, "domainID");

//   const calls = emails.map(email =>
//     TytoCalls.Person.get({ domainID, logonName: email })
//   );

//   const peopleData = (await Promise.all(calls)) as Array<Tyto.Person>;

//   const personObjects = peopleData
//     .map(personResp => _.get(personResp, "person"))
//     .filter(personObj => !!personObj);

//   return personObjects;
// } catch (err) {
//   console.warn(
//     "An error occured while loading people from Advanced Search: ",
//     err
//   );
//   return [];
// }
// }

// export function loadRecentlyRetrieved(): Tyto.DISCProfileMini[] {
//   try {
//     const recentlyRetrieved = Office.context.roamingSettings.get(
//       RECENTLY_RETRIEVED_PEOPLE
//     );

//     console.log("Recent Contacts From Raoming Data: ", recentlyRetrieved);
//     if (!Array.isArray(recentlyRetrieved)) {
//       return [];
//     }

//     return recentlyRetrieved;
//   } catch (err) {
//     console.warn("An error occured while loading Recent Contacts: ", err);
//     return [];
//   }
// }

export async function loadStarterData(
  store: AppStoreProps,
  overrideCurrentData?: boolean
) {
  // if (
  //   /^\/account-selection/i.test(window.location.pathname) &&
  //   /targetID=\w+/i.test(window.location.search)
  // ) {
  //   console.warn(
  //     "Ended loadStarterData early because believed to be at /account-selection path"
  //   );
  //   return;
  // }

  const sessionResp = await retrieveStoredSession();

  // * Checks boolean incase it is undefined and overrideCurrentData gets passed as first argument
  if (typeof store === "boolean" || !store || !store.dispatch) {
    console.warn("Store or Dispatch was not defined when it needed to be.");
    return;
  }

  if (typeof sessionResp === "object" && sessionResp.session) {
    store.dispatch({
      payload: sessionResp.session,
      type: "USER_LOGGED_IN",
    });

    const userID = _.get(sessionResp, "session.userID", 0);
    const domainID = _.get(sessionResp, "session.domainID", undefined);

    if (userID) {
      const personInfo = await retrieveUserInfo(userID);

      store.dispatch({
        payload: {
          overrideCurrentData,
          personInfo,
        },
        type: "USER_INFO_LOADED",
      });
    }

    if (domainID) {
      try {
        const { domain } = await TytoCalls.Domain.get({ domainID });

        store.dispatch({
          payload: {
            domain,
          },
          type: "USER_DOMAIN_INFO_LOADED",
        });
      } catch (err) {
        console.warn("Users Domain failed to load: ", err);
      }
    }

    const menuItems = await loadKVMenu();
    const userHasManage = !!menuItems.find(
      (item) => item.functionName === "Page Manage"
    );

    store.dispatch({
      payload: {
        userHasManage,
      },
      type: "UPDATE_USER_HAS_MANAGE",
    });

    if (userHasManage) {
      const subDomains = await loadSubDomains();

      store.dispatch({
        payload: {
          subDomains,
        },
        type: "SUB_DOMAINS_LOADED",
      });
    } else {
      store.dispatch({
        payload: {
          subDomains: [],
        },
        type: "SUB_DOMAINS_LOADED",
      });
    }

    // const domainDISCMinis = await retrieveAllDiscMinis(domainID);

    const userDISCMiniResp = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${userID}`,
    });

    // const userDISCMini = domainDISCMinis.find(
    //   ({ personID }) => personID === userID
    // );
    store.dispatch({
      payload: {
        overrideCurrentData,
        userDISCMini: userDISCMiniResp.discProfiles[0],
        userID,
      },
      type: "USER_PERSONAL_DISC_MINI",
    });

    // store.dispatch({
    //   payload: {
    //     discMini: domainDISCMinis,
    //     emailsUsed: [],
    //     overrideCurrentData
    //   },
    //   type: "DISC_MINI_PROFILES_LOADED"
    // });
  } else {
    store.dispatch({
      payload: {},
      type: "CLEAR_USER_SESSION_DATA",
    });

    store.dispatch({
      payload: {
        hasCheckedForStoredSession: true,
      },
      type: "STORED_SESSION_CHECKED",
    });
  }
}

export async function loadKVMenu() {
  try {
    const menuItemsResp = await TytoCalls.Menu.get({});

    return menuItemsResp && Array.isArray(menuItemsResp.menuItems)
      ? menuItemsResp.menuItems
      : [];
  } catch (err) {
    console.warn("Error while loading /Menu");

    return [];
  }
}

export async function loadSubDomains() {
  try {
    const subDomainsResp = await TytoCalls.TeamsByFunction.get({
      teamType: "ocDOMAIN",
    });

    return subDomainsResp.teams;
  } catch (err) {
    console.warn("Error Occurred while loading subDomains");
    return [];
  }
}

export function logout(callback: () => void) {
  // sessionStorage.setItem(SESSION_DATA_KEY, "");
  localStorage.setItem(SESSION_DATA_KEY, "");
}

export async function retrieveAllDiscMinis(domainID: number) {
  try {
    const allDISCMinis = await TytoCalls.DISCProfilesMini.get(
      { domainID },
      { useLocalStorage: true, storeResponse: true }
    );

    const profiles: Tyto.DISCProfileMini[] = _.get(
      allDISCMinis,
      "discProfiles",
      []
    );

    return profiles;
  } catch (err) {
    return [];
  }
}

export async function retrieveStoredSession() {
  try {
    // ! Switch to Array may screw up loading data after session is added
    // * [1] Look in storage for session Data
    // const storedSessionData = getSessionData();
    const storedSessions = getSessionsAsArray();
    const loggedInUserIDResp = getSessionStorageItem(LOGGED_IN_USER_KEY, true);
    const loggedInUserID = loggedInUserIDResp
      ? parseInt(loggedInUserIDResp)
      : 0;

    const storedSessionData = storedSessions.find(
      (session) => session.userID === loggedInUserID
    );

    // * [2] Check if session is "alive" and return sessionData if it is
    if (storedSessionData && storedSessionData.sessionKey) {
      console.warn(
        `Attemping to check AccountSession with:`,
        storedSessionData
      );
      const sessionCheck = await TytoCalls.AccountSession.get({
        sessionKey: storedSessionData.sessionKey,
      }).catch((err) => undefined);

      const status = _.get(sessionCheck, "error.sts", undefined);

      const sessionIsAlive = status === 0;

      if (sessionIsAlive) {
        return { session: storedSessionData };
      } else {
        // clearSessionData();
        // ? May need to handle falsey userID better
        debugger;
        removeSessionData(loggedInUserID);
      }
    }

    // * [3] If session is not found, or is not "alive" return undefined
    return undefined;
  } catch (err) {
    debugger;
    return undefined;
  }
}

export async function retrieveUserInfo(personID: number) {
  try {
    // * [1] Look in storage for session Data
    const personResp: any = await TytoCalls.Person.get({ personID });

    if (typeof personResp === "object" && personResp.person) {
      return personResp.person as Tyto.Person;
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
}

export function setSessionData(sessionData: SessionData) {
  if (sessionData) {
    const dataAsString = JSON.stringify(sessionData);
    // sessionStorage.setItem(SESSION_DATA_KEY, dataAsString);
    localStorage.setItem(SESSION_DATA_KEY, dataAsString);
    console.log("Session Data stored");
  }
}

// export function updateRecentlyRetrieved(people: Tyto.DISCProfileMini[]) {
//   if (!Array.isArray(people) || !people.length) {
//     return;
//   }

//   console.log("Updating Recent with: ", people);

//   try {
//     const recents = Office.context.roamingSettings.get(
//       RECENTLY_RETRIEVED_PEOPLE
//     );
//     const mergedRecents = _.slice(
//       _.uniqBy(_.flatten([people, recents || []]), "personID"),
//       0,
//       10
//     );

//     Office.context.roamingSettings.set(
//       RECENTLY_RETRIEVED_PEOPLE,
//       mergedRecents
//     );

//     saveRecentlyRetrieved();
//   } catch (err) {
//     console.warn(
//       "An error occured while attempting to update Recently Retrieved list."
//     );
//   }
// }

// export function saveRecentlyRetrieved() {
//   console.log("Starting timeout for saving roaming data.");
//   if (saveKey) {
//     clearTimeout(saveKey);
//   }

//   saveKey = setTimeout(() => {
//     try {
//       Office.context.roamingSettings.saveAsync(() => {
//         console.log("Roaming Data saved");
//       });
//     } catch (err) {
//       console.warn(
//         "An error occured while attempting to update Recently Retrieved list."
//       );
//     }
//   }, SAVE_ROAMING_DATA_TIMEOUT_MS);
// }

// export function getInterfaceContextParam(): InterfaceContext {
//   const params = QueryString.parse(window.location.search);

//   return params[INTERFACE_CONTEXT_PARAM_KEY] || DEFAULT_INTERFACE_CONTEXT_VALUE;
// }

export async function loadPersonDiscMiniAndUpdateAppStore({
  personID,
  AppStore,
}: {
  personID: number;
  AppStore: AppStoreProps;
}) {
  if (!personID || !AppStore.dispatch) {
    return;
  }

  try {
    const resp = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${personID}`,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: resp.discProfiles,
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }
  } catch (err) {
    debugger;
  }
}

export async function testSession({ onFail }: { onFail: () => void }) {
  try {
    await TytoCalls.AccountSession.get({});
  } catch (err) {
    onFail();
  }
}

export function thereIsSessionActivityWithinAnHour() {
  const lastActivity = getLocalStorageItem(LAST_SESSION_ACTIVITY);

  if (!lastActivity) {
    return true;
  }

  const lastSessionActivity = parseInt(`${lastActivity}`);

  if (lastSessionActivity && typeof lastSessionActivity === "number") {
    const now = Date.now();
    return now - lastSessionActivity < 1000 * 60 * 60;
  }

  return true;
}
