/*
 * Component Description
 */
import * as React from "react";

import { Button, PlaceholderText } from "../../../../components/common/";

import { TabType } from "../../tabs-interface/";
import PlaceholderPersonHeader from "./Placeholder-PersonHeader";
import PlaceholderTabsInterface from "./Placeholder-TabsInterface";

interface Props {
  backButton?: {
    value: string;
    onClick: () => void;
  };
  showBackButton: boolean;
  tabType: TabType;
}

export default (props: Props) => {
  return (
    <div className="directory-person-profile-wrapper side-pane-open">
      <section className="directory-person-profile-main-cont">
        {props.showBackButton && (
          <div className="directory-person-profile-back-button-cont">
            <Button
              iconLeft="angle-left"
              iconProps={{
                className: "directory-person-profile-back-button-icon",
                size: 30,
              }}
              className="directory-person-profile-back-button"
              onClick={props.backButton ? props.backButton.onClick : () => {}}
              value={props.backButton ? props.backButton.value : ""}
            />
          </div>
        )}

        <PlaceholderPersonHeader />

        <PlaceholderTabsInterface tabType={props.tabType} />
      </section>
    </div>
  );
};
