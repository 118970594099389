import { TabType } from "../../tabs-interface";
import { AppStoreProps } from "../../../../data/stores/AppStore";
import TytoCalls from "../../../../data/tyto";

export type PersonTabsType =
  | "comm-tips"
  | "about-r3type"
  | "about-user"
  | "you-and-other";

const personTabs = [
  {
    label: "Communication Tips",
    value: "comm-tips",
  },
  {
    label: "About Personality",
    value: "about-r3type",
  },
  {
    label: "Perception",
    value: "you-and-other",
  },
  {
    label: "About Them",
    value: "about-user",
  },
];

export function getTabsFromTabType(tabType: TabType) {
  switch (tabType) {
    case "profile-full":
    default:
      return personTabs;
  }
}

export async function loadMiniProfilesForTeam(
  teamID: string,
  appStore: AppStoreProps,
  isCascade?: boolean,
  domainID?: number
) {
  if (!appStore.dispatch) {
    return;
  }

  const teamIDParsed = parseInt(teamID);

  if (!teamIDParsed) {
    return;
  }

  const dispatchKey = isCascade
    ? "TEAM_AND_BELOW_DISC_MINI_PROFILES_LOADED"
    : "TEAM_DISC_MINI_PROFILES_LOADED";

  try {
    const teamMiniProfileResp = await TytoCalls.DISCProfilesMini.get({
      teamID: teamIDParsed,
      domainID,
      isCascade,
    });

    appStore.dispatch({
      payload: {
        discProfiles: teamMiniProfileResp.discProfiles,
        teamID,
      },
      type: dispatchKey,
    });
  } catch (err) {
    appStore.dispatch({
      payload: {
        discProfiles: [],
        teamID,
      },
      type: dispatchKey,
    });

    return;
  }
}
