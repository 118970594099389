/*
 * Component Description
 */
import * as React from "react";

import { getTabsFromTabType, PersonTabsType } from "./utils";
import Tabs from "./subcomponents/Tabs";
import AboutPersonality from "./subcomponents/profile-full-tabs/AboutPersonality";
import PersonCommTips from "./subcomponents/profile-full-tabs/PersonCommTips";
import YouAndOther from "./subcomponents/profile-full-tabs/YouAndOther";
import { Tyto } from "../../../typings/tyto";

import "./style.scss";
import tyto from "../../../data/tyto";

export type TabType = "profile-full";

interface Props {
  discMiniProfile: Tyto.DISCProfileMini;
  discCompareProfile?: Tyto.DISCCompareProfile;
  tabType: TabType;
  userDISCMini?: Tyto.DISCProfileMini;
}

export default (props: Props) => {
  const [tabs, updateTabs] = React.useState(getTabsFromTabType(props.tabType));
  const [curTab, updateCurTab] = React.useState(
    getTabsFromTabType(props.tabType)[0].value
  );

  React.useEffect(() => {
    const newTabs = getTabsFromTabType(props.tabType);

    updateCurTab(newTabs[0].value);
    updateTabs(newTabs);
  }, [props.tabType]);

  return (
    <section className="directory-tabs-interface-main-cont">
      <Tabs
        curTab={curTab}
        tabs={tabs}
        updateSelectedTab={value => updateCurTab(value)}
      />

      <div className="directory-tabs-tab-section-cont">
        {renderTabSection(props.tabType, curTab, {
          discMiniProfile: props.discMiniProfile,
          discCompareProfile: props.discCompareProfile,
          userDISCMini: props.userDISCMini
        })}
      </div>
    </section>
  );
};

const renderTabSection = (
  tabType: TabType,
  curTab: string,
  info: {
    discMiniProfile: Tyto.DISCProfileMini;
    discCompareProfile?: Tyto.DISCCompareProfile;
    userDISCMini?: Tyto.DISCProfileMini;
  }
) => {
  switch (tabType) {
    case "profile-full":
    default:
      return renderFullProfileTab(curTab as PersonTabsType, info);
  }
};

const renderFullProfileTab = (
  curTab: PersonTabsType,
  info: {
    discMiniProfile: Tyto.DISCProfileMini;
    discCompareProfile?: Tyto.DISCCompareProfile;
    userDISCMini?: Tyto.DISCProfileMini;
  }
) => {
  switch (curTab) {
    case "about-r3type":
      return (
        <AboutPersonality
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
        />
      );
    case "about-user":
      return <span>About User</span>;
    case "you-and-other":
      return (
        <YouAndOther
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
          userDISCMini={info.userDISCMini}
        />
      );
    case "comm-tips":
    default:
      return (
        <PersonCommTips
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
        />
      );
  }
};
