import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto";
import { StoreState as AdminStoreState } from "../../data/stores/AdminStore";

export function getNonDomainChildTeams({
  includeBelow,
  team,
  teamsStructured,
}: {
  includeBelow?: boolean;
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
}) {
  let childTeams = team.iPath
    ? teamsStructured[`${team.iPath}${team.teamID},`]
    : [];

  if (!Array.isArray(childTeams)) {
    return [];
  }

  if (includeBelow && childTeams.length) {
    const targetDomainID =
      team.teamType === "ocDOMAIN" ? team.teamID : team.domainID;

    const descendantTeams = childTeams
      .reduce((accum: Tyto.Team[], childTeam) => {
        let ownChildTeams: Tyto.Team[] = [];

        if (childTeam.domainID === targetDomainID) {
          ownChildTeams = getNonDomainChildTeams({
            includeBelow: true,
            team: childTeam,
            teamsStructured,
          });
        }

        return [...accum, ...(ownChildTeams || [])];
      }, [])
      .filter((team) => team.domainID === targetDomainID);

    if (descendantTeams.length) {
      childTeams = [...childTeams, ...descendantTeams];
    }
  }

  return childTeams.filter((childTeam) => childTeam.teamType !== "ocDOMAIN");
}

export function getDomainChildTeams({
  domainsByParentDomainID,
  team,
  teamsStructured,
}: {
  domainsByParentDomainID: AdminStoreState["domainsByParentDomainID"];
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
}) {
  const childTeams =
    team && team.teamID ? domainsByParentDomainID[`${team.teamID}`] : [];

  if (!Array.isArray(childTeams)) {
    return [];
  }

  return _.sortBy(
    childTeams.filter((childTeam) => childTeam.teamType === "ocDOMAIN"),
    "name"
  );

  // * Previously used logic
  // const childTeams = team.iPath
  //   ? teamsStructured[`${team.iPath}${team.teamID},`]
  //   : [];

  // if (!Array.isArray(childTeams)) {
  //   return [];
  // }

  // return childTeams.filter((childTeam) => childTeam.teamType === "ocDOMAIN");
}

export function teamShouldBeHidden({
  searchTerm,
  teamID,
  teamSearchMatchIDs,
  teamSearchMatchPathIDs,
}: {
  searchTerm?: string;
  teamID: number;
  teamSearchMatchIDs?: Set<number>;
  teamSearchMatchPathIDs?: Set<number>;
}) {
  if (!searchTerm) {
    return {
      hideTeam: false,
      hideDescendants: false,
    };
  }

  if (!teamSearchMatchIDs || !teamSearchMatchPathIDs) {
    return {
      hideTeam: false,
      hideDescendants: false,
    };
  }

  return {
    hideDescendants: !teamSearchMatchPathIDs.has(teamID),
    hideTeam: !teamSearchMatchIDs.has(teamID),
  };
}
