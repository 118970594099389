/*
 * Component Description
 */
import * as React from "react";

import { Textarea } from "../../../common";

import "./PlainText.scss";

interface Props {
  plainText: string;
}

export default (props: Props) => {
  return (
    <div className="modals-domainmessage-plaintext-cont">
      <p className="modals-domainmessage-plaintext-text">
        {props.plainText}
      </p>
    </div>
  );
};
