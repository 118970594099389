/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import TytoCalls from "../../../../data/tyto/";
import { Button, Checkbox, Icon, Input, Select } from "../../../common/";
import { Tyto } from "../../../../typings/tyto";

import "./CreateNewUser.scss";

// const hasNonNumberDashOrDot = /[^\d\.\-]/g;
// const hasNumber = /\d/g;

type ReportAccess = "inherit";

const ReportAccessOptions = [
  {
    label: "Inherit",
    value: "inherit",
  },
];

interface Props {
  reloadTeamMembers: (teamID: number) => void;
  team?: Tyto.Team;
}

export default (props: Props) => {
  const [firstName, updateFirstName] = React.useState("");
  const [lastName, updateLastName] = React.useState("");
  const [email, updateEmail] = React.useState("");
  const [emailError, updateEmailError] = React.useState<string | undefined>(
    undefined
  );
  const [emailSent, updateEmailSent] = React.useState<string | undefined>(
    undefined
  );
  const [sendEmail, updateSendEmail] = React.useState(true);
  const [reportAccess, updateReportAccess] = React.useState<ReportAccess>(
    "inherit"
  );
  const [sendingInvite, updateSendingInvite] = React.useState<boolean>(false);
  const [userCreated, updateUserCreated] = React.useState<boolean | undefined>(
    undefined
  );

  // const [manuallyImportScores, updateManuallyImportScores] = React.useState(
  //   false
  // );
  // const [driverValue, updateDriverValue] = React.useState("0");
  // const [influencerValue, updateInfluencerValue] = React.useState("0");
  // const [stabilizerValue, updateStabilizerValue] = React.useState("0");
  // const [analyzerValue, updateAnalyzerValue] = React.useState("0");

  // const parsedDValue = parseFloat(driverValue);
  // const parsedIValue = parseFloat(influencerValue);
  // const parsedSValue = parseFloat(stabilizerValue);
  // const parsedCValue = parseFloat(analyzerValue);

  //   const dValueIsValid =
  //     !hasNonNumberDashOrDot.test(_.escapeRegExp(driverValue)) &&
  //     hasNumber.test(_.escapeRegExp(driverValue));
  //   const iValueIsValid =
  //     !hasNonNumberDashOrDot.test(_.escapeRegExp(influencerValue)) &&
  //     hasNumber.test(_.escapeRegExp(influencerValue));
  //   const sValueIsValid =
  //     !hasNonNumberDashOrDot.test(_.escapeRegExp(stabilizerValue)) &&
  //     hasNumber.test(_.escapeRegExp(stabilizerValue));
  //   const cValueIsValid =
  //     !hasNonNumberDashOrDot.test(_.escapeRegExp(analyzerValue)) &&
  //     hasNumber.test(_.escapeRegExp(analyzerValue));

  const canSendInvite = !!firstName && !!lastName && !!email && !sendingInvite;

  return (
    <section className="meta-super-sidepane-createnewuser-main-cont">
      <h3 className="meta-super-sidepane-createnewuser-title">
        Create a new user
      </h3>

      <div className="meta-super-sidepane-createnewuser-inputs-cont">
        <div className="meta-super-sidepane-createnewuser-input-cont">
          <p className="meta-super-sidepane-createnewuser-input-label">
            First Name
          </p>

          <Input
            disabled={sendingInvite}
            className="meta-super-sidepane-createnewuser-input"
            onChange={(newFirstName) => updateFirstName(newFirstName)}
            hideClear={true}
            value={firstName}
          />
        </div>

        <div className="meta-super-sidepane-createnewuser-input-cont">
          <p className="meta-super-sidepane-createnewuser-input-label">
            Last Name
          </p>

          <Input
            disabled={sendingInvite}
            hideClear={true}
            className="meta-super-sidepane-createnewuser-input"
            onChange={(newLastName) => updateLastName(newLastName)}
            value={lastName}
          />
        </div>

        <div className="meta-super-sidepane-createnewuser-input-cont">
          <p className="meta-super-sidepane-createnewuser-input-label">
            Email Address
          </p>

          <Input
            disabled={sendingInvite}
            hideClear={true}
            className="meta-super-sidepane-createnewuser-input"
            onChange={(newEmail) => updateEmail(newEmail)}
            value={email}
          />
        </div>
      </div>

      {/* <div className="meta-super-sidepane-createnewuser-reportaccess-cont">
        <p className="meta-super-sidepane-createnewuser-input-label">
          Report Access
        </p>

        <Select
          className="meta-super-sidepane-createnewuser-select"
          omitFilter={true}
          onChange={(newValue) => updateReportAccess(newValue as ReportAccess)}
          options={ReportAccessOptions}
          value={reportAccess}
        />
      </div> */}

      <div className="meta-super-sidepane-createnewuser-sendemail-cont">
        <Checkbox
          checked={sendEmail}
          onCheck={(newValue) => updateSendEmail(newValue)}
          size={16}
        />

        <span className="meta-super-sidepane-createnewuser-subsection-label">
          Send Email invitation
        </span>
      </div>

      {/* <div className="meta-super-sidepane-createnewuser-importscores-cont"> */}
      {/* <Checkbox
          checked={manuallyImportScores}
          onCheck={newValue => updateManuallyImportScores(newValue)}
          size={16}
        />

        <span className="meta-super-sidepane-createnewuser-subsection-label">
          Import Assessment
        </span> */}

      {/* <div className="meta-super-sidepane-createnewuser-importscores-inner-cont"> */}
      {/* <div className="meta-super-sidepane-createnewuser-importscores-score-cont">
            <p className="meta-super-sidepane-createnewuser-importscores-score-label">
              Driver
            </p>
            <Input
              hideClear={true}
              containerClassName={cx(
                !parsedDValue &&
                  parsedDValue !== 0 &&
                  "meta-super-sidepane-createnewuser-importscores-score-input-invalid"
              )}
              className="meta-super-sidepane-createnewuser-importscores-score-input"
              disabled={sendingInvite || !manuallyImportScores}
              onChange={newDriverValue => updateDriverValue(newDriverValue)}
              value={`${driverValue}`}
            />
          </div> */}

      {/* <div className="meta-super-sidepane-createnewuser-importscores-score-cont">
            <p className="meta-super-sidepane-createnewuser-importscores-score-label">
              Influencer
            </p>
            <Input
              hideClear={true}
              containerClassName={cx(
                !parsedIValue &&
                  parsedIValue !== 0 &&
                  "meta-super-sidepane-createnewuser-importscores-score-input-invalid"
              )}
              className="meta-super-sidepane-createnewuser-importscores-score-input"
              disabled={sendingInvite || !manuallyImportScores}
              onChange={newInfluencerValue =>
                updateInfluencerValue(newInfluencerValue)
              }
              value={`${influencerValue}`}
            />
          </div> */}

      {/* <div className="meta-super-sidepane-createnewuser-importscores-score-cont">
            <p className="meta-super-sidepane-createnewuser-importscores-score-label">
              Stabilizer
            </p>
            <Input
              hideClear={true}
              containerClassName={cx(
                !parsedSValue &&
                  parsedSValue !== 0 &&
                  "meta-super-sidepane-createnewuser-importscores-score-input-invalid"
              )}
              className="meta-super-sidepane-createnewuser-importscores-score-input"
              disabled={sendingInvite || !manuallyImportScores}
              onChange={newStabilizerValue =>
                updateStabilizerValue(newStabilizerValue)
              }
              value={`${stabilizerValue}`}
            />
          </div> */}

      {/* <div className="meta-super-sidepane-createnewuser-importscores-score-cont">
            <p className="meta-super-sidepane-createnewuser-importscores-score-label">
              Analyzer
            </p>
            <Input
              hideClear={true}
              containerClassName={cx(
                !parsedCValue &&
                  parsedCValue !== 0 &&
                  "meta-super-sidepane-createnewuser-importscores-score-input-invalid"
              )}
              className="meta-super-sidepane-createnewuser-importscores-score-input"
              disabled={sendingInvite || !manuallyImportScores}
              onChange={newAnalyzerValue =>
                updateAnalyzerValue(newAnalyzerValue)
              }
              value={`${analyzerValue}`}
            />
          </div> */}
      {/* </div> */}
      {/* </div> */}

      <div className="meta-super-sidepane-createnewuser-invite-cont">
        <Button
          className="meta-super-sidepane-createnewuser-invite-button"
          disabled={!canSendInvite}
          onClick={() => {
            if (!props.team) {
              return;
            }

            updateSendingInvite(true);
            updateUserCreated(undefined);

            createUser({
              email,
              givenName: firstName,
              familyName: lastName,
              primaryElementID: props.team ? props.team.teamID : 0,
              sendEmail,
              onError: () => {
                updateSendingInvite(false);
                updateUserCreated(false);
                updateEmailError(undefined);
              },
              callback: (emailTempSessionKey?: string) => {
                updateEmailSent(emailTempSessionKey);
                props.reloadTeamMembers(props.team ? props.team.teamID : 0);
                updateSendingInvite(false);
                updateEmail("");
                updateFirstName("");
                updateLastName("");
                updateUserCreated(true);
              },
              emailFailed: (emailError: string) => {
                updateEmailError(emailError);
              },
            });
          }}
          shape="square"
          type="color"
          value={sendingInvite ? "Adding to Team..." : "Add to Team"}
        />

        {userCreated !== undefined && (
          <>
            <Icon
              className={cx(
                "meta-super-sidepane-createnewuser-created-icon",
                userCreated
                  ? "createnewuser-created-successfully"
                  : "createnewuser-created-unsuccessfully"
              )}
              icon={userCreated ? "check" : "close"}
              size={20}
            />

            {!!emailError && (
              <p className="meta-super-sidepane-createnewuser-created-emailerror-msg">
                {emailError}
              </p>
            )}
          </>
        )}
      </div>
    </section>
  );
};

const createUser = async ({
  email,
  givenName,
  familyName,
  primaryElementID,
  sendEmail,
  emailFailed,
  callback,
  onError,
}: {
  email: string;
  givenName: string;
  familyName: string;
  primaryElementID: number;
  sendEmail: boolean;
  callback: (emailTempSessionKey?: string) => void;
  emailFailed: (error: string) => void;
  onError: () => void;
}) => {
  if (!primaryElementID) {
    return;
  }

  try {
    const resp = await TytoCalls.Person.post({
      email,
      logonName: email,
      givenName,
      familyName,
      primaryElementID,
    });

    if (sendEmail) {
      const emailResp = await getEmailInviteLink({
        memberID: (resp as any).personID,
      });

      if (!!emailResp.error) {
        emailFailed(emailResp.error);
      }
    }

    callback();
  } catch (err) {
    console.warn("Failed to create use: ", err);
    onError();
  }
};

const getEmailInviteLink = async ({ memberID }: { memberID: number }) => {
  if (!memberID) {
    return {
      error: "No person indentifier found.",
    };
  }

  try {
    const resp = await TytoCalls.TeamToolsInvite.Email.post({ memberID });

    return {
      tempSessionKey: resp.tempSessionKey || "FakeTempSessionKey",
    };
  } catch (err) {
    return {
      error:
        typeof err === "string"
          ? err
          : _.get(err, "error.msg", "Failed to generate Link."),
    };
  }
};
