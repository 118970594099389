/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import pkg from "../../../../package.json";

import TytoCalls from "../../../data/tyto/";
import { TEAM_TOOLS_WEB_CHANGELOG_TEAMID } from "../../../data/constants/";
import { Timestamp } from "../../common/";

interface Props {
  onDismiss: () => void;
}

export default (props: Props) => {
  const [teamboardMessages, updateTeamboardMesages] = React.useState<any[]>([]);
  const [error, updateError] = React.useState("");
  const [curPackageNum] = React.useState((pkg && pkg.version) || "");

  React.useEffect(() => {
    loadTeamboardMessage({
      onLoad: (posts: any[]) => {
        if (Array.isArray(posts)) {
          const sortedPosts = _.orderBy(posts, ["noticeDate"], ["desc"]);
          updateTeamboardMesages(sortedPosts);
        }
      },
      onError: (msg) => updateError(msg),
    });
  }, []);

  return (
    <section className="">
      <h3 className="modals-changelog-title title-bold">Change Log</h3>

      {error && <p style={{ display: "block", color: "red" }}>{error}</p>}

      <ul className="modals-changelog-list">
        {!!teamboardMessages.length &&
          teamboardMessages.map((post, curIdx) => (
            <li className="modals-changelog-list-item" key={curIdx}>
              {parseText({
                curPackageNum,
                noticeText: _.get(post, "noticeText", undefined),
                noticeDate: _.get(post, "noticeDate", ""),
              })}
            </li>
          ))}
      </ul>
    </section>
  );
};

const parseText = ({
  curPackageNum,
  noticeDate,
  noticeText,
}: {
  curPackageNum: string;
  noticeDate: string;
  noticeText?: string;
}) => {
  if (!noticeText) {
    return "No Text found";
  }

  // const titleStartRegExp = /::com-tti-title-start::/g
  const titleEndRegExp = /::com-tti-title-end::/g;

  const textArr = noticeText.split(titleEndRegExp).filter((text) => !!text);

  if (textArr.length === 2) {
    const buildRegExp = new RegExp(_.escapeRegExp(curPackageNum), "i");
    const matchesThisPost = curPackageNum
      ? buildRegExp.test(textArr[0])
      : false;

    return (
      <>
        <h3 className="modals-changelog-list-item-title">
          {textArr[0]}
          {matchesThisPost && (
            <span className="modals-changelog-list-item-title-yourbuild">
              **You are using this build!
            </span>
          )}
        </h3>
        <p className="modals-changelog-list-item-date-timestamp">
          <Timestamp date={noticeDate} />
        </p>

        <p className="modals-changelog-list-item-text">{textArr[1]}</p>
      </>
    );
  } else {
    return (
      <>
        <h3 style={{ opacity: 0.4 }}>No Title Found</h3>
        <p className="modals-changelog-list-item-date-timestamp">
          <Timestamp date={noticeDate} />
        </p>

        <p>{textArr[0]}</p>
      </>
    );
  }
};

const loadTeamboardMessage = async ({
  onLoad,
  onError,
}: {
  onError: (msg: string) => void;
  onLoad: (posts: any[]) => void;
}) => {
  if (!TEAM_TOOLS_WEB_CHANGELOG_TEAMID) {
    return;
  }

  try {
    const resp = await TytoCalls.Teamboard.get({
      teamIDs: `${TEAM_TOOLS_WEB_CHANGELOG_TEAMID}`,
      top: 30,
    });

    onLoad((resp && resp.teamNotices) || []);
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Could not load Change Log.")
    );
  }
};
