/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Select } from "../../../common/";
import { personKeys } from "../utils/";

interface Props {
  columnCount: number;
  columnLabels: string[];
  curHoveredColumn: number;
  importing: boolean;
  showDeleteButton: boolean;
  updateCurHoveredColumn: (columnIdx: number) => void;
  updateLabel: (idx: number, value: string) => void;
}

export default (props: Props) => {
  const emailColumnMarked = props.columnLabels.some(label => label === "email");

  return (
    <li
      className={cx(
        "modals-importusers-modal-results-list-item",
        "modals-importusers-modal-results-list-item-title-cont",
        !emailColumnMarked && "modals-importusers-modal-results-list-item-error"
      )}
      style={{
        width: `${props.columnCount * 200 +
          20 +
          (props.showDeleteButton ? 35 : 0)}px`
      }}
    >
      {props.showDeleteButton && (
        <div className="modals-importusers-modal-results-list-item-delete-button-cont" />
      )}

      {props.columnLabels.map((label, curIdx) => (
        <Select
          key={`${label}-${curIdx}`}
          className="modals-importusers-modal-results-list-item-input"
          containerClassName={cx(
            "modals-importusers-modal-results-list-item-input-cont",
            props.curHoveredColumn === curIdx && "is-cur-hovered-column"
          )}
          disabled={props.importing}
          onChange={newValue => props.updateLabel(curIdx, newValue as string)}
          // onMouseEnter={() => props.updateCurHoveredColumn(curIdx)}
          omitFilter={true}
          options={personKeys}
          placeholder="Select Key"
          value={label || ""}
        />
      ))}
    </li>
  );
};
