import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M571 228.5c-78.1 88.2-179.8 108.8-184.1 109.6-134.8 26.1-153.3 7.5-237.1 37.5-10.6 3.8-21.8-3.6-21.8-14.8V112.5c0-9 7.3-16.4 16.3-16 43.2 2 95.3 13.2 155.2 42.4 140.6 68.5 223.7 62.9 252.9 57.2 18-3.8 31.3 18.1 18.6 32.4zM56 0C25.1 0 0 25.1 0 56c0 22.3 13.2 41.4 32 50.4V504c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V106.4c18.8-9 32-28.1 32-50.4 0-30.9-25.1-56-56-56z"
      />
    </svg>
  );
};
