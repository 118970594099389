/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Message, ToggleSection } from "../../../../../components/common/";

import "./TeamTips.scss";

interface Props {
  closedAtStart?: boolean;
  onLinkClick?: () => void;
  tips: Array<React.FC | string>;
  title: string;
}

export default (props: Props) => {
  return (
    <ToggleSection
      title={props.title}
      className=""
      closedAtStart={!!props.closedAtStart}
      onLinkClick={props.onLinkClick}
      renderFunction={isExpanded => {
        return isExpanded ? (
          <ul className="meta-sidepane-team-team-tips-tip-list">
            {Array.isArray(props.tips) && props.tips.length ? (
              props.tips.map((SingleTip, curIdx) => (
                <li
                  className={cx(
                    "meta-sidepane-team-team-tips-tip",
                    !!curIdx && "meta-sidepane-team-team-tips-tip-not-first"
                  )}
                  key={typeof SingleTip === "string" ? SingleTip : curIdx}
                >
                  {/* <span className="team-team-tips-tip-dot">*</span> */}
                  {typeof SingleTip === "string" ? SingleTip : <SingleTip />}
                </li>
              ))
            ) : (
              <li className="meta-sidepane-team-team-tips-tip tips-empty-msg">
                <Message value="No Tips Found" style={{ marginTop: "20px" }} />
              </li>
            )}
          </ul>
        ) : (
          <span style={{ visibility: "hidden" }} />
        );
      }}
    />
  );
};
