/*
 * Component Description
 */
import * as React from "react";

import AgnosticModal from "../../modals/agnostic/";
import SidePane, { Props as SidePaneProps } from "./";

import "./SidePaneModal.scss";

export default (props: SidePaneProps) => {
  const [showModal, updateShowModal] = React.useState(true);

  return (
    <AgnosticModal
      onDismiss={() => {
        if (props.onClose) {
          updateShowModal(false);
          props.onClose();
        }
      }}
      isOpen={showModal}
      className="meta-idepane-modal"
      overlayClassName="meta-sidepane-modal-overlay"
      blurBody={false}
      hasCloseBtn={false}
    >
      <SidePane {...props} />
    </AgnosticModal>
  );
};
