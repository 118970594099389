/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../typings/tyto";

import {
  getDomainMessage,
  saveDomainMessage,
} from "../../meta/super-side-pane/utils";
import { Button, TextButton } from "../../common";

import PlainText from "./subcomponents/PlainText";
import HTMLBody from "./subcomponents/HTMLBody";
import Preview, { checkIfHTMLHasScriptTab } from "./subcomponents/Preview";

const DEFAULT_DOMAIN_MESSAGE: Partial<Tyto.Domain.Message> = {
  headLine: "",
  bodyHtml: "",
  bodyPlain: "",
};

interface Props {
  domainID: number;
  messageType: string;
  onDismiss: () => void;
  title: string;
}

type ActiveTab = "html" | "plaintext" | "preview";

export default (props: Props) => {
  const [saving, updateSaving] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [messageTypeLoaded, updateMessageTypeLoaded] = React.useState(false);
  const [
    domainMessage,
    updateDomainMessage,
  ] = React.useState<Tyto.Domain.Message | null>(null);
  const [headline, updateHeadline] = React.useState("");
  const [htmlBody, updateHTMLBody] = React.useState("");
  const [plainText, updatePlainText] = React.useState("");
  const [activeTab, updateActiveTab] = React.useState<ActiveTab>("html");

  React.useEffect(() => {
    if ((!props.domainID && props.domainID !== 0) || !props.messageType) {
      updateError("Proper information not found.");
    } else {
      updateMessageTypeLoaded(false);
      updateHeadline("");
      updatePlainText("");
      updateHTMLBody("");

      getDomainMessage({
        domainID: props.domainID,
        messageType: props.messageType,
        forceDomain: true,
        onSuccess: (newDomainMessage) => {
          const newMessage =
            newDomainMessage ||
            getDefaultMessage({
              domainID: props.domainID,
              messageType: props.messageType,
            });

          updateDomainMessage(newMessage);
          updateHeadline(newMessage.headLine || "");
          updateHTMLBody(newMessage.bodyHtml || "");
          updatePlainText(newMessage.bodyPlain || "");

          updateMessageTypeLoaded(true);
        },
        onError: (msg: string) => {
          updateError(msg);
          updateMessageTypeLoaded(true);
        },
      });
    }
  }, [props.domainID, props.messageType]);

  const hasChange = domainMessage
    ? domainMessage.headLine !== headline ||
    domainMessage.bodyHtml !== htmlBody ||
    domainMessage.bodyPlain !== plainText
    : false;

  return (
    <section className="modals-domainmessageedit-inner-cont">
      <h3>{props.title}</h3>

      <div className="modals-domainmessageedit-tabs-cont">
        <TextButton
          className={cx(
            "modals-domainmessageedit-tab",
            activeTab === "html" && "active-tab"
          )}
          onClick={() => updateActiveTab("html")}
          value="Edit Html"
        />
        <TextButton
          className={cx(
            "modals-domainmessageedit-tab",
            activeTab === "plaintext" && "active-tab"
          )}
          onClick={() => updateActiveTab("plaintext")}
          value="Edit Plain Text"
        />
        <TextButton
          className={cx(
            "modals-domainmessageedit-tab",
            activeTab === "preview" && "active-tab"
          )}
          onClick={() => updateActiveTab("preview")}
          value="Preview HTML"
        />
      </div>

      {messageTypeLoaded ? (
        renderTab({
          activeTab,
          domainMessage,
          headline,
          htmlBody,
          plainText,
          updateHeadline,
          updateHTMLBody,
          updatePlainText,
        })
      ) : (
          <div>
            <h3 style={{ color: "#000" }}>Loading...</h3>
          </div>
        )}

      {messageTypeLoaded && (
        <div className="modals-domainmessageedit-buttons-cont">
          <Button
            className="modals-domainmessageedit-save-button"
            disabled={saving || !hasChange}
            onClick={() => {
              if (!domainMessage) {
                return;
              }

              const htmlHasScriptTag = !htmlBody
                ? false
                : checkIfHTMLHasScriptTab(htmlBody);

              if (htmlHasScriptTag) {
                updateActiveTab("preview");
                return;
              }

              updateSaving(true);

              saveDomainMessage({
                data: {
                  domainID: props.domainID,
                  ...(domainMessage.headLine !== headline
                    ? { headLine: headline || "" }
                    : {}),
                  ...(domainMessage.bodyHtml !== htmlBody
                    ? { bodyHtml: htmlBody || "" }
                    : {}),
                  ...(domainMessage.bodyPlain !== plainText
                    ? { bodyPlain: plainText || "" }
                    : {}),
                },
                messageType: props.messageType,
                onSuccess: (newDomainMessage) => {
                  const newMessage =
                    newDomainMessage ||
                    getDefaultMessage({
                      domainID: props.domainID,
                      messageType: props.messageType,
                    });

                  updateDomainMessage(newDomainMessage);
                  updateHeadline(newMessage.headLine || "");
                  updateHTMLBody(newMessage.bodyHtml || "");
                  updatePlainText(newMessage.bodyPlain || "");

                  updateSaving(false);
                },
                onError: (msg: string) => {
                  updateError(msg);
                  updateSaving(false);
                },
              });
            }}
            shape="square"
            type="color"
            value={saving ? "Saving Changes..." : "Save Changes"}
          />
        </div>
      )}
    </section>
  );
};

const getDefaultMessage = ({
  domainID,
  messageType,
}: {
  domainID: number;
  messageType: string;
}) => {
  return {
    ...DEFAULT_DOMAIN_MESSAGE,
    domainID,
    messageType,
    modifiedByID: 0,
    modifiedDate: new Date().toISOString(),
  } as Tyto.Domain.Message;
};

const renderTab = ({
  activeTab,
  domainMessage,
  headline,
  htmlBody,
  plainText,
  updateHeadline,
  updateHTMLBody,
  updatePlainText,
}: {
  activeTab: ActiveTab;
  domainMessage: Tyto.Domain.Message | null;
  headline: string;
  htmlBody: string;
  plainText: string;
  updateHeadline: (newVal: string) => void;
  updateHTMLBody: (newVal: string) => void;
  updatePlainText: (newVal: string) => void;
}) => {
  switch (activeTab) {
    case "html":
      return (
        <HTMLBody
          htmlBody={htmlBody}
          hasChange={
            domainMessage ? htmlBody !== domainMessage.bodyHtml : false
          }
          updateValue={updateHTMLBody}
        />
      );
    case "plaintext":
      return (
        <PlainText
          plainText={plainText}
          hasChange={
            domainMessage ? plainText !== domainMessage.bodyPlain : false
          }
          updateValue={updatePlainText}
        />
      );
    case "preview":
    default:
      return <Preview htmlBody={htmlBody} plainText={plainText} />;
  }
};
