/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../../../typings/tyto";
import Downloads from "./Downloads";

import "./AdminTab.scss";

interface Props {
  discMiniProfile?: Tyto.DISCProfileMini;
  userHasManage?: boolean;
}

export default (props: Props) => {
  if (!props.userHasManage) {
    return (
      <div className="directory-tabs-tab-section-admin-section-cont">
        You do not have permission to view Admin information.
      </div>
    );
  }

  return (
    <div className="directory-tabs-tab-section-admin-section-cont">
      <h3 className="title-bold directory-tabs-tab-section-admin-section-title">
        Downloads
      </h3>

      <Downloads discMiniProfile={props.discMiniProfile} />
    </div>
  );
};
