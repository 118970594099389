/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../../../typings/tyto";
import { Message } from "../../../../../components/common";

import GraphAndTip from "./GraphAndTip";
import CommTips from "./CommTips";

import "./PersonCommTips.scss";

interface Props {
  discMiniProfile: Tyto.DISCProfileMini;
  discFullProfile?: Tyto.DISCInfo;
  discCompareProfile?: Tyto.DISCCompareProfile;
}

export default (props: Props) => {
  const [commTips, updateCommTips] = React.useState(
    getDosDonts(props.discCompareProfile)
  );

  React.useEffect(() => {
    console.log("Updating Dos and Don'ts in Person Comm Tips Tab");
    updateCommTips(getDosDonts(props.discCompareProfile));
  }, [props.discCompareProfile]);

  return (
    <section className="directory-tabs-tab-section-comm-tips-cont">
      <GraphAndTip
        discCompareProfile={props.discCompareProfile}
        discMiniProfile={props.discMiniProfile}
      />

      <div className="directory-tabs-tab-section-comm-tips-tips-section">
        <h3 className="directory-tabs-tab-section-comm-tips-tips-section-title cc-toggle-section-title">
          When working with{" "}
          {props.discMiniProfile.personName.split(" ")[0] || "Them"}
        </h3>

        <div className="directory-tabs-tab-section-comm-tips-tips-section-left-cont">
          <h3 className="directory-tabs-tab-section-comm-tips-tips-section-subtitle tip-section-do-these-title">
            Do These Things
          </h3>

          <ul className="directory-tabs-tab-section-comm-tips-tips-section-tip-list">
            {commTips.doThese ? (
              commTips.doThese.map(tip => (
                <li
                  className="directory-tabs-tab-section-comm-tips-tips-section-tip"
                  key={tip.discCommTipID}
                >
                  {tip.tipDescription}
                </li>
              ))
            ) : (
              <Message
                value="These tips are not available"
                style={{
                  marginTop: "30px",
                  marginBottom: "30px"
                }}
              />
            )}
          </ul>
        </div>

        <div className="directory-tabs-tab-section-comm-tips-tips-section-right-cont">
          <h3 className="directory-tabs-tab-section-comm-tips-tips-section-subtitle tip-section-dontdo-these-title">
            Don't Do These Things
          </h3>

          <ul className="directory-tabs-tab-section-comm-tips-tips-section-tip-list">
            {commTips.doThese ? (
              commTips.dontDoThese.map(tip => (
                <li
                  className="directory-tabs-tab-section-comm-tips-tips-section-tip"
                  key={tip.discCommTipID}
                >
                  {tip.tipDescription}
                </li>
              ))
            ) : (
              <Message
                value="These tips are not available"
                style={{
                  marginTop: "30px",
                  marginBottom: "30px"
                }}
              />
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

const getDosDonts = (discCompareProfile?: Tyto.DISCCompareProfile) => {
  if (!discCompareProfile || !discCompareProfile.communicationTips) {
    return {
      doThese: [],
      dontDoThese: []
    };
  }

  const { doThese, dontDoThese } = discCompareProfile.communicationTips.reduce(
    (
      accum: {
        doThese: Tyto.DISCCompareProfile.CommTip[];
        dontDoThese: Tyto.DISCCompareProfile.CommTip[];
      },
      commTip
    ) => {
      if (commTip.blnDo) {
        accum.doThese.push(commTip);
      } else {
        accum.dontDoThese.push(commTip);
      }

      return accum;
    },
    { doThese: [], dontDoThese: [] }
  );

  return {
    doThese,
    dontDoThese
  };
};

const getGradientBG = (selectedLetter: "d" | "i" | "s" | "c") => {
  return "";
};
