import { ENDPOINT_DISC_247_IMPORT_SELF_ASSESSMENT } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_247_IMPORT_SELF_ASSESSMENT;

export const DISC247ImportSelfAssessment = {
  get(
    params: Endpoints.Tyto.DISC247.FindSelfAssessments.Get.Parameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Tyto.DISC247.FindSelfAssessments.Get.Response>;
  },
};
