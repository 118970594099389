import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M331.1 360.5c2.3 8.5 11.1 13.6 19.6 11.3l117-31.3c8.5-2.3 13.6-11.1 11.3-19.6L447.6 204c-2.3-8.5-11.1-13.6-19.6-11.3L311 224c-8.5 2.3-13.6 11.1-11.3 19.6l31.4 116.9zM0 479.9c0 17.7 14.3 32 32 32s32-14.3 32-32v-74.5C48 391.7.5 350.8 0 350.3v129.6zm503.4-106.1c-1.1-4.3-5.5-6.8-9.8-5.7l-154.1 41.3c-9.8-12.9-24.2-21.9-40.9-24.5l-59.4-221.6c-1.1-4.3-5.5-6.8-9.8-5.7l-30.9 8.3c-4.3 1.1-6.8 5.5-5.7 9.8l12.9 48.2h-39l-33.3-61.8c-11.1-21.1-32.8-34.2-56.7-34.2H48c-26.5 0-48 21.5-48 48v103c0 18.7 8.2 36.4 22.4 48.6l76 65.1 14.1 92.5c1 5.7 10.1 30.7 36.8 26.3 17.4-2.9 29.2-19.4 26.3-36.8l-14.1-92.5c-2.5-14.8-10.1-28.3-21.5-38.1l-44-37.7V228l32 59.9h94.8l28.9 107.8c-16.4 11.6-27.2 30.6-27.2 52.2 0 35.3 28.7 64 64 64 32.7 0 59.3-24.5 63.2-56.1l154.2-41.3c4.3-1.1 6.8-5.5 5.7-9.8l-8.2-30.9zm-214.8 90.1c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.1 16-16 16zM80 96c26.5 0 48-21.5 48-48S106.6 0 80 0 32 21.5 32 48s21.5 48 48 48z"
      />
    </svg>
  );
};
