/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const Crane = (props: Props) => {
  return (
    <img
      className={cx("team-tools-crane", props.className)}
      alt="Team Tools Crane"
      src="/team-tools-crane.png"
      style={props.style}
    />
  );
};

export default Crane;
