/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import {
  getTabsFromTabType,
  PersonTabsType,
  OwnProfileTabsType,
} from "./utils/";
import Tabs from "./subcomponents/Tabs";
import AboutPersonality from "./subcomponents/profile-full-tabs/AboutPersonality";
import Account from "./subcomponents/profile-full-tabs/Account";
import AdminTab from "./subcomponents/profile-full-tabs/AdminTab";
import Downloads from "./subcomponents/profile-full-tabs/Downloads";
import GrowthAreas from "./subcomponents/profile-full-tabs/GrowthAreas";
import NoDISC from "./subcomponents/profile-full-tabs/NoDISC";
import PersonCommTips from "./subcomponents/profile-full-tabs/PersonCommTips";
import YouAndOther from "./subcomponents/profile-full-tabs/YouAndOther";
import TryybProfile from "./subcomponents/profile-full-tabs/TryybProfile";
import { Tyto } from "../../../typings/tyto";

import "./style.scss";
import { AppStoreProps } from "../../../data/stores/AppStore";

export type TabType = "profile-full" | "own-profile";

interface Props {
  curTabName?: string;
  discMiniProfile?: Tyto.DISCProfileMini;
  discCompareProfile?: Tyto.DISCCompareProfile;
  tabType: TabType;
  tryybProfile?: Tyto.Person;
  userDISCMini?: Tyto.DISCProfileMini;
  userHasManage: boolean;
  userID?: string;
}

export default (props: Props) => {
  const [tabs, updateTabs] = React.useState(
    getTabsFromTabType(props.tabType, !!props.discMiniProfile)
  );
  const [curTab, updateCurTab] = React.useState(() => {
    const tabs = getTabsFromTabType(props.tabType, !!props.discMiniProfile);

    const matchingTab =
      props.curTabName && tabs.find((tab) => tab.value === props.curTabName);

    return matchingTab ? matchingTab.value : tabs[0].value;
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    const newTabs = getTabsFromTabType(props.tabType, !!props.discMiniProfile);

    const matchingTab =
      props.curTabName &&
      newTabs.find(
        (tab) =>
          tab.value === props.curTabName ||
          (props.curTabName === "download" && tab.value === "downloads")
      );

    updateCurTab(matchingTab ? matchingTab.value : newTabs[0].value);
    updateTabs(newTabs);
  }, [props.tabType, props.curTabName]);

  return (
    <section className="directory-tabs-interface-main-cont">
      <Tabs
        curTab={curTab}
        discMiniProfile={props.discMiniProfile}
        tabs={tabs}
        // updateSelectedTab={value => updateCurTab(value)}
        updateSelectedTab={(value) =>
          navigate(`/profile/${props.userID}/${value}`)
        }
        userHasManage={props.userHasManage}
      />

      <div
        className={cx(
          "directory-tabs-tab-section-cont",
          curTab === "admin-info" && "directory-tabs-tab-section-cont-for-admin"
        )}
      >
        {renderTabSection(props.tabType, curTab, {
          discMiniProfile: props.discMiniProfile,
          discCompareProfile: props.discCompareProfile,
          tryybProfile: props.tryybProfile,
          userDISCMini: props.userDISCMini,
          userHasManage: props.userHasManage,
        })}
      </div>
    </section>
  );
};

const renderTabSection = (
  tabType: TabType,
  curTab: string,
  info: {
    discMiniProfile?: Tyto.DISCProfileMini;
    discCompareProfile?: Tyto.DISCCompareProfile;
    tryybProfile?: Tyto.Person;
    userDISCMini?: Tyto.DISCProfileMini;
    userHasManage?: boolean;
  }
) => {
  switch (tabType) {
    case "own-profile":
      return renderOwnProfileTab(curTab as OwnProfileTabsType, info);
    case "profile-full":
    default:
      return renderFullProfileTab(curTab as PersonTabsType, info);
  }
};

const renderFullProfileTab = (
  curTab: PersonTabsType,
  info: {
    discMiniProfile?: Tyto.DISCProfileMini;
    discCompareProfile?: Tyto.DISCCompareProfile;
    tryybProfile?: Tyto.Person;
    userDISCMini?: Tyto.DISCProfileMini;
    userHasManage?: boolean;
  }
) => {
  if (!info.discMiniProfile) {
    switch (curTab) {
      case "tryyb-profile":
        return (
          <Account
            // discMiniProfile={info.discMiniProfile}
            tryybProfile={info.tryybProfile}
          />
        );
      // return <TryybProfile tryybProfile={info.tryybProfile} />;
      case "no-disc":
      default:
        return <NoDISC />;
    }
  }

  switch (curTab) {
    case "admin-info":
      return (
        <AdminTab
          discMiniProfile={info.discMiniProfile}
          userHasManage={info.userHasManage}
        />
      );
    case "about-r3type":
      return (
        <AboutPersonality
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
        />
      );
    case "tryyb-profile":
      return (
        <Account
          discMiniProfile={info.discMiniProfile}
          tryybProfile={info.tryybProfile}
        />
      );
    // return <TryybProfile tryybProfile={info.tryybProfile} />;
    case "you-and-other":
      return (
        <YouAndOther
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
          userDISCMini={info.userDISCMini}
        />
      );
    case "comm-tips":
    default:
      return (
        <PersonCommTips
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
        />
      );
  }
};

const renderOwnProfileTab = (
  curTab: OwnProfileTabsType,
  info: {
    discMiniProfile?: Tyto.DISCProfileMini;
    discCompareProfile?: Tyto.DISCCompareProfile;
    tryybProfile?: Tyto.Person;
    userDISCMini?: Tyto.DISCProfileMini;
    userHasManage?: boolean;
  }
) => {
  if (!info.discMiniProfile) {
    switch (curTab) {
      case "tryyb-profile":
        return (
          <Account
            discMiniProfile={info.discMiniProfile}
            tryybProfile={info.tryybProfile}
          />
        );
      // return <TryybProfile tryybProfile={info.tryybProfile} />;
      case "no-disc":
      default:
        return <NoDISC />;
    }
  }

  switch (curTab) {
    case "about-r3type":
      return (
        <AboutPersonality
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
        />
      );
    case "comm-tips":
      return (
        <PersonCommTips
          discMiniProfile={info.discMiniProfile}
          discCompareProfile={info.discCompareProfile}
          isOwnProfile={true}
        />
      );
    case "downloads":
    case "download":
      return (
        <Downloads discMiniProfile={info.discMiniProfile} isOwnProfile={true} />
      );
    case "tryyb-profile":
      return (
        <Account
          discMiniProfile={info.discMiniProfile}
          tryybProfile={info.tryybProfile}
        />
      );
    case "growth-areas":
    default:
      return <GrowthAreas compareProfile={info.discCompareProfile} />;
  }
};
