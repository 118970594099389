/*
  * Component Description
*/
import * as React from "react";
import * as _ from "lodash";

import TytoCalls from "../../../data/tyto/";
import { Tyto } from "../../../typings/tyto";
import { Input } from "../../common/";
import { StoreContext, AppStoreProps } from "../../../data/stores/AppStore";

import SearchResultPerson from "./subcomponents/SearchResultPerson";

interface Props {
    onDismiss: () => void
}

export default (props: Props) => {
    let AppStore = React.useContext(StoreContext);

    const [searchText, updateSearchText] = React.useState("");
    const [searchResults, updateSearchResults] = React.useState<{ [x: string]: Tyto.AdvancedPerson[] }>({});
    const [errorMsgs, updateErrorMsgs] = React.useState<{ [x: string]: string; }>({});
    const [timeoutKey, updateTimeoutKey] = React.useState<any>(undefined);
    const [impersonating, updateImpersonating] = React.useState(false);

    const curSearchResults = searchResults[searchText];

  return (
    <div className="modals-impersonatesearch-modal-inner-cont">
        <div className="modals-impersonatesearch-modal-inner-search-cont">
            <Input
                containerClassName="modals-impersonatesearch-modal-inner-search-wrapper"
                className="modals-impersonatesearch-modal-inner-search-input"
                disabled={impersonating}
                onChange={newSearchText => {
                    updateSearchText(newSearchText);

                    delayedSearch({
                        searchText: newSearchText,
                        onError: (update) => {
                            updateErrorMsgs({
                                ...errorMsgs,
                                [update.searchText]: update.errorMsg
                            });
                        },
                        onSuccess: (update) => {
                            updateSearchResults({
                                ...searchResults,
                                [update.searchText]: update.people
                            })
                        },
                        searchResults: searchResults,
                        timeoutKey,
                        updateTimeoutKey,
                    });
                }}
                value={searchText}
            />
        </div>
        
        <ul className="modals-impersonatesearch-modal-inner-search-results-list">
            {!curSearchResults && !!searchText && (
                <span className="modals-impersonatesearch-modal-inner-search-results-list-loading-msg">Loading...</span>
            )}
            {curSearchResults && curSearchResults.map((searchResultPerson) => (
                <SearchResultPerson
                    AppStore={AppStore}
                    key={searchResultPerson.userID}
                    onPersonSelect={() => {
                        if (props.onDismiss) {
                            props.onDismiss();
                        }
                    }}
                    impersonating={impersonating}
                    person={searchResultPerson}
                    toggleImpersonating={newVal => {
                        updateImpersonating(newVal);
                    }}
                />
            ))}
        </ul>
    </div>
  );
}

async function delayedSearch({ updateTimeoutKey, searchResults, timeoutKey, ...searchData}: {
    searchText: string;
    onError: (update: {
        searchText: string;
        errorMsg: string;
    }) => void;
    onSuccess: (update: {
        searchText: string;
        people: Tyto.AdvancedPerson[];
    }) => void;
    searchResults: { [x: string]: Tyto.AdvancedPerson[] };
    timeoutKey: any;
    updateTimeoutKey: (key: any) => void;
}) {
    if (!searchData.searchText || searchResults[searchData.searchText]) {
        return;
    }

    if (timeoutKey) {
        clearTimeout(timeoutKey);
        updateTimeoutKey(undefined);
    }

    const newTimeoutKey = setTimeout(() => {
        search(searchData)
    }, 350);

    updateTimeoutKey(newTimeoutKey);
}

async function search({
    searchText,
    onError,
    onSuccess
}: {
    searchText: string;
    onError: (update: {
        searchText: string;
        errorMsg: string;
    }) => void;
    onSuccess: (update: {
        searchText: string;
        people: Tyto.AdvancedPerson[];
    }) => void;
}) {
    try {
        const safeSearchText = `%${(searchText || "").replace(/ /g, "%")}%`;
        
        const personSearchResp = await TytoCalls.PersonAdvanced.get({
            generalName: safeSearchText,
            // functionName: "Impersonate",
            // operation: "ocVIEW"

        });

        onSuccess({
            searchText,
            people: _.get(personSearchResp, "ret.people", [])
        })
    } catch (err) {
        const errorMsg: string = typeof err === "string" ? err : _.get(err, "msg", _.get(err, "technical", "Error occurred while search for people"));

        onError({
            errorMsg,
            searchText
        })
    }
}
