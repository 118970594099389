/*
 * Component Description
 */
import * as React from "react";

import PlaceholderMembersListItem from "./PlaceholderMemberListItem";

import "./MembersList.scss";
// import "./PlaceholderMembersList.scss";

interface Props {
  length: number;
}

export default (props: Props) => {
  const [placeholderArr] = React.useState(() => {
    const arr = Array.from({ length: props.length }, (__) =>
      Array.from({ length: 10 }, () =>
        String.fromCharCode(Math.round(Math.random() * 42) + 48)
      ).join("")
    );

    return arr;
  });

  return (
    <div className="groups-people-list-cont">
      <ul className="groups-people-list">
        {placeholderArr &&
          placeholderArr.map((key) => <PlaceholderMembersListItem key={key} />)}
      </ul>
    </div>
  );
};
