/*
 * Component Description
 */
import * as React from "react";

import AgnosticModal from "../agnostic/";
import PeopleSearch from "./PeopleSearch";

import "./style.scss";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className="modals-impersonatesearch-modal-main-cont"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-impersonatesearch-modal-overlay"
    >
      <PeopleSearch onDismiss={props.onDismiss} />
    </AgnosticModal>
  );
};