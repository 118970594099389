/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import TeamTreeItemWrapper from "./TeamTreeItemWrapper";
// import TeamTreeItemWrapper from "./TeamTreeItemWrapper";

import { StoreState as AppStoreState } from "../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState
} from "../data/stores/AdminStore";
import { Tyto } from "../../../typings/tyto";
import SearchBar from "../subcomponents/SearchBar";

import "./TeamTree.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AdminStoreState: AdminStoreState;
  chooseTeam: (teamID: number) => void;
  curDomainID: number;
  disabled: boolean;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  previewPersonID?: number;
  selectedTeamID: number;
  selectedPeopleIDs: {
    [x: number]: boolean;
  };
  selectedTeamsIDs: {
    [x: number]: boolean;
  };
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
}

export default ({
  AdminStoreDispatch,
  AdminStoreState,
  chooseTeam,
  curDomainID,
  disabled,
  discMiniByTeam,
  previewPersonID,
  selectedPeopleIDs,
  selectedTeamID,
  selectedTeamsIDs,
  team
}: Props) => {
  const [teamTreeSearchTerm, updateTeamTreeSearchTerm] = React.useState("");

  const regExp = teamTreeSearchTerm
    ? new RegExp(_.escapeRegExp(teamTreeSearchTerm), "i")
    : undefined;

  const aTeamIsChecked = Object.values(AdminStoreState.selectedTeamsIDs).some(
    value => !!value
  );

  return (
    <div className="admin-content-people-manage-teamtree-cont">
      <SearchBar
        disabled={disabled}
        value={teamTreeSearchTerm}
        onChange={newTerm => updateTeamTreeSearchTerm(newTerm)}
        placeholder="Filter Teams"
      />

      <ul className="admin-content-people-manage-teamtree-list admin-teams-team-structure-list">
        <TeamTreeItemWrapper
          AdminStoreDispatch={AdminStoreDispatch}
          AdminStoreState={AdminStoreState}
          curDomainID={team.teamID}
          chooseTeam={teamID =>
            AdminStoreDispatch({
              payload: {
                selectedTeamID: teamID
              },
              type: "ADMIN_STORE_SELECTED_TEAM_ID"
            })
          }
          disabled={disabled}
          discMiniByTeam={discMiniByTeam}
          fadeUnselectedTeams={!!aTeamIsChecked}
          previewPersonID={previewPersonID || undefined}
          // previewPersonID={previewPerson ? previewPerson.personID : undefined}
          selectedPeopleIDs={selectedPeopleIDs}
          selectedTeamsIDs={selectedTeamsIDs}
          selectedTeamID={selectedTeamID || 0}
          searchTerm={teamTreeSearchTerm}
          searchTermRegExp={regExp}
          team={team}
          teamsStructured={AdminStoreState.structured}
          depth={0}
        />
      </ul>
    </div>
  );
};
