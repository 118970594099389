/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { triggerMixPanelEvent } from "../../../data/logging/";

import { DEFAULT_TEAMTOOLS_USER_PERMISSIONS } from "../../../data/constants/";
import { StoreContext, AppStoreProps } from "../../../data/stores/AppStore";
import { StoreContext as GeneralStoreContext } from "../../../data/stores/GeneralStore";
import {
  StoreContext as AdminStoreContext,
  AdminStoreProps,
} from "../../../interfaces/admin/data/stores/AdminStore";

import {
  loadTeamStructure,
  loadTeamMembers,
} from "../../../interfaces/admin/data/utils/";
import { Tyto } from "../../../typings/tyto";

interface Props {
  path?: string;
  uri?: string;
  location?: any;
  children: any;
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);
  let AdminStore = React.useContext(AdminStoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [loggedInUserID, updateLoggedInUserID] = React.useState<
    number | undefined
  >(_.get(AppStore, "state.loggedInUserID", 0));
  const [loggedInUserTeamToolsPermit, updateLoggedInUserTeamToolsPermit] =
    React.useState<Tyto.TeamToolsPermissions | undefined>(
      _.get(
        AppStore,
        `state.loggedInUserTeamToolsPermit[${_.get(
          AppStore,
          "state.loggedInUserID",
          undefined
        )}]`
      )
    );
  // const [sessionData, updateSessionData] = React.useState(_.get(AppStore, "state.sessionData", undefined));

  // React.useEffect(() => {

  // }, []);

  React.useEffect(() => {
    if (_.get(AppStore, "state.sessionData", undefined)) {
      loadTeamMemberships({ AppStore, AdminStore });
    } else {
      debugger;
    }
  }, []);

  React.useEffect(() => {
    const newLoggedInUserID = _.get(
      AppStore,
      "state.loggedInUserID",
      undefined
    );

    // * An attempt to fix the case where the redirect set on line 68sh below
    // * doesn't make new account reload same interface that they shouldn't see
    if (
      loggedInUserID &&
      (!newLoggedInUserID || loggedInUserID !== newLoggedInUserID)
    ) {
      updateLoggedInUserID(newLoggedInUserID);

      if (AdminStore.dispatch) {
        AdminStore.dispatch({
          payload: {},
          type: "ADMIN_STORE_CLEAR_STORE",
        });
      }

      navigate("/");
      // * Load TeamStructure if such does not exist in AdminStore
    } else if (newLoggedInUserID && !loggedInUserID) {
      loadTeamMemberships({ AppStore, AdminStore });
    }
  }, [_.get(AppStore, "state.loggedInUserID", undefined)]);

  React.useEffect(() => {
    const newPermit = _.get(
      AppStore,
      `state.loggedInUserTeamToolsPermit[${_.get(
        AppStore,
        "state.loggedInUserID",
        undefined
      )}]`
    );

    if (newPermit !== loggedInUserTeamToolsPermit) {
      updateLoggedInUserTeamToolsPermit(newPermit);
    }
  }, [
    _.get(
      AppStore,
      `state.loggedInUserTeamToolsPermit[${_.get(
        AppStore,
        "state.loggedInUserID",
        undefined
      )}]`
    ),
  ]);

  React.useEffect(() => {
    triggerMixPanelEvent("APP_URL_CHANGED", {
      url: location?.pathname || null,
    });

    if (GeneralStore.dispatch) {
      const { href, pathname } = document.location;

      GeneralStore.dispatch({
        payload: {
          historyItem: {
            url: href,
            uri: pathname,
          },
        },
        type: "GENERAL_STORE_UPDATE_HISTORY",
      });
    }
  }, [props.path, location?.pathname]);

  React.useEffect(() => {
    if (!_.get(AppStore, "state.sessionData", undefined)) {
      navigate(
        `/login${location?.pathname ? `?redirect=${location?.pathname}` : ""}`
      );
    } else if (
      loggedInUserTeamToolsPermit &&
      !loggedInUserTeamToolsPermit.hasBasicViewDisc &&
      !loggedInUserTeamToolsPermit.hasGrantPermitChange
    ) {
      navigate(`/waiting-room`);
    }
  }, [
    _.get(AppStore, "state.sessionData", undefined),
    !!loggedInUserTeamToolsPermit?.hasBasicViewDisc,
    !!loggedInUserTeamToolsPermit?.hasGrantPermitChange,
  ]);

  if (
    !_.get(AppStore, "state.sessionData", undefined) ||
    (loggedInUserTeamToolsPermit &&
      !loggedInUserTeamToolsPermit.hasBasicViewDisc &&
      !loggedInUserTeamToolsPermit.hasGrantPermitChange)
  ) {
    return <div>Redirecting...</div>;
  }

  return <>{props.children}</>;
};

const loadTeamMemberships = async ({
  AppStore,
  AdminStore,
}: {
  AppStore: AppStoreProps;
  AdminStore: AdminStoreProps;
}) => {
  if (!AppStore || !AdminStore) {
    debugger;
    return;
  }

  const adminStoreTeams = _.get(AdminStore, "state.flattenedList", []);
  const adminStoreMembers = _.get(AdminStore, "state.people", []);

  if (adminStoreTeams.length && adminStoreMembers.length) {
    // * People and Teams already exist in DB
    return;
  }

  try {
    const structure = await loadTeamStructure({
      AppStore,
      AdminStore,
      includeProjects: true,
      loadConfigs: false,
    });

    if (!structure) {
      return;
    }

    const teams = _.uniqBy(
      [...structure.domains, ...structure.teams, ...structure.projects],
      "teamID"
    );

    if (teams.length) {
      loadTeamMembers({
        domainID: _.get(AppStore, "state.curDomainID", 0),
        flattenedList: teams,
        onLoad: (peopleAndMemberships) => {
          if (AdminStore.dispatch) {
            AdminStore.dispatch({
              payload: {
                people: peopleAndMemberships.people,
                memberships: peopleAndMemberships.memberships,
              },
              type: "ADMIN_STORE_PEOPLE_AND_MEMBERSHIPS_LOADED",
            });
          }
        },
      });
    }
  } catch (err) {
    console.warn("Error Occurred loading TeamMemberships in SesisonHOC");
  }
};
