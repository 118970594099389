import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M528 464H112c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm64-336c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-5.2 3.1-10.8 4.6-16.4 4.6-11.1 0-21.9-5.8-27.8-16.1L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-5.9 10.4-16.7 16.1-27.8 16.1-5.6 0-11.3-1.5-16.4-4.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48zM478.7 368H161.3l-36-96.1 14 8.4c12.4 7.5 26.7 11.4 41.1 11.4 28.7 0 55.3-15.4 69.5-40.3L320 128.7l70.1 122.7c14.2 24.9 40.8 40.3 69.5 40.3 14.5 0 28.7-3.9 41.1-11.4l14-8.4-36 96.1z"
      />
    </svg>
  );
};
