/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import {
  CurrentlyOnlineBadge,
  DISCValueRender,
  Checkbox,
  Icon,
  TextHighlighter,
  UserThumb,
} from "../../../components/common";
import { UserOnlineInfo } from "../../../data/stores/AppStore";
import { AdminStoreProps } from "../../admin/data/stores/AdminStore";

import {
  getIconTypeFromDISCType,
  calcGradientForIcon,
  isValidIcon,
} from "../../../data/utils/helpers";
import { Tyto } from "../../../typings/tyto";

interface Props {
  adminStore: AdminStoreProps;
  canSelect?: boolean;
  hasR3Style?: boolean;
  isSelected: boolean;
  previewUser: (personID: number) => void;
  searchTerm: string;
  showCheckbox?: boolean;
  toggleUserID: (personID: number) => void;
  user: Tyto.DISCProfileMini;
  userOnlineInfo?: UserOnlineInfo;
}

export default ({
  adminStore,
  canSelect = true,
  hasR3Style,
  isSelected,
  previewUser,
  searchTerm,
  showCheckbox,
  toggleUserID,
  user,
  userOnlineInfo,
}: Props) => {
  const [iconType, updateIconType] = React.useState(
    // getIconTypeFromDISCType(user.styleName3)
    isValidIcon(user.graphic)
  );
  const [iconGradient, updateIconGrdient] = React.useState(
    calcGradientForIcon({
      d: user.d3,
      i: user.i3,
      s: user.s3,
      c: user.c3,
    })
  );

  const navigate = useNavigate();

  const isOnline = !!_.get(userOnlineInfo, "connectionsCount", 0);

  return (
    <li className="directory-people-list-item">
      <div
        className={cx(
          "directory-people-list-item-button-cont",
          showCheckbox && "directory-people-list-item-button-show-button"
        )}
      >
        {canSelect && (
          <Checkbox
            checked={isSelected}
            disabled={!canSelect}
            onCheck={() => toggleUserID(user.personID)}
            size={20}
          />
        )}
      </div>

      <button
        className={cx(
          "directory-people-list-item-info-cont",
          showCheckbox &&
            "directory-people-list-item-info-cont-checkbox-showing",
          isSelected && "user-is-selected"
        )}
        // onClick={hasR3Style ? () => previewUser(user.personID) : undefined}
        onClick={() => previewUser(user.personID)}
        tabIndex={showCheckbox ? -1 : undefined}
      >
        <div className="directory-people-list-item-info-userthumb-cont">
          <UserThumb
            className="directory-people-list-item-info-userthumb"
            asset={user.profileImageAsset}
            discMiniProfile={user}
            discValues={{
              d: user.d3,
              i: user.i3,
              s: user.s3,
              c: user.c3,
            }}
            userName={user.personName || ""}
            size={43}
          />
        </div>

        <div className="directory-people-list-item-info-name-cont">
          <p className="directory-people-list-item-info-name title-bold">
            <TextHighlighter
              highlightClassName=""
              searchTerm={searchTerm}
              text={user.personName}
              type="hightlight"
            />

            {isOnline && (
              <div className="directory-people-list-item-info-name-onlinebadge-cont">
                <CurrentlyOnlineBadge
                  className="directory-people-list-item-info-name-onlinebadge"
                  terse={true}
                  verboseOnHover={true}
                />
              </div>
            )}
          </p>
          {/* {userMemberships && (
            <p>
              <ContextMenu
                containerClassName=""
                icon={{
                  icon: "address-book",
                  size: 12
                }}
                menuItems={userMemberships.map(memb => ({
                  onClick: () => navigate(`/team/${memb.teamID}`),
                  value: `${memb.teamID}`
                }))}
              />
            </p>
          )} */}
          <p className="directory-people-list-item-info-jobtitle">
            {user.jobTitle}
          </p>
        </div>

        <div className="directory-people-list-item-info-contact-cont">
          <p
            className="directory-people-list-item-info-contact-email"
            style={
              !_.get(user, "emails[0]")
                ? { opacity: 0, visibility: "hidden" }
                : {}
            }
          >
            <Icon
              className="directory-people-list-item-info-contact-icon"
              icon="envelope"
              size={12}
            />
            {_.get(user, "emails[0]")}
          </p>
          <p
            className="directory-people-list-item-info-contact-phone"
            style={!user.phone1 ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="directory-people-list-item-info-contact-icon"
              icon="phone"
              size={12}
            />
            {user.phone1 || ""}
          </p>
        </div>

        <div
          className={cx(
            "directory-people-list-item-info-style-cont",
            !hasR3Style &&
              "directory-people-list-item-info-style-cont-profile-unavailable"
          )}
        >
          {hasR3Style ? (
            <DISCValueRender
              className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
              // compareProfile={compareProfile}
              iconSize={49}
              isCompareValue={false}
              omitHiddenMsg={true}
              targetKey="graphic"
              targetKeys={["graphic", "styleName", "styleKey"]}
              discMiniProfile={user}
            >
              <DISCValueRender
                className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
                // compareProfile={compareProfile}
                iconSize={49}
                isCompareValue={false}
                omitHiddenMsg={true}
                targetKey="graphic"
                discMiniProfile={user}
              >
                <Icon
                  className="directory-people-list-item-info-style-icon"
                  hasDropShadow={true}
                  icon={iconType}
                  gradient={iconGradient}
                  size={49}
                />
              </DISCValueRender>
              <p className="directory-people-list-item-info-style-name">
                <span className="directory-people-list-item-info-style-name-text">
                  <DISCValueRender
                    className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
                    isCompareValue={false}
                    omitHiddenMsg={true}
                    targetKey="styleName"
                    discMiniProfile={user}
                  >
                    {user.styleName3}
                  </DISCValueRender>
                </span>
                <span
                  className="directory-people-list-item-info-style-name-letters"
                  style={
                    iconGradient && iconGradient.length >= 1
                      ? {
                          color: (iconGradient[1] || iconGradient[0]).color,
                          fontWeight: "bold",
                        }
                      : { fontWeight: "bold" }
                  }
                >
                  <DISCValueRender
                    className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
                    isCompareValue={false}
                    omitHiddenMsg={true}
                    targetKey="styleKey"
                    discMiniProfile={user}
                  >
                    ({user.styleKey3})
                  </DISCValueRender>
                </span>
              </p>
            </DISCValueRender>
          ) : (
            <p
              className="directory-people-list-item-info-style-name-noprofile-msg"
              style={{ margin: "0px" }}
            >
              No DISC Info found
            </p>
          )}
        </div>
      </button>
    </li>
  );
};
