/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext as AppStoreContext } from "../../../data/stores/AppStore";
import type { Tyto } from "../../../typings/tyto";

import ConfirmationPage from "./ConfirmationPage";
import AssessmentTakerLandingPage from "./LandingPage";
import RestartPage from "./RestartPage";
import Taker247 from "./Taker";

import type { AssessmentTakerSubInterface } from "./types";

import "./style.scss";

export interface Props {
  isMobile: boolean;
  discProfileMini?: Tyto.DISCProfileMini;
  loggedInUserID: number;
}

const AssessmentTaker = ({ discProfileMini, isMobile }: Props) => {
  const AppStore = React.useContext(AppStoreContext);

  const loggedInUserID = AppStore.state?.loggedInUserID ?? 0;

  const [curSubInterface, updateCurSubInterface] =
    React.useState<AssessmentTakerSubInterface>("welcome");

  return (
    <div className="interface-assessment-taker-inner-wrapper">
      <main className="interface-assessment-taker-inner-cont centered-content-wrapper">
        <SubInterfaceRouter
          curSubInterface={curSubInterface}
          discProfileMini={discProfileMini}
          isMobile={isMobile}
          updateCurSubInterface={updateCurSubInterface}
          loggedInUserID={loggedInUserID}
        />
      </main>
    </div>
  );
};

interface SubInterfaceRouterProps
  extends AssessmentTakerInterface.UpdateSubInterfaceProps,
    Pick<Props, "discProfileMini" | "isMobile" | "loggedInUserID"> {}

const SubInterfaceRouter = (props: SubInterfaceRouterProps) => {
  switch (props.curSubInterface) {
    case "taker":
      return (
        <Taker247
          curSubInterface={props.curSubInterface}
          isMobile={props.isMobile}
          updateCurSubInterface={props.updateCurSubInterface}
          loggedInUserID={props.loggedInUserID}
        />
      );
    case "completed":
      return (
        <ConfirmationPage
          isMobile={props.isMobile}
          updateCurSubInterface={props.updateCurSubInterface}
        />
      );
    case "restart":
      return (
        <RestartPage
          discProfileMini={props.discProfileMini}
          onContinue={() => {
            props.updateCurSubInterface?.("taker");
          }}
          loggedInUserID={props.loggedInUserID}
        />
      );
    case "welcome":
    default:
      return (
        <AssessmentTakerLandingPage
          discProfileMini={props.discProfileMini}
          onContinue={() => {
            props.updateCurSubInterface?.("taker");
          }}
          loggedInUserID={props.loggedInUserID}
        />
      );
  }
};

export default AssessmentTaker;
