import { ENDPOINT_LOGIN_RESET_PASSWORD_VALIDATE_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_LOGIN_RESET_PASSWORD_VALIDATE_URL;

const timeoutMinutes = 60 * 24 * 90;

export const LoginResetPasswordValidate = {
  get(
    params: Endpoints.Tyto.Login.ResetPassword.Validate.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      { ...(params || {}), timeoutMinutes },
      { ...(callOpts || {}), omitSessionKey: true }
    ) as Promise<{
      error?: any;
      pwSession: SessionData;
      links: any[];
      logonName: string;
      domain: any;
    }>;
  },
};
