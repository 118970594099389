/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import TytoCalls from "../../../../../data/tyto/";
import {
  Button,
  DISCValueRender,
  Icon,
  Input,
  Timestamp,
  ToggleSection,
} from "../../../../../components/common/";
import {
  SetPassword as SetPasswordModal,
  UserRecovery as UserRecoveryModal,
} from "../../../../modals/";
import { Tyto } from "../../../../../typings/tyto";
import { AppStoreProps } from "../../../../../data/stores/AppStore";

import PersonConfigGrid from "./PersonConfigGrid";
import PDFDownloadLink from "./PDFDownloadLink";

import "./AdminSection.scss";

interface Props {
  AppStore: AppStoreProps;
  discMini: Tyto.DISCProfileMini;
  loggedInUserHasManage?: boolean;
  onPermitUpdate?: (personID: number) => void;
  personalDiscMini: Tyto.DISCProfileMini;
  primaryTeam?: Tyto.Team;
  reloadTeam?: (teamID: number) => void;
  teams?: { [x: number]: Tyto.Team };
  teamsWithConfiguration?: { [x: number]: Tyto.TeamGet };
}

export default (props: Props) => {
  const [overrideTeamID, updateOverrideTeamID] = React.useState<
    number | undefined
  >(
    findOverrideTeam({
      teams: props.teams,
      team: props.primaryTeam,
    })
  );
  const [inviteTempSessionKey, updateInviteTempSessionKey] = React.useState<
    string | undefined
  >(undefined);
  const [inviteSessionKey, updateInviteSessionKey] = React.useState<
    string | undefined
  >(undefined);
  const [creatingLink, updateCreatingLink] = React.useState(false);
  const [creatingInviteLink, updateInvitingCreatingLink] = React.useState(
    false
  );
  const [copied, updateCopied] = React.useState(false);
  const [inviteError, updateInviteError] = React.useState("");
  const [showSetPasswordModal, updateShowSetPasswordModal] = React.useState(
    false
  );
  const [showRecoveryModal, updateShowRecoveryModal] = React.useState(false);
  const [userName, updateUserName] = React.useState(
    _.get(props, "discMini.personName", "").split(" ")[0]
  );

  const inputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    // * [1] - Decide if primary team or parent of primary team should be loaded for overrides
    const newOverrideTeamID = findOverrideTeam({
      teams: props.teams,
      team: props.primaryTeam,
    });

    if (props.discMini && newOverrideTeamID !== overrideTeamID) {
      // * [2] - Trigger "reloadTeam" with override team to load data
      updateOverrideTeamID(newOverrideTeamID);

      if (props.reloadTeam) {
        props.reloadTeam(newOverrideTeamID);
      }
    }

    updateUserName(_.get(props, "discMini.personName", "").split(" ")[0]);
    updateCreatingLink(false);
    updateInvitingCreatingLink(false);
    updateInviteSessionKey(undefined);
    updateInviteTempSessionKey(undefined);
  }, [props.discMini]);

  const overrideTeam = overrideTeamID
    ? _.get(props, `teamsWithConfiguration[${overrideTeamID}]`, undefined)
    : undefined;
  const hasTakenAssessment = !!_.get(props, "discMini.styleKey3", undefined);
  const loggedInUserHasChangeAbility = !!_.get(
    props,
    "personalDiscMini.teamToolsPermit.hasPermitChange",
    false
  );
  const loggedInUserHasGrantChange = !!_.get(
    props,
    "personalDiscMini.teamToolsPermit.hasGrantPermitChange",
    false
  );

  return (
    <ToggleSection
      closedAtStart={true}
      className="meta-sidepane-admin-controls-toggle-cont"
      title="Admin Controls"
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        return (
          <>
            <div className="meta-sidepane-admin-controls-item-cont">
              <p className="meta-sidepane-admin-controls-item-label">
                Last Login:
              </p>

              <p className="meta-sidepane-admin-controls-item-value">
                {props.discMini.lastActivity ? (
                  <Timestamp date={props.discMini.lastActivity} />
                ) : null}
              </p>
            </div>

            {/* <div className="meta-sidepane-admin-controls-item-cont">
              <p className="meta-sidepane-admin-controls-item-label">
                Date Unlocked:
              </p>

              <p
                className="meta-sidepane-admin-controls-item-value"
                style={{ opacity: 0.4 }}
              >
                {"TBD"}
              </p>
            </div> */}

            {loggedInUserHasChangeAbility && (
              <div className="meta-sidepane-admin-controls-item-cont">
                <p className="meta-sidepane-admin-controls-item-label">
                  Report Access:
                </p>

                {/* <Select
                className="meta-sidepane-admin-controls-item-input"
                containerClassName="meta-sidepane-admin-controls-item-input-cont"
                omitFilter={true}
                onChange={() => {
                  // TODO
                }}
                options={[]}
                value=""
              /> */}

                <PersonConfigGrid
                  discMini={props.discMini}
                  overrideTeam={overrideTeam}
                  onPermitUpdate={(personID) => {
                    if (props.onPermitUpdate) {
                      props.onPermitUpdate(personID);
                    }
                  }}
                  personalDiscMini={props.personalDiscMini}
                  showGrantChange={loggedInUserHasGrantChange}
                />
              </div>
            )}

            {loggedInUserHasChangeAbility &&
              !props.discMini.teamToolsPermit.mayTakeDisc && (
                <div>
                  <p className="meta-sidepane-admin-controls-invite-nopermissions-msg">
                    <span className="font-bold">WARNING:</span> This user cannot
                    take the assessment with their current permissions. Sending
                    an email will allow them to login but do nothing.
                  </p>
                </div>
              )}

            {inviteSessionKey ? (
              <div className="meta-sidepane-admin-controls-invite-link-cont">
                <Input
                  autofocus={true}
                  containerClassName="meta-sidepane-admin-controls-invite-link-input-cont"
                  className="meta-sidepane-admin-controls-invite-link-input"
                  inputRef={inputRef}
                  hideClear={true}
                  value={`https://app.teamtools.com/join/${inviteSessionKey}`}
                  onChange={(newVal) => {}}
                />

                <Icon
                  className={cx(
                    "meta-sidepane-admin-controls-invite-link-copybutton-icon",
                    copied && "link-copybuttton-icon-iscopied"
                  )}
                  buttonProps={{
                    className:
                      "meta-sidepane-admin-controls-invite-link-copybutton",
                  }}
                  isButton={true}
                  icon={copied ? "check" : "copy"}
                  onClick={() => {
                    if (inputRef && inputRef.current) {
                      inputRef.current.select();
                      document.execCommand("copy");
                      updateCopied(true);
                    }
                  }}
                  size={20}
                />
              </div>
            ) : hasTakenAssessment ? null : (
              <div className="meta-sidepane-admin-controls-invite-link-cont">
                <Button
                  className="meta-sidepane-admin-controls-action-button"
                  disabled={creatingInviteLink || !!inviteSessionKey}
                  onClick={() => {
                    updateInvitingCreatingLink(true);

                    getInviteLink({
                      memberID: props.discMini.personID,
                      onError: (msg) => {
                        updateInvitingCreatingLink(false);
                        updateInviteError(msg);
                      },
                      onSuccess: (inviteSessionKey) => {
                        updateInviteSessionKey(inviteSessionKey);
                        updateInvitingCreatingLink(false);
                      },
                    });
                  }}
                  shape="square"
                  style={{
                    marginLeft: "auto",
                  }}
                  type="hollow"
                  value={
                    inviteSessionKey
                      ? "Link Generated"
                      : creatingInviteLink
                      ? "Generating Link..."
                      : "Generate Invite Link"
                  }
                />
              </div>
            )}

            {!hasTakenAssessment &&
              !inviteTempSessionKey &&
              _.get(props, "discMini.teamToolsInviteEmail.status", "") ===
                "ocREADY" && (
                <div className="meta-sidepane-admin-controls-invite-link-cont">
                  <p
                    style={{
                      fontSize: "10px",
                      display: "block",
                      paddingTop: "20px",
                      marginBottom: "-10px",
                    }}
                  >
                    An email was sent{" "}
                    {formatDate(
                      _.get(props, "discMini.teamToolsInviteEmail.date", "")
                    )}
                  </p>
                </div>
              )}

            {!hasTakenAssessment && inviteTempSessionKey && (
              <div className="meta-sidepane-admin-controls-invite-link-cont invite-link-cont-success-msg">
                <p style={{ display: "block" }}>A link will be sent!</p>
                <p
                  style={{
                    fontSize: "10px",
                    display: "block",
                    paddingTop: "6px",
                  }}
                >
                  It may take up to 10 minutes to be recieived.
                </p>
                {/* <Input
                  autofocus={true}
                  containerClassName="meta-sidepane-admin-controls-invite-link-input-cont"
                  className="meta-sidepane-admin-controls-invite-link-input"
                  inputRef={inputRef}
                  hideClear={true}
                  value={`https://app.teamtools.com/join/${inviteTempSessionKey}`}
                  onChange={(newVal) => {}}
                />

                <Icon
                  className={cx(
                    "meta-sidepane-admin-controls-invite-link-copybutton-icon",
                    copied && "link-copybuttton-icon-iscopied"
                  )}
                  buttonProps={{
                    className:
                      "meta-sidepane-admin-controls-invite-link-copybutton",
                  }}
                  isButton={true}
                  icon={copied ? "check" : "copy"}
                  onClick={() => {
                    if (inputRef && inputRef.current) {
                      inputRef.current.select();
                      document.execCommand("copy");
                      updateCopied(true);
                    }
                  }}
                  size={20}
                /> */}
              </div>
            )}

            <div className="meta-sidepane-admin-controls-action-buttons">
              {!hasTakenAssessment && (
                <Button
                  className="meta-sidepane-admin-controls-action-button control-full-width-button"
                  disabled={creatingLink || !!inviteTempSessionKey}
                  onClick={() => {
                    updateCreatingLink(true);

                    getEmailInviteLink({
                      memberID: props.discMini.personID,
                      onError: (msg) => {
                        updateCreatingLink(false);
                        updateInviteError(msg);
                      },
                      onSuccess: (tempSessionKey) => {
                        updateInviteTempSessionKey(tempSessionKey);
                        updateCreatingLink(false);
                      },
                    });
                  }}
                  shape="square"
                  type="hollow"
                  value={
                    inviteTempSessionKey
                      ? "Email Requested"
                      : creatingLink
                      ? "Requesting Email..."
                      : "Email Invite Link"
                  }
                />
              )}

              {/* <SendPasswordRecovery
                email={_.get(props, "discMini.emails[0]", "")}
              /> */}

              <Button
                className={cx(
                  "meta-sidepane-admin-controls-action-button control-full-width-button controls-action-button-sendreset"
                )}
                onClick={() => updateShowRecoveryModal(true)}
                iconLeft="user-alt"
                iconProps={{
                  className: "controls-action-button-sendreset-icon",
                  size: 12,
                }}
                shape="square"
                type="hollow"
                value="Send Recovery Email"
              />

              <Button
                className="meta-sidepane-admin-controls-action-button control-full-width-button controls-action-button-set-password"
                onClick={() => updateShowSetPasswordModal(true)}
                iconLeft="clone"
                iconProps={{
                  className: "controls-action-button-set-password-icon",
                  size: 12,
                }}
                shape="square"
                type="hollow"
                value="Set New Password"
              />
            </div>

            <div className="meta-sidepane-admin-controls-action-link-cont">
              <PDFDownloadLink
                className="meta-sidepane-admin-controls-action-link"
                AppStore={props.AppStore}
                discMini={props.discMini}
                personDISCMini={props.personalDiscMini}
              />
            </div>

            <SetPasswordModal
              title={userName ? `Set ${userName}'s Password` : ""}
              isOpen={showSetPasswordModal}
              onDismiss={() => updateShowSetPasswordModal(false)}
              userID={props.discMini.personID}
            />

            <UserRecoveryModal
              discProfileMini={props.discMini}
              isOpen={showRecoveryModal}
              onDismiss={() => updateShowRecoveryModal(false)}
              personalDiscMini={props.personalDiscMini}
              title={"Account Recovery"}
              userID={props.discMini.personID}
            />
          </>
        );
      }}
    />
  );
};

const getInviteLink = async ({
  memberID,
  onError,
  onSuccess,
}: {
  memberID: number;
  onSuccess: (tempSession: string) => void;
  onError: (msg: string) => void;
}) => {
  if (!memberID) {
    return;
  }

  try {
    const resp = await TytoCalls.TeamToolsInvite.post({ memberID });

    onSuccess(resp.pwSessionKey || "FakeTempSessionKey");
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Failed to generate Link.")
    );
  }
};

const getEmailInviteLink = async ({
  memberID,
  onError,
  onSuccess,
}: {
  memberID: number;
  onSuccess: (tempSession: string) => void;
  onError: (msg: string) => void;
}) => {
  if (!memberID) {
    return;
  }

  try {
    const resp = await TytoCalls.TeamToolsInvite.Email.post({ memberID });

    onSuccess(resp.tempSessionKey || "FakeTempSessionKey");
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Failed to generate Link.")
    );
  }
};

const formatDate = (date?: string) => {
  if (!date) {
    return "";
  }

  const asDate = new Date(date);

  if (/invalid/i.test(`${asDate}`)) {
    return "";
  }

  const hours = asDate.getHours();
  const minutes = asDate.getMinutes();
  const formattedHours = hours > 12 ? hours - 12 : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${
    asDate.getMonth() + 1
  }/${asDate.getDate()}/${asDate.getFullYear()} at ${formattedHours}:${formattedMinutes} ${
    hours > 11 ? "PM" : "AM"
  }`;
};

const findOverrideTeam = ({
  team,
  teams,
}: {
  team?: Tyto.Team;
  teams?: { [x: number]: Tyto.Team };
}) => {
  if (!team) {
    return 0;
  }

  if (team.isTeamToolsConfig) {
    return team.teamID;
  }

  const pathAsArr = team.iPath
    .split(",")
    .filter((item) => !!item)
    .reverse();
  if (!Array.isArray(pathAsArr) || !pathAsArr.length) {
    return 0;
  }

  const targetTeamID = pathAsArr.find((teamID) => {
    const parsedTeamID = parseInt(teamID);

    if (!parsedTeamID) {
      return false;
    }
    return !!_.get(teams, `${parsedTeamID}.isTeamToolsConfig`, false);
  });

  if (!targetTeamID) {
    return 0;
  }

  return parseInt(targetTeamID);
};

interface SendPasswordRecoveryProps {
  email: string;
}

const SendPasswordRecovery = (props: SendPasswordRecoveryProps) => {
  const [requestingEmail, updateRequestingEmail] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [requested, updateRequested] = React.useState(false);

  return (
    <>
      {error && (
        <p className="meta-sidepane-admin-controls-action-button-error">
          {error}
        </p>
      )}

      <Button
        className={cx(
          "meta-sidepane-admin-controls-action-button control-full-width-button controls-action-button-sendreset"
        )}
        disabled={!props.email || requestingEmail}
        onClick={() => {
          updateError("");
          updateRequestingEmail(true);

          requestRecoveryEmail({
            email: props.email,
            onSuccess: () => {
              updateRequested(true);
              updateRequestingEmail(false);
            },
            onError: (errMsg: string) => {
              updateError(errMsg);
              updateRequestingEmail(false);
            },
          });
        }}
        iconLeft={getIcon({ error: !!error, requested })}
        iconProps={{
          className: cx(
            "controls-action-button-sendreset-icon",
            requested && "controls-action-button-sendreset-icon-sent",
            error && "controls-action-button-sendreset-icon-error"
          ),
          size: 12,
        }}
        shape="square"
        type="hollow"
        value={getButtongText({ email: props.email, requestingEmail })}
      />
    </>
  );
};

const getIcon = ({
  error,
  requested,
}: {
  error: boolean;
  requested: boolean;
}) => {
  if (error) {
    return "close";
  }

  if (requested) {
    return "check";
  }

  return "user-alt";
};

const getButtongText = ({
  email,
  requestingEmail,
}: {
  email: string;
  requestingEmail: boolean;
}) => {
  if (!email) {
    return "No Email Address Found";
  }

  return requestingEmail ? "Requesting Email..." : "Send Recovery Email";
};

const requestRecoveryEmail = async ({
  email,
  onError,
  onSuccess,
}: {
  email: string;
  onError: (errMsg: string) => void;
  onSuccess: () => void;
}) => {
  if (!email) {
    onError("No email supplied.");

    return;
  }

  try {
    const resp = await TytoCalls.Login.Recover.TeamTools.post(
      {
        emailAddress: email,
      },
      { omitSessionKey: true }
    );

    onSuccess();
  } catch (err) {
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Error Occurred, email not requested.");

    onError(`${errorMsg}`);
  }
};
