/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext } from "../../../../data/stores/GeneralStore";
import {
  PlaceholderText,
  UserThumbPlaceholder
} from "../../../../components/common/";

interface Props {}

export default (props: Props) => {
  let GeneralStore = React.useContext(StoreContext);

  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  return (
    <section className="directory-profile-profile-header">
      <div className="directory-profile-profile-header-left-cont">
        <UserThumbPlaceholder
          className="directory-profile-profile-header-userthumb"
          size={isMobile ? 102 : 135}
        />

        <div className="directory-profile-profile-header-left-info-cont">
          <h1 className="directory-profile-profile-header-username">
            <PlaceholderText value="Person Namelyson" />
          </h1>
          <p className="directory-profile-profile-header-jobtitle">
            <PlaceholderText generateText={true} value="" />
          </p>

          <p className="directory-profile-profile-header-phone">
            <a
              className="directory-profile-profile-header-contact-icon-wrapper"
              href=""
            ></a>
          </p>

          <p className="directory-profile-profile-header-email">
            <a
              className="directory-profile-profile-header-contact-icon-wrapper"
              href=""
            ></a>
          </p>
        </div>
      </div>
    </section>
  );
};
