import { ENDPOINT_TEAM_TOOLS_INVITE_EMAIL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_TEAM_TOOLS_INVITE_EMAIL;

export const TeamToolsInviteEmail = {
  post(
    params: Endpoints.Tyto.TeamToolsInvite.Email.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params, callOpts) as Promise<{
      recordsAffected: number;
      emailQueueID: string;
      emailKey: string;
      tempSessionKey: string;
      session: SessionData;
    }>;
  }
};
