/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { StoreAction } from "../../../../typings";

import { Tyto } from "../../../../typings/tyto";

import {
  parseMemberships,
  parseTeamStructure,
  keyMemberships,
} from "../utils/";

export type AdminStoreDispatch = React.Dispatch<
  StoreAction<AdminStoreActionType>
>;

export type ParsedTeamMemberships = {
  [x: number]: Tyto.AdvancedPersonTeamMembership[];
};

export interface AdminStoreProps {
  state?: StoreState;
  dispatch?: AdminStoreDispatch;
}

export interface StoreState {
  curDraggedPersonID?: number;
  flattenedList: Tyto.Team[];
  loaded: boolean;
  indexedPeople?: { [x: number]: Tyto.AdvancedPerson };
  membershipsByPersonID?: { [x: number]: Tyto.AdvancedPersonTeamMembership[] };
  parsedMemberships?: ParsedTeamMemberships;
  people?: Tyto.AdvancedPerson[];
  peopleMemberships?: Tyto.AdvancedPersonTeamMembership[];
  previewPersonID?: number;
  selectedPeopleIDs: {
    [x: number]: boolean;
  };
  selectedTeamsIDs: {
    [x: number]: boolean;
  };
  selectedTeamID?: number;
  structured: {
    [x: string]: Tyto.Team[];
  };
  teamSearchTerm?: string;
  teamSearchMatchPathIDs?: Set<number>;
  teamSearchMatchIDs?: Set<number>;
  domainsByParentDomainID: {
    [x: string]: Tyto.Team[];
  };
  teamsByTeamID?: {
    [x: string]: Tyto.Team;
  };
  teamsWithConfiguration?: {
    [x: number]: Tyto.TeamGet;
  };
}

type AdminStoreActionType =
  | "ADMIN_STORE_CLEAR_STORE"
  | "ADMIN_STORE_DOMAINS_RELOADED"
  | "ADMIN_STORE_PEOPLE_AND_MEMBERSHIPS_LOADED"
  | "ADMIN_STORE_PREVIEW_PERSON_ID"
  | "ADMIN_STORE_STRUCTURE_LOADED"
  | "ADMIN_STORE_TOGGLE_PERSON_ID"
  | "ADMIN_STORE_TOGGLE_TEAM_ID"
  | "ADMIN_STORE_TEAM_CREATED"
  | "ADMIN_STORE_SELECTED_TEAM_ID"
  | "ADMIN_STORE_TEAMS_WITH_CONFIGURATION_LOADED"
  | "ADMIN_STORE_TEAM_SEARCHTERM_UPDATE"
  | "ADMIN_STORE_UPDATE_PERSON_MEMBERSHIP"
  | "ADMIN_STORE_UPDATE_DRAGGING_PERSONID";

let StoreContext: React.Context<AdminStoreProps> = React.createContext({});

let initialState: StoreState = {
  domainsByParentDomainID: {},
  flattenedList: [],
  loaded: false,
  previewPersonID: 0,
  selectedPeopleIDs: {},
  selectedTeamsIDs: {},
  selectedTeamID: 0,
  structured: {},
  teamSearchMatchPathIDs: new Set<number>(),
  teamSearchMatchIDs: new Set<number>(),
  teamSearchTerm: "",
};

let reducer = (
  state: StoreState,
  action: StoreAction<AdminStoreActionType>
) => {
  console.log(action);

  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case "ADMIN_STORE_CLEAR_STORE":
      return {
        domainsByParentDomainID: {},
        flattenedList: [],
        loaded: false,
        previewPersonID: 0,
        selectedPeopleIDs: {},
        selectedTeamsIDs: {},
        selectedTeamID: 0,
        structured: {},
      };
    case "ADMIN_STORE_DOMAINS_RELOADED":
      if (action.payload.domains && action.payload.domains.length) {
        const { domainsByParentDomainID, flattenedList, structured } = parseTeamStructure({
          domains: action.payload.domains,
          projects: [],
          // * teams is actually all teams, domains, and projects, doesn't matter that they are all in 1 Array
          teams: [...(state.flattenedList || [])],
        });

        const teamsByTeamID = _.keyBy(
          [
            ...(flattenedList || []),
          ],
          "teamID"
        );

        const newDomainsByuParentDomainID = {
          ...state.domainsByParentDomainID,
          ...domainsByParentDomainID,
        };

        return {
          ...state,
          domainsByParentDomainID: newDomainsByuParentDomainID,
          loaded: true,
          flattenedList,
          structured: {
            ...(state.structured || {}),
            ...(structured || {})
          },
          teamsByTeamID,
        };
      }
    

      return state;
    case "ADMIN_STORE_PEOPLE_AND_MEMBERSHIPS_LOADED":
      if (action.payload.people) {
        const parsedMemberships = parseMemberships(action.payload.memberships);
        const indexedPeople = _.keyBy(action.payload.people, "userID");
        const membershipsByPersonID = keyMemberships(
          action.payload.memberships
        );

        if (action.callback) {
          action.callback({});
        }

        return {
          ...state,
          indexedPeople,
          membershipsByPersonID,
          parsedMemberships: {
            ...(state.parsedMemberships || {}),
            ...parsedMemberships,
          },
          people: [...(state.people || []), ...action.payload.people],
          peopleMemberships: {
            ...(state.peopleMemberships || {}),
            ...action.payload.memberships,
          },
        };
      }

      return state;
    case "ADMIN_STORE_PREVIEW_PERSON_ID":
      if (action.payload) {
        return {
          ...state,
          previewPersonID: action.payload.previewPersonID || 0,
        };
      }

      return state;
    case "ADMIN_STORE_SELECTED_TEAM_ID":
      if (action.payload) {
        return {
          ...state,
          selectedTeamID: action.payload.selectedTeamID || 0,
        };
      }

      return state;
    case "ADMIN_STORE_STRUCTURE_LOADED":
      if (action.payload.teams || action.payload.domains) {
        const {
          flattenedList,
          structured,
          domainsByParentDomainID,
        } = parseTeamStructure({
          domains: action.payload.domains,
          projects: action.payload.projects,
          teams: action.payload.teams,
        });
        const teamsByTeamID = _.keyBy(
          [
            ...(action.payload.teams || []),
            ...(action.payload.domains || []),
            ...(action.payload.projects || []),
          ],
          "teamID"
        );

        const newDomainsByuParentDomainID = {
          ...state.domainsByParentDomainID,
          ...domainsByParentDomainID,
        };

        return {
          ...state,
          domainsByParentDomainID: newDomainsByuParentDomainID,
          loaded: true,
          flattenedList,
          structured,
          teamsByTeamID,
        };
      }

      return state;
    case "ADMIN_STORE_TEAM_SEARCHTERM_UPDATE":
      if (action.payload) {
        if (
          !action.payload.searchTerm ||
          typeof action.payload.searchTerm !== "string"
        ) {
          return {
            ...state,
            teamSearchTerm: "",
            teamSearchMatchPathIDs: new Set<number>(),
            teamSearchMatchIDs: new Set<number>(),
          };
        }

        // * [1] - Create RegExp to test team names against
        const searchTermRegExp = new RegExp(
          _.escapeRegExp(action.payload.searchTerm),
          "i"
        );

        // * [2] - Filter teams for those who at least partially match searchTerm
        const matchingTeams = state.flattenedList.filter((team) =>
          searchTermRegExp.test(team.name)
        );
        const matchTeamIDs = matchingTeams.map((team) => team.teamID);

        // * [3] - Reduce matching teams to list of teamIDs in path
        const matchingParentIDs = matchingTeams.reduce(
          (accum: number[], team) => {
            const parentIDs = team.iPath
              .split(",")
              .filter((item) => !!item)
              .map((teamID) => parseInt(teamID) || 0);

            return [...accum, ...parentIDs];
          },
          []
        );

        // * [4] - Create new Sets with results
        const newTeamSearchMatchPathIDs = new Set(_.uniq(matchingParentIDs));
        const teamSearchMatchIDs = new Set(_.uniq(matchTeamIDs));

        // * [5] - Update State
        return {
          ...state,
          teamSearchTerm: action.payload.searchTerm,
          teamSearchMatchPathIDs: newTeamSearchMatchPathIDs,
          teamSearchMatchIDs: teamSearchMatchIDs,
        };
      }

      return state;
    case "ADMIN_STORE_TEAM_CREATED":
      if (action.payload.team) {
        debugger;
        const isDomain = (action.payload.team as Tyto.Team).teamType === "ocDOMAIN";

        if (action.payload.team) {
          action.payload.team.name = action.payload.team.teamName;
        }

        const domains = isDomain ? [action.payload.team] : []; 
        const teams = isDomain ? [...(state.flattenedList || [])] : [...(state.flattenedList || []), action.payload.team]; 

        const { domainsByParentDomainID, flattenedList, structured } = parseTeamStructure({
          domains,
          projects: [],
          // * teams is actually all teams, domains, and projects, doesn't matter that they are all in 1 Array
          teams,
        });

        const parsedDomainsAsArr = Object.entries(domainsByParentDomainID);

        const newDomainsByParentDomainID = (!parsedDomainsAsArr || !parsedDomainsAsArr.length) ? state.domainsByParentDomainID : parsedDomainsAsArr.reduce((accum, [domainID, domainTeams]) => {
          if (domainID) {
            if (accum[`${domainID}`]) {
              const newTeamsList = [...(accum[`${domainID}`] || []), ...(domainTeams || [])];

              accum[`${domainID}`] = _.uniqBy(newTeamsList, "teamID");
            } else {
              accum[`${domainID}`] = domainTeams
            }
          }
  
          return accum;
        }, {...(state.domainsByParentDomainID || {})});

        // const newDomainsByParentDomainID = {
        //   ...(state.domainsByParentDomainID || {}),
        //   ...domainsByParentDomainID
        // };

        return {
          ...state,
          domainsByParentDomainID: newDomainsByParentDomainID,
          flattenedList,
          structured,
        };
      }

      return state;
    case "ADMIN_STORE_TOGGLE_PERSON_ID":
      if (action.payload.forceClear) {
        return { ...state, selectedPeopleIDs: {} };
      }

      if (action.payload.personID) {
        return {
          ...state,
          selectedPeopleIDs: {
            ...state.selectedPeopleIDs,
            [action.payload.personID]: !state.selectedPeopleIDs[
              action.payload.personID
            ],
          },
        };
      }

      return state;
    case "ADMIN_STORE_TOGGLE_TEAM_ID":
      if (action.payload.forceClear) {
        return { ...state, selectedTeamsIDs: {} };
      }

      if (action.payload.teamID) {
        return {
          ...state,
          selectedTeamsIDs: {
            ...state.selectedTeamsIDs,
            [action.payload.teamID]: !state.selectedTeamsIDs[
              action.payload.teamID
            ],
          },
        };
      }

      return state;
    case "ADMIN_STORE_TEAMS_WITH_CONFIGURATION_LOADED":
      if (action.payload && Array.isArray(action.payload.teams)) {
        const newTeamWithConfiguration = _.keyBy(
          action.payload.teams,
          "teamID"
        );

        return {
          ...state,
          teamsWithConfiguration: {
            ...(state.teamsWithConfiguration || {}),
            ...(newTeamWithConfiguration || {}),
          },
        };
      }

      return state;
    case "ADMIN_STORE_UPDATE_PERSON_MEMBERSHIP":
      if (action.payload.membership) {
        const { membership } = action.payload;
        const updateTeamMembership = (_.get(
          state,
          `parsedMemberships.${membership.teamID}`,
          []
        ) as Tyto.AdvancedPersonTeamMembership[]).map((memb) =>
          memb.memberID === membership.memberID ? membership : memb
        );

        const updatedParsedMemberships: ParsedTeamMemberships = {
          ...(state.parsedMemberships || {}),
          [membership.teamID]: updateTeamMembership,
        };

        const updatedPeopleMembership: Tyto.AdvancedPersonTeamMembership[] = (
          state.peopleMemberships || []
        ).map((memb) =>
          memb.memberID === membership.memberID ? membership : memb
        );

        return {
          ...state,
          parsedMemberships: updatedParsedMemberships,
          peopleMemberships: updatedPeopleMembership,
        };
      }

      return state;
    case "ADMIN_STORE_UPDATE_DRAGGING_PERSONID":
      return {
        ...state,
        curDraggedPersonID: action.payload.personID || undefined,
      };
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
