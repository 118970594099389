/*
 * Component Description
 */
import * as React from "react";

import { Icon } from "../../../../common/";

import "./LockedSection.scss";

interface Props {}

export default (props: Props) => {
  return (
    <div className="meta-sidepane-person-nodisc-msg-cont">
      <Icon
        className="meta-sidepane-person-nodisc-msg-icon"
        icon="lock-alt"
        size={18}
      />

      <h3 className="meta-sidepane-person-nodisc-msg">
        This user has not completed their assessment yet.
      </h3>
    </div>
  );
};
