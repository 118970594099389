/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import DomainImageUpload from "./DomainImageUpload";

import "./style.scss";

interface Props {
  className?: string;
  domainID: number;
  overlayClassName?: string;
  isOpen?: boolean;
  modalStyle?: React.CSSProperties;
  onAccountCreation: () => void;
  onDismiss?: (fileObjectURL?: string) => void;
  overlayStyle?: React.CSSProperties;
  uploadURL?: string;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className={cx("domainlogo-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      <DomainImageUpload
        domainID={props.domainID}
        uploadURL={props.uploadURL}
      />
    </AgnosticModal>
  );
};
