/*
 * Component Description
 */
import * as React from "react";

import "./style.scss";

interface Props {
  generateText?: boolean;
  value: string;
  title?: string;
}

export default ({ generateText, title, value }: Props) => {
  const [animationTimerMS] = React.useState(() =>
    Math.floor(Math.random() * 3000 + 600)
  );
  const [generatedText] = React.useState(
    generateText ? "Filler".repeat(Math.round(3 + Math.random() * 4)) : ""
  );

  return (
    <span
      className="cc-placeholder-text"
      style={{ animationDuration: `${animationTimerMS}ms` }}
      title={title}
    >
      {generatedText || value}
    </span>
  );
};
