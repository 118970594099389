/*
 * Component Description
 */
import * as React from "react";

import { parseDateForText, parseDateForHover } from "./utils/";

import "./style.scss";

interface Props {
  date: string | Date;
  text1900?: string;
}

export default (props: Props) => {
  const [dateText, updateDateText] = React.useState(
    parseDateForText(props.date)
  );
  const [dateHoverText, updateDateHoverText] = React.useState(
    parseDateForHover(props.date)
  );

  React.useEffect(() => {
    updateDateText(parseDateForText(props.date));
    updateDateHoverText(parseDateForHover(props.date));
  }, [props.date]);

  return (
    <span className="cc-timestamp" title={dateHoverText}>
      {dateText || props.text1900 || "N/A"}
    </span>
  );
};
