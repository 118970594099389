import * as React from "react";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { StoreContext as AppStoreContext } from "../../../data/stores/AppStore";
import { Button, Icon } from "../../../components/common/";

import type { AssessmentTakerSubInterface } from "./types";

import "./ConfirmationPage.scss";

interface Props {
  isMobile?: boolean;
  updateCurSubInterface: (newSubInterface: AssessmentTakerSubInterface) => void;
}

const ConfirmationPage = (props: Props) => {
  const AppStore = React.useContext(AppStoreContext);

  const [redirectPath, updateRedirectPath] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectPath) return;

    navigate(redirectPath);
  }, [redirectPath]);

  if (redirectPath) {
    return <div>Redirecting...</div>;
  }

  const userID = AppStore.state?.loggedInUserID ?? 0;

  return (
    <div className="assessment-247-confirmation-wrapper">
      <div className="assessment-247-confirmation-cont">
        <section className="assessment-247-confirmation-icon-cont">
          <Icon
            className="assessment-247-confirmation-icon"
            icon="check"
            size={48}
          />
        </section>

        <section className="assessment-247-confirmation-text">
          <h1 className="title-font">Thank you for submitting your answers!</h1>
          <p>We are calculating your results</p>
        </section>

        <section className="assessment-247-confirmation-btns">
          <Button
            className="assessment-247-confirmation-btn"
            value="Go to Home"
            onClick={() => updateRedirectPath("/")}
          />

          {userID && (
            <Button
              className="assessment-247-confirmation-btn"
              value="Go to Profile"
              onClick={() => updateRedirectPath(`/profile/${userID}`)}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default ConfirmationPage;
