/*
 * Component Description
 */
import * as React from "react";

import { Button, Message } from "../../components/common";
import PersonHeader from "./subcomponents/PersonHeader";
import TabsInterface from "./tabs-interface";
import { Tyto } from "../../typings/tyto";

import "./PersonProfile.scss";

interface Props {
  backButton?: {
    value: string;
    onClick: () => void;
  };
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile?: Tyto.DISCProfileMini;
  userDISCMini?: Tyto.DISCProfileMini;
}

export default (props: Props) => {
  if (!props.discMiniProfile) {
    return (
      <section className="directory-person-profile-main-cont side-pane-open">
        <Message
          className="directory-person-profile-error-msg"
          value="Error: Profile Not Found :("
        />
      </section>
    );
  }

  return (
    <div className="directory-person-profile-wrapper side-pane-open">
      <section className="directory-person-profile-main-cont">
        {props.backButton && (
          <div className="directory-person-profile-back-button-cont">
            <Button
              iconLeft="angle-left"
              iconProps={{
                className: "directory-person-profile-back-button-icon",
                size: 30
              }}
              className="directory-person-profile-back-button"
              onClick={props.backButton.onClick}
              value={props.backButton.value}
            />
          </div>
        )}

        <PersonHeader discMiniProfile={props.discMiniProfile} />

        <TabsInterface
          discMiniProfile={props.discMiniProfile}
          discCompareProfile={props.discCompareProfile}
          tabType="profile-full"
          userDISCMini={props.userDISCMini}
        />
      </section>
    </div>
  );
};
