/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import AdminSection from "./subcomponents/AdminSection";
import Tips from "../team/subcomponents/TeamTips";
import LockedSection from "./subcomponents/LockedSection";
import PersonHeadPhotos from "./subcomponents/PersonHeadPhotos";

import { Tyto } from "../../../../typings/tyto";
import { AppStoreProps } from "../../../../data/stores/AppStore";

interface Interactions {
  insight?: Tyto.DISCCompareProfile.Interaction;
  presented?: Tyto.DISCCompareProfile.Interaction;
  tips?: Tyto.DISCCompareProfile.Interaction;
}

interface Props {
  AppStore: AppStoreProps;
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
  hasDISCProfile: boolean;
  isMinimized?: boolean;
  isOwnProfile: boolean;
  loggedInUserHasManage?: boolean;
  onPermitUpdate?: (personID: number) => void;
  personalDiscMini: Tyto.DISCProfileMini;
  personName: string;
}

export default (props: Props) => {
  const [interactions, updateInteractions] = React.useState<Interactions>(
    getInteractions(props.discCompareProfile)
  );

  React.useEffect(() => {
    updateInteractions(getInteractions(props.discCompareProfile));
  }, [props.discCompareProfile]);

  return (
    <>
      <h1 className="meta-sidepane-person-patial-title">
        You {"&"} {props.personName || "Them"}
      </h1>

      <PersonHeadPhotos
        discMini={props.discMiniProfile}
        personalDiscMini={props.personalDiscMini}
      />

      {props.loggedInUserHasManage && (
        <AdminSection
          AppStore={props.AppStore}
          discMini={props.discMiniProfile}
          loggedInUserHasManage={props.loggedInUserHasManage}
          personalDiscMini={props.personalDiscMini}
          onPermitUpdate={props.onPermitUpdate}
        />
      )}

      {props.hasDISCProfile ? (
        <>
          <Tips
            title={`How you see ${props.personName || "Them"}`}
            tips={
              interactions.insight ? [interactions.insight.interactText] : []
            }
          />

          <Tips
            title={`How ${props.personName || "they"} sees you`}
            tips={
              interactions.presented
                ? [interactions.presented.interactText]
                : []
            }
          />

          <Tips
            title="Tips for working together"
            tips={interactions.tips ? [interactions.tips.interactText] : []}
          />
        </>
      ) : (
        <LockedSection />
      )}
    </>
  );
};

const getInteractions = (
  discCompareProfile?: Tyto.DISCCompareProfile
): Interactions => {
  if (!discCompareProfile || !discCompareProfile.interactions) {
    return {};
  }

  const interactions = _.keyBy(discCompareProfile.interactions, (interaction) =>
    interaction.interactContext.toLowerCase()
  );

  return interactions as {
    insight: Tyto.DISCCompareProfile.Interaction;
    presented: Tyto.DISCCompareProfile.Interaction;
    tips: Tyto.DISCCompareProfile.Interaction;
  };
};
