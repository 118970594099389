/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto";
import {
  Button,
  CurrentlyOnlineBadge,
  TextButton,
  Timestamp,
  ToggleSection,
  UserThumb,
  Checkbox,
  Icon,
} from "../../../common/";
import {
  getStatus,
  grantPermissionToUsers,
  sendEmailInviteLinksToUsers,
  sendResultsEmailToUsers,
} from "../utils/";
import { UserOnlineInfo } from "../../../../data/stores/AppStore";

import "./TeamMembership.scss";

interface Props {
  onlineUsersTable: {
    [x: number]: UserOnlineInfo;
  };
  onPersonSelect: (personID: number) => void;
  personalDISCMini?: Tyto.DISCProfileMini;
  reloadTeamMembers: () => void;
  teamMembers?: Tyto.DISCProfileMini[];
  teamID?: number;
  websocketServerConnected: boolean;
}

export default (props: Props) => {
  const [teamLeaders, updateTeamLeaders] = React.useState(() =>
    props.teamMembers
      ? props.teamMembers.filter((teamMember) => teamMember.isTeamLeader)
      : undefined
  );
  const [curTeamID, updateCurTeamID] = React.useState(props.teamID);
  const [teamMembers, updateTeamMembers] = React.useState(() =>
    props.teamMembers
      ? props.teamMembers.filter((teamMember) => !teamMember.isTeamLeader)
      : undefined
  );
  const [
    savingMemberPermChanges,
    updateSavingMemberPermChanges,
  ] = React.useState(false);
  const [grantTakePermission, updateGrantTakePermission] = React.useState(
    false
  );
  const [grantViewPermission, updateGrantViewPermission] = React.useState(
    false
  );
  const [grantingError, updateGrantingError] = React.useState("");
  const [selectedTeamMembers, updateSelectedTeamMembers] = React.useState<{
    [x: number]: boolean;
  }>({});
  const [emailsSent, updateEmailsSent] = React.useState<
    {
      recordsAffected: number;
      emailQueueID: string;
      emailKey: string;
      tempSessionKey: string;
    }[]
  >([]);
  const [resultsEmailsSent, updateResultsEmailsSent] = React.useState<
    {
      recordsAffected: number;
      emailQueueID: string;
      emailKey: string;
      tempSessionKey: string;
    }[]
  >([]);
  const [sendingInvites, updateSendingInvites] = React.useState(false);

  // * [E-1] - Handle Team Members changing
  React.useEffect(() => {
    if (Array.isArray(props.teamMembers)) {
      updateTeamLeaders(
        props.teamMembers.filter((teamMember) => teamMember.isTeamLeader)
      );
      updateTeamMembers(
        props.teamMembers.filter((teamMember) => !teamMember.isTeamLeader)
      );
    } else {
      updateTeamLeaders(undefined);
      updateTeamMembers(undefined);
      updateSelectedTeamMembers({});
    }
  }, [props.teamMembers]);

  // * [E-2] - Handle TeamID Changing
  React.useEffect(() => {
    if (props.teamID && props.teamID !== curTeamID) {
      updateSelectedTeamMembers({});
    }
  }, [props.teamID]);
  // * =======================================
  // * END OF EFFECTS ========================
  // * =======================================

  const loggedInUserHasChangePermissions = !!_.get(
    props.personalDISCMini,
    "teamToolsPermit.hasPermitChange",
    false
  );
  const selectedMembersCount = Object.values(selectedTeamMembers).filter(
    (val) => !!val
  ).length;

  return (
    <ToggleSection
      title="Team Membership:"
      className="meta-super-sidepane-toggle-section"
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        if (!Array.isArray(teamLeaders) && !Array.isArray(teamMembers)) {
          return (
            <div>
              <h3 className="font-bold font-italic" style={{ opacity: 0.4 }}>
                Loading Team Members...
              </h3>
            </div>
          );
        }

        return (
          <>
            <ul className="meta-super-sidepane-teammembership-list">
              <SectionTitle
                isChecked={false}
                onCheck={(nextVal) => {
                  if (nextVal) {
                    if (teamLeaders && teamLeaders.length) {
                      const ids = teamLeaders.reduce(
                        (accum: { [x: number]: boolean }, person) => {
                          accum[person.personID] = true;

                          return accum;
                        },
                        {}
                      );

                      const newSelectedMembers = {
                        ...(selectedTeamMembers || {}),
                        ...ids,
                      };

                      updateSelectedTeamMembers(newSelectedMembers);
                    }
                  } else {
                    updateSelectedTeamMembers({});
                  }
                }}
                isTeamLeader={true}
              />

              {teamLeaders && !!teamLeaders.length ? (
                teamLeaders.map((teamLeader) => (
                  <TeamMember
                    key={teamLeader.personID}
                    isChecked={!!selectedTeamMembers[teamLeader.personID]}
                    onlineConnectionsInfo={
                      props.onlineUsersTable &&
                      props.onlineUsersTable[teamLeader.personID]
                    }
                    onPersonSelect={props.onPersonSelect}
                    onPersonCheck={() => {
                      updateSelectedTeamMembers({
                        ...selectedTeamMembers,
                        [teamLeader.personID]: !selectedTeamMembers[
                          teamLeader.personID
                        ],
                      });
                    }}
                    teamMember={teamLeader}
                    websocketServerConnected={props.websocketServerConnected}
                  />
                ))
              ) : (
                <li>No Team Leaders Assigned.</li>
              )}
            </ul>

            <ul className="meta-super-sidepane-teammembership-list meta-super-sidepane-teammembership-list-members">
              <SectionTitle
                isChecked={false}
                onCheck={(nextVal) => {
                  if (nextVal) {
                    if (teamMembers && teamMembers.length) {
                      const ids = teamMembers.reduce(
                        (accum: { [x: number]: boolean }, person) => {
                          accum[person.personID] = true;

                          return accum;
                        },
                        {}
                      );

                      const newSelectedMembers = {
                        ...(selectedTeamMembers || {}),
                        ...ids,
                      };

                      updateSelectedTeamMembers(newSelectedMembers);
                    }
                  } else {
                    updateSelectedTeamMembers({});
                  }
                }}
                isTeamLeader={false}
              />

              {teamMembers && !!teamMembers.length ? (
                teamMembers.map((teamMember) => (
                  <TeamMember
                    key={teamMember.personID}
                    isChecked={!!selectedTeamMembers[teamMember.personID]}
                    onlineConnectionsInfo={
                      props.onlineUsersTable &&
                      props.onlineUsersTable[teamMember.personID]
                    }
                    onPersonSelect={props.onPersonSelect}
                    onPersonCheck={() => {
                      updateSelectedTeamMembers({
                        ...selectedTeamMembers,
                        [teamMember.personID]: !selectedTeamMembers[
                          teamMember.personID
                        ],
                      });
                    }}
                    teamMember={teamMember}
                    websocketServerConnected={props.websocketServerConnected}
                  />
                ))
              ) : (
                <li>No Team Members found.</li>
              )}
            </ul>

            <div className="meta-super-sidepane-teammembership-selcted-clear-button-cont">
              <TextButton
                className="meta-super-sidepane-teammembership-selcted-clear-button"
                disabled={!selectedMembersCount}
                onClick={() => {
                  updateSelectedTeamMembers({});
                }}
                value="Clear Selected Members"
              />
            </div>

            <div className="meta-super-sidepane-teammembership-permchanges-cont">
              {loggedInUserHasChangePermissions && (
                <>
                  <div className="meta-super-sidepane-teammembership-permchanges-checkbox-cont">
                    <Checkbox
                      className="meta-super-sidepane-teammembership-permchanges-checkbox"
                      checked={grantTakePermission}
                      onCheck={() =>
                        updateGrantTakePermission(!grantTakePermission)
                      }
                      size={20}
                    />

                    <p className="meta-super-sidepane-teammembership-permchanges-checkbox-label">
                      Grant permission to take assessment
                    </p>
                  </div>

                  <div className="meta-super-sidepane-teammembership-permchanges-checkbox-cont">
                    <Checkbox
                      className="meta-super-sidepane-teammembership-permchanges-checkbox"
                      checked={grantViewPermission}
                      onCheck={() =>
                        updateGrantViewPermission(!grantViewPermission)
                      }
                      size={20}
                    />

                    <p className="meta-super-sidepane-teammembership-permchanges-checkbox-label">
                      Grant permission to see assessment results
                    </p>
                  </div>
                </>
              )}

              {grantingError && <p style={{ color: "red" }}>{grantingError}</p>}

              <div className="meta-super-sidepane-teammembership-action-buttons-cont">
                {loggedInUserHasChangePermissions && (
                  <Button
                    className="meta-super-sidepane-teammembership-permchanges-button"
                    disabled={
                      !selectedMembersCount ||
                      sendingInvites ||
                      savingMemberPermChanges ||
                      (!grantTakePermission && !grantViewPermission)
                    }
                    onClick={() => {
                      updateSavingMemberPermChanges(true);
                      updateGrantingError("");

                      const selectedMembers = (props.teamMembers || []).filter(
                        (tm) => !!selectedTeamMembers[tm.personID]
                      );

                      grantPermissionToUsers({
                        teamMembers: selectedMembers || [],
                        keys: {
                          mayTakeDisc: grantTakePermission,
                          hasBasicViewDisc: grantViewPermission,
                        },
                        onSuccess: (resps) => {
                          updateSavingMemberPermChanges(false);
                          updateSelectedTeamMembers({});
                          if (props.reloadTeamMembers) {
                            props.reloadTeamMembers();
                          }
                        },
                        onError: (err) => {
                          updateGrantingError(err);
                          updateSavingMemberPermChanges(false);
                        },
                      });
                    }}
                    shape="square"
                    type="color"
                    value={
                      savingMemberPermChanges
                        ? "Updating Permissions..."
                        : `Update ${selectedMembersCount} User${
                            selectedMembersCount === 1 ? "" : "s"
                          } Permissions`
                    }
                  />
                )}

                <div>
                  {resultsEmailsSent && !!resultsEmailsSent.length && (
                    <Icon
                      className="meta-super-sidepane-teammembership-email-invites-icon"
                      icon="check"
                      size={16}
                      style={{ marginRight: "10px" }}
                    />
                  )}

                  <Button
                    className="meta-super-sidepane-teammembership-email-invites-button"
                    disabled={
                      !selectedMembersCount ||
                      sendingInvites ||
                      savingMemberPermChanges
                    }
                    onClick={() => {
                      updateSendingInvites(true);

                      const selectedMembers = (props.teamMembers || []).filter(
                        (tm) => !!selectedTeamMembers[tm.personID]
                      );

                      const [
                        userToBeEmailed,
                        usersWhoWontBeEmailed,
                      ] = selectedMembers.reduce(
                        (accum: Array<Tyto.DISCProfileMini[]>, tm) => {
                          if (
                            !!_.get(
                              tm,
                              "teamToolsPermit.hasBasicViewDisc",
                              false
                            ) &&
                            tm.discStatus === "ocENABLED"
                          ) {
                            accum[0].push(tm);
                          } else {
                            accum[1].push(tm);
                          }

                          return accum;
                        },
                        [[], []]
                      );

                      sendResultsEmailToUsers({
                        teamMembers: userToBeEmailed,
                        omittedUsers: usersWhoWontBeEmailed,
                        onError: () => {
                          updateSendingInvites(false);
                        },
                        onSuccess: (resp) => {
                          debugger;

                          updateResultsEmailsSent([
                            ...resultsEmailsSent,
                            ...resp,
                          ]);
                          updateSendingInvites(false);
                        },
                      });
                    }}
                    shape="square"
                    type="hollow"
                    value={
                      savingMemberPermChanges
                        ? "Sending Results Emails..."
                        : `Send ${selectedMembersCount} User${
                            selectedMembersCount === 1 ? "" : "s"
                          } Their Results Email`
                    }
                  />
                </div>

                <div>
                  {emailsSent && !!emailsSent.length && (
                    <Icon
                      className="meta-super-sidepane-teammembership-email-invites-icon"
                      icon="check"
                      size={16}
                      style={{ marginRight: "10px" }}
                    />
                  )}

                  <Button
                    className="meta-super-sidepane-teammembership-email-invites-button"
                    disabled={
                      !selectedMembersCount ||
                      sendingInvites ||
                      savingMemberPermChanges
                    }
                    onClick={() => {
                      updateSendingInvites(true);

                      const selectedMembers = (props.teamMembers || []).filter(
                        (tm) => !!selectedTeamMembers[tm.personID]
                      );

                      sendEmailInviteLinksToUsers({
                        teamMembers: selectedMembers,
                        onError: () => {
                          updateSendingInvites(false);
                        },
                        onSuccess: (resp) => {
                          updateEmailsSent([...emailsSent, ...resp]);
                          updateSendingInvites(false);
                        },
                      });
                    }}
                    shape="square"
                    type="hollow"
                    value={
                      savingMemberPermChanges
                        ? "Sending Invites..."
                        : `Send ${selectedMembersCount} User${
                            selectedMembersCount === 1 ? "" : "s"
                          } Email Invite Links`
                    }
                  />
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

interface TeamMemberProps {
  isChecked: boolean;
  onlineConnectionsInfo?: UserOnlineInfo;
  onPersonSelect: (personID: number) => void;
  onPersonCheck: () => void;
  teamMember: Tyto.DISCProfileMini;
  websocketServerConnected: boolean;
}

const TeamMember = ({
  isChecked,
  onlineConnectionsInfo,
  onPersonSelect,
  onPersonCheck,
  teamMember,
  websocketServerConnected,
}: TeamMemberProps) => {
  const userHasChangeAbilities = !!_.get(
    teamMember,
    "teamToolsPermit.hasPermitChange",
    false
  );

  const { lastActivityDate, showCurrentlyOnline } = getLastActivityForMember(
    teamMember,
    onlineConnectionsInfo
  );

  return (
    <li
      className={cx(
        "meta-super-sidepane-teammembership-list-item",
        userHasChangeAbilities && "teammembership-has-change"
      )}
    >
      <div className="meta-super-sidepane-teammembership-list-item-checkbox-cont">
        <Checkbox
          className="meta-super-sidepane-teammembership-list-item-checkbox"
          checked={isChecked}
          onCheck={onPersonCheck}
          size={16}
        />
      </div>

      <div className="meta-super-sidepane-teammembership-list-item-userthumb-cont">
        <UserThumb
          asset={teamMember.profileImageAsset}
          className="meta-super-sidepane-teammembership-list-item-userthumb"
          discMiniProfile={teamMember}
          discValues={{
            d: teamMember.d3,
            i: teamMember.i3,
            s: teamMember.s3,
            c: teamMember.c3,
          }}
          onClickIcon="user-alt"
          onClick={() => onPersonSelect(teamMember.personID)}
          userName={teamMember.personName}
          size={teamMember.isTeamLeader ? 34 : 28}
        />
      </div>

      <div className="meta-super-sidepane-teammembership-list-item-personname-cont">
        <TextButton
          className="meta-super-sidepane-teammembership-list-item-personname"
          onClick={() => onPersonSelect(teamMember.personID)}
          iconProps={
            userHasChangeAbilities
              ? {
                  className:
                    "meta-super-sidepane-teammembership-list-item-personname-icon",
                  icon: "key-skeleton",
                  size: 14,
                  title:
                    "This user has the ablitity to change what others are permitted to see and do regarding DISC inside the TeamTools platform",
                }
              : undefined
          }
          iconSide="right"
          value={teamMember.personName}
        />
      </div>

      {teamMember.isTeamLeader &&
        showCurrentlyOnline &&
        websocketServerConnected && (
          <p className="meta-super-sidepane-teammembership-list-item-last-activity is-team-leader">
            <CurrentlyOnlineBadge />
          </p>
        )}

      {!teamMember.isTeamLeader && (
        <>
          <p
            className="meta-super-sidepane-teammembership-list-item-access-level"
            title={getAccessLevelTitleText(teamMember.teamToolsPermit)}
          >
            {getAccessLevelText(teamMember.teamToolsPermit)}
          </p>

          <p
            className={cx(
              "meta-super-sidepane-teammembership-list-item-assessment-status",
              teamMember.discStatus === "ocENABLED" &&
                "teammembership-list-item-assessment-complete"
            )}
          >
            {getStatus({
              discStatus: teamMember.discStatus,
              teamToolsPermit: teamMember.teamToolsPermit,
              // styleKey3: teamMember.styleKey3,
            })}
          </p>

          <p className="meta-super-sidepane-teammembership-list-item-last-activity">
            {lastActivityDate && (
              <>
                {showCurrentlyOnline && websocketServerConnected ? (
                  <CurrentlyOnlineBadge />
                ) : (
                  <Timestamp date={lastActivityDate} />
                )}
              </>
              // <Timestamp date={teamMember.lastActivity} />
            )}
          </p>
        </>
      )}
    </li>
  );
};

function getLastActivityForMember(
  teamMember: Tyto.DISCProfileMini,
  onlineConnectionsInfo?: UserOnlineInfo
): {
  lastActivityDate?: string;
  showCurrentlyOnline: boolean;
} {
  const { lastActivity } = teamMember;

  if (!onlineConnectionsInfo) {
    return {
      lastActivityDate: lastActivity,
      showCurrentlyOnline: false,
    };
  }

  const {
    connectionsCount,
    lastActivity: connectionsLastActivity,
  } = onlineConnectionsInfo;

  let lastActivityDate = !!connectionsCount
    ? connectionsLastActivity
    : lastActivity;

  if (!connectionsCount && lastActivity) {
    if (+new Date(lastActivity) < +new Date(connectionsLastActivity)) {
      lastActivityDate = connectionsLastActivity;
    } else {
      lastActivityDate = lastActivity;
    }
  }

  return {
    lastActivityDate,
    showCurrentlyOnline: !!connectionsCount,
  };
}

const getAccessLevelText = (teamToolsPermit: Tyto.TeamToolsPermissions) => {
  if (!teamToolsPermit) {
    return "None";
  } else if (teamToolsPermit.hasBasicViewDisc && teamToolsPermit.mayTakeDisc) {
    return "Full";
  } else if (teamToolsPermit.hasBasicViewDisc || teamToolsPermit.mayTakeDisc) {
    return "Partial";
  }

  return "None";
};

const getAccessLevelTitleText = (
  teamToolsPermit: Tyto.TeamToolsPermissions
) => {
  if (!teamToolsPermit) {
    return "none";
  } else if (teamToolsPermit.mayTakeDisc && !teamToolsPermit.hasBasicViewDisc) {
    return "May take assessment but not view results";
  } else if (!teamToolsPermit.mayTakeDisc && teamToolsPermit.hasBasicViewDisc) {
    return "May view others results, but not take assesment";
  } else if (teamToolsPermit.mayTakeDisc && teamToolsPermit.hasBasicViewDisc) {
    return "May take assessment and view results";
  }

  return "Cannot Take Assessment or View Results";
};

const SectionTitle = ({
  isChecked,
  isTeamLeader,
  onCheck,
}: {
  isTeamLeader: boolean;
  isChecked: boolean;
  onCheck: (nextVal: boolean) => void;
}) => {
  return (
    <li className="meta-super-sidepane-teammembership-list-item">
      <div
        className="meta-super-sidepane-teammembership-list-item-checkbox-cont"
        style={{ minWidth: "50px", minHeight: "50px" }}
      >
        <Checkbox
          className="meta-super-sidepane-teammembership-list-item-checkbox"
          checked={isChecked}
          onCheck={onCheck}
          size={16}
        />
      </div>

      <div
        className="meta-super-sidepane-teammembership-list-item-userthumb"
        style={{ minWidth: "40px", minHeight: "40px" }}
      ></div>

      <p className="meta-super-sidepane-teammembership-list-item-personname-cont is-title-key">
        {isTeamLeader ? "Leaders" : "Members"}
      </p>

      {!isTeamLeader && (
        <>
          <p className="meta-super-sidepane-teammembership-list-item-access-level is-title-key">
            Access Level
          </p>

          <p className="meta-super-sidepane-teammembership-list-item-assessment-status is-title-key">
            Assessment Status
          </p>

          <p className="meta-super-sidepane-teammembership-list-item-last-activity is-title-key">
            Last Activity
          </p>
        </>
      )}
    </li>
  );
};
