/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Button, Input } from "../../../components/common";
import { Tyto } from "../../../typings/tyto";
import TytoCalls from "../../../data/tyto";

import "./TeamTreeItemAddTeam.scss";

const newTeamKeys: Array<keyof Tyto.TeamGet> = [
  "activeStatus",
  "domainID",
  "outsideExpirationDate",
  "outsideType",
  "teamType",
  "teamID",
];

interface Props {
  onCreation: (team: Tyto.Team) => void;
  onError: (errorMsg?: string) => void;
  team: Tyto.Team;
}

export default (props: Props) => {
  const [saving, updateSaving] = React.useState<boolean>(false);
  const [value, updateValue] = React.useState<string>("");

  return (
    <div className="admin-teams-team-structure-item-addteam-cont">
      <p
        className="admin-teams-team-structure-item-addteam-title"
        title={`${props.team.parentNamePath
          .split("\t")
          .filter((item) => !!item)
          .map((teamName, curIdx) => `${teamName} > `)} ${props.team.name}`}
      >
        New team below <b>{props.team.name}</b>
      </p>

      <Input
        autofocus={true}
        containerClassName="admin-teams-team-structure-item-addteam-input-cont"
        className="admin-teams-team-structure-item-addteam-input"
        hideClear={true}
        onChange={(newValue) => updateValue(newValue)}
        placeholder="New Team Name"
        value={value}
      />

      <Button
        className="admin-teams-team-structure-item-addteam-button"
        disabled={!value || saving}
        onClick={() => {
          updateSaving(true);
          props.onError("");

          createTeam({
            onCreation: props.onCreation,
            onError: props.onError,
            parentTeam: props.team,
            primaryElementID: props.team.teamID,
            teamName: value,
          });
          // TODO: Start save
        }}
        shape="square"
        type="color"
        value={saving ? "Creating..." : "Create"}
      />
    </div>
  );
};

const createTeam = async ({
  onCreation,
  onError,
  teamName,
  parentTeam,
  primaryElementID,
}: {
  onCreation: (team: Tyto.Team) => void;
  onError: (msg: string) => void;
  parentTeam: Tyto.Team;
  primaryElementID: number;
  teamName: string;
}) => {
  if (!teamName || !primaryElementID) {
    return;
  }

  try {
    const { teamID } = await TytoCalls.Team.post({
      primaryElementID,
      teamName,
    });

    const { team: newTeam } = await TytoCalls.Team.get({ teamID });

    const formattedTeamResp = mimicTBFResponse({ newTeam, parentTeam });

    onCreation(formattedTeamResp);
    return formattedTeamResp;
  } catch (err) {
    const formattedError =
      typeof err === "string" ? err : _.get(err, "msg", "An error Occurred.");
    onError(formattedError);
  }
};
// * TBF === TeamsByFunction
const mimicTBFResponse = ({
  newTeam,
  parentTeam,
}: {
  newTeam: Tyto.TeamGet;
  parentTeam: Tyto.Team;
}) => {
  const pluckedItems: {
    activeStatus: Tyto.TeamGet["activeStatus"];
    domainID: Tyto.TeamGet["domainID"];
    outsideExpirationDate: Tyto.TeamGet["outsideExpirationDate"];
    outsideType: Tyto.TeamGet["outsideType"];
    teamType: Tyto.TeamGet["teamType"];
    teamID: Tyto.TeamGet["teamID"];
  } = _.pick(newTeam, newTeamKeys);

  // ! Wicked Hacky. This is to avoid a large, uneccessary, potentially very slow call to TeamsByFunction just for 1 new team
  const formattedTeam: Tyto.Team = {
    ...parentTeam,
    isBelow: true,
    isDirect: false,
    isTeamToolsConfig: false,
    level: parentTeam.level + 1,
    parentNamePath: `${parentTeam.parentNamePath}\t${parentTeam.name}`,
    iPath: `${parentTeam.iPath}${parentTeam.teamID},`,
    parentID: parentTeam.teamID,
    isLeader: false,
    name: newTeam.teamName || "",
    ocType: newTeam.teamType,
    // ! need to calc "subDomainParentPath"
    ...pluckedItems,
  };

  return formattedTeam;
};
