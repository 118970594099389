import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M592 192h-94.08A144.14 144.14 0 0 1 576 320c0 61.91-39.28 114.82-94.22 135.13L493 499.88A16 16 0 0 0 508.49 512h39A16 16 0 0 0 563 499.88L576 448h16a48 48 0 0 0 48-48V240a48 48 0 0 0-48-48zM288 320a144.14 144.14 0 0 1 78.08-128H48a48 48 0 0 0-48 48v160a48 48 0 0 0 48 48h16l13 51.88A16 16 0 0 0 92.49 512h39A16 16 0 0 0 147 499.88L160 448h206.08A144.14 144.14 0 0 1 288 320zM96 352a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm96 0a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm352-32a112 112 0 1 0-112 112 112.16 112.16 0 0 0 112-112zm-120-40a32 32 0 0 0-32 32 16 16 0 0 1-32 0 64.07 64.07 0 0 1 64-64 16 16 0 0 1 0 32zm0-152h16a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm93.65 5.65a16 16 0 0 0 22.63 0l67.88-67.88a16 16 0 0 0 0-22.63l-11.31-11.31a16 16 0 0 0-22.63 0l-67.88 67.89a16 16 0 0 0 0 22.62zm-193.93 0a16 16 0 0 0 22.63 0l11.31-11.31a16 16 0 0 0 0-22.62l-67.88-67.89a16 16 0 0 0-22.63 0l-11.31 11.31a16 16 0 0 0 0 22.63z"
      />
    </svg>
  );
};
