/*
 * Component Description
 */
import * as React from "react";
import { useLocation, useParams } from "react-router-dom";

import { AppStoreProps, StoreContext } from "../../data/stores/AppStore";
import AccountCreation from "./subcomponents/AccountCreation";
import PickAccount from "./subcomponents/PickAccount";
import Join from "./subcomponents/Join";
import Login from "./subcomponents/Login";
import SessionCreation from "./subcomponents/SessionCreation";
import SetPassword from "./subcomponents/SetPassword";

import "./style.scss";

interface Props {
  creation?: boolean;
  default?: boolean;
  location?: any;
  loginName?: string;
  outlookSessionKey?: string;
  tempSessionKey?: string;
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);

  const location = useLocation();
  const tempSessionKeyFromURL = useTempSessionKey();
  const loginNameFromURL = useLoginName();

  const tempSessionKey = tempSessionKeyFromURL || props.tempSessionKey;
  const loginName = loginNameFromURL || props.loginName;

  return (
    <section className="logon-interface">
      {renderComponent({
        isAccountCreation: props.creation,
        loginName: loginName,
        location: location || props.location,
        path: location.pathname,
        store,
        tempSessionKey: tempSessionKey,
        uri: props.uri,
      })}
    </section>
  );
};

const renderComponent = (data: {
  isAccountCreation?: boolean;
  location?: any;
  loginName?: string;
  path?: string;
  store: AppStoreProps;
  tempSessionKey?: string;
  uri?: string;
}) => {
  if (data.isAccountCreation) {
    return <AccountCreation />;
  } else if (data.path && data.path.includes("account-selection")) {
    return (
      <PickAccount
        loginName={data.loginName}
        location={data.location}
        uri={data.uri}
        store={data.store}
      />
    );
  } else if (data.path && data.path.includes("session-check")) {
    return (
      <SessionCreation
        store={data.store}
        location={data.location}
        path={data.path}
        uri={data.uri}
      />
    );
  } else if (data.path && data.path.includes("join")) {
    return (
      <Join
        store={data.store}
        location={data.location}
        path={data.path}
        tempSessionKey={data.tempSessionKey}
        uri={data.uri}
      />
    );
  } else if (data.path && data.path.includes("set-password")) {
    return (
      <SetPassword
        store={data.store}
        location={data.location}
        path={data.path}
        uri={data.uri}
        requireCurrentPassword={
          !!(data.path && data.path.includes("my-password"))
        }
      />
    );
  }

  return (
    <Login
      loginName={data.loginName}
      store={data.store}
      location={data.location}
      uri={data.uri}
    />
  );
};

function useTempSessionKey() {
  const { tempSessionKey } = useParams();
  return tempSessionKey || "";
}

function useLoginName() {
  const { loginName } = useParams();
  return loginName || "";
}
