/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { SessionHOC } from "../../components/hoc/";
import { StoreContext } from "../../data/stores/AppStore";
import { StoreContext as GeneralStoreContext } from "../../data/stores/GeneralStore";
import { Nav } from "../../components/meta/";
import { Tyto } from "../../typings/tyto";
import TytoCalls from "../../data/tyto/";
import { BILLING_MODAL_DEFAULT } from "../../data/constants/";

import Accounts from "./subcomponents/Accounts";
import AccountsSummary from "./subcomponents/AccountsSummary";

import "./style.scss";

interface ReducedBillingSummary {
  createdUserCount: number;
  deletedUserCount: number;
  exceptionCount: number;
  userCount: number;
}

interface Props {
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  const [sessionKey, updateSessionKey] = React.useState(
    _.get(AppStore, "state.sessionData.sessionKey", "")
  );
  const [searchTerm, updateSearchTerm] = React.useState("");
  // const [billingInfo, updateBillingInfo] = React.useState<
  //   { [x: number]: ReducedBillingSummary } | undefined
  // >(undefined);
  const [billingInfo, updateBillingInfo] = React.useState<
    { [x: number]: Tyto.BillingSummaryResp } | undefined
  >(undefined);
  const [primaryDomain, updatePrimaryDomain] = React.useState<
    Tyto.Team | undefined
  >(_.orderBy(_.get(AppStore, "state.subDomains", []), ["level"], ["asc"])[0]);
  const [subDomains, updateSubDomains] = React.useState(() => {
    const newSubDomains: Tyto.Team[] = _.get(AppStore, "state.subDomains", []);

    if (!Array.isArray(newSubDomains)) {
      return [];
    }

    const maxDepth = primaryDomain ? primaryDomain.level + 1 : 1;

    const sortedAndFilteredSubDomains = _.orderBy(
      newSubDomains,
      ["name"],
      ["asc"]
    ).filter((subDomain) => subDomain.level <= maxDepth);

    return sortedAndFilteredSubDomains;
  });
  const [filteredSubDomains, updateFilteredSubDomains] = React.useState<
    Tyto.Team[]
  >(_.get(AppStore, "state.subDomains", []));

  // * =====================================================
  // * [E-0] ===============================================
  // * =====================================================
  React.useEffect(() => {
    if (!_.get(GeneralStore, "state.uploadURL", undefined)) {
      loadConfigurationClient({
        onError: (errMsg) => {},
        onSuccess: (config) => {
          if (GeneralStore.dispatch) {
            GeneralStore.dispatch({
              payload: {
                configurationClient: config,
              },
              type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
            });
          }
        },
      });
    }
  }, []);

  // * =====================================================
  // * [E-1] ===============================================
  // * =====================================================
  React.useEffect(() => {
    // ! Errors because not all have domains have a non-custom billingInfo which makes it error and fail.
    if (Array.isArray(subDomains) && subDomains.length) {
      getBillingInfo(updateBillingInfo, subDomains);
    }
  }, [subDomains]);

  // * =====================================================
  // * [E-2] ===============================================
  // * =====================================================
  React.useEffect(() => {
    console.log("Accounts useEffect triggered.");
    const newSubDomains: Tyto.Team[] = _.get(AppStore, "state.subDomains", []);

    if (!Array.isArray(newSubDomains)) {
      updateSubDomains([]);
      updatePrimaryDomain(undefined);
    }

    const newPrimaryDomain = _.orderBy(newSubDomains, ["level"], ["asc"])[0];
    const maxDepth = newPrimaryDomain ? newPrimaryDomain.level + 1 : 1;

    const sortedAndFilteredSubDomains = _.orderBy(
      newSubDomains,
      ["name"],
      ["asc"]
    ).filter((subDomain) => subDomain.level <= maxDepth);

    updateSubDomains(sortedAndFilteredSubDomains);
    updatePrimaryDomain(newPrimaryDomain);

    if (searchTerm) {
      const regExp = new RegExp(_.escapeRegExp(searchTerm), "i");

      updateFilteredSubDomains(
        subDomains.filter((subDomain) => regExp.test(subDomain.name))
      );
    }
  }, [_.get(AppStore, "state.subDomains", [])]);

  // * [E-3] - Keep local sessionData up to date
  React.useEffect(() => {
    const newSessionData = _.get(AppStore, "state.sessionData", undefined);

    if (
      sessionKey &&
      (!newSessionData || (newSessionData && !newSessionData.sessionKey))
    ) {
      updateSessionKey(undefined);
    }
  }, [_.get(AppStore, "state.sessionData", undefined)]);
  // * =====================================================
  // * End of Effects ======================================
  // * =====================================================

  // if (!sessionKey) {
  //   return <Redirect to="/login" noThrow={true} />;
  // }

  const storeSessionData = _.get(AppStore, "state.sessionData", undefined);

  return (
    <SessionHOC path={props.path} uri={props.uri}>
      <section className="partner-dashboard-interface">
        <Nav path={props.path} uri={props.uri} />

        <section className="partner-dashboard-main-cont">
          <h1 className="partner-dashboard-main-title title-bold">
            {primaryDomain
              ? primaryDomain.name
              : (storeSessionData && storeSessionData.domainID) ||
                "Domain Name Not Found"}

            <AccountsSummary
              billingInfo={billingInfo}
              searchTerm={searchTerm}
              subDomains={subDomains}
              updateSearchTerm={(newSearchTerm) => {
                const newFilteredSubDomains = filterSubDomains(
                  searchTerm,
                  newSearchTerm,
                  filteredSubDomains,
                  subDomains
                );

                updateSearchTerm(newSearchTerm);
                updateFilteredSubDomains(newFilteredSubDomains);
              }}
            />

            {Array.isArray(filteredSubDomains) ? (
              <Accounts
                AppStore={AppStore}
                billingInfo={billingInfo}
                primaryDomain={primaryDomain}
                searchTerm={searchTerm}
                subDomains={filteredSubDomains}
                uploadURL={GeneralStore.state?.uploadURL || ""}
                // uploadURL={_.get(GeneralStore, "state.uploadURL", undefined)}
              />
            ) : (
              <h2 className="title-bold">Loading Accounts...</h2>
            )}
          </h1>
        </section>
      </section>
    </SessionHOC>
  );
};

const filterSubDomains = (
  curSearchTerm: string,
  searchTerm: string,
  curFilteredSubDomains: Tyto.Team[],
  subDomains: Tyto.Team[]
) => {
  if (!Array.isArray(subDomains) || !subDomains.length) {
    return [];
  } else if (!searchTerm) {
    return subDomains;
  }

  const regExpOfCurSearchTerm = new RegExp(
    `/^${_.escapeRegExp(curSearchTerm)}/g`,
    "i"
  );
  const regExp = new RegExp(_.escapeRegExp(searchTerm), "i");

  if (regExpOfCurSearchTerm.test(searchTerm)) {
    return curFilteredSubDomains.filter((subDomain) =>
      regExp.test(subDomain.name)
    );
  }

  return subDomains.filter((subDomain) => regExp.test(subDomain.name));
};

const getBillingInfo = async (
  setBillingInfo: (
    update: { [x: number]: Tyto.BillingSummaryResp } | undefined
  ) => void,
  subDomains: Tyto.Team[]
) => {
  if (!Array.isArray(subDomains)) {
    // ? Why is it returning and empty Array ?
    return [];
  }

  try {
    const domainBillings = await loadDomainBillings({});
    // const domainBillingsKeyed = _.keyBy(domainBillings, "teamID");

    const firstOfNextMonth = new Date();
    firstOfNextMonth.setMonth(firstOfNextMonth.getMonth() + 1);
    firstOfNextMonth.setDate(1);

    const billingInfoCalls = domainBillings
      .filter((domain) => domain.teamID !== 551)
      .map((domain) => loadDomainBilling({ firstOfNextMonth, domain }));

    const billingInfo = await Promise.all(billingInfoCalls);

    const filteredResults = billingInfo
      .map((summary) => ({
        domainID: summary.domainID,
        ...(summary.billingSummary || {}),
        billingModel: summary.billingModel,
        domainName: summary.domainName,
      }))
      .filter((billingInfo) => !!billingInfo);
    const resultsKeyed = _.keyBy(filteredResults, "domainID");
    debugger;
    setBillingInfo(resultsKeyed as any);
  } catch (err) {
    console.warn("Error while loading Billing Info.");
    // ? Why is it returning and empty Array ?
    return [];
  }
};

const loadConfigurationClient = async ({
  onError,
  onSuccess,
}: {
  onError: (errMsg: string) => void;
  onSuccess: (config: Tyto.Configuration.Client) => void;
}) => {
  try {
    const configurationClient = await TytoCalls.Configuration.Client.get({});

    onSuccess(configurationClient);
  } catch (err) {
    onError(`${err}`);
  }
};

const loadDomainBillings = async ({}: {}) => {
  try {
    const resp = await TytoCalls.DomainBillings.get({});

    return resp.domainBilling;
  } catch (err) {
    return [];
  }
};

const loadDomainBilling = async ({
  // subDomain,
  // domainBillingsKeyed,
  domain,
  firstOfNextMonth,
}: {
  // subDomain: Tyto.Team;
  // domainBillingsKeyed: { [x: number]: Tyto.DomainBilling };
  domain: Tyto.DomainBilling;
  firstOfNextMonth: Date;
}) => {
  if (!domain) {
    debugger;
    return {};
  } else if (domain.billingModel !== BILLING_MODAL_DEFAULT) {
    return {
      domainID: domain.teamID,
      domainName: domain.teamName || domain.otherName || "",
      billingModel: domain.billingModel,
      billingSummary: null,
    };
  }

  try {
    const resp = await TytoCalls.DomainBilling.get({
      domainID: domain.teamID,
      billingPeriodBeginDate: firstOfNextMonth,
      billingPeriodEndDate: firstOfNextMonth,
    });

    return {
      domainID: domain.teamID,
      domainName: domain.teamName || domain.otherName || "",
      billingModel: domain.billingModel,
      billingSummary: resp.billingSummary,
    };
  } catch (err) {
    return {
      domainID: domain.teamID,
      domainName: domain.teamName || domain.otherName || "",
      billingModel: domain.billingModel,
      billingSummary: null,
    };
  }
};
