/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { StoreContext } from "../../../data/stores/GeneralStore";
import AgnosticModal from "../../modals/agnostic/";
import Nav from "../nav/";

import "./style.scss";

interface Props {
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  let GeneralStore = React.useContext(StoreContext);

  const showMenu = _.get(GeneralStore, "state.showMenu", false);

  // if (!showMenu) {
  //   return null;
  // }

  return (
    <>
      <AgnosticModal
        onDismiss={() => {
          if (GeneralStore.dispatch) {
            GeneralStore.dispatch({
              payload: {},
              type: "GENERAL_STORE_TOGGLE_NAVMENU"
            });
          }
        }}
        isOpen={showMenu}
        className="meta-mobilenav-modal"
        overlayClassName="meta-mobilenav-modal-overlay"
        blurBody={false}
        hasCloseBtn={false}
      >
        <Nav
          onMenuButtonClick={() => {
            if (GeneralStore.dispatch) {
              GeneralStore.dispatch({
                payload: {
                  showMenu: false
                },
                type: "GENERAL_STORE_TOGGLE_NAVMENU"
              });
            }
          }}
          {...props}
        />
      </AgnosticModal>

      <div
        className={cx(
          "meta-mobilenav-menu-btn-cont",
          !showMenu && "meta-mobilenav-menu-btn-shown"
        )}
      >
        <button
          className="meta-mobilenav-menu-btn"
          onClick={() => {
            if (GeneralStore.dispatch) {
              GeneralStore.dispatch({
                payload: {},
                type: "GENERAL_STORE_TOGGLE_NAVMENU"
              });
            }
          }}
        >
          <hr className="meta-mobilenav-menu-btn-line meta-mobilenav-menu-btn-line-1" />
          <hr className="meta-mobilenav-menu-btn-line meta-mobilenav-menu-btn-line-2" />
          <hr className="meta-mobilenav-menu-btn-line meta-mobilenav-menu-btn-line-3" />
        </button>
      </div>
    </>
  );
};
