/*
 * Component Description
 */
import * as React from "react";

import { Button, Message } from "../../../components/common/";

interface Props {
  backButton?: {
    value: string;
    onClick: () => void;
  };
  showBackButton: boolean;
  userID?: string;
}

export default (props: Props) => {
  return (
    <div className="directory-person-profile-wrapper side-pane-open">
      <section className="directory-person-profile-main-cont">
        {props.showBackButton && (
          <div className="directory-person-profile-back-button-cont">
            <Button
              iconLeft="angle-left"
              iconProps={{
                className: "directory-person-profile-back-button-icon",
                size: 30,
              }}
              className="directory-person-profile-back-button"
              onClick={props.backButton ? props.backButton.onClick : () => {}}
              value={props.backButton ? props.backButton.value : ""}
            />
          </div>
        )}

        <Message
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
          value={`User ${
            props.userID ? `matching the ID ${props.userID} ` : ""
          }is unavailable.`}
        />

        <Message
          style={{
            marginTop: "0px",
            paddingTop: "10px",
            fontSize: "12px",
            boxSizing: "border-box",
          }}
          value={
            "Either the ID is incorrect, or you do not have sufficient permission to view their information."
          }
        />

        <h1
          style={{
            margin: "50px 0px",
            width: "100%",
            fontSize: "160px",
            textAlign: "center",
            opacity: 0.07,
            color: "#000",
          }}
        >
          !
        </h1>
      </section>
    </div>
  );
};
