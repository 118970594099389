/*
 * Component Description
 */
import * as React from "react";

import PlacerholderTeamTreeItem from "./Placeholder-TeamTreeItem";

const fakeTreeMap = [
  0,
  1,
  1,
  1,
  2,
  3,
  2,
  2,
  1,
  2,
  3,
  4,
  4,
  3,
  1,
  1,
  2,
  3,
  2,
  2,
  1,
  1,
  1,
  1,
  2,
  2,
  3,
  4,
  3,
  3
];

interface Props {}

export default (props: Props) => {
  const height = Math.round(((window.innerHeight || 500) - 150) / 38);
  return (
    <div>
      <ul className="admin-teams-team-structure-list">
        {fakeTreeMap.slice(0, height).map((item, curIdx) => (
          <PlacerholderTeamTreeItem key={curIdx} depth={item} />
        ))}
      </ul>
    </div>
  );
};
