import { ENDPOINT_TEAM } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_TEAM;

export const Team = {
  get(
    params: Endpoints.Tyto.Team.GetParameters & Endpoints.Tyto.MetaArgs,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<{
      team: Tyto.TeamGet;
      session: SessionData;
    }>;
  },

  post(params: Endpoints.Tyto.Team.PostParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, params, callOpts) as Promise<{
      teamID: number;
      session: SessionData;
    }>;
  }
};
