import { useQuery } from "@tanstack/react-query";

import { REACT_QUERY_KEYS } from "../constants/";
import API from "../tyto/";

export function useDISCMini({
  userID,
  retry = false,
  isEnabled = true,
}: {
  userID: number;
  retry?: boolean | number;
  isEnabled?: boolean;
}) {
  const query = useQuery({
    queryKey: [REACT_QUERY_KEYS.DISC_MINI_USER, { userID }],
    queryFn: async () => {
      const data = await API.DISCProfilesMini.get({
        personIDs: `${userID}`,
      });

      return data;
    },
    enabled: isEnabled,
    retry,
  });

  return {
    ...query,
  };
}
