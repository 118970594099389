/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import QueryString from "query-string";

import pkg from "../../../../package.json";
import TytoCalls from "../../../data/tyto/";
import { removeSessionData } from "../../../data/storage/session";
import { AppStoreProps } from "../../../data/stores/AppStore";
import { Button, Input } from "../../../components/common/";
import { SessionData } from "../../../typings";

import "./SetPassword.scss";
import {
  setTempSessionData,
  clearTempSessionData,
} from "../../../data/storage/session";
import { Tyto } from "../../../typings/tyto";

const appBrand = "team-tools-webapp";
const appVersion = _.get(pkg, "version", "");

interface Props {
  AppStore?: AppStoreProps;
  requireCurrentPassword?: boolean;
  tempSessionData?: SessionData;
}

export default (props: Props) => {
  const [saving, updateSaving] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [currentPassword, updateCurrentPassword] = React.useState("");
  const [password, updatePassword] = React.useState("");
  const [passwordConfirm, updatePasswordConfirm] = React.useState("");
  const [tempSessionKeyFromStore, updateTempSessionKeyFromStore] =
    React.useState(
      _.get(props, "tempSessionData.tempSessionKey") ||
        _.get(props, "AppStore.state.tempSessionKey", "")
    );
  // const [sessionKeyForAPI, updateSessionKeyForAPI] = React.useState(
  //   _.get(props, "tempSessionData.sessionKey") ||
  //     _.get(props, "AppStore.state.sessionKey", "")
  // );
  const [manualSessionKey, updateManualSessionKey] = React.useState("");
  const [logonNameFailed, updateLogonNameFailed] = React.useState(false);
  const [logonName, updateLogonName] = React.useState("");
  const [loginDomainID, updateLoginDomainID] = React.useState<
    string | undefined
  >(undefined);
  // const [person, updatePerson] = React.useState<Tyto.Person | undefined>(
  //   undefined
  // );

  const navigate = useNavigate();

  React.useEffect(() => {
    // const tempSessionUserID =
    //   _.get(props, "tempSessionData.userID", 0) ||
    //   _.get(props, "AppStore.state.tempSessionData.userID", 0);

    if (tempSessionKeyFromStore) {
      validateSession({
        sessionKey: tempSessionKeyFromStore,
        onError: (errMsg: string) => updateError(errMsg),
        onSuccess: (newLogonName, newLoginDomainID) => {
          updateLoginDomainID(newLoginDomainID || loginDomainID);
          updateLogonName(newLogonName);
          updateLogonNameFailed(!newLogonName);
        },
        // onSuccess: (person) => updatePerson(person),
      });
    }
  }, []);

  const nonTempSessionData = _.get(props.AppStore, "state.sessionData");
  //requireCurrentPassword
  const requiredInputDataPresent =
    !!password && !!passwordConfirm && password === passwordConfirm;

  const canSave =
    requiredInputDataPresent &&
    (logonName || (props.requireCurrentPassword && nonTempSessionData));

  const sessionUserName = _.get(
    props,
    "tempSessionData.userName",
    _.get(nonTempSessionData, "userName", "")
  );

  return (
    <section className="assessmenttaker-interface-assessmentcomplete-setpassword-main-cont">
      <h2 className="assessmenttaker-interface-questions-title title-bold">
        {props.requireCurrentPassword ? "Update" : "Set"} Password for Account
      </h2>

      {sessionUserName && (
        <p style={{ boxSizing: "border-box", padding: "12px 0px 26px" }}>
          Set the password for{" "}
          <span style={{ fontWeight: "bold" }}>{sessionUserName}</span>'s
          account
        </p>
      )}

      {error && (
        <p style={{ color: "red", display: "block", padding: "4px 0px" }}>
          {error}
        </p>
      )}

      {logonNameFailed && (
        <>
          <p className="assessmenttaker-interface-assessmentcomplete-setpassword-label">
            Logon Name or Email Address
          </p>
          <Input
            className="assessmenttaker-interface-assessmentcomplete-setpassword-input"
            disabled={saving}
            containerClassName="assessmenttaker-interface-assessmentcomplete-setpassword-input-cont"
            hideClear={true}
            onChange={(newVal) => updateLogonName(newVal)}
            value={logonName || ""}
          />
        </>
      )}

      {props.requireCurrentPassword && (
        <>
          <p className="assessmenttaker-interface-assessmentcomplete-setpassword-label">
            Current Password
          </p>
          <Input
            className="assessmenttaker-interface-assessmentcomplete-setpassword-input"
            disabled={saving}
            containerClassName="assessmenttaker-interface-assessmentcomplete-setpassword-input-cont"
            hideClear={true}
            onChange={(newVal) => updateCurrentPassword(newVal)}
            type="password"
            value={currentPassword}
          />
        </>
      )}

      <p className="assessmenttaker-interface-assessmentcomplete-setpassword-label">
        Password
      </p>
      <Input
        className="assessmenttaker-interface-assessmentcomplete-setpassword-input"
        disabled={saving}
        containerClassName="assessmenttaker-interface-assessmentcomplete-setpassword-input-cont"
        hideClear={true}
        onChange={(newVal) => updatePassword(newVal)}
        type="password"
        value={password}
      />

      <p className="assessmenttaker-interface-assessmentcomplete-setpassword-label">
        Confirm Password
      </p>
      <Input
        className="assessmenttaker-interface-assessmentcomplete-setpassword-input"
        disabled={saving}
        containerClassName="assessmenttaker-interface-assessmentcomplete-setpassword-input-cont"
        hideClear={true}
        onChange={(newVal) => updatePasswordConfirm(newVal)}
        type="password"
        value={passwordConfirm}
      />

      {!tempSessionKeyFromStore && !props.requireCurrentPassword && (
        <>
          <p className="assessmenttaker-interface-assessmentcomplete-setpassword-label">
            Assessment Key
          </p>
          <Input
            className="assessmenttaker-interface-assessmentcomplete-setpassword-input"
            disabled={saving}
            containerClassName="assessmenttaker-interface-assessmentcomplete-setpassword-input-cont"
            hideClear={true}
            onChange={(newVal) => updateManualSessionKey(newVal)}
            value={manualSessionKey}
          />
        </>
      )}

      {!logonName &&
        !logonNameFailed &&
        (!props.requireCurrentPassword || !nonTempSessionData) && (
          <p>Loading some account information...</p>
        )}

      <div className="assessmenttaker-interface-assessmentcomplete-setpassword-buttons-cont">
        <Button
          className="assessmenttaker-interface-assessmentcomplete-setpassword-button"
          disabled={
            saving ||
            !canSave ||
            (!tempSessionKeyFromStore &&
              manualSessionKey.length < 10 &&
              !props.requireCurrentPassword) ||
            (props.requireCurrentPassword && !currentPassword)
          }
          onClick={() => {
            updateError("");
            updateSaving(true);

            setPassword({
              currentPassword,
              password,
              onError: (msg) => {
                updateError(msg);
                updateSaving(false);
              },
              requireCurrentPassword: !!props.requireCurrentPassword,
              sessionKey:
                tempSessionKeyFromStore ||
                manualSessionKey ||
                _.get(nonTempSessionData, "sessionKey", ""),
              onSuccess: () => {
                // * Login with Data
                debugger;
                loginWithNewCredentials({
                  navigate,
                  AppStore: props.AppStore,
                  logonName,
                  loginDomainID,
                  userID: _.get(
                    props,
                    "AppStore.state.tempSessionData.userID",
                    0
                  ),
                  password,
                  onError: (msg) => {
                    updateError(msg);
                    updateSaving(false);
                  },
                  onSuccess: () => {
                    debugger;
                    if (props.AppStore && props.AppStore.dispatch) {
                      clearTempSessionData();

                      props.AppStore.dispatch({
                        payload: {
                          tempSessionData: undefined,
                          tempSessionKey: undefined,
                        },
                        type: "SET_TEMP_SESSION_DATA",
                      });
                    }

                    updateSaving(false);

                    // * Look for redirect path in URL and use if found
                    const redirectParams: string = _.get(
                      window.location,
                      "search",
                      ""
                    );
                    const parsedParamsObj: {
                      [x: string]: string;
                    } = QueryString.parse(redirectParams);

                    const path =
                      (parsedParamsObj && parsedParamsObj.redirect) || "/";

                    navigate(path);
                  },
                });
              },
            });
          }}
          shape="square"
          type="color"
          value={saving ? "Setting Pasword..." : "Set Password"}
        />
      </div>
    </section>
  );
};

const validateSession = async ({
  sessionKey,
  onError,
  onSuccess,
}: {
  sessionKey: string;
  onError: (msg: string) => void;
  onSuccess: (logonName: string, loginDomainID?: string) => void;
}) => {
  try {
    const validateResp = await TytoCalls.Login.ResetPassword.Validate.get(
      { sessionKey },
      { omitSessionKey: true }
    );

    debugger;

    onSuccess(
      validateResp.logonName || "",
      _.get(validateResp, "domain.loginDomainID", undefined)
    );
  } catch (err) {
    const errMsg =
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Could not Set password.");

    onError(errMsg);
  }
};

// const loadPerson = async ({
//   userID,
//   sessionKey,
//   onError,
//   onSuccess,
// }: {
//   userID: number;
//   sessionKey: string;
//   onError: (msg: string) => void;
//   onSuccess: (person: Tyto.Person) => void;
// }) => {
//   try {
//     const personResp = await TytoCalls.Person.get(
//       { personID: userID, sessionKey },
//       { omitSessionKey: true }
//     );

//     onSuccess(personResp.person);
//   } catch (err) {
//     const errMsg =
//       typeof err === "string"
//         ? err
//         : _.get(err, "error.msg", "Could not Set password.");

//     onError(errMsg);
//   }
// };

const loginWithNewCredentials = async ({
  navigate,
  AppStore,
  logonName,
  loginDomainID,
  password,
  userID,
  onError,
  onSuccess,
}: {
  navigate: ReturnType<typeof useNavigate>;
  AppStore?: AppStoreProps;
  logonName: string;
  loginDomainID?: string;
  password: string;
  userID: number;
  onError: (msg: string) => void;
  onSuccess: () => void;
}) => {
  if (!logonName || !password || !AppStore) {
    if (password && !logonName && AppStore && AppStore.dispatch) {
      removeSessionData(_.get(AppStore, "state.loggedInUserID", 0), () => {
        if (AppStore.dispatch) {
          AppStore.dispatch({
            payload: {},
            type: "CLEAR_USER_SESSION_DATA",
          });

          navigate("/login");
        }
      });
    }

    onError("Not enough information was supplied.");

    return;
  }

  try {
    const resp = await TytoCalls.Login.Authenticate4.post({
      username: logonName,
      domain: loginDomainID,
      password,
      appBrand,
      appVersion,
    });

    let authSession: SessionData | undefined = undefined;

    if (resp.authResults && resp.authResults.length === 1) {
      authSession = resp.authResults[0].authSession;
    } else if (resp.authResults && resp.authResults.length > 1) {
      const targetResult = resp.authResults.find((ar) => ar.userID === userID);

      authSession = targetResult ? targetResult.authSession : undefined;
    }

    if (AppStore && AppStore.dispatch && authSession) {
      AppStore.dispatch({
        payload: authSession,
        type: "USER_LOGGED_IN",
      });

      await loadNewDISCMini({
        AppStore,
        personID: _.get(authSession, "userID", 0),
        onError,
      });

      onSuccess();
    } else {
      console.warn(
        "store or store.dispatch were not defined when they should be."
      );

      onError("Could not update Local Data.");
    }
  } catch (err) {
    console.warn(
      "An Error occurred while trying to Authenticate Using new crednetials."
    );

    let error = typeof err === "string" ? err : _.get(err, "msg", "Err");

    if (err && (err as any).sts === -202) {
      onError(`New Password failed.`);
    } else {
      onError(`${error}`);
    }
  }
};

const loadNewDISCMini = async ({
  AppStore,
  personID,
  onError,
}: {
  AppStore: AppStoreProps;
  personID: number;
  onError: (msg: string) => void;
}) => {
  if (!personID) {
    onError("User Identifier not found.");
    return;
  }

  try {
    const resp = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${personID}`,
    });

    if (AppStore && AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          userDISCMini: resp.discProfiles[0],
          usrID: personID,
        },
        type: "USER_PERSONAL_DISC_MINI",
      });

      return;
    }
  } catch (err) {
    onError("Failed to load new DISC Report.");
  }
};

const setPassword = async ({
  currentPassword,
  password,
  onError,
  sessionKey,
  onSuccess,
  requireCurrentPassword,
}: {
  currentPassword: string;
  password: string;
  sessionKey: string;
  onSuccess: () => void;
  onError: (msg: string) => void;
  requireCurrentPassword: boolean;
}) => {
  if (!password || (!sessionKey && !requireCurrentPassword)) {
    const reasons = [
      !password ? "password" : "",
      !sessionKey ? "session key" : "",
    ]
      .filter((reason) => reason)
      .join(", and ");
    onError(`Missing ${reasons || "Information"}`);
    return;
  }

  try {
    if (requireCurrentPassword) {
      await TytoCalls.Person.MyPassword.put({
        oldPassword: currentPassword,
        password,
      });
    } else {
      await TytoCalls.Login.ResetPassword.post({
        password,
        sessionKey,
      });
    }

    onSuccess();
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Could not Set password.")
    );
  }
};
