/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../typings/tyto";
import AgnosticModal from "../agnostic";
import SetPassword from "./SetPassword";

import "./style.scss";

interface Props {
  userID: number;
  isOpen: boolean;
  onDismiss: () => void;
  discProfileMini?: Tyto.DISCProfileMini;
  title?: string;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className="modals-setpassword-modal-main-cont"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-setpassword-modal-overlay"
    >
      <SetPassword
        discProfileMini={props.discProfileMini}
        isOpen={props.isOpen}
        onDismiss={props.onDismiss}
        title={props.title}
        userID={props.userID}
      />
    </AgnosticModal>
  );
};
