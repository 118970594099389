/*
 * Load PDF Report if discMini has style, and render link if you have permission as user and Lesson response is Valid
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { AppStoreProps } from "../../../../../data/stores/AppStore";
import { Tyto } from "../../../../../typings/tyto";
import TytoCalls from "../../../../../data/tyto/";
import { URL_BASE } from "../../../../../data/constants/";
import { Button, DISCValueRender, Icon } from "../../../../common/";

import "./PDFDownloadLink.scss";

interface Props {
  className?: string;
  AppStore: AppStoreProps;
  discMini: Tyto.DISCProfileMini;
  personDISCMini: Tyto.DISCProfileMini;
}

export default (props: Props) => {
  const [downloadPath, updateDownloadPath] = React.useState("");
  const [hasChecked, updateHasChecked] = React.useState(false);
  const [errorMsg, updateErrorMsg] = React.useState("");

  React.useEffect(() => {
    if (props.discMini) {
      updateHasChecked(false);

      if (props.discMini.pdfLessonID) {
        const lessonFromState = _.get(
          props.AppStore,
          `state.pdfLessons[${props.discMini.pdfLessonID}]`,
          undefined
        );

        if (lessonFromState) {
          const dlPath = pullDownloadPathFromLesson(lessonFromState);

          updateDownloadPath(
            dlPath
              ? `${dlPath}&sessionKey=${_.get(
                  props.AppStore,
                  "state.sessionData.sessionKey"
                )}`
              : dlPath
          );
          updateHasChecked(true);

          return;
        }

        _loadLesson({
          pdfLessonID: _.get(props, "discMini.pdfLessonID", 0),
          onError: (errMsg) => {
            updateErrorMsg(errMsg);
            updateHasChecked(true);
          },
          onSuccess: (pdfLesson) => {
            const newDownloadPath = pullDownloadPathFromLesson(pdfLesson);

            updateDownloadPath(
              newDownloadPath
                ? `${newDownloadPath}&sessionKey=${_.get(
                    props.AppStore,
                    "state.sessionData.sessionKey"
                  )}`
                : newDownloadPath
            );
            updateHasChecked(true);

            if (props.AppStore.dispatch) {
              props.AppStore.dispatch({
                payload: {
                  pdfLesson,
                },
                type: "PDF_LESSON_LOADED",
              });
            }
          },
        });
      } else {
        updateDownloadPath("");
        updateHasChecked(true);
      }
    }
  }, [props.discMini]);

  if (errorMsg) {
    return <p className="">{errorMsg}</p>;
  }

  if (!downloadPath) {
    if (!hasChecked) {
      return <span>Loading...</span>;
    }

    return null;
  }

  return (
    <DISCValueRender
      className="meta-sidepane-admin-controls-action-link-cont-wrapper"
      isCompareValue={false}
      targetKey="pdfLessonID"
      discMiniProfile={props.discMini}
    >
      <a className={cx(props.className)} href={downloadPath} target="_blank">
        <Button
          className="meta-sidepane-admin-controls-action-button control-full-width-button controls-action-button-set-password"
          iconProps={{
            className: "download-link-icon",
            size: 10,
          }}
          onClick={() => {}}
          iconLeft="download"
          shape="square"
          type="hollow"
          value="Download PDF Report"
        />
        {/* <Icon className="download-link-icon" size={10} icon="download" />
        Download PDF Report{" "} */}
      </a>
    </DISCValueRender>
  );
};

async function _loadLesson({
  pdfLessonID,
  onError,
  onSuccess,
}: {
  pdfLessonID: number;
  onError: (errorMsg: string) => void;
  onSuccess: (lesson: any) => void;
}) {
  try {
    const pdfResp = await TytoCalls.Lesson.get({ lessonID: pdfLessonID });

    onSuccess(pdfResp.lesson);
  } catch (err) {
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(err, "msg", _.get(err, "technical", "Error Occurred."));

    onError(`${errorMsg}`);
  }
}

function pullDownloadPathFromLesson(lesson: any) {
  if (!lesson || !lesson.assets) {
    return "";
  }

  const originalEncodings = lesson.assets
    .map((asset: any) =>
      _.get(asset, "encodings", []).find(
        (enc: any) => enc.encodingType === "ocORIGINAL"
      )
    )
    .filter((enc: any) => !!enc);

  const pathURLs: string[] = (originalEncodings || []).map(
    (enc: any) => enc.pathURL
  );

  const firstPath = pathURLs[0] || "";

  return firstPath ? `https://${URL_BASE}${firstPath}` : "";
}
