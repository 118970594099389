/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { AppStoreProps } from "../../../../data/stores/AppStore";
import { Tyto } from "../../../../typings/tyto";
import TytoCalls from "../../../../data/tyto/";
import { setSessionData } from "../../../../data/storage/session";
import { PICK_SESSION_TARGET_ID_KEY } from "../../../../data/constants/";

interface Props {
  AppStore: AppStoreProps;
  impersonating: boolean;
  onPersonSelect: (userID: number) => void;
  person: Tyto.AdvancedPerson;
  toggleImpersonating: (newVal: boolean) => void;
}

export default ({
  AppStore,
  impersonating,
  onPersonSelect,
  person,
  toggleImpersonating,
}: Props) => {
  const [errorMsg, updateErrorMsg] = React.useState("");
  const [fullName, updateFullName] = React.useState(() => {
    const { givenName, familyName } = person;

    return `${givenName}${givenName && familyName && " "}${familyName}`;
  });

  React.useEffect(() => {
    const { givenName, familyName } = person;

    const newFullName = `${givenName}${
      givenName && familyName && " "
    }${familyName}`;

    updateFullName(newFullName);
  }, [person]);

  return (
    <li className="modals-impersonatesearch-modal-inner-search-results-list-item">
      <button
        className={cx(
          "modals-impersonatesearch-modal-inner-search-results-list-item-button",
          impersonating && "starting-impersonationg-session"
        )}
        disabled={impersonating}
        onClick={() => {
          toggleImpersonating(true);

          impersonateUser({
            AppStore,
            person,
            onError: (errorMsg) => {
              updateErrorMsg(errorMsg);
              toggleImpersonating(false);
            },
            onSuccess: () => {
              toggleImpersonating(false);
              onPersonSelect(person.userID);
            },
          });
        }}
      >
        <span className="modals-impersonatesearch-modal-inner-search-results-list-item-name">
          {fullName}
        </span>
        <span className="modals-impersonatesearch-modal-inner-search-results-list-item-domainname">
          {person.domainName}
        </span>
      </button>

      {errorMsg && (
        <p className="modals-impersonatesearch-modal-inner-search-results-list-item-error-msg">
          {errorMsg}
        </p>
      )}
    </li>
  );
};

async function impersonateUser({
  AppStore,
  person,
  onError,
  onSuccess,
}: {
  AppStore: AppStoreProps;
  person: Tyto.AdvancedPerson;
  onError: (errorMsg: string) => void;
  onSuccess: () => void;
}) {
  try {
    // TODO: Impersonate User on click
    // * NOTE: Make sure keepAlive time is lower than usual session: maybe 60 minutes?
    const resp: any = await TytoCalls.AccountSession.post({
      personID: person.userID,
      timeOutMinutes: 60,
    });

    if (!resp.newSession || !resp.accountSession) {
      onError("Proper information not returns. Could not impersonate.");
      return;
    }

    const impersonationSession = {
      ...(resp.newSession || {}),
      ...(resp.accountSession || {}),
    };

    const userID = _.get(impersonationSession, "userID", 0);

    if (userID) {
      setSessionData(impersonationSession);

      window.open(
        `/account-selection?${PICK_SESSION_TARGET_ID_KEY}=${userID}&redirect=`
      );

      onSuccess();
    }

    debugger;
    // if (AppStore.dispatch) {
    //     AppStore.dispatch({
    //         callback: () => {
    //             onSuccess();
    //         },
    //         payload: impersonationSession,
    //         type: "USER_LOGGED_IN"
    //     });
    // }
  } catch (err) {
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(
            err,
            "msg",
            _.get(
              err,
              "technical",
              "Error occurred, could not create impersonation session"
            )
          );

    onError(errorMsg);
  }
}
