import * as _ from "lodash";
import { useQuery } from "@tanstack/react-query";

import { REACT_QUERY_KEYS } from "../../constants/";
import API from "../../tyto/";

export function useDISCVendorForTake({
  isEnabled = true,
}: {
  isEnabled?: boolean;
}) {
  const query = useQuery({
    queryKey: [REACT_QUERY_KEYS.DISC_VENDOR_FOR_TAKE],
    queryFn: async () => {
      const data = await API.DISC.Vendor.ForTake.get({});

      return data;
    },
    retry: 2,
    enabled: !!isEnabled,
  });

  return {
    ...query,
    eagerData: query.data,
  };
}
