import { ENDPOINT_DISC_247_FIND_SELF_ASSESSMENTS } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_DISC_247_FIND_SELF_ASSESSMENTS;

export const DISC247FindSelfAssessments = {
  get(
    params: Endpoints.Tyto.DISC247.FindSelfAssessments.Get.Parameters,
    callOpts?: CallOpts
  ) {
    return callWrapper(
      "get",
      endpoint,
      params || {},
      callOpts
    ) as Promise<Endpoints.Tyto.DISC247.FindSelfAssessments.Get.Response>;
  },
};
