import { ENDPOINT_DOMAIN } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

import { Tyto } from "../../typings/tyto";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_DOMAIN;

export const Domain = {
  get(params: Endpoints.Tyto.Domain.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      domain: Tyto.Domain;
      session: SessionData;
    }>;
  },
  post(params: Endpoints.Tyto.Domain.PostParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      recordsAffected: number;
      domainID: number;
      session: SessionData;
    }>;
  },
  put(params: Endpoints.Tyto.Domain.PutParameters, callOpts?: CallOpts) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      recordsAffected: number;
      session: SessionData;
    }>;
  },
};
