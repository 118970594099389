/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import TytoCalls from "../../../data/tyto/";
import { Input, Button } from "../../../components/common/";

import "./ForgotPassword.scss";

interface Props {
  cancel: () => void;
}

export default (props: Props) => {
  const [emailSent, updateEmailSent] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [email, updateEmail] = React.useState("");
  const [sending, updateSending] = React.useState(false);

  return (
    <section className="logon-interface">
      <div className="logon-cont logon-forgotpassword-cont">
        <h1 className="logon-title extra-space title-bold">Account Recovery</h1>

        <p className="logon-join-text">
          Please supply the email address you account is linked to so we can
          send you a link to reset your password
        </p>

        <Input
          autofocus={true}
          containerClassName="logon-sendresetlink-input-wrapper"
          disabled={sending || emailSent}
          onChange={(newVal) => updateEmail(newVal)}
          onEnter={() => {
            if (!email) {
              return;
            }

            updateSending(true);
            updateError("");

            sendEmail({
              email,
              onSuccess: () => {
                updateEmailSent(true);
                updateSending(false);
              },
              onError: (errMsg) => {
                updateError(`${errMsg}`);
                updateSending(false);
              },
            });
          }}
          name="email"
          placeholder="Account Email Address"
          value={email}
        />

        {emailSent && (
          <p className="logon-sendresetlink-reqeusted-msg">
            An email has been successfully requested! It may take a few minutes
            to receive the email.
          </p>
        )}

        {error && <p className="logon-sendresetlink-error-msg">{error}</p>}

        <div className="logon-sendresetlink-buttons-cont">
          <Button
            className="logon-sendresetlink-button"
            disabled={sending}
            onClick={props.cancel}
            shape="square"
            type="hollow"
            iconLeft="angle-left"
            iconProps={{
              size: 15,
            }}
            value="Back"
          />

          <Button
            className="logon-sendresetlink-button"
            disabled={!email || sending || emailSent}
            onClick={() => {
              updateSending(true);
              updateError("");

              sendEmail({
                email,
                onSuccess: () => {
                  updateEmailSent(true);
                  updateSending(false);
                },
                onError: (errMsg) => {
                  updateError(`${errMsg}`);
                  updateSending(false);
                },
              });
            }}
            shape="square"
            type="color"
            value={sendButtonText({ emailSent, sending })}
          />
        </div>
      </div>
    </section>
  );
};

const sendButtonText = ({
  emailSent,
  sending,
}: {
  emailSent: boolean;
  sending: boolean;
}) => {
  if (emailSent) {
    return "Email Requested";
  }

  return sending ? "Requesting Email..." : "Request Email";
};

const sendEmail = async ({
  email,
  onError,
  onSuccess,
}: {
  email: string;
  onError: (msg: string) => void;
  onSuccess: () => void;
}) => {
  if (!email) {
    onError("No email address supplied");
    return;
  }

  try {
    const resp = await TytoCalls.Login.Recover.TeamTools.post({
      emailAddress: email,
    });

    onSuccess();

    return;
  } catch (err) {
    console.log("ERROR: ", err);
    const errStatus: number = _.get(err, "sts", 0);
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Error occurred, could not send email.");

    if (!errStatus) {
      onError(`${errorMsg}`);
    }

    switch (errStatus) {
      case -301:
        return onError("Supplied Email is Incorrect");
      default:
        onError(`${errorMsg}`);
    }

    return;
  }
};
