/*
 * Component Description
 */
import * as React from "react";

import { Nav } from "../../components/meta/";
import {
  StoreContext as AppStoreContext,
  StoreState as AppStoreState,
  AppStoreDispatch,
} from "../../data/stores/AppStore";
import {
  StoreContext as AdminStoreContext,
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "./data/stores/AdminStore";
import { SessionHOC } from "../../components/hoc/";
import { loadTeamStructure } from "./data/utils/";

import AdminInterface from "./team-manage";
import ManagePeople from "./people-manage";

import "./style.scss";

export type SubIntefaceKey = "default" | "manage-people";

interface Props {
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);
  let AdminStore = React.useContext(AdminStoreContext);
  const [subInterfaceKey, updateSubInterfaceKey] =
    React.useState<SubIntefaceKey>("default");

  React.useEffect(() => {
    loadTeamStructure({ AppStore, AdminStore, loadConfigs: false });
  }, []);

  const storeItemsReady =
    !!AppStore.state &&
    !!AppStore.dispatch &&
    !!AdminStore.state &&
    !!AdminStore.dispatch;

  return (
    <SessionHOC path={props.path} uri={props.uri}>
      <section className="home-interface">
        <Nav path={props.path} uri={props.uri} />

        {storeItemsReady ? (
          renderSubInterface(subInterfaceKey, updateSubInterfaceKey, {
            AppStoreDispatch: AppStore.dispatch as AppStoreDispatch,
            AdminStoreDispatch: AdminStore.dispatch as AdminStoreDispatch,
            AppStoreState: AppStore.state as AppStoreState,
            AdminStoreState: AdminStore.state as AdminStoreState,
          })
        ) : (
          <h1>Configuring...</h1>
        )}
      </section>
    </SessionHOC>
  );
};

const renderSubInterface = (
  subInterface: SubIntefaceKey,
  updateSubInterfaceKey: (newKey: SubIntefaceKey) => void,
  stores: {
    AppStoreDispatch: AppStoreDispatch;
    AdminStoreDispatch: AdminStoreDispatch;
    AppStoreState: AppStoreState;
    AdminStoreState: AdminStoreState;
  }
) => {
  switch (subInterface) {
    case "manage-people":
      return (
        <ManagePeople
          subInterface={subInterface}
          updateSubInterfaceKey={updateSubInterfaceKey}
          {...stores}
        />
      );
    case "default":
    default:
      return (
        <AdminInterface
          subInterface={subInterface}
          updateSubInterfaceKey={updateSubInterfaceKey}
          {...stores}
        />
      );
  }
};
