/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import pkg from "../../../../package.json";
import TytoCalls from "../../../data/tyto/";
import { AppStoreProps } from "../../../data/stores/AppStore";
import { SessionData } from "../../../typings";
import { setTempSessionData } from "../../../data/storage/session";

const appBrand = "team-tools-webapp";
const appVersion = _.get(pkg, "version", "");

interface Props {
  store: AppStoreProps;
  location?: any;
  path?: string;
  tempSessionKey?: string;
  uri?: string;
}

export default (props: Props) => {
  const [error, updateError] = React.useState("");
  const [sessionKeyGrabAttemped, updateSessionKeyGrabAttempted] =
    React.useState(false);
  const [tempSessionKey, updateTempSessionKey] = React.useState<string>(
    props.tempSessionKey || ""
  );
  const [tempSessionData, updateTempSessionData] = React.useState(null);
  const [sessionData, updateSessionData] = React.useState(
    _.get(props, "store.state.sessionData", undefined)
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    updateTempSessionKey(props.tempSessionKey || "");
    updateSessionKeyGrabAttempted(true);
  }, []);

  React.useEffect(() => {
    if (tempSessionKey) {
      //   checkCredentials({
      //     AppStore: props.store,
      //     tempSessionKey,
      //     onError: msg => updateError(msg)
      //   });
      createNewSession({
        AppStore: props.store,
        tempSessionKey,
        onError: (msg) => updateError(msg),
      });
    }
  }, [tempSessionKey]);

  React.useEffect(() => {
    if (!sessionData) return;

    navigate("/");
  }, [sessionData]);

  // * If sessionData (logged into another account) redirect
  if (sessionData) {
    return <div>Redirecting...</div>;
  }

  // * If
  if (!tempSessionData && !props.tempSessionKey && !sessionKeyGrabAttemped) {
    return (
      <section className="logon-cont login-join-main-cont">
        <h2 className="logon-title title-bold">
          Join <span style={{ opacity: 0.4 }}>Failed</span>
        </h2>

        <p style={error ? { color: "red" } : undefined}>
          {error ? (
            error
          ) : (
            <span>Invitation Expired or otherwise unusable.</span>
          )}
        </p>
      </section>
    );
  }

  return (
    <section className="logon-cont login-join-main-cont">
      <h2 className="logon-title title-bold">
        Join {error && <span>Failed</span>}
      </h2>

      {error ? (
        <>
          <h3
            className="logon-join-error-msg title-bold"
            style={{ fontSize: "24px" }}
          >
            Hmm...
          </h3>
          <p className="logon-join-error-msg">{error}</p>
          <p className="logon-join-text">
            Please reach out to your trainer for a new invitation.
          </p>
        </>
      ) : (
        <p className="logon-join-text">
          Welcome to TeamTools! Give us a moment while we validate your
          assessment key and then we'll get you started!
        </p>
      )}
    </section>
  );
};

// const checkCredentials = async ({
//   AppStore,
//   tempSessionKey,
//   onError,
// }: {
//   AppStore?: AppStoreProps;
//   tempSessionKey: string;
//   onError: (msg: string) => void;
// }) => {
//   try {
//     // * [1] Make Sure passes sessionKey is even valid/load sessionData for sessionKey
//     const sessionResp: any = await TytoCalls.AccountSession.get(
//       { sessionKey: tempSessionKey },
//       { omitSessionKey: true }
//     );
//     const tempSessionUserID: number = _.get(sessionResp, "session.userID", 0);

//     // * If no userID in response then it can't be used for anything - return;
//     if (!tempSessionUserID) {
//       return;
//     }

//     debugger;

//     if (AppStore && AppStore.dispatch) {
//       setTempSessionData(sessionResp.session as SessionData);

//       AppStore.dispatch({
//         payload: {
//           tempSessionData: sessionResp.session,
//         },
//         type: "SET_TEMP_SESSION_DATA",
//       });
//     }

//     // * A valud local matching session does not exist, so lets create one with the tempSessionKey!
//     createNewSession({ AppStore, tempSessionKey, onError });
//   } catch (err) {
//     console.warn(
//       "Something went wrong attempting to create a new session from the tempSessionKey"
//     );
//     onError(
//       typeof err === "string"
//         ? err
//         : _.get(err, "error.msg", "We were unable to authenticate you.")
//     );
//   }
// };

const createNewSession = async ({
  AppStore,
  tempSessionKey,
  onError,
}: {
  AppStore?: AppStoreProps;
  tempSessionKey: string;
  onError: (msg: string) => void;
}) => {
  try {
    const newSessionResp: any =
      await TytoCalls.TeamToolsInvite.TempSession.post(
        { sessionKey: tempSessionKey, appBrand, appVersion },
        { omitSessionKey: true }
      );

    // * resp.newSession does not contain sessionKey, as it lives in resp.accountSession.sessionKey
    const newSessionKey = _.get(
      newSessionResp,
      "accountSession.sessionKey",
      undefined
    );

    const createdSessionData: SessionData = {
      sessionKey: newSessionKey,
      ...(newSessionResp.newSession || {}),
      tempSessionKey,
    };

    if (AppStore && AppStore.dispatch) {
      setTempSessionData(createdSessionData);

      AppStore.dispatch({
        payload: {
          tempSessionData: createdSessionData,
          // tempSessionData: newSessionResp.newSession,
          tempSessionKey,
        },
        type: "SET_TEMP_SESSION_DATA",
      });
    }

    const newlyCreatedSessionKey = _.get(createdSessionData, "sessionKey", "");
    // const newlyCreatedSessionKey = _.get(
    //   newSessionResp,
    //   "newSession.sessionKey",
    //   ""
    // );

    if (newlyCreatedSessionKey) {
      const sessionResp: any = await TytoCalls.AccountSession.get(
        { sessionKey: newlyCreatedSessionKey },
        { omitSessionKey: true }
      );

      if (sessionResp && sessionResp.session && AppStore && AppStore.dispatch) {
        AppStore.dispatch({
          payload: {
            sessionKey: newlyCreatedSessionKey,
            ...sessionResp.session,
          },
          type: "USER_LOGGED_IN",
        });
      }
    } else {
      onError("Could not Use Assessment Key.");
    }
  } catch (err) {
    onError("Could not Use Assessment Key.");
    console.warn("Failed to create new session using Outlook Session Key");
  }
};
