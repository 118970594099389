/*
 * Component Description
 */
import * as React from "react";
// import cx from "classnames";
import * as _ from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import QueryString from "query-string";
import { PICK_SESSION_TARGET_ID_KEY } from "../../../data/constants/";

import { AppStoreProps } from "../../../data/stores/AppStore";
import {
  getSessionsAsArray,
  removeSessionData,
} from "../../../data/storage/session";
import { SessionList } from "../../../components/modals/components/";

// import { Icon } from "../../../components/common/";
import { SessionData } from "../../../typings";

interface Props {
  location?: any;
  loginName?: string;
  store?: AppStoreProps;
  uri?: string;
}

export default (props: Props) => {
  const [storedSessions, updateStoredSessions] = React.useState(
    getSessionsAsArray()
  );
  // const [targetUserID, updateTargetUserID] = React.useState<number | undefined>(() => {
  //   const redirectParams: string = _.get(props.location, "search", "");
  //   const parsedParamsObj: { [x: string]: string } = QueryString.parse(
  //     redirectParams
  //   );

  //   return _.get(parsedParamsObj, "targetID", undefined);
  // });
  const [sessionData, updateSessionData] = React.useState(
    _.get(props, "store.state.sessionData", undefined)
  );

  const navigate = useNavigate();
  const location = useLocation();

  // * EFFECTS ======================================
  // * [E-1] - Check for targetID and look for matching account in storedSessions
  React.useEffect(() => {
    const redirectParams: string = _.get(location, "search", "");
    const parsedParamsObj: { [x: string]: string } =
      QueryString.parse(redirectParams);

    debugger;

    const targetID = parseInt(
      `${_.get(parsedParamsObj, PICK_SESSION_TARGET_ID_KEY, undefined)}`
    );

    if (!targetID || !!Number.isNaN(targetID)) {
      console.log("targetID is parsed to be falsey: ", targetID);
      return;
    }

    const matchingStoredSession = (storedSessions || []).find(
      (storedSession) => storedSession.userID === targetID
    );

    if (!matchingStoredSession) {
      console.log(`No matching storedSession for ${targetID}`);
      return;
    }

    if (props.store && props.store.dispatch) {
      props.store &&
        props.store.dispatch({
          payload: matchingStoredSession,
          type: "USER_LOGGED_IN",
        });
    }
  }, []);

  // * [E-2] - Watch for sessionData change in Store
  React.useEffect(() => {
    updateSessionData(_.get(props, "store.state.sessionData", undefined));
  }, [props.store]);

  React.useEffect(() => {
    if (!sessionData) return;

    const redirectParam: string = _.get(props.location, "search", "");
    const parsedValue = /\?redirect\=/g.test(redirectParam)
      ? redirectParam.replace("?redirect=", "")
      : "/";

    navigate(parsedValue);
  }, [sessionData]);

  React.useEffect(() => {
    if (!Array.isArray(storedSessions)) return;
    if (storedSessions.length) return;

    const redirectParam: string = _.get(props.location, "search", "");

    navigate(`/login${redirectParam}`);
  }, [storedSessions]);
  // * END OF EFFECTS ===============================
  // * ==============================================

  if (sessionData) {
    return <div>Redirecting...</div>;
  }

  if (Array.isArray(storedSessions) && !storedSessions.length) {
    return <div>Redirecting...</div>;
  }

  if (!Array.isArray(storedSessions)) {
    return <h3>Error</h3>;
  }

  return (
    <section className="logon-interface">
      <div className="logon-pickaccount-cont">
        <h1 className="logon-pickaccount-title title-bold">Pick Session</h1>

        <SessionList
          storedSessions={storedSessions}
          onSessionDelete={(storedSession) =>
            removeSession(storedSession, storedSessions, updateStoredSessions)
          }
          onSessionSelect={(storedSession) => {
            if (props.store && props.store.dispatch) {
              props.store &&
                props.store.dispatch({
                  payload: storedSession,
                  type: "USER_LOGGED_IN",
                });
            }
          }}
        />
        {/* <ul className="logon-sessions-list">
          {storedSessions.map((storedSession, curIdx) => (
            <li
              className={cx(
                "logon-sessions-list-item",
                !!curIdx && "not-first-session-list-item"
              )}
              key={storedSession.userID}
            >
              <button
                className="logon-sessions-list-item-button"
                onClick={() => {
                  if (props.store && props.store.dispatch) {
                    props.store &&
                      props.store.dispatch({
                        payload: storedSession,
                        type: "USER_LOGGED_IN"
                      });
                  }
                }}
              >
                {storedSession.userName}
                <span
                  style={{ paddingLeft: "8px", fontSize: "10px", opacity: 0.4 }}
                >
                  {storedSession.domainID}
                </span>
              </button>

              <Icon
                className="logon-sessions-list-item-delete-button"
                isButton={true}
                icon="trash-alt"
                onClick={() =>
                  removeSession(
                    storedSession,
                    storedSessions,
                    updateStoredSessions
                  )
                }
                size={15}
              />
            </li>
          ))}
        </ul> */}
      </div>
    </section>
  );
};

const removeSession = (
  sessionData: SessionData,
  localSessions: SessionData[],
  updateLocalStoredSessions: (sessions: SessionData[]) => void
) => {
  if (!sessionData.userID) {
    return;
  }

  removeSessionData(sessionData.userID);

  const updatedLocalCopy = localSessions.filter(
    (localSession) => localSession.userID !== sessionData.userID
  );

  if (updateLocalStoredSessions) {
    updateLocalStoredSessions(updatedLocalCopy);
  }
};
