/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import TytoCalls from "../../../data/tyto/";
import { Button, Checkbox, Input, TextButton } from "../../common/";
import { Tyto } from "../../../typings/tyto";

interface Props {
  discProfileMini?: Tyto.DISCProfileMini;
  isOpen: boolean;
  onDismiss: () => void;
  title?: string;
  userID: number;
}

export default (props: Props) => {
  const [headline] = React.useState(() => {
    if (props.title) {
      return props.title;
    }

    const userName = _.get(props, "discProfileMini.personName", "user").split(
      " "
    )[0];

    return `Set ${userName}'s password`;
  });
  const [newPassword, updateNewPassword] = React.useState("");
  const [forcePasswordChange, updateForcePasswordChange] = React.useState(true);
  const [error, updateError] = React.useState("");
  const [passwordReset, updateReset] = React.useState(false);
  const [settingPassword, updateSettingPassword] = React.useState(false);

  return (
    <div className="modals-setpassword-modal-inner-cont">
      <h2 className="modals-setpassword-title title-bold">{headline}</h2>

      <p className="modals-setpassword-description-text">
        Setting a password for a user will invalidate all currently existing
        sessions they have. Additionally,{" "}
        <i>
          <b>
            they will not receive an email informing them of their new password.
          </b>
        </i>{" "}
        It is your responsibility to communicate with the user and inform them
        of their new password in whatever medium is most appropriate.
      </p>

      <Input
        autofocus={true}
        containerClassName="modals-setpassword-input-wrapper"
        disabled={settingPassword}
        onChange={(newVal) => updateNewPassword(newVal)}
        onEnter={() => {
          updateSettingPassword(true);
          updateError("");
          updateReset(false);

          setPassword({
            changePassword: forcePasswordChange,
            memberID: props.userID,
            password: newPassword,
            onError: (errMsg) => {
              updateError(`${errMsg}`);
              updateSettingPassword(false);
            },
            onSuccess: () => {
              updateSettingPassword(false);
              updateReset(true);
            },
          });
        }}
        placeholder="New Password"
        type="password"
        value={newPassword}
      />

      <div className="modals-setpassword-checkbox-cont">
        <Checkbox
          className="modals-setpassword-checkbox"
          disabled={settingPassword}
          onCheck={(nextValue) => updateForcePasswordChange(nextValue)}
          checked={forcePasswordChange}
          size={16}
        />

        <TextButton
          className="modals-setpassword-checkbox-textbutton"
          disabled={settingPassword}
          onClick={() => updateForcePasswordChange(!forcePasswordChange)}
          value="Prompt User to Set a new Password next time they login"
        />
      </div>

      {error && <p className="modals-setpassword-error-msg">{error}</p>}

      {passwordReset && (
        <p className="modals-setpassword-success-msg">
          Password was successfully reset
        </p>
      )}

      <div className="modals-setpassword-buttons-cont">
        <Button
          className="modals-setpassword-button"
          disabled={settingPassword}
          onClick={props.onDismiss}
          shape="square"
          type="hollow"
          value={passwordReset ? "Close" : "Cancel"}
        />

        <Button
          className="modals-setpassword-button"
          disabled={settingPassword || !newPassword}
          onClick={() => {
            updateSettingPassword(true);
            updateError("");
            updateReset(false);

            setPassword({
              changePassword: forcePasswordChange,
              memberID: props.userID,
              password: newPassword,
              onError: (errMsg) => {
                updateError(`${errMsg}`);
                updateSettingPassword(false);
              },
              onSuccess: () => {
                updateSettingPassword(false);
                updateReset(true);
              },
            });
          }}
          shape="square"
          type="color"
          value={settingPassword ? "Setting Password..." : "Set Password"}
        />
      </div>
    </div>
  );
};

const setPassword = async ({
  changePassword,
  memberID,
  password,
  onError,
  onSuccess,
}: {
  changePassword: boolean;
  memberID: number;
  onError: (errMsg: string) => void;
  onSuccess: () => void;
  password: string;
}) => {
  if (!memberID) {
    onError("User Identifier was not found, could not set password.");

    return;
  } else if (!password) {
    onError("No password supplied, could not set password.");

    return;
  }

  try {
    await TytoCalls.Person.Password.put({
      changePassword,
      memberID,
      password,
    });

    onSuccess();

    return;
  } catch (err) {
    const errMsg =
      typeof err === "string"
        ? err
        : _.get(
            err,
            "technical",
            "An error occurred. New password was not set."
          );

    onError(errMsg);
  }
};
