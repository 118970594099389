/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import QueryString from "query-string";
// @ts-ignore
import pkg from "../../../../package.json";

import { AppStoreProps } from "../../../data/stores/AppStore";
import TytoCalls from "../../../data/tyto/";
import { getSessionData } from "../../../data/storage/session";
import { SessionData } from "../../../typings";

const appBrand = "team-tools-webapp";
const appVersion = _.get(pkg, "version", "");

interface Props {
  location?: Location;
  path?: string;
  store?: AppStoreProps;
  uri?: string;
}

export default (props: Props) => {
  const [sessionKeyGrabAttemped, updateSessionKeyGrabAttempted] =
    React.useState(false);
  const [outsideSessionKey, updateOutsideSessionKey] =
    React.useState<string>("");
  const [sessionData] = React.useState(
    _.get(props, "store.state.sessionData", undefined)
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    const redirectParams = props.location?.search || "";
    // const redirectParams: string = _.get(props.location, "search", "");
    const parsedParamsObj: { [x: string]: string } = redirectParams
      ? QueryString.parse(redirectParams)
      : {};

    const newOutsideSessionKey =
      (parsedParamsObj && parsedParamsObj.outsideSessionKey) || "";

    updateOutsideSessionKey(newOutsideSessionKey);

    updateSessionKeyGrabAttempted(true);
  }, []);

  React.useEffect(() => {
    if (outsideSessionKey) {
      checkCredentials({ AppStore: props.store, outsideSessionKey });
    }
  }, [outsideSessionKey]);

  React.useEffect(() => {
    if (outsideSessionKey) return;
    if (!sessionKeyGrabAttemped) return;

    const redirectParams: string = _.get(props.location, "search", "");
    const parsedParamsObj: { [x: string]: string } =
      QueryString.parse(redirectParams);

    navigate(
      `/login${
        parsedParamsObj && parsedParamsObj.redirect
          ? `?redirect=${parsedParamsObj.redirect}`
          : ""
      }`
    );
  }, [outsideSessionKey, sessionKeyGrabAttemped]);

  React.useEffect(() => {
    if (!sessionData) return;

    const redirectParams: string = _.get(props.location, "search", "");
    const parsedParamsObj: { [x: string]: string } =
      QueryString.parse(redirectParams);

    navigate((parsedParamsObj && parsedParamsObj.redirect) || "/");
  }, [sessionData]);

  if (!outsideSessionKey && sessionKeyGrabAttemped) {
    return <div>Redirecting...</div>;
  }

  if (sessionData) {
    return <div>Redirecting...</div>;
  }

  return (
    <div className="logon-interface">
      <h1 className="title-bold">Hashing out your Credentials.</h1>
    </div>
  );
};

export const checkCredentials = async ({
  AppStore,
  outsideSessionKey,
  onFail,
}: {
  AppStore?: AppStoreProps;
  outsideSessionKey: string;
  onFail?: () => void;
}) => {
  try {
    // * [1] Make Sure passes sessionKey is even valid/load sessionData for sessionKey
    const sessionResp: any = await TytoCalls.AccountSession.get(
      { sessionKey: outsideSessionKey },
      { omitSessionKey: true }
    );
    const outsideSessionUserID: number = _.get(
      sessionResp,
      "session.userID",
      0
    );
    const outsideSessionDomainID: number = _.get(
      sessionResp,
      "session.domainID",
      0
    );

    debugger;

    // * If no userID in response then it can't be used for anything - return;
    if (!outsideSessionUserID) {
      if (onFail) {
        onFail();
      }

      return;
    }

    // * Attempt to find if session for same userID already exists locally, and if so check it is valid
    const validMatchingLocalSession = await getValidMatchingLocalSession({
      outsideSessionUserID,
      outsideSessionDomainID,
    });

    // * A valid local matching session object exists, so we're going to use it!
    if (
      validMatchingLocalSession &&
      validMatchingLocalSession.sessionKey &&
      AppStore &&
      AppStore.dispatch
    ) {
      AppStore.dispatch({
        payload: validMatchingLocalSession,
        type: "USER_LOGGED_IN",
      });
      // * A valud local matching session does not exist, so lets create one with the outsideSessionKey!
    } else {
      createNewSession({ AppStore, outsideSessionKey });
    }
  } catch (err) {
    console.warn(
      "Something went wrong attempting to create a new session from the outsideSessionKey"
    );
    debugger;
  }
};

const createNewSession = async ({
  AppStore,
  outsideSessionKey,
}: {
  AppStore?: AppStoreProps;
  outsideSessionKey: string;
}) => {
  try {
    const newSessionResp: any = await TytoCalls.AccountSession.post(
      { sessionKey: outsideSessionKey, appBrand, appVersion },
      { omitSessionKey: true }
    );

    const newlyCreatedSessionKey = _.get(
      newSessionResp,
      "accountSession.sessionKey",
      ""
    );

    if (newlyCreatedSessionKey) {
      const sessionResp: any = await TytoCalls.AccountSession.get(
        { sessionKey: newlyCreatedSessionKey },
        { omitSessionKey: true }
      );

      // * Handle new session GET resp that excludes sessionKey in resp.session
      if (sessionResp && sessionResp.session && AppStore && AppStore.dispatch) {
        const newSessionData =
          sessionResp && sessionResp.session
            ? {
                ...sessionResp.session,
                sessionKey: newlyCreatedSessionKey,
              }
            : undefined;

        AppStore.dispatch({
          payload: newSessionData,
          type: "USER_LOGGED_IN",
        });
      }
    }
  } catch (err) {
    debugger;
    console.warn("Failed to create new session using Outside Session Key");
  }
};

const getValidMatchingLocalSession = async ({
  outsideSessionDomainID,
  outsideSessionUserID,
}: {
  outsideSessionDomainID: number;
  outsideSessionUserID: number;
}): Promise<SessionData | undefined> => {
  try {
    const matchingLocalSessionData = getSessionData(outsideSessionUserID);

    if (
      _.get(matchingLocalSessionData, "domainID") !== outsideSessionDomainID
    ) {
      debugger;
      return undefined;
    }

    const matchingLocalSessionKey = _.get(
      matchingLocalSessionData,
      "sessionKey"
    );
    // const matchingLocalSessionKey = getSessionKey(outsideSessionUserID);

    if (matchingLocalSessionKey) {
      debugger;
      const matchingLocalSessionResp: any = await TytoCalls.AccountSession.get(
        { sessionKey: matchingLocalSessionKey },
        { omitSessionKey: true }
      );

      if (_.get(matchingLocalSessionResp, "session.userID")) {
        const matchingLocalSession = getSessionData(outsideSessionUserID);

        return matchingLocalSession;
      }
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
};

// * [1] Pull and update params for sessionKey
// *
// * [2] Hit AccountSession GET to ensure session is good and get sessionData for session
// *
// * [3] Use resp.session.userID to find existing session matching userID
// *
// * [4-A] If matching session exists, call AccounSession GET to ensure is good session still
// *
// * [4-B] If good use session
// *
// * [5] If Session is bad or session does not exist, call AccountSession POST to create session and use said session
