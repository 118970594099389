export const sendMessageTypes = {
  ERROR: "error",
  GET_USERS_CONNECTION_STATUS: "get-users-connections-status",
  MINIS_UPDATED: "mini-updated",
  WATCH_MINI: "watch-mini",
  WATCH_MULTIPLE: "watch-multiple",
  WATCH_INTERACTIVE: "watch-interactive",
  RESET_USER_WATCH_LISTS: "reset-user-watch-lists",
  // UPDATE_CONNECTION_USERID: "update-connection-userid",
  UPDATE_CONNECTION_USER_METADATA: "update-connection-user-metadata",
  UPDATE_LAST_ACTIVITY: "update-last-activity",
};
export const messageTypes = {
  MINI_CHANGED: "mini-changed",
  MULTIPLE_MINIS_CHANGED: "multiple-minis-changed",
  USER_ACTIVITY_CHANGED: "user-activity-changed",
  USER_EXCEPTION_PING: "user-exception-ping",
  USER_ACTION_CONFIRMATION: "user-action-confirmation",
  REQUESTED_USERS_CONNECTIONS_STATUS: "requested-users-connections-status",
};
