import TytoCalls from "../../../../data/tyto/";
// import { getSessionData } from "../../../../data/utils/";
import WebSocketClient from "../../../../data/websocket/";
import {
  getSessionData,
  getLoggedInUserID,
} from "../../../../data/storage/session";

import { Tyto } from "../../../../typings/tyto";

export type FormattedSectionAnswers = {
  itemID: number;
  options: {
    order: number;
    value: string;
  }[];
}[];

export async function commitAssessment(params: {
  discID: number;
  assessmentResultID: number;
}) {
  try {
    const reportCall: any = await TytoCalls.PeopleKeys.Report.get({
      ...params,
      pdfBehavior: "replaceIfNotFound",
      isClient: true,
    });

    return (reportCall && reportCall.peopleKeysResponse) || true;
  } catch (err) {
    return false;
  }
}

export function getQuestionKey(pageID: number, itemID: number) {
  return `${pageID}-${itemID}`;
}

export async function initializeDISCAssessment(): Promise<
  Tyto.PeopleKeys.InitializedData | undefined
> {
  try {
    const loggedInUserID = getLoggedInUserID();
    const sessionData = getSessionData(loggedInUserID);

    if (!sessionData || !sessionData.domainID) {
      console.warn(
        "Expected to find sessionData but none was found; cannot initialize Assessment."
      );
      return undefined;
    }

    const initializedData = await TytoCalls.PeopleKeys.Initialize.post({
      domainID: sessionData.domainID,
    });

    return initializedData as Tyto.PeopleKeys.InitializedData;
  } catch (err) {
    console.warn("An error occurred; cannot initialize Assessment.");
    return undefined;
  }
}

export async function loadAssessmentPages(): Promise<
  Tyto.PeopleKeys.Page[] | undefined
> {
  try {
    const pages = (await TytoCalls.PeopleKeys.Pages.get({})) as {
      pages: Tyto.PeopleKeys.Page[];
    };

    return pages ? pages.pages : undefined;
  } catch (err) {
    return undefined;
  }
}

export async function loadNextPage(
  resultID: number
): Promise<Tyto.PeopleKeys.Response | undefined> {
  if (!resultID) {
    return undefined;
  }

  try {
    const nextPage = await TytoCalls.PeopleKeys.NextPage.get({ resultID });

    return nextPage as Tyto.PeopleKeys.Response;
  } catch (err) {
    return undefined;
  }
}

export function pingWSAboutUser(userID?: number) {
  if (!userID) {
    console.log(
      "(::WEBSOCKET::) Ping about assessment starting for first time aborted because passed userID was falsey."
    );
    return;
  }
  WebSocketClient.announceUpdates([userID]);
}

export function formatAnswersForResponseSubmit(
  pageID: number,
  pageItems: Tyto.PeopleKeys.PageItem[],
  answers: { [x: string]: Tyto.PeopleKeys.PageItemContentOption[] }
) {
  const allSectionAnswers = pageItems.map((pageItem) => {
    const key = getQuestionKey(pageID, pageItem.item_id);
    const rawAnswerOptions = answers[key];
    const formattedAnswerOptions = Array.isArray(rawAnswerOptions)
      ? rawAnswerOptions.map((rawAnswerOption, curIdx) => ({
          order: curIdx + 1,
          value: rawAnswerOption.Value,
        }))
      : [];

    return {
      itemID: pageItem.item_id,
      options: formattedAnswerOptions,
    };
  });

  return allSectionAnswers;
}

export async function submitResponse(params: {
  resultID: number;
  pageID: number;
  response?: FormattedSectionAnswers;
}) {
  try {
    const response = await TytoCalls.PeopleKeys.Response.post(params);

    return true;
  } catch (err) {
    return false;
  }
}
