import { ENDPOINT_TEAM_TOOLS_CONFIG } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_TEAM_TOOLS_CONFIG;

export const TeamToolsConfig = {
  delete(
    params: Endpoints.Tyto.TeamTools.Config.DeleteParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("delete", endpoint, { ...params }, callOpts);
  },
  put(
    params: Endpoints.Tyto.TeamTools.Config.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("put", endpoint, { ...params }, callOpts);
  }
};
