/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext as GeneralStoreContext } from "../../../../data/stores/GeneralStore";
import TytoCalls from "../../../../data/tyto/";
import { Button } from "../../../../components/common/";
import { NewAccount } from "../../../../components/modals/";
import { Tyto } from "../../../../typings/tyto";

import "./CreateNewAccount.scss";

interface Props {
  curDomainID?: number;
  curDomain?: Tyto.Team;
  loggedInUserRoleID?: number;
  onAccountCreation: (newDomainID: number) => void;
}

export default (props: Props) => {
  let GeneralStore = React.useContext(GeneralStoreContext);

  const [showCreateAccountModal, updateShowCreateAccountModal] = React.useState(
    false
  );
  const [uploadURL, updateUploadURL] = React.useState(
    _.get(GeneralStore, "state.uploadURL", "")
  );
  const [uploadURLError, updateUploadURLError] = React.useState("");

  React.useEffect(() => {
    if (!_.get(GeneralStore, "state.uploadURL", "")) {
      loadConfigurationClient({
        onError: (errMsg) => updateUploadURLError(errMsg),
        onSuccess: (config) => {
          if (GeneralStore.dispatch) {
            GeneralStore.dispatch({
              payload: {
                configurationClient: config,
              },
              type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
            });
          }
        },
      });
    }
  }, []);

  React.useEffect(() => {
    const newUploadURL = _.get(GeneralStore, "state.uploadURL", "");

    if (!uploadURL && newUploadURL) {
      updateUploadURL(newUploadURL);
    }
  }, [_.get(GeneralStore, "state.uploadURL")]);

  const curDomainName = _.get(props, "curDomain.name", "");

  return (
    <div className="admin-create-account-cont">
      {uploadURLError ? (
        <p className="admin-create-account-uploadurl-errormsg">
          {uploadURLError}
        </p>
      ) : (
        <Button
          className="admin-create-account-button"
          disabled={!uploadURL || !props.curDomainID}
          iconLeft="plus"
          iconProps={{
            className: "admin-create-account-button-icon",
            size: 18,
          }}
          onClick={() => updateShowCreateAccountModal(true)}
          value={`Create New Account${
            curDomainName ? ` Inside ${curDomainName}` : ""
          }`}
        />
      )}

      <NewAccount
        isOpen={showCreateAccountModal}
        loggedInUserRoleID={props.loggedInUserRoleID}
        onDismiss={() => updateShowCreateAccountModal(false)}
        onAccountCreation={(newDomainID: number) => {
          // updateShowCreateAccountModal(false);
          props.onAccountCreation(newDomainID);
        }}
        parentDomain={props.curDomain}
        primaryElementID={props.curDomainID || 0}
        uploadURL={uploadURL}
      />
    </div>
  );
};

const loadConfigurationClient = async ({
  onError,
  onSuccess,
}: {
  onError: (errMsg: string) => void;
  onSuccess: (config: Tyto.Configuration.Client) => void;
}) => {
  try {
    const configurationClient = await TytoCalls.Configuration.Client.get({});

    onSuccess(configurationClient);
  } catch (err) {
    const errMsg =
      typeof err === "string"
        ? err
        : _.get(
            err,
            "msg",
            _.get(
              err,
              "technical",
              "Error Occurred retrieving an upload destination. Cannot Create a New Account"
            )
          );

    onError(errMsg);
  }
};
