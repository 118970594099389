import { ENDPOINT_R3_DISC_VENDOR_247_DISC_EXTENSION } from "../../constants/";
import { addParamsToURL, callWrapper, CallOpts } from "../utils";

import { DISCExtensionGetParams, DISCExtensionGetResponse } from "./types";

const endpoint = ENDPOINT_R3_DISC_VENDOR_247_DISC_EXTENSION;

export const DISC247Extension = {
  get(
    params: DISCExtensionGetParams & { suppliedPathURL: string },
    callOpts?: CallOpts
  ) {
    const { suppliedPathURL, ...restOfParams } = params;

    const pulledArgs = pullParamsFromSuppliedURL(suppliedPathURL);

    const allArgs: DISCExtensionGetParams = { ...pulledArgs, ...restOfParams };

    const mutatedURL = addParamsToURL(endpoint, allArgs);

    return callWrapper(
      "get",
      mutatedURL,
      { ...restOfParams },
      callOpts
    ) as Promise<DISCExtensionGetResponse>;
  },
};

function pullParamsFromSuppliedURL(
  suppliedURL: string
): DISCExtensionGetParams {
  const url = new URL(
    `${window.location.origin}${
      /^\//i.test(suppliedURL) ? "" : "/"
    }${suppliedURL}`
  );

  const params: any = {} as any;

  url.searchParams.forEach((value: any, key: any) => {
    params[key] = value;
  });

  return params;
}
