/*
 * Wrapper around Profile interface for triggering
 * check for and load of data.
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import TytoCalls from "../../data/tyto/";
import { SessionHOC } from "../../components/hoc/";
import { StoreContext as AppStoreContext } from "../../data/stores/AppStore";
import {
  StoreContext as GeneralStoreContext,
  GeneralStoreProps,
} from "../../data/stores/GeneralStore";
import { Message } from "../../components/common/";
import { Nav } from "../../components/meta/";

import {
  checkStateForOmittedID,
  pullCompareProfileFromState,
  pullProfileFromState,
  loadNewCompareProfile,
  loadNewProfile,
  loadTryybProfile,
} from "./utils/";

import Profile from "./Profile";

import "./style.scss";
import { Tyto } from "../../typings/tyto";

interface Props {
  //   backButton?: {
  //     value: string;
  //     onClick: () => void;
  //   };
  //   discCompareProfile?: Tyto.DISCCompareProfile;
  //   discMiniProfile?: Tyto.DISCProfileMini;
  //   userDISCMini?: Tyto.DISCProfileMini;
  path?: string;
  curTabName?: string;
  uri?: string;
  userID?: string;
}

export default (props: Props) => {
  let AppStore = React.useContext(AppStoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  const navigate = useNavigate();
  const { curTabName, userID } = useParamsData();

  const [prevInterfaceURI, updatePrevInterfaceURI] = React.useState<
    string | undefined
  >(() => {
    const locHist = _.get(GeneralStore, "state.history", []);

    return locHist.length > 1
      ? _.get(locHist, "[0].uri", undefined)
      : undefined;
  });
  const [profile, updateProfile] = React.useState(
    pullProfileFromState(AppStore, userID)
  );
  const [discCompareProfile, updateDiscCompareProfile] = React.useState(
    pullCompareProfileFromState(AppStore, userID)
  );
  const [hasLoadedProfile, updateHasLoadedProfile] = React.useState(
    checkStateForOmittedID(AppStore, userID)
  );

  // * [E-1] - If userId changes, check for it in Store, if not found attempt to load it.
  React.useEffect(() => {
    if (userID) {
      const newProfile = pullProfileFromState(AppStore, userID);
      updateProfile(newProfile);

      if (!newProfile && !checkStateForOmittedID(AppStore, userID)) {
        loadNewProfile(AppStore, userID);
      }

      const newCompareProfile = pullCompareProfileFromState(AppStore, userID);
      updateDiscCompareProfile(newCompareProfile);

      if (!newCompareProfile) {
        loadNewCompareProfile(AppStore, userID);
      }

      if (
        _.get(AppStore, `state.tryybProfiles[${userID}]`, undefined) ===
        undefined
      ) {
        loadTryybProfile({ userID: parseInt(`${userID}`), AppStore });
      }
    }
  }, [userID]);

  // * [E-2] - Watch for change in Store
  React.useEffect(() => {
    if (userID) {
      const newProfile = pullProfileFromState(AppStore, userID);
      const newCompareProfile = pullCompareProfileFromState(AppStore, userID);

      if (newProfile || checkStateForOmittedID(AppStore, userID)) {
        updateProfile(newProfile);
        updateHasLoadedProfile(true);
      }

      if (newCompareProfile) {
        updateDiscCompareProfile(newCompareProfile);
      }
    }
  }, [
    _.get(AppStore.state, "discCompareProfiles"),
    _.get(AppStore.state, "discMini"),
    _.get(AppStore.state, "discMiniNull"),
  ]);

  React.useEffect(() => {
    if (!_.get(GeneralStore, "uploadURL", "")) {
      loadTryybConfig({ GeneralStore });
    }

    const profile = pullProfileFromState(AppStore, userID);
    if (!profile && userID) {
      loadNewProfile(AppStore, userID);
    }
  }, []);
  // * ==============================================
  // * End of Effects ===============================
  // * ==============================================

  const localHistory = _.get(GeneralStore, "state.history", []);
  const loggedInUserID = _.get(AppStore, "state.loggedInUserID", 0);

  const profileIsNull = !profile && checkStateForOmittedID(AppStore, userID);

  return (
    <SessionHOC path={props.path} uri={props.uri}>
      <section className="home-interface">
        <Nav />

        {!userID ? (
          <Message value="No user identifier found." />
        ) : (
          <Profile
            AppStore={AppStore}
            backButton={
              prevInterfaceURI && !!window.history.back
                ? {
                    // onClick: () => window.history.back(),
                    onClick: () => navigate(prevInterfaceURI),
                    value: "Back",
                  }
                : {
                    onClick: () => navigate("/"),
                    value: "Your Primary Team",
                  }
            }
            discCompareProfile={discCompareProfile}
            discMiniProfile={profile}
            discMiniProfileIsNull={profileIsNull}
            hasLoadedProfile={hasLoadedProfile}
            loggedInUserID={loggedInUserID}
            curTabName={curTabName}
            tryybProfile={_.get(
              AppStore,
              `state.tryybProfiles[${userID}]`,
              undefined
            )}
            userDISCMini={_.get(AppStore, "state.userDISCMini")}
            userID={userID}
            uploadURL={_.get(GeneralStore, "state.uploadURL", "")}
          />
        )}
      </section>
    </SessionHOC>
  );

  function useParamsData() {
    const { userID: unsafeUserID, curTabName: unsafeCurTabName } = useParams();

    return {
      curTabName:
        typeof unsafeCurTabName === "string" ? unsafeCurTabName : undefined,
      userID: typeof unsafeUserID === "string" ? unsafeUserID : undefined,
    };
  }

  //   return !props.userID || !profile ? (
  //     <Message value="No user identifier found." />
  //   ) : (
  //     <Profile
  //       // backButton={}
  //       discCompareProfile={discCompareProfile}
  //       discMiniProfile={profile}
  //       userDISCMini={_.get(AppStore, "state.userDISCMini")}
  //     />
  //   );
};

const loadTryybConfig = ({
  GeneralStore,
}: {
  GeneralStore: GeneralStoreProps;
}) => {
  loadConfigurationClient({
    onError: (err) => {},
    onSuccess: (config: Tyto.Configuration.Client) => {
      if (GeneralStore && GeneralStore.dispatch) {
        GeneralStore.dispatch({
          payload: {
            configurationClient: config,
          },
          type: "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED",
        });
      }
    },
  });
};

const loadConfigurationClient = async ({
  onError,
  onSuccess,
}: {
  onError: (errMsg: string) => void;
  onSuccess: (config: Tyto.Configuration.Client) => void;
}) => {
  try {
    const configurationClient = await TytoCalls.Configuration.Client.get({});

    onSuccess(configurationClient);
  } catch (err) {
    onError(`${err}`);
  }
};
