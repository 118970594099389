/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import {
  StoreState as AppStoreState,
  AppStoreDispatch,
} from "../../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "../../data/stores/AdminStore";

import TeamTreeItem from "./TeamTreeItem";
import TeamTreeInnerList from "./TeamTreeInnerList";
import { getNonDomainChildTeams, teamShouldBeHidden } from "../utils/";

import "./TeamTreeTeam.scss";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AppStoreDispatch: AppStoreDispatch;
  AppStoreState: AppStoreState;
  chooseTeam: (teamID: number) => void;
  curDomainID: number;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  forceSelfOnly?: boolean;
  previewPersonID?: number;
  searchTerm?: string;
  selectedTeamID: number;
  stopCascade: boolean;
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
  teamSearchTerm?: string;
  teamSearchMatchPathIDs?: Set<number>;
  teamSearchMatchIDs?: Set<number>;
  depth: number;
}

export default (props: Props) => {
  const [showChildTeams, updateShowChildTeams] = React.useState(false);

  const { hideDescendants, hideTeam } = teamShouldBeHidden({
    searchTerm: props.teamSearchTerm,
    teamID: props.team.teamID,
    teamSearchMatchIDs: props.teamSearchMatchIDs,
    teamSearchMatchPathIDs: props.teamSearchMatchPathIDs,
  });

  if (hideTeam && hideDescendants) {
    return null;
  }

  const childTeams = getNonDomainChildTeams({
    team: props.team,
    teamsStructured: props.teamsStructured,
  });
  //   const childTeams = props.team.iPath
  //     ? props.teamsStructured[`${props.team.iPath}${props.team.teamID},`]
  //     : [];
  const hasChildTeams = Array.isArray(childTeams) && !!childTeams.length;

  return (
    <li className={cx("admin-teams-team-structure-childteams-list-item")}>
      <TeamTreeItem
        {...props}
        childTeams={childTeams}
        showChildTeams={!!props.teamSearchTerm || showChildTeams}
        hasChildTeams={hasChildTeams}
        updateShowChildTeams={() => updateShowChildTeams(!showChildTeams)}
      />

      {!hideDescendants &&
        hasChildTeams &&
        !props.stopCascade &&
        (!!props.teamSearchTerm || showChildTeams) && (
          <TeamTreeInnerList
            AdminStoreDispatch={props.AdminStoreDispatch}
            AppStoreDispatch={props.AppStoreDispatch}
            AppStoreState={props.AppStoreState}
            chooseTeam={props.chooseTeam}
            childTeams={childTeams}
            curDomainID={props.curDomainID}
            discMiniByTeam={props.discMiniByTeam}
            forceSelfOnly={props.forceSelfOnly}
            previewPersonID={props.previewPersonID}
            selectedTeamID={props.selectedTeamID}
            searchTerm={props.teamSearchTerm}
            parentTeam={props.team}
            teamsStructured={props.teamsStructured}
            teamSearchTerm={props.teamSearchTerm}
            teamSearchMatchPathIDs={props.teamSearchMatchPathIDs}
            teamSearchMatchIDs={props.teamSearchMatchIDs}
            depth={props.depth + 1}
          />
        )}
    </li>
  );
};
