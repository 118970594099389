/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Icon } from "../../common/";

import { Tyto } from "../../../typings/tyto";
import { UserOnlineInfo } from "../../../data/stores/AppStore";
import { Props as SuperSidePaneProps } from "./";
import TeamBreadCrumb from "./subcomponents/TeamBreadCrumb";
import AddToTeam from "./subcomponents/AddToTeam";
import AccessLevels from "./subcomponents/AccessLevels";
import DomainEmailSettings from "./subcomponents/DomainEmailSettings";
import CustomizeWaitingMessage from "./subcomponents/CustomizeWaitingMessage";
import CustomizeWelcomeMessage from "./subcomponents/CustomizeWelcomeMessage";
import TeamMembership from "./subcomponents/TeamMembership";

interface Props extends SuperSidePaneProps {
  isDomain: boolean;
  onlineUsersTable: {
    [x: number]: UserOnlineInfo;
  };
  personalDISCMini?: Tyto.DISCProfileMini;
  team: Tyto.Team;
  teamMembers?: Tyto.DISCProfileMini[];
  websocketServerConnected: boolean;
}

export default (props: Props) => {
  const isTryybAdmin = props.roleID && props.roleID === 1;

  return (
    <section
      className={cx("meta-super-sidepane-main-cont", props.className)}
      onClick={props.onClick}
    >
      {props.canClose && (
        <Icon
          className="meta-sidepane-close-button"
          isButton={true}
          icon="close"
          size={25}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />
      )}

      <TeamBreadCrumb onTeamSelect={props.onTeamSelect} team={props.team} />

      <AccessLevels
        onConfigurationUpdate={(teamID) => {
          if (props.onConfigurationUpdate) {
            props.onConfigurationUpdate(teamID);
          }
        }}
        personalDISCMini={props.personalDISCMini}
        titleOverride="When Can Users Access Reports?"
        team={props.team}
        teamWithConfiguration={props.teamWithConfiguration}
        teamID={_.get(props.team, "teamID", 0)}
      />

      <CustomizeWelcomeMessage
        closeAtStart={true}
        domainID={props.team.teamID}
      />

      {isTryybAdmin && (
        <>
          <DomainEmailSettings domainID={props.team.teamID} />

          <CustomizeWaitingMessage
            closeAtStart={true}
            domainID={props.team.teamID}
          />

          <CustomizeWelcomeMessage
            closeAtStart={true}
            domainID={0}
            title="Default Welcome Message (Domain 0)"
          />

          <DomainEmailSettings
            closedAtStart={true}
            domainID={0}
            title="Default Email Settings (Domain 0)"
          />

          <CustomizeWaitingMessage
            closeAtStart={true}
            domainID={0}
            title="Default Waiting Message (Domain 0)"
          />
        </>
      )}

      <TeamMembership
        onlineUsersTable={props.onlineUsersTable}
        onPersonSelect={props.onPersonSelect}
        personalDISCMini={props.personalDISCMini}
        reloadTeamMembers={() =>
          props.reloadTeamMembers(_.get(props.team, "teamID", 0))
        }
        teamMembers={props.teamMembers}
        websocketServerConnected={props.websocketServerConnected}
      />

      <AddToTeam
        closedAtStart={true}
        reloadTeamMembers={props.reloadTeamMembers}
        team={props.team}
      />
    </section>
  );
};
