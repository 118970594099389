/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../typings/tyto";
import { AppStoreProps } from "../../../data/stores/AppStore";
import NewAccount from "./NewAccount";
import { BILLING_MODAL_DEFAULT } from "../../../data/constants/";

import Account from "./Account";

import "./Accounts.scss";

interface Props {
  AppStore: AppStoreProps;
  billingInfo?: { [x: number]: Tyto.BillingSummaryResp };
  primaryDomain?: Tyto.Team;
  searchTerm: string;
  subDomains: Tyto.Team[];
  uploadURL: string;
}

export default ({
  AppStore,
  billingInfo,
  primaryDomain,
  searchTerm,
  subDomains,
  uploadURL,
}: Props) => {
  return (
    <ul className="partner-dashboard-accounts-list">
      {Array.isArray(subDomains) &&
        subDomains.map((subDomain, curIdx) =>
          !billingInfo || !billingInfo[subDomain.teamID] ? null : (
            <Account
              key={subDomain.teamID}
              AppStore={AppStore}
              domainBillingInfo={
                billingInfo ? billingInfo[subDomain.teamID] : undefined
              }
              isTypicalBillingModel={
                _.get(billingInfo, `[${subDomain.teamID}].billingModel`) ===
                BILLING_MODAL_DEFAULT
              }
              curIdx={curIdx}
              searchTerm={searchTerm}
              subDomain={subDomain}
              uploadURL={uploadURL}
            />
          )
        )}

      <NewAccount
        primaryDomain={primaryDomain}
        reloadAccounts={() => {}}
        uploadURL={uploadURL}
      />
    </ul>
  );
};
