/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../../../typings/tyto";
import { Icon, Message } from "../../../../../components/common";
import {
  calcGradientForIcon,
  isValidIcon
} from "../../../../../data/utils/helpers";

import "./AboutPersonality.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
}

export default ({ discCompareProfile, discMiniProfile }: Props) => {
  if (discCompareProfile === null) {
    return <h1>No Compare Info. {":("}</h1>;
  }

  if (!discCompareProfile) {
    return <h2>Loading...</h2>;
  }

  const [iconGradient, updateUpdateIconGradient] = React.useState(
    calcGradientForIcon({
      d: discMiniProfile.d3,
      i: discMiniProfile.i3,
      s: discMiniProfile.s3,
      c: discMiniProfile.c3
    })
  );

  React.useEffect(() => {
    updateUpdateIconGradient(
      calcGradientForIcon({
        d: discMiniProfile.d3,
        i: discMiniProfile.i3,
        s: discMiniProfile.s3,
        c: discMiniProfile.c3
      })
    );
  }, [discMiniProfile]);

  const noCharacteristics =
    !discCompareProfile.style.characteristic1details &&
    !discCompareProfile.style.characteristic2details &&
    !discCompareProfile.style.characteristic3details;

  return (
    <section className="directory-tabs-tab-section-about-personality-cont">
      <div className="directory-tabs-tab-section-about-personality-top-cont">
        <div className="directory-tabs-tab-section-about-personality-top-icon-cont">
          <Icon
            className="directory-tabs-tab-section-about-personality-top-icon"
            icon={isValidIcon(discMiniProfile.graphic)}
            size={66}
            gradient={iconGradient}
          />
        </div>

        <div className="directory-tabs-tab-section-about-personality-top-text-cont">
          <h2 className="directory-tabs-tab-section-about-personality-top-title">
            {discCompareProfile.style.styleName}
          </h2>
          <p className="directory-tabs-tab-section-about-personality-top-description">
            {discCompareProfile.style.styleHeadline}
          </p>
        </div>
      </div>

      <div className="directory-tabs-tab-section-about-personality-section-cont">
        <h2 className="directory-tabs-tab-section-about-personality-section-title">
          3 Main things to know about {discCompareProfile.style.styleName}?
        </h2>

        {noCharacteristics ? (
          <Message
            className="directory-tabs-tab-section-about-personality-section-missing-characteristic-message"
            value="No Characteristic information found."
          />
        ) : (
          <>
            <div className="directory-tabs-tab-section-about-personality-section-inner-section">
              <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title">
                {discCompareProfile.style.characteristic1}
              </h3>
              <p className="directory-tabs-tab-section-about-personality-section-inner-section-description">
                {discCompareProfile.style.characteristic1details}
              </p>
            </div>

            <div className="directory-tabs-tab-section-about-personality-section-inner-section">
              <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title">
                {discCompareProfile.style.characteristic2}
              </h3>
              <p className="directory-tabs-tab-section-about-personality-section-inner-section-description">
                {discCompareProfile.style.characteristic2details}
              </p>
            </div>

            <div className="directory-tabs-tab-section-about-personality-section-inner-section">
              <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title">
                {discCompareProfile.style.characteristic3}
              </h3>
              <p className="directory-tabs-tab-section-about-personality-section-inner-section-description">
                {discCompareProfile.style.characteristic3details}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="directory-tabs-tab-section-about-personality-section-cont">
        <h2 className="directory-tabs-tab-section-about-personality-section-title">
          What should you expect during your collaboration with{" "}
          {discCompareProfile.style.styleName}s?
        </h2>

        <div className="directory-tabs-tab-section-about-personality-section-inner-section">
          <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title">
            {discCompareProfile.style.styleName}s value to a team
          </h3>
          <ul className="directory-tabs-tab-section-about-personality-section-inner-section-list">
            {discCompareProfile.style.teamContributions &&
              discCompareProfile.style.teamContributions
                .split("\n")
                .map(item => (
                  <li
                    className="directory-tabs-tab-section-about-personality-section-inner-section-list-item"
                    key={item}
                  >
                    {item}
                  </li>
                ))}
          </ul>
        </div>

        <div className="directory-tabs-tab-section-about-personality-section-inner-section">
          <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title">
            How {discCompareProfile.style.styleName}s Analyze
          </h3>
          <ul className="directory-tabs-tab-section-about-personality-section-inner-section-list">
            {discCompareProfile.style.analyticDispostion &&
              discCompareProfile.style.analyticDispostion
                .split("\n")
                .map(item => (
                  <li
                    className="directory-tabs-tab-section-about-personality-section-inner-section-list-item"
                    key={item}
                  >
                    {item}
                  </li>
                ))}
          </ul>
        </div>

        <div className="directory-tabs-tab-section-about-personality-section-inner-section">
          <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title">
            Be careful about these points
          </h3>
          <ul className="directory-tabs-tab-section-about-personality-section-inner-section-list">
            {discCompareProfile.style.environmentIdeal &&
              discCompareProfile.style.environmentIdeal
                .split("\n")
                .map(item => (
                  <li
                    className="directory-tabs-tab-section-about-personality-section-inner-section-list-item"
                    key={item}
                  >
                    {item}
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
