import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35 47"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        d="M0 46.0726V0H25.9136L30.0097 3.84107L34.1057 7.68212V46.0726H0Z"
        fill="#223958"
      />
      <path
        d="M6.58203 34.1056V26.3271H9.57376V34.1056H6.58203Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M11.9668 38.2941V26.3271H14.9585V38.2941H11.9668Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M17.3516 40.6874V26.3271H20.3433V40.6874H17.3516Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M22.7363 38.2941V26.3271H25.7281V38.2941H22.7363Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M8.07812 21.8396L13.4632 18.2495L18.8483 21.8396L24.2335 11.6677"
        stroke="white"
        stroke-width="3"
        stroke-linejoin="bevel"
      />
      <circle
        cx="18.8474"
        cy="21.8396"
        r="0.995864"
        fill="white"
        stroke="white"
      />
      <circle
        cx="24.2322"
        cy="11.6677"
        r="0.995864"
        fill="white"
        stroke="white"
      />
      <circle
        cx="13.4627"
        cy="18.2495"
        r="0.995864"
        fill="white"
        stroke="white"
      />
      <circle
        cx="8.0779"
        cy="21.8396"
        r="0.995864"
        fill="white"
        stroke="white"
      />
      <path
        d="M25.7285 7.77856L25.7285 7.39098e-05L34.1054 7.77856L25.7285 7.77856Z"
        fill="#435369"
      />
    </svg>
  );
};
