/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";

import "./style.scss";

interface Props {
  className: string;
  hideChildrenWhenToggled?: boolean;
  onLinkClick?: () => void;
  renderFunction: (
    isExpanded: boolean,
    sectionContRef: React.MutableRefObject<null>
  ) => React.ReactNode;
  style?: React.CSSProperties;
  title?: string;
  closedAtStart?: boolean;
}

export const ToggleSection = (props: Props) => {
  const sectionContRef = React.useRef(null);
  const [isExpanded, updateIsExpanded] = React.useState(!props.closedAtStart);

  return (
    <section
      className={cx(
        "cc-toggle-section",
        !isExpanded && "cc-toggle-section-closed",
        props.className
      )}
      ref={sectionContRef}
    >
      {props.title && (
        <h3 className={"cc-toggle-section-title"}>
          <span
            className="cc-toggle-section-title-text"
            role="button"
            onClick={() => updateIsExpanded(!isExpanded)}
          >
            {props.title}
          </span>

          {!!props.onLinkClick && (
            <Icon
              buttonProps={{
                className: "cc-toggle-section-title-link-cont",
              }}
              className="cc-toggle-section-title-link"
              isButton={true}
              icon="arrow-from-left"
              onClick={props.onLinkClick}
              size={14}
            />
          )}
        </h3>
      )}

      {!isExpanded && props.hideChildrenWhenToggled ? (
        <span style={{ visibility: "hidden" }} />
      ) : (
        props.renderFunction(isExpanded, sectionContRef)
      )}

      <Icon
        buttonProps={{
          className: "cc-toggle-section-toggle-btn",
        }}
        onClick={() => updateIsExpanded(!isExpanded)}
        icon={isExpanded ? "minus" : "plus"}
        isButton={true}
        size={20}
      />
    </section>
  );
};

export default ToggleSection;
