import { ENDPOINT_LESSON } from "../constants";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_LESSON;

export const Lesson = {
  get(params: Endpoints.Tyto.Lesson.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      lesson: any;
      session: SessionData;
    }>;
  },
};
