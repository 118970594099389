import { ENDPOINT_DISC_PROFILES } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_DISC_PROFILES;

export const DISCProfiles = {
  get(params: Endpoints.Tyto.DISCProfiles.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      discProfiles: any[];
      session: SessionData;
    }>;
  },
};
