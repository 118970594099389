/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import QueryString from "query-string";
// @ts-ignore
import pkg from "../../../../package.json";

import { AppStoreProps } from "../../../data/stores/AppStore";
import TytoCalls from "../../../data/tyto/";
import { SessionData } from "../../../typings";
import { setTempSessionData } from "../../../data/storage/session";

import { Button, Loading, Message } from "../../../components/common/";
import SetPasswordComponent from "../../assessment-taker/subcomponents/SetPassword";

import "./SetPassword.scss";
import ForgotPassword from "./ForgotPassword";

const appBrand = "team-tools-webapp";
const appVersion = _.get(pkg, "version", "");

interface Props {
  location?: Location;
  path?: string;
  requireCurrentPassword: boolean;
  store?: AppStoreProps;
  uri?: string;
}

export default (props: Props) => {
  const [tempSessionKey, updateTempSessionKey] = React.useState<string>(() => {
    const redirectParams: string = _.get(props.location, "search", "");
    const parsedParamsObj: { [x: string]: string } =
      QueryString.parse(redirectParams);

    const newTempSessionKey =
      (parsedParamsObj && parsedParamsObj.temporaryKey) || "";

    return newTempSessionKey || "";
  });
  const [sessionDataFromAppState] = React.useState(
    _.get(props, "store.state.sessionData", undefined)
  );
  const [tempSessionData, updateTempSessionData] = React.useState<
    SessionData | undefined
  >(() => {
    const tempSessionStoreData = _.get(props, "store.state.tempSessionData");

    // TODO Logic to check it is the proper temp Session?

    return tempSessionStoreData;
  });
  const [failedMsg, updateFailedMsg] = React.useState("");

  const [showForgotPassword, updateShowForgotPassword] = React.useState(false);

  const navigate = useNavigate();

  // * [E-1] - On Mount check credentials if outsideSessionKey exists in URL
  React.useEffect(() => {
    if (tempSessionKey) {
      // * 'outlookSessionKey' is misleading, just named that because oringinally was outlook -> Web App using sessionKey from session in Outlook Add-In
      checkCredentials({
        AppStore: props.store,
        outsideSessionKey:
          tempSessionKey || sessionDataFromAppState.sessionKey || "",
        onSuccess: (session) => updateTempSessionData(session),
        onFail: (reason: string) => updateFailedMsg(`${reason}`),
      });
    } else if (
      sessionDataFromAppState &&
      sessionDataFromAppState.changePassword &&
      sessionDataFromAppState.sessionKey
    ) {
      updateTempSessionData(sessionDataFromAppState);
    }
  }, []);

  // * [E-2] - Since tempKey is used to load tempSessionData in this component, watch for changes to it in Store
  React.useEffect(() => {
    const newTempSessionData = _.get(props, "store.state.tempSessionData");

    if (newTempSessionData) {
      updateTempSessionData(newTempSessionData);
    }
  }, [_.get(props, "store.state.tempSessionData")]);

  //   React.useEffect(() => {
  //     if (tempSessionKey) {
  //       // * 'outlookSessionKey' is misleading, just named that because oringinally was outlook -> Web App using sessionKey from session in Outlook Add-In
  //       checkCredentials({
  //         AppStore: props.store,
  //         outsideSessionKey: tempSessionKey,
  //         onFail: () => {}
  //       });
  //     }
  //   }, [tempSessionKey]);

  const sessionData = _.get(props, "store.state.sessionData");

  if (failedMsg || (!sessionData && props.requireCurrentPassword)) {
    if (showForgotPassword) {
      return (
        <div className="logon-interface">
          <ForgotPassword cancel={() => updateShowForgotPassword(false)} />
        </div>
      );
    }

    return (
      <div className="logon-interface">
        <div className="logon-cont setpassword-cont">
          <h1 className="logon-title extra-space title-bold">
            Cannot Set Password
          </h1>

          <p className="logon-setpassword-error-msg">{failedMsg}</p>

          <div className="logon-setpassword-error-buttons-cont">
            <Button
              className="logon-setpassword-error-button"
              onClick={() => navigate("/login")}
              shape="square"
              type="hollow"
              iconLeft="angle-left"
              iconProps={{
                size: 13,
                className: "",
              }}
              value="Go To Login"
            />

            <Button
              className="logon-setpassword-error-button"
              onClick={() => updateShowForgotPassword(true)}
              shape="square"
              type="color"
              value="Send Reset Password Email"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="logon-interface">
      {tempSessionData || (props.requireCurrentPassword && sessionData) ? (
        <SetPasswordComponent
          AppStore={props.store}
          tempSessionData={tempSessionData}
          requireCurrentPassword={props.requireCurrentPassword}
        />
      ) : (
        <div className="logon-cont">
          <Loading />
        </div>
      )}
    </div>
  );
};

const checkCredentials = async ({
  AppStore,
  outsideSessionKey,
  onFail,
  onSuccess,
}: {
  AppStore?: AppStoreProps;
  outsideSessionKey: string;
  onFail: (reason: string) => void;
  onSuccess: (sessionData: SessionData) => void;
}) => {
  try {
    const newSessionResp: any =
      await TytoCalls.TeamToolsInvite.TempSession.post(
        { sessionKey: outsideSessionKey, appBrand, appVersion },
        { omitSessionKey: true }
      );

    // * resp.newSession does not contain sessionKey, as it lives in resp.accountSession.sessionKey
    const newSessionKey = _.get(
      newSessionResp,
      "accountSession.sessionKey",
      undefined
    );

    const createdSessionData: SessionData = {
      sessionKey: newSessionKey,
      ...(newSessionResp.newSession || {}),
      tempSessionKey: outsideSessionKey,
    };

    if (AppStore && AppStore.dispatch) {
      setTempSessionData(createdSessionData);

      AppStore.dispatch({
        payload: {
          tempSessionData: createdSessionData,
          // tempSessionData: newSessionResp.newSession,
          tempSessionKey: outsideSessionKey,
        },
        type: "SET_TEMP_SESSION_DATA",
      });
    }
    // * PREV LOGIC ---------
    // const sessionResp = await TytoCalls.AccountSession.get(
    //   { sessionKey: outsideSessionKey },
    //   { omitSessionKey: true }
    // );
    // const outsideSessionUserID: number = _.get(
    //   sessionResp,
    //   "session.userID",
    //   0
    // );

    // if (!outsideSessionUserID) {
    //   onFail("Could not validate identity with supplied key.");

    //   return;
    // }

    // const mergedSessionData = {
    //   ...(sessionResp.session || {}),
    //   sessionKey: outsideSessionKey,
    // } as SessionData;

    // onSuccess(mergedSessionData);
  } catch (err) {
    debugger;
    let errMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Error occurrred, unable validate identity.");

    if (err && typeof err === "object" && "sts" in err && err.sts === -298) {
      errMsg = `The temporary key supplied in the URL is invalid. If you have already followed a link to get here before, and previously reset your password then you will need to send yourself a new Reset Password Email.`;
    }

    onFail(errMsg);
  }
};
