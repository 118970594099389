import { ENDPOINT_DOMAIN_MESSAGE } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

import { Tyto } from "../../typings/tyto";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_DOMAIN_MESSAGE;

export const DomainMessage = {
  put(
    params: Endpoints.Tyto.Domain.Message.PutParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("put", endpoint, params || {}, callOpts) as Promise<{
      message: Tyto.Domain.Message;
      session: SessionData;
    }>;
  },
};
