/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import "./Preview.scss";

interface Props {
  htmlBody: string;
  plainText: string;
}

export default (props: Props) => {
  const [hasScriptTag, updateHasScriptTag] = React.useState(
    checkIfHTMLHasScriptTab(props.htmlBody)
  );

  React.useEffect(() => {
    if (props.htmlBody) {
      updateHasScriptTag(checkIfHTMLHasScriptTab(props.htmlBody));
    }
  }, [props.htmlBody]);

  return (
    <div className="modals-domainmessageedit-preview-cont">
      {hasScriptTag ? (
        <div className="modals-domainmessageedit-preview-warning-cont">
          <h3 style={{ color: "red" }}>
            A Script Tag Has been Detected. We will neither render nor save HTML
            with Script Tags prssent. Remove the Script Tag and you may
            Preview/Save the HTML.
          </h3>
        </div>
      ) : (
          <div
            className="modals-domainmessageedit-preview-html-cont"
            style={{ color: "#000" }}
          >
            <div
              className="modals-domainmessageedit-preview-html"
              dangerouslySetInnerHTML={{ __html: props.htmlBody }}
            />
          </div>
        )}
    </div>
  );
};

export const checkIfHTMLHasScriptTab = (htmlAsString: string) => {
  if (!htmlAsString) {
    return false;
  }

  const regExp = new RegExp(_.escapeRegExp("<script"), "i");

  return regExp.test(htmlAsString);
};
