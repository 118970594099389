/*
 * Component Description
 */
import * as React from "react";

import { Button, Message } from "../../../common/";

import "./GenerateLink.scss";

interface Props {}

export default (props: Props) => {
  const [generatingLink, updateGeneratingLink] = React.useState(false);

  return (
    <div>
      <h3 className="meta-super-sidepane-createnewuser-title">
        Invite Users via Link:
      </h3>

      <Message
        className="meta-super-sidepane-createnewuser-message"
        value="This Feature is not yet available"
      />

      {/* <div style={{ paddingTop: "20px" }}>
        <Button
          className="meta-super-sidepane-createnewuser-invite-button"
          disabled={true || generatingLink}
          onClick={() => {}}
          value="Generate Link"
        />
      </div> */}
    </div>
  );
};
