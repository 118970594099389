import { ENDPOINT_SESSION_CHECK } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_SESSION_CHECK;

export const AccountSession = {
  get(
    params: Endpoints.Tyto.AccountSession.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, {
      circumventChangePasswordCheck: true,
      ...(callOpts || {}),
    }) as Promise<{
      session: SessionData;
    }>;
  },
  post(
    params: Endpoints.Tyto.AccountSession.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params || {}, {
      circumventChangePasswordCheck: true,
      ...(callOpts || {}),
    });
  },
};
