import * as React from "react";

import { IconProps } from "../typings";

export default ({ className, color, gradient, gradientKey, size, title }: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop key={`${grad.offsetPercent}-${grad.color}`} offset={`${grad.offsetPercent}%`} stopColor={grad.color} />
            ))}
          </linearGradient>
        </defs>
      )}
      {title && <title>{title}</title>}
        <path fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""} d="M448 0H320c-35.35 0-64 28.65-64 64v153.6L4.69 468.91c-6.25 6.25-6.25 16.38 0 22.63l15.77 15.77c6.25 6.25 16.38 6.25 22.63 0l36.25-36.25 36.11 36.11c6.25 6.25 16.38 6.25 22.63 0l43.16-43.16c6.25-6.25 6.25-16.38 0-22.63l-36.11-36.11L176 374.4l36.91 36.91c6.25 6.25 16.38 6.25 22.63 0l15.77-15.77c6.25-6.25 6.25-16.38 0-22.63L214.4 336l80-80H448c35.35 0 64-28.65 64-64V64c0-35.35-28.65-64-64-64zm-73.37 182.63c-12.5 12.5-32.76 12.5-45.26 0s-12.5-32.76 0-45.25c12.5-12.5 32.76-12.5 45.26 0 12.49 12.49 12.49 32.75 0 45.25zm64-64c-12.5 12.5-32.76 12.5-45.26 0s-12.5-32.76 0-45.25c12.5-12.5 32.76-12.5 45.26 0 12.49 12.49 12.49 32.75 0 45.25z"/>
    </svg>
  );
};
