/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreContext } from "../../../data/stores/GeneralStore";

import Nav from "./";
import MobileNav from "../mobile-nav/";

interface Props {
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  let GeneralStore = React.useContext(StoreContext);

  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  return isMobile ? <MobileNav {...props} /> : <Nav {...props} />;
};
