/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import QueryString from "query-string";

import { Nav } from "../../components/meta/";
import { SessionHOC } from "../../components/hoc/";

import Groups from "./Groups";

interface Props {
  location?: Location;
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  const [personIDs, updatePersonIDs] = React.useState(
    getPersonIDsFromURL(props.location)
  );

  return (
    <SessionHOC path={props.path} uri={props.uri}>
      <section className="home-interface">
        <Nav path={props.path} uri={props.uri} />

        <Groups personIDs={personIDs} />
      </section>
    </SessionHOC>
  );
};

const getPersonIDsFromURL = (location?: Location) => {
  const redirectParams: string = _.get(location, "search", "");
  const parsedParamsObj: { [x: string]: string } = QueryString.parse(
    redirectParams
  );

  const personIDs = (parsedParamsObj && parsedParamsObj.personIDs) || "";

  const asNumberArr = personIDs
    .split(",")
    .map((personID) => (personID ? parseInt(personID) : null))
    .filter((personID) => !!personID);

  return asNumberArr as number[];
};
