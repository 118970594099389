import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M391.77844,120.23438c-16.64439-16.64258-42.06121-17.63868-60.76247-4.73047C307.55639,102.98633,281.92079,96,256,96A160,160,0,1,0,369.149,369.13672c51.02312-51.01758,59.65883-127.62891,27.36235-188.14453C409.421,162.291,408.42283,136.877,391.77844,120.23438ZM335.20393,335.19531a112.44148,112.44148,0,1,1-24.47337-176.39062c1.047,10.668,4.98687,21.14062,13.15964,29.3125,8.163,8.16211,18.62312,12.0957,29.27857,13.15039C377.15966,243.93945,371.48131,298.92383,335.20393,335.19531ZM256,192a64,64,0,1,0,64.007,64A64.00289,64.00289,0,0,0,256,192Zm80.90528,255.67773a208.24661,208.24661,0,0,1-227.9994-44.59961C39.328,333.50781,29.62381,226.59766,79.41829,146.38672a56.05613,56.05613,0,1,0-37.799-30.34375c-64.97,99.2832-53.88672,233.75781,33.34152,320.97656A255.17414,255.17414,0,0,0,364.422,487.65039c-2.19165-1.80273-4.5376-3.32812-6.58665-5.377A86.91321,86.91321,0,0,1,336.90528,447.67773ZM437.03922,74.98047A255.17108,255.17108,0,0,0,147.58,24.34961c2.19165,1.80273,4.53761,3.32812,6.5847,5.375a86.87534,86.87534,0,0,1,20.93,34.59766,208.24661,208.24661,0,0,1,227.9994,44.59961c69.57593,69.56835,79.28011,176.47851,29.48759,256.68945a56.05819,56.05819,0,1,0,37.799,30.3457C535.3507,296.67383,524.26746,162.19922,437.03922,74.98047Z"
      />
    </svg>
  );
};
