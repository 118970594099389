/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { Tyto } from "../../../../../typings/tyto";

// import { TOGGLE_SECTION_BLOCKS } from "../../../data/constants/";
import { DISCValueRender, Message, ToggleSection } from "../../../../common/";

import "./PersonMotivators.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
  personName: string;
}

export default (props: Props) => {
  // const theirName =
  //   props.discMiniProfile && props.discMiniProfile.personName
  //     ? props.discMiniProfile.personName.split(" ")[0]
  //     : "They";

  return (
    <ToggleSection
      className="meta-sidepane-person-motivators-cont"
      closedAtStart={false}
      // mixpanelKey={TOGGLE_SECTION_BLOCKS.Person.MOTIVATORS}
      title={`What Motivates ${props.personName}`}
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return <span style={{ visibility: "hidden" }} />;
        }

        return (
          <DISCValueRender
            className="meta-sidepane-person-renderer-wrapper"
            compareProfile={props.discCompareProfile}
            isCompareValue={true}
            targetKey="style.motivators"
            discMiniProfile={props.discMiniProfile}
          >
            {props.discCompareProfile ? (
              <ul className="meta-sidepane-person-motivators-list">
                {_.get(props.discCompareProfile, "style.motivators", "")
                  .split("\n")
                  .map((tip: string) => (
                    <li
                      className="meta-sidepane-person-motivators-list-item"
                      key={tip}
                    >
                      {tip}
                    </li>
                  ))}
              </ul>
            ) : (
              <Message value="No Motivators found." />
            )}
          </DISCValueRender>
        );
      }}
    />
  );
};
