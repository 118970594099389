/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../../../typings/tyto";
import { UserThumb } from "../../../../common/";

import "./TeamMinimizedProfileThumbs.scss";

interface Props {
  discMiniProfiles: Tyto.DISCProfileMini[];
  setFocusedPerson: (personID: number) => void;
}

export default (props: Props) => {
  return (
    <div className="meta-sidepane-team-minimized-usethumbs-cont">
      <ul className="meta-sidepane-team-minimized-usethumbs-list">
        {props.discMiniProfiles.map((person, curIdx) => (
          <li className="meta-sidepane-team-minimized-usethumbs-list-item">
            <UserThumb
              className="meta-sidepane-team-minimized-usethumbs-userthumb"
              discMiniProfile={person}
              discValues={{
                d: person.d3 || 0,
                i: person.i3 || 0,
                s: person.s3 || 0,
                c: person.c3 || 0,
              }}
              size={42}
              asset={person.profileImageAsset}
              onClick={() => props.setFocusedPerson(person.personID)}
              userName={person.personName}
              style={{
                zIndex: curIdx + 1,
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
