import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map(grad => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      <path
        fill={Array.isArray(gradient) ? `url(#${gradientKey})` : ""}
        d="M12.971 352h32.394C67.172 454.735 181.944 512 288 512c106.229 0 220.853-57.38 242.635-160h32.394c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h35.146c-20.29 54.317-84.963 86.588-144.117 94.015V256h52c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-52v-5.47c37.281-13.178 63.995-48.725 64-90.518C384.005 43.772 341.605.738 289.37.01 235.723-.739 192 42.525 192 96c0 41.798 26.716 77.35 64 90.53V192h-52c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v190.015c-58.936-7.399-123.82-39.679-144.117-94.015h35.146c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0L4.485 331.515C-3.074 339.074 2.28 352 12.971 352zM288 64c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"
      />
    </svg>
  );
};
