/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto";
import { Button, Checkbox, Icon, TextButton } from "../../../common/";
import { AppStoreProps } from "../../../../data/stores/AppStore";

import {
  addUserToTeam,
  formatUserForSave,
  importUser,
  updateExistingUsersData,
} from "../utils/";

import "./SaveUser.scss";
import AddToTeam from "../../../meta/super-side-pane/subcomponents/AddToTeam";

interface Props {
  autoAddToTeam: boolean;
  appStore: AppStoreProps;
  columnLabels: string[];
  result: string[];
  rowDisabled: boolean;
  team: Tyto.Team;
}

export default (props: Props) => {
  const [saving, updateSaving] = React.useState(
    !props.rowDisabled && !!props.team
  );
  const [saved, updateSaved] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [newlyCreatedUserID, updateNewlyCreatedUserID] = React.useState(0);
  const [person, updatePerson] = React.useState<Tyto.Person | undefined>(
    undefined
  );
  const [successMsg, updateSuccessMsg] = React.useState("");
  const [differencesKeys, updateDifferencesKeys] = React.useState<string[]>([]);
  const [differencesBools, updateDifferencesBools] = React.useState<Boolean[]>(
    []
  );
  const [addAsMemberOfTeam, updateAddAsMemberOfTeam] = React.useState<
    Boolean | undefined
  >(undefined);

  React.useEffect(() => {
    if (props.rowDisabled) {
      return;
    }

    const domainID =
      props.team.teamType === "ocDOMAIN"
        ? props.team.teamID
        : props.team.domainID;

    importUser({
      autoAddToTeam: props.autoAddToTeam,
      domainID,
      onError: (err: any) => {
        if (typeof err === "string") {
          updateError(err);
        } else {
          const errorMsg = (err && err.msg) || "Error occurred";
          updateError(errorMsg);
        }
      },
      onSuccess: ({ personID, msg }: { personID?: number; msg: string }) => {
        updateSaved(true);
        if (personID) {
          updateNewlyCreatedUserID(personID);
        }
        updateSuccessMsg(msg);
        updateSaving(false);
      },
      columnLabels: props.columnLabels,
      personData: props.result,
      primaryElementID: props.team.teamID,
      updateMissingMembership: (value?: boolean) => {
        updateAddAsMemberOfTeam(value);
      },
      updatePerson: (personInfo) => {
        updatePerson(personInfo);
        updateSaving(false);
      },
      setDifferences: (differences) => {
        updateDifferencesKeys(differences);
        updateDifferencesBools(
          Array.from({ length: differences.length }, (__) => true)
        );
      },
    });
  }, []);

  if (props.rowDisabled) {
    return null;
  }

  if (saving) {
    return (
      <li className="modals-importusers-modal-results-list-item saving-list-item">
        <div className="modals-importusers-modal-results-list-item-success-section">
          <p className="modals-importusers-modal-results-list-item-saving-msg">
            Importing...
          </p>
        </div>
      </li>
    );
  }

  if (person) {
    const formattedData =
      formatUserForSave({
        columnLabels: props.columnLabels,
        personData: props.result,
        primaryElementID: props.team.teamID,
      }) || ({} as Endpoints.Tyto.Person.PostParameters);

    return (
      <li className="modals-importusers-modal-results-list-item saving-list-item">
        <Button
          className="modals-importusers-modal-results-save-difference-submit-button"
          disabled={
            !differencesBools.filter((bool) => bool).length &&
            !addAsMemberOfTeam
          }
          onClick={() => {
            if (addAsMemberOfTeam) {
              addUserToTeam({
                userID: person.personID,
                teamID: props.team.teamID,
                onError: (newErrMsg) => updateError(newErrMsg),
                onSuccess: (newSuccessMsg) => {
                  if (!differencesBools.filter((bool) => bool).length) {
                    updateSaved(true);
                    if (person.personID) {
                      updatePerson(undefined);
                      updateNewlyCreatedUserID(person.personID);
                    }
                    updateSuccessMsg(newSuccessMsg);
                    updateSaving(false);
                  }
                },
              });
            }

            if (differencesBools.filter((bool) => bool).length) {
              updateExistingUsersData({
                differingValues: differencesKeys.filter(
                  (key, curIdx) => !!differencesBools[curIdx]
                ),
                onError: (err: any) => {
                  if (typeof err === "string") {
                    updateError(err);
                  } else {
                    const errorMsg = (err && err.msg) || "Error occurred";
                    updateError(errorMsg);
                  }
                },
                onSuccess: ({
                  personID,
                  msg,
                }: {
                  personID?: number;
                  msg: string;
                }) => {
                  updateSaved(true);
                  if (personID) {
                    updatePerson(undefined);
                    updateNewlyCreatedUserID(personID);
                  }
                  updateSuccessMsg(msg);
                  updateSaving(false);
                },
                person,
                personParams: formattedData,
              });
            }
          }}
          shape="square"
          type="hollow"
          value="Update User"
        />

        <div className="modals-importusers-modal-results-save-difference-summary-cont">
          <details>
            <summary>View Changes</summary>
            <ul className="modals-importusers-modal-results-save-difference-summary-list">
              {typeof addAsMemberOfTeam !== "undefined" && (
                <li className="modals-importusers-modal-results-save-difference-summary-list-item save-difference-summary-list-item-addtoteam">
                  <Checkbox
                    className="modals-importusers-modal-results-save-difference-summary-list-checkbox"
                    checked={!!addAsMemberOfTeam}
                    onCheck={() => updateAddAsMemberOfTeam(!addAsMemberOfTeam)}
                    size={12}
                  />
                  <b>Add to this Team</b> <i>({props.team.name})</i>
                </li>
              )}

              {differencesKeys.map((key, curIdx) => (
                <li
                  className="modals-importusers-modal-results-save-difference-summary-list-item"
                  key={key}
                >
                  <Checkbox
                    className="modals-importusers-modal-results-save-difference-summary-list-checkbox"
                    checked={!!differencesBools[curIdx]}
                    onCheck={() => {
                      const newDifferencesBools = [...differencesBools];
                      newDifferencesBools[curIdx] = !differencesBools[curIdx];
                      updateDifferencesBools(newDifferencesBools);
                    }}
                    size={12}
                  />
                  <b>{key === "__fullName__" ? "Full Name" : key}:</b>{" "}
                  {key === "__fullName__"
                    ? `${person["givenName"]} ${person["familyName"]}`
                    : person[key as keyof Tyto.Person] || <i>No Value</i>}
                  {" -> "}
                  {formattedData[
                    key as keyof Endpoints.Tyto.Person.PostParameters
                  ] || <i>No value</i>}
                </li>
              ))}
            </ul>
          </details>
        </div>
      </li>
    );
  }

  const sessionKey = _.get(props.appStore, "state.sessionData.sessionKey", "");
  const name = getName({
    personData: props.result,
    columnLabels: props.columnLabels,
  });

  return (
    <li
      className={cx(
        "modals-importusers-modal-results-list-item",
        saved && "modals-importusers-modal-results-list-item-success",
        "saving-list-item"
      )}
    >
      {saved && newlyCreatedUserID ? (
        <>
          <div className="modals-importusers-modal-results-list-item-success-section">
            <Icon
              className="modals-importusers-modal-results-list-item-success-check"
              size={14}
              icon="check"
            />
          </div>

          <div
            className="modals-importusers-modal-results-list-item-success-section"
            style={{ width: "225px" }}
          >
            {name}
          </div>

          <div className="modals-importusers-modal-results-list-item-success-section">
            <TextButton
              onClick={() => {
                if (sessionKey) {
                  window.open(
                    `/session-check?outsideSessionKey=${sessionKey}&redirect=/profile/${newlyCreatedUserID}`
                  );
                }
              }}
              value="Go To Account"
            />
          </div>

          <div className="modals-importusers-modal-results-list-item-success-section">
            <p className="modals-importusers-modal-results-list-item-success-msg">
              {successMsg}
            </p>
          </div>
        </>
      ) : (
        <span>{error}</span>
      )}
    </li>
  );
};

const getName = ({
  personData,
  columnLabels,
}: {
  personData: string[];
  columnLabels: string[];
}) => {
  const nameIdx = columnLabels.findIndex(
    (key) =>
      key === "__fullName__" || key === "givenName" || key === "familyName"
  );

  if (nameIdx >= 0 && personData[nameIdx]) {
    return `${personData[nameIdx]}`;
  }

  const emailIdx = columnLabels.findIndex(
    (key) => key === "logonName" || key === "email"
  );

  if (nameIdx >= 0 && personData[emailIdx]) {
    return `${personData[emailIdx]}`;
  }

  return "";
};
