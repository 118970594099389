import * as _ from "lodash";
import { AppStoreProps, StoreState } from "../../../data/stores/AppStore";
import TytoCalls from "../../../data/tyto/";

export function checkStateForOmittedID(
  AppStore: AppStoreProps,
  userID?: string
) {
  const discMiniNull: StoreState["discMiniNull"] = _.get(
    AppStore,
    "state.discMiniNull",
    []
  );
  const parsedUserID = userID ? parseInt(userID) : undefined;

  if (!parsedUserID) {
    return false;
  }

  return !!discMiniNull.find((omittedUserID) => omittedUserID === parsedUserID);
}

export async function loadNewCompareProfile(
  AppStore: AppStoreProps,
  userID: string
) {
  const parsedUserID = parseInt(userID);

  try {
    const discCompareResp = await TytoCalls.DISCProfile.Interactive.get({
      personID: parsedUserID,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discCompareProfile: discCompareResp.discProfile,
        },
        type: "DISC_COMPARE_PROFILE_LOADED",
      });
    }
  } catch (err) {
    console.warn("Error while loading single profile");
    return;
  }
}

export async function loadNewProfile(AppStore: AppStoreProps, userID: string) {
  const parsedUserID = parseInt(userID);

  try {
    const discMiniResp = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${parsedUserID}`,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: discMiniResp.discProfiles,
          userIDsUsed: [parsedUserID],
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }
  } catch (err) {
    console.warn("Error while loading single profile");
    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: [],
          userIDsUsed: [parsedUserID],
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }
    return;
  }
}

export async function loadDiscMiniProfile({
  AppStore,
  callback,
  userID,
}: {
  userID: number;
  callback?: () => void;
  AppStore: AppStoreProps;
}) {
  if (!userID) {
    return null;
  }

  try {
    const { discProfiles } = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${userID}`,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: discProfiles,
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }

    if (callback) {
      callback();
    }
  } catch (err) {
    console.warn("Error occurred while loading Tryyb Profile");

    if (AppStore && AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          profile: null,
          userID,
        },
        type: "TRYYB_PROFILE_LOADED",
      });
    }

    return null;
  }
}

export async function loadTryybProfile({
  AppStore,
  callback,
  userID,
}: {
  userID: number;
  callback?: () => void;
  AppStore: AppStoreProps;
}) {
  if (!userID) {
    return null;
  }

  try {
    loadDiscMiniProfile({
      AppStore,
      userID,
    });

    const personResp = await TytoCalls.Person.get({ personID: userID });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          profile: personResp.person,
          userID,
        },
        type: "TRYYB_PROFILE_LOADED",
      });
    }

    if (callback) {
      callback();
    }
  } catch (err) {
    console.warn("Error occurred while loading Tryyb Profile");

    if (AppStore && AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          profile: null,
          userID,
        },
        type: "TRYYB_PROFILE_LOADED",
      });
    }

    return null;
  }
}

export function pullCompareProfileFromState(
  AppStore: AppStoreProps,
  userID?: string
) {
  const discCompareProfiles: StoreState["discCompareProfiles"] = _.get(
    AppStore,
    "state.discCompareProfiles",
    {}
  );
  const parsedUserID = userID ? parseInt(userID) : undefined;

  if (!parsedUserID || !discCompareProfiles[parsedUserID]) {
    return undefined;
  }

  return discCompareProfiles[parsedUserID];
}

export function pullProfileFromState(AppStore: AppStoreProps, userID?: string) {
  const discMinis: StoreState["discMini"] = _.get(
    AppStore,
    "state.discMini",
    {}
  );
  const parsedUserID = userID ? parseInt(userID) : undefined;

  if (!parsedUserID || !discMinis[parsedUserID]) {
    return undefined;
  }

  return discMinis[parsedUserID];
}
