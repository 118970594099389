/*
 * Component Description
 */
import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import cx from "classnames";
import * as _ from "lodash";

import { SessionHOC } from "../../components/hoc/";
import {
  StoreContext as AppStoreContext,
  AppStoreProps,
} from "../../data/stores/AppStore";
import { getProfilesOfTeamMembers } from "../admin/data/utils/helpers";
import { StoreContext as GeneralStoreContext } from "../../data/stores/GeneralStore";
import { StoreContext as AdminStoreContext } from "../admin/data/stores/AdminStore";
import { loadTeamsWithTeamToolsConfig } from "../admin/data/utils/";
import TytoCalls from "../../data/tyto";
import { loadMiniProfilesForTeam } from "./tabs-interface/utils";
import DisplayedUsersCount from "./subcomponents/DisplayedUsersCount";
import SearchBar from "./subcomponents/SearchBar";
import PeopleList from "./subcomponents/PeopleList";
import PersonFullProfile from "./PersonProfile";
import { SidePane } from "../../components/meta";
import {
  calcGroupStyle,
  getGroupStyleWithStdDeviation,
} from "../../components/meta/side-pane/team/utils";
import { Tyto } from "../../typings/tyto";
import { setUserSnapStyles } from "../../data/utils/user-snap";

import "./style.scss";
import { TextButton } from "../../components/common";

export type SearchFilter =
  | "all"
  | "selected"
  | "unselected"
  | "disc-complete"
  | "disc-incomplete";

const options: {
  label: string;
  value: SearchFilter;
}[] = [
  {
    label: "All Profiles",
    value: "all",
  },
  {
    label: "Selected Profiles",
    value: "selected",
  },
  {
    label: "Unselected Profiles",
    value: "unselected",
  },
  {
    label: "Profiles With DISC",
    value: "disc-complete",
  },
  {
    label: "Profiles Without DISC",
    value: "disc-incomplete",
  },
];

interface Props {
  path?: string;
  subInterface?: string;
  subID?: string;
  teamID?: string;
  uri?: string;
}

export default (props: Props) => {
  let appStore = React.useContext(AppStoreContext);
  let AdminStore = React.useContext(AdminStoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [users, updateUsers] = React.useState<Tyto.DISCProfileMini[]>(
    getProfilesOfTeamMembers({
      AppStoreState: appStore.state,
      teamID: props.teamID || "",
    }) || []
  );
  const [curTeamProfile, updateCurTeamProfile] = React.useState<
    string | undefined
  >(() => {
    if (!users) {
      return "";
    }

    const groupStyle = calcGroupStyle(users);

    return getGroupStyleWithStdDeviation(groupStyle);
  });
  const [includeTeamsBelow, updateIncludeTeamsBelow] = React.useState(false);
  const [previewUser, updatePreviewUser] = React.useState<
    Tyto.DISCProfileMini | undefined
  >(undefined);
  const [selectedIDs, updateSelectedIDs] = React.useState<{
    [x: number]: boolean;
  }>({});
  const [searchTerm, updateSearchTerm] = React.useState("");
  const [focusedPersonID, updateFocusedPersonID] = React.useState(0);
  const [discCompareProfile, updateDiscCompareProfile] = React.useState<
    Tyto.DISCCompareProfile | undefined
  >(getCompareProfile(appStore, focusedPersonID || 0));
  const [filter, updateFilter] = React.useState<SearchFilter>(options[0].value);
  const [filteredUsers, updateFilteredUsers] = React.useState<
    Tyto.DISCProfileMini[]
  >(filterUsers(searchTerm, getFilteredUsers(users, selectedIDs, filter)));

  // * =========================================================
  // * <Effects> ===============================================
  // * [E-0]
  React.useEffect(() => {
    if (props.teamID) {
      const teamDISCMinis = getProfilesOfTeamMembers({
        AppStoreState: appStore.state,
        teamID: props.teamID,
        isCascade: false,
      });

      if (!teamDISCMinis) {
        loadMiniProfilesForTeam(props.teamID, appStore);
      }

      if (includeTeamsBelow) {
        const teamAndBelowDISCMinis = getProfilesOfTeamMembers({
          AppStoreState: appStore.state,
          teamID: props.teamID,
          isCascade: true,
        });

        const team = _.get(
          AdminStore,
          `state.teamsByTeamID[${props.teamID}]`,
          undefined
        );
        const domainID =
          _.get(team, "teamType") === "ocDOMAIN"
            ? _.get(team, `teamID`, undefined)
            : _.get(team, `domainID`, undefined);

        if (!teamAndBelowDISCMinis) {
          loadMiniProfilesForTeam(props.teamID, appStore, true, domainID);
        }
      }
    }
  }, [props.teamID, includeTeamsBelow]);

  // * [E-1]
  React.useEffect(() => {
    if (!props.teamID) {
      return;
    }
    const newUsers: Tyto.DISCProfileMini[] =
      getProfilesOfTeamMembers({
        AppStoreState: appStore.state,
        isCascade: includeTeamsBelow,
        teamID: props.teamID,
      }) || [];

    updateUsers(newUsers);
    updateFilteredUsers(
      filterUsers(searchTerm, getFilteredUsers(newUsers, selectedIDs, filter))
    );
    updateDiscCompareProfile(
      getCompareProfile(appStore, focusedPersonID || 0, newUsers, selectedIDs)
    );

    const newGroupStyle = calcGroupStyle(newUsers);

    updateCurTeamProfile(getGroupStyleWithStdDeviation(newGroupStyle));

    if (previewUser && previewUser.personID) {
      const curPreviewUserRedundant: Tyto.DISCProfileMini | undefined = _.get(
        appStore,
        `state.discMini[${previewUser.personID}]`,
        undefined
      );

      if (curPreviewUserRedundant) {
        updatePreviewUser(curPreviewUserRedundant);
      }
    }
  }, [
    _.get(appStore, `state.discMiniByTeam.${props.teamID}`),
    _.get(appStore, `state.discMiniByTeamAndBelow.${props.teamID}`),
    _.get(appStore, `state.discMini`),
    includeTeamsBelow,
  ]);

  // * [E-2] - Watch for changes to discCompareProfiles
  React.useEffect(() => {
    // const profile: Tyto.DISCCompareProfile | undefined | null = _.get(
    //   appStore,
    //   `state.discCompareProfiles.${focusedPersonID}`
    // );
    const profile = appStore.state?.discCompareProfiles?.[focusedPersonID];

    if (
      (profile && !discCompareProfile) ||
      (profile &&
        discCompareProfile &&
        profile.result.discID !== discCompareProfile.result.discID)
    ) {
      updateDiscCompareProfile(profile);
    }
  }, [_.get(appStore, `state.discCompareProfiles.${focusedPersonID}`)]);

  // * [E-3] - On SelectedIDs update, check if profile exists and if not load it
  React.useEffect(() => {
    const selectedUsers = getSelectedUsers(users, selectedIDs);
    if (Array.isArray(selectedUsers) && selectedUsers.length === 1) {
      const hasCompareProfile = !!getCompareProfile(
        appStore,
        selectedUsers[0].personID
      );

      if (!hasCompareProfile) {
        loadCompareProfile(appStore, selectedUsers[0].personID);
      }
    }
  }, [selectedIDs]);

  // * [E-4] - Handle change to Full Profile
  React.useEffect(() => {
    const parsedID = props.subID ? parseInt(props.subID) : 0;
    updateFocusedPersonID(parsedID || 0);

    const hasCompareProfile = !!getCompareProfile(appStore, parsedID);
    if (!hasCompareProfile) {
      loadCompareProfile(appStore, parsedID);
    }

    const focusedPersonProfile = users.find(
      (user) => user.personID === parsedID
    );
    if (focusedPersonProfile) {
      updatePreviewUser(focusedPersonProfile);
    }
  }, [props.subInterface, props.subID]);

  // * [E-5] - Handle change of focusedPerson or previewUser
  React.useEffect(() => {
    const selectedUsers = getSelectedUsers(users, selectedIDs);

    if (
      focusedPerson ||
      previewUser ||
      (Array.isArray(selectedUsers) && selectedUsers.length)
    ) {
      setUserSnapStyles({ marginRight: "305px" });
    } else {
      setUserSnapStyles({ marginRight: "2em" });
    }
  }, [focusedPersonID, previewUser, selectedIDs]);

  // * [E-6] - Handle change to Team
  // React.useEffect(() => {
  //   // TODO
  // }, [props.teamID]);
  // * </Effects> =================================================
  // * ============================================================

  const selectedUsers = getSelectedUsers(users, selectedIDs);
  const focusedPerson = getFocusedPerson(users, focusedPersonID);

  const targetCompareProfile = _.get(
    appStore,
    `state.discCompareProfiles.${focusedPersonID}`
  );
  const userDISCMini = _.get(appStore, "state.userDISCMini", undefined);
  const curTeamName = pullTeamNameFromProfile({
    teamID: props.teamID,
    profile: _.get(appStore, "state.personInfo", undefined),
  });
  const userHasManage = _.get(appStore, "state.userHasManage", undefined);
  // const resultsTotal = Array.isArray(users) ? users.length : 0;
  const isMobile = _.get(GeneralStore, "state.isMobile", false);
  const curTeamInfo: Tyto.Team | undefined = _.get(
    AdminStore,
    `state.teamsByTeamID[${props.teamID}]`,
    undefined
  );
  const teams = _.get(AdminStore, "state.teamsByTeamID", undefined);
  const teamsWithConfiguration = _.get(
    AdminStore,
    "state.teamsWithConfiguration",
    undefined
  );
  const memberships = _.get(
    AdminStore,
    "state.membershipsByPersonID",
    undefined
  );

  // console.log("CurTeamInfo: ", curTeamInfo);

  return (
    <SessionHOC path={props.path} uri={location?.pathname || ""}>
      <>
        {focusedPersonID ? (
          <PersonFullProfile
            discMiniProfile={focusedPerson}
            // discCompareProfile={discCompareProfile}
            discCompareProfile={targetCompareProfile}
            backButton={{
              // onClick: () => updateFocusedPersonID(0),
              onClick: () => {
                navigate(props.teamID ? `/team/${props.teamID}` : "/directory");
              },
              value: "Directory",
            }}
            userDISCMini={userDISCMini}
          />
        ) : (
          <section
            className={cx(
              "directory-interface",
              (!!selectedUsers.length || previewUser) && "side-pane-open"
            )}
          >
            <h1 className="directory-title title-bold">
              {curTeamName || _.get(curTeamInfo, "name", "")}
            </h1>
            {curTeamProfile && (
              <button
                className="directory-group-style"
                onClick={() => {
                  const userIDsMap = (filteredUsers || []).reduce(
                    (accum: { [x: number]: boolean }, user) => {
                      if (user && user.personID) {
                        accum[user.personID] = true;
                      }

                      return accum;
                    },
                    {}
                  );

                  updateSelectedIDs(userIDsMap);
                }}
              >
                {curTeamProfile.split("").map((letter) => (
                  <span
                    key={letter}
                    className={`directory-group-style-letter directory-group-style-letter-${letter}`}
                  >
                    {letter.toUpperCase()}
                  </span>
                ))}
              </button>
            )}
            {curTeamInfo && (
              <h5 className="team-title-parent-path">
                {curTeamInfo.parentNamePath
                  .split("\t")
                  .filter((tn) => tn)
                  .map((teamPathName, curIdx) => (
                    <span
                      className="team-title-parent-path-item"
                      key={teamPathName}
                    >
                      {!!curIdx && " / "}
                      {teamPathName}
                    </span>
                  ))}

                {/* {curTeamInfo.teamType === "ocPROJECT"} */}
              </h5>
            )}

            <SearchBar
              className="teams-team-searchbar"
              filterOptions={options}
              filter={filter}
              searchTerm={searchTerm}
              updateFilter={(value) => {
                updateFilter(value);
                updateFilteredUsers(
                  filterUsers(
                    searchTerm,
                    getFilteredUsers(users, selectedIDs, value)
                  )
                );
              }}
              updateSearchTerm={(value) => {
                updateSearchTerm(value);
                updateFilteredUsers(
                  filterUsers(
                    value,
                    getFilteredUsers(users, selectedIDs, filter)
                  )
                );
              }}
            />

            <DisplayedUsersCount
              clearSelectedUsers={() => updateSelectedIDs({})}
              includeTeamsBelow={includeTeamsBelow}
              selectCurrentlyShowingUsers={() => {
                const userIDsMap = (filteredUsers || []).reduce(
                  (accum: { [x: number]: boolean }, user) => {
                    if (user && user.personID) {
                      accum[user.personID] = true;
                    }

                    return accum;
                  },
                  { ...(selectedIDs || {}) }
                );

                updateSelectedIDs(userIDsMap);
              }}
              loading={
                !_.get(
                  appStore,
                  `state.discMiniByTeam${includeTeamsBelow ? "AndBelow" : ""}.${
                    props.teamID
                  }`,
                  undefined
                )
              }
              filteredUsersCount={filteredUsers.length}
              toggleIncludeTeamsBelow={() =>
                updateIncludeTeamsBelow(!includeTeamsBelow)
              }
              totalUsersCount={users.length}
            />

            <PeopleList
              AppStore={appStore}
              adminStore={AdminStore}
              loading={
                !_.get(
                  appStore,
                  `state.discMiniByTeam${includeTeamsBelow ? "AndBelow" : ""}.${
                    props.teamID
                  }`,
                  undefined
                )
              }
              previewUser={(previewPersonID) => {
                if (!Array.isArray(users)) {
                  updatePreviewUser(undefined);
                } else {
                  const newPreviewPerson = users.find(
                    (user) => user.personID === previewPersonID
                  );

                  if (newPreviewPerson && newPreviewPerson.personID) {
                    const previewPersonCompareGraph = _.get(
                      appStore,
                      `state.discCompareProfiles.${newPreviewPerson.personID}`
                    );

                    if (!previewPersonCompareGraph) {
                      loadCompareProfile(appStore, newPreviewPerson.personID);
                    }
                  }

                  updatePreviewUser(newPreviewPerson);
                }
              }}
              selectedIDs={selectedIDs}
              searchTerm={searchTerm}
              toggleUserID={(personID: number) => {
                const noCurSelectedUsers = !getSelectedUsers(users, selectedIDs)
                  .length;

                const newSelectedIDs = {
                  ...selectedIDs,
                  [personID]: !selectedIDs[personID],
                };
                updateSelectedIDs(newSelectedIDs);

                if (noCurSelectedUsers) {
                  updatePreviewUser(undefined);
                }

                updateFocusedPersonID(0);
                updateFilteredUsers(
                  filterUsers(
                    searchTerm,
                    getFilteredUsers(users, newSelectedIDs, filter)
                  )
                );
              }}
              users={filteredUsers}
            />
          </section>
        )}

        {!focusedPersonID && !!selectedUsers.length && (
          <SidePane
            canClose={true}
            canMinimize={isMobile}
            className={cx(
              "directory-sidepane",
              previewUser && "directory-sidepane-underneath"
            )}
            // discCompareProfile={discCompareProfile}
            discCompareProfile={_.get(
              appStore,
              `state.discCompareProfiles.${selectedUsers[0].personID}`
            )}
            discMini={selectedUsers}
            focusedPersonID={focusedPersonID}
            loggedInUserHasManage={userHasManage}
            loggedInUserID={_.get(appStore, "state.loggedInUserID", 0)}
            onClick={
              !!previewUser ? () => updatePreviewUser(undefined) : undefined
            }
            onClose={() => updateSelectedIDs({})}
            onPersonSelect={(personID) => {
              if (!Array.isArray(users)) {
                updatePreviewUser(undefined);
              } else {
                const newPreviewPerson = users.find(
                  (user) => user.personID === personID
                );

                if (newPreviewPerson && newPreviewPerson.personID) {
                  const previewPersonCompareGraph = _.get(
                    appStore,
                    `state.discCompareProfiles.${newPreviewPerson.personID}`
                  );

                  if (!previewPersonCompareGraph) {
                    loadCompareProfile(appStore, newPreviewPerson.personID);
                  }
                }

                updatePreviewUser(newPreviewPerson);
              }
            }}
            memberships={memberships}
            reloadTeam={(teamID: number) => {
              const targetTeam = teams ? teams[teamID] : undefined;

              if (targetTeam) {
                loadTeamsWithTeamToolsConfig({
                  AdminStore,
                  teams: [targetTeam],
                });
              }
            }}
            teamsWithConfiguration={teamsWithConfiguration}
            teams={teams}
          />
        )}

        {!focusedPersonID && previewUser && (
          <SidePane
            asModal={isMobile}
            canClose={true}
            className={cx(
              "directory-sidepane directory-preview-pane",
              !!selectedUsers.length && "directory-sidepane-overtop"
            )}
            canMinimize={false}
            closeOnBlur={true}
            discCompareProfile={_.get(
              appStore,
              `state.discCompareProfiles.${previewUser.personID}`
            )}
            discMini={[previewUser]}
            focusedPersonID={focusedPersonID}
            loggedInUserHasManage={userHasManage}
            loggedInUserID={_.get(appStore, "state.loggedInUserID", 0)}
            onClose={() => updatePreviewUser(undefined)}
            onPersonSelect={(personID) => {
              navigate(`/profile/${personID}`);
            }}
            memberships={memberships}
            reloadTeam={(teamID: number) => {
              const targetTeam = teams ? teams[teamID] : undefined;

              if (targetTeam) {
                loadTeamsWithTeamToolsConfig({
                  AdminStore,
                  teams: [targetTeam],
                });
              }
            }}
            teamsWithConfiguration={teamsWithConfiguration}
            teams={teams}
          />
        )}
      </>
    </SessionHOC>
  );
};

const getFilteredUsers = (
  users: Tyto.DISCProfileMini[],
  selectedIDs: { [x: number]: boolean } = {},
  filter: SearchFilter
) => {
  switch (filter) {
    case "selected":
      return users.filter(({ personID }) => !!selectedIDs[personID]);
    case "unselected":
      return users.filter(({ personID }) => !selectedIDs[personID]);
    case "disc-complete":
      return users.filter(({ styleKey3 }) => !!styleKey3);
    case "disc-incomplete":
      return users.filter(({ styleKey3 }) => !styleKey3);
    case "all":
    default:
      return users;
  }
};

const getCompareProfile = (
  AppStore: AppStoreProps,
  focusedPersonID: number,
  users?: Tyto.DISCProfileMini[],
  selectedIDs?: { [x: number]: boolean }
) => {
  if (!focusedPersonID && !Array.isArray(users)) {
    return undefined;
  }

  let targetID = focusedPersonID;

  if (!focusedPersonID && Array.isArray(users) && users.length) {
    const curSelectedUsers = getSelectedUsers(
      users,
      selectedIDs as { [x: number]: boolean }
    );

    if (curSelectedUsers.length) {
      targetID = curSelectedUsers[0].personID;
    }
  }

  const profile = _.get(
    AppStore,
    `state.discCompareProfiles[${targetID}]`
  ) as any as NonNullable<
    (typeof AppStore)["state"]
  >["discCompareProfiles"][number];

  return profile;
};

const loadCompareProfile = async (
  AppStore: AppStoreProps,
  focusedPersonID?: number
) => {
  if (
    AppStore.state &&
    focusedPersonID &&
    typeof AppStore.state.discCompareProfiles[focusedPersonID] === "undefined"
  ) {
    try {
      const discCompareProfile: any =
        await TytoCalls.DISCProfile.Interactive.get({
          personID: focusedPersonID,
        });
      if (AppStore.dispatch) {
        AppStore.dispatch({
          payload: {
            discCompareProfile: discCompareProfile
              ? discCompareProfile.discProfile
              : undefined,
          },
          type: "DISC_COMPARE_PROFILE_LOADED",
        });
      }

      return true;
    } catch (err) {
      return false;
    }
  }
};

const getFocusedPerson = (
  users: Tyto.DISCProfileMini[],
  focusedPersonID: number
) => {
  if (!focusedPersonID) {
    return undefined;
  }

  return users.find(({ personID }) => personID === focusedPersonID);
};

const getSelectedUsers = (
  users: Tyto.DISCProfileMini[],
  selectedIDs: { [x: number]: boolean }
) => {
  return users.filter(({ personID }) => selectedIDs[personID]);
};

// const getTeamRoot = ({ personInfo, teamMemberships }: { personInfo?: Tyto.Person, teamMemberships: Tyto.TeamMembership[] }) => {
//     if (!personInfo || !teamMemberships.length) {
//         return undefined;
//     }

//     return teamMemberships.find(({ TeamID }) => TeamID === personInfo.teamRoot);
// }

const filterUsers = (searchTerm: string, users: Tyto.DISCProfileMini[]) => {
  if (!searchTerm) {
    return users;
  }

  const regExp = new RegExp(_.escapeRegExp(searchTerm), "i");

  return _.filter(users, (user) => regExp.test(user.personName));
};

const pullTeamNameFromProfile = (info: {
  teamID?: string;
  profile?: Tyto.Person;
}) => {
  const teams = _.get(info.profile, "teamMemberships", undefined);

  if (!Array.isArray(teams) || !info.teamID) {
    return "";
  }

  const parsedTeamID = parseInt(info.teamID);
  const targetTeam = teams.find((team: any) => team.TeamID === parsedTeamID);

  return (targetTeam && targetTeam.teamName) || "";
};
