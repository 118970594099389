/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Button, { Props as ButtonProps } from "../button/";

interface Props {
  buttonProps: ButtonProps;
}

export default (props: Props) => {
  return (
    <Button
      {...props.buttonProps}
      className={cx("cc-multiplechoice-option", props.buttonProps.className)}
    />
  );
};
