import * as _ from "lodash";

import { WS_SERVER_URL } from "../constants/";
import { AppStoreProps } from "../stores/AppStore";
import { messageTypes, sendMessageTypes } from "./constants/";
import {
  getValidMessage,
  getMessageFromString,
  tellAppStoreWebSocketConnectionChanged,
} from "./utils/helpers";
import {
  reloadDISCData,
  reloadDISCDataForMultipleUsers,
  updateUserConnectionsStatus,
  updateSingleUserConnectionStatus,
} from "./utils/";
import { Console } from "console";
import message from "../../components/common/message";

const WS_SERVER_UID = String.fromCharCode(
  ...Array.from({ length: 40 }, () => {
    let number = Math.random() * 42 + 49;

    if (number > 57 && number < 65) {
      if (Math.random() > 0.49) {
        number += 10;
      } else {
        number -= 10;
      }
    }

    return number;
  })
)
  .split("")
  .map((char) => (Math.random() > 0.49 ? char.toLocaleLowerCase() : char))
  .join("");

interface TeamToolsWebSocketClient {
  // AppStoreRef: AppStoreProps | undefined;
  isAlive: boolean;
  initialize: (args: {
    callback?: () => void;
    id?: string | undefined;
    AppStore?: AppStoreProps;
  }) => void;
  _addEventListeners: () => void;
  _reestablishConnection: () => void;
  announceUpdates: (userIDs: number[]) => void;
  closeConnection: () => void;
  handleBacklog: () => void;
  getConnectionCallback: () => WebSocket | undefined;
  onClose: (data?: { reason?: string; callback?: () => void }) => void;
  onConnection: () => void;
  onError: (ev: Event) => void;
  onMessage: (ev: MessageEvent) => void;
  requestConnectionStatusOfUsers: (userIDs: number[]) => void;
  setAppStore: (AppStore?: AppStoreProps) => void;
  setLastActivityDate: (userID: number) => void;
  setisAdminForConnection: (isAdmin?: boolean) => void;
  setUserIDForConnection: (userID: number) => void;
  send: (data: WebSocketClient.SendParams, isBackLog?: boolean) => void;
  watchMinis: (userIDs: number[]) => void;
}

let AppStoreRef: AppStoreProps | undefined;
let Connection: WebSocket | undefined;
let activelyWatchedUserIDs = new Set<number>();

let messageBacklog: WebSocketClient.SendParams[] = [];

const WebSocketClient: TeamToolsWebSocketClient = {
  // AppStoreRef: undefined,
  isAlive: false,
  initialize: ({
    callback,
    id,
    AppStore,
  }: {
    callback?: () => void;
    id?: string;
    AppStore?: AppStoreProps;
  }) => {
    AppStoreRef = AppStore;
    const _id = id || WS_SERVER_UID;

    const connection = new WebSocket(`${WS_SERVER_URL}/${_id}`);

    Connection = connection;

    if (Connection) {
      // * Setup Event listeners
      console.warn("(::WebSocket::) Connection setup initiated!");
      WebSocketClient._addEventListeners();
    } else {
      console.warn("(::WebSocket::) Connection Failed to start setup.");
    }

    // console.log("Connection: ", Connection);

    if (callback) {
      callback();
    }
  },
  _addEventListeners: () => {
    if (!Connection) {
      console.log(
        "(::WebSocket::) Connection expected to be defined but was undefined."
      );
      return;
    }

    // * On Open (Connection is newly established) ==============
    Connection.addEventListener("open", WebSocketClient.onConnection);

    // * On Connection Closed (be either self or server) ========
    Connection.addEventListener("close", WebSocketClient.onClose);

    // * On Message (Received from server) ======================
    Connection.addEventListener("message", WebSocketClient.onMessage);

    // * On Error ===============================================
    Connection.addEventListener("error", WebSocketClient.onError);
  },
  _reestablishConnection: () => {
    // ? Handle is a different ID was passed in original connection ?
    console.log("(::WebSocket::) Attempting to reestablish connection...");

    // * Once Connection is hopefully reestablished, send all backlogged messages
    WebSocketClient.initialize({
      AppStore: AppStoreRef,
    });
  },
  closeConnection: (data?: { reason?: string; callback?: () => void }) => {
    if (!Connection) {
      WebSocketClient.isAlive = false;
      console.warn(
        "(::WebSocket::) Connection Close event triggered but WebSocket Connection not found?? Nothing to close."
      );
      return;
    }

    Connection.close();
    WebSocketClient.isAlive = false;

    if (data && data.callback) {
      data.callback();
    }
  },
  getConnectionCallback: () => {
    return Connection;
  },
  handleBacklog: () => {
    if (!messageBacklog || !messageBacklog.length) {
      console.log(
        "(::WebSocket::) Backlog found to be empty: ",
        messageBacklog
      );
      return;
    }

    if (!Connection) {
      console.log(
        "(::WebSocket::) Attemping to clear backlog but Connection is not found."
      );
      return;
    }

    // * [1] Iterate over and send each message
    messageBacklog.forEach((message) => WebSocketClient.send(message, true));

    // * [2] Clear backlog as it *should* be all sent now
    messageBacklog = [];
  },
  onClose: () => {
    WebSocketClient.isAlive = false;

    tellAppStoreWebSocketConnectionChanged({
      isConnected: false,
      AppStore: AppStoreRef,
    });
  },
  onConnection: () => {
    if (!Connection) {
      console.warn(
        "(::WebSocket::) Connection event triggered but WebSocket Connection not found??"
      );
      return;
    }

    WebSocketClient.isAlive = true;

    tellAppStoreWebSocketConnectionChanged({
      isConnected: true,
      AppStore: AppStoreRef,
    });

    console.log("(::WebSocket::) Connection Established!");

    if (messageBacklog && messageBacklog.length) {
      WebSocketClient.handleBacklog();
    }
  },
  onError: (ev: Event) => {
    // TODO
  },
  onMessage: (ev: MessageEvent) => {
    if (!Connection) {
      console.warn(
        "(::WebSocket::) Message event triggered but WebSocket Connection not found??"
      );
      return;
    }

    console.log(
      "(::WebSocket::) Received Message Data; raw message: ",
      ev.data
    );

    const messageParsed = getMessageFromString(ev.data);

    if (!messageParsed) {
      console.warn(
        "(::WebSocket::) Unexpected message received and parse returned null."
      );
      return;
    }

    switch (messageParsed.type) {
      case messageTypes.MINI_CHANGED:
        if (AppStoreRef) {
          reloadDISCData({
            AppStore: AppStoreRef,
            userID: _.get(messageParsed, "payload.userID", 0),
          });
        }
        return;
      case messageTypes.MULTIPLE_MINIS_CHANGED:
        if (AppStoreRef) {
          reloadDISCDataForMultipleUsers({
            AppStore: AppStoreRef,
            userIDs: _.get(messageParsed, "payload.userIDs", 0),
          });
        }
        return;
      case messageTypes.REQUESTED_USERS_CONNECTIONS_STATUS:
        if (AppStoreRef) {
          updateUserConnectionsStatus({
            AppStore: AppStoreRef,
            userConnectionsStatus: _.get(
              messageParsed,
              "payload.usersConnectionsInfo",
              []
            ),
          });
        }
        return;
      case messageTypes.USER_ACTIVITY_CHANGED:
        if (AppStoreRef) {
          updateSingleUserConnectionStatus({
            AppStore: AppStoreRef,
            userConnectionStatus: _.get(messageParsed, "payload", []),
          });
        }

        return;
      case messageTypes.USER_ACTION_CONFIRMATION:
        const actionconf_actionType = _.get(
          messageParsed,
          "payload.actionType",
          ""
        );
        const actionconf_wasSuccess = _.get(
          messageParsed,
          "payload.wasSuccess",
          ""
        );

        if (actionconf_actionType) {
          switch (actionconf_actionType) {
            case "set-isadmin":
              if (actionconf_wasSuccess) {
                const activelyWatchedUserIDsAsArr = Array.from(
                  activelyWatchedUserIDs || []
                );

                if (
                  activelyWatchedUserIDsAsArr &&
                  activelyWatchedUserIDsAsArr.length
                ) {
                  WebSocketClient.requestConnectionStatusOfUsers(
                    activelyWatchedUserIDsAsArr
                  );
                }
              }

              break;
            default:
              break;
          }
        }

        return;
      case messageTypes.USER_EXCEPTION_PING:
        // if ()
        // TODO: Handle exception ping based on data.payload.errorMsg

        return;
      default:
        return;
    }
  },
  setAppStore: (AppStore?: AppStoreProps) => {
    if (AppStore) {
      AppStoreRef = AppStore;
      console.log("(::WebSocket::) Set AppStoreRef again.");
    }
  },
  send: (data: WebSocketClient.SendParams, isBackLog?: boolean) => {
    if (!Connection || !WebSocketClient.isAlive) {
      if (!isBackLog) {
        messageBacklog.push(data);
      }

      console.warn(
        "(::WebSocket::) Send event triggered but WebSocket Connection not found??"
      );
      return;
    }

    const validMessage = getValidMessage(data);

    if (validMessage) {
      console.log("(::WebSocket::) Message Sending... ", validMessage);
      try {
        Connection.send(validMessage);
      } catch (err) {
        console.warn("Error occurred!: ", err);
      }
    } else {
      console.warn(
        "(::WebSocket::) Message send aborted, data found to be invalid: ",
        data
      );
    }
  },
  // * =================================================
  // * Dev Friendly wrappers around send method ========
  // * =================================================
  announceUpdates: (userIDs: number[]) => {
    if (!Array.isArray(userIDs) || !userIDs.length) {
      console.warn(
        "(::WebSocket::) Update(s) Announcement aborted; userIDs was missing, empty, or invalid type: ",
        userIDs
      );
      return;
    }

    WebSocketClient.send({
      type: sendMessageTypes.MINIS_UPDATED as "mini-updated",
      payload: {
        userIDs,
      },
    });
  },
  requestConnectionStatusOfUsers: (userIDs: number[]) => {
    console.log(
      "requestConnectionStatusOfUsers triggered and userIDs length is: ",
      _.get(userIDs, "length", "undefined")
    );
    if (!userIDs || !userIDs.length) {
      return;
    }

    WebSocketClient.send({
      type: sendMessageTypes.GET_USERS_CONNECTION_STATUS as "get-users-connections-status",
      payload: {
        userIDs,
      },
    });
  },
  setLastActivityDate: () => {
    const lastActivity = new Date().toISOString();

    WebSocketClient.send({
      type: sendMessageTypes.UPDATE_LAST_ACTIVITY as "update-last-activity",
      payload: {
        lastActivity,
      },
    });
  },
  setisAdminForConnection: (isAdmin?: boolean) => {
    if (isAdmin === undefined) {
      return;
    }

    WebSocketClient.send({
      type: sendMessageTypes.UPDATE_CONNECTION_USER_METADATA as "update-connection-user-metadata",
      payload: {
        isAdmin: !!isAdmin,
      },
    });
  },
  setUserIDForConnection: (userID: number) => {
    if (!userID) {
      return;
    }

    WebSocketClient.send({
      type: sendMessageTypes.UPDATE_CONNECTION_USER_METADATA as "update-connection-user-metadata",
      payload: {
        userID,
      },
    });
  },
  watchMinis: (userIDs: number[]) => {
    if (!Array.isArray(userIDs) || !userIDs.length) {
      console.warn(
        "(::WebSocket::) Minis Watch setup aborted; userIDs was missing, empty, or invalid type: ",
        userIDs
      );
      return;
    }

    const userIDsNotAlreadyWatched = userIDs.filter(
      (userID) => !activelyWatchedUserIDs.has(userID)
    );

    if (!userIDsNotAlreadyWatched.length) {
      console.log(
        "(::WebSocket::) Message to watch watch userIDs aborted because all userIDs are already being watched."
      );
      return;
    }

    if (userIDsNotAlreadyWatched.length === 1) {
      WebSocketClient.send({
        type: sendMessageTypes.WATCH_MINI as "watch-mini",
        payload: {
          userID: userIDsNotAlreadyWatched[0],
        },
      });
    } else {
      WebSocketClient.send({
        type: sendMessageTypes.WATCH_MULTIPLE as "watch-multiple",
        payload: {
          userIDs: userIDsNotAlreadyWatched,
        },
      });
    }

    // TODO
    // ! Should Setup a response from Server to know for sure these are being watched Server Side
    userIDsNotAlreadyWatched.forEach((userID) =>
      activelyWatchedUserIDs.add(userID)
    );

    // WebSocketClient.requestConnectionStatusOfUsers(userIDsNotAlreadyWatched);
  },
};

export default WebSocketClient;
