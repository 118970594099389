/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import {
  Icon,
  TextButton,
  ToggleSection,
} from "../../../../../components/common/";
import { Tyto } from "../../../../../typings/tyto";

import "./UserTeamMemberships.scss";

interface Props {
  isOwnProfile: boolean;
  personName: string;
  memberships: Tyto.AdvancedPersonTeamMembership[];
  teams?: { [x: number]: Tyto.Team };
}

export default (props: Props) => {
  const navigate = useNavigate();

  return (
    <ToggleSection
      className=""
      closedAtStart={true}
      title={`${props.isOwnProfile ? "Your" : `${props.personName}'s`} Teams`}
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        return (
          <ul className="meta-sidepane-person-usertemmemberships-list">
            {props.memberships.map((membership) => (
              <li
                className="meta-sidepane-person-usertemmemberships-list-item"
                key={membership.teamID}
              >
                <TextButton
                  className="meta-sidepane-person-usertemmemberships-list-item-name"
                  onClick={() => navigate(`/team/${membership.teamID}`)}
                  value={_.get(props.teams, `${membership.teamID}.name`, "")}
                />

                {/* <Icon
                  className="meta-sidepane-person-usertemmemberships-list-item-icon"
                  icon="arrow-from-left"
                  size={14}
                /> */}
              </li>
            ))}
          </ul>
        );
      }}
    />
  );
};

// {userMemberships && (
//     <p>
//       <ContextMenu
//         containerClassName=""
//         icon={{
//           icon: "address-book",
//           size: 12
//         }}
//         menuItems={userMemberships.map(memb => ({
//           onClick: () => navigate(`/team/${memb.teamID}`),
//           value: `${memb.teamID}`
//         }))}
//       />
//     </p>
//   )}
