/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";

import { Tyto } from "../../../../typings/tyto";
import { TextButton } from "../../../common/";

import "./TeamBreadCrumb.scss";

interface BreadCrumb {
  iPath?: string;
  name: string;
  teamID: string;
}

interface Props {
  className?: string;
  onTeamSelect: (teamID: number) => void;
  team?: Tyto.Team;
}

export default (props: Props) => {
  const [breadCrumb, updateBreadCrumb] = React.useState<BreadCrumb[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (props.team) {
      const splitPath = props.team.iPath.split(",").filter((item) => item);
      const splitNames = props.team.parentNamePath
        .split("\t")
        .filter((item) => item);

      splitPath.push(`${props.team.teamID}`);
      splitNames.push(props.team.name);

      const newBreadCrumbs: BreadCrumb[] = splitPath.map((id, curIdx) => ({
        teamID: id,
        name: splitNames[curIdx] || "",
      }));

      updateBreadCrumb(newBreadCrumbs || []);
    }
  }, [props.team]);

  return (
    <>
      <h2 className={cx("meta-super-sidepane-breadcrumb", props.className)}>
        {breadCrumb.map((crumb, curIdx) => (
          <>
            {!!curIdx && <span>{" > "}</span>}

            <TextButton
              className={cx(
                "meta-super-sidepane-breadcrumb-item",
                curIdx + 1 === breadCrumb.length &&
                  "meta-super-sidepane-breadcrumb-item-cur-team"
              )}
              onClick={() => props.onTeamSelect(parseInt(crumb.teamID))}
              value={crumb.name}
            />
          </>
        ))}
      </h2>

      {props.team && (
        <TextButton
          className="meta-super-sidepane-breadcrumb-link"
          onClick={() => navigate(`team/${props.team ? props.team.teamID : 0}`)}
          iconProps={{
            className: "meta-super-sidepane-breadcrumb-link-icon",
            icon: "arrow-from-left",
            size: 12,
          }}
          iconSide="right"
          value="Go To Team"
        />
      )}
    </>
  );
};
