import { ENDPOINT_LOGIN_RESET_PASSWORD_URL } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_LOGIN_RESET_PASSWORD_URL;

const timeoutMinutes = 60 * 24 * 90;

export const LoginResetPassword = {
  post(
    params: Endpoints.Tyto.Login.ResetPassword.PostParameters,
    callOpts?: CallOpts
  ): Promise<{ error?: any; session: SessionData }> {
    return callWrapper(
      "post",
      endpoint,
      { ...(params || {}), timeoutMinutes },
      { ...(callOpts || {}), omitSessionKey: true }
    ) as Promise<{
      error?: any;
      session: SessionData;
    }>;
  }
};
