import { AccountSession } from "./AccountSession";
import { ConfigurationClient } from "./Configuration.Client";
import { Domain } from "./Domain";
import { DomainImage } from "./Domain.Image";
import { DISCProfileInteractive } from "./DiscProfile.Interactive";
import { DISCProfileInteractiveMockup } from "./DiscProfile.Interactive.Mockup";
import { DISCProfileTeam } from "./DiscProfile.Team";
import { DISCProfileMini } from "./DiscProfile.Mini";
import { DISCProfiles } from "./DiscProfiles";
import { DISCVendorForSearch } from "./DISC.Vendor.ForSearch";
import { DISCVendorForTake } from "./DISC.Vendor.ForTake";
import { DISC247FindSelfAssessments } from "./DISC247.FindSelfAssessments";
import { DISC247ImportSelfAssessment } from "./DISC247.ImportSelfAssessment";
import { DISCProfilesEmailViewReady } from "./DiscProfiles.EmailViewReady";
import { DISCProfilesMini } from "./DiscProfilesMini";
import { DomainBilling } from "./DomainBilling";
import { DomainBillings } from "./DomainBillings";
import { DomainInvitationEmail } from "./DomainInvitationEmail";
import { DomainInvitationEmailTemplate } from "./DomainInvitationEmail.Template";
import { DomainMessage } from "./Domain.Message";
import { DomainMessages } from "./Domain.Messages";
import { EmailQueueStatus } from "./EmailQueueStatus";
import { EmailQueueStatusUser } from "./EmailQueueStatus.User";
import { Lesson } from "./Lesson";
import { LoginAuthenticate } from "./LoginAuthenticate";
import { LoginAuthenticate4 } from "./Login.Authenticate4";
import { LoginRecover } from "./Login.Recover";
import { LoginRecoverTeamTools } from "./Login.Recover.TeamTools";
import { LoginResetPassword } from "./Login.ResetPassword";
import { LoginResetPasswordValidate } from "./Login.ResetPassword.Validate";
import { Menu } from "./Menu";
import { Person } from "./Person";
import { PersonMyPassword } from "./Person.MyPassword";
import { PersonPassword } from "./Person.Password";
import { ProfilePhoto } from "./Person.ProfilePhoto";
import { PeopleKeysInitialize } from "./PeopleKeys.Initialize";
import { PeopleKeysNextPage } from "./PeopleKeys.NextPage";
import { PeopleKeysPages } from "./PeopleKeys.Pages";
import { PeopleKeysReport } from "./PeopleKeysCallback.Report";
import { PeopleKeysResponse } from "./PeopleKeys.Response";
import { PersonAdvanced } from "./PersonAdvanced";
import { Logout } from "./Session.Logout";
import { SaveForLesson } from "./SaveForLesson";
import { Team } from "./Team";
import { Teamboard } from "./Teamboard";
import { TeamsByFunction } from "./TeamsByFunction";
import { TeamMembershipPerson } from "./TeamMembership.Person";
import { TeamToolsConfig } from "./TeamTools.Config";
import { TeamToolsEmailLogin } from "./TeamTools.EmailLogin";
import { TeamToolsInvite } from "./TeamToolsInvite";
import { TeamToolsInviteEmail } from "./TeamToolsInvite.Email";
import { TeamToolsInviteTempSession } from "./TeamToolsInvite.tempSession";
import { Upload } from "./Upload";
import * as Vendor247 from "./vendor-247/";

export default {
  AccountSession,
  Configuration: {
    Client: ConfigurationClient,
  },
  DISCProfiles: {
    ...DISCProfiles,
    EmailViewReady: DISCProfilesEmailViewReady,
  },
  DISCProfile: {
    Interactive: DISCProfileInteractive,
    InteractiveMockup: DISCProfileInteractiveMockup,
    Mini: DISCProfileMini,
    Team: DISCProfileTeam,
  },
  DISCProfilesMini,
  DISC: {
    Vendor: {
      ForTake: DISCVendorForTake,
      ForSearch: DISCVendorForSearch,
    },
  },
  DISC247: {
    FindSelfAssessments: DISC247FindSelfAssessments,
    ImportSelfAssessment: DISC247ImportSelfAssessment,
  },
  DomainBilling,
  DomainBillings,
  Domain: {
    ...Domain,
    Image: DomainImage,
    Message: DomainMessage,
    Messages: DomainMessages,
  },
  DomainInvitationEmail: {
    ...DomainInvitationEmail,
    Template: DomainInvitationEmailTemplate,
  },
  EmailQueueStatus: {
    ...EmailQueueStatus,
    User: EmailQueueStatusUser,
  },
  Lesson,
  LoginAuthenticate,
  // LoginRecover,
  Login: {
    Authenticate4: LoginAuthenticate4,
    ResetPassword: {
      ...LoginResetPassword,
      Validate: LoginResetPasswordValidate,
    },
    Recover: {
      ...LoginRecover,
      TeamTools: LoginRecoverTeamTools,
    },
  },
  Menu,
  PeopleKeys: {
    Initialize: PeopleKeysInitialize,
    NextPage: PeopleKeysNextPage,
    Pages: PeopleKeysPages,
    Report: PeopleKeysReport,
    Response: PeopleKeysResponse,
  },
  Person: {
    ...Person,
    MyPassword: PersonMyPassword,
    Password: PersonPassword,
    ProfilePhoto,
  },
  PersonAdvanced,
  SaveForLesson,
  Session: {
    Logout,
  },
  Team,
  Teamboard,
  TeamsByFunction,
  TeamMembership: {
    Person: TeamMembershipPerson,
  },
  TeamTools: {
    Config: TeamToolsConfig,
    EmailLogin: TeamToolsEmailLogin,
  },
  TeamToolsInvite: {
    ...TeamToolsInvite,
    Email: TeamToolsInviteEmail,
    TempSession: TeamToolsInviteTempSession,
  },
  Upload,
  Vendor247: {
    Assessment: Vendor247.Vendor247Assessment,
    ConvertToResponse: Vendor247.Vendor247ConvertToResponse,
    DISCQuestionnaire: {
      ...Vendor247.Vendor247DISCQuestionnaire,
      Validate: Vendor247.DISC247QuestionnaireValidate,
    },
    DirectDISCQuestionnaire: Vendor247.Vendor247DISCQuestionnaire,
    Extension: Vendor247.DISC247Extension,
    FindUsers: Vendor247.Vendor247FindUsers,
    Register: Vendor247.Vendor247Register,
    ValidateAnswered: Vendor247.Vendor247ValidateAnswered,
  },
};
