/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface TextAreaProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  name?: string;
  minRows?: number;
  onChange: (newVal: string) => void;
  placeholder?: string;
  value: string;
}

export default (props: TextAreaProps) => {
  return (
    <textarea
      autoFocus={props.autoFocus}
      className={cx("cc-textarea", props.className)}
      disabled={props.disabled}
      maxLength={props.maxLength}
      minLength={props.minLength}
      name={props.name}
      onChange={(e) => props.onChange((e.target as HTMLTextAreaElement).value)}
      placeholder={props.placeholder}
      style={
        props.minRows
          ? {
              minHeight: `${props.minRows * 14}px`,
            }
          : undefined
      }
      value={props.value}
    />
  );
};
