import * as _ from "lodash";
import { AppStoreProps, UserOnlineInfo } from "../../stores/AppStore";

import { CALL_SOURCES } from "../../constants/";
import TytoCalls from "../../tyto/";
// import { sendMessageTypes } from "../constants/";

// * START RELOADING DISC DATA FOR MULTIPLE USERS
export async function reloadDISCDataForMultipleUsers({
  userIDs,
  AppStore,
}: {
  userIDs: number[];
  AppStore: AppStoreProps;
}) {
  try {
    // * [1] - Reload Minis
    reloadMinis({
      userIDs,
      AppStore,
    });

    // * [2] - Reload Interactive Reports
    reloadInteractiveReports({
      userIDs,
      AppStore,
    });
  } catch (err) {
    // TODO
  }
}

// * START RELOADING DISC DATA FOR A SINGLE USER
export async function reloadDISCData({
  userID,
  AppStore,
}: {
  userID: number;
  AppStore: AppStoreProps;
}) {
  try {
    reloadMini({
      userID,
      AppStore,
    });
    reloadInteractiveReport({
      userID,
      AppStore,
    });
  } catch (err) {
    // TODO
  }
}

// * RELOADING MULTIPLE DISCS AT ONCE
export async function reloadMinis({
  userIDs,
  AppStore,
}: {
  userIDs: number[];
  AppStore: AppStoreProps;
}) {
  try {
    if (!AppStore || !AppStore.state) {
      console.warn(
        "(::WebSocket::) Aborted DISC Mini[s] reload because AppStore was not fully defined"
      );
      return;
    }

    const filteredUserIDs = userIDs.reduce(
      (accum: number[], userID: number) => {
        const miniExists = !!_.get(
          AppStore,
          `state.discMini[${userID}]`,
          undefined
        );
        const existsAsNull =
          miniExists ||
          _.get(AppStore, `state.discMiniNullProfiles[${userID}]`) ||
          _.get(AppStore, "state.discMiniNull", []).some(
            (personID: number) => personID === userID
          );
        const isPersonalDISCMini =
          userID === _.get(AppStore, "state.loggedInUserID", 0);

        if (!miniExists && !existsAsNull && !isPersonalDISCMini) {
          console.warn(
            "(::WebSocket::) DISC Mini filtered from multuple reload because target Mini was not already found in local Store"
          );
        } else {
          accum.push(userID);
        }

        return accum;
      },
      []
    );

    const discMiniResp = await TytoCalls.DISCProfilesMini.get({
      personIDs: filteredUserIDs.join(","),
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: discMiniResp.discProfiles,
          source: CALL_SOURCES.WEBSOCKET,
          // overrideCurrentData: true
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });

      // * Search for own discMini
      const personalMini = (discMiniResp.discProfiles || []).find(
        (profile) =>
          profile.personID === _.get(AppStore, "state.loggedInUserID", 0)
      );

      if (personalMini) {
        AppStore.dispatch({
          payload: {
            userDISCMini: personalMini,
            userID: _.get(AppStore, "state.loggedInUserID", 0),
            source: CALL_SOURCES.WEBSOCKET,
          },
          type: "USER_PERSONAL_DISC_MINI",
        });
      }
    }
  } catch (err) {
    // TODO
    console.warn(
      "(::WebSocket::) Error occurred while reloading DISC Mini via WebSocket Message"
    );
  }
}

// * RELOADING A SINGLE DISC
export async function reloadMini({
  userID,
  AppStore,
}: {
  userID: number;
  AppStore: AppStoreProps;
}) {
  try {
    if (!AppStore || !AppStore.state) {
      console.warn(
        "(::WebSocket::) Aborted DISC Mini reload because AppStore was not fully defined"
      );
      return;
    }

    debugger;

    const miniExists = !!_.get(
      AppStore,
      `state.discMini[${userID}]`,
      undefined
    );
    const existsAsNull =
      miniExists ||
      _.get(AppStore, `state.discMiniNullProfiles[${userID}]`) ||
      _.get(AppStore, "state.discMiniNull", []).some(
        (personID: number) => personID === userID
      );
    const isPersonalDISCMini =
      userID === _.get(AppStore, "state.loggedInUserID", 0);

    if (!miniExists && !existsAsNull && !isPersonalDISCMini) {
      console.warn(
        "(::WebSocket::) DISC Mini Reload Aborted because target Mini was not already found in local Store"
      );
      return;
    }

    const discMiniResp = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${userID}`,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discMini: discMiniResp.discProfiles,
          source: CALL_SOURCES.WEBSOCKET,
          // overrideCurrentData: true
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });

      if (
        discMiniResp.discProfiles[0].personID ===
        _.get(AppStore, "state.loggedInUserID", 0)
      ) {
        AppStore.dispatch({
          payload: {
            userDISCMini: discMiniResp.discProfiles[0],
            userID: _.get(AppStore, "state.loggedInUserID", 0),
            source: CALL_SOURCES.WEBSOCKET,
          },
          type: "USER_PERSONAL_DISC_MINI",
        });
      }
    }
  } catch (err) {
    // TODO
    console.warn(
      "(::WebSocket::) Error occurred while reloading DISC Mini via WebSocket Message"
    );
  }
}

// * RELOAD MULTIPLE INTERACTIVE REPORTS
export async function reloadInteractiveReports({
  userIDs,
  AppStore,
}: {
  userIDs: number[];
  AppStore: AppStoreProps;
}) {
  try {
    if (!AppStore || !AppStore.state) {
      console.warn(
        "(::WebSocket::) Aborted DISC Interactive Report[s] reload because AppStore was not fully defined"
      );
      return;
    }

    const filteredUserIDs = (userIDs || []).reduce(
      (accum: number[], userID: number) => {
        const compareProfileExistsLocally = !!_.get(
          AppStore,
          `state.discCompareProfiles[${userID}]`,
          undefined
        );

        if (!compareProfileExistsLocally) {
          console.warn(
            "(::WebSocket::) DISC Interactive Report filter out of reload because target Report was not already found in local Store"
          );
        } else {
          accum.push(userID);
        }

        return accum;
      },
      []
    );

    if (!filteredUserIDs.length) {
      return;
    }

    const calls = filteredUserIDs.map((userID) =>
      TytoCalls.DISCProfile.Interactive.get({
        personID: userID,
      })
    );

    const interactiveResp = await Promise.all(calls);

    const compareProfiles = interactiveResp.map(
      (singleResp) => singleResp.discProfile
    );

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discCompareProfile: compareProfiles,
          source: CALL_SOURCES.WEBSOCKET,
        },
        type: "DISC_COMPARE_PROFILES_LOADED",
      });
    }
  } catch (err) {
    // TODO
    console.warn(
      "(::WebSocket::) Error occurred while reloading DISC Interactive Report via WebSocket Message"
    );
  }
}

// * RELOAD A SINGLE INTERACTIVE REPORT
export async function reloadInteractiveReport({
  userID,
  AppStore,
}: {
  userID: number;
  AppStore: AppStoreProps;
}) {
  try {
    if (!AppStore || !AppStore.state) {
      console.warn(
        "(::WebSocket::) Aborted DISC Interactive Report reload because AppStore was not fully defined"
      );
      return;
    }

    const compareProfileExistsLocally = !!_.get(
      AppStore,
      `state.discCompareProfiles[${userID}]`,
      undefined
    );

    if (!compareProfileExistsLocally) {
      console.warn(
        "(::WebSocket::) DISC Interactive Report reload Aborted because target Report was not already found in local Store"
      );
      return;
    }

    const interactiveResp = await TytoCalls.DISCProfile.Interactive.get({
      personID: userID,
    });

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discCompareProfile: interactiveResp.discProfile,
          source: CALL_SOURCES.WEBSOCKET,
        },
        type: "DISC_COMPARE_PROFILE_LOADED",
      });
    }
  } catch (err) {
    // TODO
    console.warn(
      "(::WebSocket::) Error occurred while reloading DISC Interactive Report via WebSocket Message"
    );
  }
}

export async function updateSingleUserConnectionStatus({
  AppStore,
  userConnectionStatus,
}: {
  AppStore: AppStoreProps;
  userConnectionStatus: any;
}) {
  const userID = _.get(userConnectionStatus, "userID", 0);
  const connectionsCount = _.get(
    userConnectionStatus,
    "connectionsCount",
    undefined
  );
  const lastActivity = _.get(userConnectionStatus, "lastActivity", "");

  if (!userID || connectionsCount === undefined) {
    return;
  }

  updateUserConnectionsStatus({
    AppStore,
    userConnectionsStatus: [
      {
        userID,
        connectionsCount,
        lastActivity,
      },
    ],
  });
}

export async function updateUserConnectionsStatus({
  AppStore,
  userConnectionsStatus,
}: {
  AppStore: AppStoreProps;
  userConnectionsStatus: any[];
}) {
  try {
    if (!AppStore || !AppStore.state) {
      console.warn(
        "(::WebSocket::) Aborted updating users connections status set because AppStore was not fully defined"
      );
      return;
    }

    if (AppStore.dispatch) {
      AppStore.dispatch({
        type: "UPDATE_USERS_CONNECTIONS_TABLE",
        payload: {
          userConnections: userConnectionsStatus as UserOnlineInfo[],
        },
      });
    }
  } catch (err) {
    console.warn(
      "(::WebSocket::) Error occurred while interpretting usersConnectionStatus message via WebSocket Message"
    );
  }
}

// async function singleInteractiveReportCall({
//     AppStore,
//     userID
// }: {
//     AppStore: AppStoreProps;
//     userID: number;
// }) {
//     if (!AppStore || !AppStore.state) {
//         console.warn(
//           "(::WebSocket::) Aborted DISC Interactive Report reload because AppStore was not fully defined"
//         );
//         return;
//       }

//       const compareProfileExistsLocally = !!_.get(
//         AppStore,
//         `state.discCompareProfiles[${userID}]`,
//         undefined
//       );

//       if (!compareProfileExistsLocally) {
//         console.warn(
//           "(::WebSocket::) DISC Interactive Report reload Aborted because target Report was not already found in local Store"
//         );
//         return;
//       }

//       return TytoCalls.DISCProfile.Interactive.get({
//         personID: userID,
//       });
// }
