/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import PlainText from "./PlainText";

import "./HTMLBody.scss";

interface Props {
  htmlBody: string;
  plainText: string;
}

export default (props: Props) => {
  const [hasScriptTag, updateHasScriptTag] = React.useState(
    checkIfHTMLHasScriptTab(props.htmlBody)
  );

  React.useEffect(() => {
    if (props.htmlBody) {
      updateHasScriptTag(checkIfHTMLHasScriptTab(props.htmlBody));
    }
  }, [props.htmlBody]);


  if (hasScriptTag) {
    return <PlainText plainText={props.plainText} />;
  }

  return (
    <div className="modals-domainmessage-htmlmessage-cont">
      <div
        className="domain-message-html"
        style={{ color: "#000" }}
      >
        <div
          className="modals-domainmessage-htmlmessage-html"
          dangerouslySetInnerHTML={{ __html: props.htmlBody }}
        />
      </div>
    </div>
  );
};

export const checkIfHTMLHasScriptTab = (htmlAsString: string) => {
  if (!htmlAsString) {
    return false;
  }

  const regExp = new RegExp(_.escapeRegExp("<script"), "i");

  return regExp.test(htmlAsString);
};
