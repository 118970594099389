/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../../../typings/tyto";
import { DISCPlotGraph, Icon, Message } from "../../../../../components/common";
import {
  calcGradientForIcon,
  isValidIcon
} from "../../../../../data/utils/helpers";

import "./YouAndOther.scss";

interface Interactions {
  insight?: Tyto.DISCCompareProfile.Interaction;
  presented?: Tyto.DISCCompareProfile.Interaction;
  tips?: Tyto.DISCCompareProfile.Interaction;
}

interface Props {
  discMiniProfile: Tyto.DISCProfileMini;
  discFullProfile?: Tyto.DISCInfo;
  discCompareProfile?: Tyto.DISCCompareProfile;
  userDISCMini?: Tyto.DISCProfileMini;
}

export default ({
  discCompareProfile,
  discMiniProfile,
  userDISCMini
}: Props) => {
  const [interactions, updateInteractions] = React.useState<Interactions>(
    getInteractions(discCompareProfile)
  );
  //   const [themIconGradient, updateThemIconGradient] = React.useState(
  //     calcGradientForIcon({
  //       d: discMiniProfile.d3,
  //       i: discMiniProfile.i3,
  //       s: discMiniProfile.s3,
  //       c: discMiniProfile.c3
  //     })
  //   );
  //   const [youIconGradient, updateYouIconGradient] = React.useState(
  //     userDISCMini
  //       ? calcGradientForIcon({
  //           d: userDISCMini.d3,
  //           i: userDISCMini.i3,
  //           s: userDISCMini.s3,
  //           c: userDISCMini.c3
  //         })
  //       : undefined
  //   );

  //   React.useEffect(() => {
  //     updateInteractions(getInteractions(discCompareProfile));
  //   }, [discCompareProfile]);

  //   React.useEffect(() => {
  //     updateThemIconGradient(
  //       calcGradientForIcon({
  //         d: discMiniProfile.d3,
  //         i: discMiniProfile.i3,
  //         s: discMiniProfile.s3,
  //         c: discMiniProfile.c3
  //       })
  //     );
  //   }, [discMiniProfile]);

  const firstName = discMiniProfile.personName
    ? discMiniProfile.personName.split(" ")[0]
    : "Them";

  return (
    <section className="directory-tabs-tab-section-youandother-cont">
      <div className="directory-tabs-tab-section-youandother-top-cont">
        {userDISCMini && (
          <div className="directory-tabs-tab-section-youandother-graph-cont">
            <DISCPlotGraph
              groupStyle={[
                {
                  d: discMiniProfile.d3,
                  i: discMiniProfile.i3,
                  s: discMiniProfile.s3,
                  c: discMiniProfile.c3,
                  personName: discMiniProfile.personName
                },
                {
                  d: userDISCMini.d3,
                  i: userDISCMini.i3,
                  s: userDISCMini.s3,
                  c: userDISCMini.c3,
                  personName: userDISCMini.personName
                }
              ]}
              size={250}
            />
          </div>
        )}
        <h2 className="directory-tabs-tab-section-youandother-top-title">
          You {"&"} {firstName}
        </h2>
      </div>

      <div className="directory-tabs-tab-section-youandother-section-cont">
        {!discCompareProfile ? (
          <Message
            className="directory-tabs-tab-section-about-personality-section-missing-characteristic-message"
            value="No Interaction information found."
          />
        ) : (
          <>
            <h3 className="directory-tabs-tab-section-youandother-section-inner-section-title">
              How you see {firstName}
            </h3>

            <div className="directory-tabs-tab-section-youandother-section-icon-section">
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon youandother-section-icon-large"
                icon="user-alt-solid"
                size={40}
                // gradient={themIconGradient}
              />
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon youandother-section-icon-medium"
                icon="angle-left"
                size={40}
              />
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon youandother-section-icon-small"
                icon="user-alt-solid"
                size={40}
                // gradient={youIconGradient}
              />
            </div>

            <div className="directory-tabs-tab-section-youandother-section-text-section">
              <p className="directory-tabs-tab-section-youandother-section-inner-section-description">
                {interactions.insight ? interactions.insight.interactText : ""}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="directory-tabs-tab-section-youandother-section-cont youandother-section-cont-opposite">
        {!discCompareProfile ? (
          <Message
            className="directory-tabs-tab-section-about-personality-section-missing-characteristic-message"
            value="No Interaction information found."
          />
        ) : (
          <>
            <h3
              className="directory-tabs-tab-section-youandother-section-inner-section-title"
              //   style={{ order: 1 }}
            >
              How {firstName} sees you
            </h3>

            <div
              className="directory-tabs-tab-section-youandother-section-icon-section"
              //   style={{ order: 3 }}
            >
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon youandother-section-icon-large"
                icon="user-alt-solid"
                size={40}
                // gradient={themIconGradient}
              />
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon youandother-section-icon-medium"
                icon="angle-right"
                size={40}
              />
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon youandother-section-icon-small"
                icon="user-alt-solid"
                size={40}
                // gradient={youIconGradient}
              />
            </div>

            <div
              className="directory-tabs-tab-section-youandother-section-text-section"
              //   style={{ order: 2 }}
            >
              <p
                className="directory-tabs-tab-section-youandother-section-inner-section-description"
                style={{ paddingRight: "0px", paddingLeft: "10%" }}
              >
                {interactions.presented
                  ? interactions.presented.interactText
                  : ""}
              </p>
            </div>
          </>
        )}
      </div>

      <div className="directory-tabs-tab-section-youandother-section-cont">
        {!discCompareProfile ? (
          <Message
            className="directory-tabs-tab-section-about-personality-section-missing-characteristic-message"
            value="No Interaction information found."
          />
        ) : (
          <>
            <h3 className="directory-tabs-tab-section-youandother-section-inner-section-title">
              Tips for working together
            </h3>

            <div className="directory-tabs-tab-section-youandother-section-icon-section">
              <Icon
                className="directory-tabs-tab-section-youandother-section-icon"
                icon="handshake"
                size={65}
                // gradient={themIconGradient}
                style={{ filter: "none" }}
              />
            </div>

            <div className="directory-tabs-tab-section-youandother-section-text-section">
              <p className="directory-tabs-tab-section-youandother-section-inner-section-description">
                {interactions.tips ? interactions.tips.interactText : ""}
              </p>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

const getInteractions = (
  discCompareProfile?: Tyto.DISCCompareProfile
): Interactions => {
  if (!discCompareProfile || !discCompareProfile.interactions) {
    return {};
  }

  const interactions = _.keyBy(discCompareProfile.interactions, interaction =>
    interaction.interactContext.toLowerCase()
  );

  return interactions as {
    insight: Tyto.DISCCompareProfile.Interaction;
    presented: Tyto.DISCCompareProfile.Interaction;
    tips: Tyto.DISCCompareProfile.Interaction;
  };
};
