/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import {
  Checkbox,
  Icon,
  PlaceholderText,
  UserThumbPlaceholder,
} from "../../../components/common";

/*
 * Component Description
 */

interface ListItemProps {
  noAnimation?: boolean;
  showCheckbox?: boolean;
}
export default ({ noAnimation, showCheckbox }: ListItemProps) => {
  const [hasEmail] = React.useState(() => Math.random() * 2 > 1);
  const [hasPhone] = React.useState(() => Math.random() * 2 > 1);
  const [fillerName] = React.useState(() => {
    const fill = "Fill ";

    return fill.repeat(Math.round(Math.random() * 4) + 3);
  });
  const [fillerJobTitle] = React.useState(() => {
    const fill = "Fill ";

    return fill.repeat(Math.round(Math.random() * 5) + 2);
  });
  //   const [animationDuration1] = React.useState(() =>
  //     Math.round(1800 + Math.random() * 1200)
  //   );
  //   const [animationDuration2] = React.useState(() =>
  //     Math.round(1800 + Math.random() * 1200)
  //   );

  return (
    <li
      className={cx(
        "groups-people-list-item",
        !!noAnimation && "groups-people-list-item-placeholder-noanimation"
      )}
    >
      <div
        className={cx(
          "groups-people-list-item-button-cont",
          showCheckbox && "groups-people-list-item-button-show-button"
        )}
      >
        <Checkbox checked={false} onCheck={() => {}} size={20} />
      </div>

      <button
        className={cx(
          "groups-people-list-item-info-cont",
          showCheckbox && "groups-people-list-item-info-cont-checkbox-showing"
        )}
        onClick={() => {}}
        tabIndex={showCheckbox ? -1 : undefined}
      >
        <div className="groups-people-list-item-info-userthumb-cont">
          <UserThumbPlaceholder
            className="groups-people-list-item-info-userthumb"
            size={43}
          />
        </div>

        <div className="groups-people-list-item-info-name-cont">
          <p className="groups-people-list-item-info-name title-bold">
            {/* <span
              className="is-dummy-placeholder-text"
              style={{ animationDuration: `${animationDuration1}ms` }}
            >
              {fillerName}
            </span> */}
            <PlaceholderText value={fillerName} />
          </p>
          <p className="groups-people-list-item-info-jobtitle">
            {/* <span
              className="is-dummy-placeholder-text"
              style={{ animationDuration: `${animationDuration2}ms` }}
            >
              {fillerJobTitle}
            </span> */}
            <PlaceholderText value={fillerJobTitle} />
          </p>
        </div>

        <div className="groups-people-list-item-info-contact-cont">
          <p
            className="groups-people-list-item-info-contact-email"
            style={!hasEmail ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="groups-people-list-item-info-contact-icon"
              icon="envelope"
              size={12}
            />
            <PlaceholderText value="Filler Fake Email" />
          </p>
          <p
            className="groups-people-list-item-info-contact-phone"
            style={!hasPhone ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="groups-people-list-item-info-contact-icon"
              icon="phone"
              size={12}
            />
            <PlaceholderText value="Filler Phone" />
          </p>
        </div>

        <div
          className="groups-people-list-item-info-style-cont"
          style={{ backgroundColor: "#fff" }}
        ></div>
      </button>
    </li>
  );
};
