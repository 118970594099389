/*
 * Component Description
 */
import * as React from "react";

import { Button, ToggleSection } from "../../../common/";
import { ImportUsers } from "../../../modals/";
import { Tyto } from "../../../../typings/tyto";

import "./TeamImportUsers.scss";

interface Props {
  reloadTeamMembers: (teamID: number) => void;
  team: Tyto.Team;
}

export default (props: Props) => {
  const [showImportModal, updateShowImportModal] = React.useState(false);

  return (
    <>
      <ToggleSection
        className="meta-super-sidepane-toggle-section"
        title="Import Users"
        renderFunction={() => {
          return (
            <div className="meta-super-sidepane-importusers-section">
              <Button
                className="meta-super-sidepane-importusers-launch-button"
                type="hollow"
                shape="square"
                onClick={() => updateShowImportModal(true)}
                value="Open Import Dialogue"
              />
            </div>
          );
        }}
      />

      <ImportUsers
        team={props.team}
        isOpen={showImportModal}
        onDismiss={() => {
          updateShowImportModal(false);
          props.reloadTeamMembers(props.team.teamID);
        }}
      />
    </>
  );
};
