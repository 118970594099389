/*
 * Component Description
 */
import * as React from "react";
import { Link } from "react-router-dom";

import { Icon } from "../../components/common/";

import "./style.scss";

interface Props {
  default: boolean;
  uri?: string;
}

export default (props: Props) => {
  return (
    <section className="four04-interface">
      <Icon className="four04-icon" icon="question" size={120} color="#fff" />
      <h1 className="four04-title">Uhh...</h1>
      <p className="four04-description">
        {props.uri ? (
          <span
            style={{ fontStyle: "italic", opacity: 0.75, fontWeight: "bold" }}
          >
            "{props.uri}"
          </span>
        ) : (
          "This URL"
        )}{" "}
        does no match any interfaces. Are you sure you got it correct?
      </p>
      <div className="four04-link-cont">
        <Link className="four04-link" to="/directory">
          Directory
        </Link>
      </div>
    </section>
  );
};
