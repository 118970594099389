/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import { Icon, Input } from "../../../common/";
import { AppStoreProps } from "../../../../data/stores/AppStore";

import SaveUser from "./SaveUser";

interface Props {
  autoAddToTeam: boolean;
  appStore: AppStoreProps;
  columnLabels: string[];
  curHoveredColumn: number;
  deleteRow: (rowIdx: number) => void;
  importing: boolean;
  listItemIdx: number;
  result: string[];
  showDeleteButton: boolean;
  showDisabledRows: boolean;
  team: Tyto.Team;
  useEmailAsLogonName: boolean;
  updateCurHoveredColumn: (columnIdx: number) => void;
  updateItem: (listItemIdx: number, itemIdx: number, value: any) => void;
}

export default (props: Props) => {
  const [logonName, email] = pullLogonNameOptions(
    props.columnLabels,
    props.result
  );
  const [saveUser, updateSaveUser] = React.useState(props.importing);

  React.useEffect(() => {
    if (props.importing && !saveUser) {
      updateSaveUser(props.importing);
    }
  }, [props.importing]);

  const rowDisabled =
    (!logonName && !email) || (props.useEmailAsLogonName && !email);

  if (saveUser) {
    return (
      <SaveUser
        autoAddToTeam={props.autoAddToTeam}
        appStore={props.appStore}
        columnLabels={props.columnLabels}
        result={props.result}
        rowDisabled={rowDisabled}
        team={props.team}
      />
    );
  }

  return (
    <li
      className={cx(
        "modals-importusers-modal-results-list-item",
        props.showDisabledRows && rowDisabled && "result-row-disabled"
      )}
    >
      {props.showDeleteButton && (
        <div className="modals-importusers-modal-results-list-item-delete-button-cont">
          <Icon
            buttonProps={{
              className:
                "modals-importusers-modal-results-list-item-delete-button"
            }}
            icon="trash-alt"
            size={20}
            isButton={true}
            onClick={() => props.deleteRow(props.listItemIdx)}
          />
        </div>
      )}

      {props.result.map((item, curIdx) => (
        <Input
          className={cx(
            "modals-importusers-modal-results-list-item-input",
            !props.columnLabels[curIdx] && "result-column-disabled",
            rowDisabled && "result-row-disabled"
          )}
          key={`${props.listItemIdx}-${curIdx}`}
          containerClassName={cx(
            "modals-importusers-modal-results-list-item-input-cont",
            curIdx > 2 &&
              "modals-importusers-modal-results-list-item-input-cont-small-col",
            props.curHoveredColumn === curIdx && "is-cur-hovered-column"
          )}
          hideClear={true}
          onChange={newValue =>
            props.updateItem(props.listItemIdx, curIdx, newValue)
          }
          placeholder={
            props.columnLabels[curIdx] === "logonName" && !logonName && email
              ? email
              : undefined
          }
          // onMouseEnter={() => props.updateCurHoveredColumn(curIdx)}
          // onMouseLeave={() => props.updateCurHoveredColumn(-1)}
          style={
            rowDisabled &&
            shouldUnderline({
              columnLabel: props.columnLabels[curIdx],
              useEmailAsLogonName: props.useEmailAsLogonName
            })
              ? {
                  borderBottomColor: "red"
                }
              : undefined
          }
          value={item}
        />
      ))}
    </li>
  );
};

const pullLogonNameOptions = (columnLabels: string[], result: string[]) => {
  const [logonName, email] = columnLabels.reduce(
    (accum: Array<string | null>, colKey, curIdx: number) => {
      if (colKey && (colKey === "logonName" || colKey === "email")) {
        accum[colKey === "logonName" ? 0 : 1] = result[curIdx];
      }

      return accum;
    },
    [null, null]
  );

  return [logonName, email];
};

const shouldUnderline = ({
  columnLabel,
  useEmailAsLogonName
}: {
  columnLabel: string;
  useEmailAsLogonName: boolean;
}): boolean => {
  if (useEmailAsLogonName) {
    return columnLabel === "email";
  }

  return columnLabel === "email" || columnLabel === "logonName";
};
