/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { TextButton } from "../../../components/common";
import { NewAccount } from "../../../components/modals/";
import { Tyto } from "../../../typings/tyto";

interface Props {
  primaryDomain?: Tyto.Team;
  reloadAccounts: () => void;
  uploadURL?: string;
}

export default (props: Props) => {
  const [showModal, updateShowModal] = React.useState(false);

  if (!_.get(props, "primaryDomain.teamID", undefined)) {
    return null;
  }

  return (
    <li className="partner-dashboard-accounts-account partner-dashboard-accounts-new-account">
      <TextButton
        className="partner-dashboard-accounts-new-account-name"
        iconProps={{
          icon: "plus",
          size: 42,
          className: "partner-dashboard-accounts-new-account-name-icon",
        }}
        onClick={() => updateShowModal(true)}
        value="New Account"
      />

      <NewAccount
        isOpen={showModal}
        onDismiss={() => updateShowModal(false)}
        onAccountCreation={() => {}}
        primaryElementID={_.get(props, "primaryDomain.teamID", 0)}
        uploadURL={props.uploadURL}
      />
    </li>
  );
};
