/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../../../typings/tyto";
import {
  DISCValueRender,
  Icon,
  Message,
} from "../../../.././../components/common";
import {
  calcGradientForIcon,
  isValidIcon,
} from "../../../../../data/utils/helpers/";

import "./AboutPersonality.scss";

interface Props {
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile: Tyto.DISCProfileMini;
}

export default ({ discCompareProfile, discMiniProfile }: Props) => {
  if (discCompareProfile === null) {
    return <h1>No Compare Info. {":("}</h1>;
  }

  if (!discCompareProfile) {
    return <h2>Loading...</h2>;
  }

  const [iconGradient, updateUpdateIconGradient] = React.useState(
    calcGradientForIcon({
      d: discMiniProfile.d3,
      i: discMiniProfile.i3,
      s: discMiniProfile.s3,
      c: discMiniProfile.c3,
    })
  );

  React.useEffect(() => {
    updateUpdateIconGradient(
      calcGradientForIcon({
        d: discMiniProfile.d3,
        i: discMiniProfile.i3,
        s: discMiniProfile.s3,
        c: discMiniProfile.c3,
      })
    );
  }, [discMiniProfile]);

  const noCharacteristics =
    !discCompareProfile.style.characteristic1details &&
    !discCompareProfile.style.characteristic2details &&
    !discCompareProfile.style.characteristic3details;

  return (
    <section className="directory-tabs-tab-section-about-personality-cont">
      <div className="directory-tabs-tab-section-about-personality-top-cont">
      <DISCValueRender
            className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
            isCompareValue={true}
            compareProfile={discCompareProfile}
            targetKey="style.graphic"
            targetKeys={[
              "style.graphic",
              "style.styleName",
              "style.styleHeadline"
            ]}
            discMiniProfile={discMiniProfile}
          >
            <div className="directory-tabs-tab-section-about-personality-top-icon-cont">
              <DISCValueRender
                className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                iconSize={38}
                isCompareValue={true}
                compareProfile={discCompareProfile}
                omitHiddenMsg={true}
                targetKey="style.graphic"
                discMiniProfile={discMiniProfile}
              >
                <Icon
                  className="directory-tabs-tab-section-about-personality-top-icon"
                  icon={isValidIcon(discMiniProfile.graphic)}
                  size={66}
                  gradient={iconGradient}
                />
              </DISCValueRender>
            </div>

            <div className="directory-tabs-tab-section-about-personality-top-text-cont">
              <h2 className="directory-tabs-tab-section-about-personality-top-title">
                <DISCValueRender
                  className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                  isCompareValue={true}
                  compareProfile={discCompareProfile}
                  targetKey="style.styleName"
                  discMiniProfile={discMiniProfile}
                >
                  {discCompareProfile.style.styleName}
                </DISCValueRender>
              </h2>
              <p className="directory-tabs-tab-section-about-personality-top-description">
                <DISCValueRender
                  className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                  isCompareValue={true}
                  compareProfile={discCompareProfile}
                  targetKey="style.styleHeadline"
                  discMiniProfile={discMiniProfile}
                >
                  {discCompareProfile.style.styleHeadline}
                </DISCValueRender>
              </p>
            </div>
          </DISCValueRender>
      </div>

      <div className="directory-tabs-tab-section-about-personality-section-cont">
        <h2 className="directory-tabs-tab-section-about-personality-section-title">
          3 Main things to know about {discCompareProfile.style.styleName}?
        </h2>

        {noCharacteristics ? (
          <Message
            className="directory-tabs-tab-section-about-personality-section-missing-characteristic-message"
            value="No Characteristic information found."
          />
        ) : (
          <DISCValueRender
            className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
            isCompareValue={true}
            compareProfile={discCompareProfile}
            targetKey="style.characteristic1"
            targetKeys={["style.characteristic1"]}
            discMiniProfile={discMiniProfile}
          >
            <div className="directory-tabs-tab-section-about-personality-section-inner-section">
              <DISCValueRender
                className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                isCompareValue={true}
                compareProfile={discCompareProfile}
                targetKey="style.characteristic1"
                discMiniProfile={discMiniProfile}
              >
                <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title profile-section-title">
                  {discCompareProfile.style.characteristic1}
                </h3>
                <p className="directory-tabs-tab-section-about-personality-section-inner-section-description">
                  {discCompareProfile.style.characteristic1details}
                </p>
              </DISCValueRender>
            </div>

            <div className="directory-tabs-tab-section-about-personality-section-inner-section">
              <DISCValueRender
                className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                isCompareValue={true}
                compareProfile={discCompareProfile}
                targetKey="style.characteristic2"
                discMiniProfile={discMiniProfile}
              >
                <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title profile-section-title">
                  {discCompareProfile.style.characteristic2}
                </h3>
                <p className="directory-tabs-tab-section-about-personality-section-inner-section-description">
                  {discCompareProfile.style.characteristic2details}
                </p>
              </DISCValueRender>
            </div>

            <div className="directory-tabs-tab-section-about-personality-section-inner-section">
              <DISCValueRender
                className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
                isCompareValue={true}
                compareProfile={discCompareProfile}
                targetKey="style.characteristic3"
                discMiniProfile={discMiniProfile}
              >
                <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title profile-section-title">
                  {discCompareProfile.style.characteristic3}
                </h3>
                <p className="directory-tabs-tab-section-about-personality-section-inner-section-description">
                  {discCompareProfile.style.characteristic3details}
                </p>
              </DISCValueRender>
            </div>
          </DISCValueRender>
        )}
      </div>

      <div className="directory-tabs-tab-section-about-personality-section-cont">
        <h2 className="directory-tabs-tab-section-about-personality-section-title">
          What should you expect during your collaboration with{" "}
          {discCompareProfile.style.styleName}s?
        </h2>

        <DISCValueRender
            className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
            isCompareValue={true}
            compareProfile={discCompareProfile}
            targetKey="style.teamContributions"
            targetKeys={[
              "style.teamContributions",
              "style.analyticDispostion",
              "style.environmentIdeal"
            ]}
            discMiniProfile={discMiniProfile}
          >
          <div className="directory-tabs-tab-section-about-personality-section-inner-section">
            <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title profile-section-title">
              {discCompareProfile.style.styleName}s value to a team
            </h3>
            <DISCValueRender
              className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
              isCompareValue={true}
              compareProfile={discCompareProfile}
              targetKey="style.teamContributions"
              discMiniProfile={discMiniProfile}
            >
              <ul className="directory-tabs-tab-section-about-personality-section-inner-section-list profile-section-list">
                {discCompareProfile.style.teamContributions &&
                  discCompareProfile.style.teamContributions
                    .split("\n")
                    .map((item) => (
                      <li
                        className="directory-tabs-tab-section-about-personality-section-inner-section-list-item"
                        key={item}
                      >
                        {item}
                      </li>
                    ))}
              </ul>
            </DISCValueRender>
          </div>

          <div className="directory-tabs-tab-section-about-personality-section-inner-section">
            <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title profile-section-title">
              How {discCompareProfile.style.styleName}s Analyze
            </h3>

            <DISCValueRender
              className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
              isCompareValue={true}
              compareProfile={discCompareProfile}
              targetKey="style.analyticDispostion"
              discMiniProfile={discMiniProfile}
            >
              <ul className="directory-tabs-tab-section-about-personality-section-inner-section-list profile-section-list">
                {discCompareProfile.style.analyticDispostion &&
                  discCompareProfile.style.analyticDispostion
                    .split("\n")
                    .map((item) => (
                      <li
                        className="directory-tabs-tab-section-about-personality-section-inner-section-list-item"
                        key={item}
                      >
                        {item}
                      </li>
                    ))}
              </ul>
            </DISCValueRender>
          </div>

          <div className="directory-tabs-tab-section-about-personality-section-inner-section">
            <h3 className="directory-tabs-tab-section-about-personality-section-inner-section-title profile-section-title">
              Be careful about these points
            </h3>
            <DISCValueRender
              className="directory-tabs-tab-section-about-personality-section-inner-section-item-wrapper"
              isCompareValue={true}
              compareProfile={discCompareProfile}
              targetKey="style.environmentIdeal"
              discMiniProfile={discMiniProfile}
            >
              <ul className="directory-tabs-tab-section-about-personality-section-inner-section-list profile-section-list">
                {discCompareProfile.style.environmentIdeal &&
                  discCompareProfile.style.environmentIdeal
                    .split("\n")
                    .map((item) => (
                      <li
                        className="directory-tabs-tab-section-about-personality-section-inner-section-list-item"
                        key={item}
                      >
                        {item}
                      </li>
                    ))}
              </ul>
            </DISCValueRender>
          </div>
          </DISCValueRender>
      </div>
    </section>
  );
};
