/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../../../typings/tyto";

import "./GrowthAreas.scss";

interface Props {
  compareProfile?: Tyto.DISCCompareProfile;
}

export default (props: Props) => {
  const [growthAreas, updateGrowthAreas] = React.useState(
    getGrowthAreas(props.compareProfile)
  );

  React.useEffect(() => {
    updateGrowthAreas(getGrowthAreas(props.compareProfile));
  }, [props.compareProfile]);

  return (
    <div className="directory-tabs-tab-section-comm-tips-tips-section-dosdonts-cont">
      {growthAreas.length ? (
        <>
          {growthAreas.map(growthArea => (
            <p
              key={growthArea.slice(0, 30)}
              className="directory-tabs-tab-section-growth-areas-tip"
            >
              {growthArea}
            </p>
          ))}
        </>
      ) : (
        <p className="directory-tabs-tab-section-growth-areas-tip">
          Not Found.
        </p>
      )}
    </div>
  );
};

const getGrowthAreas = (compareProfile?: Tyto.DISCCompareProfile): string[] => {
  if (!compareProfile) {
    return [];
  }

  const personalGrowthAreas = _.get(
    compareProfile,
    "style.personalGrowthAreas",
    ""
  );

  return personalGrowthAreas.split("\n");
};
