import * as _ from "lodash";
import { Tyto } from "../../../../../typings/tyto";
import TytoCalls from "../../../../../data/tyto";
import { calcStandardDeviation } from "../../../../../data/utils/helpers/";

const defaultDISCValue = {
  d: 0,
  i: 0,
  s: 0,
  c: 0,
};

export function calcGroupStyle(discMiniProfile: Tyto.DISCProfileMini[]) {
  //   const discValuesAsObj = calcGroupStyleAsObj(discMiniProfile);
  //   const discValuesAsObj = calcPositiveGroupStyleAsObj(discMiniProfile);
  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  const discValuesAsObj = calcSqrPositiveGroupStyleAsObj(discMinisFiltered);

  return _.orderBy(
    [
      { label: "d", value: discValuesAsObj.d },
      { label: "i", value: discValuesAsObj.i },
      { label: "s", value: discValuesAsObj.s },
      { label: "c", value: discValuesAsObj.c },
    ],
    ["value"],
    ["desc"]
  );
}

export function calcGroupStyleAsObj(discMiniProfile: Tyto.DISCProfileMini[]) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + profile.d3,
      i: accum.i + profile.i3,
      s: accum.s + profile.s3,
      c: accum.c + profile.c3,
    }),
    defaultDISCValue
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

export function calcPositiveGroupStyleAsObj(
  discMiniProfile: Tyto.DISCProfileMini[]
) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + (profile.d3 > 0 ? profile.d3 : 0),
      i: accum.i + (profile.i3 > 0 ? profile.i3 : 0),
      s: accum.s + (profile.s3 > 0 ? profile.s3 : 0),
      c: accum.c + (profile.c3 > 0 ? profile.c3 : 0),
    }),
    defaultDISCValue
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

export function calcSqrPositiveGroupStyleAsObj(
  discMiniProfile: Tyto.DISCProfileMini[]
) {
  if (!Array.isArray(discMiniProfile)) {
    return defaultDISCValue;
  }

  const discMinisFiltered = discMiniProfile.filter(
    (profile) => !_.get(profile, "permitMatrix.SCORES_.HIDE", true)
  );

  if (!discMinisFiltered.length) {
    return defaultDISCValue;
  }

  const letterSums = discMinisFiltered.reduce(
    (accum: { d: number; i: number; s: number; c: number }, profile) => ({
      d: accum.d + (profile.d3 > 0 ? Math.pow(profile.d3, 2) : 0),
      i: accum.i + (profile.i3 > 0 ? Math.pow(profile.i3, 2) : 0),
      s: accum.s + (profile.s3 > 0 ? Math.pow(profile.s3, 2) : 0),
      c: accum.c + (profile.c3 > 0 ? Math.pow(profile.c3, 2) : 0),
    }),
    {
      d: 0,
      i: 0,
      s: 0,
      c: 0,
    }
  );

  return {
    d: letterSums.d / discMinisFiltered.length,
    i: letterSums.i / discMinisFiltered.length,
    s: letterSums.s / discMinisFiltered.length,
    c: letterSums.c / discMinisFiltered.length,
  };
}

// * Returns values that will be used for surfacing insight about team
export function getGroupStyleWithStdDeviation(
  disc: { label: string; value: number }[]
) {
  if (!Array.isArray(disc) || !disc.length) {
    return "";
  }

  const values = disc.map((info) => info.value);
  const stdDeviation = calcStandardDeviation(values);

  const highestValue = _.orderBy(disc, ["value"], ["desc"])[0].value;
  const lowestAcceptedValue = highestValue - stdDeviation;

  return disc
    .filter((info) => info.value >= lowestAcceptedValue)
    .map((info) => info.label)
    .join("");
}

export function loadTeamProfile(teamStyleKey: string) {
  return TytoCalls.DISCProfile.Team.get({ styleKey: teamStyleKey });
}

// * ==================================================
// * Attempt at using normalize values ================
// * ==================================================

function alterValueBasedOnOpposite(value: number, oppValue: number) {
  if (value <= 0) {
    return value;
    // * oppValue greater than -3 -> remove from value
  } else if (oppValue >= -3) {
    return value - (3 - -oppValue);
    // * oppValue less than -6 -> add to value
  } else if (oppValue <= -6) {
    return value + (Math.abs(oppValue) - 6);
  }

  // oppValue between -6 and -3 -> return as is
  return value;
}

function reduceCompareProfile(discCompareProfile: Tyto.DISCCompareProfile) {
  const dVal = getValueFromPercentile(discCompareProfile.result.d3percentile);
  const iVal = getValueFromPercentile(discCompareProfile.result.i3percentile);
  const sVal = getValueFromPercentile(discCompareProfile.result.s3percentile);
  const cVal = getValueFromPercentile(discCompareProfile.result.c3percentile);

  return {
    d: alterValueBasedOnOpposite(dVal, sVal),
    i: alterValueBasedOnOpposite(iVal, cVal),
    s: alterValueBasedOnOpposite(sVal, dVal),
    c: alterValueBasedOnOpposite(cVal, iVal),
  };
}

function getValueFromPercentile(percentile: number) {
  return (percentile / 100) * 16 - 8;
}

export function getValuesFromPercentiles(
  discCompareProfiles: Tyto.DISCCompareProfile[]
) {
  if (!Array.isArray(discCompareProfiles)) {
    return defaultDISCValue;
  }

  const letterSums = discCompareProfiles.reduce(
    (accum: { d: number; i: number; s: number; c: number }, compareProfile) => {
      const reducedCompare = reduceCompareProfile(compareProfile);

      return {
        d: accum.d + (reducedCompare.d > 0 ? reducedCompare.d : 0),
        i: accum.i + (reducedCompare.i > 0 ? reducedCompare.i : 0),
        s: accum.s + (reducedCompare.s > 0 ? reducedCompare.s : 0),
        c: accum.c + (reducedCompare.c > 0 ? reducedCompare.c : 0),
      };
    },
    {
      d: 0,
      i: 0,
      s: 0,
      c: 0,
    }
  );

  return {
    d: letterSums.d / discCompareProfiles.length,
    i: letterSums.i / discCompareProfiles.length,
    s: letterSums.s / discCompareProfiles.length,
    c: letterSums.c / discCompareProfiles.length,
  };
}

export function calcGroupStyleAsPercent(
  discCompareProfiles: Tyto.DISCCompareProfile[]
) {
  //   const discValuesAsObj = calcGroupStyleAsObj(discMiniProfile);
  //   const discValuesAsObj = calcPositiveGroupStyleAsObj(discMiniProfile);
  // const discValuesAsObj = calcSqrPositiveGroupStyleAsObj(discMiniProfile);
  const discValuesAsObj = getValuesFromPercentiles(discCompareProfiles);

  return _.orderBy(
    [
      { label: "d", value: discValuesAsObj.d },
      { label: "i", value: discValuesAsObj.i },
      { label: "s", value: discValuesAsObj.s },
      { label: "c", value: discValuesAsObj.c },
    ],
    ["value"],
    ["desc"]
  );
}
