/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { DialogContent, DialogOverlay } from "@reach/dialog";

import { Icon } from "../../common/";

import "../style.scss";
import "./style.scss";

interface Props {
  className?: string;
  overlayClassName?: string;
  isOpen?: boolean;
  modalType?: "normal" | "super";
  modalStyle?: React.CSSProperties;
  onDismiss?: () => void;
  blurBody?: boolean;
  hasCloseBtn?: boolean;
  overlayStyle?: React.CSSProperties;
  children?: any;
}

export default ({
  className,
  isOpen,
  modalStyle,
  modalType = "normal",
  onDismiss,
  blurBody = true,
  hasCloseBtn = true,
  overlayClassName,
  overlayStyle,
  ...props
}: Props) => {
  React.useEffect(() => {
    if (isOpen && blurBody) {
      const mainDiv = document.getElementById("main");

      if (mainDiv) {
        // mainDiv.style.filter = "blur(3px)";
        mainDiv.className = `${mainDiv.className} main-div-is-blurred`;
      }
    } else {
      const mainDiv = document.getElementById("main");

      if (mainDiv) {
        mainDiv.className = `App`;
      }
    }
  }, [isOpen]);

  const isSuperModal = modalType === "super";

  return (
    <DialogOverlay
      className={cx(
        "modals-dialog-overlay",
        isSuperModal && "is-supermodal",
        overlayClassName
      )}
      onDismiss={onDismiss}
      isOpen={isOpen}
      style={overlayStyle}
    >
      <DialogContent
        className={cx(
          "modals-dialog-content",
          isSuperModal && "is-supermodal",
          className
        )}
        style={modalStyle}
      >
        {hasCloseBtn && (
          <Icon
            buttonProps={{
              className: cx(
                "modals-agnostic-close-button",
                isSuperModal && "is-supermodal"
              ),
            }}
            icon="window-close"
            isButton={true}
            onClick={onDismiss}
            size={isSuperModal ? 45 : 25}
          />
        )}
        {props.children}
      </DialogContent>
    </DialogOverlay>
  );
};
