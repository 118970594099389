/**
 * Renders a heatmap with points for each profile
 * @flow
 */
import { map } from "lodash";
import React, { Component } from "react";
import cx from "classnames";
import "./vendor/";

import { UserThumb } from "../../common";
import { getLetterColor } from "../../../data/utils/helpers/";
import {
  DEFAULT_INTENSITY_COLORS,
  calculatePoints,
  drawHeatmap,
} from "./utils";
import { Tyto } from "../../../typings/tyto";
import Button from "../button/";

import "./style.scss";

const negativeValue = 20;
const labels: {
  label: string;
  style: React.CSSProperties;
  letterStyle: React.CSSProperties;
  type: string;
}[] = [
  {
    label: "Driver",
    style: {
      top: `-${negativeValue}px`,
      left: "0px",
    },
    letterStyle: {
      top: "0px",
      left: "0px",
    },
    type: "d",
  },
  {
    label: "Influencer",
    style: {
      top: `-${negativeValue}px`,
      left: "50%",
    },
    letterStyle: {
      top: "0px",
      left: "50%",
    },
    type: "i",
  },
  {
    label: "Stabilizer",
    style: {
      bottom: `-${negativeValue}px`,
      left: "50%",
    },
    letterStyle: {
      top: "50%",
      left: "50%",
    },
    type: "s",
  },
  {
    label: "Analyzer",
    style: {
      bottom: `-${negativeValue}px`,
      left: "0px",
    },
    letterStyle: {
      top: "50%",
      left: "0px",
    },
    type: "c",
  },
];

interface Props {
  profiles: Tyto.DISCProfileMini[];
  heatMapType?: 1 | 2 | 3;
  size: number;
  onUserSelect?: (userID: number) => void;
  intensityColorsOverride?: {
    [insensityPercentagePoint: number]: string;
  };
}

interface State {
  heatMapType: number;
}

export default class Heatmap extends Component<Props, State> {
  heatmap?: HTMLDivElement | null;

  static defaultProps = {
    size: 300,
  };
  static displayName = "Heatmap";

  state = {
    heatMapType: this.props.heatMapType || 3,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this._drawHeatmap();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.heatMapType &&
      (prevProps.heatMapType !== this.props.heatMapType ||
        this.props.intensityColorsOverride !==
          prevProps.intensityColorsOverride)
    ) {
      this.setState(
        { heatMapType: this.props.heatMapType || this.state.heatMapType || 3 },
        () => {
          this._drawHeatmap();
        }
      );
    } else {
      this._drawHeatmap();
    }
  }

  _addHeatmapRef = (n: HTMLDivElement) => {
    this.heatmap = n;
  };

  _drawHeatmap() {
    if (this.props.profiles && this.heatmap) {
      drawHeatmap(
        this.props.profiles,
        this.heatmap,
        this.state.heatMapType,
        this.props.size,
        this.props.intensityColorsOverride || DEFAULT_INTENSITY_COLORS
      );
    }
  }

  render() {
    if (!this.props.profiles) {
      return null;
    }
    const points: {
      model: Tyto.DISCProfileMini;
      x: number;
      y: number;
      count: number;
      profile: Tyto.DISCProfileMini;
      quadrant: string;
    }[] = calculatePoints(
      this.props.profiles,
      this.state.heatMapType,
      this.props.size
    );
    // const counts = points.reduce(
    //   (m: { [x: string]: number }, { quadrant }) => {
    //     m[quadrant]++;
    //     return m;
    //   },
    //   { D: 0, I: 0, S: 0, C: 0 }
    // );
    // const offset = this.props.size / 20;
    const offset = 0;

    return (
      <div
        className="disc-heatmap-cont"
        style={{ height: this.props.size + 25 }}
      >
        <div
          className="disc-heatmap-info"
          style={{ height: this.props.size + 25, width: this.props.size }}
        >
          <div
            className="disc-heatmap-points-cont disc-grid-bg has-grid"
            style={{
              height: this.props.size,
              width: this.props.size,
              margin: `${negativeValue}px 0px ${negativeValue}px`,
            }}
          >
            {labels.map(({ label, style, type }) => (
              <p
                className="disc-heatmap-section-labels"
                key={label}
                style={{
                  ...style,
                  color: getLetterColor(type),
                }}
              >
                {label}
              </p>
            ))}

            {labels.map(({ label, letterStyle, type }) => (
              <p
                className="disc-heatmap-section-grid-letter"
                key={label}
                style={{
                  ...letterStyle,
                  fontSize: `${Math.floor(this.props.size / 4)}px`,
                }}
              >
                {type.toUpperCase()}
              </p>
            ))}

            <div
              className="disc-heatmap-points"
              ref={this._addHeatmapRef}
              style={{
                height: this.props.size - offset * 2,
                left: offset,
                top: offset,
                width: this.props.size - offset * 2,
              }}
            >
              {points.map(({ x, y, profile }) => (
                <UserPoint
                  key={profile.personID}
                  onUserSelect={this.props.onUserSelect}
                  heatMapType={this.state.heatMapType}
                  profile={profile}
                  x={x}
                  y={y}
                />
              ))}
            </div>
          </div>

          {/* <div className="disc-heatmap-btns">
            <Button
              className={
                "cc-disc-heatmap-btn" +
                (this.state.heatMapType == 3
                  ? " cc-disc-heatmap-btn-selected"
                  : "")
              }
              type={this.state.heatMapType == 3 ? "color" : "hollow"}
              onClick={this.updateType.bind(this, 3)}
              value="Ideal"
            />
            <Button
              className={
                "cc-disc-heatmap-btn" +
                (this.state.heatMapType == 1
                  ? " cc-disc-heatmap-btn-selected"
                  : "")
              }
              onClick={this.updateType.bind(this, 1)}
              type={this.state.heatMapType == 1 ? "color" : "hollow"}
              value="Stressed"
            />
          </div> */}

          {/* <div>
            <span className="disc-heatmap-cnt-txt ibm"> Counts by type: </span>
            {map(counts, (count, type) => (
              <span className="ibm" style={{ fontSize: 13 }} key={type}>
                {`${type}:`}
                <strong style={{ padding: "0 6px 0 2px" }}>{count}</strong>
              </span>
            ))}
          </div> */}
        </div>
      </div>
    );
  }

  updateType(heatMapType: number) {
    this.setState({
      heatMapType,
    });
  }
}

interface UserPointProps {
  heatMapType: 1 | 2 | 3;
  onUserSelect?: (userID: number) => void;
  profile: Tyto.DISCProfileMini;
  x: number;
  y: number;
}

const UserPoint = ({
  heatMapType,
  onUserSelect,
  profile,
  x,
  y,
}: UserPointProps) => {
  const [hovering, updateHovering] = React.useState(false);

  return (
    <div
      className="disc-heatmap-point-cont"
      key={profile.personID}
      //   style={{ top: y - 6, left: x - 6 }}
      style={{ top: y, left: x, zIndex: hovering ? 100 : undefined }}
      title={profile.personName || ""}
    >
      {/* <div className="disc-heatmap-point" /> */}
      <UserThumb
        asset={profile.profileImageAsset}
        className={cx(
          "disc-heatmap-point-userthumb",
          hovering && "disc-heatmap-point-userthumb-hovering"
        )}
        discValues={{
          d: profile[`d${heatMapType}` as "d3"],
          i: profile[`i${heatMapType}` as "i3"],
          s: profile[`s${heatMapType}` as "s3"],
          c: profile[`c${heatMapType}` as "c3"],
        }}
        onClick={() => {
          if (onUserSelect) {
            onUserSelect(profile.personID);
          }
        }}
        omitHoverIcon={true}
        onMouseEnter={() => updateHovering(true)}
        onMouseLeave={() => updateHovering(false)}
        size={hovering ? 30 : 14}
        style={{
          left: `-${hovering ? 15 : 7.5}px`,
          top: `-${hovering ? 15 : 7.5}px`,
        }}
        userName={profile.personName || ""}
      />
    </div>
  );
};
