/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../../icon/";

import "./squares.scss";

interface Props {
  className?: string;
  omitTitle?: boolean;
  size: number;
}

export default (props: Props) => {
  const squareSize = props.size / 2;

  return (
    <div
      className={cx("cc-loading-square-cont", props.className)}
      style={{ width: props.size, height: props.size }}
    >
      <Icon
        className="cc-loading-square cc-loading-square-1"
        size={squareSize}
        icon="square"
        style={{
          fontSize: squareSize,
        }}
      />
      <Icon
        className="cc-loading-square cc-loading-square-2"
        size={squareSize}
        icon="square"
        style={{
          fontSize: squareSize,
        }}
      />
      <Icon
        className="cc-loading-square cc-loading-square-3"
        size={squareSize}
        icon="square"
        style={{
          fontSize: squareSize,
        }}
      />
      <Icon
        className="cc-loading-square cc-loading-square-4"
        size={squareSize}
        icon="square"
        style={{
          fontSize: squareSize,
        }}
      />

      {!props.omitTitle && (
        <div className="cc-loading-square-image-cont">
          <img
            className="cc-loading-square-image"
            src={"/tt-light.png"}
            style={{
              maxWidth: props.size,
            }}
          />
        </div>
      )}
    </div>
  );
};
