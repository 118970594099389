import { ENDPOINT_PERSON_GET_ADVANCED } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";
// import { Endpoints } from "../../typings/data";

const endpoint = ENDPOINT_PERSON_GET_ADVANCED;

interface RespType extends Endpoints.GenericRespData {
  ret: {
    people: Tyto.AdvancedPerson[];
    memberships: Tyto.AdvancedPersonTeamMembership[];
    sessionUserTeams: Tyto.TeamMembership[];
  };
}

export const PersonAdvanced = {
  get(
    params: Endpoints.Tyto.PersonAdvanced.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<RespType>;
  },

  post(
    params: Endpoints.Tyto.PersonAdvanced.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params, callOpts) as Promise<RespType>;
  }
};
