/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";

import "./style.scss";

interface Option {
  label: string;
  value: string | number;
}

export interface Props {
  canClear?: boolean;
  className?: string;
  containerClassName?: string;
  defaultValue?: string | number;
  disableIconProps?: Object;
  disabled?: boolean;
  name?: string;
  omitFilter?: boolean;
  onChange: (value?: string | number) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  options: Option[];
  placeholder?: string;
  style?: string;
  containerStyle?: string;
  value?: string | number;
}

export default (props: Props) => {
  const [localValue, updateLocalValue] = React.useState(
    props.value || props.defaultValue || ""
  );
  const [disabled, updateDisabled] = React.useState(false);

  return (
    <div
      className={cx("cc-select-cont", props.containerClassName)}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {!props.omitFilter && (
        <div className="cc-select-disable-button-cont">
          <Icon
            className={cx(
              "cc-select-disable-button",
              disabled && "is-disabled"
            )}
            icon="filter"
            isButton={true}
            size={16}
            onClick={() => {
              if (props.onChange) {
                props.onChange(
                  !disabled ? props.defaultValue || "" : localValue
                );
              }

              updateDisabled(!disabled);
            }}
            {...(props.disableIconProps || {})}
          />
        </div>
      )}

      <select
        className={cx(
          "cc-select-input",
          !props.value && "cc-select-input-noanswer",
          props.className
        )}
        disabled={props.disabled}
        name={props.name}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value);
          }

          updateLocalValue(e.target.value);
          updateDisabled(false);
        }}
        aria-placeholder={props.placeholder || ""}
        value={props.value}
      >
        {Array.isArray(props.options) &&
          [
            { label: props.placeholder || "Select Option", value: "" },
            ...props.options,
          ].map((option) => (
            <option
              className="cc-select-option"
              value={option.value}
              key={option.value}
            >
              {option.label}
            </option>
          ))}
      </select>

      {props.canClear && props.value && (
        <Icon
          className="cc-select-clear-button"
          icon="close"
          isButton={true}
          size={16}
          onClick={() => {
            if (props.onChange) {
              props.onChange("");
            }
          }}
        />
      )}
    </div>
  );
};
