/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../typings/tyto";
import AgnosticModal from "../agnostic/";
import ImportUsers from "./ImportUsers";

import "./style.scss";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
  team: Tyto.Team;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className="modals-importusers-modal-main-cont"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-importusers-modal-overlay"
    >
      <ImportUsers onDismiss={props.onDismiss} team={props.team} />
    </AgnosticModal>
  );
};
