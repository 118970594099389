/*
 * Component Description
 */
import * as React from "react";
import { Tyto } from "../../../typings/tyto";

import { Message } from "../../common/";

import { loadInfo } from "./utils";

import EmailForm from "./subcomponents/EmailForm";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
  discProfileMini?: Tyto.DISCProfileMini;
  personalDiscMini?: Tyto.DISCProfileMini;
  title?: string;
  userID: number;
}

export default (props: Props) => {
  const [headline, updateHeadline] = React.useState(
    props.title || "Send Login Email"
  );
  const [person, updatePerson] = React.useState<Tyto.Person | null>(null);
  const [domainTemplate, updateDomainTemplate] = React.useState<
    Tyto.DomainInvitation.Template | undefined
  >(undefined);
  const [error, updateError] = React.useState("");

  React.useEffect(() => {
    if (props.discProfileMini) {
      updateError("");

      loadInfo({
        userID: props.userID,
        onError: (errMsg: string) => {
          updateError(errMsg);
        },
        onSuccess: ({ person, template }) => {
          updateError("");
          updateDomainTemplate(template);
          updatePerson(person);
        },
      });
    }
  }, [props.discProfileMini]);

  React.useEffect(() => {
    if (props.discProfileMini) {
      updateError("");

      loadInfo({
        userID: props.userID,
        onError: (errMsg: string) => {
          updateError(errMsg);
        },
        onSuccess: ({ person, template }) => {
          updateError("");
          updateDomainTemplate(template);
          updatePerson(person);
        },
      });
    }
  }, []);

  const loading = !person && !domainTemplate;

  return (
    <section className="modals-userrecovery-modal-inner-cont">
      <h2 className="modals-setpassword-title title-bold">{headline}</h2>

      {loading ? (
        <Message value={error ? `${error}` : "Loading..."} />
      ) : (
        <EmailForm
          onDismiss={props.onDismiss}
          template={domainTemplate}
          personalDiscMini={props.personalDiscMini}
          userID={props.userID}
        />
      )}
    </section>
  );
};
