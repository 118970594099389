/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import { Tyto } from "../../../../typings/tyto";
import { TextButton, UserThumb, UserThumbPlaceholder } from "../../../common/";
import { getSessionsAsArray } from "../../../../data/storage/session";
import { SessionData } from "../../../../typings";
import { AppStoreProps } from "../../../../data/stores/AppStore";
import NavLoginModal from "../subcomponents/NavLoginModal";
import WSConnectionIdentifier from "./WSConnectionIdentifier";

interface Props {
  AppStore: AppStoreProps;
  curDomainID?: number;
  curDomainInfo?: Tyto.Team;
  personalDiscMini?: Tyto.DISCProfileMini;
  personInfo?: Tyto.Person;
  sessionsArray: SessionData[];
  updateSessionsArray: (newSessionData: SessionData[]) => void;
  userHasManage: boolean;
}

export default ({
  AppStore,
  curDomainInfo,
  personalDiscMini,
  personInfo,
  sessionsArray,
  updateSessionsArray,
  userHasManage,
}: Props) => {
  const isImpersonationSession = !!_.get(
    AppStore,
    "state.sessionData.adminID",
    0
  );

  const navigate = useNavigate();

  return (
    <li
      className={cx(
        "meta-nav-list-userinfo",
        isImpersonationSession && "is-impersonation-session"
      )}
    >
      <button
        className="meta-nav-profile-link"
        onClick={() => {
          if (personalDiscMini) {
            navigate(`/profile/${personalDiscMini.personID}`);
          }
        }}
      >
        {personalDiscMini ? (
          <UserThumb
            asset={personalDiscMini.profileImageAsset}
            discMiniProfile={personalDiscMini}
            discValues={{
              d: personalDiscMini.d3,
              i: personalDiscMini.i3,
              s: personalDiscMini.s3,
              c: personalDiscMini.c3,
            }}
            userName={personalDiscMini.personName}
            size={34}
          />
        ) : (
          <UserThumbPlaceholder className="meta-nav-list-userinfo" size={34} />
        )}

        <h3 className="meta-nav-list-userinfo-title title-bold">
          {userHasManage && curDomainInfo ? (
            <TextButton
              className="meta-nav-list-userinfo-title-domain-name"
              onClick={() => {}}
              value={curDomainInfo.name}
            />
          ) : (
            "My TeamTools"
          )}
        </h3>
      </button>

      <NavLoginModal
        AppStore={AppStore}
        reloadSessions={() => {
          const newSessionArr = getSessionsAsArray();
          console.log("reloadSessions triggered: ", newSessionArr);
          updateSessionsArray(newSessionArr);
        }}
        sessionsArray={sessionsArray}
        userHasManage={userHasManage}
      />

      <WSConnectionIdentifier
        isConnected={_.get(AppStore, "state.websocketServerConnected", false)}
      />

      {isImpersonationSession && (
        <span className="meta-nav-list-userinfo-impersonation-session-text">
          Impersonation Session
        </span>
      )}
    </li>
  );
};
