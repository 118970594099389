/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";

import Button from "../button/";

import "./style.scss";

interface Props {
  acceptedFileTypes?: string;
  className?: string;
  multiple?: boolean;
  id?: string;
  innerRef?: React.MutableRefObject<HTMLInputElement | null>;
  label?: string;
  onChange: (files: File[]) => void;
}

export default ({
  acceptedFileTypes,
  className,
  id,
  innerRef,
  label,
  multiple = false,
  onChange
}: Props) => {
  const [
    curDraggedItemsAreAcceptable,
    updateCurDraggedItemsAreAcceptable
  ] = React.useState<boolean | undefined>(undefined);

  return (
    <div
      className={cx(
        "cc-file-input-cont",
        curDraggedItemsAreAcceptable !== undefined &&
          curDraggedItemsAreAcceptable &&
          "cc-file-input-cont-areacceptablefiles",
        curDraggedItemsAreAcceptable !== undefined &&
          !curDraggedItemsAreAcceptable &&
          "cc-file-input-cont-arenotacceptablefiles",
        className
      )}
    >
      <input
        accept={acceptedFileTypes}
        className="cc-file-input"
        id={id}
        multiple={multiple}
        onChange={e => {
          // TODO Handle multiple files
          if (e.target && e.target.files) {
            onChange([e.target.files[0]]);
          }
        }}
        onDrop={e => {
          if (acceptedFileTypes) {
            const firstFileType: string = _.get(
              e,
              "dataTransfer.items[0].type",
              ""
            );
            const fileTypes = acceptedFileTypes
              .replace(".", "")
              .split(",")
              .filter(ft => !!ft);
            const oneIsAcceptable = fileTypes.some(ft => {
              const regExp = new RegExp(_.escapeRegExp(ft), "i");

              return regExp.test(firstFileType);
            });

            if (!oneIsAcceptable) {
              e.preventDefault();
            }
          }
        }}
        onDragOver={e => {
          if (!acceptedFileTypes) {
            updateCurDraggedItemsAreAcceptable(true);
          } else {
            const firstFileType: string = _.get(
              e,
              "dataTransfer.items[0].type",
              ""
            );
            const fileTypes = acceptedFileTypes
              .replace(".", "")
              .split(",")
              .filter(ft => !!ft);
            const oneIsAcceptable = fileTypes.some(ft => {
              const regExp = new RegExp(_.escapeRegExp(ft), "i");

              return regExp.test(firstFileType);
            });

            if (!fileTypes.length || oneIsAcceptable) {
              updateCurDraggedItemsAreAcceptable(true);
            } else {
              updateCurDraggedItemsAreAcceptable(false);
            }
          }
        }}
        onDragLeave={() => {
          if (curDraggedItemsAreAcceptable !== undefined) {
            updateCurDraggedItemsAreAcceptable(undefined);
          }
        }}
        ref={innerRef}
        type="file"
      />

      <div className="cc-file-input-fake-cont">
        <span className="cc-file-input-fake-label">Drag and Drop Here or</span>

        <Button
          className="cc-file-input-fake-button"
          type="hollow"
          shape="square"
          onClick={() => {}}
          value="Choose a File"
        />

        {acceptedFileTypes && (
          <span className="cc-file-input-fake-filetypes">
            Accepted File Types:{" "}
            {acceptedFileTypes.replace(",", ", ").toUpperCase()}
          </span>
        )}
      </div>
    </div>
  );
};
