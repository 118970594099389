/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import { Tyto } from "../../typings/tyto";
import { TextButton } from "../../components/common/";
import { clearSessionData } from "../../data/utils/";
import { StoreContext } from "../../data/stores/AppStore";
import { getTempSessionData } from "../../data/storage/session";
import { DEFAULT_TEAMTOOLS_USER_PERMISSIONS } from "../../data/constants/";
import CannotTakeDISC from "./subcomponents/CannotTakeDISC";
import Questions from "./subcomponents/Questions";

import BeginAssessmentConfirmation from "./subcomponents/BeginAssessmentConfirmation";
import AssessmentTaker247 from "./247-taker/AssessmentTaker";

import { StoreContextProvider } from "./data/store/";

import "./style.scss";

interface Props {
  path?: string;
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const navigate = useNavigate();

  const [loggedInUserTeamToolsPermit, updateLoggedInUserTeamToolsPermit] =
    React.useState<Tyto.TeamToolsPermissions>(
      _.get(
        AppStore,
        `state.loggedInUserTeamToolsPermit[${_.get(
          AppStore,
          "state.loggedInUserID",
          undefined
        )}]`,
        DEFAULT_TEAMTOOLS_USER_PERMISSIONS
      )
    );
  const [sessionKey, updateSessionKey] = React.useState(
    AppStore &&
      AppStore.state &&
      AppStore.state.sessionData &&
      AppStore.state.sessionData.sessionKey
      ? AppStore.state.sessionData.sessionKey
      : ""
  );
  const [beginAssessment, updateBeginAssessment] = React.useState(false);

  React.useEffect(() => {
    const curTempSessionDataInState = _.get(AppStore, "state.tempSessionData");
    const loggedInUserID = _.get(AppStore, "state.loggedInUserID", 0);

    if (!curTempSessionDataInState && loggedInUserID) {
      const tempSessionDataFromStorage = getTempSessionData(loggedInUserID);

      if (
        tempSessionDataFromStorage &&
        tempSessionDataFromStorage.sessionKey &&
        AppStore.dispatch
      ) {
        AppStore.dispatch({
          payload: {
            tempSessionData: tempSessionDataFromStorage,
            // @ts-ignore
            tempSessionKey: tempSessionDataFromStorage.tempSessionKey || "",
          },
          type: "SET_TEMP_SESSION_DATA",
        });
      }
    }
  }, []);

  React.useEffect(() => {
    const newSessionData = _.get(AppStore, "state.sessionData", undefined);

    if (
      sessionKey &&
      (!newSessionData || (newSessionData && !newSessionData.sessionKey))
    ) {
      updateSessionKey(undefined);
    }
    // * Could be used to redirect to Directory based on personProfile availability change
  }, [_.get(AppStore, "state.sessionData", undefined)]);

  React.useEffect(() => {
    updateLoggedInUserTeamToolsPermit(
      _.get(
        AppStore,
        `state.loggedInUserTeamToolsPermit[${_.get(
          AppStore,
          "state.loggedInUserID",
          undefined
        )}]`,
        DEFAULT_TEAMTOOLS_USER_PERMISSIONS
      )
    );
  }, [_.get(AppStore, "state.loggedInUserTeamToolsPermit")]);

  React.useEffect(() => {
    if (!sessionKey) {
      navigate("/login");
    } else if (!_.get(AppStore, "state.needsAssessment", true)) {
      navigate("/");
    }
  }, [!!sessionKey, _.get(AppStore, "state.needsAssessment", true)]);

  if (!sessionKey || !_.get(AppStore, "state.needsAssessment", true)) {
    return <div>Redirecting...</div>;
  } else if (!loggedInUserTeamToolsPermit.mayTakeDisc) {
    return (
      <section className="assessmenttaker-interface">
        <CannotTakeDISC AppStore={AppStore} />
      </section>
    );
  }

  return (
    <StoreContextProvider>
      <section className="assessmenttaker-interface">
        <AssessmentTaker247
          isMobile={false}
          loggedInUserID={AppStore.state?.loggedInUserID || 0}
        />
      </section>
    </StoreContextProvider>
  );
};

{
  /* {beginAssessment ? (
      <Questions AppStore={AppStore} />
    ) : (
      <BeginAssessmentConfirmation
        triggerStart={() => updateBeginAssessment(true)}
      />
    )}

    <div className="assessmenttaker-interface-logout-button-cont">
      <TextButton
        className="assessmenttaker-interface-logout-button"
        value="Logout"
        onClick={() =>
          clearSessionData(() => {
            if (AppStore.dispatch) {
              AppStore.dispatch({
                payload: {},
                type: "CLEAR_USER_SESSION_DATA",
              });
            }
          })
        }
      />
    </div> */
}
