/*
 * Component Description
 */
import * as React from "react";

import { DISCPlotGraph, Icon, UserThumb } from "../../../../common/";
import {
  calcGradientForIcon,
  getIconTypeFromDISCType,
  isValidIcon,
} from "../../../../../data/utils/helpers/";

import "./PersonHeadPhotos.scss";
import { Tyto } from "../../../../../typings/tyto";
import { isPropertySignature } from "typescript";

interface Props {
  discMini: Tyto.DISCProfileMini;
  personalDiscMini: Tyto.DISCProfileMini;
}

export default ({ discMini, personalDiscMini }: Props) => {
  const contRef: React.MutableRefObject<HTMLElement | null> = React.useRef(
    null
  );
  const [contWidth, updateContWidth] = React.useState(0);

  React.useEffect(() => {
    if (contRef && contRef.current) {
      const contInfo = contRef.current.getBoundingClientRect();
      updateContWidth(contInfo.width);
    } else {
      updateContWidth(0);
    }
  }, [contRef.current]);

  return (
    <section className="meta-sidepane-person-headphotos-cont" ref={contRef}>
      <div className="meta-sidepane-person-headphotos-persons-cont">
        <div className="meta-sidepane-person-headphotos-person-cont meta-sidepane-person-headphotos-person-left">
          <UserThumb
            asset={personalDiscMini.profileImageAsset}
            discMiniProfile={discMini}
            discValues={{
              d: personalDiscMini.d3,
              i: personalDiscMini.i3,
              s: personalDiscMini.s3,
              c: personalDiscMini.c3,
            }}
            size={58}
            userName={personalDiscMini.personName}
          />

          <Icon
            className="meta-sidepane-person-headphotos-person-icon"
            gradient={calcGradientForIcon({
              d: personalDiscMini.d3,
              i: personalDiscMini.i3,
              s: personalDiscMini.s3,
              c: personalDiscMini.c3,
            })}
            icon={isValidIcon(personalDiscMini.graphic)}
            size={34}
          />

          <div className="meta-sidepane-person-headphotos-person-stylename-cont">
            <p
              className="meta-sidepane-person-headphotos-person-stylename"
              style={{ width: "62px" }}
            >
              {personalDiscMini.styleName3}
            </p>
          </div>
        </div>

        <div className="meta-sidepane-person-headphotos-person-cont meta-sidepane-person-headphotos-person-right">
          <Icon
            className="meta-sidepane-person-headphotos-person-icon"
            gradient={calcGradientForIcon({
              d: discMini.d3,
              i: discMini.i3,
              s: discMini.s3,
              c: discMini.c3,
            })}
            icon={isValidIcon(discMini.graphic)}
            size={34}
          />

          <UserThumb
            asset={discMini.profileImageAsset}
            discValues={{
              d: discMini.d3,
              i: discMini.i3,
              s: discMini.s3,
              c: discMini.c3,
            }}
            size={58}
            userName={discMini.personName}
          />

          <div className=".meta-sidepane-person-headphotos-person-stylename-cont">
            <p
              className="meta-sidepane-person-headphotos-person-stylename"
              style={{ width: "62px" }}
            >
              {discMini.styleName3}
            </p>
          </div>
        </div>
      </div>

      {contWidth && (
        <DISCPlotGraph
          groupStyle={[
            {
              d: discMini.d3,
              i: discMini.i3,
              s: discMini.s3,
              c: discMini.c3,
              personName: discMini.personName,
            },
            {
              d: personalDiscMini.d3,
              i: personalDiscMini.i3,
              s: personalDiscMini.s3,
              c: personalDiscMini.c3,
              personName: personalDiscMini.personName,
            },
          ]}
          size={contWidth}
        />
      )}
    </section>
  );
};
