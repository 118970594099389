/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Icon, Input } from "../../../../../components/common/";

interface Props {
  canEdit: boolean;
  canView: boolean;
  hasLocalChange: boolean;
  onChange: (newValue: string) => void;
  reset: () => void;
  labelKey: string;
  saving: boolean;
  title: string;
  value: string;
  widthOverride?: "full" | "half";
}

export default (props: Props) => {
  if (!props.canView) {
    return null;
  }

  if (!props.canEdit) {
    return (
      <div
        className={cx(
          "directory-tabs-tab-section-account-section-item-cont",
          props.widthOverride === "full" &&
            "account-section-item-cont-fullwidth"
        )}
      >
        <p className="directory-tabs-tab-section-account-section-item-input-label">
          {props.title}
        </p>

        <p className="directory-tabs-tab-section-account-section-item-text">
          {props.value || (
            <span style={{ opacity: 0.2, fontStyle: "italic" }}>-empty-</span>
          )}
        </p>
      </div>
    );
  }

  return (
    <div
      className={cx(
        "directory-tabs-tab-section-account-section-item-cont",
        props.widthOverride === "full" && "account-section-item-cont-fullwidth"
      )}
    >
      <p className="directory-tabs-tab-section-account-section-item-input-label">
        {props.title}
      </p>

      <Input
        containerClassName="directory-tabs-tab-section-account-section-item-input-cont"
        className={cx(
          "directory-tabs-tab-section-account-section-item-input",
          props.hasLocalChange && "account-section-item-input-has-change"
        )}
        hideClear={true}
        disabled={props.saving}
        name={props.labelKey}
        placeholder="-Empty-"
        onChange={props.onChange}
        value={props.value}
      />

      {props.hasLocalChange && (
        <Icon
          isButton={true}
          buttonProps={{
            className:
              "directory-tabs-tab-section-account-section-item-reset-button",
            disabled: props.saving
          }}
          icon="undo-alt"
          onClick={props.reset}
          size={12}
        />
      )}
    </div>
  );
};
