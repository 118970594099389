/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import { Icon, TextButton } from "../../common/";
import { Tyto } from "../../../typings/tyto";
import { UserOnlineInfo } from "../../../data/stores/AppStore";

import { triggerMixPanelEvent } from "../../../data/logging/";
import Person from "./person/";
import Team from "./team/";

import "./style.scss";

export interface Props {
  asModal?: boolean;
  canClose?: boolean;
  canMinimize?: boolean;
  className?: string;
  closeOnBlur?: boolean;
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMini: Tyto.DISCProfileMini[];
  discFull?: Tyto.DISCInfo[];
  loadDISCMiniIfNotFound?: boolean;
  memberships?: { [x: number]: Tyto.AdvancedPersonTeamMembership[] };
  onClose?: () => void;
  onClick?: () => void;
  onlineUsersTable?: {
    [x: number]: UserOnlineInfo;
  };
  onPersonSelect: (personID: number) => void;
  onPermitUpdate?: (personID: number) => void;
  focusedPersonID?: number;
  loggedInUserHasManage?: boolean;
  loggedInUserID?: number;
  reloadTeam?: (teamID: number) => void;
  teams?: { [x: number]: Tyto.Team };
  teamsWithConfiguration?: { [x: number]: Tyto.TeamGet };
}

export default (props: Props) => {
  const [singleProfile, updateSingleProfile] = React.useState(() => {
    if (props.focusedPersonID) {
      const profile = props.discMini.find(
        ({ personID }) => personID === props.focusedPersonID
      );

      return profile;
    } else {
      return props.discMini[0];
    }
  });
  const [toucheHandleX, updateTouchHandleX] = React.useState(0);
  const [toucheHandleY, updateTouchHandleY] = React.useState(0);
  const [isMinimized, updateIsMinimized] = React.useState(false);

  React.useEffect(() => {
    // * [1] - Logging Trigger
    if (props.focusedPersonID || props.discMini[0]) {
      const discMiniID =
        Array.isArray(props.discMini) &&
        props.discMini.length === 1 &&
        _.get(props.discMini, "[0].personID", undefined);

      const discMiniProfileIsUnique =
        discMiniID &&
        props.focusedPersonID !== discMiniID &&
        discMiniID !== _.get(singleProfile, "personID", undefined);

      const noDiscMiniAndFocusPersonChanged =
        !discMiniID &&
        props.focusedPersonID &&
        props.focusedPersonID !== _.get(singleProfile, "personID", undefined);

      if (discMiniProfileIsUnique || noDiscMiniAndFocusPersonChanged) {
        triggerMixPanelEvent("APP_PERSON_VIEWED", {
          userID: props.focusedPersonID,
          userName: null,
        });
      }
    }

    // * [2] - Use information
    if (props.focusedPersonID) {
      const profile = props.discMini.find(
        ({ personID }) => personID === props.focusedPersonID
      );

      updateSingleProfile(profile);
    } else {
      updateSingleProfile(props.discMini[0]);
    }
  }, [props.discMini, props.focusedPersonID]);

  return (
    <section
      className={cx(
        "meta-sidepane-main-cont",
        isMinimized && "meta-sidepane-main-cont-minimized",
        props.className
      )}
      onClick={props.onClick}
      onTouchMove={(e) => {
        if (props.canMinimize && e.targetTouches.length) {
          // debugger;
          const diffX =
            (e.targetTouches[0].clientX || 0) - (toucheHandleX || 0);
          const diffY =
            (e.targetTouches[0].clientY || 0) - (toucheHandleY || 0);

          if (Math.abs(diffY) > 30) {
            return;
          }

          if (!isMinimized && diffX > 100) {
            updateIsMinimized(true);
          } else if (isMinimized && diffX < 100) {
            updateIsMinimized(false);
          }
        }
      }}
      onTouchStart={(e) => {
        if (props.canMinimize && e.targetTouches.length) {
          updateTouchHandleX(e.targetTouches[0].clientX);
          updateTouchHandleY(e.targetTouches[0].clientY);
        }
      }}
    >
      {props.canMinimize && (
        <TextButton
          className={cx(
            "meta-sidepane-minimize-button",
            isMinimized && "meta-sidepane-minimize-button-isminimized"
          )}
          iconProps={{
            icon: isMinimized ? "angle-left" : "angle-right",
            size: 16,
          }}
          onClick={() => updateIsMinimized(!isMinimized)}
          value={isMinimized ? "More" : "Less"}
        />
      )}

      {props.canClose && !isMinimized && (
        <Icon
          className="meta-sidepane-close-button"
          isButton={true}
          icon="close"
          size={25}
          onClick={() => {
            if (props.onClose) {
              props.onClose();
            }
          }}
        />
      )}

      {props.discMini.length > 1 && !props.focusedPersonID ? (
        <Team
          discMiniProfiles={props.discMini}
          isMinimized={isMinimized}
          setFocusedPerson={props.onPersonSelect}
        />
      ) : (
        <Person
          discCompareProfile={props.discCompareProfile}
          discMiniProfile={singleProfile}
          hasDISCProfile={!!(singleProfile && !!singleProfile.styleKey3)}
          isMinimized={isMinimized}
          isOwnProfile={
            !!props.loggedInUserID &&
            !!singleProfile &&
            props.loggedInUserID === singleProfile.personID
          }
          loggedInUserHasManage={props.loggedInUserHasManage}
          memberships={props.memberships}
          onPermitUpdate={props.onPermitUpdate}
          showFull={!props.focusedPersonID}
          setFocusedPerson={props.onPersonSelect}
          reloadTeam={props.reloadTeam}
          teams={props.teams}
          teamsWithConfiguration={props.teamsWithConfiguration}
        />
      )}
    </section>
  );
};
