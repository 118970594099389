import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";
import pkg from "../../../../package.json";

import { AuthResult, SessionData } from "../../../typings";
import { AppStoreProps } from "../../../data/stores/AppStore";
import { Button, Input, TextButton } from "../../../components/common";
import TytoCalls from "../../../data/tyto/";
import {
  getSessionsAsArray,
  setSessionDataForMultipleSessions,
} from "../../../data/storage/session";
// import { loadStarterData } from "../../../data/utils/";

import ForgotPassword from "./ForgotPassword";
import MultipleSessionsMatched from "./MultipleSessionsMatched";

const appBrand = "team-tools-webapp";
const appVersion = _.get(pkg, "version", "");

interface Props {
  location?: any;
  loginName?: string;
  store?: AppStoreProps;
  uri?: string;
}

const Logon = (props: Props) => {
  const [email, updateEmail] = React.useState(props.loginName || "");
  const [error, updateError] = React.useState("");
  const [password, updatePassword] = React.useState("");
  const [loggingIn, updateLoggingIn] = React.useState(false);
  const [redirectToAssessment, updateRedirectToAssessment] =
    React.useState(false);
  const [showForgotPassword, updateShowForgotPassword] = React.useState(false);
  const [authResults, updateAuthResults] = React.useState<
    AuthResult[] | undefined
  >(undefined);
  const [sessionData, updateSessionData] = React.useState<
    SessionData | undefined
  >(_.get(props, "store.state.sessionData", undefined));
  const [storedSessions] = React.useState(getSessionsAsArray());

  const navigate = useNavigate();

  // * Keep checking for sessionData in store
  React.useEffect(() => {
    updateSessionData(_.get(props, "store.state.sessionData", undefined));
  }, [props.store]);

  // * If there is exactly one stoed session assume it should be the session
  // * NOTE: This "shouldn't" be reached
  React.useEffect(() => {
    if (Array.isArray(storedSessions) && storedSessions.length === 1) {
      const [onlyStoredSession] = storedSessions;

      if (
        typeof onlyStoredSession === "object" &&
        onlyStoredSession.sessionKey &&
        props.store &&
        props.store.dispatch
      ) {
        props.store.dispatch({
          payload: onlyStoredSession,
          type: "USER_LOGGED_IN",
        });
      }
    }
  }, [storedSessions]);

  React.useEffect(() => {
    if (!sessionData) return;

    debugger;

    if (sessionData) {
      const redirectParam: string = _.get(props.location, "search", "");
      const parsedValue = /\?redirect\=/g.test(redirectParam)
        ? redirectParam.replace("?redirect=", "")
        : "/";

      if (sessionData.changePassword) {
        return navigate(`/set-password${parsedValue}`);
      } else {
        return navigate(parsedValue || "/");
      }
    }
  }, [sessionData]);

  React.useEffect(() => {
    if (!props.loginName) return;
    if (!Array.isArray(storedSessions)) return;
    if (storedSessions.length < 2) return;

    const redirectParam: string = _.get(props.location, "search", "");
    debugger;

    navigate(`/account-selection${redirectParam}`);
  }, [storedSessions, props.loginName]);

  React.useEffect(() => {
    if (!Array.isArray(storedSessions)) return;
    if (storedSessions.length < 2) return;

    const redirectParam: string = _.get(props.location, "search", "");
    debugger;

    navigate(`/account-selection${redirectParam}`);
  }, []);

  React.useEffect(() => {
    if (!redirectToAssessment) return;

    navigate("/account-creation");
  }, [redirectToAssessment]);

  if (sessionData) {
    return <div>Redirecting...</div>;
  }

  if (redirectToAssessment) {
    return <div>Redirecting...</div>;
  }

  if (showForgotPassword) {
    return <ForgotPassword cancel={() => updateShowForgotPassword(false)} />;
  }

  if (
    Array.isArray(storedSessions) &&
    storedSessions.length > 1 &&
    !props.loginName
  ) {
    debugger;
    const redirectParam: string = _.get(props.location, "search", "");

    return <div>Redirecting...</div>;
  }

  return (
    <section className="logon-interface">
      <div className="logon-inner-wrapper">
        {authResults && authResults.length ? (
          <MultipleSessionsMatched
            AppStore={props.store}
            authResults={authResults}
          />
        ) : (
          <div className="logon-cont">
            <h1 className="logon-title extra-space title-bold">
              Welcome to TeamTools
            </h1>

            <Input
              autofocus={true}
              containerClassName="logon-input"
              hideClear={true}
              onChange={(email) => updateEmail(email)}
              placeholder="Email"
              value={email}
            />

            <Input
              containerClassName="logon-input"
              hideClear={true}
              value={password}
              onChange={(password) => updatePassword(password)}
              onEnter={() =>
                loginCall({
                  email,
                  password,
                  loggingIn,
                  updateError,
                  updateAuthResults,
                  updateLoggingIn,
                  AppStore: props.store,
                })
              }
              placeholder="Password"
              type="password"
            />

            {error && <p style={{ color: "red" }}>{error}</p>}
            <Button
              className="logon-button"
              disabled={loggingIn || !email || !password}
              onClick={() =>
                loginCall({
                  email,
                  password,
                  loggingIn,
                  updateError,
                  updateAuthResults,
                  updateLoggingIn,
                  AppStore: props.store,
                })
              }
              shape="square"
              type="color"
              value={loggingIn ? "Logging In..." : "Login"}
            />

            <div className="logon-teamtools-redirect-cont">
              <TextButton
                className="logon-teamtools-redirect-link"
                value="Forgot Password"
                onClick={() => updateShowForgotPassword(true)}
              />

              {/* <TextButton
                className="logon-teamtools-redirect-link"
                value="Create Account"
                onClick={() => updateRedirectToAssessment(true)}
              /> */}
            </div>

            <div className="logon-teamtools-img-cont">
              <img className="logon-teamtools-img" src={"./teamtools.png"} />
            </div>

            <p className="logon-teamtools-version-id">
              {_.get(pkg, "version", "")}
            </p>
          </div>
        )}
        <div className="logon-inner-wrapper-animation">
          {/* <div className="logon-inner-wrapper-animation-square logon-inner-wrapper-animation-square-1" />
          <div className="logon-inner-wrapper-animation-square logon-inner-wrapper-animation-square-2" />
          <div className="logon-inner-wrapper-animation-square logon-inner-wrapper-animation-square-3" />
          <div className="logon-inner-wrapper-animation-square logon-inner-wrapper-animation-square-4" /> */}
        </div>
      </div>
    </section>
  );
};

const loginCall = ({
  loggingIn,
  email,
  password,
  AppStore,
  updateError,
  updateAuthResults,
  updateLoggingIn,
}: {
  loggingIn: boolean;
  email: string;
  password: string;
  AppStore?: AppStoreProps;
  updateLoggingIn: (newVal: boolean) => void;
  updateAuthResults: (authResults: AuthResult[]) => void;
  updateError: (msg: string) => void;
}) => {
  if (!(loggingIn || !email || !password)) {
    if (AppStore && AppStore.dispatch) {
      updateLoggingIn(true);
      TytoCalls.Login.Authenticate4.post({
        username: email,
        password,
        appBrand,
        appVersion,
      })
        .then(async (resp) => {
          if (AppStore && AppStore.dispatch) {
            if (resp.authResults && resp.authResults.length === 1) {
              const { authSession, ...authResp } = resp.authResults[0];

              const { session } = await TytoCalls.AccountSession.get({
                sessionKey: authSession.sessionKey,
              });

              const coercedAuthResp: SessionData = {
                ...authSession,
                ...authResp,
                ...session,
              };

              AppStore.dispatch({
                payload: coercedAuthResp,
                type: "USER_LOGGED_IN",
              });
              // AppStore.dispatch({
              //   payload: resp.session,
              //   type: "USER_LOGGED_IN",
              // });
            } else if (resp.authResults && resp.authResults.length > 1) {
              const pluckedSessions = resp.authResults
                .map((authResult) => authResult.authSession)
                .filter((authSession) => !!authSession);

              setSessionDataForMultipleSessions(pluckedSessions);

              updateAuthResults(resp.authResults);
            }
          } else {
            console.warn(
              "store or store.dispatch were not defined when they should be."
            );
          }
        })
        .catch((err) => {
          console.warn("An Error occurred while trying to Authenticate User");

          let error = typeof err === "string" ? err : _.get(err, "msg", "Err");

          if (err && err.sts) {
            /*
               -201  'Logon fails. No logon names match'
               -202  'Logon fails. No password match'
              -204  'Password or LogonName is less than the minimum required characters (4) to be valid'	
              instead of the -225 response for multiple matches this endpoint creates a session for all valid user accounts with mathcing password. 
              The response list may contain one or more of the following for invalid or disabled accounts
              -205  'This account has been deactivated, contact customer support'	
              -221  'user account is not enabled, contact customer support'
            */
            if (err.sts === -202 || err.sts === -201) {
              updateError(`Incorrect email or password`);
            } else if (err.sts === -204) {
              updateError(
                `Password or LogonName is less than the minimum required characters (4) to be valid`
              );
            } else if (err.sts === -205) {
              updateError(
                `This account has been deactivated, contact customer support`
              );
            } else if (err.sts === -221) {
              updateError(
                `This account is not enabled, contact customer support`
              );
            }
          } else {
            updateError(`${error}`);
          }

          updateLoggingIn(false);
        });
    }
  }
};

export default Logon;
