/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../typings/tyto";
import { AppStoreProps } from "../../../data/stores/AppStore";
import { AdminStoreProps } from "../../admin/data/stores/AdminStore";
import { getPersonValueRespectfully } from "../../../data/utils/helpers/";
import { DISC, Message } from "../../../components/common";
import PeopleListItem from "./PeopleListItem";

import "./PeopleList.scss";
import userAlt from "../../../components/common/icon/icons/user-alt";

interface Props {
  AppStore: AppStoreProps;
  adminStore: AdminStoreProps;
  loading: boolean;
  previewUser: (personID: number) => void;
  searchTerm: string;
  selectedIDs: { [x: number]: boolean };
  toggleUserID: (personID: number) => void;
  users: Tyto.DISCProfileMini[];
}

export default (props: Props) => {
  const [selectedUserExists, updateSelectedUserExists] = React.useState(
    Object.values(props.selectedIDs || {}).some((isSelected) => !!isSelected)
  );

  React.useEffect(() => {
    const newSelectedUserExists = Object.values(props.selectedIDs || {}).some(
      (isSelected) => !!isSelected
    );

    updateSelectedUserExists(newSelectedUserExists);
  }, [props.selectedIDs]);

  return (
    <section className="directory-people-list-cont">
      <ul className="directory-people-list">
        {Array.isArray(props.users) &&
          _.orderBy(props.users, ["personName"], ["asc"]).map((user) => (
            <PeopleListItem
              adminStore={props.adminStore}
              canSelect={canBeSelected(user)}
              key={user.personID}
              hasR3Style={!!user.styleKey3}
              isSelected={props.selectedIDs[user.personID]}
              previewUser={props.previewUser}
              searchTerm={props.searchTerm}
              //   showCheckbox={selectedUserExists}
              showCheckbox={true}
              toggleUserID={props.toggleUserID}
              user={user}
              userOnlineInfo={_.get(
                props.AppStore,
                `state.onlineUsersTable[${user.personID}]`
              )}
            />
          ))}

        {props.loading && (
          <li>
            <Message
              className="directory-people-list-loading-msg"
              value="Loading..."
            />
          </li>
        )}
      </ul>
    </section>
  );
};

const canBeSelected = (discMiniProfile: Tyto.DISCProfileMini) => {
  if (!discMiniProfile.styleKey3) {
    return false;
  }

  return !_.get(
    getPersonValueRespectfully({ key: "d3", discMiniProfile }),
    "permitItem.HIDE",
    true
  );
};
