import * as _ from "lodash";
import { StoreState as AppStoreState } from "../../../../data/stores/AppStore";
import { Tyto } from "../../../../typings/tyto";

export function getProfilesOfTeamMembers({
  AppStoreState,
  isCascade,
  teamID,
}: {
  AppStoreState?: AppStoreState;
  isCascade?: boolean;
  teamID: number | string;
}) {
  if (!AppStoreState) {
    return undefined;
  }

  const teamList = _.get(
    AppStoreState,
    `${isCascade ? "discMiniByTeamAndBelow" : "discMiniByTeam"}[${`${teamID}`}]`
  );

  if (!Array.isArray(teamList)) {
    return undefined;
  }

  if (!teamList.length) {
    return [];
  }

  const miniProfiles = teamList.reduce(
    (accum: Tyto.DISCProfileMini[], userID) => {
      const miniProfile = _.get(AppStoreState, `discMini[${userID}]`);

      // * If Mini found, add to list
      if (miniProfile) {
        accum.push(miniProfile);
      } else {
        const miniProfileNullProfile = _.get(
          AppStoreState,
          `discMiniNullProfiles[${userID}]`
        );

        // * If mini NOT found, but nullProfile found, add to list
        if (miniProfileNullProfile) {
          accum.push(miniProfileNullProfile);
        }
      }

      return accum;
    },
    []
  );

  return miniProfiles;
}

// export function getProfilesOfTeamAndBelowMembers({
//   AppStoreState,
//   teamID,
// }: {
//   AppStoreState?: AppStoreState;
//   teamID: number | string;
// }) {
//   if (!AppStoreState) {
//     return undefined;
//   }

//   const teamList = _.get(
//     AppStoreState,
//     `discMiniByTeamAndBelow[${`${teamID}`}]`
//   );

//   if (!Array.isArray(teamList)) {
//     return undefined;
//   }

//   if (!teamList.length) {
//     return [];
//   }

//   const miniProfiles = teamList.reduce(
//     (accum: Tyto.DISCProfileMini[], userID) => {
//       const miniProfile = _.get(AppStoreState, `discMini[${userID}]`);

//       // * If Mini found, add to list
//       if (miniProfile) {
//         accum.push(miniProfile);
//       } else {
//         const miniProfileNullProfile = _.get(
//           AppStoreState,
//           `discMiniNullProfiles[${userID}]`
//         );

//         // * If mini NOT found, but nullProfile found, add to list
//         if (miniProfileNullProfile) {
//           accum.push(miniProfileNullProfile);
//         }
//       }

//       return accum;
//     },
//     []
//   );

//   return miniProfiles;
// }
