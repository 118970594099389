/*
 * Component Description
 */
import * as React from "react";

import { Input, Loading } from "../../../components/common/";

import "./SearchBar.scss";

interface Props {
  disabled?: boolean;
  loading?: boolean;
  onChange: (newTerm: string) => void;
  placeholder?: string;
  value: string;
}

export default (props: Props) => {
  return (
    <div className="admin-content-people-manage-searchbar">
      <Input
        containerClassName="admin-content-people-manage-searchbar-input-cont"
        className="admin-content-people-manage-searchbar-input"
        disabled={props.disabled}
        onChange={props.onChange}
        hideClear={true}
        placeholder={props.placeholder || "Search..."}
        value={props.value}
      />

      {props.loading && (
        <Loading
          className="admin-content-people-manage-searchbar-loading-icon"
          size={16}
        />
      )}
    </div>
  );
};
