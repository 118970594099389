/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Option from "./Option";

import "./style.scss";

interface Props {
  containerClassName?: string;
  className?: string;
  title?: string;
  titleClassName?: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}

export default (props: Props) => {
  return (
    <div className={cx("cc-multiplechoice-cont", props.containerClassName)}>
      {props.title && (
        <h3 className={cx("cc-multiplechoice-title", props.titleClassName)}>
          {props.title}
        </h3>
      )}

      <div className={cx("cc-multiplechoice-inner-cont", props.className)}>
        {Array.isArray(props.options) &&
          props.options.map(option => (
            <Option
              buttonProps={{
                className: cx(
                  props.value === option.value &&
                    "cc-multiplechoice-option-isselected"
                ),
                shape: "square",
                type: "hollow",
                onClick: () => props.onChange(option.value),
                value: option.label
              }}
            />
          ))}
      </div>
    </div>
  );
};
