/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";
import Papa from "papaparse";

import { Tyto } from "../../../typings/tyto";
import { Button } from "../../common/";
import { StoreContext } from "../../../data/stores/AppStore";

import ActionButtons from "./subcomponents/ActionButtons";
import InputCont from "./subcomponents/InputCont";
import Results from "./subcomponents/Results";

import { startDownloadTemplate } from "./utils/";

// type ParsedCSV = Papa.ParseResult[];

interface ParsedCSVItem {
  [x: string]: string;
}

interface Props {
  onDismiss: () => void;
  team: Tyto.Team;
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const inputRef = React.useRef<React.MutableRefObject<HTMLInputElement>>();
  const [error, updateError] = React.useState("");
  const [importing, updateImporting] = React.useState(false);
  const [file, updateFile] = React.useState<File | null>(null);
  const [parsingFile, updateParsingFile] = React.useState<boolean>(false);
  const [parsedCSV, updateParsedCSV] = React.useState<any[][] | null>(null);
  const [columnKeys, updateColumnKeys] = React.useState<string[]>([]);
  const [curHoveredColumn, updateCurHoveredColumn] = React.useState<number>(-1);
  const [useEmailAsLogonName, updateUseEmailAsLogonName] = React.useState(true);
  const [autoAddToTeam, updateAutoAddToTeam] = React.useState(true);
  const [importHasHeaders, updateImportHasHeaders] =
    React.useState<boolean>(true);
  const [showDeleteButton, updateShowDeleteButton] =
    React.useState<boolean>(true);
  const [deletedRows, updateDeletedRows] = React.useState<
    { idx: number; row: any[] }[]
  >([]);

  React.useEffect(() => {
    if (file) {
      updateError("");
      updateParsingFile(true);
      Papa.parse(file, {
        complete: (results, _file) => {
          debugger;
          const keyOrder = (importHasHeaders && results.meta.fields) || [];
          const data: any[] = Array.isArray(results.data[0])
            ? results.data
            : results.data.map((result) =>
                keyOrder.map(
                  (key) =>
                    (result &&
                      typeof result === "object" &&
                      (result as any)[key]) ||
                    ""
                )
              );
          // const data = Array.isArray(results.data[0])
          //   ? results.data
          //   : results.data.map(result =>
          //       keyOrder.map(key => result[key] || "")
          //     );

          updateParsedCSV(data);

          const emailRegExp = new RegExp(_.escapeRegExp("email"), "i");
          const emailIdx =
            importHasHeaders && results.meta.fields
              ? keyOrder.findIndex((key) => emailRegExp.test(key))
              : getEmailColIdx(data);

          const length = Array.isArray(data[0]) ? data[0].length : 0;
          updateColumnKeys(
            Array.from(
              { length: typeof length === "number" ? length : 0 },
              (__, curIdx) => (curIdx === emailIdx ? "email" : "")
            )
          );
          updateParsingFile(false);
        },
        error: () => {
          updateError("Error occurred while attempting to parse file.");
          updateParsingFile(false);
        },
        header: importHasHeaders,
      });
    }
  }, [file]);

  const columnCount = columnKeys.length || 0;
  const atLeastOneLabelSelected = columnKeys.some((colKey) => !!colKey);
  const hasIdentifierLabel = atLeastOneLabelSelected
    ? columnKeys.some((colKey) => colKey === "email" || colKey === "outsideID")
    : false;

  return (
    <div className="modals-importusers-modal-inner-cont">
      <h3 className="modals-importusers-modal-main-title">
        Import users into {props.team ? props.team.name : "Team Name"}
      </h3>

      <div
        className="modals-importusers-modal-top-row-cont"
        style={
          importing
            ? {
                opacity: 0,
                pointerEvents: "none",
              }
            : undefined
        }
      >
        <InputCont
          curFile={file}
          importing={importing}
          inputRef={inputRef}
          onFileChange={(file) => {
            if (file) {
              updateFile(file);
            } else {
              updateFile(null);
            }
          }}
        />

        <ActionButtons
          autoAddToTeam={autoAddToTeam}
          canAddRow={Array.isArray(parsedCSV)}
          deletedRowsExist={!!deletedRows.length}
          importHasHeaders={importHasHeaders}
          importedDataExists={Array.isArray(parsedCSV) && !!parsedCSV.length}
          showDeleteButton={showDeleteButton}
          useEmailAsLogonName={useEmailAsLogonName}
          onButtonClick={(action: string) => {
            if (importing) {
              return;
            }

            switch (action) {
              case "add-row":
                if (Array.isArray(parsedCSV) && columnCount) {
                  const newRow = Array.from(
                    { length: columnCount },
                    (__) => ""
                  );

                  updateParsedCSV([...parsedCSV, newRow]);
                }
                return;
              case "auto-team-add":
                updateAutoAddToTeam(!autoAddToTeam);

                return;
              case "clear-and-reset":
                if (inputRef && inputRef.current) {
                  // @ts-ignore
                  inputRef.current.value = null;
                  updateFile(null);
                  updateParsedCSV(null);
                }

                return;
              case "download-template":
                startDownloadTemplate();

                return;
              case "restore-row":
                if (Array.isArray(parsedCSV) && deletedRows.length) {
                  const [lastDeletedRow, ...rest] = deletedRows;

                  if (typeof lastDeletedRow.idx === "number") {
                    const updatedRows = [...parsedCSV];
                    updatedRows.splice(
                      lastDeletedRow.idx || 0,
                      0,
                      lastDeletedRow.row
                    );

                    updateParsedCSV(updatedRows);
                    updateDeletedRows(rest);
                  }
                }

                return;
              case "show-row-delete":
                updateShowDeleteButton(!showDeleteButton);

                return;
              case "import-has-headers":
                updateImportHasHeaders(!importHasHeaders);

                return;
              case "email-as-logon":
                updateUseEmailAsLogonName(!useEmailAsLogonName);
                return;
              default:
                return;
            }
          }}
        />
      </div>

      {parsedCSV && Array.isArray(parsedCSV) && (
        <p
          className={cx(
            "modals-importusers-modal-column-msg",
            !hasIdentifierLabel
              ? "modals-importusers-modal-column-error"
              : "modals-importusers-modal-column-warning"
          )}
        >
          {!hasIdentifierLabel ? (
            <>
              You must specify a column as <i>email</i> to import
            </>
          ) : (
            <>
              Data from unlabeled columns <b>will not be imported</b>
            </>
          )}
        </p>
      )}

      {parsedCSV && Array.isArray(parsedCSV) && (
        <Results
          autoAddToTeam={autoAddToTeam}
          appStore={AppStore}
          columnKeys={columnKeys}
          columnCount={columnCount}
          curHoveredColumn={curHoveredColumn}
          deleteRow={(rowIdx: number) => {
            const localCopy = [...parsedCSV];
            const rowToDelete = localCopy.splice(rowIdx, 1)[0];

            updateDeletedRows([
              { idx: rowIdx, row: rowToDelete },
              ...deletedRows,
            ]);
            updateParsedCSV(localCopy);
          }}
          importing={importing}
          showDeleteButton={showDeleteButton}
          showDisabledRows={atLeastOneLabelSelected && hasIdentifierLabel}
          team={props.team}
          useEmailAsLogonName={useEmailAsLogonName}
          updateCurHoveredColumn={updateCurHoveredColumn}
          updateColumnLabel={(labelIdx: number, value: string) => {
            if (importing) {
              return;
            }

            const newKeys = columnKeys.map((key, curIdx) => {
              // * Special case for handling use of Full Name || First/Last, but never both
              if (
                value === "__fullName__" ||
                value === "familyName" ||
                value === "givenName"
              ) {
                if (value === "__fullName__") {
                  if (
                    key === value ||
                    key === "familyName" ||
                    key === "givenName"
                  ) {
                    return "";
                  } else if (curIdx === labelIdx) {
                    return value;
                  }
                } else {
                  if (key === value || key === "__fullName__") {
                    return "";
                  } else if (curIdx === labelIdx) {
                    return value;
                  }
                }
              } else {
                if (key === value) {
                  return "";
                } else if (curIdx === labelIdx) {
                  return value;
                }
              }

              return key;
            });

            updateColumnKeys(newKeys);
          }}
          results={parsedCSV}
          updateItem={(listItemIdx: number, itemIdx: number, value: any) => {
            if (
              parsedCSV[listItemIdx] &&
              typeof parsedCSV[listItemIdx][itemIdx] !== undefined
            ) {
              const newValue = [...parsedCSV];

              parsedCSV[listItemIdx][itemIdx] = value;

              updateParsedCSV(newValue);
            }
          }}
        />
      )}

      {error && (
        <div className="modals-importusers-modal-errormsg">{error}</div>
      )}

      {parsingFile && (
        <div className="modals-importusers-modal-parsingmsg">
          Parsing File...
        </div>
      )}

      <div className="modals-importusers-modal-import-button-cont">
        <Button
          className="modals-importusers-modal-import-button"
          disabled={importing}
          onClick={props.onDismiss ? () => props.onDismiss() : () => {}}
          shape="square"
          type="hollow"
          value="Cancel"
        />

        <Button
          className="modals-importusers-modal-import-button"
          disabled={
            importing ||
            !Array.isArray(parsedCSV) ||
            !parsedCSV.length ||
            !atLeastOneLabelSelected ||
            !hasIdentifierLabel
          }
          onClick={() => {
            if (Array.isArray(parsedCSV) && parsedCSV.length) {
              updateImporting(true);

              // importUsers({
              //   onSuccess: () => {
              //     updateImporting(false);
              //   },
              //   onError: () => {
              //     updateImporting(false);
              //   },
              //   columnLabels: columnKeys,
              //   parsedCSV,
              //   primaryElementID: props.team.teamID
              // });
            }
          }}
          shape="square"
          type="color"
          value="Import Users"
        />
      </div>
    </div>
  );
};

const getEmailColIdx = (data: any[][]) => {
  const emailValueRegExpTest =
    /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;

  const colIdx = data.reduce((curColIdx, row) => {
    if (curColIdx >= 0) {
      return curColIdx;
    }

    return row.findIndex((val: string) => emailValueRegExpTest.test(val));
  }, -1);

  return colIdx;
};
