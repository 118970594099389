/*
 * Component Description
 */
import * as React from "react";

interface Props {}

export default (props: Props) => {
  return (
    <div className="directory-tabs-tab-section-comm-tips-tips-section-dosdonts-cont">
      User Has Not Completed the DISC Assessment.
    </div>
  );
};
