/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Tyto } from "../../../typings/tyto";
import {
  Checkbox,
  Icon,
  TextHighlighter,
  UserThumb,
} from "../../../components/common/";

interface Props {
  disabled: boolean;
  onCheck: () => void;
  onDrag: (personID: number) => void;
  isChecked: boolean;
  isRevealed: boolean;
  person: Tyto.AdvancedPerson;
  selectPerson: () => void;
  searchTerm: string;
  searchTermRegExp?: RegExp;
}

export default ({
  disabled,
  onCheck,
  onDrag,
  isChecked,
  isRevealed,
  person,
  selectPerson,
  searchTerm,
  searchTermRegExp,
}: Props) => {
  const displayName =
    person.givenName || person.familyName
      ? `${person.givenName} ${person.familyName}`
      : person.email;
  const passesTest = searchTermRegExp
    ? searchTermRegExp.test(displayName)
    : true;

  if (!passesTest && !isChecked) {
    return null;
  }

  return (
    <li
      key={person.userID}
      className="admin-content-people-manage-people-list-item"
    >
      <div className="admin-content-people-manage-people-list-item-person-inner-cont">
        <div className="admin-content-people-manage-people-list-item-person-checkbox-cont">
          <Checkbox
            className="admin-content-people-manage-people-list-item-person-checkbox"
            checked={isChecked}
            disabled={disabled}
            onCheck={() => onCheck()}
            size={16}
          />
        </div>

        <div className="admin-content-people-manage-people-list-item-person">
          <UserThumb
            className="admin-content-people-manage-people-list-item-person-userthumb"
            altImageAssetID={
              (person.profileImage && person.profileImage.assetID) || 0
            }
            discValues={{
              d: 0,
              i: 0,
              s: 0,
              c: 0,
            }}
            hasGradient={false}
            size={24}
            userName={`${person.givenName} ${person.familyName}`}
          />

          <p className="admin-content-people-manage-people-list-item-person-name">
            <TextHighlighter
              highlightClassName=""
              text={displayName}
              searchTerm={searchTerm}
              type="hightlight"
            />
          </p>
        </div>
      </div>

      <Icon
        buttonProps={{
          className: cx(
            "admin-content-people-manage-people-list-item-person-showicon-cont",
            isRevealed &&
              "admin-content-people-manage-people-list-item-person-showicon-cont-isrevealed"
          ),
          name: "Reveal all memberships in Team Structure",
        }}
        className="admin-content-people-manage-people-list-item-person-showicon"
        icon="eye"
        isButton={true}
        onClick={() => onDrag(isRevealed ? 0 : person.userID)}
        size={16}
      />
    </li>
  );
};
