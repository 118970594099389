import { ENDPOINT_DOMAIN_INVITATION_EMAIL_TEMPLATE } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_DOMAIN_INVITATION_EMAIL_TEMPLATE;

export const DomainInvitationEmailTemplate = {
  get(
    params: Endpoints.Tyto.DomainInvitationEmail.Template.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      template: any;
      session: SessionData;
    }>;
  },
};
