/*
 * Component Description
 */
import * as React from "react";

import { Textarea } from "../../../common";

import "./PlainText.scss";

interface Props {
  hasChange: boolean;
  plainText: string;
  updateValue: (newVal: string) => void;
}

export default (props: Props) => {
  return (
    <div className="modals-domainmessageedit-plaintext-cont">
      <div className="modals-domainmessageedit-plaintext-textarea-cont">
        <Textarea
          className="modals-domainmessageedit-plaintext-textarea"
          onChange={(newVal) => props.updateValue(newVal)}
          value={props.plainText}
        />
      </div>
    </div>
  );
};
