/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreAction } from "../../../../typings";
import { Tyto } from "../../../../typings/tyto";
import { getQuestionKey } from "../utils/";

interface UpdateQuestionSortOrder {
  nextPageID: number;
  optionValue: number;
  item_id: number;
  curIdx: number;
  newIdx: number;
}

export interface DirectoryProps {
  state?: StoreState;
  dispatch?: React.Dispatch<StoreAction<AssessmentTakerActionType>>;
}

export interface StoreState {
  assessmentResultID?: number;
  assessmentStatus?: string;
  curNextPageID?: number;
  curItemID?: number;
  discID?: number;
  initializing: boolean;
  isComplete?: boolean;
  loading?: boolean;
  error?: string;
  initializedDate?: Tyto.PeopleKeys.InitializedData;
  pages: Tyto.PeopleKeys.Page[];
  questionOptions: {
    [x: string]: Tyto.PeopleKeys.PageItemContentOption[];
  };
  questionNumber: number;
  pageItemsRaw: {
    [x: number]: Tyto.PeopleKeys.PageItem[];
  };
  sectionQuestionIdx?: number;
}

export type AssessmentTakerActionType =
  | "ASSESSMENT_TAKER_ASSESSMENT_COMPLETE"
  | "ASSESSMENT_TAKER_ERROR_OCCURRED"
  | "ASSESSMENT_TAKER_NEXT_PAGE_LOADED"
  | "ASSESSMENT_TAKER_NEXT_QUESTION"
  | "ASSESSMENT_TAKER_PAGES_LOADED"
  | "ASSESSMENT_TAKER_INITIALIZED_DATA_LOADED"
  | "ASSESSMENT_TAKER_SUBMIT_RESPONSE"
  | "ASSESSMENT_TAKER_TOGGLE_LOADING"
  | "ASSESSMENT_TAKER_UPDATE_QUESTION_SORT_ORDER";

let StoreContext: React.Context<DirectoryProps> = React.createContext({});

let initialState: StoreState = {
  initializing: true,
  loading: false,
  pages: [],
  questionOptions: {},
  questionNumber: 0,
  pageItemsRaw: {},
  sectionQuestionIdx: 0,
};

let reducer = (
  state: StoreState,
  action: StoreAction<AssessmentTakerActionType>
) => {
  console.log(action);

  if (!action.payload) {
    return state;
  }

  switch (action.type) {
    case "ASSESSMENT_TAKER_ASSESSMENT_COMPLETE":
      return {
        ...state,
        isComplete: true,
      };
    case "ASSESSMENT_TAKER_ERROR_OCCURRED":
      return {
        ...state,
        error: action.payload.error || "An error occurred",
      };
    case "ASSESSMENT_TAKER_NEXT_PAGE_LOADED":
      const {
        assessmentStatus,
        nextPageID,
        pageItems,
      } = action.payload.nextPage;

      const isComplete =
        typeof assessmentStatus === "string"
          ? assessmentStatus.toLowerCase() === "completed"
          : false;
      console.log(
        `AssessmentStatus: ${assessmentStatus}, evaluated to ${isComplete}`
      );

      if (isComplete) {
        return {
          ...state,
          isComplete,
        };
      }

      if (nextPageID) {
        const newPageItemsRaw = {
          ...state.pageItemsRaw,
          [nextPageID]: pageItems,
        };

        const pageIndex = _.findIndex(
          state.pages,
          (page) => page.page_id === nextPageID
        );
        let nextCurItemID = state.curItemID;

        const newOptions = (pageItems as any[]).reduce(
          (
            accum: { [x: string]: Tyto.PeopleKeys.PageItemContentOption[] },
            pageItem: Tyto.PeopleKeys.PageItem,
            curIdx: number
          ) => {
            if (!curIdx) {
              nextCurItemID = pageItem.item_id;
            }
            const key = getQuestionKey(nextPageID, pageItem.item_id);
            accum[key] = _.get(pageItem, "content.Options", []);

            return accum;
          },
          {}
        );

        const newQuestionOptions = {
          ...state.questionOptions,
          ...newOptions,
        };

        return {
          ...state,
          assessmentStatus,
          curNextPageID: nextPageID,
          curItemID: nextCurItemID,
          loading: false,
          questionOptions: newQuestionOptions,
          // questionNumber: state.questionNumber + (!!pageItems.length ? 1 : 0),
          questionNumber: Math.max(pageIndex - 1, 0) * 8 + 1,
          pageItemsRaw: newPageItemsRaw,
          sectionQuestionIdx: 0, // * Could possibly be not 0 or undefined in reality?
        };
      }

      return state;
    case "ASSESSMENT_TAKER_NEXT_QUESTION":
      if (state.curNextPageID && state.curItemID) {
        const questions = state.pageItemsRaw[state.curNextPageID];
        const curQuestionIndex = _.findIndex(
          questions,
          (question: Tyto.PeopleKeys.PageItem) =>
            question.item_id === state.curItemID
        );

        // * There are 8 questions per section; 7 is index of 8th question
        if (curQuestionIndex >= 7) {
          // TODO
          // ? Shouldn't get to this point ?
        } else {
          const nextItemID = questions[curQuestionIndex + 1].item_id;

          return {
            ...state,
            curItemID: nextItemID,
            loading: false,
            questionNumber: state.questionNumber + 1,
            sectionQuestionIdx: curQuestionIndex + 1,
          };
        }
      }

      return state;
    case "ASSESSMENT_TAKER_PAGES_LOADED":
      if (Array.isArray(action.payload.pages)) {
        return {
          ...state,
          pages: action.payload.pages,
        };
      }

      return state;
    case "ASSESSMENT_TAKER_INITIALIZED_DATA_LOADED":
      if (action.payload.assessmentResultID) {
        return {
          ...state,
          assessmentResultID: action.payload.assessmentResultID,
          discID: action.payload.discID,
          initializing: false,
          initializedData: action.payload,
        };
      } else if (!action.payload) {
        return {
          ...state,
          error: "An error occurred while initializing the Assessment.",
          initializing: false,
        };
      }

      return state;
    case "ASSESSMENT_TAKER_TOGGLE_LOADING":
      const loading =
        typeof action.payload.loading === "undefined"
          ? !state.loading
          : action.payload.loading;

      return {
        ...state,
        loading,
      };
    case "ASSESSMENT_TAKER_UPDATE_QUESTION_SORT_ORDER":
      const orderUpdate = action.payload as UpdateQuestionSortOrder;

      const questionKey = `${orderUpdate.nextPageID}-${orderUpdate.item_id}`;
      const targetQuestion = state.questionOptions[questionKey];

      if (Array.isArray(targetQuestion)) {
        const newAnswerOptions = Array.from(targetQuestion);
        const removedItem = newAnswerOptions.splice(orderUpdate.curIdx, 1)[0];
        newAnswerOptions.splice(orderUpdate.newIdx, 0, removedItem);

        // const updatedQuestionOptions = state.questionOptions[questionKey];
        const updatedQuestionOptions = newAnswerOptions;

        return {
          ...state,
          questionOptions: {
            ...state.questionOptions,
            [questionKey]: updatedQuestionOptions,
          },
        };
      } else {
        console.warn(
          "Expected to find questions block by ID but did not; COULD NOT UPDATE ANSWER SORT ORDER"
        );
      }

      return state;
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
