/*
 * Component Description
 */
import * as React from "react";

import { Message } from "../../../../components/common/";
import {
  getTabsFromTabType,
  PersonTabsType,
} from "../../tabs-interface/utils/";
import { TabType } from "../../tabs-interface/";
import Tabs from "../../tabs-interface/subcomponents/Tabs";

interface Props {
  tabType: TabType;
}

export default (props: Props) => {
  const [tabs, updateTabs] = React.useState(
    getTabsFromTabType(props.tabType, true)
  );
  const [curTab, updateCurTab] = React.useState(
    getTabsFromTabType(props.tabType, true)[0].value
  );

  return (
    <section className="directory-tabs-interface-main-cont">
      <div className="directory-tabs-interface-tabs-cont">
        <Tabs
          curTab={curTab}
          tabs={tabs}
          updateSelectedTab={(value) => {}}
          userHasManage={false}
        />
      </div>

      <div className="directory-tabs-tab-section-cont">
        <div
          className="directory-tabs-tab-section-comm-tips-tips-section-dosdonts-cont"
          style={{ minHeight: "600px" }}
        >
          <Message
            style={{
              marginTop: "0px",
              padding: "100px 0px 140px",
              boxSizing: "border-box",
            }}
            value="Loading..."
          />
        </div>
      </div>
    </section>
  );
};
