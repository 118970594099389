import * as React from "react";
import cx from "classnames";

import { Button, Icon, Input, Select } from "../../../../components/common";
import { Props as ButtonProps } from "../../../../components/common/button/";

import { SearchFilter } from "../../../teams/";

import "./style.scss";

type View = "list" | "tree";

interface Props {
  className?: string;
  filter: string;
  filterOptions: {
    label: string;
    value: string;
  }[];
  hideClear?: boolean;
  noSpace?: boolean;
  placeholder?: string;
  searchButton?: ButtonProps;
  searchTerm: string;
  searchType: string;
  searchTypeOptions: {
    label: string;
    value: string;
  }[];
  updateSearchType: (value: SearchFilter) => void;
  updateFilter: (value: SearchFilter) => void;
  updateSearchTerm: (value: string) => void;
}

export default (props: Props) => {
  // const [searchTerm, updateSearchTerm] = React.useState("");
  // const [selectFilter, updateSelectFilter] = React.useState(props.filterOptions[0].value);
  const [view, updateView] = React.useState("list");

  return (
    <section className={cx("directory-search-bar", props.className)}>
      {/* <Select
        containerClassName={cx(
          "directory-search-bar-searchtype-select-cont"
          //   props.noSpace && "director-search-bar-searchtype-select-cont-no-space"
        )}
        className="directory-search-bar-searchtype-select"
        defaultValue={props.searchTypeOptions[0].value}
        disabled={true}
        name="People View Filter"
        onChange={value => props.updateSearchType(`${value}` as SearchFilter)}
        options={props.searchTypeOptions}
        placeholder="Select Filter"
        value={props.searchType}
        omitFilter={true}
      /> */}
      <div className="directory-search-bar-searchtype-select-cont">
        <span className="directory-search-bar-searchtype-select fake-placeholder">People</span>
      </div>

      <Input
        containerClassName={cx(
          "directory-search-bar-search-input-cont",
          props.noSpace && "directory-search-bar-search-input-cont-no-space"
        )}
        hideClear={props.hideClear === undefined ? true : props.hideClear}
        iconLeft="search"
        iconProps={{
          className: "directory-search-bar-search-input-icon"
        }}
        onChange={value => props.updateSearchTerm(value)}
        onEnter={
          props.searchButton
            ? () => {
                if (
                  props.searchButton &&
                  !props.searchButton.disabled &&
                  props.searchButton.onClick
                ) {
                  props.searchButton.onClick();
                }
              }
            : undefined
        }
        placeholder={props.placeholder || getPlaceholderText(props.searchType)}
        value={props.searchTerm}
      />

      {props.searchButton && (
        <div className="directory-search-bar-search-button-cont">
          <Button
            className="directory-search-bar-search-button"
            {...props.searchButton}
          />
        </div>
      )}

      {/* <Select
        containerClassName={cx(
          "director-search-bar-select-cont",
          props.noSpace && "director-search-bar-select-cont-no-space"
        )}
        defaultValue={props.filterOptions[0].value}
        name="People View Filter"
        onChange={value => props.updateFilter(`${value}` as SearchFilter)}
        options={props.filterOptions}
        placeholder="Select Filter"
        value={props.filter}
      /> */}

      <div className="directory-search-bar-view-buttons-cont">
        <button
          className={cx(
            "directory-search-bar-view-button-cont",
            view === "list" && "is-selected"
          )}
          onClick={() => {
            updateView("list");
          }}
        >
          <Icon
            className="directory-search-bar-view-button"
            icon="list-ul"
            size={16}
          />
        </button>
        <button
          className={cx(
            "directory-search-bar-view-button-cont",
            view === "tree" && "is-selected"
          )}
          onClick={() => {
            updateView("tree");
          }}
        >
          <Icon
            className="directory-search-bar-view-button"
            icon="angle-down"
            size={16}
          />
        </button>
      </div>
    </section>
  );
};

const getPlaceholderText = (searchType: string) => {
  switch (searchType) {
    case "teams":
      return "Find a team";
    case "teams-and-people":
      return "Browse People and Teams";
    default:
    case "people":
      return "Find your colleague";
  }
};
