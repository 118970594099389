/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../typings/tyto";
import AgnosticModal from "../agnostic/";
import ChangeLog from "./ChangeLog";

import "./style.scss";

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className="modals-changelog-modal-main-cont"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      overlayClassName="modals-changelog-modal-overlay"
    >
      <ChangeLog onDismiss={props.onDismiss} />
    </AgnosticModal>
  );
};
