import * as _ from "lodash";

import TytoCalls from "../../../data/tyto";
import { Tyto } from "../../../typings/tyto";

export async function loadInfo({
  userID,
  onError,
  onSuccess,
}: {
  userID: number;
  onError: (errorMsg: string) => void;
  onSuccess: (info: { person: Tyto.Person; template: any }) => void;
}) {
  if (!userID) {
    onError("Necessary information not found.");
    return;
  }

  try {
    const { person } = await TytoCalls.Person.get({ personID: userID });
    const { template } = await TytoCalls.DomainInvitationEmail.Template.get({
      domainID: person.domainID,
    });

    onSuccess({
      person,
      template,
    });
  } catch (err) {
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Could not load required information.");

    onError(errorMsg);
  }
}

// export async function loadPerson({
//   userID
// }: {
//   userID: number;
// }) {
//   if (!userID) {
//     return;
//   }

//   try {
//     const personResp = await TytoCalls.Person.get({ personID: userID });

//     return personResp.person
//   } catch (err) {
//     const errorMsg =
//       typeof err === "string"
//         ? err
//         : _.get(err, "technical", "Could not load required information.");

//     return errorMsg;
//   }
// }

// export async function loadDomainTemplate({
//   domainID
// }: {
//   domainID: number;
// }) {
//   if (!domainID) {
//     onError("Necessary information not found.");
//     return;
//   }

//   try {
//     const templateResp = await TytoCalls.DomainInvitationEmail.Template.get({
//       domainID,
//     });

//     if (templateResp.template) {
//       onSuccess(templateResp.template);
//     }
//   } catch (err) {
//     const errorMsg =
//       typeof err === "string"
//         ? err
//         : _.get(err, "technical", "Could not load required information.");

//     onError(errorMsg);
//   }
// }

export async function sendTeamToolsEmailInvite({
  memberID,
  body,
  fromName,
  replyToAddress,
  replyToName,
  subject,
  onError,
  onSuccess,
}: {
  body: string;
  memberID: number;
  subject: string;
  fromName: string;
  replyToName: string;
  replyToAddress: string;
  onError: (errorMsg: string) => void;
  onSuccess: () => void;
}) {
  if (!memberID) {
    onError("Failed to Request Email.");
    return;
  }

  try {
    await TytoCalls.TeamTools.EmailLogin.post({
      memberID,
      body,
      fromName,
      replyToName,
      //   subject,
      replyToAddress,
    });

    onSuccess();
  } catch (err) {
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Failed to request Email.");

    onError(errorMsg);
  }
}

export async function sendEmailInvite({
  memberID,
  body,
  fromName,
  replyAddress,
  subject,
  onError,
  onSuccess,
}: {
  body: string;
  memberID: number;
  subject: string;
  fromName: string;
  replyAddress: string;
  onError: (errorMsg: string) => void;
  onSuccess: () => void;
}) {
  if (!memberID) {
    onError("Failed to Request Email.");
    return;
  }

  try {
    await TytoCalls.DomainInvitationEmail.post({
      memberID,
      body,
      fromName,
      subject,
      replyAddress,
    });

    onSuccess();
  } catch (err) {
    const errorMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Failed to request Email.");

    onError(errorMsg);
  }
}
