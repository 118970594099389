/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";

import { Tyto } from "../../../../typings/tyto";
import {
  StoreState as AppStoreState,
  AppStoreDispatch,
} from "../../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState,
} from "../../data/stores/AdminStore";
import { getNonDomainChildTeams, teamShouldBeHidden } from "../utils/";

import TeamTreeItem from "./TeamTreeItem";
import TeamTreeInnerList from "./TeamTreeInnerList";

import "./TeamTreeDomain.scss";
import AddTeam from "./AddTeam";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AppStoreDispatch: AppStoreDispatch;
  AppStoreState: AppStoreState;
  chooseTeam: (teamID: number) => void;
  closedAtStart?: boolean;
  curDomainID: number;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  forceSelfOnly?: boolean;
  previewPersonID?: number;
  selectedTeamID: number;
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
  teamSearchTerm?: string;
  teamSearchMatchPathIDs?: Set<number>;
  teamSearchMatchIDs?: Set<number>;
  depth: number;
}

export default (props: Props) => {
  const [showChildTeams, updateShowChildTeams] = React.useState(
    !props.closedAtStart
  );

  const { hideDescendants, hideTeam } = teamShouldBeHidden({
    searchTerm: props.teamSearchTerm,
    teamID: props.team.teamID,
    teamSearchMatchIDs: props.teamSearchMatchIDs,
    teamSearchMatchPathIDs: props.teamSearchMatchPathIDs,
  });

  if (hideTeam && hideDescendants) {
    return null;
  }

  const childTeams = getNonDomainChildTeams({
    team: props.team,
    teamsStructured: props.teamsStructured,
  });
  const childTeamsAndDescendants = getNonDomainChildTeams({
    includeBelow: true,
    team: props.team,
    teamsStructured: props.teamsStructured,
  });

  const hasChildTeams = Array.isArray(childTeams) && !!childTeams.length;

  return (
    <section
      className={cx(
        "admin-teams-team-structure-domain-cont",
        showChildTeams && "admin-teams-team-structure-domain-cont-open"
      )}
    >
      <TeamTreeItem
        AdminStoreDispatch={props.AdminStoreDispatch}
        AppStoreDispatch={props.AppStoreDispatch}
        AppStoreState={props.AppStoreState}
        childTeams={childTeams}
        chooseTeam={props.chooseTeam}
        curDomainID={props.curDomainID}
        discMiniByTeam={props.discMiniByTeam}
        forceSelfOnly={props.forceSelfOnly}
        hasChildTeams={hasChildTeams}
        previewPersonID={props.previewPersonID}
        selectedTeamID={props.selectedTeamID}
        searchTerm={props.teamSearchTerm}
        showChildTeams={!!props.teamSearchTerm || showChildTeams}
        updateShowChildTeams={() => updateShowChildTeams(!showChildTeams)}
        team={props.team}
        teamsStructured={props.teamsStructured}
        depth={0}
      />

      {!hideDescendants && (!!props.teamSearchTerm || showChildTeams) && (
        <TeamTreeInnerList
          AdminStoreDispatch={props.AdminStoreDispatch}
          AppStoreDispatch={props.AppStoreDispatch}
          AppStoreState={props.AppStoreState}
          chooseTeam={props.chooseTeam}
          childTeams={childTeams}
          curDomainID={props.curDomainID}
          discMiniByTeam={props.discMiniByTeam}
          forceSelfOnly={props.forceSelfOnly}
          previewPersonID={props.previewPersonID}
          selectedTeamID={props.selectedTeamID}
          searchTerm={props.teamSearchTerm}
          parentTeam={props.team}
          teamsStructured={props.teamsStructured}
          teamSearchTerm={props.teamSearchTerm}
          teamSearchMatchPathIDs={props.teamSearchMatchPathIDs}
          teamSearchMatchIDs={props.teamSearchMatchIDs}
          depth={0}
        />
      )}

      <AddTeam
        AdminStoreDispatch={props.AdminStoreDispatch}
        domain={props.team}
        subTree={_.sortBy(
          childTeamsAndDescendants.filter((team) => team.teamType === "ocTEAM"),
          ["name"],
          ["asc"]
        )}
      />
    </section>
  );
};
