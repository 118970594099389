/*
 * Component Description
 */
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Input, TextButton } from "../../../components/common";

interface Props {}

export default (props: Props) => {
  const [email, updateEmail] = React.useState("");
  const [password, updatePassword] = React.useState("");
  const [passwordConfirm, updatePasswordConfirm] = React.useState("");
  const [redirectToLogin, updateRedirectToLogin] = React.useState(false);

  const navigate = useNavigate();

  const mismatchingPassword = !!(
    password &&
    passwordConfirm &&
    password !== passwordConfirm
  );

  React.useEffect(() => {
    if (!redirectToLogin) return;

    navigate("/login");
  }, [redirectToLogin]);

  if (redirectToLogin) {
    return <div>Redirecting...</div>;
  }

  return (
    <section className="logon-interface">
      <div className="logon-cont">
        <div className="logon-banner-img-cont"></div>

        <h1 className="logon-title title-bold">Welcome to TeamTools</h1>

        <p className="logon-description">
          In order to take the assessment, we will need you to create a password
          for your TeamTools account.
        </p>

        <div className="logon-company-info">
          <p className="logon-company-info-name">
            Organization:{" "}
            <span className="logon-company-info-value">Company Name</span>
          </p>
          <p className="logon-company-info-team">
            Team: <span className="logon-company-info-value">Team Name</span>
          </p>
        </div>

        <Input
          autofocus={true}
          containerClassName="logon-input"
          value={email}
          onChange={(email) => updateEmail(email)}
          placeholder="Your Email"
        />

        <Input
          containerClassName="logon-input"
          value={password}
          onChange={(password) => updatePassword(password)}
          placeholder="Create a Password"
          type="password"
        />

        <Input
          containerClassName="logon-input"
          value={passwordConfirm}
          onChange={(passwordConfirm) => updatePasswordConfirm(passwordConfirm)}
          placeholder="Confirm Your Password"
          type="password"
        />

        <Button
          className="logon-button"
          disabled={
            !email || !password || !passwordConfirm || mismatchingPassword
          }
          onClick={() => {
            // TODO
          }}
          shape="square"
          type="color"
          value="Begin Assessment"
        />

        <div className="logon-teamtools-redirect-cont">
          <TextButton
            className="logon-teamtools-redirect-link"
            value="Login"
            onClick={() => updateRedirectToLogin(true)}
          />
        </div>

        <div className="logon-teamtools-img-cont">
          <img className="logon-teamtools-img" src={"./teamtools.png"} />
        </div>
      </div>
    </section>
  );
};
