/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import { Tyto } from "../../../../../typings/tyto";
import { ToggleSection } from "../../../../common/";

interface Props {
  compareProfile?: Tyto.DISCCompareProfile;
  discMini: Tyto.DISCProfileMini;
}

export default (props: Props) => {
  const [growthAreas, updateGrowthAreas] = React.useState(
    getGrowthAreas(props.compareProfile)
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    updateGrowthAreas(getGrowthAreas(props.compareProfile));
  }, [props.compareProfile]);

  return (
    <ToggleSection
      className=""
      onLinkClick={
        props.discMini
          ? () => navigate(`/profile/${props.discMini.personID}/growth-areas`)
          : undefined
      }
      title="Growth Areas"
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        return (
          <div className="directory-tabs-tab-section-growth-areas-tips-cont">
            {growthAreas.length ? (
              <>
                {growthAreas.map((growthArea) => (
                  <p
                    key={growthArea.slice(0, 30)}
                    className="directory-tabs-tab-section-growth-areas-tip"
                  >
                    {growthArea}
                  </p>
                ))}
              </>
            ) : (
              <p className="directory-tabs-tab-section-growth-areas-tip">
                Not Found.
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

const getGrowthAreas = (compareProfile?: Tyto.DISCCompareProfile): string[] => {
  if (!compareProfile) {
    return [];
  }

  const personalGrowthAreas = _.get(
    compareProfile,
    "style.personalGrowthAreas",
    ""
  );

  return personalGrowthAreas.split("\n");
};
