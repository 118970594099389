import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto";

// import { SessionData } from "../../typings";

export const Upload = {
  post(params: Endpoints.Tyto.Upload.PostParameters, callOpts?: CallOpts) {
    // if (!params.endpointURL) {
    //   return new Promise((res, rej) => {
    //     rej("Upload destination not found.");
    //   });
    // }

    const { endpointURL, ...rest } = params || {};

    return callWrapper("post-form", endpointURL || "", rest || {}, {
      ...(callOpts || {}),
    }) as Promise<Tyto.Upload>;
  },
};
