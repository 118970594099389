import * as _ from "lodash";
import { useQuery } from "@tanstack/react-query";

import { REACT_QUERY_KEYS, URL_ORIGIN } from "../../constants/";
import TytoCalls from "../../tyto/";

import { useDISCVendorForTake } from "../Vendor/ForTake";

import { DISCQuestionnaireGetParams } from "../../tyto/vendor-247/types";

export function use247DISCQuestionnaireQuery(args: DISCQuestionnaireGetParams) {
  const forTake = useDISCVendorForTake({});

  const apiKey = getApiPath(forTake?.data?.discVendor?.tryybServiceEndpoint);
  const isExpected = isExpectedURL(apiKey);

  // if (!forTake.isLoading) {
  //   debugger;
  // }

  const scopeParams = {
    apiKey,
    ...args,
  };

  return {
    ...useQuery({
      queryKey: [REACT_QUERY_KEYS.VENDOR_247_DISC_QUESTIONNAIRE, scopeParams],
      queryFn: async () => {
        const data = await TytoCalls.Vendor247.DISCQuestionnaire.get({
          ...args,
        });

        return data;
      },
      enabled: !forTake.isLoading && !!apiKey && isExpected,
    }),
    forTakeQuery: forTake,
    discVendor: forTake?.data?.discVendor,
    isExpectedURL: isExpected,
  };
}

function getApiPath(pathUnparsed?: string) {
  if (!pathUnparsed) {
    return "";
  }

  // * Should come back as something like: 'GET-/tyto/api/tryyb/GetDISCQuestionnaire'
  const pathParsed = `${pathUnparsed.split("-")[1]}`;

  return `${URL_ORIGIN}${/^\//.test(pathParsed) ? "" : "/"}${pathParsed}`;
}

function isExpectedURL(rawPathString: string) {
  return /\/tyto\/api\/r3Disc\/Vendor247\/DiscQuestionnaire/i.test(
    rawPathString
  );
}
