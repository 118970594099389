/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { Menu, MenuButton, MenuList, MenuItem } from "@reach/menu-button";

import Icon, { IconProps } from "../icon";
import { iconType } from "../icon/typings/";

import "./style.scss";

export interface MenuItemProps {
  className?: string;
  uniqueKey: string;
  onClick?: () => void;
  renderFnc?: React.FC;
  value: string;
}

interface Props {
  containerClassName?: string;
  icon?: IconProps;
  menuItems: MenuItemProps[];
}

export default ({
  containerClassName,
  icon = {
    size: 25,
    icon: "elipsis-h"
  },
  menuItems = []
}: Props) => {
  return (
    <div className={cx("cc-contextmenu-menu-wrapper", containerClassName)}>
      <Menu>
        <MenuButton className="cc-contextmenu-options-menu-button">
          <Icon
            {...icon}
            className={cx(
              "cc-contextmenu-options-menu-button-icon",
              icon.className
            )}
          />
        </MenuButton>
        <MenuList className="cc-contextmenu-options-menu-list">
          {menuItems.map(menuItem => (
              <MenuItem
                key={menuItem.uniqueKey}
                className={cx(
                  "cc-contextmenu-options-menu-list-item",
                  menuItem.className
                )}
                onSelect={() => {
                  if (menuItem.onClick) {
                    menuItem.onClick();
                  }
                }}
              >
                {menuItem.renderFnc ? <menuItem.renderFnc /> : menuItem.value}
              </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  );
};
