/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../../typings/tyto";
import {
  //   Button,
  TextButton,
  //   Timestamp,
  ToggleSection,
  //   UserThumb,
  //   Checkbox,
} from "../../../common/";
import {
  DOMAIN_MESSAGE_EMAIL_ACCESS_CHANGED_KEY,
  DOMAIN_MESSAGE_EMAIL_ASSESSMENT_REMINDER_KEY,
  DOMAIN_MESSAGE_EMAIL_INVITE_KEY,
  DOMAIN_MESSAGE_EMAIL_PASSWORD_RESET_KEY,
} from "../../../../data/constants/";
import { DomainMessage, DomainMessageEdit } from "../../../modals/";

import "./DomainEmailSettings.scss";

const emailTypes = [
  {
    title: "Invitation Email",
    summary: "Sent automatically to new users",
    messageType: DOMAIN_MESSAGE_EMAIL_INVITE_KEY,
    canChange: true,
  },
  {
    title: "Report Access Changes",
    summary: "Sent automatically when users access is changed",
    messageType: DOMAIN_MESSAGE_EMAIL_ACCESS_CHANGED_KEY,
    canChange: false,
  },
  {
    title: "Assessment Reminder",
    summary: "Sent automaticall after 7 days of pending assessment",
    messageType: DOMAIN_MESSAGE_EMAIL_ASSESSMENT_REMINDER_KEY,
    canChange: true,
  },
  {
    title: "Password Reset",
    summary: "Sent on user request",
    messageType: DOMAIN_MESSAGE_EMAIL_PASSWORD_RESET_KEY,
    canChange: false,
  },
];

interface Props {
  closedAtStart?: boolean;
  domainID: number;
  title?: string;
}

export default (props: Props) => {
  const [activeMessageType, updateActiveMessageType] = React.useState<
    string | null
  >(null);
  const [activeTitle, updateActiveTitle] = React.useState("");
  const [previewMessageType, updatePreviewMessageType] = React.useState("");

  return (
    <ToggleSection
      closedAtStart={props.closedAtStart}
      title={props.title || "Email Settings"}
      className="meta-super-sidepane-toggle-section"
      renderFunction={(isExpanded) => {
        if (!isExpanded) {
          return null;
        }

        return (
          <section className="meta-super-sidepane-domainemailsettings-main-cont">
            <ul className="meta-super-sidepane-domainemailsettings-list">
              {emailTypes.map((emailType) => (
                <li
                  key={emailType.messageType}
                  className="meta-super-sidepane-domainemailsettings-list-item"
                >
                  <h4 className="meta-super-sidepane-domainemailsettings-list-item-title">
                    {emailType.title}
                  </h4>
                  <p className="meta-super-sidepane-domainemailsettings-list-item-summary">
                    {emailType.summary}
                    {emailType.canChange && (
                      <TextButton
                        className="meta-super-sidepane-domainemailsettings-list-item-change-button"
                        onClick={() => {
                          updateActiveTitle(emailType.title);
                          updateActiveMessageType(emailType.messageType);
                        }}
                        value="Change"
                      />
                    )}
                  </p>
                  <TextButton
                    className="meta-super-sidepane-domainemailsettings-list-item-preview-button"
                    onClick={() => {
                      updateActiveTitle(emailType.title);
                      updatePreviewMessageType(emailType.messageType);
                    }}
                    value="Preview"
                  />
                </li>
              ))}
            </ul>

            <DomainMessage
              domainID={props.domainID}
              messageType={previewMessageType as string}
              isOpen={!!previewMessageType}
              onDismiss={() => {
                updatePreviewMessageType("");
                updateActiveTitle("");
              }}
              title={activeTitle}
            />

            <DomainMessageEdit
              domainID={props.domainID}
              messageType={activeMessageType as string}
              isOpen={!!activeMessageType}
              onDismiss={() => {
                updateActiveMessageType(null);
                updateActiveTitle("");
              }}
              title={activeTitle}
            />
          </section>
        );
      }}
    />
  );
};
