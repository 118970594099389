/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import AgnosticModal from "../agnostic";
import ProfileImage from "./subcomponents/ProfileImage";
import { Tyto } from "../../../typings/tyto";

import "./style.scss";

interface Props {
  className?: string;
  overlayClassName?: string;
  isOpen?: boolean;
  modalStyle?: React.CSSProperties;
  onImageUpload: () => void;
  onDismiss?: () => void;
  overlayStyle?: React.CSSProperties;
  memberID: number;
  tryybProfile?: Tyto.Person;
  uploadURL: string;
}

export default (props: Props) => {
  return (
    <AgnosticModal
      className={cx("profileimage-modal-dialog-container", props.className)}
      overlayClassName={props.overlayClassName}
      isOpen={props.isOpen}
      modalStyle={props.modalStyle}
      onDismiss={props.onDismiss}
      overlayStyle={props.overlayStyle}
    >
      <ProfileImage
        onImageUpload={props.onImageUpload}
        closePopup={props.onDismiss}
        memberID={props.memberID}
        tryybProfile={props.tryybProfile}
        uploadURL={props.uploadURL}
      />
    </AgnosticModal>
  );
};
