import * as React from "react";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

// import { StoreContext } from "../../../data/stores/contexts/AppStore";
import {
  DISCValueRender,
  DISCPlotGraph,
  ToggleSection,
} from "../../../../../components/common/";
import { getSectionWidth } from "../../../../../data/utils/helpers/";

import { Tyto } from "../../../../../typings/tyto";

import "./PersonCompareGraph.scss";

interface Interactions {
  insight?: Tyto.DISCCompareProfile.Interaction;
  presented?: Tyto.DISCCompareProfile.Interaction;
  tips?: Tyto.DISCCompareProfile.Interaction;
}

interface Props {
  // discData: Tyto.DISCInfo;
  compareProfile?: Tyto.DISCCompareProfile;
  discMini: Tyto.DISCProfileMini;
  personName: string;
  personalDiscMini?: Tyto.DISCProfileMini;
}

export default ({
  compareProfile,
  discMini,
  personName,
  personalDiscMini,
}: Props) => {
  const [perceptions, updatePerceptions] = React.useState<Interactions>(
    getInteractions(compareProfile)
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    updatePerceptions(getInteractions(compareProfile));
  }, [compareProfile]);

  return (
    <ToggleSection
      className=""
      onLinkClick={() =>
        navigate(`/profile/${discMini.personID}/you-and-other`)
      }
      title={`You & ${discMini.personName.split(" ")[0]}`}
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        if (!isExpanded) {
          return <span style={{ visibility: "hidden" }} />;
        }

        return (
          <DISCValueRender
            className="meta-sidepane-person-viewfull-renderer-wrapper"
            compareProfile={compareProfile}
            isCompareValue={false}
            targetKey="d3"
            targetKeys={["d3", "interactions"]}
            discMiniProfile={discMini}
          >
            <div
              className="meta-sidepane-person-disc-graph-graph-cont"
              style={{
                minHeight: sectionWrapperWidth
                  ? (sectionWrapperWidth * 275) / 296 + 20
                  : 250,
              }}
            >
              {sectionWrapperWidth && personalDiscMini && (
                <DISCValueRender
                  className="meta-sidepane-person-viewfull-renderer-wrapper"
                  compareProfile={compareProfile}
                  isCompareValue={false}
                  targetKey="d3"
                  discMiniProfile={discMini}
                >
                  <DISCPlotGraph
                    groupStyle={[
                      {
                        d: discMini.d3,
                        i: discMini.i3,
                        s: discMini.s3,
                        c: discMini.c3,
                        personName: discMini.personName,
                      },
                      {
                        d: personalDiscMini.d3,
                        i: personalDiscMini.i3,
                        s: personalDiscMini.s3,
                        c: personalDiscMini.c3,
                        personName: personalDiscMini.personName,
                      },
                    ]}
                    size={sectionWrapperWidth}
                  />
                </DISCValueRender>
              )}
            </div>

            <DISCValueRender
              className="meta-sidepane-person-viewfull-renderer-wrapper"
              compareProfile={compareProfile}
              isCompareValue={true}
              targetKey="interactions"
              // targetKeys={[
              //   "interactions"
              // ]}
              discMiniProfile={discMini}
            >
              {perceptions.insight && (
                <>
                  <h4 className="meta-sidepane-person-perception-text-title">
                    How You See {personName}
                  </h4>

                  <p className="meta-sidepane-person-perception-text">
                    <DISCValueRender
                      className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
                      compareProfile={compareProfile}
                      isCompareValue={true}
                      targetKey="interactions"
                      discMiniProfile={discMini}
                    >
                      {perceptions.insight.interactText}
                    </DISCValueRender>
                  </p>
                </>
              )}

              {perceptions.presented && (
                <>
                  <h4 className="meta-sidepane-person-perception-text-title">
                    How {personName} Sees you
                  </h4>

                  <p className="meta-sidepane-person-perception-text">
                    <DISCValueRender
                      className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
                      compareProfile={compareProfile}
                      isCompareValue={true}
                      targetKey="interactions"
                      discMiniProfile={discMini}
                    >
                      {perceptions.presented.interactText}
                    </DISCValueRender>
                  </p>
                </>
              )}

              {perceptions.tips && (
                <>
                  <h4 className="meta-sidepane-person-perception-text-title">
                    Tips for working together
                  </h4>

                  <p className="meta-sidepane-person-perception-text">
                    <DISCValueRender
                      className="meta-sidepane-person-viewfull-renderer-nopadding-wrapper"
                      compareProfile={compareProfile}
                      isCompareValue={true}
                      targetKey="interactions"
                      discMiniProfile={discMini}
                    >
                      {perceptions.tips.interactText}
                    </DISCValueRender>
                  </p>
                </>
              )}
            </DISCValueRender>
          </DISCValueRender>
        );
      }}
    />
  );
};

const getInteractions = (
  discCompareProfile?: Tyto.DISCCompareProfile
): Interactions => {
  if (!discCompareProfile || !discCompareProfile.interactions) {
    return {};
  }

  const interactions = _.keyBy(discCompareProfile.interactions, (interaction) =>
    interaction.interactContext.toLowerCase()
  );

  return interactions as {
    insight: Tyto.DISCCompareProfile.Interaction;
    presented: Tyto.DISCCompareProfile.Interaction;
    tips: Tyto.DISCCompareProfile.Interaction;
  };
};
