/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import {
  Checkbox,
  Icon,
  UserThumbPlaceholder
} from "../../../components/common";

interface Props {
  noAnimation?: boolean;
  showCheckbox?: boolean;
}

export default ({ noAnimation, showCheckbox }: Props) => {
  const [hasEmail] = React.useState(() => Math.random() * 2 > 1);
  const [hasPhone] = React.useState(() => Math.random() * 2 > 1);
  const [fillerName] = React.useState(() => {
    const fill = "Fill ";

    return fill.repeat(Math.round(Math.random() * 4) + 3);
  });
  const [fillerJobTitle] = React.useState(() => {
    const fill = "Fill ";

    return fill.repeat(Math.round(Math.random() * 5) + 2);
  });
  const [animationDuration1] = React.useState(() =>
    Math.round(1800 + Math.random() * 1200)
  );
  const [animationDuration2] = React.useState(() =>
    Math.round(1800 + Math.random() * 1200)
  );

  return (
    <li
      className={cx(
        "directory-people-list-item",
        !!noAnimation && "directory-people-list-item-placeholder-noanimation"
      )}
    >
      <div
        className={cx(
          "directory-people-list-item-button-cont",
          showCheckbox && "directory-people-list-item-button-show-button"
        )}
      >
        <Checkbox checked={false} onCheck={() => {}} size={20} />
      </div>

      <button
        className={cx(
          "directory-people-list-item-info-cont",
          showCheckbox &&
            "directory-people-list-item-info-cont-checkbox-showing"
        )}
        onClick={() => {}}
        tabIndex={showCheckbox ? -1 : undefined}
      >
        <div className="directory-people-list-item-info-userthumb-cont">
          <UserThumbPlaceholder
            className="directory-people-list-item-info-userthumb"
            size={43}
          />
        </div>

        <div className="directory-people-list-item-info-name-cont">
          <p className="directory-people-list-item-info-name title-bold">
            <span
              className="is-dummy-placeholder-text"
              style={{ animationDuration: `${animationDuration1}ms` }}
            >
              {fillerName}
            </span>
          </p>
          <p className="directory-people-list-item-info-jobtitle">
            <span
              className="is-dummy-placeholder-text"
              style={{ animationDuration: `${animationDuration2}ms` }}
            >
              {fillerJobTitle}
            </span>
          </p>
        </div>

        <div className="directory-people-list-item-info-contact-cont">
          <p
            className="directory-people-list-item-info-contact-email"
            style={!hasEmail ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="directory-people-list-item-info-contact-icon"
              icon="envelope"
              size={12}
            />
            <span className="is-dummy-placeholder-text">Filler Fake Email</span>
          </p>
          <p
            className="directory-people-list-item-info-contact-phone"
            style={!hasPhone ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="directory-people-list-item-info-contact-icon"
              icon="phone"
              size={12}
            />
            <span className="is-dummy-placeholder-text">Filler Phone</span>
          </p>
        </div>

        <div
          className="directory-people-list-item-info-style-cont"
          style={{ backgroundColor: "#fff" }}
        >
          {/* <Icon
            className="directory-people-list-item-info-style-icon"
            hasDropShadow={true}
            icon={iconType}
            gradient={iconGradient}
            size={49}
          />
          <p className="directory-people-list-item-info-style-name">
            {user.styleName3}
            <br />
            <span
              style={
                iconGradient && iconGradient.length >= 1
                  ? {
                      color: (iconGradient[1] || iconGradient[0]).color,
                      fontWeight: "bold"
                    }
                  : { fontWeight: "bold" }
              }
            >
              ({user.styleKey3})
            </span>
          </p> */}
        </div>
      </button>
    </li>
  );
};
