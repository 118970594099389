/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import Icon from "../icon/";
import Squares from "./subcomponents/squares";

import "./style.scss";

interface Props {
  className?: string;
  omitTitle?: boolean;
  size?: number;
  type?: "crane" | "spinner" | "squares";
}

export default (props: Props) => {
  const type = props.type || "squares";

  if (type === "squares") {
    return (
      <Squares
        omitTitle={props.omitTitle}
        className={props.className}
        size={props.size || 20}
      />
    );
  }

  return (
    <Icon
      className={cx("cc-loading", props.className)}
      icon="spinner"
      size={props.size || 20}
    />
  );
};
