/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import {
  DISCValueRender,
  Icon,
  UserThumb,
  UserThumbPlaceholder,
  TextButton,
} from "../../../components/common/";
import {
  getIconTypeFromDISCType,
  calcGradientForIcon,
  isValidIcon,
} from "../../../data/utils/helpers/";
import { Tyto } from "../../../typings/tyto";
import { StoreContext } from "../../../data/stores/GeneralStore";
import { AppStoreProps } from "../../../data/stores/AppStore";
import { ProfileImage } from "../../../components/modals/";
import { loadTryybProfile } from "../utils/";

import "./PersonHeader.scss";

interface Props {
  AppStore: AppStoreProps;
  discCompareProfile?: Tyto.DISCCompareProfile;
  discMiniProfile?: Tyto.DISCProfileMini;
  isOwnProfile: boolean;
  tryybProfile?: Tyto.Person;
  userID?: string;
  uploadURL?: string;
}

export default ({
  AppStore,
  discCompareProfile,
  discMiniProfile,
  isOwnProfile,
  tryybProfile,
  userID,
  uploadURL,
}: Props) => {
  let GeneralStore = React.useContext(StoreContext);
  const [showProfileImageModal, updateShowProfileImageModal] = React.useState(
    false
  );
  const [reloadingProfile, updateReloadingProfile] = React.useState(false);
  const [reloadError, updateReloadError] = React.useState("");

  const isMobile = _.get(GeneralStore, "state.isMobile", false);

  const personName = discMiniProfile
    ? discMiniProfile.personName
    : (tryybProfile &&
        (tryybProfile.givenName || tryybProfile.familyName) &&
        `${tryybProfile.givenName} ${tryybProfile.familyName}`) ||
      "";
  const jobTitle = discMiniProfile
    ? discMiniProfile.jobTitle
    : (tryybProfile && tryybProfile.jobTitle) || "";
  const domainName = _.get(discMiniProfile, "domainName", "");
  const phone1 = discMiniProfile
    ? discMiniProfile.phone1
    : (tryybProfile && tryybProfile.phone1) || "";
  const email = discMiniProfile
    ? discMiniProfile.emails[0]
    : (tryybProfile && tryybProfile.email) || "";
  const asset: Tyto.Asset | undefined =
    _.get(discMiniProfile, "profileImageAsset", undefined) ||
    _.get(tryybProfile, "profileImageAssets[0]", undefined);

  return (
    <section className="directory-profile-profile-header">
      <div className="directory-profile-profile-header-left-cont">
        {reloadingProfile ? (
          <UserThumbPlaceholder
            className="directory-profile-profile-header-userthumb"
            size={isMobile ? 102 : 135}
          />
        ) : (
          <UserThumb
            className="directory-profile-profile-header-userthumb"
            asset={asset}
            discMiniProfile={discMiniProfile}
            discValues={
              discMiniProfile
                ? {
                    d: discMiniProfile.d3,
                    i: discMiniProfile.i3,
                    s: discMiniProfile.s3,
                    c: discMiniProfile.c3,
                  }
                : {
                    d: 0,
                    i: 0,
                    s: 0,
                    c: 0,
                  }
            }
            userName={personName}
            size={isMobile ? 102 : 135}
          />
        )}

        {isOwnProfile && (
          <TextButton
            className="directory-profile-profile-header-change-image-button"
            onClick={() => updateShowProfileImageModal(true)}
            value="Change Profile Image"
          />
        )}

        <div className="directory-profile-profile-header-left-info-cont">
          <h1 className="directory-profile-profile-header-username">
            {personName}
          </h1>
          <p className="directory-profile-profile-header-jobtitle">
            {jobTitle}
          </p>
          {domainName && (
            <p className="directory-profile-profile-header-domain-name">
              {domainName}
            </p>
          )}
          {phone1 && (
            <p className="directory-profile-profile-header-phone">
              <a
                className="directory-profile-profile-header-contact-icon-wrapper"
                href={`tel:${phone1}`}
              >
                <Icon
                  className="directory-profile-profile-header-contact-icon"
                  icon="phone"
                  size={12}
                />
              </a>
              {phone1}
            </p>
          )}
          {email && (
            <p
              className={cx(
                "directory-profile-profile-header-email",
                !phone1 && "with-padding-top"
              )}
            >
              <a
                className="directory-profile-profile-header-contact-icon-wrapper"
                href={`mailto:${email}`}
              >
                <Icon
                  className="directory-profile-profile-header-contact-icon"
                  icon="envelope"
                  // isButton={true}
                  // onClick={() => {}}
                  size={12}
                />
              </a>
              {email}
            </p>
          )}
        </div>
      </div>

      {discMiniProfile && (
        <div className="directory-profile-profile-header-right-cont">
          <DISCValueRender
            className="directory-profile-profile-header-wrapper"
            iconSize={56}
            isCompareValue={true}
            compareProfile={discCompareProfile}
            omitHiddenMsg={true}
            targetKey="style.graphic"
            discMiniProfile={discMiniProfile}
          >
            <Icon
              className="directory-profile-profile-header-style-icon"
              icon={isValidIcon(discMiniProfile.graphic)}
              gradient={calcGradientForIcon({
                d: discMiniProfile.d3,
                i: discMiniProfile.i3,
                s: discMiniProfile.s3,
                c: discMiniProfile.c3,
              })}
              size={isMobile ? 120 : 100}
            />
          </DISCValueRender>

          <div className="directory-profile-profile-header-right-info-cont">
            <p className="directory-profile-profile-header-style-label">
              Personality
            </p>
            <p className="directory-profile-profile-header-style-name">
              <DISCValueRender
                className="directory-profile-profile-header-wrapper"
                isCompareValue={true}
                compareProfile={discCompareProfile}
                omitHiddenMsg={true}
                targetKey="style.styleName"
                discMiniProfile={discMiniProfile}
              >
                {discMiniProfile.styleName3}
              </DISCValueRender>
            </p>
          </div>
        </div>
      )}

      <ProfileImage
        memberID={userID ? parseInt(userID) : 0}
        isOpen={showProfileImageModal}
        onDismiss={() => updateShowProfileImageModal(false)}
        onImageUpload={() => {
          updateReloadingProfile(true);

          loadTryybProfile({
            AppStore,
            callback: () => {
              updateReloadingProfile(false);
            },
            userID: userID ? parseInt(userID) : 0,
          });
        }}
        tryybProfile={tryybProfile}
        uploadURL={uploadURL || ""}
      />
    </section>
  );
};
