/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Button, Input, Message } from "../../../common/";
import { sendTeamToolsEmailInvite } from "../utils";
import { Tyto } from "../../../../typings/tyto";

import "./EmailForm.scss";

interface Props {
  onDismiss: () => void;
  personalDiscMini?: Tyto.DISCProfileMini;
  template?: Tyto.DomainInvitation.Template;
  userID: number;
}

export default (props: Props) => {
  const [body, updateBody] = React.useState(
    `${_.get(props, "template.inviteMessage", "")}`
  );
  const [replyAddress, updateReplyAddress] = React.useState(
    _.get(
      props,
      "template.contactEmail",
      _.get(props, "personalDiscMini.emails[0]", "")
    )
  );
  const [replyName, updateReplyName] = React.useState(
    _.get(
      props,
      "template.contactName",
      _.get(props, "personalDiscMini.personName", "")
    )
  );
  const [subject, updateSubject] = React.useState("Account Recovery");
  const [fromName, updateFromName] = React.useState(
    _.get(props, "personalDiscMini.personName", "")
  );
  const [requesting, updateRequesting] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [requested, updateRequested] = React.useState(false);

  return (
    <section className="modals-userrecovery-modal-form">
      <div className="modals-userrecovery-modal-form-section">
        <p className="modals-userrecovery-modal-form-section-label">
          Reply Address
        </p>

        <Input
          autofocus={true}
          containerClassName="modals-userrecovery-modal-form-input-cont"
          disabled={requested}
          onChange={(newVal) => updateReplyAddress(newVal)}
          placeholder="Reply Address"
          value={replyAddress}
        />
      </div>

      <div className="modals-userrecovery-modal-form-section">
        <p className="modals-userrecovery-modal-form-section-label">
          Reply Name
        </p>

        <Input
          containerClassName="modals-userrecovery-modal-form-input-cont"
          disabled={requested}
          onChange={(newVal) => updateReplyName(newVal)}
          placeholder="Reply To Name"
          value={replyName}
        />
      </div>

      <div className="modals-userrecovery-modal-form-section">
        <p className="modals-userrecovery-modal-form-section-label">
          From Name
        </p>

        <Input
          containerClassName="modals-userrecovery-modal-form-input-cont"
          disabled={requested}
          onChange={(newVal) => updateFromName(newVal)}
          placeholder="From Name"
          value={fromName}
        />
      </div>

      {/* <div className="modals-userrecovery-modal-form-section">
        <p className="modals-userrecovery-modal-form-section-label">Subject</p>

        <Input
          containerClassName="modals-userrecovery-modal-form-input-cont"
          disabled={requested}
          onChange={(newVal) => updateSubject(newVal)}
          placeholder="Subject"
          value={subject}
        />
      </div> */}

      <div className="modals-userrecovery-modal-form-section">
        <p className="modals-userrecovery-modal-form-section-label">
          Email Body
        </p>

        <textarea
          className="modals-userrecovery-modal-form-textarea"
          disabled={requested}
          onChange={(e) => updateBody((e.target as HTMLTextAreaElement).value)}
          placeholder="Body"
          value={body}
        />
      </div>

      {error && (
        <p className="modals-userrecovery-modal-form-error-msg">{error}</p>
      )}

      <div className="modals-userrecovery-modal-form-section buttons-cont">
        <Button
          className="modals-userrecovery-modal-form-button"
          disabled={requesting}
          onClick={props.onDismiss}
          shape="square"
          type="hollow"
          value="Close"
        />

        <Button
          className="modals-userrecovery-modal-form-button"
          disabled={requesting || !props.userID || requested}
          onClick={() => {
            updateRequesting(true);
            updateError("");

            startEmailRequest({
              body,
              subject,
              replyAddress,
              replyName,
              fromName,
              memberID: props.userID,
              onError: (errMsg) => {
                updateError(errMsg);
                updateRequesting(false);
              },
              onSuccess: () => {
                updateRequested(true);
                updateRequesting(false);
              },
            });
          }}
          iconLeft={requested ? "check" : undefined}
          iconProps={{
            className: "modals-userrecovery-modal-form-button-icon",
            size: 13,
          }}
          shape="square"
          type="color"
          value={getButtonText({ requested, requesting })}
        />
      </div>
    </section>
  );
};

const getButtonText = ({
  requesting,
  requested,
}: {
  requesting: boolean;
  requested: boolean;
}) => {
  if (requested) {
    return "Email Requested";
  }

  return requesting ? "Requesting..." : "Send Email";
};

const startEmailRequest = async ({
  body,
  subject,
  replyAddress,
  fromName,
  replyName,
  memberID,
  onError,
  onSuccess,
}: {
  body: string;
  subject: string;
  replyAddress: string;
  replyName: string;
  fromName: string;
  memberID: number;
  onError: (errMsg: string) => void;
  onSuccess: () => void;
}) => {
  try {
    await sendTeamToolsEmailInvite({
      body,
      subject,
      replyToAddress: replyAddress,
      replyToName: replyName,
      fromName,
      memberID,
      onError,
      onSuccess,
    });
  } catch (err) {
    const errMsg =
      typeof err === "string"
        ? err
        : _.get(err, "technical", "Error occurred, could not request email.");

    onError(errMsg);
  }
};
