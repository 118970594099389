/*
 * Displays simple badge to indicate a user is online
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  terse?: boolean;
  verboseOnHover?: boolean;
}

export default (props: Props) => {
  return (
    <span
      className={cx(
        "cc-currently-online-badge",
        props.className,
        props.terse && "hide-text",
        props.verboseOnHover && "show-text-on-hover"
      )}
    >
      <span className="cc-currently-online-badge-text">Currently Online</span>
    </span>
  );
};
