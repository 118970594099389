/*
  * Component Description
*/
import * as React from "react";
import cx from "classnames";

import { Button } from "../../../../components/common";

interface Props {
    curTab: string;
    tabs: {
        label: string;
        value: string;
    }[];
    updateSelectedTab: (value: string) => void;
}

export default (props: Props) => {
    return (
        <div className="directory-tabs-interface-tabs-cont">
            {props.tabs.map(tab => (
                <Button
                    className={cx("directory-tabs-interface-tab", props.curTab === tab.value && "directory-tabs-interface-tab-active-tab")}
                    key={tab.value}
                    value={tab.label}
                    onClick={() => props.updateSelectedTab(tab.value)}
                />
            ))}
        </div>
    );
}