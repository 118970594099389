import * as React from "react";

import { IconProps } from "../typings";

export default ({ className, color, gradient, size }: IconProps) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            fill={color}
        >
            {Array.isArray(gradient) && (
                <defs>
                    <linearGradient id="FilterGradient1" x1="0" x2="1" y1="0" y2="1">
                        {gradient.map(grad => (
                            <stop offset={`${grad.offsetPercent}%`} stopColor={grad.color} />
                        ))}
                    </linearGradient>
                </defs>
            )}
            <path
                fill={Array.isArray(gradient) ? "url(#FilterGradient1)" : ""}
                d="M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z" />
        </svg>
    );
};
