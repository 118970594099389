import { ENDPOINT_TEAM_INFO } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_TEAM_INFO;

export const TeamsByFunction = {
  get(
    params: Endpoints.Tyto.TeamsByFunction.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params, callOpts) as Promise<{
      teams: Tyto.Team[];
      session: SessionData;
    }>;
  }
};
