/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { StoreAction } from "../../typings/";
import { MOBILE_WIDTH_BREAKPOINT } from "../constants/";
import { Tyto } from "../../typings/tyto";

function isMobileSize(): boolean {
  return window && window.innerWidth
    ? window.innerWidth <= MOBILE_WIDTH_BREAKPOINT
    : false;
}

export interface HistoryItem {
  url: string;
  uri: string;
}

export type GeneralStoreDispatch = React.Dispatch<
  StoreAction<GeneralStoreActionType>
>;

export interface GeneralStoreProps {
  state?: GeneralStoreState;
  dispatch?: GeneralStoreDispatch;
}

export interface GeneralStoreState {
  configurationClient?: Tyto.Configuration.Client;
  history: HistoryItem[];
  isMobile: boolean;
  showMenu: boolean;
  uploadURL?: string;
}

type GeneralStoreActionType =
  | "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED"
  | "GENERAL_STORE_NAVIGATE_BACK"
  | "GENERAL_STORE_UPDATE_HISTORY"
  | "GENERAL_STORE_UPDATE_SIZE"
  | "GENERAL_STORE_TOGGLE_NAVMENU";

let StoreContext: React.Context<GeneralStoreProps> = React.createContext({});

let initialState: GeneralStoreState = {
  history: [],
  isMobile: isMobileSize(),
  showMenu: false,
};

let reducer = (
  state: GeneralStoreState,
  action: StoreAction<GeneralStoreActionType>
) => {
  console.log(action);

  switch (action.type) {
    case "GENERAL_STORE_CONFIGURATION_CLIENT_LOADED":
      return {
        ...state,
        configurationClient: action.payload.configurationClient,
        uploadURL: _.get(
          action.payload,
          "configurationClient.uploadServices[0]",
          undefined
        ),
      };
    case "GENERAL_STORE_NAVIGATE_BACK":
      if (state.history.length) {
        const [lastRoute, ...previousHistory] = state.history;

        window.history.back();
        // navigate(lastRoute.uri);

        return {
          ...state,
          history: previousHistory,
        };
      }

      return state;
    case "GENERAL_STORE_UPDATE_HISTORY":
      if (action.payload.historyItem) {
        return {
          ...state,
          history: [action.payload.historyItem, ...state.history],
        };
      }

      return state;
    case "GENERAL_STORE_UPDATE_SIZE":
      if (action.payload) {
        return {
          ...state,
          isMobile: action.payload.isMobile,
        };
      }

      return state;
    case "GENERAL_STORE_TOGGLE_NAVMENU":
      return {
        ...state,
        showMenu:
          action.payload.showMenu !== undefined
            ? !!action.payload.showMenu
            : !state.showMenu,
      };
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
