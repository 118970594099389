/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Tyto } from "../../../../../typings/tyto";
import {
  Button,
  Checkbox,
  Message,
  TextButton,
} from "../../../../../components/common/";
import {
  StoreContext,
  AppStoreProps,
} from "../../../../../data/stores/AppStore";
import TytoCalls from "../../../../../data/tyto/";
import WebSocketClient from "../../../../../data/websocket/";
import { DISC_OPT_OUT_OPTIONS } from "../../../../../data/constants/";

import {
  AccountItem as AccountItemType,
  accountItems,
  updatePerson,
} from "../../utils/";
import AccountItem from "./AccountItem";

import "./Account.scss";

interface Props {
  discMiniProfile?: Tyto.DISCProfileMini;
  tryybProfile?: Tyto.Person;
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const [isOwnProfile, updateIsOwnProfile] = React.useState(
    detectIfIsOwnProfile({ AppStore, tryybProfile: props.tryybProfile })
  );
  const [editing, updateEditing] = React.useState(isOwnProfile);
  const [canEditProfile, updateCanEditProfile] = React.useState(
    hasEditPerson(props.tryybProfile)
  );
  const [localAccountItems, updateLocalAccountItems] = React.useState(
    getLocalAccountItems(props.tryybProfile)
  );
  const [localDiscOptOut, updateLocalDiscOptOut] = React.useState<
    keyof typeof Tyto.DiscOptOut | undefined
  >(_.get(props, "discMiniProfile.discOptOut"));
  const [savingDiscOptOut, updateSavingDiscOptOut] = React.useState(false);
  const [saving, updateSaving] = React.useState(false);
  const [discOptOutError, updateDiscOptOutError] = React.useState("");

  React.useEffect(() => {
    if (props.tryybProfile) {
      updateLocalAccountItems(getLocalAccountItems(props.tryybProfile));

      updateIsOwnProfile(
        detectIfIsOwnProfile({ AppStore, tryybProfile: props.tryybProfile })
      );

      if (!isOwnProfile && editing) {
        updateEditing(false);
      }
    }

    updateCanEditProfile(hasEditPerson(props.tryybProfile));
  }, [props.tryybProfile]);

  React.useEffect(() => {
    if (
      localDiscOptOut &&
      localDiscOptOut !== _.get(props, "discMiniProfile.discOptOut", "")
    ) {
      updateSavingDiscOptOut(true);

      if (!localDiscOptOut) {
        return;
      }

      saveDiscOptOutChange({
        AppStore: AppStore,
        discOptOut: localDiscOptOut,
        personID: _.get(props.discMiniProfile, "personID"),
        onError: (errMsg: string) => {
          updateDiscOptOutError(`${errMsg}`);
          updateSavingDiscOptOut(false);
        },
        onSuccess: () => {
          updateSavingDiscOptOut(false);
        },
      });
    }
  }, [localDiscOptOut]);

  if (props.tryybProfile === undefined) {
    return <Message value="Loading..." />;
  } else if (props.tryybProfile === null) {
    return <Message value="No Profile Found." />;
  }

  const changeExists = hasChange(props.tryybProfile || {}, localAccountItems);
  const canEdit = isOwnProfile || canEditProfile;

  return (
    <>
      {isOwnProfile && props.discMiniProfile && (
        <div
          className={cx(
            "directory-tabs-tab-section-account-section-optout-cont",
            savingDiscOptOut && "account-section-optout-cont-disabled"
          )}
        >
          <h3 className="directory-tabs-tab-section-account-section-optout-title">
            Sharing
            {/* {props.discMiniProfile &&
              props.discMiniProfile.discOptOut === "ocVACANT" && (
                <span className="directory-tabs-tab-section-account-section-optout-title-unsetmsg">
                  (Currently Unset)
                </span>
              )} */}
          </h3>
          <h3 className="directory-tabs-tab-section-account-section-optout-desc">
            How much do you want to share about yourself to other people inside
            the organization?
          </h3>

          <ul className="directory-tabs-tab-section-account-section-optout-list">
            <li className="directory-tabs-tab-section-account-section-optout-list-item">
              <div className="directory-tabs-tab-section-account-section-optout-list-item-button">
                <Checkbox
                  className="directory-tabs-tab-section-account-section-optout-list-item-checkbox"
                  checked={
                    localDiscOptOut === "ocLEVEL00" ||
                    localDiscOptOut === "ocVACANT"
                  }
                  disabled={savingDiscOptOut}
                  onCheck={() => {
                    if (localDiscOptOut !== "ocLEVEL00") {
                      updateLocalDiscOptOut("ocLEVEL00");
                    }
                  }}
                  size={14}
                  type="circle"
                />

                <TextButton
                  className="directory-tabs-tab-section-account-section-optout-list-item-textbutton"
                  disabled={savingDiscOptOut}
                  onClick={() => updateLocalDiscOptOut("ocLEVEL00")}
                  title={
                    localDiscOptOut === "ocVACANT"
                      ? "Currently no Opt-out value is set (Vacant) which defaults to Everything"
                      : undefined
                  }
                  value={DISC_OPT_OUT_OPTIONS.ocLEVEL00}
                />
              </div>

              <p className="directory-tabs-tab-section-account-section-optout-list-item-desc">
                Share everything, including the full download of your report.
              </p>
            </li>

            <li className="directory-tabs-tab-section-account-section-optout-list-item">
              <div className="directory-tabs-tab-section-account-section-optout-list-item-button">
                <Checkbox
                  className="directory-tabs-tab-section-account-section-optout-list-item-checkbox"
                  checked={localDiscOptOut === "ocLEVEL10"}
                  disabled={savingDiscOptOut}
                  onCheck={() => {
                    if (localDiscOptOut !== "ocLEVEL10") {
                      updateLocalDiscOptOut("ocLEVEL10");
                    }
                  }}
                  size={14}
                  type="circle"
                />

                <TextButton
                  className="directory-tabs-tab-section-account-section-optout-list-item-textbutton"
                  disabled={savingDiscOptOut}
                  onClick={() => updateLocalDiscOptOut("ocLEVEL10")}
                  value={DISC_OPT_OUT_OPTIONS.ocLEVEL10}
                />
              </div>

              <p className="directory-tabs-tab-section-account-section-optout-list-item-desc">
                Communication tips, comparison reports, style intensity,
                personality overview, and positive traits (value to a team,
                analysis style, points of caution.
              </p>
            </li>

            <li className="directory-tabs-tab-section-account-section-optout-list-item">
              <div className="directory-tabs-tab-section-account-section-optout-list-item-button">
                <Checkbox
                  className="directory-tabs-tab-section-account-section-optout-list-item-checkbox"
                  checked={localDiscOptOut === "ocLEVEL20"}
                  disabled={savingDiscOptOut}
                  onCheck={() => {
                    if (localDiscOptOut !== "ocLEVEL20") {
                      updateLocalDiscOptOut("ocLEVEL20");
                    }
                  }}
                  size={14}
                  type="circle"
                />

                <TextButton
                  className="directory-tabs-tab-section-account-section-optout-list-item-textbutton"
                  disabled={savingDiscOptOut}
                  onClick={() => updateLocalDiscOptOut("ocLEVEL20")}
                  value={DISC_OPT_OUT_OPTIONS.ocLEVEL20}
                />
              </div>

              <p className="directory-tabs-tab-section-account-section-optout-list-item-desc">
                Only share communication tips and the comparison report.
              </p>
            </li>
          </ul>

          {discOptOutError && (
            <p className="directory-tabs-tab-section-account-section-optout-error-msg">
              {discOptOutError}
            </p>
          )}
        </div>
      )}

      <div className="directory-tabs-tab-section-account-section-cont">
        {accountItems &&
          accountItems.length &&
          accountItems.map(({ domainLabelKey, label, key, width }) => (
            <AccountItem
              canEdit={editing && canEditItem(props.tryybProfile || null, key)}
              canView={canViewItem(props.tryybProfile || null, key)}
              key={key}
              labelKey={key}
              // @ts-ignore
              value={localAccountItems[key] ? `${localAccountItems[key]}` : ""}
              hasLocalChange={
                // @ts-ignore
                localAccountItems[key] !== props.tryybProfile[key]
              }
              onChange={(newValue: string) => {
                updateLocalAccountItems({
                  ...localAccountItems,
                  [key]: newValue,
                });
              }}
              reset={() => {
                updateLocalAccountItems({
                  ...localAccountItems,
                  [key]: `${_.get(props.tryybProfile, key, "")}`,
                });
              }}
              saving={saving}
              title={
                (domainLabelKey &&
                  _.get(
                    props.tryybProfile,
                    `domain[${domainLabelKey}]`,
                    undefined
                  )) ||
                label
              }
              widthOverride={width}
            />
          ))}

        {canEdit && (
          <div className="directory-tabs-tab-section-account-section-buttons-cont">
            {!editing ? (
              <Button
                className="directory-tabs-tab-section-account-section-buttons-button"
                disabled={saving}
                onClick={() => updateEditing(true)}
                type="hollow"
                shape="square"
                value="Edit"
              />
            ) : (
              <>
                <Button
                  className="directory-tabs-tab-section-account-section-buttons-button"
                  disabled={saving || (!changeExists && isOwnProfile)}
                  onClick={() => {
                    updateLocalAccountItems(
                      getLocalAccountItems(props.tryybProfile)
                    );

                    if (!isOwnProfile) {
                      updateEditing(false);
                    }
                  }}
                  type="hollow"
                  shape="square"
                  value={isOwnProfile ? "Reset" : "Cancel"}
                />

                <Button
                  className="directory-tabs-tab-section-account-section-buttons-button"
                  disabled={saving || !changeExists}
                  onClick={() => {
                    const changes = findChanges(
                      props.tryybProfile || {},
                      localAccountItems
                    );

                    if (!Object.keys(changes).length) {
                      return;
                    }

                    updateSaving(true);

                    updatePerson({
                      AppStore,
                      changes,
                      userID: _.get(props.tryybProfile, "personID", 0),
                      callback: () => updateSaving(false),
                    });
                  }}
                  type="color"
                  shape="square"
                  value={saving ? "Saving..." : "Save"}
                />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const canEditItem = (
  tryybProfile: Tyto.Person | null,
  key: keyof Tyto.Person
): boolean => {
  const permissions = _.get(tryybProfile, "permissions", undefined);

  if (!Array.isArray(permissions)) {
    debugger;
    return false;
  }

  const permissionInQuestion = (permissions as Tyto.Person["permissions"]).find(
    (permission) => (permission as any).fieldName === key
  );

  return permissionInQuestion
    ? (permissionInQuestion as any).changeAccess
    : false;
};

const canViewItem = (
  tryybProfile: Tyto.Person | null,
  key: keyof Tyto.Person
): boolean => {
  const permissions = _.get(tryybProfile, "permissions", undefined);

  if (!Array.isArray(permissions)) {
    debugger;
    return false;
  }

  const permissionInQuestion = (permissions as Tyto.Person["permissions"]).find(
    (permission) => (permission as any).fieldName === key
  );

  return permissionInQuestion
    ? (permissionInQuestion as any).viewAccess
    : false;
};

const getLocalAccountItems = (tryybProfile?: Tyto.Person) => {
  if (!tryybProfile) {
    return [];
  }

  const accountItemKeys = accountItems.map((ai) => ai.key);

  return _.pick(tryybProfile, accountItemKeys);
};

const hasChange = (
  profile: { [x: string]: any },
  localCopy: { [x: string]: any }
) => {
  return accountItems.some(
    (accountItem) => profile[accountItem.key] !== localCopy[accountItem.key]
  );
};

const hasEditPerson = (tryybProfile?: Tyto.Person | null): boolean => {
  const permissions = _.get(tryybProfile, "permissions", undefined);

  if (!Array.isArray(permissions)) {
    debugger;
    return false;
  }

  const permsMap: _.Dictionary<Tyto.Permission> = _.keyBy(
    permissions,
    "fieldName"
  );

  const canChangeSomething = accountItems.some((item) => {
    return permsMap[item.key] && permsMap[item.key].changeAccess;
  });

  return canChangeSomething;

  // const permissionInQuestion = (permissions as Tyto.Person["permissions"]).find(
  //   (permission) => (permission as any).functionName === "Block Enroll"
  // );

  // return permissionInQuestion
  //   ? (permissionInQuestion as any).changeAccess
  //   : false;
};

const detectIfIsOwnProfile = ({
  AppStore,
  tryybProfile,
}: {
  AppStore: AppStoreProps;
  tryybProfile?: Tyto.Person;
}) => {
  const ownUserID = _.get(AppStore, "state.loggedInUserID", undefined);
  const focusedPersonID = _.get(tryybProfile, "personID", undefined);

  if (ownUserID === undefined || focusedPersonID === undefined) {
    return false;
  }

  return ownUserID === focusedPersonID;
};

const findChanges = (
  profile: { [x: string]: any },
  localCopy: { [x: string]: any }
) => {
  const changes = accountItems.reduce(
    (accum: { [x: string]: any }, accountItem) => {
      if (
        profile[accountItem.key] !== undefined &&
        localCopy[accountItem.key] !== undefined &&
        profile[accountItem.key] !== localCopy[accountItem.key]
      ) {
        accum[accountItem.key] = localCopy[accountItem.key];
      }

      return accum;
    },
    {}
  );

  return changes;
};

const saveDiscOptOutChange = async ({
  AppStore,
  discOptOut,
  personID,
  onError,
  onSuccess,
}: {
  AppStore: AppStoreProps;
  discOptOut: keyof typeof Tyto.DiscOptOut;
  personID?: number;
  onError: (errMsg: string) => void;
  onSuccess: () => void;
}) => {
  if (!discOptOut || !personID) {
    return;
  }

  try {
    // TODO
    await TytoCalls.DISCProfile.Mini.put({
      discOptOut,
      personID,
    });

    const profilesResp = await TytoCalls.DISCProfilesMini.get({
      personIDs: `${personID}`,
    });

    const loggedInUserID = _.get(AppStore, "state.loggedInUserID", 0);

    if (AppStore && AppStore.dispatch) {
      if (personID === loggedInUserID) {
        const personalMini = profilesResp.discProfiles[0];

        if (personalMini && personalMini.personID === loggedInUserID) {
          AppStore.dispatch({
            payload: {
              userDISCMini: personalMini,
              userID: personID,
            },
            type: "USER_PERSONAL_DISC_MINI",
          });
        }
      }

      AppStore.dispatch({
        payload: {
          discMini: profilesResp.discProfiles,
        },
        type: "DISC_MINI_PROFILES_LOADED",
      });
    }

    WebSocketClient.announceUpdates([personID]);

    onSuccess();
  } catch (err) {
    const errMsg =
      typeof err === "string" ? err : _.get(err, "msg", "Error occurred");

    onError(errMsg);
  }
};
