import { ENDPOINT_DISC_PROFILES_MINI } from "../constants";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_DISC_PROFILES_MINI;

export const DISCProfilesMini = {
  get(
    params: Endpoints.Tyto.DISCProfilesMini.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      discProfiles: Tyto.DISCProfileMini[];
      permit: {
        hasAdvanceView: boolean;
        hasBasicView: boolean;
        isSelf: boolean;
        optOutLevel: Tyto.DiscOptOut;
        spoilerMode: string;
        subjectReveal: boolean;
      };
      session: SessionData;
    }>;
  },

  post(
    params: Endpoints.Tyto.DISCProfilesMini.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params || {}, callOpts) as Promise<{
      discProfiles: Tyto.DISCProfileMini[];
      session: SessionData;
    }>;
  },
};
