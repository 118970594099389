import * as React from "react";

import { IconProps } from "../typings";

export default ({ className, color, gradient, size }: IconProps) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            fill={color}
        >
            {Array.isArray(gradient) && (
                <defs>
                    <linearGradient id="ListULGradient1" x1="0" x2="1" y1="0" y2="1">
                        {gradient.map(grad => (
                            <stop offset={`${grad.offsetPercent}%`} stopColor={grad.color} />
                        ))}
                    </linearGradient>
                </defs>
            )}
            <path
                fill={Array.isArray(gradient) ? "url(#ListULGradient1)" : ""}
                d="M48 368a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm0-160a48 48 0 1 0 48 48 48 48 0 0 0-48-48zm448 24H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16z"
            />
        </svg>
    );
};
