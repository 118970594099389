/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto";
import { StoreContext, AppStoreProps } from "../../../../data/stores/AppStore";
import TeamMinimizedProfileThumbs from "./subcomponents/TeamMinimizedProfileThumbs";
import TeamPeople from "./subcomponents/TeamPeople";
import TeamGroupStyle from "./subcomponents/TeamGroupStyle";
import TeamSelectPlotGraph from "./subcomponents/TeamSelectPlotGraph";
import TeamTips from "./subcomponents/TeamTips";

import {
  calcGroupStyle,
  getGroupStyleWithStdDeviation,
  loadTeamProfile,
} from "./utils/";

import "./style.scss";

interface Props {
  discMiniProfiles: Tyto.DISCProfileMini[];
  isMinimized?: boolean;
  setFocusedPerson: (personID: number) => void;
}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);

  const [showCustomizationOptions] = React.useState(() => {
    const domainID = _.get(AppStore, "state.personInfo.domainID", 0);

    // * KVault, CardoneVentures, or Luminate Domains
    return domainID === 551 || domainID === 756384 || domainID === 1698652;
  });
  const [isMinimized, updateIsMinimized] = React.useState(!!props.isMinimized);
  const [groupStyle, updateGroupStyle] = React.useState(
    calcGroupStyle(props.discMiniProfiles)
  );
  const [groupStyleKeyWithStdDeviation, updateGroupStyleKeyWithStdDeviation] =
    React.useState(getGroupStyleWithStdDeviation(groupStyle));
  const [teamProfile, updateTeamProfile] = React.useState<
    Tyto.DISCTeamProfile | undefined
  >(() => {
    if (Array.isArray(groupStyle) && groupStyle.length) {
      if (AppStore.state) {
        // const teamStyleKey = `${groupStyle[0].label}${
        //   groupStyle[1] ? groupStyle[1].label : ""
        // }`;
        const teamStyleKeyWithStdDeviation =
          getGroupStyleWithStdDeviation(groupStyle);

        const newTeamProfile =
          AppStore.state.discTeamStyle[
            teamStyleKeyWithStdDeviation.slice(0, 2)
          ];

        if (!newTeamProfile && newTeamProfile !== null) {
          loadNewTeamProfile(AppStore, teamStyleKeyWithStdDeviation);
        }

        return newTeamProfile;
      }
    }

    return undefined;
  });

  // * [E-1] - Update GroupStyle and grab existing teamProfile as profileArray changes
  React.useEffect(() => {
    const newGroupStyle = calcGroupStyle(props.discMiniProfiles);

    if (Array.isArray(newGroupStyle) && newGroupStyle.length) {
      updateGroupStyle(newGroupStyle);

      const teamStyleKeyWithStdDeviation =
        getGroupStyleWithStdDeviation(newGroupStyle);

      updateGroupStyleKeyWithStdDeviation(teamStyleKeyWithStdDeviation);

      if (AppStore.state) {
        // const teamStyleKey = `${newGroupStyle[0].label}${
        //   newGroupStyle[1] ? newGroupStyle[1].label : ""
        // }`;

        const newTeamProfile =
          AppStore.state.discTeamStyle[
            teamStyleKeyWithStdDeviation.slice(0, 2)
          ];

        if (!newTeamProfile && newTeamProfile !== null) {
          loadNewTeamProfile(AppStore, teamStyleKeyWithStdDeviation);
        }

        updateTeamProfile(newTeamProfile);
      }
    }
  }, [props.discMiniProfiles]);

  // * [E-2] - Update teamProfile as state changes
  React.useEffect(() => {
    const discTeamStyle = AppStore.state?.discTeamStyle;
    // const discTeamStyle = _.get(AppStore, "state.discTeamStyle", {});
    // const teamStyleKey = `${groupStyle[0] ? groupStyle[0].label : ""}${
    //   groupStyle[1] ? groupStyle[1].label : ""
    // }`;
    const teamStyleKeyWithStdDeviation =
      getGroupStyleWithStdDeviation(groupStyle);

    if (teamStyleKeyWithStdDeviation) {
      const newTeamProfile = discTeamStyle?.[teamStyleKeyWithStdDeviation];

      console.log(
        `Checking Style Key on AppStore update for ${teamStyleKeyWithStdDeviation} and got: `,
        newTeamProfile
      );
      if (newTeamProfile) {
        updateTeamProfile(newTeamProfile);
      }
    }
  }, [_.get(AppStore, "state.discTeamStyle")]);

  React.useEffect(() => {
    if (isMinimized && !props.isMinimized) {
      updateLocalIsMinimized(!!props.isMinimized, updateIsMinimized);
    } else {
      updateIsMinimized(!!props.isMinimized);
    }
  }, [props.isMinimized]);

  if (props.isMinimized) {
    return (
      <TeamMinimizedProfileThumbs
        discMiniProfiles={props.discMiniProfiles}
        setFocusedPerson={props.setFocusedPerson}
      />
    );
  }

  if (isMinimized) {
    return null;
  }

  return (
    <div className="meta-sidepane-person-view-cont">
      <h2>Group Dynamic</h2>

      <TeamPeople
        discMini={props.discMiniProfiles}
        setFocusedPerson={props.setFocusedPerson}
      />

      <TeamGroupStyle
        discMini={props.discMiniProfiles}
        groupStyle={groupStyle}
        groupStyleKeyWithStdDeviation={groupStyleKeyWithStdDeviation}
        setFocusedPerson={props.setFocusedPerson}
        showCustomizationOptions={showCustomizationOptions}
      />

      {/* <TeamSelectPlotGraph
        discMini={props.discMiniProfiles}
        groupStyle={groupStyle}
        groupStyleKeyWithStdDeviation={groupStyleKeyWithStdDeviation}
      /> */}

      <TeamTips
        title="Facilitating Meeting"
        tips={
          teamProfile ? (teamProfile.meetingTips || "").split(/\n|\r\n/) : []
        }
      />

      <TeamTips
        closedAtStart={false}
        title="Decision Making Style"
        tips={
          teamProfile ? (teamProfile.decisionMaking || "").split(/\n|\r\n/) : []
        }
      />

      <TeamTips
        closedAtStart={false}
        title="Strength Areas"
        tips={
          teamProfile ? (teamProfile.strengthAreas || "").split(/\n|\r\n/) : []
        }
      />
    </div>
  );
};

const loadNewTeamProfile = async (
  AppStore: AppStoreProps,
  teamStyleKey: string
) => {
  try {
    const teamProfileResp: any = await loadTeamProfile(
      teamStyleKey.slice(0, 2)
    );

    if (AppStore.dispatch) {
      AppStore.dispatch({
        payload: {
          discStyle: teamProfileResp.discStyle,
        },
        type: "DISC_TEAM_PROFILE_LOADED",
      });
    }
  } catch (err) {}
};

const updateLocalIsMinimized = (
  isMinimized: boolean,
  updateIsMinimized: (val: boolean) => void
) => {
  setTimeout(() => {
    updateIsMinimized(isMinimized);
  }, 650);
};
