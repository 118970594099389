/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Checkbox, Icon, TextButton } from "../../../common/";

import "./ActionButtons.scss";

interface Props {
  autoAddToTeam: boolean;
  canAddRow: boolean;
  deletedRowsExist?: boolean;
  importHasHeaders: boolean;
  importedDataExists: boolean;
  onButtonClick: (type: string) => void;
  showDeleteButton: boolean;
  useEmailAsLogonName: boolean;
}

export default (props: Props) => {
  return (
    <div className="modals-importusers-modal-action-buttons-cont">
      <div className="modals-importusers-modal-action-buttons-checkbox-cont">
        <span>
          <TextButton
            className="modals-importusers-modal-action-buttons-checkbox-label"
            onClick={() => props.onButtonClick("download-template")}
            value="Download Template"
          />
        </span>
      </div>

      {props.importedDataExists && (
        <div className="modals-importusers-modal-action-buttons-checkbox-cont">
          <span>
            <TextButton
              className="modals-importusers-modal-action-buttons-checkbox-label"
              onClick={() => props.onButtonClick("clear-and-reset")}
              value="Clear and Reset"
            />
          </span>
        </div>
      )}

      {props.deletedRowsExist && (
        <div className="modals-importusers-modal-action-buttons-checkbox-cont">
          <Icon
            className="modals-importusers-modal-action-buttons-button-icon"
            buttonProps={{
              className: "modals-importusers-modal-action-buttons-button",
              disabled: !props.deletedRowsExist,
              name: "Restore Deleted Row"
            }}
            size={16}
            icon="undo-alt"
            isButton={true}
            onClick={() => props.onButtonClick("restore-row")}
          />

          <span>
            <TextButton
              className="modals-importusers-modal-action-buttons-checkbox-label"
              onClick={() => props.onButtonClick("restore-row")}
              // value="Add Existing users to Team"
              value="Restore Last Deleted Row"
            />
          </span>
        </div>
      )}

      {props.importedDataExists && (
        <div className="modals-importusers-modal-action-buttons-checkbox-cont">
          <Icon
            className="modals-importusers-modal-action-buttons-button-icon"
            buttonProps={{
              className: "modals-importusers-modal-action-buttons-button",
              disabled: !props.canAddRow,
              name: "Add Row"
            }}
            size={20}
            icon="plus"
            isButton={true}
            onClick={() => props.onButtonClick("add-row")}
          />

          <span>
            <TextButton
              className="modals-importusers-modal-action-buttons-checkbox-label"
              onClick={() => props.onButtonClick("add-row")}
              value="Add Row"
            />
          </span>
        </div>
      )}

      <div className="modals-importusers-modal-action-buttons-checkbox-cont">
        <Checkbox
          className="modals-importusers-modal-action-buttons-checkbox"
          size={16}
          checked={props.importHasHeaders}
          onCheck={() => props.onButtonClick("import-has-headers")}
        />

        <span>
          <TextButton
            className="modals-importusers-modal-action-buttons-checkbox-label"
            onClick={() => props.onButtonClick("import-has-headers")}
            value="File Has Headers"
          />
        </span>
      </div>

      {/* <div className="modals-importusers-modal-action-buttons-checkbox-cont">
        <Checkbox
          className="modals-importusers-modal-action-buttons-checkbox"
          size={16}
          checked={props.showDeleteButton}
          onCheck={() => props.onButtonClick("show-row-delete")}
        />

        <span>
          <TextButton
            className="modals-importusers-modal-action-buttons-checkbox-label"
            onClick={() => props.onButtonClick("email-as-logon")}
            // value="Add Existing users to Team"
            iconProps={{
              className: cx(
                "modals-importusers-modal-action-buttons-checkbox-icon-delete",
                props.showDeleteButton
                  ? "modals-importusers-modal-action-buttons-checkbox-icon-delete-active"
                  : "modals-importusers-modal-action-buttons-checkbox-icon-delete-inactive"
              ),
              icon: "trash-alt",
              size: 20
            }}
            value=""
          />
        </span>
      </div> */}

      <div className="modals-importusers-modal-action-buttons-checkbox-cont">
        <Checkbox
          className="modals-importusers-modal-action-buttons-checkbox"
          size={16}
          checked={props.autoAddToTeam}
          onCheck={() => props.onButtonClick("auto-team-add")}
        />

        <span>
          <TextButton
            className="modals-importusers-modal-action-buttons-checkbox-label"
            onClick={() => props.onButtonClick("email-as-logon")}
            // value="Add Existing users to Team"
            value="Add user to team if user already exists"
          />
        </span>
      </div>

      {/* <div className="modals-importusers-modal-action-buttons-checkbox-cont">
        <Checkbox
          className="modals-importusers-modal-action-buttons-checkbox"
          size={16}
          checked={props.useEmailAsLogonName}
          onCheck={() => props.onButtonClick("email-as-logon")}
        />

        <span>
          <TextButton
            className="modals-importusers-modal-action-buttons-checkbox-label"
            onClick={() => props.onButtonClick("email-as-logon")}
            value="Force Email as Logon Name"
          />
        </span>
      </div> */}
    </div>
  );
};
