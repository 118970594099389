import * as _ from "lodash";

import {
  LOGGED_IN_USER_KEY,
  SESSION_DATA_KEY,
  TEMP_SESSION_DATA_KEY,
} from "../constants";
import { getSessionStorageItem } from "./utils";
import { SessionData } from "../../typings/";

export function hardClearStorage(callback?: () => void) {
  try {
    localStorage.clear();
    sessionStorage.clear();

    console.log(
      "%cLOCAL_STORAGE AND SESSION_STORAGE CLEARED.",
      "background-color: green;color: #ececec;"
    );

    if (callback) {
      callback();
    }
  } catch (err) {
    if (callback) {
      callback();
    }
  }
}

export function clearSessionData(callback?: () => void) {
  // sessionStorage.setItem(SESSION_DATA_KEY, "");

  // const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  const emptyObj = JSON.stringify({});
  localStorage.setItem(SESSION_DATA_KEY, emptyObj);

  const sessionData = localStorage.getItem(SESSION_DATA_KEY);
  console.log("ALL SESSION DATA CLEARED - VALUE NOW: ", sessionData);

  if (callback) {
    callback();
  }
}

export function getLoggedInUserID(): number {
  const queriedUserID = getSessionStorageItem(LOGGED_IN_USER_KEY, true);
  const parsedUserID = queriedUserID ? parseInt(queriedUserID) : 0;

  return parsedUserID || 0;
}

export function getSessionData(userID: number): SessionData | undefined {
  // const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  const sessionObject = getSessionsObject();
  const sessionData = sessionObject[userID];

  //   console.log("SESSION DATA: ", sessionData);

  if (!sessionData || typeof sessionData !== "object") {
    return undefined;
  }

  return sessionData;
}

export function getSessionsAsArray() {
  const sessions = getSessionsObject();

  const sessionsArr = Object.values(sessions);

  return sessionsArr.filter(
    (session) => typeof session === "object" && session.sessionKey
  );
}

export function getSessionsObject(): { [x: number]: SessionData } {
  try {
    const sessionDataRaw = localStorage.getItem(SESSION_DATA_KEY);
    const sessionDataParsed = sessionDataRaw
      ? JSON.parse(sessionDataRaw)
      : undefined;

    return sessionDataParsed || {};
  } catch (err) {
    return {};
  }
}

export function getSessionKey(userID: number): string {
  if (!userID) {
    return "";
  }

  const sessionData = getSessionData(userID);

  if (!sessionData || typeof sessionData !== "object") {
    return "";
  }

  return sessionData.sessionKey || "";
}

export function removeSessionData(userID: number, callback?: () => void) {
  if (!userID) {
    return;
  }

  const sessionsObject = getSessionsObject();

  const sessions = {
    ...sessionsObject,
    [userID]: undefined,
  };

  const sessionAsString = JSON.stringify(sessions);

  localStorage.setItem(SESSION_DATA_KEY, sessionAsString);

  console.log(
    `SESSION DATA FOR USERID ${userID} CLEARED - SESSIONS OBJ VALUE NOW: ${sessions}`,
    sessions
  );

  if (callback) {
    callback();
  }
}

export function setSessionData(sessionData: SessionData) {
  if (!sessionData || !sessionData.userID) {
    return;
  }

  try {
    const sessionsObject = getSessionsObject();

    const newSessionsObject = {
      ...sessionsObject,
      [sessionData.userID]: sessionData,
    };

    const newSessionObjectAsString = JSON.stringify(newSessionsObject);

    localStorage.setItem(SESSION_DATA_KEY, newSessionObjectAsString);

    console.log(
      `SESSIONS OBJECT UPDATED - NEW VALUE ${newSessionObjectAsString}`
    );
  } catch (err) {}
}

export function setSessionDataForMultipleSessions(sessionDatas: SessionData[]) {
  if (!sessionDatas || !sessionDatas.length) {
    return;
  }

  const filteredSessions = sessionDatas.filter((sd) => !!sd.userID);

  if (!filteredSessions.length) {
    return;
  }

  try {
    const sessionsObject = getSessionsObject();

    const newSessionsObject = filteredSessions.reduce(
      (accum, sd) => {
        return {
          ...accum,
          [sd.userID]: sd,
        };
      },
      { ...sessionsObject }
    );

    const newSessionObjectAsString = JSON.stringify(newSessionsObject);

    localStorage.setItem(SESSION_DATA_KEY, newSessionObjectAsString);

    console.log(
      `SESSIONS OBJECT UPDATED WITH MULTIPLE SESSIONS - NEW VALUE ${newSessionObjectAsString}`
    );
  } catch (err) {}
}

export function curSessionStillExists(): boolean {
  const curUserID = getLoggedInUserID();

  const curSessionData = getSessionData(curUserID);

  return !!(curSessionData && curSessionData.sessionKey);
}

// * ============================================
// * Temp Session Data Methods ==================
// * ============================================
export function clearTempSessionData(callback?: () => void) {
  // sessionStorage.setItem(SESSION_DATA_KEY, "");

  // const sessionData = sessionStorage.getItem(SESSION_DATA_KEY);
  const emptyObj = JSON.stringify({});
  localStorage.setItem(TEMP_SESSION_DATA_KEY, emptyObj);

  const sessionData = localStorage.getItem(TEMP_SESSION_DATA_KEY);
  console.log("ALL TEMP SESSION DATA CLEARED - VALUE NOW: ", sessionData);

  if (callback) {
    callback();
  }
}

export function getTempLoggedInUserID(): number {
  // * Alias for normal Call since not difference at the moment
  return getLoggedInUserID();
}

export function getTempSessionData(userID: number): SessionData | undefined {
  const sessionObject = getTempSessionsObject();
  const sessionData = sessionObject[userID];

  if (!sessionData || typeof sessionData !== "object") {
    return undefined;
  }

  return sessionData;
}

export function getTempSessionsAsArray() {
  const sessions = getTempSessionsObject();

  const sessionsArr = Object.values(sessions);

  return sessionsArr.filter(
    (session) => typeof session === "object" && session.sessionKey
  );
}

export function getTempSessionsObject(): { [x: number]: SessionData } {
  try {
    const sessionDataRaw = localStorage.getItem(TEMP_SESSION_DATA_KEY);
    const sessionDataParsed = sessionDataRaw
      ? JSON.parse(sessionDataRaw)
      : undefined;

    return sessionDataParsed || {};
  } catch (err) {
    return {};
  }
}

export function getTempSessionKey(userID: number): string {
  if (!userID) {
    return "";
  }

  const sessionData = getTempSessionData(userID);

  if (!sessionData || typeof sessionData !== "object") {
    return "";
  }

  return sessionData.sessionKey || "";
}

export function removeTempSessionData(userID: number, callback?: () => void) {
  if (!userID) {
    return;
  }

  const sessionsObject = getTempSessionsObject();

  const sessions = {
    ...sessionsObject,
    [userID]: undefined,
  };

  const sessionAsString = JSON.stringify(sessions);

  localStorage.setItem(TEMP_SESSION_DATA_KEY, sessionAsString);

  console.log(
    `TEMP SESSION DATA FOR USERID ${userID} CLEARED - SESSIONS OBJ VALUE NOW: ${sessions}`,
    sessions
  );

  if (callback) {
    callback();
  }
}

export function setTempSessionData(sessionData: SessionData) {
  if (!sessionData || !sessionData.userID) {
    return;
  }

  try {
    const sessionsObject = getTempSessionsObject();

    const newSessionsObject = {
      ...sessionsObject,
      [sessionData.userID]: sessionData,
    };

    const newSessionObjectAsString = JSON.stringify(newSessionsObject);

    localStorage.setItem(TEMP_SESSION_DATA_KEY, newSessionObjectAsString);

    console.log(
      `TEMP SESSIONS OBJECT UPDATED - NEW VALUE ${newSessionsObject}`
    );
  } catch (err) {}
}

export function curTempSessionStillExists(): boolean {
  const curUserID = getTempLoggedInUserID();

  const curSessionData = getSessionData(curUserID);

  return !!(curSessionData && curSessionData.sessionKey);
}
