import { ENDPOINT_DISC_PROFILE_INTERACTIVE } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { SessionData } from "../../typings";
import { Tyto } from "../../typings/tyto";

const endpoint = ENDPOINT_DISC_PROFILE_INTERACTIVE;

export const DISCProfileInteractive = {
  get(
    params: Endpoints.Tyto.DISCProfile.Interactive.GetParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("get", endpoint, params || {}, callOpts) as Promise<{
      discProfile: Tyto.DISCCompareProfile;
      session: SessionData;
    }>;
  }
};
