/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto";
import { Button, Input, Textarea, ToggleSection } from "../../../common/";
import { DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY } from "../../../../data/constants/";
import { getDomainMessage, saveDomainMessage } from "../utils/";

import "./CustomizeWelcomeMessage.scss";

interface Props {
    closeAtStart?: boolean;
    domainID: number;
    title?: string;
}

export default (props: Props) => {
    const [saving, updateSaving] = React.useState(false);
    const [error, updateError] = React.useState("");
    const [waitingMessageLoaded, updateWaitingMessageLoaded] = React.useState(
        false
    );
    const [
        domainWaitingMessageLocalCopy,
        updateDomainWaitingMessageLocalCopy,
    ] = React.useState<Tyto.Domain.Message | null>(null);
    const [bodyPlain, updateBodyPlain] = React.useState<string>("");
    const [headline, updateHeadline] = React.useState("");

    React.useEffect(() => {
        if (props.domainID || props.domainID === 0) {
            updateWaitingMessageLoaded(false);

            getDomainMessage({
                domainID: props.domainID,
                messageType: DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY,
                forceDomain: true,
                onSuccess: (waitingMessage) => {
                    updateWaitingMessageLoaded(true);

                    updateDomainWaitingMessageLocalCopy(waitingMessage);
                    updateBodyPlain(_.get(waitingMessage, "bodyPlain", ""));
                    updateHeadline(_.get(waitingMessage, "headLine", ""));
                },
                onError: (msg: string) => {
                    updateError(msg);
                    updateWaitingMessageLoaded(true);
                },
            });
        }
    }, [props.domainID]);

    const bodyHasChanges =
        bodyPlain !== _.get(domainWaitingMessageLocalCopy, "bodyPlain", "");
    const headlineHasChanges =
        headline !== _.get(domainWaitingMessageLocalCopy, "headLine", "");

    return (
        <ToggleSection
            title={props.title || "Customize Waiting Message"}
            closedAtStart={props.closeAtStart}
            className="meta-super-sidepane-toggle-section"
            renderFunction={(isExpanded) => {
                if (!isExpanded) {
                    return null;
                }

                if (!waitingMessageLoaded) {
                    return (
                        <section className="meta-super-sidepane-domainemailsettings-main-cont">
                            <h3>Loading Waiting Message...</h3>
                        </section>
                    );
                }

                return (
                    <section className="meta-super-sidepane-domainemailsettings-main-cont">
                        {error && (
                            <div style={{ width: "100%", paddingBottom: "20px" }}>
                                <p style={{ color: "red" }}>{error}</p>
                            </div>
                        )}

                        <Input
                            containerClassName="meta-super-sidepane-domainemailsettings-headline-cont"
                            className="meta-super-sidepane-domainemailsettings-headline"
                            disabled={saving}
                            hideClear={true}
                            onChange={(newVal) => updateHeadline(newVal)}
                            placeholder="Title"
                            value={headline}
                        />

                        <Textarea
                            className="meta-super-sidepane-domainemailsettings-body"
                            disabled={saving}
                            onChange={(newVal) => updateBodyPlain(newVal)}
                            placeholder="Waiting Message Body"
                            value={bodyPlain}
                        />

                        <div className="meta-super-sidepane-domainemailsettings-buttons-cont">
                            <Button
                                className="meta-super-sidepane-domainemailsettings-button"
                                disabled={saving || (!bodyHasChanges && !headlineHasChanges)}
                                shape="square"
                                type="hollow"
                                onClick={() => {
                                    updateHeadline(
                                        _.get(domainWaitingMessageLocalCopy, "headLine", "")
                                    );
                                    updateBodyPlain(
                                        _.get(domainWaitingMessageLocalCopy, "bodyPlain", "")
                                    );
                                }}
                                value={"Reset"}
                            />

                            <Button
                                className="meta-super-sidepane-domainemailsettings-button"
                                disabled={saving || (!bodyHasChanges && !headlineHasChanges)}
                                shape="square"
                                type="color"
                                onClick={() => {
                                    updateSaving(true);

                                    saveDomainMessage({
                                        data: {
                                            domainID: props.domainID,
                                            headLine: headlineHasChanges ? headline : undefined,
                                            bodyPlain: bodyHasChanges ? bodyPlain : undefined,
                                        },
                                        messageType: DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY,
                                        onSuccess: (newWaitingMessage) => {
                                            updateDomainWaitingMessageLocalCopy(newWaitingMessage);
                                            updateBodyPlain(
                                                _.get(newWaitingMessage, "bodyPlain", "")
                                            );
                                            updateHeadline(_.get(newWaitingMessage, "headLine", ""));
                                            updateSaving(false);
                                        },
                                        onError: (msg: string) => {
                                            updateError(msg);
                                            updateSaving(false);
                                        },
                                    });
                                }}
                                value={saving ? "Saving..." : "Save Changes"}
                            />
                        </div>
                    </section>
                );
            }}
        />
    );
};