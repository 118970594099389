/*
 * Component Description
 */
import * as React from "react";

import { Checkbox, TextButton } from "../../../components/common/";

import "./DisplayedUsersCount.scss";

interface Props {
  clearSelectedUsers: () => void;
  filteredUsersCount: number;
  includeTeamsBelow: boolean;
  loading: boolean;
  selectCurrentlyShowingUsers: () => void;
  toggleIncludeTeamsBelow: () => void;
  totalUsersCount: number;
}

export default (props: Props) => {
  if (props.loading) {
    return (
      <div className="directory-teams-displayed-users-cont">
        <p
          className="directory-teams-displayed-users-msg title-bold"
          style={{ color: "transparent" }}
        >
          Loading...
        </p>
      </div>
    );
  }

  return (
    <div className="directory-teams-displayed-users-cont">
      <div className="directory-teams-displayed-users-mark-buttons-cont">
        <TextButton
          className="directory-teams-displayed-users-mark-buttons-button mark-button"
          disabled={!props.filteredUsersCount}
          onClick={props.selectCurrentlyShowingUsers}
          value="Select Currently Showing Users"
        />

        <TextButton
          className="directory-teams-displayed-users-mark-buttons-button clear-button"
          onClick={props.clearSelectedUsers}
          value="Reset Selected Users"
        />

        <Checkbox
          className="directory-teams-displayed-users-mark-buttons-checkbox"
          checked={props.includeTeamsBelow}
          onCheck={props.toggleIncludeTeamsBelow}
          size={12}
        />

        <TextButton
          className="directory-teams-displayed-users-mark-buttons-button toggle-button"
          onClick={props.toggleIncludeTeamsBelow}
          value="Include People Below This Team"
        />
      </div>

      <p className="directory-teams-displayed-users-msg title-bold">
        {getText({
          filteredUsersCount: props.filteredUsersCount,
          totalUsersCount: props.totalUsersCount,
        })}
      </p>
    </div>
  );
};

const getText = ({
  filteredUsersCount,
  totalUsersCount,
}: {
  filteredUsersCount: number;
  totalUsersCount: number;
}) => {
  if (filteredUsersCount === totalUsersCount) {
    return `Showing all users (${totalUsersCount})`;
  }

  return `Showing ${filteredUsersCount} of ${totalUsersCount} total users`;
};
