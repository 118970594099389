/*
 * Component Description
 */
import * as React from "react";

import { Nav } from "../../components/meta/";
import { SessionHOC } from "../../components/hoc/";

import Directory from "./Directory";

interface Props {
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  return (
    <SessionHOC path={props.path} uri={props.uri}>
      <section className="home-interface">
        <Nav path={props.path} uri={props.uri} />

        <Directory />
      </section>
    </SessionHOC>
  );
};
