/*
 * Component Description
 */
import * as React from "react";

import { Tyto } from "../../../typings/tyto";

import MemberListItem from "./MemberListItem";

import "./MembersList.scss";

interface Props {
  groupMembers?: Tyto.DISCProfileMini[];
  onPersonSelect: (personID: number) => void;
}

export default (props: Props) => {
  return (
    <div className="groups-people-list-cont">
      <ul className="groups-people-list">
        {props.groupMembers &&
          props.groupMembers.map((member) => (
            <MemberListItem
              canSelect={false}
              hasR3Style={true}
              isSelected={false}
              searchTerm={""}
              showCheckbox={false}
              previewUser={(personID) => props.onPersonSelect(personID)}
              toggleUserID={() => {}}
              user={member}
            />
          ))}
      </ul>
    </div>
  );
};
