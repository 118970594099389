/*
 * Component Description
 */
import * as React from "react";
// import WSWebSocket from "ws";

import Router from "./";

interface Props {}

interface State {
  pageVisibility: "prerender" | "hidden" | "visible";
}

// const sendMessageTypes = {
//   ERROR: "error",
//   MINIS_UPDATED: "mini-updated",
//   WATCH_MINI: "watch-mini",
//   WATCH_INTERACTIVE: "watch-interactive",
//   RESET_USER_WATCH_LISTS: "reset-user-watch-lists",
// };
// const messageTypes = {
//   MINI_CHANGED: "mini-changed",
// };

// const WS_SERVER_URL = `ws://localhost:8080`;
// const WS_SERVER_UID = String.fromCharCode(
//   ...Array.from({ length: 40 }, () => {
//     let number = Math.random() * 42 + 49;

//     if (number > 57 && number < 65) {
//       if (Math.random() > 0.49) {
//         number += 10;
//       } else {
//         number -= 10;
//       }
//     }

//     return number;
//   })
// );

export default class RouterWrapper extends React.Component<Props, State> {
  wsServerConnection?: WebSocket;

  constructor(props: Props) {
    super(props);

    this.state = {
      pageVisibility: document.visibilityState,
    };

    // this._startWSConnection = this._startWSConnection.bind(this);
    // this._testConnection = this._testConnection.bind(this);
    this._updateVisibilityState = this._updateVisibilityState.bind(this);
  }

  componentWillMount() {
    document.addEventListener("visibilitychange", this._updateVisibilityState);

    // this._startWSConnection();
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this._updateVisibilityState
    );
  }

  // _startWSConnection() {
  //   const connection = new WebSocket(`${WS_SERVER_URL}/${WS_SERVER_UID}`);

  //   this.wsServerConnection = connection;

  //   if (this.wsServerConnection) {
  //     // * On Connection ========
  //     this.wsServerConnection.addEventListener("open", (ev: Event) => {
  //       console.log("CONNECTION ESTABLISHED!");
  //     });

  //     // * On Message ========
  //     this.wsServerConnection.addEventListener("message", (message) => {
  //       console.log("Received Message: ", message);
  //     });

  //     setTimeout(() => {
  //       this._testConnection();
  //     }, 2000);
  //   }
  // }

  // _testConnection() {
  //   if (this.wsServerConnection) {
  //     const fakeCaringMsg = JSON.stringify({
  //       type: sendMessageTypes.WATCH_MINI,
  //       payload: {
  //         userID: 12345678,
  //       },
  //     });

  //     this.wsServerConnection.send(fakeCaringMsg);

  //     setTimeout(() => {
  //       if (this.wsServerConnection) {
  //         const fakeUpdateMsg = JSON.stringify({
  //           type: sendMessageTypes.MINIS_UPDATED,
  //           payload: {
  //             userIDs: [12345678],
  //             omitSender: false,
  //           },
  //         });

  //         this.wsServerConnection.send(fakeUpdateMsg);
  //       }
  //     }, 400);
  //   }
  // }

  _updateVisibilityState() {
    if (document.visibilityState !== this.state.pageVisibility) {
      this.setState({
        pageVisibility: document.visibilityState,
      });
    }
  }

  render() {
    return <Router visibilityState={this.state.pageVisibility} />;
  }
}
