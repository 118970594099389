/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { URL_BASE } from "../../../../data/constants/";
import { Tyto } from "../../../../typings/tyto";

interface Props {
  existingProfileImage: Tyto.Asset;
  curPhotoAssetID: number;
  curSelectedImageID?: number;
  chooseExistingImage: (assetID: number) => void;
}

export default (props: Props) => {
  if (
    props.curPhotoAssetID &&
    props.curPhotoAssetID === props.existingProfileImage.assetID
  ) {
    return null;
  }

  const imgPath = getAssetUrl(props.existingProfileImage);

  if (!imgPath) {
    return null;
  }

  return (
    <button
      className={cx(
        "profileimage-modal-inner-images-image-cont",
        !!props.curSelectedImageID &&
          props.existingProfileImage.assetID === props.curSelectedImageID &&
          "profileimage-modal-inner-images-image-cont-selected"
      )}
      onClick={() =>
        props.chooseExistingImage(props.existingProfileImage.assetID)
      }
    >
      <img className="profileimage-modal-inner-images-image" src={imgPath} />
    </button>
  );
};

const getAssetUrl = (existingProfileImage: Tyto.Asset) => {
  if (!existingProfileImage) {
    return "";
  }

  const keyedEncodings = _.keyBy(
    existingProfileImage.encodings,
    "encodingType"
  );

  const encoding = keyedEncodings["ocDEFAULT"] || keyedEncodings["ocORIGINAL"];

  if (!encoding) {
    return "";
  }

  return `https://${URL_BASE}${encoding.pathURL}`;
};
