/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import "./style.scss";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  value: string;
}

export default ({ className, style, value }: Props) => {
  return (
    <p className={cx("cc-message", className)} style={style}>
      {value}
    </p>
  );
};
