/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Button } from "../../../../components/common";
import { Tyto } from "../../../../typings/tyto";

const labelRegExpTest = /\{\{(.*?)\}\}/g;

interface Props {
  curTab: string;
  discMiniProfile?: Tyto.DISCProfileMini;
  tabs: {
    label: string;
    value: string;
  }[];
  updateSelectedTab: (value: string) => void;
  userHasManage: boolean;
}

export default (props: Props) => {
  return (
    <div className="directory-tabs-interface-tabs-cont">
      {props.tabs.map((tab) =>
        tab.value === "admin-info" && !props.userHasManage ? null : (
          <Button
            className={cx(
              "directory-tabs-interface-tab",
              props.curTab === tab.value &&
                "directory-tabs-interface-tab-active-tab",
              tab.value === "admin-info" &&
                "directory-tabs-interface-tab-admin-tab"
            )}
            key={tab.value}
            // value={tab.label}
            value={parseLabel({
              label: tab.label,
              discMini: props.discMiniProfile,
            })}
            onClick={() => props.updateSelectedTab(tab.value)}
          />
        )
      )}
    </div>
  );
};

export const parseLabel = ({
  label,
  discMini,
}: {
  label: string;
  discMini?: Tyto.DISCProfileMini;
}) => {
  const testedLabel = labelRegExpTest.exec(label);

  if (Array.isArray(testedLabel) && testedLabel.length) {
    const specialKey = _.get(testedLabel, "[1]", "");
    const target = _.get(testedLabel, "[0]", "");
    const specificValue = getSpecificValue({ specialKey, discMini });

    return label.replace(target, specificValue);
  }

  return label;
};

const getSpecificValue = ({
  specialKey,
  discMini,
}: {
  specialKey: string;
  discMini?: Tyto.DISCProfileMini;
}) => {
  switch (specialKey) {
    case "personName":
      return _.get(discMini, "personName", "").split(" ")[0];
    case "styleName":
      const styleName = _.get(discMini, "styleName3", "");
      return styleName ? `${styleName}s` : "";
    default:
      return "";
  }
};
