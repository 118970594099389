import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto/";
// import { Endpoints } from "../../../../typings/data/";
import TytoCalls from "../../../../data/tyto/";
import { AppStoreProps } from "../../../../data/stores/AppStore";

import {
  AdminStoreProps,
  StoreState as AdminStoreState,
} from "../stores/AdminStore";

export async function addPeopleToTeams({
  onComplete,
  onError,
  selectedPeopleIDs,
  selectedTeamsIDs,
}: {
  onComplete: () => void;
  onError: (msg: string) => void;
  selectedPeopleIDs: AdminStoreState["selectedPeopleIDs"];
  selectedTeamsIDs: AdminStoreState["selectedTeamsIDs"];
}) {
  try {
    // * [1] - Get All userIDs and TeamIDs that are selected
    const personIDs = Object.keys(selectedPeopleIDs)
      .filter((key) => key && !!selectedPeopleIDs[parseInt(`${key}`)])
      .map((personID) => parseInt(`${personID}`));

    const teamIDs = Object.keys(selectedTeamsIDs)
      .filter((key) => key && !!selectedTeamsIDs[parseInt(`${key}`)])
      .map((teamID) => parseInt(`${teamID}`));

    if (!teamIDs.length || !personIDs.length) {
      onError("No TeamIDs or MemberIDs found");
      return;
    }

    // * [2] - Make List of userID/teamID combos for calls
    const pairs = personIDs.reduce((accum: number[][], personID) => {
      const allTeams = teamIDs.map((teamID) => [personID, teamID]);

      return [...accum, ...allTeams];
    }, []);

    // * [3] - Make all requests
    const calls = pairs.map(([memberID, teamID]) =>
      addPersonToTeam(memberID, teamID)
    );

    // * [4] - Wait for all requests to complete
    const resps = await Promise.all(calls);

    onComplete();
  } catch (err) {
    console.warn("An error occurred while adding people to teams: ", err);
    onError("An error occurred while attemptig to save members to teams.");
    debugger;
  }
}

export async function addPersonToTeam(memberID: number, teamID: number) {
  if (!memberID || !teamID) {
    return false;
  }

  try {
    await TytoCalls.TeamMembership.Person.post({ teamID, memberID });

    return true;
  } catch (err) {
    return false;
  }
}

export async function loadTeamMembers({
  domainID,
  flattenedList,
  onLoad,
}: {
  domainID: number;
  flattenedList: Tyto.Team[];
  onLoad: (peopleAndMemberships: {
    people: Tyto.AdvancedPerson[];
    memberships: Tyto.AdvancedPersonTeamMembership[];
  }) => void;
}) {
  if (!domainID || !Array.isArray(flattenedList)) {
    return;
  }

  try {
    const teamIDsWithinDomain = flattenedList.reduce(
      (accum: number[], team) => {
        if (
          (team.domainID === domainID && team.teamType === "ocTEAM") ||
          team.teamID === domainID
        ) {
          accum.push(team.teamID);
        }

        return accum;
      },
      []
    );

    if (!teamIDsWithinDomain.length) {
      return;
    }

    const excludeDate = new Date("01/01/1970");

    const { ret } = await TytoCalls.PersonAdvanced.post({
      teamIDs: teamIDsWithinDomain.join(","),
      excludeTerminateBefore: excludeDate.toISOString(),
    });

    onLoad({ people: ret.people || [], memberships: ret.memberships || [] });
  } catch (err) {
    debugger;
    // TODO
  }
}

export function parseMemberships(
  memberships: Tyto.AdvancedPersonTeamMembership[]
): { [x: number]: Tyto.AdvancedPersonTeamMembership[] } {
  if (!Array.isArray(memberships)) {
    return {};
  }

  const indexedMemberships = memberships.reduce(
    (
      accum: { [x: number]: Tyto.AdvancedPersonTeamMembership[] },
      membership
    ) => {
      if (Array.isArray(accum[membership.teamID])) {
        accum[membership.teamID].push(membership);
      } else {
        accum[membership.teamID] = [membership];
      }

      return accum;
    },
    {}
  );

  return indexedMemberships;
}

export function flattenStructureResponse(
  {
    domains,
    teams,
    projects,
  }: { domains: Tyto.Team[]; teams: Tyto.Team[]; projects?: Tyto.Team[] },
  includeProjects?: boolean
) {
  return _.uniqBy(
    [
      ...(domains || []),
      ...(teams || []),
      ...(includeProjects && Array.isArray(projects) ? projects : []),
    ],
    "teamID"
  );
}

export async function loadTeamStructure({
  AdminStore,
  AppStore,
  includeProjects,
  loadConfigs = true,
}: {
  AdminStore: AdminStoreProps;
  AppStore: AppStoreProps;
  includeProjects?: boolean;
  loadConfigs?: boolean;
}) {
  try {
    const [domains, teams, projects] = await Promise.all([
      TytoCalls.TeamsByFunction.get({ teamType: "ocDOMAIN" }),
      TytoCalls.TeamsByFunction.get({ teamType: "ocTEAM" }),
      TytoCalls.TeamsByFunction.get({ teamType: "ocPROJECT" }),
    ]);

    if (AdminStore.dispatch) {
      AdminStore.dispatch({
        payload: {
          domains: domains.teams,
          teams: teams.teams,
          projects: includeProjects ? _.get(projects as any, "teams", []) : [],
        },
        type: "ADMIN_STORE_STRUCTURE_LOADED",
      });

      if (loadConfigs) {
        loadTeamsWithTeamToolsConfig({
          AdminStore,
          teams: [...(domains.teams || []), ...(teams.teams || [])],
        });
      }

      return {
        domains: domains.teams,
        teams: teams.teams,
        projects: includeProjects ? _.get(projects, "teams", []) : [],
      };
    }
  } catch (err) {
    console.warn("Failed to retrieve Team Structure Info in Admin Interface");
  }
}

export function parseTeamStructure(
  {
    domains,
    teams,
    projects,
  }: {
    domains: Tyto.Team[];
    teams: Tyto.Team[];
    projects?: Tyto.Team[];
  },
  includeProjects?: boolean
) {
  const flattenedList = flattenStructureResponse(
    { domains, teams, projects },
    includeProjects
  );

  const structured = _.sortBy(flattenedList, "level").reduce(
    (accum: { [x: string]: Tyto.Team[] }, item: Tyto.Team) => {
      if (typeof item.iPath === "string") {
        if (accum[item.iPath]) {
          accum[item.iPath].push(item);
        } else {
          accum[item.iPath] = [item];
        }
      }

      return accum;
    },
    {} as { [x: string]: Tyto.Team[] }
  );

  const domainsByParentDomainID = domains.reduce(
    (accum: { [x: string]: Tyto.Team[] }, domainTeam) => {
      if (domainTeam && domainTeam.teamID !== domainTeam.domainID) {
        if (accum[`${domainTeam.domainID}`]) {
          accum[`${domainTeam.domainID}`].push(domainTeam);
        } else {
          accum[`${domainTeam.domainID}`] = [domainTeam];
        }
      }

      return accum;
    },
    {}
  );

  return {
    domainsByParentDomainID,
    flattenedList,
    structured,
  };
}

export async function loadTeamsWithTeamToolsConfig({
  AdminStore,
  teams,
}: {
  AdminStore: AdminStoreProps;
  teams: Tyto.Team[];
}) {
  // TODO: ----v
  // ? Filter teams to only load thosae in current domain ?

  try {
    const filteredTeams = teams.filter((team) => !!team.isTeamToolsConfig);
    const teamCalls = filteredTeams.length
      ? filteredTeams.map((team) => loadTeam(team.teamID))
      : [];

    const resps = await Promise.all(teamCalls);

    if (AdminStore.dispatch) {
      AdminStore.dispatch({
        payload: {
          teams: resps,
        },
        type: "ADMIN_STORE_TEAMS_WITH_CONFIGURATION_LOADED",
      });
    }
  } catch (err) {
    debugger;
    console.warn(
      "An error occurred while attempting to load team Configuration Data"
    );
  }
}

export async function loadTeam(teamID: number) {
  if (!teamID) {
    return null;
  }

  try {
    // const cacheBuster = Array.from({ length: 10 }, __ =>
    //   String.fromCharCode(Math.round(Math.random() * 42 + 48))
    // ).join("");

    const resp = await TytoCalls.Team.get({ teamID });

    return resp.team;
  } catch (err) {
    return null;
  }
}

export async function updateUser({
  onError,
  onSuccess,
  memberID,
  teamID,
  updates,
}: {
  memberID: number;
  teamID: number;
  updates: Partial<Endpoints.Tyto.TeamMembership.Person.PutParameters>;
  onError: (msg?: string) => void;
  onSuccess: (
    successfulUpdates: Partial<Endpoints.Tyto.TeamMembership.Person.PutParameters>
  ) => void;
}) {
  try {
    const updateResp = await TytoCalls.TeamMembership.Person.put({
      ...updates,
      memberID,
      teamID,
    });

    onSuccess(updates);
  } catch (err) {
    debugger;
    onError(`${err}`);
  }
}

export function keyMemberships(
  memberships: Tyto.AdvancedPersonTeamMembership[]
) {
  if (!memberships || !memberships.length) {
    return {} as { [x: number]: Tyto.AdvancedPersonTeamMembership[] };
  }

  return memberships.reduce(
    (
      accum: { [x: number]: Tyto.AdvancedPersonTeamMembership[] },
      membership
    ) => {
      if (accum[membership.memberID]) {
        accum[membership.memberID].push(membership);
      } else {
        accum[membership.memberID] = [membership];
      }

      return accum;
    },
    {}
  );
}
