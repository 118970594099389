/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import TytoCalls from "../../../data/tyto/";
import { AuthResult, SessionData } from "../../../typings";
import { Tyto } from "../../../typings/tyto";
import { AppStoreProps } from "../../../data/stores/AppStore";
import { UserThumb, UserThumbPlaceholder } from "../../../components/common/";

import "./MultipleSessionsMatched.scss";

interface Props {
  authResults: AuthResult[];
  AppStore?: AppStoreProps;
  onChoose?: () => void;
}

export default ({ authResults, AppStore, onChoose }: Props) => {
  const [choosing, updateChoosing] = React.useState(false);

  return (
    <div className="logon-cont logon-multiple-sessions-found-cont">
      <h1 className="logon-title extra-space title-bold">Choose Account</h1>

      <ul className="logon-multiple-sessions-list">
        {authResults.map((authResult, curIdx) => (
          <AuthResultItem
            AppStore={AppStore}
            authResult={authResult}
            curIdx={curIdx}
            onChoose={onChoose}
            key={authResult.userID}
            choosing={choosing}
            updateChoosing={updateChoosing}
          />
        ))}
      </ul>
    </div>
  );
};

interface AuthResultItemProps {
  AppStore?: AppStoreProps;
  authResult: AuthResult;
  curIdx: number;
  choosing: boolean;
  onChoose?: () => void;
  updateChoosing: (newVal: boolean) => void;
}

const AuthResultItem = ({
  AppStore,
  authResult,
  choosing,
  curIdx,
  onChoose,
  updateChoosing,
}: AuthResultItemProps) => {
  const [discMini, updateDISCMini] =
    React.useState<Tyto.DISCProfileMini | null>(null);

  React.useEffect(() => {
    loadDISCMiniForSession({
      authResult,
      updateDISCMini,
    });
  }, []);

  return (
    <li
      className="logon-multiple-sessions-list-item"
      key={`${authResult.userID}-${authResult.domainID}`}
    >
      <button
        className="logon-multiple-sessions-list-item-button"
        style={{ animationDuration: `${600 + 80 * curIdx}ms` }}
        disabled={choosing}
        onClick={async () => {
          if (AppStore && AppStore.dispatch) {
            updateChoosing(true);

            try {
              const { authSession, ...authResp } = authResult;

              const { session } = await TytoCalls.AccountSession.get({
                sessionKey: _.get(authSession, "sessionKey", ""),
              });

              const coercedAuthResp: SessionData = {
                ...authSession,
                ...authResp,
                ...session,
              };

              AppStore.dispatch({
                payload: coercedAuthResp,
                callback: () => {
                  if (onChoose) {
                    onChoose();
                  }
                },
                type: "USER_LOGGED_IN",
              });
            } catch (err) {
              AppStore.dispatch({
                payload: authResult.authSession,
                callback: () => {
                  if (onChoose) {
                    onChoose();
                  }
                },
                type: "USER_LOGGED_IN",
              });
            }
          }
        }}
      >
        <div className="logon-multiple-sessions-list-item-userthumb-cont">
          {discMini ? (
            <UserThumb
              className="logon-multiple-sessions-list-item-userthumb"
              size={43}
              discValues={{
                d: _.get(discMini, "d3", 0),
                i: _.get(discMini, "i3", 0),
                s: _.get(discMini, "s3", 0),
                c: _.get(discMini, "c3", 0),
              }}
              discMiniProfile={discMini}
              userName={_.get(discMini, "personName", "")}
              asset={discMini.profileImageAsset}
            />
          ) : (
            <UserThumbPlaceholder
              className="logon-multiple-sessions-list-item-userthumb"
              size={43}
            />
          )}
        </div>
        <div className="logon-multiple-sessions-list-item-inner-cont">
          <span className="logon-multiple-sessions-list-item-username">
            {_.get(authResult, "authSession.userName", "")}
          </span>
          <span className="logon-multiple-sessions-list-item-domainname">
            {authResult.domainName || authResult.otherName}
          </span>
        </div>
      </button>
    </li>
  );
};

const loadDISCMiniForSession = async ({
  authResult,
  updateDISCMini,
}: {
  authResult: AuthResult;
  updateDISCMini: (DISCMini: Tyto.DISCProfileMini) => void;
}) => {
  try {
    const discMiniResp = await TytoCalls.DISCProfilesMini.get(
      {
        personIDs: `${_.get(authResult, "authSession.userID", 0)}`,
        // @ts-ignore
        sessionKey: _.get(authResult, "authSession.sessionKey", ""),
      },
      {
        omitSessionKey: true,
      }
    );

    updateDISCMini(discMiniResp.discProfiles[0]);
  } catch (err) {
    // TODO
  }
};
