import * as _ from "lodash";

import { TEAM_TOOLS_STORAGE_KEY } from "../constants/";
// import { getSessionData } from "../utils/";
import { getSessionData, getLoggedInUserID } from "./session";

const SESSION_STORAGE_SUBKEY = "session-storage::";

export function formatXMLRequestForStorage(
  urlWithoutParams: string,
  params: { [x: string]: any } = {}
) {
  const paramsWithoutSesKey = _.omit(params || {}, "sessionKey");
  const paramsAsString = Object.keys(paramsWithoutSesKey || {})
    .map(key => `${key}=${params[key]}`)
    .join("&");

  console.log(
    `Formatted XML Request for storage looks like: ${urlWithoutParams}?${paramsAsString}`
  );

  return `${urlWithoutParams}?${paramsAsString}`;
}

export function setSessionStorageItem(
  key: string,
  value: any,
  omitUserID: boolean
) {
  if (!sessionStorage || !key) {
    return false;
  }

  try {
    const formattedKey = `${TEAM_TOOLS_STORAGE_KEY}${SESSION_STORAGE_SUBKEY}${
      omitUserID ? "" : getUserIDForKey()
    }${key}`;
    const valueStringified = JSON.stringify(value);

    sessionStorage.setItem(formattedKey, valueStringified);
    createTimestamp(key);

    console.log(`Set sessionStorage value for ${key}`);

    return true;
  } catch (err) {
    console.warn(
      "==========================================================================="
    );
    console.warn(`sessionStorage failed to store item.`);
    console.warn(
      "==========================================================================="
    );

    return false;
  }
}

export function setItem(key: string, value: any): boolean {
  if (!localStorage || !key) {
    return false;
  }

  try {
    const formattedKey = `${TEAM_TOOLS_STORAGE_KEY}${getUserIDForKey()}${key}`;
    const valueStringified = JSON.stringify(value);

    localStorage.setItem(formattedKey, valueStringified);
    createTimestamp(key);

    console.log(`Set localStorage value for ${key}`);

    return true;
  } catch (err) {
    console.warn(
      "==========================================================================="
    );
    console.warn(`localStorage failed to store item.`);
    console.warn(
      "==========================================================================="
    );

    return false;
  }
}

export function getSessionStorageItem(
  key: string,
  omitUserID: boolean,
  expirationLengthInMS?: number
): string | undefined {
  if (!sessionStorage || !key) {
    console.warn(
      `Failed at retrieved item. KEY: ${key}, Expiration: ${expirationLengthInMS}`
    );
    return undefined;
  }

  try {
    const formattedKey = `${TEAM_TOOLS_STORAGE_KEY}${SESSION_STORAGE_SUBKEY}${
      omitUserID ? "" : getUserIDForKey()
    }${key}`;
    const itemFromSessionStorage = sessionStorage.getItem(formattedKey);
    const parsedValue = itemFromSessionStorage
      ? JSON.parse(itemFromSessionStorage)
      : undefined;

    // * If requested, check that data isn't stale according to expirationLengthInMS
    if (parsedValue && expirationLengthInMS) {
      const timeOfStorage = getTimestamp(key);

      // * If it has expired, clear storage for that item and return undefined;
      if (timeOfStorage && Date.now() > timeOfStorage + expirationLengthInMS) {
        removeItem(key);

        return undefined;
      }
    }

    return parsedValue;
  } catch (err) {
    console.warn(
      "==========================================================================="
    );
    console.warn(`sessionStorage failed to retrieve item.`);
    console.warn(
      "==========================================================================="
    );

    return undefined;
  }
}

export function getItem(
  key: string,
  expirationLengthInMS?: number
): string | undefined {
  if (!localStorage || !key) {
    console.warn(
      `Failed at retrieved item. KEY: ${key}, Expiration: ${expirationLengthInMS}`
    );
    return undefined;
  }

  try {
    const formattedKey = `${TEAM_TOOLS_STORAGE_KEY}${getUserIDForKey()}${key}`;
    const itemFromLocalStorage = localStorage.getItem(formattedKey);
    const parsedValue = itemFromLocalStorage
      ? JSON.parse(itemFromLocalStorage)
      : undefined;

    // * If requested, check that data isn't stale according to expirationLengthInMS
    if (parsedValue && expirationLengthInMS) {
      const timeOfStorage = getTimestamp(key);

      // * If it has expired, clear storage for that item and return undefined;
      if (timeOfStorage && Date.now() > timeOfStorage + expirationLengthInMS) {
        removeItem(key);

        return undefined;
      }
    }

    return parsedValue;
  } catch (err) {
    console.warn(
      "==========================================================================="
    );
    console.warn(`localStorage failed to retrieved item.`);
    console.warn(
      "==========================================================================="
    );

    return undefined;
  }
}

export function removeItem(key: string) {
  try {
    // ! Incorrect format??
    localStorage.removeItem(key);

    return true;
  } catch (err) {
    console.warn(
      "==========================================================================="
    );
    console.warn(`Failed to remove item from localStorage.`);
    console.warn(
      "==========================================================================="
    );

    return false;
  }
}

export function getTimestamp(key: string): number | undefined {
  try {
    const timestampKey = `${TEAM_TOOLS_STORAGE_KEY}${getUserIDForKey()}timestamp::${key}`;

    const timestamp = localStorage.getItem(timestampKey);
    const parsedTimestamp = timestamp ? JSON.parse(timestamp) : undefined;

    return parsedTimestamp;
  } catch (err) {
    return undefined;
  }
}

function createTimestamp(key: string) {
  try {
    const timestampKey = `${TEAM_TOOLS_STORAGE_KEY}${getUserIDForKey()}timestamp::${key}`;
    const timestamp = `${Date.now()}`;

    localStorage.setItem(timestampKey, timestamp);

    return true;
  } catch (err) {
    console.warn(
      "==========================================================================="
    );
    console.warn(`localStorage failed to store timestamp.`);
    console.warn(
      "==========================================================================="
    );

    return false;
  }
}
function getUserIDForKey(): string {
  //   const sessionData = getSessionData();
  //   const userID = sessionData ? sessionData.userID : 0;
  const userID = getLoggedInUserID();
  //   console.log("I think stored userID is: ", userID);

  return `${userID}::`;
}
