/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import cx from "classnames";

import { Tyto } from "../../../typings/tyto";
import {
  CurrentlyOnlineBadge,
  DISCValueRender,
  Checkbox,
  Icon,
  Message,
  UserThumb,
} from "../../../components/common/";
import { calcGradientForIcon, isValidIcon } from "../../../data/utils/helpers";
import DummyLoadingPersonListItem from "../../teams/subcomponents/DummyLoadingPersonListItem";
import { AppStoreProps } from "../../../data/stores/AppStore";
import App from "../../../App";

interface Props {
  AppStore: AppStoreProps;
  discProfile?: Tyto.DISCProfileMini;
  forceCheckBoxSpace?: boolean;
  hide?: boolean;
  isSelected?: boolean;
  loading?: boolean;
  person?: Tyto.AdvancedPerson;
  previewUser: (userID: number) => void;
  profileUnavailable: boolean;
  showCheckbox?: boolean;
  toggleUserID: (userID: number) => void;
}

export default ({
  AppStore,
  discProfile,
  hide,
  forceCheckBoxSpace,
  isSelected,
  loading,
  person,
  profileUnavailable,
  previewUser,
  showCheckbox,
  toggleUserID,
}: Props) => {
  const [iconType, updateIconType] = React.useState(
    // getIconTypeFromDISCType(user.styleName3)
    discProfile ? isValidIcon(discProfile.graphic) : undefined
  );
  const [iconGradient, updateIconGrdient] = React.useState(
    discProfile
      ? calcGradientForIcon({
          d: discProfile.d3,
          i: discProfile.i3,
          s: discProfile.s3,
          c: discProfile.c3,
        })
      : undefined
  );

  React.useEffect(() => {
    updateIconType(discProfile ? isValidIcon(discProfile.graphic) : undefined);
    updateIconGrdient(
      discProfile
        ? calcGradientForIcon({
            d: discProfile.d3,
            i: discProfile.i3,
            s: discProfile.s3,
            c: discProfile.c3,
          })
        : undefined
    );
  }, [discProfile]);

  if (hide) {
    return null;
  }

  if (loading) {
    return <DummyLoadingPersonListItem showCheckbox={true} />;
  }

  if (!person) {
    return null;
  }

  const isOnline = !!_.get(
    AppStore,
    `state.onlineUsersTable[${person.userID}].connectionsCount`,
    0
  );

  return (
    <li className="directory-people-list-item">
      <div
        className={cx(
          "directory-people-list-item-button-cont",
          (showCheckbox || forceCheckBoxSpace) &&
            "directory-people-list-item-button-show-button"
        )}
      >
        {showCheckbox && (
          <Checkbox
            checked={!!isSelected}
            onCheck={() => toggleUserID(person.userID)}
            size={20}
          />
        )}
      </div>

      <button
        className={cx(
          "directory-people-list-item-info-cont",
          showCheckbox &&
            "directory-people-list-item-info-cont-checkbox-showing",
          isSelected && "user-is-selected",
          !discProfile && "is-not-selectable"
        )}
        onClick={
          discProfile ? () => previewUser(discProfile.personID) : () => {}
        }
        tabIndex={showCheckbox ? -1 : undefined}
      >
        <div className="directory-people-list-item-info-userthumb-cont">
          {/* {(discProfile || (person.profileImage && person.profileImage.assetID)) ? ( */}
          <UserThumb
            altImageAssetID={
              person.profileImage ? person.profileImage.assetID : undefined
            }
            className="directory-people-list-item-info-userthumb"
            asset={discProfile ? discProfile.profileImageAsset : undefined}
            discMiniProfile={discProfile}
            discValues={
              discProfile
                ? {
                    d: discProfile.d3,
                    i: discProfile.i3,
                    s: discProfile.s3,
                    c: discProfile.c3,
                  }
                : {
                    d: 0,
                    i: 0,
                    s: 0,
                    c: 0,
                  }
            }
            hasGradient={!!discProfile}
            userName={
              discProfile
                ? discProfile.personName
                : `${person.givenName} ${person.familyName}`
            }
            size={43}
          />
        </div>

        <div className="directory-people-list-item-info-name-cont">
          <p className="directory-people-list-item-info-name title-bold">
            {/* <TextHighlighter
                  highlightClassName=""
                  searchTerm={searchTerm}
                  text={discProfile.personName}
                  type="hightlight"
                /> */}
            {person.givenName && person.familyName
              ? `${person.givenName} ${person.familyName}`
              : discProfile && discProfile.personName}

            {isOnline && (
              <div className="directory-people-list-item-info-name-onlinebadge-cont">
                <CurrentlyOnlineBadge
                  className="directory-people-list-item-info-name-onlinebadge"
                  verboseOnHover={true}
                  terse={true}
                />
              </div>
            )}
          </p>
          <p className="directory-people-list-item-info-jobtitle">
            {person.jobTitle || (discProfile && discProfile.jobTitle)}
          </p>
        </div>

        <div className="directory-people-list-item-info-contact-cont">
          <p
            className="directory-people-list-item-info-contact-email"
            style={!person.email ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="directory-people-list-item-info-contact-icon"
              icon="envelope"
              size={12}
            />
            {person.email}
          </p>
          <p
            className="directory-people-list-item-info-contact-phone"
            style={!person.phone1 ? { opacity: 0, visibility: "hidden" } : {}}
          >
            <Icon
              className="directory-people-list-item-info-contact-icon"
              icon="phone"
              size={12}
            />
            {person.phone1 || ""}
          </p>
        </div>

        <div
          className={cx(
            "directory-people-list-item-info-style-cont",
            (loading || profileUnavailable) &&
              "directory-people-list-item-info-style-cont-profile-unavailable",
            discProfile &&
              "directory-people-list-item-info-style-cont-animate-cont"
          )}
        >
          {discProfile ? (
            <DISCValueRender
              className="directory-people-list-item-info-style-cont-item-wrapper"
              iconSize={49}
              isCompareValue={false}
              omitHiddenMsg={true}
              targetKey="graphic"
              targetKeys={["graphic", "styleName", "styleKey"]}
              discMiniProfile={discProfile}
            >
              {iconType && discProfile ? (
                <DISCValueRender
                  className="directory-people-list-item-info-style-cont-item-wrapper"
                  iconSize={49}
                  isCompareValue={false}
                  omitHiddenMsg={true}
                  targetKey="graphic"
                  discMiniProfile={discProfile}
                >
                  <Icon
                    className="directory-people-list-item-info-style-icon"
                    hasDropShadow={true}
                    icon={iconType}
                    gradient={iconGradient}
                    size={49}
                  />
                </DISCValueRender>
              ) : null}

              <p
                className={cx(
                  "directory-people-list-item-info-style-name",
                  profileUnavailable &&
                    "directory-people-list-item-info-style-unavailable"
                )}
              >
                {loading || profileUnavailable || !discProfile ? (
                  loading ? (
                    "Loading DISC..."
                  ) : (
                    <span className="directory-people-list-item-info-style-name-noprofile-msg">
                      DISC Style Not Found
                    </span>
                  )
                ) : (
                  <>
                    <span className="directory-people-list-item-info-style-name-text">
                      <DISCValueRender
                        className="directory-people-list-item-info-style-cont-item-wrapper"
                        isCompareValue={false}
                        omitHiddenMsg={true}
                        targetKey="styleName"
                        discMiniProfile={discProfile}
                      >
                        {discProfile.styleName3}
                      </DISCValueRender>
                    </span>
                    <DISCValueRender
                      className="directory-people-list-item-info-style-cont-item-wrapper"
                      isCompareValue={false}
                      omitHiddenMsg={true}
                      targetKey="styleKey"
                      discMiniProfile={discProfile}
                    >
                      <span
                        className="directory-people-list-item-info-style-name-letters"
                        style={
                          iconGradient && iconGradient.length >= 1
                            ? {
                                color: (iconGradient[1] || iconGradient[0])
                                  .color,
                                fontWeight: "bold",
                              }
                            : { fontWeight: "bold" }
                        }
                      >
                        ({discProfile.styleKey3})
                      </span>
                    </DISCValueRender>
                  </>
                )}
              </p>
            </DISCValueRender>
          ) : (
            <Message value="NA" />
          )}
        </div>
      </button>
    </li>
  );
};
