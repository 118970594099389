/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";

import { AppStoreProps } from "../../../data/stores/AppStore";
import { URL_BASE } from "../../../data/constants/";
import { Icon, TextButton, TextHighlighter } from "../../../components/common/";
import { DomainLogo } from "../../../components/modals/";

import { Tyto } from "../../../typings/tyto";

interface Props {
  AppStore: AppStoreProps;
  domainBillingInfo?: Tyto.BillingSummaryResp;
  curIdx: number;
  isTypicalBillingModel: boolean;
  searchTerm: string;
  subDomain: Tyto.Team;
  uploadURL?: string;
}

export default ({
  AppStore,
  domainBillingInfo,
  curIdx,
  isTypicalBillingModel,
  searchTerm,
  subDomain,
  uploadURL,
}: Props) => {
  const [showDetails, updateShowDetails] = React.useState(false);
  const [activeUsersCount, updateActiveUsersCount] = React.useState(() => {
    if (domainBillingInfo) {
      _.get(domainBillingInfo, "billingSummary[0].details.length", 0);
    } else {
      return 0;
    }
  });
  const [showDomainLogoModal, updateShowDomainLogoModal] =
    React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (domainBillingInfo) {
      updateActiveUsersCount(
        _.get(domainBillingInfo, "billingSummary[0].details.length", 0)
      );
    }
  }, [domainBillingInfo]);

  return (
    <li
      className={cx(
        "partner-dashboard-accounts-account",
        !isTypicalBillingModel && "account-nontypical",
        !!curIdx && "partner-dashboard-accounts-account-not-first-account"
      )}
    >
      <div className="partner-dashboard-accounts-account-top-cont">
        <Icon
          buttonProps={{
            className: "partner-dashboard-accounts-account-toggle-button",
          }}
          icon={showDetails ? "angle-down" : "angle-right"}
          isButton={true}
          onClick={() => updateShowDetails(!showDetails)}
          size={35}
        />

        <p className="partner-dashboard-accounts-account-name">
          <TextHighlighter
            highlightClassName=""
            searchTerm={searchTerm}
            text={subDomain.name}
            type="hightlight"
          />
          <br />
          <span className="partner-dashboard-accounts-account-bottom-top-label">
            account
          </span>
        </p>

        <div className="partner-dashboard-accounts-account-link">
          <TextButton
            iconProps={{
              className: "partner-dashboard-accounts-account-link-icon",
              icon: "external-link",
              size: 12,
            }}
            className="partner-dashboard-accounts-account-link-button"
            onClick={() => {
              if (AppStore.dispatch) {
                AppStore.dispatch({
                  callback: () => {
                    setTimeout(() => {
                      navigate("/directory");
                    }, 0);
                  },
                  payload: {
                    domainID: subDomain.teamID,
                  },
                  type: "SET_TARGET_DOMAINID",
                });
              }
            }}
            value="Go To Account"
          />
        </div>

        <div className="partner-dashboard-accounts-account-welcome-link">
          <TextButton
            iconProps={{
              className: "partner-dashboard-accounts-account-link-icon",
              icon: "external-link",
              size: 12,
            }}
            className="partner-dashboard-accounts-account-link-button"
            onClick={() => {}}
            value="Welcome Message"
          />
        </div>

        <div
          className="partner-dashboard-accounts-account-summary-item"
          style={{ marginLeft: "auto" }}
        >
          {isTypicalBillingModel && (
            <>
              <p className="partner-dashboard-accounts-account-summary-item-value">
                {activeUsersCount}
              </p>
              <p className="partner-dashboard-accounts-account-summary-item-label">
                <Icon icon="users" size={12} />
                Active Users
              </p>
            </>
          )}
        </div>

        <div className="partner-dashboard-accounts-account-summary-item">
          {isTypicalBillingModel ? (
            <>
              <p className="partner-dashboard-accounts-account-summary-item-value">
                + 0
              </p>
              <p className="partner-dashboard-accounts-account-summary-item-label">
                User Change (30 Days)
              </p>
            </>
          ) : (
            <p className="partner-dashboard-accounts-account-summary-item-label">
              <span>Incorrect Billing Type</span>
            </p>
          )}
        </div>

        {/* <div className="partner-dashboard-accounts-account-summary-item">
          <p className="partner-dashboard-accounts-account-summary-item-value">
            $0000.00
          </p>
          <p className="partner-dashboard-accounts-account-summary-item-label">
            Account Billed (Month Date)
          </p>
        </div> */}
      </div>

      {showDetails && (
        <div className="partner-dashboard-accounts-account-bottom-cont">
          <div className="partner-dashboard-accounts-account-bottom-top-cont">
            <div className="partner-dashboard-accounts-account-bottom-top-item-cont">
              <img
                className="partner-dashboard-accounts-account-bottom-top-logo"
                src={`https://${URL_BASE}/v2/domains/${subDomain.teamID}/images/home_link.png`}
              />

              <p className="partner-dashboard-accounts-account-bottom-top-label">
                <TextButton
                  onClick={() => updateShowDomainLogoModal(true)}
                  style={{
                    color: "inherit",
                    fontSize: "inherit",
                    textDecoration: "none",
                  }}
                  value="Logo"
                />
              </p>
            </div>

            <div className="partner-dashboard-accounts-account-bottom-top-item-cont">
              <p className="partner-dashboard-accounts-account-bottom-top-value">
                Plan Name
              </p>

              <p className="partner-dashboard-accounts-account-bottom-top-label">
                Plan
              </p>
            </div>

            <div className="partner-dashboard-accounts-account-bottom-top-item-cont">
              <p className="partner-dashboard-accounts-account-bottom-top-value">
                Admin UserThumbs
              </p>

              <p className="partner-dashboard-accounts-account-bottom-top-label">
                Admins
              </p>
            </div>

            <div className="partner-dashboard-accounts-account-bottom-top-item-cont">
              <p className="partner-dashboard-accounts-account-bottom-top-value">
                Creation Date
              </p>

              <p className="partner-dashboard-accounts-account-bottom-top-label">
                Active Since
              </p>
            </div>
          </div>

          <div className="partner-dashboard-accounts-account-bottom-bottom-cont">
            <TextButton
              className="partner-dashboard-accounts-account-link-button"
              onClick={() => {}}
              value="Edit"
            />
          </div>
        </div>
      )}

      {showDomainLogoModal && !!uploadURL && (
        <DomainLogo
          className=""
          domainID={subDomain.teamID}
          onAccountCreation={() => {}}
          onDismiss={() => updateShowDomainLogoModal(false)}
          uploadURL={uploadURL}
        />
      )}
    </li>
  );
};
