const dayInMs = 86400 * 1000;
const hourInMs = 3600 * 1000;
const minuteInMs = 60 * 1000;
const monthInMs = dayInMs * (365 / 12);

export function parseDateForText(date: string | Date) {
  const enforcedDate = new Date(date);
  const now = new Date();

  if (enforcedDate.getFullYear() === 1900) {
    {
      return "";
    }
  }

  const difference = +now - +enforcedDate;

  if (difference < dayInMs) {
    if (difference < minuteInMs * 2) {
      return "moments ago";
    }

    if (difference < hourInMs) {
      return `${Math.floor(difference / minuteInMs)} minutes ago`;
    }

    return `${Math.floor(difference / hourInMs)} hours ago`;
  } else if (difference < dayInMs * 61) {
    return `${Math.floor(difference / dayInMs)} days ago`;
  } else if (difference < monthInMs * 12) {
    return `${Math.round(difference / monthInMs)} Months ago`;
  }

  const asYears = Math.round(difference / (monthInMs * 12));

  return `${asYears} year${asYears > 1 ? "s" : ""} ago`;
}

export function parseDateForHover(date: string | Date) {
  const enforcedDate = new Date(date);
  //   const now = new Date();

  const hour = enforcedDate.getHours();
  const minutes = enforcedDate.getMinutes();
  const dayOfMonth = enforcedDate.getDate();
  const month = enforcedDate.getMonth();
  const year = enforcedDate.getFullYear();

  const formattedHour = hour + 1 > 12 ? hour + 1 - 12 : hour + 1;

  return `${formattedHour < 10 ? `0${formattedHour}` : formattedHour}:${
    minutes < 10 ? `0${minutes}` : minutes
  } ${hour + 1 >= 12 ? "PM" : "AM"} ${month + 1}/${dayOfMonth}/${year}`;
}
