/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Button, FileInput } from "../../common/";
import TytoCalls from "../../../data/tyto/";

interface Props {
  className?: string;
  domainID: number;
  onComplete?: () => void;
  uploadURL?: string;
}

export default (props: Props) => {
  const [uploading, updateUploading] = React.useState(false);
  const [error, updateError] = React.useState("");
  const [file, updateFile] = React.useState<File | Blob | null>(null);
  const [fileObjectURL, updateFileObjectURL] = React.useState("");

  if (!props.uploadURL) {
    return (
      <div>
        <h2>Loading Configuration...</h2>
      </div>
    );
  }

  return (
    <div className={cx("domainlogo-modal-inner-cont", props.className)}>
      <label>Select Photo</label>

      <FileInput
        className=""
        acceptedFileTypes="image/png"
        onChange={(files) => {
          if (Array.isArray(files) && files.length) {
            updateFile(files[0]);

            if (fileObjectURL) {
              URL.revokeObjectURL(fileObjectURL);
            }

            const objURL = URL.createObjectURL(files[0]);

            updateFileObjectURL(objURL);
          }
        }}
      />

      {/* {!!fileObjectURL && (
        <>
          <img
            style={{ maxHeight: "150px", maxWidth: "100%" }}
            src={fileObjectURL}
          />

          <span
            role="button"
            onClick={() => {
              updateFileObjectURL("");
              URL.revokeObjectURL(fileObjectURL);
            }}
            style={{ margin: "6px 0px" }}
          >
            Revoke Object URL
          </span>
        </>
      )} */}

      {error && (
        <p style={{ color: "red", fontSize: "11px", margin: "12px 0px" }}>
          {error}
        </p>
      )}

      <div className="newaccount-modal-inner-row-cont newaccount-modal-inner-buttons-cont">
        <Button
          className="newaccount-modal-inner-buttons-button"
          disabled={uploading}
          onClick={() => {
            updateError("");
            updateUploading(true);

            uploadPhoto({
              domainID: props.domainID,
              file,
              onError: (errMsg) => {
                updateUploading(false);
                updateError(errMsg);
              },
              onSuccess: (resp: any) => {
                updateUploading(false);

                if (props.onComplete) {
                  props.onComplete();
                }
              },
              uploadURL: props.uploadURL,
            });
          }}
          value={uploading ? "Uploading (this may take a while)..." : "Upload Image as Domain Logo"}
        />
      </div>
    </div>
  );
};

const uploadPhoto = async ({
  domainID,
  file,
  onError,
  onSuccess,
  uploadURL,
}: {
  domainID?: number;
  file: File | Blob | null;
  onError: (errMsg: string) => void;
  onSuccess: (resp: any) => void;
  uploadURL?: string;
}) => {
  if (!uploadURL || !file || !domainID) {
    onError("No Upload destination found.");
    return;
  }

  try {
    const resp = await TytoCalls.Upload.post({
      endpointURL: uploadURL,
      files: [file],
    });

    const imageUploadKey = _.get(resp, "uploadFiles[0].fileUploadKey", "");

    /*
     * types:
     * StartBackground gets weritten to DB,
     * Logo who knows
     * Styles gets written to stylesheet?
     */

    const domainResp = await TytoCalls.Domain.Image.put({
      domainID,
      imageUploadKey,
      targetFileName: `domain-logo`,
      type: "Logo",
    });
    debugger;

    onSuccess(null);
  } catch (err) {
    onError(`${err}`);
  }
};
