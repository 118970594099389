/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import {
  UserThumbPlaceholder,
  PlaceholderText
} from "../../../../components/common/";

interface Props {
  depth: number;
}

export default (props: Props) => {
  return (
    <>
      <li
        className={cx("admin-teams-team-structure-item")}
        style={{ paddingLeft: `${20 * props.depth - (props.depth ? 6 : 0)}px` }}
      >
        <div className={cx("admin-teams-team-structure-item-inner-cont")}>
          <button
            className="admin-teams-team-structure-item-title-cont"
            onClick={undefined}
          >
            <UserThumbPlaceholder
              className="admin-teams-team-structure-item-icon"
              size={16}
            />

            <p className="admin-teams-team-structure-item-name">
              <PlaceholderText generateText={true} value={"Placeholder Text"} />
            </p>
            {/* 
            {hasRuleException && (
              <Icon
                className="admin-teams-team-structure-item-rules-icon"
                icon="arrow-alt-from-top"
                size={12}
              />
            )} */}
          </button>
        </div>
      </li>
    </>
  );
};
