/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { calcGroupStyle } from "../utils/";
import {
  getLetterColor,
  getPersonValueRespectfully,
} from "../../../../../data/utils/helpers/";
import {
  Button,
  Checkbox,
  DISCPlotGraph,
  Icon,
  HeatMap,
  TextButton,
  ToggleSection,
} from "../../../../../components/common/";
import { DEFAULT_INTENSITY_COLORS } from "../../../../../components/common/heat-map/utils";
import { HeatMapModal } from "../../../../modals/";
import { Tyto } from "../../../../../typings/tyto";

import CustomIntensityColors, {
  IntensityColorsMap,
} from "./CustomIntensityColors";

import "./TeamGroupStyle.scss";

interface Props {
  discMini: Tyto.DISCProfileMini[];
  groupStyle: {
    label: string;
    value: number;
  }[];
  groupStyleKeyWithStdDeviation: string;
  setFocusedPerson: (personID: number) => void;
  showCustomizationOptions?: boolean;
  // teamContRef: React.MutableRefObject<null | HTMLElement>;
}

const DEFAULT_WIDTH = 368;

export default (props: Props) => {
  const [showCustomIntensityColors, updateShowCustomIntensityColors] =
    React.useState(false);
  const [showAsHeatMap, updateShowAsHeatMap] = React.useState(false);
  const [showAsStressed, updateShowAsStressed] = React.useState(false);
  const [showHeatMapModal, updateShowHeatMapModal] = React.useState(false);
  const [customColors, updateCustomsColors] =
    React.useState<IntensityColorsMap>(() => ({ ...DEFAULT_INTENSITY_COLORS }));
  //   const [groupStyle, updateGroupStyle] = React.useState(
  //     calcGroupStyle(props.discMini)
  //   );

  //   React.useEffect(() => {
  //     updateGroupStyle(calcGroupStyle(props.discMini));
  //   }, [props.discMini]);

  return (
    <ToggleSection
      className=""
      title="Group Style"
      renderFunction={(isExpanded, sectionWrapperRef) => {
        const [sectionWrapperWidth, updateSectionWrapperWidth] = React.useState(
          getSectionWidth(sectionWrapperRef)
        );
        React.useEffect(() => {
          updateSectionWrapperWidth(getSectionWidth(sectionWrapperRef));
        }, [sectionWrapperRef]);

        return !isExpanded ? (
          <span style={{ visibility: "hidden" }} />
        ) : (
          <>
            <h1 className="meta-sidepane-team-group-style-title">
              {props.groupStyle.map(({ label, value }) =>
                value > 0 &&
                props.groupStyleKeyWithStdDeviation.includes(label) ? (
                  <span
                    className="meta-sidepane-team-group-style-title-letter"
                    key={label}
                    style={{
                      color: props.groupStyleKeyWithStdDeviation.includes(label)
                        ? getLetterColor(label)
                        : "#9e9e9e",
                      position: "relative",
                    }}
                  >
                    {label.toUpperCase()}
                    {/* <span className="meta-sidepane-team-group-style-title-letter-value">
                      {value.toFixed(1)}
                    </span> */}
                  </span>
                ) : (
                  <span key={label} style={{ visibility: "hidden" }} />
                )
              )}
            </h1>

            {sectionWrapperWidth && (
              <>
                {showAsHeatMap ? (
                  <HeatMap
                    heatMapType={showAsStressed ? 2 : 3}
                    profiles={getValidMinis(props.discMini)}
                    onUserSelect={(userID) => props.setFocusedPerson(userID)}
                    size={sectionWrapperWidth}
                    intensityColorsOverride={customColors}
                  />
                ) : (
                  <DISCPlotGraph
                    canSelectUser={true}
                    size={sectionWrapperWidth}
                    groupStyle={getGroupStyle({
                      discMinis: props.discMini,
                      showAsStressed,
                    })}
                  />
                )}

                <div className="disc-heatmap-btns meta-sidepane-team-group-style-toggle-buttons-cont">
                  <Button
                    className={
                      "cc-disc-heatmap-btn" +
                      (!showAsHeatMap ? " cc-disc-heatmap-btn-selected" : "")
                    }
                    type={!showAsHeatMap ? "color" : "hollow"}
                    onClick={() => updateShowAsHeatMap(false)}
                    value="Plot Graph"
                  />
                  <Button
                    className={
                      "cc-disc-heatmap-btn" +
                      (showAsHeatMap ? " cc-disc-heatmap-btn-selected" : "")
                    }
                    onClick={() => updateShowAsHeatMap(true)}
                    type={showAsHeatMap ? "color" : "hollow"}
                    value="Heat Map"
                  />
                </div>

                <div className="disc-heatmap-btns meta-sidepane-team-group-style-stressed-toggle-cont">
                  <Checkbox
                    className="meta-sidepane-team-group-style-stressed-toggle"
                    checked={showAsStressed}
                    onCheck={(newVal) => updateShowAsStressed(newVal)}
                    size={16}
                  />

                  <TextButton
                    className="meta-sidepane-team-group-style-stressed-toggle-text"
                    onClick={() => updateShowAsStressed(!showAsStressed)}
                    value="Show Under Stress"
                  />

                  {/* {showAsHeatMap && (
                    <button
                      className="meta-sidepane-team-group-style-openinmodal-button"
                      onClick={() => updateShowHeatMapModal(!showHeatMapModal)}
                    >
                      <Icon
                        className="meta-sidepane-team-group-style-openinmodal-button-icon"
                        icon="external-link"
                        size={14}
                      />
                    </button>
                  )} */}
                </div>

                {props.showCustomizationOptions && (
                  <div className="disc-heatmap-custom-opts-cont">
                    <h2 className="disc-heatmap-custom-opts-title">
                      Custom Intensity Colors
                      <TextButton
                        className="disc-heatmap-custom-opts-toggle-btn"
                        onClick={() =>
                          updateShowCustomIntensityColors(
                            !showCustomIntensityColors
                          )
                        }
                        value={showCustomIntensityColors ? "Hide" : "Show"}
                      />
                    </h2>

                    {showCustomIntensityColors && (
                      <CustomIntensityColors
                        inensityColors={customColors}
                        updateCustomMap={(newIntensityColors) =>
                          updateCustomsColors(newIntensityColors)
                        }
                      />
                    )}
                  </div>
                )}

                {/* <HeatMapModal
                  isOpen={showHeatMapModal}
                  discMinis={props.discMini}
                  onDismiss={() => updateShowHeatMapModal(false)}
                /> */}
              </>
            )}
          </>
        );
      }}
    />
  );
};

const getGroupStyle = ({
  discMinis,
  showAsStressed,
}: {
  discMinis?: Tyto.DISCProfileMini[];
  showAsStressed: boolean;
}) => {
  // debugger;
  if (!discMinis) {
    return [];
  }

  const filtered = discMinis.filter(
    (mini) =>
      !!mini.styleKey3 &&
      !_.get(
        getPersonValueRespectfully({ key: "d3", discMiniProfile: mini }),
        "permitItem.HIDE",
        true
      )
  );

  return filtered.map((discProfile) => ({
    d: discProfile[showAsStressed ? "d2" : "d3"],
    i: discProfile[showAsStressed ? "i2" : "i3"],
    s: discProfile[showAsStressed ? "s2" : "s3"],
    c: discProfile[showAsStressed ? "c2" : "c3"],
    personName: discProfile.personName,
    asset: discProfile.profileImageAsset,
  }));
};

const getValidMinis = (discMinis: Tyto.DISCProfileMini[]) => {
  return discMinis.filter(
    (discMini) =>
      !!discMini.styleKey3 &&
      !_.get(
        getPersonValueRespectfully({ key: "d3", discMiniProfile: discMini }),
        "permitItem.HIDE",
        true
      )
  );
};

const getSectionWidth = (
  sectionContRef: React.MutableRefObject<null | HTMLElement>
) => {
  //   debugger;
  if (sectionContRef.current) {
    const info = sectionContRef.current.getBoundingClientRect();
    return info.width;
  }

  return 0;
};

// const getTeamContWidth = (
//     teamContRef: React.MutableRefObject<null | HTMLElement>
// ) => {
//     if (teamContRef.current) {
//         const info = teamContRef.current.getBoundingClientRect();
//         // debugger;
//         return info.width;
//     }

//     return 0;
// };
