/*
 * Component Description
 */
import * as React from "react";

import SidePane, { Props as SidePaneProps } from "./";
import SidePaneModal from "./SidePaneModal";

export default (props: SidePaneProps) => {
  if (props.asModal) {
    return <SidePaneModal {...props} />;
  }

  return <SidePane {...props} />;
};
