import * as _ from "lodash";

import { Tyto } from "../../../../typings/tyto";
import WebSocketClient from "../../../../data/websocket/";
import { DEFAULT_TEAMTOOLS_USER_PERMISSIONS } from "../../../../data/constants/";
import TytoCalls from "../../../../data/tyto/";

// export function getStatus(styleR3?: string) {
//   if (!styleR3) {
//     return "incomplete assessment";
//   }

//   return "";
// }
export function getStatus({
  teamToolsPermit,
  discStatus,
}: {
  teamToolsPermit: Tyto.TeamToolsPermissions;
  styleKey3?: string;
  discStatus: Tyto.DISCProfileMini["discStatus"];
}) {
  // if (!styleKey3 && !_.get(teamToolsPermit, "mayTakeDisc", false)) {
  //   return "cannot take assessment";
  // } else if (!styleKey3) {
  //   return "assessment incomplete";
  // } else if (!_.get(teamToolsPermit, "hasBasicViewDisc", false)) {
  //   return "pending";
  // }

  switch (discStatus) {
    case "ocDISABlED":
      return "Disabled";
    case "ocENABLED":
      return "Complete";
    case "ocNOTSTARTED":
      if (!_.get(teamToolsPermit, "mayTakeDisc", false)) {
        return "Cannot Take Assessment";
      }

      return "Not Started";
    case "ocPENDING":
      return "Started";
    default:
      return "";
  }
}

export function getTeamConfigValues(
  team: Tyto.TeamGet
): {
  onInitialize: Tyto.TeamToolsPermissions;
  onDiscComplete: Tyto.TeamToolsPermissions;
  onBasicTrainingComplete: Tyto.TeamToolsPermissions;
  onAdvTrainingComplete: Tyto.TeamToolsPermissions;
} {
  return {
    onInitialize: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig && team.teamToolsConfig.onInitialize) || {}),
    },
    onDiscComplete: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig && team.teamToolsConfig.onDiscComplete) || {}),
    },
    onBasicTrainingComplete: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig &&
        team.teamToolsConfig.onBasicTrainingComplete) ||
        {}),
    },
    onAdvTrainingComplete: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig &&
        team.teamToolsConfig.onAdvTrainingComplete) ||
        {}),
    },
  };
}

export function teamHasConfigOverrides(team: Tyto.Team) {
  return (
    !!team.onInitialize ||
    !!team.onDiscComplete ||
    !!team.onBasicTrainingComplete ||
    !!team.onAdvTrainingComplete
  );
}

const getConfigValues = (discMini?: Tyto.DISCProfileMini) => {
  return {
    ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
    ...((discMini && discMini.teamToolsPermit) || {}),
  };
};

export async function grantPermissionToUsers({
  teamMembers,
  keys,
  onError,
  onSuccess,
}: {
  teamMembers: Tyto.DISCProfileMini[];
  keys: {
    mayTakeDisc: boolean;
    hasBasicViewDisc: boolean;
  };
  onSuccess: (resps: { personID: number; updated: boolean }[]) => void;
  onError: (msg: string) => void;
}) {
  if (!teamMembers || !teamMembers.length) {
    onError("");
    alert("No Users");
    return;
  }

  try {
    const { mayTakeDisc, hasBasicViewDisc } = keys;
    const filteredUsers = teamMembers.filter(
      (teamMember) =>
        (mayTakeDisc
          ? !_.get(teamMember, `teamToolsPermit.mayTakeDisc`)
          : false) ||
        (hasBasicViewDisc
          ? !_.get(teamMember, `teamToolsPermit.hasBasicViewDisc`)
          : false)
    );

    if (!filteredUsers.length) {
      onError("");
      alert("None of the users require an update.");
      return;
    }

    const calls = filteredUsers.map((user) =>
      grantPermission({ personID: user.personID, discMini: user, keys })
    );

    const resps = await Promise.all(calls);

    const personIDsWhoWereUpdated = resps
      .filter((resp) => resp.updated)
      .map((resp) => resp.personID);

    if (personIDsWhoWereUpdated && personIDsWhoWereUpdated.length) {
      WebSocketClient.announceUpdates(personIDsWhoWereUpdated);
    }

    onSuccess(resps);
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            "An Error occurred while updating permissions."
          )
    );
  }
}

export async function sendEmailInviteLinksToUsers({
  teamMembers,
  onError,
  onSuccess,
}: {
  teamMembers: Tyto.DISCProfileMini[];
  onSuccess: (
    resps: {
      recordsAffected: number;
      emailQueueID: string;
      emailKey: string;
      tempSessionKey: string;
    }[]
  ) => void;
  onError: (msg: string) => void;
}) {
  if (!teamMembers || !teamMembers.length) {
    onError("");
    alert("No Users");
    return;
  }

  try {
    const calls = teamMembers.map((user) =>
      TytoCalls.TeamToolsInvite.Email.post({ memberID: user.personID })
    );

    const resps = await Promise.all(calls);

    onSuccess(resps);
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            "An Error occurred while sending Email Invites."
          )
    );
  }
}

export async function sendResultsEmailToUsers({
  omittedUsers,
  teamMembers,
  onError,
  onSuccess,
}: {
  omittedUsers: Tyto.DISCProfileMini[];
  teamMembers: Tyto.DISCProfileMini[];
  onSuccess: (
    resps: {
      recordsAffected: number;
      emailQueueID: string;
      emailKey: string;
      tempSessionKey: string;
    }[]
  ) => void;
  onError: (msg: string) => void;
}) {
  if (!teamMembers || !teamMembers.length) {
    onError("");
    alert("None of the selected users has the ability ");
    return;
  }

  if (omittedUsers && omittedUsers.length) {
    const userNames = omittedUsers.map((user) => `${user.personName}`);
    const omittedNamesFormatted =
      userNames.length > 5 ? `${userNames.length} users` : userNames.join(", ");

    alert(
      `${omittedNamesFormatted} do not have ability view DISC information and/or have not completed their assessment. The other ${
        teamMembers.length
      } user${teamMembers.length === 1 ? "" : "s"} will be sent an email`
    );
  }

  try {
    const calls = teamMembers.map((user) =>
      TytoCalls.DISCProfiles.EmailViewReady.post({ memberID: user.personID })
    );

    const resp = await Promise.all(calls);

    debugger;

    onSuccess(Array.isArray(resp) ? (resp as any[]) : []);
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            "An Error occurred while sending Results Emails."
          )
    );
  }
}

export async function grantPermission({
  discMini,
  personID,
  keys,
}: {
  personID: number;
  discMini: Tyto.DISCProfileMini;
  keys: {
    mayTakeDisc: boolean;
    hasBasicViewDisc: boolean;
  };
}) {
  if (!personID) {
    return {
      personID,
      updated: false,
    };
  }

  try {
    const onlyTrueKeys = {
      ...(keys.mayTakeDisc ? { mayTakeDisc: true } : {}),
      ...(keys.hasBasicViewDisc ? { hasBasicViewDisc: true } : {}),
    };
    const newTeamToolsPermit = {
      ...getConfigValues(discMini),
      ...onlyTrueKeys,
    };
    const resp = await TytoCalls.DISCProfile.Mini.put({
      personID,
      teamToolsPermit: newTeamToolsPermit,
    });

    return {
      personID,
      updated: true,
    };
  } catch (err) {
    return {
      personID,
      updated: false,
    };
  }
}

export async function loadDomainMessages({
  domainID,
  onError,
  onSuccess,
}: {
  domainID: number;
  onSuccess: (welcomeMessage: Tyto.Domain.Message[]) => void;
  onError: (msg: string) => void;
}) {
  if (!domainID && domainID !== 0) {
    onSuccess([]);
  }

  try {
    const resp = await TytoCalls.Domain.Messages.get({
      domainID,
    });

    // debugger;
    onSuccess(resp.domainMessages || []);
  } catch (err) {
    onError(
      typeof err == "string"
        ? err
        : _.get(err, "error.msg", "Could not Load Domain Welcome Message")
    );
  }
}

export async function getDomainMessage({
  domainID,
  messageType,
  forceDomain,
  onError,
  onSuccess,
}: {
  domainID: number;
  messageType: string;
  forceDomain?: boolean;
  onSuccess: (welcomeMessage: Tyto.Domain.Message | null) => void;
  onError: (msg: string) => void;
}) {
  if (!domainID && domainID !== 0) {
    return null;
  }

  try {
    const curDomainMessage = await loadDomainMessage({
      domainID,
      messageType,
      onError,
      onSuccess,
    });

    if (curDomainMessage || forceDomain || domainID === 0) {
      onSuccess(curDomainMessage || null);
      return;
    }

    const domain0Message = await loadDomainMessage({
      domainID: 0,
      messageType,
      onError,
      onSuccess,
    });

    onSuccess(domain0Message || null);
    // debugger;
  } catch (err) {
    onError(
      typeof err == "string"
        ? err
        : _.get(err, "error.msg", "Could not Load Domain Welcome Message")
    );
  }
}

export async function loadDomainMessage({
  domainID,
  messageType,
  onError,
  onSuccess,
}: {
  domainID: number;
  messageType: string;
  onSuccess: (welcomeMessage: Tyto.Domain.Message | null) => void;
  onError: (msg: string) => void;
}) {
  if (!domainID && domainID !== 0) {
    return;
  }

  try {
    const resp = await TytoCalls.Domain.Messages.get({
      domainID,
      messageType,
    });

    // debugger;
    return resp.domainMessages[0] || null;
  } catch (err) {
    onError(
      typeof err == "string"
        ? err
        : _.get(err, "error.msg", "Could not Load Domain Welcome Message")
    );
  }
}

export async function saveDomainMessage({
  data,
  messageType,
  onError,
  onSuccess,
}: {
  data: {
    domainID: number;
    headLine?: string;
    bodyPlain?: string;
    bodyHtml?: string;
  };
  messageType: string;
  onSuccess: (welcomeMessage: Tyto.Domain.Message | null) => void;
  onError: (msg: string) => void;
}) {
  if (
    (!data.domainID && data.domainID !== 0) ||
    (data.headLine === undefined &&
      data.bodyPlain === undefined &&
      data.bodyHtml === undefined)
  ) {
    return;
  }

  try {
    const resp = await TytoCalls.Domain.Message.put({
      ...data,
      messageType,
    });

    await loadDomainMessage({
      domainID: data.domainID,
      messageType,
      onError,
      onSuccess,
    });
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(err, "error.msg", "Could not Save updates to Welcome Message.")
    );
  }
}
