/*
 * Renders Top level NavBar
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";
import { useNavigate } from "react-router-dom";

import { StoreContext } from "../../../data/stores/AppStore";
import { StoreContext as AdminStoreContext } from "../../../interfaces/admin/data/stores/AdminStore";
import {
  getLoggedInUserID,
  getSessionsAsArray,
  removeSessionData,
} from "../../../data/storage/session";
import { Icon } from "../../common/";
import { iconType } from "../../common/icon/typings/";
import { Tyto } from "../../../typings/tyto";
import MenuItem from "./subcomponents/MenuItem";
import NavFooter from "./subcomponents/NavFooter";
import NavUserInfo from "./subcomponents/NavUserInfo";
import Notifications from "./subcomponents/Notifications";

import "./style.scss";
import { Domain } from "domain";

interface Option {
  icon: iconType;
  text: string;
  to: string;
}

const options: Option[] = [
  // {
  //     icon: "users",
  //     text: "My Team",
  //     to: "/home"
  // },
  {
    icon: "id-card",
    text: "Directory",
    to: "/directory",
  },
];

interface Props {
  onMenuButtonClick?: () => void;
  path?: string;
  uri?: string;
}

export default (props: Props) => {
  let store = React.useContext(StoreContext);
  let AdminStore = React.useContext(AdminStoreContext);

  const navigate = useNavigate();

  const [sessionsArray, updateSessionsArray] = React.useState(
    getSessionsAsArray()
  );
  const [personInfo, updatePersonInfo] = React.useState<
    Tyto.Person | undefined
  >(_.get(store, "state.personInfo", undefined));
  const [personalDiscMini, updatePersonalDiscMini] = React.useState(() => {
    if (store.state && store.state.userDISCMini) {
      return store.state.userDISCMini;
    }

    return undefined;
  });
  const [teamMemberships, updateTeamMemberships] = React.useState<
    Tyto.TeamMembership[]
  >(_.get(store, "state.personInfo.teamMemberships", []));
  const [teamRoot, updateTeamRoot] = React.useState(() =>
    getTeamRoot({ personInfo, teamMemberships })
  );
  const [showAll, updateShowAll] = React.useState(false);

  // * [E-1] - Update PErsonal Info
  React.useEffect(() => {
    console.log("PersonInfo change Triggered in Nav");
    const newPersonInfo = _.get(store, "state.personInfo", undefined);
    const newTeamMemberships: Tyto.TeamMembership[] = _.get(
      store,
      "state.personInfo.teamMemberships",
      []
    );

    updatePersonInfo(newPersonInfo);
    updateTeamMemberships(newTeamMemberships);
    updateTeamRoot(
      getTeamRoot({
        personInfo: newPersonInfo,
        teamMemberships: newTeamMemberships,
      })
    );
  }, [_.get(store, "state.personInfo")]);

  // * [E-2] - Update personal Disc Mini info
  React.useEffect(() => {
    // debugger;
    const newPersonalDiscMini = _.get(store, "state.userDISCMini", undefined);

    if (
      // (newPersonalDiscMini && !personalDiscMini)
      !(!newPersonalDiscMini && !personalDiscMini)
    ) {
      updatePersonalDiscMini(newPersonalDiscMini);

      // ! Probably not best place to put this ?
      updateSessionsArray(getSessionsAsArray());
    }
  }, [_.get(store, "state.userDISCMini", undefined)]);

  // * ===============================================
  // * End of Effects ================================
  // * ===============================================

  const userHasManage = _.get(store, "state.userHasManage", false);
  const isMemberOfMultipleTeams = teamMemberships && teamMemberships.length > 1;
  const curDomainID = _.get(store, "state.curDomainID", 0);
  const userDomainType = _.get(
    store,
    "state.userDomain.domainType",
    ""
  ) as keyof typeof Tyto.DomainType;
  const showMenuItems = userDomainType && userDomainType !== "ocTEAMTOOLSCOACH";

  return (
    <nav className={cx("meta-nav", getInterfaceClass(props.path))}>
      <ul className="meta-nav-list">
        <NavUserInfo
          AppStore={store}
          curDomainID={curDomainID}
          curDomainInfo={_.get(
            AdminStore,
            `state.teamsByTeamID[${curDomainID}]`,
            undefined
          )}
          personalDiscMini={personalDiscMini}
          personInfo={personInfo}
          sessionsArray={sessionsArray}
          updateSessionsArray={updateSessionsArray}
          userHasManage={userHasManage}
        />

        {showMenuItems && (
          <>
            {teamRoot && (
              <li
                className={cx(
                  "meta-nav-list-item",
                  props.uri &&
                    props.uri === `/team/${teamRoot.TeamID}` &&
                    "active-list-item"
                )}
                key={teamRoot.TeamID}
              >
                <Icon
                  className="meta-nav-list-item-icon"
                  icon="users"
                  size={23}
                />

                <button
                  className="meta-nav-team-link"
                  onClick={
                    isMemberOfMultipleTeams
                      ? () => updateShowAll(!showAll)
                      : () => {
                          if (props.onMenuButtonClick) {
                            props.onMenuButtonClick();
                          }

                          navigate(`/team/${teamRoot.TeamID}`);
                        }
                  }
                >
                  <p className="meta-nav-list-item-text title-bold">
                    {isMemberOfMultipleTeams ? "My Teams" : "My Team"}
                  </p>
                </button>

                {isMemberOfMultipleTeams && (
                  <Icon
                    className="meta-nav-list-item-teams-toggle-button"
                    icon={showAll ? "angle-down" : "angle-left"}
                    isButton={true}
                    onClick={() => updateShowAll(!showAll)}
                    size={20}
                  />
                )}
              </li>
            )}

            {showAll &&
              teamMemberships.map((teamMembership) =>
                !isMemberOfMultipleTeams &&
                teamRoot &&
                teamRoot.TeamID === teamMembership.TeamID ? null : (
                  <MenuItem
                    key={teamMembership.TeamID}
                    uri={props.uri}
                    to={`/team/${teamMembership.TeamID}`}
                    text={teamMembership.teamName}
                  />
                )
              )}

            {options.map((option) => (
              <MenuItem
                key={option.to}
                onMenuButtonClick={props.onMenuButtonClick}
                uri={props.uri}
                {...option}
              />
            ))}

            {userHasManage && (
              <MenuItem
                key="admin"
                onMenuButtonClick={props.onMenuButtonClick}
                to={"/admin"}
                uri={props.uri}
                icon="cog"
                text="Admin"
              />
            )}

            {userHasManage &&
              props.path &&
              props.path.includes("/partner-dashboard") && (
                <MenuItem
                  key="partner-dashboard"
                  onMenuButtonClick={props.onMenuButtonClick}
                  to="/partner-dashboard"
                  uri={props.uri}
                  icon="table"
                  text="Partner Dashboard"
                />
              )}
          </>
        )}

        {!showMenuItems && userDomainType === "ocTEAMTOOLSCOACH" && (
          <li className="meta-nav-list-item-nosocial-msg">
            <div className="meta-nav-list-item-nosocial-lock-cont">
              <Icon
                className="meta-nav-list-item-nosocial-lock"
                size={22}
                icon="lock-alt"
              />
            </div>

            <p className="meta-nav-list-item-nosocial-msg-text">
              Your Account does not allow interaction or lookup of other users.
            </p>
          </li>
        )}
      </ul>

      {/* <Notifications /> */}

      <NavFooter
        logout={() => {
          const loggedInUserID = getLoggedInUserID();

          removeSessionData(loggedInUserID, () => {
            if (store.dispatch) {
              store.dispatch({
                payload: {},
                type: "CLEAR_USER_SESSION_DATA",
              });
            }
          });
        }}
        rootDomain={_.get(personInfo, "domainID", 0)}
      />
    </nav>
  );
};

const getInterfaceClass = (path?: string) => {
  switch (path) {
    case "/partner-dashboard":
      return "menu-for-partner-dashboard";
    default:
      return "";
  }
};

const getTeamRoot = ({
  personInfo,
  teamMemberships,
}: {
  personInfo?: Tyto.Person;
  teamMemberships: Tyto.TeamMembership[];
}) => {
  if (!personInfo || !teamMemberships.length) {
    return undefined;
  }

  return teamMemberships.find(({ TeamID }) => TeamID === personInfo.teamRoot);
};
