/*
 * Component Description
 */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import * as _ from "lodash";

import { SidePane } from "../../components/meta/";
import {
  StoreContext,
  StoreState,
  AppStoreProps,
} from "../../data/stores/AppStore";
import {
  StoreContext as AdminStoreContext,
  AdminStoreProps,
} from "../admin/data/stores/AdminStore";
import {
  StoreContext as DirectoryStoreContext,
  DirectoryProps,
} from "./data/stores/DirectoryStore";
import { loadTeamsWithTeamToolsConfig } from "../admin/data/utils/";
import { StoreContext as GeneralStoreContext } from "../../data/stores/GeneralStore";
import DummyLoadingPersonListItem from "../teams/subcomponents/DummyLoadingPersonListItem";
import { Icon, Message } from "../../components/common/";
import { searchOpts } from "./utils/";

import {
  getDirectorySearchRecents,
  getSearchKeyParams,
  getSelectedUsersKey,
  getSearchStoreKey,
  loadCompareProfile,
  loadDiscMinis,
  loadRecents,
  searchForPeople,
} from "./utils/";
import SearchBar from "./subcomponents/search-bar/";
import PersonResult from "./subcomponents/searchResultPerson";

import "./style.scss";
import { Tyto } from "../../typings/tyto";

const filterOptions = [
  {
    label: "All Results",
    value: "all",
  },
  {
    label: "Completed DISC",
    value: "disc-complete",
  },
  {
    label: "Missing DISC Data",
    value: "disc-incomplete",
  },
];

// interface SearchResults {
//   [x: string]: Tyto.AdvancedPerson[];
// }

interface Props {}

export default (props: Props) => {
  let AppStore = React.useContext(StoreContext);
  let AdminStore = React.useContext(AdminStoreContext);
  let DirectoryStore = React.useContext(DirectoryStoreContext);
  let GeneralStore = React.useContext(GeneralStoreContext);

  // const [activeSearchTerm, updateActiveSearchTerm] = React.useState("");
  const [error, updateError] = React.useState("");
  const [filter, updateFilter] = React.useState("all");
  const [searchType, updateSearchType] = React.useState(searchOpts[0].value);
  const [loadingPeople, updateLoadingPeople] = React.useState(false);
  // const [searchResults, updateSearchResults] = React.useState<SearchResults>(
  //   {}
  // );
  const [searchTerm, updateSearchTerm] = React.useState(
    _.get(
      DirectoryStore,
      `state.activeSearchTerms[${getSelectedUsersKey(
        getSearchKeyParams(AppStore, "")
      )}]`,
      ""
    )
  );
  const [previewUser, updatePreviewUser] = React.useState<
    Tyto.DISCProfileMini | undefined
  >(undefined);

  const discMiniNull = AppStore.state?.discDataNull || [];
  // const discMiniNull: StoreState["discMiniNull"] = _.get(
  //   AppStore,
  //   "state.discMiniNull",
  //   {}
  // );
  const discMiniProfiles: StoreState["discMini"] = _.get(
    AppStore,
    "state.discMini",
    {}
  );

  const activeSearchTerm = _.get(
    DirectoryStore,
    `state.activeSearchTerms[${getSelectedUsersKey(
      getSearchKeyParams(AppStore, "")
    )}]`,
    ""
  );
  const curDomainID = _.get(AppStore, "state.curDomainID", 0);
  const allPeople = DirectoryStore.state?.allPeople || {};
  // const allPeople = _.get(DirectoryStore, "state.allPeople", {});
  const searchKeyParams = getSearchKeyParams(AppStore, activeSearchTerm);
  const curSearchResultsPreFilter =
    DirectoryStore.state?.people?.[getSearchStoreKey(searchKeyParams)];
  // const curSearchResultsPreFilter = _.get(DirectoryStore, "state.people", {})[
  //   getSearchStoreKey(searchKeyParams)
  // ];
  const curSearchResults = Array.isArray(curSearchResultsPreFilter)
    ? curSearchResultsPreFilter.filter(
        (person) => person.domainID === curDomainID
      )
    : undefined;
  const toggleSelectedUsersKey = getSelectedUsersKey(searchKeyParams);
  const curSelectedIDs: { [x: number]: boolean } = _.get(
    DirectoryStore,
    `state.selectedUsers.${toggleSelectedUsersKey}`,
    {}
  );
  const selectedUsers: Tyto.DISCProfileMini[] = Object.keys(curSelectedIDs)
    .filter((userID) => curSelectedIDs[parseInt(`${userID}`)])
    .map((userID) => discMiniProfiles[parseInt(`${userID}`)]);

  const navigate = useNavigate();

  // * [E-1]
  React.useEffect(() => {
    if (Array.isArray(selectedUsers) && selectedUsers.length === 1) {
      const personID: number = _.get(selectedUsers, "[0].personID", 0);

      const previewPersonCompareGraph = _.get(
        AppStore,
        `state.discCompareProfiles.${personID}`
      );

      if (!previewPersonCompareGraph) {
        loadCompareProfile(AppStore, personID);
      }
    }

    if (previewUser) {
      const previewUserCompareGraph = _.get(
        AppStore,
        `state.discCompareProfiles.${previewUser.personID}`
      );

      if (!previewUserCompareGraph) {
        loadCompareProfile(AppStore, previewUser.personID);
      }
    }
  }, [selectedUsers, previewUser]);

  // * [E-2] - Loads recents
  React.useEffect(() => {
    if (curDomainID) {
      const recents = getDirectorySearchRecents(curDomainID);

      if (DirectoryStore.dispatch) {
        DirectoryStore.dispatch({
          payload: {
            userIDs: recents,
          },
          type: "DIRECTORY_SEARCH_UPDATE_RECENTS_USERIDS",
        });
      }

      loadRecents({
        AppStore,
        userIDs: recents,
      });
    }
  }, [curDomainID]);

  const userHasManage = _.get(AppStore, "state.userHasManage", undefined);
  const isMobile = _.get(GeneralStore, "state.isMobile", false);
  // * Type is `number[]` but I think this is actuall `AdvancedPerson[]`
  const recents: any[] = DirectoryStore.state?.recents || [];
  // const recents = _.get(DirectoryStore, "state.recents", []);
  const teams = _.get(AdminStore, "state.teamsByTeamID", undefined);
  const teamsWithConfiguration = _.get(
    AdminStore,
    "state.teamsWithConfiguration",
    undefined
  );
  const memberships = _.get(
    AdminStore,
    "state.membershipsByPersonID",
    undefined
  );

  return (
    <>
      <section className="directory-content-main-cont side-pane-open">
        <div className="directory-content-inner-cont">
          <h1 className="directory-content-header title-bold">
            Directory Search
          </h1>

          <SearchBar
            className="directory-content-search-bar"
            filter={filter}
            filterOptions={filterOptions}
            hideClear={false}
            noSpace={true}
            searchTerm={searchTerm}
            searchType={searchType}
            searchTypeOptions={searchOpts}
            searchButton={{
              disabled: loadingPeople || !searchTerm,
              onClick: () => {
                startSearchPeople({
                  AppStore,
                  DirectoryStore,
                  searchTerm,
                  updateLoadingPeople,
                });
                if (DirectoryStore.dispatch) {
                  DirectoryStore.dispatch({
                    payload: {
                      activeSearchTerm: searchTerm,
                      key: toggleSelectedUsersKey,
                    },
                    type: "DIRECTORY_SEARCH_UPDATE_ACTIVE_SEARCH_TERM",
                  });
                }
                // updateActiveSearchTerm(searchTerm);
              },
              shape: "square",
              type: "hollow",
              value: loadingPeople ? "Searching..." : "Search",
            }}
            updateFilter={(newFilter) => updateFilter(newFilter)}
            updateSearchType={(newSearchType) =>
              updateSearchType(newSearchType)
            }
            updateSearchTerm={(newSearchTerm) =>
              updateSearchTerm(newSearchTerm)
            }
          />

          <div className="directory-people-list-cont">
            <ul className="directory-people-list">
              {loadingPeople ? (
                <>
                  <DummyLoadingPersonListItem showCheckbox={true} />
                  <DummyLoadingPersonListItem showCheckbox={true} />
                  <DummyLoadingPersonListItem showCheckbox={true} />
                  <DummyLoadingPersonListItem showCheckbox={true} />
                  <DummyLoadingPersonListItem showCheckbox={true} />
                </>
              ) : Array.isArray(curSearchResults) &&
                !!curSearchResults.length ? (
                curSearchResults.map((person) => (
                  <PersonResult
                    key={person.userID}
                    AppStore={AppStore}
                    discProfile={discMiniProfiles[person.userID]}
                    forceCheckBoxSpace={true}
                    hide={shouldHide({
                      discProfile: discMiniProfiles[person.userID],
                      loading:
                        !discMiniProfiles[person.userID] &&
                        !discMiniNull.some(
                          (userID) => `${userID}` === `${person.userID}`
                        ),
                      profileUnavailable:
                        !discMiniProfiles[person.userID] &&
                        discMiniNull.some(
                          (userID) => `${userID}` === `${person.userID}`
                        ),
                      filter,
                    })}
                    isSelected={curSelectedIDs[person.userID]}
                    loading={
                      !discMiniProfiles[person.userID] &&
                      !discMiniNull.some(
                        (userID) => `${userID}` === `${person.userID}`
                      )
                    }
                    previewUser={(userID) => {
                      const discProfile = discMiniProfiles[person.userID];

                      if (discProfile) {
                        updatePreviewUser(discProfile);
                      }
                    }}
                    profileUnavailable={
                      !discMiniProfiles[person.userID] &&
                      discMiniNull.some(
                        (userID) => `${userID}` === `${person.userID}`
                      )
                    }
                    person={person}
                    showCheckbox={!!discMiniProfiles[person.userID]}
                    toggleUserID={(userID) => {
                      if (DirectoryStore.dispatch) {
                        DirectoryStore.dispatch({
                          payload: {
                            key: toggleSelectedUsersKey,
                            userID,
                          },
                          type: "DIRECTORY_SEARCH_TOGGLE_SELECTED_USER",
                        });
                      }
                    }}
                  />
                ))
              ) : activeSearchTerm ? (
                <h3 className="directory-people-no-results-msg">
                  No Results found matching {activeSearchTerm}
                </h3>
              ) : null}
            </ul>
          </div>

          {!activeSearchTerm && (
            <>
              <div
                className="directory-people-selected-users-title-cont"
                style={{ paddingTop: "20px" }}
              >
                <h3 className="directory-people-selected-users-title title-bold">
                  Recents
                </h3>
                <hr className="directory-people-selected-users-title-line" />
              </div>

              <div
                className="directory-people-list-cont"
                style={{ position: "relative" }}
              >
                <ul className="directory-people-list">
                  {Array.isArray(recents) && !!recents.length ? (
                    recents.map((recentPerson) => (
                      <PersonResult
                        key={recentPerson}
                        AppStore={AppStore}
                        discProfile={discMiniProfiles[recentPerson]}
                        forceCheckBoxSpace={true}
                        hide={shouldHide({
                          discProfile: discMiniProfiles[recentPerson],
                          loading:
                            !discMiniProfiles[recentPerson] &&
                            !discMiniNull.some(
                              (userID) => `${userID}` === `${recentPerson}`
                            ),
                          profileUnavailable:
                            !discMiniProfiles[recentPerson] &&
                            discMiniNull.some(
                              (userID) => `${userID}` === `${recentPerson}`
                            ),
                          filter,
                        })}
                        isSelected={curSelectedIDs[recentPerson]}
                        loading={
                          !discMiniProfiles[recentPerson] &&
                          !discMiniNull.some(
                            (userID) => `${userID}` === `${recentPerson}`
                          )
                        }
                        previewUser={(userID) => {
                          const discProfile = discMiniProfiles[recentPerson];

                          if (discProfile) {
                            updatePreviewUser(discProfile);
                          }
                        }}
                        profileUnavailable={
                          !discMiniProfiles[recentPerson] &&
                          discMiniNull.some(
                            (userID) => `${userID}` === `${recentPerson}`
                          )
                        }
                        person={recentPerson}
                        showCheckbox={!!discMiniProfiles[recentPerson]}
                        toggleUserID={(userID) => {
                          if (DirectoryStore.dispatch) {
                            DirectoryStore.dispatch({
                              payload: {
                                key: toggleSelectedUsersKey,
                                userID: recentPerson,
                              },
                              type: "DIRECTORY_SEARCH_TOGGLE_SELECTED_USER",
                            });
                          }
                        }}
                      />
                    ))
                  ) : (
                    <>
                      <li className="directory-people-list-item-norecents-msg-cont">
                        <Message
                          className="directory-people-list-item-norecents-msg"
                          value="Once you Select users the most recent will be shown here
                          in the future"
                        />
                      </li>

                      <Icon
                        className="directory-people-list-item-norecents-msg-icon"
                        icon="users-solid"
                        size={
                          _.get(GeneralStore, "state.isMobile", false)
                            ? 150
                            : 150
                        }
                      />
                    </>
                  )}
                </ul>
              </div>
            </>
          )}

          {!!selectedUsers.length && (
            <>
              <div className="directory-people-selected-users-title-cont">
                <h3 className="directory-people-selected-users-title title-bold">
                  Selected Users
                </h3>
                <hr className="directory-people-selected-users-title-line" />
              </div>

              <div className="directory-people-list-cont">
                <ul className="directory-people-list">
                  {selectedUsers.map((selectedUser) =>
                    selectedUser ? (
                      <PersonResult
                        key={selectedUser.personID}
                        AppStore={AppStore}
                        discProfile={selectedUser}
                        forceCheckBoxSpace={true}
                        isSelected={curSelectedIDs[selectedUser.personID]}
                        person={(allPeople[toggleSelectedUsersKey] || []).find(
                          (person: any) =>
                            person.userID === selectedUser.personID
                        )}
                        previewUser={(userID) =>
                          updatePreviewUser(selectedUser)
                        }
                        profileUnavailable={false}
                        showCheckbox={true}
                        toggleUserID={(userID) => {
                          if (DirectoryStore.dispatch) {
                            DirectoryStore.dispatch({
                              payload: {
                                key: toggleSelectedUsersKey,
                                userID,
                              },
                              type: "DIRECTORY_SEARCH_TOGGLE_SELECTED_USER",
                            });
                          }
                        }}
                      />
                    ) : null
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </section>

      {!!selectedUsers.length && (
        <SidePane
          canClose={true}
          canMinimize={isMobile}
          className={cx(
            "directory-sidepane",
            previewUser && "directory-sidepane-underneath"
          )}
          discCompareProfile={
            selectedUsers.length === 1
              ? _.get(
                  AppStore,
                  `state.discCompareProfiles.${selectedUsers[0].personID}`
                )
              : undefined
          }
          discMini={selectedUsers.filter((user) => !!user)}
          // focusedPersonID={focusedPersonID}
          loggedInUserHasManage={userHasManage}
          loggedInUserID={_.get(AppStore, "state.loggedInUserID", 0)}
          onClick={
            !!previewUser ? () => updatePreviewUser(undefined) : undefined
          }
          onClose={() => {
            if (DirectoryStore.dispatch) {
              DirectoryStore.dispatch({
                payload: {},
                type: "DIRECTORY_SEARCH_CLEAR_SELECTED_USER",
              });
            }
          }}
          onPersonSelect={(personID) => {
            const newPreviewPerson = discMiniProfiles[personID];

            if (!newPreviewPerson) {
              updatePreviewUser(undefined);
            } else {
              if (newPreviewPerson && newPreviewPerson.personID) {
                const previewPersonCompareGraph = _.get(
                  AppStore,
                  `state.discCompareProfiles.${newPreviewPerson.personID}`
                );

                if (!previewPersonCompareGraph) {
                  loadCompareProfile(AppStore, newPreviewPerson.personID);
                }
              }

              updatePreviewUser(newPreviewPerson);
            }
          }}
          memberships={memberships}
          reloadTeam={(teamID: number) => {
            const targetTeam = teams ? teams[teamID] : undefined;

            if (targetTeam) {
              loadTeamsWithTeamToolsConfig({
                AdminStore,
                teams: [targetTeam],
              });
            }
          }}
          teams={teams}
          teamsWithConfiguration={teamsWithConfiguration}
        />
      )}

      {previewUser && (
        <SidePane
          asModal={isMobile}
          canClose={true}
          className={cx(
            "directory-sidepane directory-preview-pane"
            // !!selectedUsers.length && "directory-sidepane-overtop"
          )}
          closeOnBlur={true}
          discCompareProfile={_.get(
            AppStore,
            `state.discCompareProfiles.${previewUser.personID}`
          )}
          discMini={[previewUser]}
          // focusedPersonID={focusedPersonID}
          loggedInUserHasManage={userHasManage}
          loggedInUserID={_.get(AppStore, "state.loggedInUserID", 0)}
          onClose={() => updatePreviewUser(undefined)}
          onPersonSelect={(personID) => {
            navigate(`/profile/${personID}`);
          }}
          memberships={memberships}
          reloadTeam={(teamID: number) => {
            const targetTeam = teams ? teams[teamID] : undefined;

            if (targetTeam) {
              loadTeamsWithTeamToolsConfig({
                AdminStore,
                teams: [targetTeam],
              });
            }
          }}
          teams={teams}
          teamsWithConfiguration={teamsWithConfiguration}
        />
      )}
    </>
  );
};

const shouldHide = (info: {
  discProfile?: Tyto.DISCProfileMini;
  loading: boolean;
  profileUnavailable: boolean;
  filter: string;
}) => {
  if (info.loading) {
    return false;
  }

  switch (info.filter) {
    case "all":
      return false;
    case "disc-complete":
      return !info.discProfile;
    case "disc-incomplete":
      return !!info.discProfile;
    default:
      return true;
  }
};

const startSearchPeople = async (info: {
  searchTerm: string;
  // searchResults: SearchResults;
  // updateSearchResults: (allSearchResults: SearchResults) => void;
  updateLoadingPeople: (loadingPeople: boolean) => void;
  AppStore: AppStoreProps;
  DirectoryStore: DirectoryProps;
}) => {
  const userID = _.get(info.AppStore, "state.loggedInUserID", 0);
  const curDomainID = _.get(info.AppStore, "state.curDomainID", 0);
  const searchResults = _.get(info.DirectoryStore, "state.people", undefined);
  // const searchResults = _.get(info.DirectoryStore, "state.people", {});
  const searchKey = getSearchStoreKey(
    getSearchKeyParams(info.AppStore, info.searchTerm)
  );

  if (info.searchTerm && searchResults?.[searchKey]) {
    return;
  }

  info.updateLoadingPeople(true);

  try {
    const results = await searchForPeople(info.searchTerm);

    if (Array.isArray(results)) {
      if (info.DirectoryStore.dispatch) {
        info.DirectoryStore.dispatch({
          payload: {
            userID,
            domainID: curDomainID,
            users: results,
            searchTerm: info.searchTerm,
          },
          type: "DIRECTORY_SEARCH_USER_SEARCH_LOADED",
        });
      }

      info.updateLoadingPeople(false);

      const missingUserIDs = findMissingDiscProfiles(info.AppStore, results);

      if (Array.isArray(missingUserIDs) && missingUserIDs.length) {
        loadDiscMinis(info.AppStore, missingUserIDs);
      }
    }
  } catch {
    info.updateLoadingPeople(false);
  }
};

const findMissingDiscProfiles = (
  AppStore: AppStoreProps,
  results: Tyto.AdvancedPerson[]
) => {
  const discMiniNull: StoreState["discMiniNull"] = _.get(
    AppStore,
    "state.discMiniNull",
    []
  );
  const keyedNullUserIDs = _.keyBy(discMiniNull, (userID) => `${userID}`);
  const discMiniProfiles: StoreState["discMini"] = _.get(
    AppStore,
    "state.discMini",
    {}
  );

  const missingProfilesUserIDs = results
    .filter(
      (person) =>
        !discMiniProfiles[person.userID] && !keyedNullUserIDs[person.userID]
    )
    .map((person) => person.userID);

  return missingProfilesUserIDs;
};
