/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";

import { Icon } from "../../../common/";

interface Props {
  isConnected: boolean;
}

export default (props: Props) => {
  return (
    <div className="meta-nav-list-userinfo-wsconnection-identifier-cont">
      <Icon
        className={cx(
          "meta-nav-list-userinfo-wsconnection-identifier",
          !props.isConnected && "wsconnection-identifier-not-connected"
        )}
        icon={props.isConnected ? "wifi" : "wifi-slash"}
        size={12}
        title={
          props.isConnected
            ? "Currently connected and listening for live updates!"
            : "Currently disconnected and unable to receive live updates."
        }
      />
    </div>
  );
};
