/*
 * Component Description
 */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as _ from "lodash";

import pkg from "../../../package.json";
import { Tyto } from "../../typings/tyto";
import { AppStoreProps } from "../../data/stores/AppStore";
import { DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY } from "../../data/constants/";
import { getTempSessionData } from "../../data/storage/session";
import { getDomainMessage } from "../../components/meta/super-side-pane/utils/";
import { Button, TextButton, UserThumb } from "../../components/common/";
import {
  getLoggedInUserID,
  removeSessionData,
  hardClearStorage,
} from "../../data/storage/session";
import { parseLabel } from "../profile/tabs-interface/subcomponents/Tabs";

import "./style.scss";
import { SessionData } from "../../typings";

interface Props {
  AppStore: AppStoreProps;
  path?: string;
  sessionData?: SessionData;
}

export default (props: Props) => {
  const [Error, updateError] = React.useState("");
  const [waitingMessageLoaded, updateWaitingMessageLoaded] =
    React.useState(false);
  const [waitingMessage, updateWaitingMessage] =
    React.useState<Tyto.Domain.Message | null>(null);
  const [headline, updateHeadline] = React.useState("");
  const [tempSessionKey, updateTempSessionKey] = React.useState("");

  const navigate = useNavigate();

  // * [E-1]
  React.useEffect(() => {
    // * If sessionData is empty object and session has been checked redirect and force user to login
    if (
      !_.get(props, "sessionData.sessionKey", "") &&
      _.get(props, "AppStore.state.hasCheckedForStoredSession", false)
    ) {
      navigate("/");
    }

    const userDISCMini = _.get(props, "AppStore.state.userDISCMini", undefined);

    if (userDISCMini) {
      checkIfRedirectIsNeeded(navigate, userDISCMini);
    }

    updateWaitingMessageLoaded(false);
    const domainID: number = _.get(props.AppStore, "state.curDomainID", 0);

    getDomainMessage({
      domainID,
      messageType: DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY,
      onSuccess: (newDomainMessage) => {
        if (newDomainMessage) {
          updateWaitingMessage(newDomainMessage);
          updateHeadline(
            parseLabel({
              label: newDomainMessage.headLine,
              discMini: userDISCMini,
            })
          );
        } else {
          updateWaitingMessage({
            domainID,
            headLine: "",
            bodyHtml: "",
            bodyPlain: "",
            messageType: DOMAIN_MESSAGE_NO_ACCESS_MSG_KEY,
            modifiedByID: 0,
            modifiedDate: new Date().toISOString(),
          });
          updateHeadline("");
        }

        updateWaitingMessageLoaded(true);
      },
      onError: (msg: string) => {
        updateError(msg);
        updateWaitingMessageLoaded(true);
      },
    });

    const loggedInUserID = _.get(props, "sessionData.userID", 0);

    if (loggedInUserID) {
      const tempSessionData = getTempSessionData(loggedInUserID) as any;

      if (
        tempSessionData &&
        tempSessionData.tempSessionKey &&
        props.AppStore.dispatch
      ) {
        // @ts-ignore
        props.AppStore.dispatch({
          payload: {
            temporarySessionDaya: tempSessionData,
            // @ts-ignore
            temporarySessionKey: tempSessionData.tempSessionKey,
          },
          type: "SET_TEMP_SESSION_DATA",
        });
      }
    }
  }, []);

  // * [E-2]
  React.useEffect(() => {
    const tempSessionKeyFromStore = _.get(
      props,
      "AppStore.state.tempSessionData.tempSessionKey",
      ""
    );

    updateTempSessionKey(tempSessionKeyFromStore);
  }, [_.get(props, "AppStore.state.tempSessionData")]);

  // * [E-3]
  React.useEffect(() => {
    const userDISCMini = _.get(props, "AppStore.state.userDISCMini", undefined);

    if (userDISCMini) {
      checkIfRedirectIsNeeded(navigate, userDISCMini);
    }
  }, [_.get(props, "AppStore.state.userDISCMini", undefined)]);

  // * [E-4]
  React.useEffect(() => {
    if (
      !_.get(props, "sessionData.sessionKey", "") &&
      _.get(props, "AppStore.state.hasCheckedForStoredSession", false)
    ) {
      navigate("/");
    }

    const loggedInUserID = _.get(props, "sessionData.userID", 0);

    if (loggedInUserID) {
      const tempSessionData = getTempSessionData(loggedInUserID);

      // @ts-ignore
      if (tempSessionData && tempSessionData.tempSessionKey) {
        // @ts-ignore
        return tempSessionData.tempSessionKey;
      }
    }
  }, [
    props.sessionData,
    _.get(props, "AppStore.state.hasCheckedForStoredSession", false),
  ]);

  const userDISCMini: Tyto.DISCProfileMini | undefined = _.get(
    props,
    "AppStore.state.userDISCMini",
    undefined
  );

  return (
    <section className="antechamber-main-cont">
      <div className="antechamber-inner-cont">
        <div className="antechamber-featured-image">
          <img
            className="antechamber-featured-image"
            src="/work-from-home.jpg"
          />
        </div>

        {waitingMessageLoaded ? (
          <>
            <h1 className="antechamber-main-title title-bold">
              {headline || (waitingMessage && waitingMessage.headLine) || ""}
            </h1>

            <p className="antechamber-text">
              {waitingMessage ? waitingMessage.bodyPlain : ""}
            </p>
          </>
        ) : (
          <h3>Loading...</h3>
        )}

        {userDISCMini && (
          <div className="antechamber-userinfo-cont">
            <UserThumb
              className="antechamber-userinfo-userthumb"
              discMiniProfile={userDISCMini}
              discValues={{
                d: 0,
                i: 0,
                s: 0,
                c: 0,
              }}
              asset={userDISCMini.profileImageAsset}
              userName={userDISCMini.personName}
              hasGradient={false}
              size={40}
            />

            <p className="antechamber-userinfo-email">
              {userDISCMini.emails[0] || userDISCMini.personName}
            </p>
          </div>
        )}

        {tempSessionKey && (
          <div style={{ padding: "10px 0px", boxSizing: "border-box" }}>
            <a href={`/set-password?temporaryKey=${tempSessionKey}`}>
              Set Password
            </a>
          </div>
        )}

        <div className="antechamber-logout-button-cont">
          <TextButton
            iconSide="right"
            iconProps={{
              icon: "sign-out",
              size: 10,
              className: "antechamber-logout-button-icon",
            }}
            className="antechamber-logout-button"
            onClick={() => {
              if (props.AppStore && props.AppStore.dispatch) {
                hardClearStorage(() => {
                  if (props.AppStore.dispatch) {
                    props.AppStore.dispatch({
                      payload: {},
                      type: "CLEAR_USER_SESSION_DATA",
                    });
                  }
                });
              }
            }}
            value="Logout"
          />

          {!!_.get(props, "sessionData.adminID") && (
            <TextButton
              className="antechamber-relogin-button"
              onClick={() => {
                if (props.AppStore && props.AppStore.dispatch) {
                  const loggedInUserID = getLoggedInUserID();

                  removeSessionData(loggedInUserID, () => {
                    if (props.AppStore.dispatch) {
                      props.AppStore.dispatch({
                        payload: {},
                        type: "CLEAR_USER_SESSION_DATA",
                      });
                    }
                  });
                }
              }}
              value="Remove This Session"
            />
          )}
        </div>

        <div className="antechamber-tti-logo-cont">
          <img className="antechamber-tti-logo" src="/teamtools.png" />
        </div>

        <p className="logon-teamtools-version-id">
          {_.get(pkg, "version", "")}
        </p>
      </div>
    </section>
  );
};

const checkIfRedirectIsNeeded = (
  navigate: ReturnType<typeof useNavigate>,
  userDISCMini: Tyto.DISCProfileMini | null
) => {
  const hasBasicViewDisc = _.get(
    userDISCMini,
    "teamToolsPermit.hasBasicViewDisc",
    false
  );

  if (!hasBasicViewDisc) {
    return;
  }

  const userID = _.get(userDISCMini, "personID", 0);

  if (userID) {
    navigate(`/profile/${userID}/downloads`);
  } else {
    navigate(`/`);
  }
  // navigate("/");
};
