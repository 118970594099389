/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import * as _ from "lodash";

import { Tyto } from "../../../../../typings/tyto";
import WebSocketClient from "../../../../../data/websocket";
import TytoCalls from "../../../../../data/tyto/";
import { Button, Checkbox } from "../../../../common/";
import { DEFAULT_TEAMTOOLS_USER_PERMISSIONS } from "../../../../../data/constants/";

import "./PersonConfigGrid.scss";

const configItems = [
  {
    label: "Can Take Assessment",
    value: "mayTakeDisc",
  },
  {
    label: "Can Import DISC",
    value: "mayImportDisc",
    _adminOnly: true,
  },
  {
    label: "Can View DISC Results",
    value: "hasBasicViewDisc",
  },
  {
    label: "Can View Advanced DISC Results",
    value: "hasAdvViewDisc",
    _adminOnly: true,
  },
  {
    label: "Can Take Training",
    value: "mayTakeBasicTrain",
    _adminOnly: true,
  },
  {
    label: "Can Take Advanced Training",
    value: "mayTakeAdvTrain",
    _adminOnly: true,
  },
];

const adminConfigItems = [
  {
    label: "Can Change Others Abilities",
    value: "hasPermitChange",
  },
  {
    label: "Can Grant Others the Power to Change Abilities",
    value: "hasGrantPermitChange",
  },
];

interface Props {
  discMini: Tyto.DISCProfileMini;
  onPermitUpdate: (personID: number) => void;
  overrideTeam?: Tyto.TeamGet;
  personalDiscMini: Tyto.DISCProfileMini;
  showGrantChange: boolean;
}

export default (props: Props) => {
  const [error, updateError] = React.useState("");
  const [saving, updateSaving] = React.useState(false);
  const [
    originalPersonConfig,
    updateOriginalPersonConfig,
  ] = React.useState<Tyto.TeamToolsPermissions | null>(null);
  const [
    personConfig,
    updatePersonConfig,
  ] = React.useState<Tyto.TeamToolsPermissions | null>(null);

  React.useEffect(() => {
    updateSaving(false);
    const newConfigValues = getConfigValues(props.discMini);

    updateOriginalPersonConfig(newConfigValues);
    updatePersonConfig(newConfigValues);
  }, [props.discMini]);

  const hasChanges = detectChanges(personConfig, originalPersonConfig);
  const overrideTeamConfig = getTeamValues(
    !!_.get(props, "discMini.styleKey3", ""),
    props.overrideTeam
  );

  return (
    <div className="meta-sidepane-person-admin-config-cont">
      {props.overrideTeam && (
        <p className="meta-sidepane-person-admin-config-overrideteam-name">
          Rules from{" "}
          <span className="meta-sidepane-person-admin-config-overrideteam-name-text">
            {props.overrideTeam.teamName}
          </span>
        </p>
      )}

      {props.discMini && (
        <ul className="meta-sidepane-person-admin-config-grid">
          {configItems.map((configItem) =>
            !configItem._adminOnly ||
            _.get(props, "personalDiscMini.domainID", 0) === 551 ? (
              <ConfigItemRow
                isAdminItem={false}
                originalPersonConfig={originalPersonConfig}
                personConfig={personConfig}
                configItem={configItem}
                updatePersonConfig={(
                  newConfigValue: Tyto.TeamToolsPermissions | null
                ) => updatePersonConfig(newConfigValue)}
                overrideTeamConfig={overrideTeamConfig}
              />
            ) : null
          )}

          {props.showGrantChange && (
            <>
              <li className="meta-sidepane-person-admin-config-grid-admin-title">
                Config Change Abilities
              </li>

              {adminConfigItems.map((configItem) => (
                <ConfigItemRow
                  isAdminItem={true}
                  originalPersonConfig={originalPersonConfig}
                  personConfig={personConfig}
                  configItem={configItem}
                  updatePersonConfig={(
                    newConfigValue: Tyto.TeamToolsPermissions | null
                  ) => updatePersonConfig(newConfigValue)}
                  overrideTeamConfig={overrideTeamConfig}
                />
              ))}
            </>
          )}
        </ul>
      )}

      <div className="meta-sidepane-person-admin-config-buttons-cont">
        <Button
          className="meta-sidepane-person-admin-config-button"
          disabled={saving || !hasChanges}
          onClick={() => {
            if (originalPersonConfig) {
              updatePersonConfig(originalPersonConfig);
            }
          }}
          shape="square"
          type="hollow"
          value="Reset"
        />

        <Button
          className="meta-sidepane-person-admin-config-button"
          disabled={saving || !hasChanges}
          onClick={() => {
            if (props.discMini) {
              updateSaving(true);
              saveChanges({
                personID: _.get(props.discMini, "personID", 0),
                updates: personConfig,
                onSuccess: () => {
                  props.onPermitUpdate(props.discMini.personID);
                },
                onError: (msg: string) => {
                  updateError(msg);
                  updateSaving(false);
                },
              });
            }
          }}
          shape="square"
          type="color"
          value={saving ? "Updating..." : "Update"}
        />
      </div>
    </div>
  );
};

const detectChanges = (
  originalConfigValues: Tyto.TeamToolsPermissions | null,
  configValues: Tyto.TeamToolsPermissions | null
) => {
  if (!originalConfigValues || !configValues) {
    return false;
  }

  return [...configItems, ...adminConfigItems].some(
    (configItem) =>
      originalConfigValues[
        configItem.value as keyof Tyto.TeamToolsPermissions
      ] !== configValues[configItem.value as keyof Tyto.TeamToolsPermissions]
  );
};

const getConfigValues = (discMini?: Tyto.DISCProfileMini) => {
  return {
    ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
    ...((discMini && discMini.teamToolsPermit) || {}),
  };
};

const saveChanges = async ({
  personID,
  updates,
  onSuccess,
  onError,
}: {
  personID: number;
  updates: Tyto.TeamToolsPermissions | null;
  onSuccess: () => void;
  onError: (msg: string) => void;
}) => {
  if (!personID || !updates) {
    return;
  }

  try {
    const newTeamToolsPermit = getConfigValues({
      teamToolsPermit: updates,
    } as Tyto.DISCProfileMini);

    const resp = await TytoCalls.DISCProfile.Mini.put({
      personID,
      teamToolsPermit: newTeamToolsPermit,
    });

    debugger;
    if (resp.recordsAffected) {
      WebSocketClient.announceUpdates([personID]);
    }

    onSuccess();
  } catch (err) {
    onError(
      typeof err === "string"
        ? err
        : _.get(
            err,
            "error.msg",
            "An Error occurred while updating this users Configuration."
          )
    );
  }
};

// * ===========================================
// * Team Related Functions ====================
const getTeamValues = (hasTakenAssessment: boolean, team?: Tyto.TeamGet) => {
  const {
    onInitialize,
    onDiscComplete,
    onBasicTrainingComplete,
    onAdvTrainingComplete,
  } = getTeamConfigValues(team || ({} as any));

  return hasTakenAssessment
    ? { ...onInitialize, ...onDiscComplete }
    : onInitialize;

  // const values = configItems.reduce(
  //   (accum: { [x: string]: string }, configItem) => {
  //     accum[configItem.value] = getValue(
  //       {
  //         onInitialize,
  //         onDiscComplete,
  //         onBasicTrainingComplete,
  //         onAdvTrainingComplete
  //       },
  //       configItem.value
  //     );

  //     return accum;
  //   },
  //   {}
  // );

  // return values;
};

export function getTeamConfigValues(
  team: Tyto.TeamGet
): {
  onInitialize: Tyto.TeamToolsPermissions;
  onDiscComplete: Tyto.TeamToolsPermissions;
  onBasicTrainingComplete: Tyto.TeamToolsPermissions;
  onAdvTrainingComplete: Tyto.TeamToolsPermissions;
} {
  return {
    onInitialize: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig && team.teamToolsConfig.onInitialize) || {}),
    },
    onDiscComplete: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig && team.teamToolsConfig.onDiscComplete) || {}),
    },
    onBasicTrainingComplete: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig &&
        team.teamToolsConfig.onBasicTrainingComplete) ||
        {}),
    },
    onAdvTrainingComplete: {
      ...DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
      ...((team.teamToolsConfig &&
        team.teamToolsConfig.onAdvTrainingComplete) ||
        {}),
    },
  };
}

interface ConfigItemRow {
  configItem: {
    label: string;
    value: string;
  };
  isAdminItem: boolean;
  originalPersonConfig: Tyto.TeamToolsPermissions | null;
  overrideTeamConfig: Tyto.TeamToolsPermissions;
  personConfig: Tyto.TeamToolsPermissions | null;
  updatePersonConfig: (
    newConfigValue: Tyto.TeamToolsPermissions | null
  ) => void;
}

const ConfigItemRow = ({
  configItem,
  isAdminItem,
  originalPersonConfig,
  overrideTeamConfig,
  personConfig,
  updatePersonConfig,
}: ConfigItemRow) => {
  return (
    <li
      className={cx(
        "meta-sidepane-person-admin-config-row",
        isAdminItem && "is-admin-item",
        personConfig &&
          originalPersonConfig &&
          personConfig[configItem.value as keyof Tyto.TeamToolsPermissions] !==
            originalPersonConfig[
              configItem.value as keyof Tyto.TeamToolsPermissions
            ] &&
          "row-has-change",
        !!overrideTeamConfig[
          configItem.value as keyof Tyto.TeamToolsPermissions
        ] && "meta-sidepane-person-admin-config-row-teamoverride"
      )}
      key={configItem.value}
    >
      <div className="meta-sidepane-person-admin-config-column label-column">
        {configItem.label}
      </div>

      <div className="meta-sidepane-person-admin-config-column">
        <Checkbox
          className=""
          checked={
            !!(
              personConfig &&
              personConfig[configItem.value as keyof Tyto.TeamToolsPermissions]
            )
          }
          innerClassName={cx(
            "meta-sidepane-person-admin-config-checkbox-inner",
            personConfig &&
              !personConfig[
                configItem.value as keyof Tyto.TeamToolsPermissions
              ] &&
              "user-value-does-not-match"
          )}
          onCheck={() => {
            if (
              personConfig &&
              personConfig[
                configItem.value as keyof Tyto.TeamToolsPermissions
              ] !== undefined
            ) {
              if (
                !!overrideTeamConfig[
                  configItem.value as keyof Tyto.TeamToolsPermissions
                ]
              ) {
                updatePersonConfig({
                  ...personConfig,
                  [configItem.value]: true,
                });
              } else {
                updatePersonConfig({
                  ...personConfig,
                  [configItem.value]: !personConfig[
                    configItem.value as keyof Tyto.TeamToolsPermissions
                  ],
                });
              }
            }
          }}
          size={16}
        />
      </div>
    </li>
  );
};
