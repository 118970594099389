import { ENDPOINT_PERSON_PROFILE_PHOTO } from "../constants/";
import { callWrapper, CallOpts } from "./utils";

const endpoint = ENDPOINT_PERSON_PROFILE_PHOTO;

interface RespType extends Endpoints.GenericRespData {
  profileImageID: number;
}

export const ProfilePhoto = {
  post(
    params: Endpoints.Tyto.Person.ProfilePhoto.PostParameters,
    callOpts?: CallOpts
  ) {
    return callWrapper("post", endpoint, params, callOpts) as Promise<RespType>;
  },
};
