/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";

import { Button, TextButton } from "../../../../common";
import { DEFAULT_INTENSITY_COLORS } from "../../../../common/heat-map/utils";
import { createPseudoRandomString } from "../../../../../data/utils/helpers/";

import "./CustomIntensityColors.scss";

export interface IntensityColorsMap {
  [percentPoint: number]: string;
}

export type ColorArr = [number, string, string][];

interface Props {
  inensityColors: IntensityColorsMap;
  updateCustomMap: (newMap: IntensityColorsMap) => void;
}

// function componentToHex(colorNumber: number) {
//   var hex = colorNumber.toString(16);
//   return hex.length == 1 ? "0" + hex : hex;
// }

// function getColorLetterAsNumber(rgbColor: string | number) {
//   if (typeof rgbColor === "number") {
//     return rgbColor;
//   }

//   const safeRGBColor = parseInt(rgbColor, 10);

//   if (safeRGBColor === NaN) {
//     return 0;
//   }

//   return safeRGBColor;
// }

// function rgbToHex(r: string | number, g: string | number, b: string | number) {
//   const safeR = getColorLetterAsNumber(r);
//   const safeG = getColorLetterAsNumber(g);
//   const safeB = getColorLetterAsNumber(b);

//   return "#" + componentToHex(safeR) + componentToHex(safeG) + componentToHex(safeB);
// }

// function getRGBValuesFromString(colorString: string) {
//   if (/^(rgb(a)?)/i.test(colorString)) {
//     const [r, g, b] = `${colorString}`.match(/(\d|\.){1,3}/gi) || [];

//     if (r === undefined || g === undefined || b === undefined) {
//       return [];
//     }

//     return [r, g, b];
//   }

//   return [];
// }

function getSafeNumericPointValue(point: string | number) {
  const parsedPoint = parseFloat(`${point}`);
  const safePoint =
    typeof parsedPoint !== "number" || Number.isNaN(parsedPoint)
      ? 0
      : parsedPoint;
  const pointVal = Math.min(100, safePoint * 100);

  return Math.max(Math.min(100, pointVal), 0);
}

function createCustomColorsArrayFromObj(colorsMap: IntensityColorsMap) {
  const colorEntries = Object.entries(colorsMap).map(([point, color]) => {
    const safePointVal = getSafeNumericPointValue(point);
    const guid = createPseudoRandomString(10);

    return [safePointVal, `${color}`, guid];
  });

  return _.orderBy(colorEntries, ["[0]"], ["asc"]) as ColorArr;
}

function createCustomColorsObjectFromArray(colorsAsArray: ColorArr) {
  const newListAsObj = colorsAsArray.reduce(
    (accum: IntensityColorsMap, [percentPoint, color]) => {
      const colorKey =
        percentPoint === 100 ? 1.0 : parseFloat(`0.${percentPoint}`);
      accum[colorKey] = `${color}`;

      return accum;
    },
    {}
  );

  return newListAsObj;
}

const CustomInensityColors = (props: Props) => {
  const [colorsAsArray, updateColorsAsArray] = React.useState<ColorArr>(() =>
    createCustomColorsArrayFromObj(
      props.inensityColors || DEFAULT_INTENSITY_COLORS
    )
  );

  React.useEffect(() => {
    const colorObj = createCustomColorsObjectFromArray(colorsAsArray);

    props.updateCustomMap(colorObj);
  }, [colorsAsArray]);

  return (
    <>
      <ul className="custom-heatmap-colors-list">
        {colorsAsArray && colorsAsArray.length ? (
          <>
            <li className="custom-heatmap-colors-list-item">
              <div className="custom-heatmap-colors-list-item-percent">
                <span className="custom-heatmap-colors-list-item-label">
                  Percent
                </span>
              </div>
              <div className="custom-heatmap-colors-list-item-color">
                <span className="custom-heatmap-colors-list-item-label">
                  Color
                </span>
              </div>
            </li>

            {colorsAsArray.map(([percentPoint, color, guid], curIdx) => (
              <li className="custom-heatmap-colors-list-item" key={`${guid}`}>
                <div className="custom-heatmap-colors-list-item-percent">
                  <input
                    className="custom-heatmap-colors-list-item-percent-input"
                    type="number"
                    min={0}
                    max={100}
                    value={percentPoint}
                    onChange={(e) => {
                      let newPercentValue = _.get(
                        e,
                        "target.value",
                        percentPoint
                      );

                      if (typeof newPercentValue === "string") {
                        newPercentValue = parseFloat(newPercentValue);

                        if (Number.isNaN(newPercentValue)) {
                          newPercentValue = percentPoint;
                        }
                      }

                      const newColorsList = createNewArrayWithUpdate({
                        color,
                        index: curIdx,
                        guid: guid,
                        point: newPercentValue,
                        curList: colorsAsArray,
                      });

                      updateColorsAsArray(newColorsList);
                    }}
                  />
                </div>

                <div className="custom-heatmap-colors-list-item-color">
                  <input
                    className="custom-heatmap-colors-list-item-color-input"
                    type="color"
                    onChange={(e) => {
                      console.log(
                        "Color Change Event: ",
                        _.get(e, "target.value", "uh..")
                      );

                      const newColorsList = createNewArrayWithUpdate({
                        color: _.get(e, "target.value", "#000000"),
                        index: curIdx,
                        guid: guid,
                        point: percentPoint,
                        curList: colorsAsArray,
                      });

                      updateColorsAsArray(newColorsList);
                    }}
                    value={color}
                  />

                  <span className="custom-heatmap-colors-list-item-color-hex">
                    {color}
                  </span>
                </div>
              </li>
            ))}
          </>
        ) : (
          <p>No Color Entries Found</p>
        )}
      </ul>

      <div className="custom-heatmap-colors-addnew-cont">
        <TextButton
          className="custom-heatmap-colors-addnew-btn"
          onClick={() => {
            const guid = createPseudoRandomString(10);

            const curColorsArray = [
              ...(colorsAsArray || []),
              [100, "#000000", guid],
            ] as ColorArr;

            updateColorsAsArray(curColorsArray);
          }}
          value="Add Intensity Color"
        />

        <TextButton
          className="custom-heatmap-colors-addnew-btn"
          onClick={() => {
            const sortedArray = _.sortBy(colorsAsArray, ["[0]"], ["asc"]);

            updateColorsAsArray(sortedArray);
          }}
          value="Sort by Percent Value"
        />
      </div>

      <div className="custom-heatmap-colors-update-cont">
        <Button
          className="custom-heatmap-colors-update-btn"
          onClick={() => {
            const resetArray = createCustomColorsArrayFromObj({
              ...DEFAULT_INTENSITY_COLORS,
            });

            updateColorsAsArray(resetArray);
            props.updateCustomMap({ ...DEFAULT_INTENSITY_COLORS });
          }}
          shape="square"
          type="hollow"
          value="Reset"
        />

        {/* <Button
          className="custom-heatmap-colors-update-btn"
          onClick={() => {
            const colorObj = createCustomColorsObjectFromArray(colorsAsArray);

            props.updateCustomMap(colorObj);
          }}
          shape="square"
          type="color"
          value="Apply Updates"
        /> */}
      </div>
    </>
  );
};

function createNewArrayWithUpdate({
  color,
  index,
  guid,
  point,
  curList,
}: {
  color: string;
  index: number;
  guid: string;
  point: number;
  curList: [number, string, string][];
}) {
  const listCopy = [...curList];

  // const safePointVal = getSafeNumericPointValue(point);

  listCopy.splice(index, 1, [point, `${color}`, guid]);

  return listCopy;
}

export default CustomInensityColors;
