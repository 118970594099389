/*
 * Component Description
 */
import * as React from "react";
import * as _ from "lodash";
import { StoreAction, SessionData, InterfaceContext } from "../../typings/";
import {
  mapMiniProfilesByEmails,
  mapMiniProfilesByUserID,
  mapPersonEmailsByPersonIDs,
} from "../utils/helpers";
import WebSocketClient from "../websocket/";
import {
  CALL_SOURCES,
  CUR_DOMAINID_KEY,
  DEFAULT_ACCOUNT_CRITICAL_ERROR_MSG,
  DEFAULT_TEAMTOOLS_USER_PERMISSIONS,
  LOGGED_IN_USER_KEY,
  URL_BASE,
} from "../constants/";
import { setSessionData, setTempSessionData } from "../storage/session";
import { setSessionStorageItem } from "../storage/utils";
import { Tyto } from "../../typings/tyto";
import {
  initMixPanelPerson,
  setMixPanelUserProperties,
  triggerMixPanelEvent,
} from "../logging/";

export type AppStoreDispatch = React.Dispatch<StoreAction<RecipientActionType>>;

export interface AppStoreProps {
  state?: StoreState;
  dispatch?: AppStoreDispatch;
}

export type PersonData = {
  loading: boolean;
  person?: Tyto.Person;
};

export interface UserOnlineInfo {
  userID: number;
  connectionsCount: number;
  lastActivity: string;
}

export interface StoreState {
  CRITICAL_ERROR?: string;
  curDomainID?: number;
  hasCheckedForStoredSession: boolean;
  focusedPerson?: string;
  loading?: boolean;
  discCompareProfiles: {
    [x: number]: Tyto.DISCCompareProfile;
  };
  discData: {
    [x: string]: Tyto.DISCInfo;
  };
  discMiniByTeam: {
    [x: string]: number[];
  };
  discMiniByTeamAndBelow: {
    [x: string]: number[];
  };
  discMini: {
    // [x: string]: Tyto.DISCProfileMini;
    [x: number]: Tyto.DISCProfileMini;
  };
  discDataNull: string[]; // * Null emails
  discMiniNull: number[]; // * Null userIDs
  discMiniNullProfiles: {
    [x: number]: Tyto.DISCProfileMini;
  };
  discTeamStyle: {
    [x: string]: Tyto.DISCTeamProfile;
  };
  loggedInUserID: number;
  loggedInUserTeamToolsPermit: {
    [x: number]: Tyto.TeamToolsPermissions;
  };
  needsAssessment?: boolean;
  onlineUsersTable: {
    [x: number]: UserOnlineInfo;
  };
  pdfLessons: {
    [x: number]: any;
  };
  personInfo?: Tyto.Person;
  personIDEmailMap: {
    [x: number]: string;
  };
  profilesByTeam: {
    [x: number]: Tyto.DISCProfileMini[];
  };
  sessionData?: any;
  subDomains?: Tyto.Team[];
  tempSessionData?: SessionData;
  tempSessionKey?: string;
  tryybProfiles: { [x: number]: Tyto.Person };
  userDISCMini?: Tyto.DISCProfileMini;
  userHasManage?: boolean;
  userDomain?: Tyto.Domain;
  websocketServerConnected: boolean;
}

type RecipientActionType =
  | "CLEAR_USER_SESSION_DATA"
  | "DISC_COMPARE_PROFILE_LOADED"
  | "DISC_COMPARE_PROFILES_LOADED"
  | "DISC_MINI_PROFILES_LOADED"
  | "DISC_TEAM_PROFILE_LOADED"
  | "PDF_LESSON_LOADED"
  | "RESET"
  | "USER_LOGGED_IN"
  | "SET_FOCUSED_PROFILE"
  | "STORED_SESSION_CHECKED"
  | "TEAM_PROFILES_LOADED"
  | "SET_TARGET_DOMAINID"
  | "SUB_DOMAINS_LOADED"
  | "SET_TEMP_SESSION_DATA"
  | "TEAM_DISC_MINI_PROFILES_LOADED"
  | "TEAM_AND_BELOW_DISC_MINI_PROFILES_LOADED"
  | "TRYYB_PROFILE_LOADED"
  | "UPDATE_USER_HAS_MANAGE"
  | "UPDATE_USERS_CONNECTIONS_TABLE"
  | "USER_PERSONAL_DISC_MINI"
  | "USER_INFO_LOADED"
  | "USER_SEARCH_LOADED"
  | "USER_DOMAIN_INFO_LOADED"
  | "USERS_DISC_INFO_LOADED"
  | "WEBSOCKET_CONNECTION_STATUS_CHANGE";

let StoreContext: React.Context<AppStoreProps> = React.createContext({});

let initialState: StoreState = {
  CRITICAL_ERROR: "",
  discCompareProfiles: {},
  discData: {},
  discMiniByTeam: {},
  discMiniByTeamAndBelow: {},
  discMini: {},
  discDataNull: [], // * Null emails
  discMiniNull: [], // * Null userIDs
  discMiniNullProfiles: {},
  discTeamStyle: {},
  hasCheckedForStoredSession: false,
  loading: false,
  loggedInUserID: 0,
  loggedInUserTeamToolsPermit: {},
  onlineUsersTable: {},
  pdfLessons: {},
  personIDEmailMap: {},
  profilesByTeam: {},
  tryybProfiles: {},
  userDomain: undefined,
  websocketServerConnected: false,
  // sessionData: getSessionData()
};

let reducer = (state: StoreState, action: StoreAction<RecipientActionType>) => {
  console.log(action);

  switch (action.type) {
    case "CLEAR_USER_SESSION_DATA":
      return {
        ...state,
        CRITICAL_ERROR: "",
        curDomainID: undefined,
        discData: {},
        discMini: {},
        discMiniByTeam: {},
        discMiniByTeamAndBelow: {},
        loggedInUserID: 0,
        onlineUsersTable: {},
        pdfLessons: {},
        sessionData: undefined,
        tempSessionData: undefined,
        tempSessionKey: undefined,
        userHasManage: undefined,
        userDISCMini: undefined,
        userDomain: undefined,
        personInfo: undefined,
      };

    case "DISC_COMPARE_PROFILE_LOADED":
      if (action.payload.discCompareProfile) {
        const personID = _.get(
          action.payload,
          "discCompareProfile.result.personID",
          0
        );
        if (personID) {
          const discCompareProfiles = {
            ...state.discCompareProfiles,
            [personID]: action.payload.discCompareProfile,
          };

          return {
            ...state,
            discCompareProfiles,
          };
        }
      }

      return state;
    case "DISC_COMPARE_PROFILES_LOADED":
      if (
        Array.isArray(action.payload.discCompareProfiles) &&
        action.payload.discCompareProfiles.length
      ) {
        const mappedProfiles = _.keyBy(
          action.payload.discCompareProfiles as Tyto.DISCCompareProfile[],
          "result.personID"
        );

        const discCompareProfiles = {
          ...state.discCompareProfiles,
          ...mappedProfiles,
        };

        return {
          ...state,
          discCompareProfiles,
        };
      }

      return state;
    case "DISC_TEAM_PROFILE_LOADED":
      if (action.payload.discStyle) {
        const discTeamStyle = {
          ...state.discTeamStyle,
          [action.payload.discStyle.styleKey.toLowerCase()]:
            action.payload.discStyle,
        };

        return {
          ...state,
          discTeamStyle,
        };
      }

      return state;
    case "PDF_LESSON_LOADED":
      if (action.payload.pdfLesson) {
        const lessonID = _.get(action.payload, "pdfLesson.lessonID", 0);

        if (!lessonID) {
          return state;
        }

        return {
          ...state,
          pdfLessons: {
            ...state.pdfLessons,
            [lessonID]: action.payload.pdfLesson,
          },
        };
      }

      return state;
    case "SET_FOCUSED_PROFILE":
      if (action.payload) {
        return {
          ...state,
          focusedPerson: action.payload.email as string,
        };
      }

      return state;
    case "SET_TARGET_DOMAINID":
      if (action.payload) {
        setSessionStorageItem(CUR_DOMAINID_KEY, action.payload.domainID, true);

        if (action.callback) {
          action.callback({});
        }

        return {
          ...state,
          curDomainID: action.payload.domainID || undefined,
        };
      }

      return state;
    case "STORED_SESSION_CHECKED":
      if (action.payload) {
        return {
          ...state,
          hasCheckedForStoredSession:
            !!action.payload.hasCheckedForStoredSession,
        };
      }

      return state;

    case "SUB_DOMAINS_LOADED":
      if (action.payload) {
        return {
          ...state,
          subDomains: Array.isArray(action.payload.subDomains)
            ? action.payload.subDomains
            : [],
        };
      }

      return state;
    case "SET_TEMP_SESSION_DATA":
      if (action.payload.tempSessionData) {
        return {
          ...state,
          tempSessionData: action.payload.tempSessionData,
          tempSessionKey: action.payload.tempSessionKey,
        };
      }

      return state;
    case "TEAM_PROFILES_LOADED":
      // if (action.payload) {
      //   const discProfiles: Tyto.DISCInfo[] = _.get(
      //     action.payload,
      //     "discInfo",
      //     []
      //   );

      //   const discProfilesByEmail = mapMiniProfilesByEmails(discProfiles);
      //   const emailsByPersonIDsMap = mapPersonEmailsByPersonIDs(discProfiles);

      //   const personIDEmailMap = {
      //     ...state.personIDEmailMap,
      //     ...emailsByPersonIDsMap
      //   };

      //   const discMini = {
      //     ...state.discMini,
      //     ...discProfilesByEmail
      //   };

      //   return {
      //     ...state,
      //     discMini,
      //     personIDEmailMap
      //   };
      // }

      return state;
    case "TRYYB_PROFILE_LOADED":
      if (action.payload.profile !== undefined && action.payload.userID) {
        const personID =
          (action.payload.profile && action.payload.profile.personID) ||
          action.payload.userID;

        return {
          ...state,
          tryybProfiles: {
            ...state.tryybProfiles,
            [personID]: action.payload.profile,
          },
        };
      }

      return state;
    case "UPDATE_USER_HAS_MANAGE":
      if (action.payload.userHasManage !== undefined) {
        WebSocketClient.setisAdminForConnection(!!action.payload.userHasManage);

        return {
          ...state,
          userHasManage: action.payload.userHasManage,
        };
      }

      return state;
    case "USER_LOGGED_IN":
      if (action.payload) {
        setSessionData(action.payload as SessionData);
        const loggedInUserID = _.get(action, "payload.userID", 0);
        setSessionStorageItem(LOGGED_IN_USER_KEY, loggedInUserID, true);

        if (action.callback) {
          action.callback({});
        }

        const differentUser =
          !!state.loggedInUserID && state.loggedInUserID !== loggedInUserID;

        const newCurDomainID =
          differentUser || !state.loggedInUserID
            ? action.payload.domainID
            : state.curDomainID;

        // const personInfoForIncorrectUser =
        //   state.personInfo && state.personInfo.personID !== loggedInUserID;

        setSessionStorageItem(CUR_DOMAINID_KEY, newCurDomainID, true);

        if (loggedInUserID && (!state.loggedInUserID || differentUser)) {
          initMixPanelPerson(loggedInUserID);
          setMixPanelUserProperties(loggedInUserID, {
            domainID: action.payload.domainID || 0,
          });

          triggerMixPanelEvent("APP_USER_LOGGED_IN", {
            userID: loggedInUserID,
            dominID: action.payload.domainID || null,
          });
        }

        WebSocketClient.setUserIDForConnection(loggedInUserID);

        return {
          ...state,
          ...(differentUser
            ? {
                discData: {},
                discMini: {},
                personInfo: undefined,
                userDISCMini: undefined,
              }
            : {}),
          curDomainID: newCurDomainID,
          sessionData: action.payload,
          hasCheckedForStoredSession: true,
          loggedInUserID,
        };
      }

      return state;
    case "USERS_DISC_INFO_LOADED":
      if (action.payload && Array.isArray(action.payload.discProfiles)) {
        const newDiscData = (
          action.payload.discProfiles as Tyto.DISCInfo[]
        ).reduce((accum: { [x: string]: Tyto.DISCInfo }, discProfile) => {
          if (discProfile && discProfile.personID) {
            const key = state.personIDEmailMap[discProfile.personID];

            if (key && typeof key === "string") {
              accum[key.toLowerCase()] = discProfile as Tyto.DISCInfo;
            }
          }

          return accum;
        }, {});

        const discData = {
          ...state.discData,
          ...newDiscData,
        };

        console.log("New DISC Data should be: ", discData);

        return {
          ...state,
          discData,
        };
      }

      return state;
    case "USER_PERSONAL_DISC_MINI":
      if (action.payload) {
        const userDISCMini = action.payload.userDISCMini;

        if (!userDISCMini) {
          if (
            action.payload.userID &&
            action.payload.userID === state.loggedInUserID
          ) {
            return {
              ...state,
              CRITICAL_ERROR: DEFAULT_ACCOUNT_CRITICAL_ERROR_MSG,
            };
          } else {
            return state;
          }
        }

        // * Don't override with old session discMini =====================
        if (
          _.get(userDISCMini, "personID", 0) !==
          _.get(state, "sessionData.userID")
        ) {
          console.warn(
            `DISCMini did not match storedSession. || (${userDISCMini}) -- (${state.sessionData}) ||`
          );
          return state;
        }
        // * ================================================================

        if (_.get(action, "payload.source", "") !== CALL_SOURCES.WEBSOCKET) {
          if (userDISCMini && userDISCMini.personID) {
            WebSocketClient.watchMinis([userDISCMini.personID]);
          }
        }

        return {
          ...state,
          needsAssessment: !!userDISCMini && !userDISCMini.styleKey3,
          loggedInUserTeamToolsPermit: {
            ...state.loggedInUserTeamToolsPermit,
            ...(userDISCMini && userDISCMini.personID
              ? {
                  [userDISCMini.personID]: _.get(
                    userDISCMini,
                    "teamToolsPermit",
                    DEFAULT_TEAMTOOLS_USER_PERMISSIONS
                  ),
                }
              : {}),
          },
          userDISCMini,
          // userDISCMini:
          //   !!userDISCMini && userDISCMini.styleKey3 ? userDISCMini : undefined,
        };
      }

      return state;
    case "USER_INFO_LOADED":
      if (action.payload) {
        const personInfo: Tyto.Person | undefined = _.get(
          action.payload,
          "personInfo",
          undefined
        );

        if (personInfo && state.loggedInUserID) {
          if (
            _.get(personInfo, "personID", undefined) === state.loggedInUserID
          ) {
            const primaryTeam = (personInfo.teamMemberships || []).find(
              (team) => team.TeamID === personInfo.teamRoot
            );

            setMixPanelUserProperties(state.loggedInUserID, {
              ["$avatar"]: personInfo.profileImageID
                ? `https://${URL_BASE}/v25/user/photo/?assetID=${personInfo.profileImageID}&encoding=ocDEFAULT`
                : undefined,
              ["$email"]: personInfo.email,
              jobTitle: personInfo.jobTitle,
              domainName: _.get(personInfo, "domain.domainName", null),
              primaryTeamID: primaryTeam ? primaryTeam.TeamID : null,
              primaryTeamName: primaryTeam ? primaryTeam.teamName : null,
              userName:
                personInfo.givenName || personInfo.familyName
                  ? `${personInfo.givenName} ${personInfo.familyName}`
                  : null,
            });
          }
        }

        return {
          ...state,
          personInfo: action.payload.personInfo,
          tryybProfiles: {
            ...state.tryybProfiles,
            ...(personInfo
              ? { [_.get(personInfo, "personID", 0)]: personInfo }
              : {}),
          },
        };
      }

      return state;
    case "DISC_MINI_PROFILES_LOADED":
      if (action.payload && Array.isArray(action.payload.discMini)) {
        // * [1] - Safely Pull profiles from Action
        const discProfiles: Tyto.DISCProfileMini[] = _.get(
          action.payload,
          "discMini",
          []
        );
        const allDiscProfilesByID = _.keyBy(discProfiles, "personID");

        const userIDs = discProfiles.map((profile) => profile.personID);

        // * [2] - If not from WebSocket, Ping WebSocket to watch for changes on these Profiles
        if (_.get(action, "payload.source", "") !== CALL_SOURCES.WEBSOCKET) {
          WebSocketClient.watchMinis(userIDs);
        }

        // * [3] - Create Lists for the Profiles, theirs userIDs, and their emails
        const discProfilesByEmail = mapMiniProfilesByEmails(discProfiles);
        const discProfilesByID = mapMiniProfilesByUserID(discProfiles);
        const emailsByPersonIDsMap = mapPersonEmailsByPersonIDs(discProfiles);

        // * [4] - Merge or override state emails into local list
        const personIDEmailMap = !!action.payload.overrideCurrentData
          ? {
              ...emailsByPersonIDsMap,
            }
          : {
              ...state.personIDEmailMap,
              ...emailsByPersonIDsMap,
            };

        // * [5] - Get Emails sent that didn't return in response
        const emailsUsed = _.get(action, "payload.emailsUsed", []) as string[];
        const omittedEmails = Array.isArray(emailsUsed)
          ? emailsUsed
              .filter((email) => !discProfilesByEmail[email.toLowerCase()])
              .map((email) => email.toLowerCase())
          : [];
        // * [6] - Filter userIDsUsed to find those who don't have a response
        const userIDsUsed = action.payload.userIDsUsed as number[];
        const discMiniNull = Array.isArray(userIDsUsed)
          ? userIDsUsed.filter(
              (usedUserID) =>
                !discProfilesByID[usedUserID] ||
                !discProfilesByID[usedUserID].styleKey3
            )
          : [];

        // * [7] - Create List of profiles that returned but were not complete
        const discMiniNullProfiles = Array.isArray(userIDs)
          ? userIDs.reduce(
              (accum: { [x: number]: Tyto.DISCProfileMini }, usedUserID) => {
                if (
                  allDiscProfilesByID[usedUserID] &&
                  !allDiscProfilesByID[usedUserID].styleKey3
                ) {
                  accum[usedUserID] = allDiscProfilesByID[usedUserID];
                }

                return accum;
              },
              {}
            )
          : {};

        // * [8] - Merge or override state minis into local key-value list
        const discMini = !!action.payload.overrideCurrentData
          ? {
              ...discProfilesByID,
            }
          : {
              ...state.discMini,
              ...discProfilesByID,
            };

        // * [9] - Make Sure Null list does not include values that were previously null but now exist
        const newDiscMiniNull = _.uniq([
          ...state.discMiniNull,
          ...discMiniNull,
        ]).filter((userID) => !discMini[userID]);

        // if (_.get(action, "payload.source", "") === CALL_SOURCES.WEBSOCKET) {
        //   debugger;
        // }

        // * [10] - Return new State
        return {
          ...state,
          discMini,
          discMiniNullProfiles: {
            ...state.discMiniNullProfiles,
            ...discMiniNullProfiles,
          },
          discMiniNull: newDiscMiniNull,
          discDataNull: _.uniq([...state.discDataNull, ...omittedEmails]),
          personIDEmailMap,
        };
      } else if (
        Array.isArray(action.payload.emailsUsed) &&
        !!action.payload.emailsUsed.length
      ) {
        // * [B] - If not profiles were supplied, update null list with the supplied emails
        return {
          ...state,
          discDataNull: _.uniq([
            ...state.discDataNull,
            ...action.payload.emailsUsed,
          ]),
        };
      }

      return state;
    case "TEAM_DISC_MINI_PROFILES_LOADED":
      // * [A] - Minimum Info is present, proceeed...
      if (action.payload && Array.isArray(action.payload.discProfiles)) {
        // * [1] - Safely pull profiles that loaded and the teamID they belong to
        const discProfiles: Tyto.DISCProfileMini[] = _.get(
          action.payload,
          "discProfiles",
          []
        );
        const teamID: string = _.get(action.payload, "teamID", undefined);

        // * [2] - If not from WebSocket tell the WebSocket to watch changes for these DISCMinis
        if (_.get(action, "payload.source", "") !== CALL_SOURCES.WEBSOCKET) {
          const userIDs = discProfiles.map((discMini) => discMini.personID);

          if (userIDs.length) {
            WebSocketClient.watchMinis(userIDs);
          }
        }

        // * [3] - Make lists PRofiles by userID, emails
        const discProfilesByEmail = mapMiniProfilesByEmails(discProfiles);
        const discProfilesByID = mapMiniProfilesByUserID(discProfiles);
        const allDiscProfilesByID = mapMiniProfilesByUserID(discProfiles, true); // * IncludeProfilesWithoutDISC
        const emailsByPersonIDsMap = mapPersonEmailsByPersonIDs(discProfiles);

        const allDiscMiniKeysForTeam = Object.keys(allDiscProfilesByID)
          .map((stringOfUserID) => parseInt(stringOfUserID) || 0)
          .filter((userID) => !!userID);

        // * [4] - Merge or override state ids map into local list
        const personIDEmailMap = !!action.payload.overrideCurrentData
          ? {
              ...emailsByPersonIDsMap,
            }
          : {
              ...state.personIDEmailMap,
              ...emailsByPersonIDsMap,
            };

        // * [5] - Get Emails sent that didn't return in response
        const emailsUsed = action.payload.emailsUsed as string[];
        const omittedEmails = Array.isArray(emailsUsed)
          ? emailsUsed
              .filter((email) => !discProfilesByEmail[email.toLowerCase()])
              .map((email) => email.toLowerCase())
          : [];

        // * [6] - Determine which userIDs are null
        const userIDsUsed = discProfiles.map(
          (mini) => mini.personID
        ) as number[];
        const discMiniNull = Array.isArray(userIDsUsed)
          ? userIDsUsed.filter(
              (usedUserID) =>
                !discProfilesByID[usedUserID] ||
                !discProfilesByID[usedUserID].styleKey3
            )
          : [];

        // * [7] - Create List of profiles that returned but were not complete
        const discMiniNullProfiles = Array.isArray(userIDsUsed)
          ? userIDsUsed.reduce(
              (accum: { [x: number]: Tyto.DISCProfileMini }, usedUserID) => {
                if (
                  allDiscProfilesByID[usedUserID] &&
                  !allDiscProfilesByID[usedUserID].styleKey3
                ) {
                  accum[usedUserID] = allDiscProfilesByID[usedUserID];
                }

                return accum;
              },
              {}
            )
          : {};

        // * [8] - Merge state team -> user map into local key-value list
        const discMiniByTeam = !!action.payload.overrideCurrentData
          ? {
              [teamID]: allDiscMiniKeysForTeam,
            }
          : {
              ...(state.discMiniByTeam || {}),
              [teamID]: allDiscMiniKeysForTeam,
            };

        // * [9] - Make Sure Null list does not include values that were previously null but now exist
        const discMini = {
          ...state.discMini,
          ...discProfilesByID,
        };

        const newDiscMiniNull = _.uniq([
          ...state.discMiniNull,
          ...discMiniNull,
        ]).filter((userID) => !discMini[userID]);

        // * [10] - Return new State
        return {
          ...state,
          discMini,
          discMiniNullProfiles: {
            ...state.discMiniNullProfiles,
            ...discMiniNullProfiles,
          },
          discMiniByTeam,
          discMiniNull: newDiscMiniNull,
          discDataNull: _.uniq([...state.discDataNull, ...omittedEmails]),
          personIDEmailMap,
        };
      }

      // * [B] - Return State as it was
      return state;
    case "TEAM_AND_BELOW_DISC_MINI_PROFILES_LOADED":
      // * [A] - Minimum Info is present, proceeed...
      if (action.payload && Array.isArray(action.payload.discProfiles)) {
        // * [1] - Safely pull profiles that loaded and the teamID they belong to
        const discProfiles: Tyto.DISCProfileMini[] = _.get(
          action.payload,
          "discProfiles",
          []
        );
        const teamID: string = _.get(action.payload, "teamID", undefined);

        // * [2] - If not from WebSocket tell the WebSocket to watch changes for these DISCMinis
        if (_.get(action, "payload.source", "") !== CALL_SOURCES.WEBSOCKET) {
          const userIDs = discProfiles.map((discMini) => discMini.personID);

          if (userIDs.length) {
            WebSocketClient.watchMinis(userIDs);
          }
        }

        // * [3] - Make lists PRofiles by userID, emails
        const discProfilesByEmail = mapMiniProfilesByEmails(discProfiles);
        const discProfilesByID = mapMiniProfilesByUserID(discProfiles);
        const allDiscProfilesByID = mapMiniProfilesByUserID(discProfiles, true); // * IncludeProfilesWithoutDISC
        const emailsByPersonIDsMap = mapPersonEmailsByPersonIDs(discProfiles);

        const allDiscMiniKeysForTeam = Object.keys(allDiscProfilesByID)
          .map((stringOfUserID) => parseInt(stringOfUserID) || 0)
          .filter((userID) => !!userID);

        // * [4] - Merge or override state ids map into local list
        const personIDEmailMap = !!action.payload.overrideCurrentData
          ? {
              ...emailsByPersonIDsMap,
            }
          : {
              ...state.personIDEmailMap,
              ...emailsByPersonIDsMap,
            };

        // * [5] - Get Emails sent that didn't return in response
        const emailsUsed = action.payload.emailsUsed as string[];
        const omittedEmails = Array.isArray(emailsUsed)
          ? emailsUsed
              .filter((email) => !discProfilesByEmail[email.toLowerCase()])
              .map((email) => email.toLowerCase())
          : [];

        // * [6] - Determine which userIDs are null
        const userIDsUsed = discProfiles.map(
          (mini) => mini.personID
        ) as number[];
        const discMiniNull = Array.isArray(userIDsUsed)
          ? userIDsUsed.filter(
              (usedUserID) =>
                !discProfilesByID[usedUserID] ||
                !discProfilesByID[usedUserID].styleKey3
            )
          : [];

        // * [7] - Create List of profiles that returned but were not complete
        const discMiniNullProfiles = Array.isArray(userIDsUsed)
          ? userIDsUsed.reduce(
              (accum: { [x: number]: Tyto.DISCProfileMini }, usedUserID) => {
                if (
                  allDiscProfilesByID[usedUserID] &&
                  !allDiscProfilesByID[usedUserID].styleKey3
                ) {
                  accum[usedUserID] = allDiscProfilesByID[usedUserID];
                }

                return accum;
              },
              {}
            )
          : {};

        // * [8] - Merge state team -> user map into local key-value list
        const discMiniByTeamAndBelow = !!action.payload.overrideCurrentData
          ? {
              [teamID]: allDiscMiniKeysForTeam,
            }
          : {
              ...(state.discMiniByTeamAndBelow || {}),
              [teamID]: allDiscMiniKeysForTeam,
            };

        // * [9] - Make Sure Null list does not include values that were previously null but now exist
        const discMini = {
          ...state.discMini,
          ...discProfilesByID,
        };

        const newDiscMiniNull = _.uniq([
          ...state.discMiniNull,
          ...discMiniNull,
        ]).filter((userID) => !discMini[userID]);

        // * [10] - Return new State
        return {
          ...state,
          discMini,
          discMiniNullProfiles: {
            ...state.discMiniNullProfiles,
            ...discMiniNullProfiles,
          },
          discMiniByTeamAndBelow,
          discMiniNull: newDiscMiniNull,
          discDataNull: _.uniq([...state.discDataNull, ...omittedEmails]),
          personIDEmailMap,
        };
      }

      // * [B] - Return State as it was
      return state;

    case "UPDATE_USERS_CONNECTIONS_TABLE":
      if (action.payload.userConnections) {
        const connectionsAsObj = _.keyBy(
          action.payload.userConnections as UserOnlineInfo[],
          "userID"
        );

        const newOnlineUsersTable = {
          ...(state.onlineUsersTable || {}),
          ...(connectionsAsObj || {}),
        };

        return {
          ...state,
          onlineUsersTable: newOnlineUsersTable,
        };
      }

      return state;
    case "USER_DOMAIN_INFO_LOADED":
      if (action.payload.domain) {
        return {
          ...state,
          userDomain: action.payload.domain,
        };
      }

      return state;
    case "WEBSOCKET_CONNECTION_STATUS_CHANGE":
      return {
        ...state,
        websocketServerConnected: !!_.get(action, "payload.isConnected"),
      };
    default:
      return state;
  }
};

function StoreContextProvider(props: any) {
  // [A]
  let [state, dispatch] = React.useReducer(reducer, initialState);
  let value = { state, dispatch };

  // [B]
  return (
    <StoreContext.Provider value={value}>
      {props.children}
    </StoreContext.Provider>
  );
}

let StoreContextConsumer = StoreContext.Consumer;

// [C]
export { StoreContext, StoreContextProvider, StoreContextConsumer };
