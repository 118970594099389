import { ENDPOINT_MENU_GET } from "../constants/";
import { callWrapper, CallOpts } from "./utils";
import { Tyto } from "../../typings/tyto/";
import { SessionData } from "../../typings";

const endpoint = ENDPOINT_MENU_GET;

export const Menu = {
  get(params: Endpoints.Tyto.Menu.GetParameters, callOpts?: CallOpts) {
    return callWrapper("get", endpoint, { ...params }, callOpts) as Promise<{
      menuItems: Tyto.MenuItem[];
      session: SessionData;
    }>;
  }
};
