/*
 * Component Description
 */
import * as React from "react";
import cx from "classnames";
import { Link } from "react-router-dom";

import { Icon } from "../../../common/";
import { iconType } from "../../../common/icon/typings/";

interface Props {
  onMenuButtonClick?: () => void;
  path?: string;
  icon?: iconType;
  text: string;
  to: string;
  uri?: string;
}

export default (props: Props) => {
  return (
    <Link
      className="meta-nav-link"
      to={props.to}
      onClick={props.onMenuButtonClick}
    >
      <li
        className={cx(
          "meta-nav-list-item",
          props.uri && props.uri === props.to && "active-list-item"
        )}
        key={props.text}
      >
        {props.icon && (
          <Icon
            className="meta-nav-list-item-icon"
            icon={props.icon}
            size={23}
          />
        )}

        <p className="meta-nav-list-item-text title-bold">{props.text}</p>
      </li>
    </Link>
  );
};
