import * as React from "react";

import { IconProps } from "../typings";

export default ({
  className,
  color,
  gradient,
  gradientKey,
  size,
}: IconProps) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 49"
      height={size}
      width={size}
      fill={color}
    >
      {Array.isArray(gradient) && (
        <defs>
          <linearGradient id={gradientKey} x1="0" x2="1" y1="0" y2="1">
            {gradient.map((grad) => (
              <stop
                key={`${grad.offsetPercent}-${grad.color}`}
                offset={`${grad.offsetPercent}%`}
                stopColor={grad.color}
              />
            ))}
          </linearGradient>
        </defs>
      )}
      {/* <path
        d="M0.398438 46.0726V0H26.3121L30.4081 3.84107L34.5041 7.68212V46.0726H0.398438Z"
        fill="#223958"
      /> */}
      <path
        d="M21.7364 32.0863L14.4912 15.4055L31.172 22.6507L35.7213 29.8959L31.846 32.7602L28.9816 36.6356L21.7364 32.0863Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M14.6594 15.9109L21.7622 32.1333L28.9813 36.6355L31.8315 32.746L30.1237 31.0383L22.9906 24.429L14.6594 15.9109Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M10.0645 34.6592L23.2524 32.9288L28.9811 36.6356L11.2439 35.8386L9.94145 39.837L10.0645 34.6592Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M21.7363 32.0867L28.9815 36.636L31.8459 32.7606L30.6664 31.5812L21.7363 32.0867Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M25.4434 34.4451L28.9817 36.6355L31.8605 32.7746L31.0036 31.9177L25.4434 34.4451Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M14.9968 15.574L31.2193 22.6768L35.7214 29.8959L31.832 32.7461L30.1242 31.0383L23.5149 23.9052L14.9968 15.574Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M34.8789 6.13892L32.0145 24.1676L35.7214 29.8964L34.8789 6.13892Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M31.1699 22.6511L35.7192 29.8963L31.8439 32.7607L30.6644 31.5812L31.1699 22.6511Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
      <path
        d="M33.5293 26.3579L35.7197 29.8963L31.8588 32.7751L31.0019 31.9182L33.5293 26.3579Z"
        stroke="white"
        stroke-linejoin="bevel"
        stroke-dasharray="2 1"
      />
    </svg>
  );
};
