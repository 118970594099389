import * as React from "react";
import { Tyto } from "../../../typings/tyto";

import TeamTreeItem from "./TeamTreeItem";
// import TeamTreeItemWrapper from "./TeamTreeItemWrapper";

import { StoreState as AppStoreState } from "../../../data/stores/AppStore";
import {
  AdminStoreDispatch,
  StoreState as AdminStoreState
} from "../data/stores/AdminStore";

interface Props {
  AdminStoreDispatch: AdminStoreDispatch;
  AdminStoreState: AdminStoreState;
  chooseTeam: (teamID: number) => void;
  curDomainID: number;
  discMiniByTeam: AppStoreState["discMiniByTeam"];
  disabled: boolean;
  fadeUnselectedTeams: boolean;
  previewPersonID?: number;
  selectedPeopleIDs: {
    [x: number]: boolean;
  };
  selectedTeamsIDs: {
    [x: number]: boolean;
  };
  selectedTeamID: number;
  searchTerm: string;
  searchTermRegExp?: RegExp;
  team: Tyto.Team;
  teamsStructured: AdminStoreState["structured"];
  depth: number;
}

export default (props: Props) => {
  return (
    <>
      <TeamTreeItem
        AdminStoreDispatch={props.AdminStoreDispatch}
        AdminStoreState={props.AdminStoreState}
        chooseTeam={props.chooseTeam}
        curDomainID={props.curDomainID}
        discMiniByTeam={props.discMiniByTeam}
        fadeUnselectedTeams={props.fadeUnselectedTeams}
        disabled={props.disabled}
        previewPersonID={props.previewPersonID}
        selectedPeopleIDs={props.selectedPeopleIDs}
        selectedTeamsIDs={props.selectedTeamsIDs}
        selectedTeamID={props.selectedTeamID}
        searchTerm={props.searchTerm}
        searchTermRegExp={props.searchTermRegExp}
        team={props.team}
        teamsStructured={props.teamsStructured}
        depth={props.depth}
      />
    </>
  );
};
